import React from 'react';
import { ShopCartItem } from 'rx-domain';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Container } from '../CheckoutProductsList/index.styles';

import { ShopCartCardTitle } from '../../shopping-cart/ShopCartCardTitle';
import { Table } from '../../shopping-cart/ShopCartCard/index.styles';
import { CheckoutShopCartDrugItem } from '../CheckoutShopCartDrugItem';

interface IProductCardListProps {
  title: string;
  products: ShopCartItem[];
  shippingState: string;
}

export const ProductCardList: React.FC<IProductCardListProps> = (props) => {
  return (
    <Container>
      <ShopCartCardTitle title={props.title} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>NAME</TableCell>
            <TableCell>QUANTITY</TableCell>
            <TableCell>SUBTOTAL</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.products.map((product) => {
            return (
              <CheckoutShopCartDrugItem
                key={product.id}
                product={product}
                shippingState={props.shippingState}
              />
            );
          })}
        </TableBody>
      </Table>
    </Container>
  );
};
