import React from 'react';
import { Menu, IconButton } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

import { Option } from './index.styles';

type ITransactionItem = {
  type: string;
  text: string;
  onClick: () => Promise<void> | void;
  disabled?: boolean;
};

type RxMenuProps = {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  options: Array<ITransactionItem>;
  onOpenMenu: (e: React.MouseEvent<HTMLElement>) => void;
};

const ITEM_HEIGHT = 48;

const RxMenu = ({
  anchorEl,
  onClose,
  onOpenMenu,
  options,
}: RxMenuProps): JSX.Element => {
  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={onOpenMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          onClose();
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {options.map((option) => (
          <Option
            key={option.type}
            disabled={option.disabled}
            onClick={(e) => {
              e.stopPropagation();
              option.onClick();
              onClose();
            }}
          >
            {option.text}
          </Option>
        ))}
      </Menu>
    </>
  );
};

export { RxMenu };
