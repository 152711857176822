import React, { useEffect } from 'react';

import {
  GetProducts,
  GetShoppingCart,
  ShippingConfig,
  ShopCartItem,
} from 'rx-domain';

import { ShopCartCard } from '../ShopCartCard';
import { ShopCartCheckout } from '../ShopCartCheckout';

import { Container } from './index.styles';
import { useSelector } from 'react-redux';
import { getWACSubtotal } from '../../../../rx-store';
import formatProduct from '../../../../rx-utils/gtmFormatters';

type IProps = {
  shippingConfig: Record<string, ShippingConfig>;
  productsBySellers: Record<string, ShopCartItem[]>;
  sellerWithProducts: string[];
};

export const ShopCartProductsBySeller: React.FC<IProps> = ({
  sellerWithProducts,
  productsBySellers,
  shippingConfig,
}) => {
  const fullTotalOrder = useSelector(getWACSubtotal);

  useEffect(() => {
    if (fullTotalOrder !== 0) {
      const products: GetShoppingCart.ShoppingCartProductItemGtm[] = [];
      for (const orderKey of sellerWithProducts) {
        for (const product of productsBySellers[orderKey]) {
          products.push({ ...product.product, count: product.count });
        }
      }

      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({ ecommerce: null });
      // @ts-ignore
      window.dataLayer.push({
        event: 'view_cart',
        ecommerce: {
          currency: 'USD',
          value: 0,
          items: products.map((product, index) => {
            return formatProduct({
              product: product as unknown as GetProducts.ProductItem,
              index: index,
              quantity: product.count,
            });
          }),
        },
        user_id: localStorage.getItem('_userid'),
      });
    }
  }, [fullTotalOrder]);
  return (
    <Container>
      {sellerWithProducts.map((orderKey) => (
        <ShopCartCard
          key={orderKey}
          title={orderKey}
          products={productsBySellers[orderKey]}
          shipConfig={shippingConfig[orderKey]}
        />
      ))}
      {sellerWithProducts.length > 0 && <ShopCartCheckout />}
    </Container>
  );
};
