import React from 'react';
import { useHistory } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { GetInventory } from 'rx-domain';
import { formatMoney } from 'rx-utils';
import RxRiseLogo from 'assets/logos/rxrise-gray-logo.svg';

type IInventoryItemProps = {
  data: GetInventory.InventoryItem;
  onClickRemove(id: number): () => void;
};

export const InventoryItem: React.FC<IInventoryItemProps> = (props) => {
  const history = useHistory();
  const [isRowOpen, setIsRowOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsRowOpen(!isRowOpen)}
          >
            {isRowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <img
            src={props.data.pictureUrl ? props.data.pictureUrl : RxRiseLogo}
            alt=""
            style={{
              boxSizing: 'border-box',
              border: '1px solid gray',
              borderRadius: '0.25rem',
              padding: '0.25rem',
              width: '4rem',
            }}
          />
        </TableCell>
        <TableCell>{props.data.ndc}</TableCell>
        <TableCell>{props.data.name}</TableCell>
        <TableCell>{props.data.description}</TableCell>

        <TableCell>{formatMoney(props.data.price)}</TableCell>
        <TableCell>{props.data.expirationDate}</TableCell>
        <TableCell>{props.data.lotNumber}</TableCell>
        <TableCell>{props.data.units}</TableCell>

        <TableCell>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              setAnchorEl(event.currentTarget)
            }
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="product-options"
            anchorEl={anchorEl}
            keepMounted
            open={isMenuOpen}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              key="product-edit"
              onClick={() => history.push(`/seller/products/${props.data.id}`)}
            >
              Edit Product
            </MenuItem>
            <MenuItem
              color="red"
              key="delete-product"
              onClick={props.onClickRemove(props.data.id)}
            >
              <Typography color="secondary">Remove Product</Typography>
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={isRowOpen} timeout="auto" unmountOnExit>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product ID</TableCell>
                  <TableCell>Minimum Order</TableCell>
                  <TableCell>Manufacturer</TableCell>
                  <TableCell>Shipping Name</TableCell>
                  <TableCell>State Restrictions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{props.data.productId}</TableCell>
                  <TableCell>{props.data.minimumQuantity}</TableCell>
                  <TableCell>{props.data.manufacturer}</TableCell>
                  <TableCell>{props.data.shippingName}</TableCell>
                  <TableCell>{props.data.shippingConstraints}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
