import { Paper, Typography } from '@material-ui/core';

export const OrderCountReportsTooltip = ({ payload, label, active }: any) => {
  if (active && payload && payload.length) {
    return (
      <Paper variant="outlined" style={{ padding: '1rem' }}>
        <Typography variant="h6">{`${label}`}</Typography>
        <Typography>
          Total Order Number: {payload[0].value + payload[1].value}
        </Typography>
        <Typography style={{ color: '#008080' }}>
          Regular Orders Number: {payload[0].value}
        </Typography>
        <Typography style={{ color: '#FF7F50' }}>
          EDI Orders Number: {payload[1].value}
        </Typography>
      </Paper>
    );
  }

  return null;
};
