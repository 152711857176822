import * as Yup from 'yup';
import { productSchema } from './common.validator';

const ManageItemschema = Yup.object().shape({
  products: Yup.array()
    .of(productSchema)
    .min(1, 'products field must have at least 1 items')
    .required('must have one product added'),
});

export { ManageItemschema };
