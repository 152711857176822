import styled from 'styled-components';
import { RxTable } from 'rx-core';
import { Paper } from '@material-ui/core';

export const Container = styled(Paper)`
  margin-bottom: 1rem;
  padding: 1rem;
`;

export const Table = styled(RxTable)`
  margin-top: 1rem;
`;
