import React from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { Divider, Paper, Typography } from '@material-ui/core';

import {
  useDeleteProductPricingTierAdmin,
  usePostProductPricingTierAdmin,
} from 'rx-api';
import { SEO, TemplateView } from 'rx-core';
import { useAdminEditProduct, useToast } from 'rx-hooks';

import {
  EditDrugValidator,
  UpdateDrugValidator,
} from 'validations/EditDrugValidator';
import { AddProductForm } from './AddProductForm';
import { AddProductActions } from './AddProductActions';
import { Container } from './index.styles';
import { ProductPricingTierList } from '../ProductPricingTier/ProductPricingTierList';
import { AddPricingTierForm } from './AddPricingTierForm';
import { ProductPricingTierDeleteModal } from '../ProductPricingTier/ProductPricingTierDeleteModal';

export const AddProductView: React.FC = () => {
  const history = useHistory();
  const toast = useToast();
  const { initialValues, onSubmit, isEdit, isLoading, product, refetch } =
    useAdminEditProduct();
  const { id: productId = '0' } = useParams<{ id?: string }>();
  const [selectedPricingTierId, setSelectedPricingTierId] = React.useState<
    number | null
  >(null);
  const [open, setOpen] = React.useState(false);
  const removeProduct = useDeleteProductPricingTierAdmin(
    productId,
    selectedPricingTierId || '0'
  );

  const handleClickRemove = (id: number) => () => {
    setSelectedPricingTierId(id);
    setOpen(true);
  };

  const productFormik = useFormik({
    initialValues: {
      ndc: product?.ndc || '',
      name: product?.name || '',
      description: product?.description || '',
      manufacturer: product?.manufacturer || '',
      price: product?.price || 0,
      expirationDate: product?.expirationDate || '',
      lotNumber: product?.lotNumber || '',
      units: product?.units || 0,
      minimumQuantity: product?.minimumQuantity || 0,
      maximumQuantity: product?.maximumQuantity || '',
      productId: product?.productId || '',
      categoryId: product?.categoryId || 1,
      category: product?.category || 'drug',
      strengthValue: product?.strengthValue || null,
      strengthUnit: product?.strengthUnit || 'none',
    },
    // validationSchema: isEdit ? UpdateDrugValidator : EditDrugValidator,
    onSubmit,
    enableReinitialize: true,
  });

  const addPricingTier = usePostProductPricingTierAdmin(product?.id || 0);

  const pricingTierFormik = useFormik({
    initialValues: {
      minAmount: 1,
      maxAmount: 2,
      price: `${product?.price}` ?? '1.00',
      logicalOperator: 'between',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        const payload = ((data: any) => {
          if (data.logicalOperator === 'less_than') {
            return { ...data, minAmount: null };
          }

          if (data.logicalOperator === 'more_than') {
            return { ...data, maxAmount: null };
          }

          return data;
        })(values);

        await addPricingTier.mutateAsync(payload);
        toast.onSuccessToast('Pricing tier added!');
        formikHelpers.setFieldValue('minAmount', 0);
        formikHelpers.setFieldValue('minAmount', 0);
        formikHelpers.setFieldValue('logicalOperator', 'between');
        refetch();
      } catch (err) {
        toast.onErrorToast('Adding pricing tier failed!');
      }
    },
    enableReinitialize: true,
  });

  const tiers = product?.pricingTiers ?? [];

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickConfirm = async () => {
    try {
      await removeProduct.mutateAsync({});
      toast.onSuccessToast('Pricing tier removed from product!');
      setOpen(false);
      refetch();
    } catch (err) {
      toast.onErrorToast('Could not remove pricing tier!');
    }
  };

  return (
    <TemplateView>
      <SEO
        title={isEdit ? 'RxRise | Edit Product' : 'RxRise | Add new product'}
      />

      <Container>
        <form onSubmit={productFormik.handleSubmit}>
          <AddProductActions isEdit={isEdit} productName={initialValues.name} />
          <AddProductForm
            isLoading={isLoading}
            errors={productFormik.errors}
            onChange={productFormik.handleChange}
            values={productFormik.values}
            setFieldValue={productFormik.setFieldValue}
            isDisabled={productFormik.isSubmitting}
            onClickCancel={() => history.push('/products/')}
          />
        </form>

        {!isLoading && (
          <Paper
            variant="outlined"
            style={{ marginTop: '2rem', padding: '1rem' }}
          >
            <Typography variant="h6">Pricing Tiers</Typography>

            <ProductPricingTierList
              productId={productId}
              data={tiers}
              onClickRemove={handleClickRemove}
            />

            <ProductPricingTierDeleteModal
              open={open}
              onClickConfirm={handleClickConfirm}
              onClose={handleClose}
            />

            <Divider style={{ margin: '1rem 0 1.5rem 0' }} />

            <AddPricingTierForm
              onSubmit={pricingTierFormik.handleSubmit}
              values={pricingTierFormik.values}
              errors={pricingTierFormik.errors}
              onChange={pricingTierFormik.handleChange}
              isDisabled={pricingTierFormik.isSubmitting}
            />
          </Paper>
        )}
      </Container>
    </TemplateView>
  );
};
