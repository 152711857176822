import styled, { css } from 'styled-components';
import { RxButtonSimple } from 'rx-core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    font-family: ${theme.fonts.robotoRegular};
    font-size: ${theme.sizes.h2};
    border-bottom: 1px solid rgb(43, 54, 71, 0.35);
  `}
`;

export const Content = styled.p``;

export const CancelButton = styled(RxButtonSimple)`
  margin-right: 1rem;
  width: 6rem;
  margin-top: 1rem;
`;
