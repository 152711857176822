import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useDropzone } from 'react-dropzone';

import { useDeletePictureProduct } from 'rx-api';

import { IAddProductForm } from 'rx-domain';

import { useToast } from 'rx-hooks';
import { Alert } from '@material-ui/lab';
import {
  Container,
  DragText,
  DragContainer,
  DragTextBlue,
  RemoveIcon,
  Icon,
  PreviewImg,
  ImgContainer,
  ErrorMessage,
} from './index.styles';

export const AddProductDrag = ({
  isDragActive,
}: {
  isDragActive: boolean;
}): JSX.Element => {
  return isDragActive ? (
    <DragText>Drop the file here</DragText>
  ) : (
    <DragContainer>
      <Icon fontSize="large" />
      <DragText>
        Drag your image here or <DragTextBlue>browse image</DragTextBlue>
      </DragText>
    </DragContainer>
  );
};

type IProps = {
  url: string;
  isEdit: boolean;
  idProduct: number;
};

export const AddProductDropzone = (props: IProps): JSX.Element => {
  const [img64, setImg64] = useState('');

  const { onSuccessToast } = useToast();

  const { setFieldValue, errors } = useFormikContext<IAddProductForm>();

  const deletePicture = useDeletePictureProduct();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        setFieldValue('picture', acceptedFiles[0]);
      }

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => setImg64(reader.result as string);
        reader.readAsDataURL(file);
      });
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['image/png', 'image/jpg', 'image/jpeg'],
    multiple: false,
    onDrop,
  });

  const removeImage = useCallback(
    async (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      setImg64('');
      setFieldValue('picture', null);
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (props.url) setImg64(props.url);
  }, [props.url]);

  return (
    <div>
      <Container>
        <input
          {...getInputProps({
            name: 'picture',
            type: 'file',
          })}
        />
        {img64 ? (
          <ImgContainer>
            <RemoveIcon fontSize="large" onClick={removeImage} />
            <Container {...getRootProps()}>
              <PreviewImg alt="preview" src={img64} />
            </Container>
          </ImgContainer>
        ) : (
          <div {...getRootProps()}>
            <AddProductDrag isDragActive={isDragActive} />
          </div>
        )}
      </Container>
      {errors.picture && (
        <Alert severity="error">
          Use a png, jpg or jpeg file with size below 2 MB. For better view,
          square format recommended.
        </Alert>
      )}
    </div>
  );
};
