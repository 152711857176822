import { useAuth, useResponsive } from 'rx-hooks';
import React from 'react';
import styled from 'styled-components';

import { HeaderLogo } from '../atoms';
import { HeaderSearchPharmacist } from './HeaderSearchPharmacist';
import { HeaderAdminActions } from './HeaderAdminActions';
import { HeaderManufacturerActions } from './HeaderManufacturerActions';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
`;

const Spacer = styled.div`
  padding-right: 138px;
`;

const HeaderSearchBar = (): JSX.Element => {
  const { type } = useAuth();
  const { isDesktop } = useResponsive();

  return (
    <Container>
      <HeaderLogo />
      {type === 'pharmacist' && isDesktop && <HeaderSearchPharmacist />}
      <Spacer />
      {type === 'manufacturer' && isDesktop && <HeaderManufacturerActions />}
      {type === 'admin' && isDesktop && <HeaderAdminActions />}
    </Container>
  );
};

HeaderSearchBar.defaultProps = {
  showSearch: true,
};

export { HeaderSearchBar };
