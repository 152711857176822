import React from 'react';

import { IGetAdminManufacturersIdShipConfigResponse } from 'rx-domain';

import { Button, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { useToast } from 'rx-hooks';
import { usePutAdminManufacturerIdShipConfig } from 'rx-api';
import { useQueryClient } from 'react-query';
import numeral from 'numeral';
import { ManufacturerShippingSchema } from 'utils/validators/admin/manufacturer-shipping.validator';
import { AdminSellerCardSubTitle } from '../AdminSellerCardSubtitle';
import { AdminSellerShippingInfoStatesForm } from '../AdminSellerShippingInfoStatesForm';
import { SellerShipInfoMinOrderForm } from '../SellerShipInfoMinOrderForm';
import { AdminSellerShippingInfoRateForm } from '../AdminSellerShippingInfoRateForm';
import { AdminSellerCardTitle } from '../AdminSellerCardTitle';

type IAdminSellerShippingInfoProps = {
  data: IGetAdminManufacturersIdShipConfigResponse | undefined;
  idOwner: string;
};

export const AdminSellerShippingInfo: React.FC<
  IAdminSellerShippingInfoProps
> = (props) => {
  const updateShippingInfo = usePutAdminManufacturerIdShipConfig();
  const queryClient = useQueryClient();

  const toast = useToast();

  const initialValues = {
    id: parseInt(props.idOwner ?? '0', 10),
    shippingConstraints: props.data ? props.data.shippingConstraints : '',
    groundShipping: props.data ? props.data?.groundShipping : 0,
    overnightShipping: props.data ? props.data?.overnightShipping : 0,
    minimumOrderAmount: props.data ? props.data.minimumOrderAmount : 0,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ManufacturerShippingSchema,
    onSubmit: async (values) => {
      try {
        const groundShipping = numeral(values.groundShipping).value();
        const overnightShipping = numeral(values.overnightShipping).value();

        if (!groundShipping || !overnightShipping) {
          return;
        }

        await updateShippingInfo.mutateAsync({
          ...values,
          groundShipping,
          overnightShipping,
        });

        await queryClient.refetchQueries(
          'get-admin-manufacturers-id-ship-config',
          {
            active: true,
          }
        );

        toast.onSuccessToast('Shipping info updated 🚀');
      } catch (err) {
        toast.onErrorToast(
          'We were not able to save your shipping information'
        );
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <AdminSellerCardTitle>Shipping Information</AdminSellerCardTitle>
      <AdminSellerCardSubTitle>
        Customize Manufacturer’s state restrictions and modify how much they
        charge for shipping during checkout.
      </AdminSellerCardSubTitle>
      <AdminSellerCardSubTitle bold>State Restrictions</AdminSellerCardSubTitle>
      <AdminSellerCardSubTitle>
        Add a state or country you don’t ship to, you can click/tap to add as a
        restricted state.
      </AdminSellerCardSubTitle>
      <AdminSellerShippingInfoStatesForm
        data={props.data}
        setValue={formik.setFieldValue}
      />

      <div style={{ marginTop: '2rem' }}>
        <AdminSellerCardSubTitle bold>
          Minimum Order Amount
        </AdminSellerCardSubTitle>
      </div>

      <AdminSellerCardSubTitle>
        Set the minimum amount of money for checkout per manufacturer.
      </AdminSellerCardSubTitle>
      <SellerShipInfoMinOrderForm
        handleChange={formik.handleChange}
        error={formik.errors?.minimumOrderAmount}
        value={formik.values.minimumOrderAmount}
      />

      <div style={{ marginTop: '2rem' }}>
        <AdminSellerCardSubTitle bold>Rate</AdminSellerCardSubTitle>
      </div>
      <AdminSellerCardSubTitle>
        Set how much this Manufacturer charges for shipping at checkout.
      </AdminSellerCardSubTitle>
      <AdminSellerShippingInfoRateForm formik={formik} />
      <Grid container spacing={2} style={{ marginTop: '1rem' }}>
        <Grid item xs={12} md={12}>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
