import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';
import { PUT_ADMIN_PHARMACY_SHIPPING_ADDRESS_URL } from 'rx-api/api/admin/pharmacies/constants';

type PutAdminPharmacyShippingAddressPaylaodDto = {
  address: string;
  state: string;
  city: string;
  zipCode: string;
};

export const useUpdatePharmacyShippingAddress = (id: number) => {
  const onRequest = async (data: PutAdminPharmacyShippingAddressPaylaodDto) => {
    return http.put(PUT_ADMIN_PHARMACY_SHIPPING_ADDRESS_URL(id), data);
  };

  return useMutation(onRequest, {});
};
