import React from 'react';
import { useFormik } from 'formik';
import { Button, Grid, Paper, TextField } from '@material-ui/core';

import { useUpdateContactInfo } from 'rx-api/api/general';
import { useGetPharmacistAccount } from 'rx-api';
import { useToast } from 'rx-hooks';
import { clearPhonenumber } from 'rx-utils';
import { PharmacyPersonalInfoSchema } from 'utils/validators/account-settings/pharmacy/personal-info.validator';
import { Title } from './index.styles';

type IForm = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  secondaryEmail: string;
  titleOrPosition: string;
};

export const SettingsPersonalInfo: React.FC = () => {
  const { data: accountResponse } = useGetPharmacistAccount();
  const updateAccount = useUpdateContactInfo();

  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      email: accountResponse?.data?.email || '',
      firstName: accountResponse?.data?.firstName || '',
      lastName: accountResponse?.data?.lastName || '',
      phoneNumber: accountResponse?.data?.phoneNumber || '',
      secondaryEmail: accountResponse?.data?.secondaryEmail ?? '',
      titleOrPosition: accountResponse?.data?.titleOrPosition ?? '',
    },
    enableReinitialize: true,
    validationSchema: PharmacyPersonalInfoSchema,
    onSubmit: async ({ email, ...values }: IForm) => {
      try {
        await updateAccount.mutateAsync({
          ...values,
          phoneNumber: clearPhonenumber(values.phoneNumber),
        });

        toast.onSuccessToast('Changes saved succesfully 🚀');
      } catch (err) {
        toast.onErrorToast('Error: could not save changes');
      }
    },
  });

  return (
    <Grid item md={12} lg={12}>
      <Paper style={{ padding: '1rem' }} variant="outlined">
        <Title>Personal Information</Title>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                name="firstName"
                label="First Name *"
                placeholder="First Name"
                disabled={formik.isSubmitting}
                value={formik.values.firstName}
                variant="outlined"
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="lastName"
                label="Last Name *"
                placeholder="Last Name"
                disabled={formik.isSubmitting}
                value={formik.values.lastName}
                variant="outlined"
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                name="titleOrPosition"
                label="Title/Position *"
                placeholder="Title/Position"
                value={formik.values.titleOrPosition}
                disabled={formik.isSubmitting}
                variant="outlined"
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="phoneNumber"
                label="Contact Phone Number *"
                value={formik.values.phoneNumber}
                placeholder=" (xxx) xxx-xxxx"
                disabled={formik.isSubmitting}
                variant="outlined"
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                name="email"
                label="Email *"
                placeholder="orders@adamgladieux.com"
                value={formik.values.email}
                disabled
                variant="outlined"
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="secondaryEmail"
                label="Secondary Email *"
                placeholder="secondary@email.com"
                disabled={formik.isSubmitting}
                value={formik.values.secondaryEmail}
                variant="outlined"
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <Button
                disabled={formik.isSubmitting}
                onClick={formik.submitForm}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};
