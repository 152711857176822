import { useQuery } from 'react-query';
import { IGetAccountManufacturerDataDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetAccountManufacturerData = () => {
  return useQuery(
    ['get-account-manufacturer-data'],
    async () => {
      return http.get<IGetAccountManufacturerDataDTO>(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/account/manufacturer-data`,
        { withCredentials: true }
      );
    },
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );
};
