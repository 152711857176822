import * as Yup from 'yup';

import { email } from '../common.validator';

const LoginSchema = Yup.object().shape({
  email,
  password: Yup.string().required('Please provide a password'),
});

export { LoginSchema };
