import React from 'react';

import { TabItem } from './index.styles';

export type RxTableTabProps = {
  title: string;
  active: boolean;
  value: string;
  onSetView: (value: string) => void;
};

const RxTableTab = (props: RxTableTabProps): JSX.Element => {
  return (
    <TabItem active={props.active} onClick={() => props.onSetView(props.value)}>
      {props.title}
    </TabItem>
  );
};

export { RxTableTab };
