import React from 'react';
import { MenuItem, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';

type IProps = {
  logout: () => Promise<void>;
};

export const HeaderSettingsSeller: React.FC<IProps> = (props) => {
  const history = useHistory();

  return (
    <div data-cy="settings">
      <MenuItem
        onClick={() => history.push('/settings/profile')}
        data-cy="settings-profile"
      >
        <AccountBoxOutlinedIcon style={{ marginRight: '0.5rem' }} />
        <Typography>Settings</Typography>
      </MenuItem>

      <MenuItem onClick={props.logout} data-cy="settings-logout">
        <ExitToAppOutlinedIcon style={{ marginRight: '0.5rem' }} />
        <Typography>Log out</Typography>
      </MenuItem>
    </div>
  );
};
