import React, { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

type HeaderInputProps = InputHTMLAttributes<HTMLInputElement>;

const Input = styled.input`
  ${({ theme }) => css`
    background: ${theme.colors.white[100]};
    border-radius: 50px;
    border: none;
    width: 408px;
    font-size: 18px;
    height: 50px;
    padding-left: 52px;
    outline: none;

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${theme.colors.gray[500]};
      font-family: ${theme.fonts.robotoRegular};
      font-style: normal;
      font-weight: normal;
    }
  `}
`;

const HeaderInput = (props: HeaderInputProps): JSX.Element => {
  return <Input {...props} />;
};

export { HeaderInput };
