import React, { useRef, ChangeEvent, useState, useCallback } from 'react';
import styled from 'styled-components';
import XLSX from 'xlsx';

import { useManageItemsUpload } from 'rx-contexts';

import { useAuth } from 'rx-hooks';

import { RxSimpleCheckbox } from 'rx-core';
import { Button } from '@material-ui/core';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.h2`
  font-size: ${({ theme: { sizes } }) => `${sizes.h2}`};
  font-family: ${({ theme: { fonts } }) => `${fonts.robotoLight}`};

  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgb(43, 54, 71, 0.35);
`;

const UploadInput = styled.input`
  display: none;
`;

const UploadContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;

const UploadFilename = styled.span`
  font-size: ${({ theme: { sizes } }) => `${sizes.small}`};
`;

const TemplateText = styled.span`
  font-size: ${({ theme }) => theme.sizes.medium};
  font-family: ${({ theme }) => theme.fonts.robotoLight};
  margin-left: 32px;
`;

const ReplaceCheckbox = styled(RxSimpleCheckbox)`
  margin-bottom: 1rem;
`;

const FooterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const FooterSpacer = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(43, 54, 71, 0.35);
  flex: 1;
`;

const StrongText = styled.span`
  color: ${({ theme: { colors } }) => `${colors.blue[100]}`};
  cursor: pointer;
`;

export const CsvUploadPickFile: React.FC = () => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [filename, setFilename] = useState<string>('No file chosen');

  const {
    overrideInventory,
    onStepUpload,
    onSetRawCsvData,
    onSetCsvData,
    onSetUploadModalVisible,
    onSetOverrideInventory,
    bulkUploadType,
    onSetBulkUploadType,
  } = useManageItemsUpload();

  const { type } = useAuth();

  const csvMessage =
    type === 'admin'
      ? 'Replace any product that has a duplicate on Seller’s inventory.'
      : 'Replace any product that has the same information.';

  const emptyFile =
    !inputFileRef.current ||
    (!!inputFileRef.current.files && inputFileRef.current.files?.length === 0);

  const onClickUpload = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const onSelectFile = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files && evt.target.files.length > 0) {
      const { name } = evt.target.files[0];
      setFilename(name);
    }
  };

  const onCheckFile = async () => {
    if (
      !emptyFile &&
      inputFileRef.current?.files &&
      inputFileRef.current.files.length > 0
    ) {
      const file = await inputFileRef.current.files[0].arrayBuffer();

      onSetRawCsvData(file);
      onStepUpload('PREVIEW');
    }
  };

  const onCancel = () => {
    onSetCsvData([]);
    onSetRawCsvData(null);
    onSetUploadModalVisible(false);
    onSetBulkUploadType('drug');
  };

  const downloadTemplate = useCallback(() => {
    const csvRow = {
      name: 'CHLORDIAZEPOXIDE HYDROCHLORIDE AND CLIDINIUM BROMIDE',
      description: '5mg-2.5mg 100 count',
      manufacturerName: 'ECI Pharmaceuticals',
      expirationDate: '10/20/2030',
      price: 32.0,
      units: 1000,
      lotNumber: '1901-26',
      minimumQuantity: 1,
      maximumQuantity: 10,
      productId: '',
    };

    const data = [
      bulkUploadType === 'drug' ? { ndc: '51293-607-01', ...csvRow } : csvRow,
    ];

    const ws = XLSX.utils.json_to_sheet(data);
    const csv = XLSX.utils.sheet_to_csv(ws);

    const blob = new Blob([csv], { type: 'text/plain;charset=UTF-8' });

    const csvBlob = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = csvBlob;
    link.download = 'TEMPLATE.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [bulkUploadType]);

  const onOverrideInvHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    onSetOverrideInventory(evt.target.checked);
  };

  return (
    <Container>
      <Title>Upload product from CSV file</Title>
      <UploadInput
        type="file"
        accept=".csv"
        ref={inputFileRef}
        onChange={onSelectFile}
      />
      <UploadContainer>
        <Button
          variant="outlined"
          color="primary"
          onClick={onClickUpload}
          style={{ marginRight: '1rem' }}
        >
          Choose File
        </Button>
        <UploadFilename>{filename}</UploadFilename>
      </UploadContainer>
      <ReplaceCheckbox
        name="replace"
        text={csvMessage}
        onChange={onOverrideInvHandler}
        checked={overrideInventory}
      />
      <TemplateText>
        Download a{' '}
        <StrongText onClick={downloadTemplate}>CSV TEMPLATE</StrongText> to see
        an example of the format required to upload a product.
      </TemplateText>
      <FooterSpacer />
      <FooterActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={onCancel}
          style={{ marginRight: '1rem' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={emptyFile}
          onClick={onCheckFile}
        >
          Upload File
        </Button>
      </FooterActions>
    </Container>
  );
};
