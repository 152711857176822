import React, { useEffect } from 'react';
import styled from 'styled-components';

import { IBlackListItem } from 'rx-domain';

import { useAdminProducts } from 'rx-contexts';

import { AdminProductsBlackListDataItem } from './AdminProductsBlackListDataItem';

type IProps = {
  items: IBlackListItem[];
};

const Spacer = styled.tr`
  height: 0.5rem;
`;

const AdminProductsBlackListDataItems = (props: IProps): JSX.Element => {
  const { setAllSelected, setOffCurrentSelected, selectAll } =
    useAdminProducts();

  useEffect(() => {
    const productsId = props.items.map(({ id }) => `products.${id}.selected`);

    if (selectAll) {
      setAllSelected(productsId);
    } else {
      setOffCurrentSelected(productsId);
    }
  }, [props.items, selectAll]);

  return (
    <>
      {props.items.map((item, index) => (
        <React.Fragment key={index}>
          <Spacer />
          <AdminProductsBlackListDataItem {...item} index={index} key={index} />
        </React.Fragment>
      ))}
    </>
  );
};

export { AdminProductsBlackListDataItems };
