import * as Yup from 'yup';

const MOSFulfillOrderSchema = Yup.object().shape({
  shippingCarrier: Yup.string()
    .min(2, 'Must least two characters.')
    .required('The shipping carrier is required'),
  trackingNumber: Yup.string().required('The tracking number is required'),
  trackingUrl: Yup.string()
    .url('The tracking url is invalid')
    .required('The tracking url is required'),
});

export { MOSFulfillOrderSchema };
