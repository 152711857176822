import React from 'react';
import { ShopCartItem, ShippingConfig } from 'rx-domain';

import { ShopCartDrugItem } from '../ShopCartDrugItem';
import { ShopCartCardWarning } from '../ShopCartCardWarning';
import { Container } from './index.styles';

type IProps = {
  products: ShopCartItem[];
  shipConfig: ShippingConfig;
  grantTotal: number;
  mode?: 'summary' | 'shop-cart';
};

export const ShopCartDrugItems: React.FC<IProps> = (props) => {
  const { mode = 'shop-cart' } = props;

  return (
    <Container>
      {props.products.map((product) => (
        <ShopCartDrugItem
          shopCartItem={product}
          key={product.product.id}
          mode={props.mode}
        />
      ))}

      {props.grantTotal < props.shipConfig.minimumAmount &&
        mode === 'shop-cart' && (
          <ShopCartCardWarning
            groundShipping={props.shipConfig.groundShipping}
            overnightShipping={props.shipConfig.overnightShipping}
            minimumAmount={props.shipConfig.minimumAmount}
            type="min-amount"
          />
        )}
    </Container>
  );
};
