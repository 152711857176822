import { useEffect, useState } from 'react';

import { IGetManufacturer } from 'rx-domain';

import { useGetSellers } from 'rx-api';

import { usePaginationQueries } from 'rx-hooks/utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTransactionFilterSeller = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<IGetManufacturer | null>(null);
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<IGetManufacturer[]>([]);

  const { queryBuyer } = usePaginationQueries();

  const { data: adminManufacturers, isLoading } = useGetSellers({
    approvalStatus: 'approved',
    shipper: search,
    skip: 0,
  });

  useEffect(() => {
    if (adminManufacturers?.data) {
      setOptions(adminManufacturers.data.results);
    }
  }, [adminManufacturers]);

  useEffect(() => {
    if (queryBuyer) setValue(null);
  }, [queryBuyer]);

  return {
    open,
    setOpen,
    value,
    setValue,
    search,
    setSearch,
    options,
    setOptions,
    isLoading,
  };
};

export { useTransactionFilterSeller };
