import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { ITransaction } from 'rx-domain';
import { useOrder } from 'rx-contexts';
import { useGetOrdersIdUniForm, usePutOrdersIdInvoiceStatus } from 'rx-api';
import { useToast } from 'rx-hooks/utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTransactionOrderActions = ({ order }: ITransaction) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const queryClient = useQueryClient();
  const toast = useToast();
  const { onOpenCancelModal } = useOrder();

  const downloadUniForm = useGetOrdersIdUniForm();
  const onUpdateOrderStatus = usePutOrdersIdInvoiceStatus();

  const onOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onDownloadUniversalForm = useCallback(async () => {
    try {
      const res = await downloadUniForm.mutateAsync({
        id: order.id,
      });

      const blob = new Blob([res.data], { type: 'application/pdf' });

      const csvBlob = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = csvBlob;
      link.download = 'universal-form.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      toast.onErrorToast('Error: could not download universal form');
    }
  }, [downloadUniForm, order.id, toast]);

  const onMarkAsStatus = useCallback(
    async (status: 'paid' | 'pending' | 'delinquent' | 'unrecovered') => {
      try {
        await onUpdateOrderStatus.mutateAsync({
          id: order.id,
          status,
        });

        await queryClient.refetchQueries('get-transactions', {
          active: true,
        });

        toast.onSuccessToast(`Status changed`);
      } catch (err) {
        toast.onErrorToast('Error: Could not mark order as paid');
      }
    },
    [onUpdateOrderStatus, order.id, queryClient, toast]
  );

  const onCancelOrder = useCallback(async () => {
    onOpenCancelModal(null, { idCancel: order.id, type: 'order' });
  }, [onOpenCancelModal, order.id]);

  const downloadOptions = useMemo(
    () =>
      [
        {
          type: 'UNIVERSAL_FORM',
          text: 'Download Universal Form',
          onClick: onDownloadUniversalForm,
        },
        order.status === 'pending'
          ? {
              type: 'MARK_AS_PAID',
              text: 'Mark entire order as Paid',
              onClick: () => onMarkAsStatus('paid'),
            }
          : null,
        {
          type: 'CANCEL_FULL_ORDER',
          text: 'Cancel entire order',
          onClick: onCancelOrder,
        },
      ].filter((a) => a !== null) as {
        type: string;
        text: string;
        onClick: () => Promise<void>;
      }[],
    [onCancelOrder, onDownloadUniversalForm, onMarkAsStatus, order.status]
  );

  return {
    onOpenMenu,
    onClose,
    anchorEl,
    downloadOptions,
  };
};
