import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { Grid, TextField, Button } from '@material-ui/core';
import { useAddPharmacyPaymentMethodAch } from 'rx-api';
import { useToast } from 'rx-hooks';
import { useQueryClient } from 'react-query';
import { VerifyPharmacyAchForm } from './VerifyPharmacyAchForm';

interface IProps {
  pharmacyId: number;
  onSuccess(): void;
  onClickCancel(): void;
}

export const AddPharmacyAchPaymentMethod: React.FC<IProps> = (props) => {
  const [view, setView] = React.useState<'add-ach' | 'verify-ach'>('add-ach');
  const [achId, setAchId] = React.useState<null | number>(null);
  const toast = useToast();
  const params = useParams<{ id: string }>();
  const addAch = useAddPharmacyPaymentMethodAch(params.id);
  const queryClient = useQueryClient();

  const addAchFormik = useFormik({
    initialValues: {
      accountNumber: '',
      routingNumber: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      accountNumber: Yup.string().required('Please enter the account number'),
      routingNumber: Yup.string().required('Please enter the routing number'),
    }).required(),
    onSubmit: async (values) => {
      try {
        const result = await addAch.mutateAsync({
          data: { ...values },
          setAsDefault: false,
        });
        setAchId(result.data.id);
        toast.onSuccessToast('ACH Account added!');
        await queryClient.refetchQueries('get-pharmacy-payment-methods-ach', {
          active: true,
        });
        setView('verify-ach');
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toast.onErrorToast(`Error:  ${error.response?.data.message}`);
        } else {
          toast.onErrorToast('Could not add ACH account!');
        }
      }
    },
  });

  return (
    <>
      {view === 'add-ach' && (
        <form onSubmit={addAchFormik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="accountNumber"
                variant="outlined"
                label="Account Number"
                helperText={addAchFormik.errors.accountNumber}
                error={!!addAchFormik.errors.accountNumber}
                value={addAchFormik.values.accountNumber}
                onChange={addAchFormik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="routingNumber"
                variant="outlined"
                label="Routing Number"
                helperText={addAchFormik.errors.routingNumber}
                error={!!addAchFormik.errors.routingNumber}
                value={addAchFormik.values.routingNumber}
                onChange={addAchFormik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
              />
            </Grid>
          </Grid>

          <div
            style={{
              padding: '1rem 0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={props.onClickCancel}
              disabled={addAch.isLoading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={addAch.isLoading}
            >
              Add
            </Button>
          </div>
        </form>
      )}

      {view === 'verify-ach' && (
        <VerifyPharmacyAchForm
          pharmacyId={props.pharmacyId}
          achId={achId || 0}
          onSuccess={props.onSuccess}
          onClickCancel={props.onClickCancel}
        />
      )}
    </>
  );
};
