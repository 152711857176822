import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useTransactions } from 'rx-contexts';

import { capitalize } from 'rx-utils';

import { useDownloadFile } from 'rx-hooks';

import { RxButton } from 'rx-core';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const Title = styled.h2`
  font-size: ${({ theme: { sizes } }) => `${sizes.h2}`};
  font-family: ${({ theme: { fonts } }) => `${fonts.robotoLight}`};
  padding-bottom: 16px;
  text-align: center;
`;

const TransactionGenerateSuccess = (): JSX.Element => {
  const { onDownloadByLink } = useDownloadFile();

  const { onSetModalGenerate, onInit, url, typeDoc } = useTransactions();

  const getTitle = useMemo(() => {
    switch (typeDoc) {
      case 'CONSOLIDATED-INVOICE':
        return 'invoice';
      case 'CONSOLIDATED-RECEIPT':
        return 'receipt';
      case 'SALE-ORDER-INVOICE':
        return 'invoice';
      default:
        return 'invoice';
    }
  }, [typeDoc]);

  const getFileTitle = useMemo(() => {
    switch (typeDoc) {
      case 'CONSOLIDATED-INVOICE':
        return 'invoice.pdf';
      case 'CONSOLIDATED-RECEIPT':
        return 'receipt.pdf';
      case 'SALE-ORDER-INVOICE':
        return 'sale-order-invoice.pdf';
      default:
        return 'CONSOLIDATED-invoice.pdf';
    }
  }, [typeDoc]);

  const onDowloadFile = () => {
    onSetModalGenerate(false);
    onInit();
    onDownloadByLink(url, getFileTitle, true);
  };

  return (
    <Container>
      <Title>{capitalize(getTitle)} ready!</Title>
      <RxButton
        text={`Download ${capitalize(getTitle)}`}
        width="180px"
        onClick={onDowloadFile}
      />
    </Container>
  );
};

export { TransactionGenerateSuccess };
