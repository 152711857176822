import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 440,
      overflow: 'scroll-y',
      '&::-webkit-scrollbar': {
        display: 'block',
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '10px',
        backgroundColor: '#F0F0F0',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        backgroundColor: '#B0B0B0',
      },
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableHead: {
      backgroundColor: '#F7F9FC',
    },
    paid: {
      background: '#DDE0E4',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    refunded: {
      background: '#DDE0E4',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    cancelled: {
      color: 'white',
      background: '#FF6969',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    pending: {
      color: 'white',
      background: '#FF6969',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    fulfilled: {
      background: '#DDE0E4',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    unfulfilled: {
      background: '#FFEA93',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    bodyRow: {
      cursor: 'pointer',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);
