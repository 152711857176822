import React from 'react';
import { Grid } from '@material-ui/core';

import { BannerTitle, SEO, TemplateView } from 'rx-core';

import { SettingsNav } from '../../../shared/SettingsNav';

import { Container, PageContainer, SettingsList, Title } from './index.styles';

type IProps = {
  pageTitle: string;
  links: Array<{
    text: string;
    to: string;
    dataCy: string;
  }>;
};

export const SettingsTemplate: React.FC<IProps> = ({
  children,
  links,
  pageTitle,
}): JSX.Element => {
  return (
    <TemplateView>
      <SEO title={pageTitle} />
      <div style={{ padding: 16, flexGrow: 1 }}>
        <Container container wrap="nowrap" spacing={2}>
          <SettingsList item xs={3}>
            <Grid item xs={12}>
              <SettingsNav links={links} />
            </Grid>
          </SettingsList>
          <PageContainer item xs={9}>
            {children}
          </PageContainer>
        </Container>
      </div>
    </TemplateView>
  );
};
