import { useMutation } from 'react-query';
import { IPutBillingInformationDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePutManufacturerBillingInformation = () => {
  return useMutation(
    ['update-manufacturer-billing-information'],
    async (request: IPutBillingInformationDTO) => {
      return http.put(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/manufacturer/billing-information`,
        request
      );
    },
    {}
  );
};
