import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useGetPharmacistPharmacyById } from 'rx-api';
import { Alert } from '@material-ui/lab';

import { SettingsTemplate } from '../settingsV2/SettingsTemplate';
import { SettingsPharmacy } from './SettingsPharmacy';
import { Separator } from '../settingsV2/index.styles';
import { SettingsTradeRef } from './SettingsPharmacyTradeReferences';
import { SettingsPharmacyLegalDocuments } from './SettingsPharmacyLegalDocuments';
import { settingsBuyerTabs } from '../settingsV2/SettingsTemplate/tabs';
import { SettingsBillingInfo } from './SettingsBillingInfo';
import { SettingsShipAddress } from './SettingsShipAddress';
import { PharmacyMemberList } from './PharmacyMemberList';
import { PharmacyPaymentMethods } from './PharmacyPaymentMethods';

export const SettingsPharmacyByIdView: React.FC = () => {
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useGetPharmacistPharmacyById(params.id);

  const disabled = !isLoading && data?.data.approvalStatus !== 'approved';

  return (
    <SettingsTemplate
      data-cy="account-settings"
      pageTitle="RxRise | Profile"
      links={settingsBuyerTabs()}
    >
      {disabled && (
        <Alert severity="warning" style={{ marginBottom: '1rem' }}>
          Edition is disabled because pharmacy is pending of approval, rejected
          or suspended.
        </Alert>
      )}

      <Grid item xs={12}>
        <SettingsPharmacy data={data?.data} disabled={disabled} />
      </Grid>

      <Grid item xs={12}>
        <Separator />
      </Grid>

      <Grid item xs={12}>
        <SettingsBillingInfo
          data={data?.data.billingInformation || null}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12}>
        <Separator />
      </Grid>

      <Grid item xs={12}>
        <SettingsShipAddress
          data={data?.data.shippingAddress || null}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12}>
        <Separator />
      </Grid>

      <Grid item xs={12}>
        <SettingsPharmacyLegalDocuments
          data={data?.data.legalDocuments || []}
          isLoading={isLoading}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12}>
        <Separator />
      </Grid>

      <Grid item xs={12}>
        <SettingsTradeRef data={data?.data} disabled={disabled} />
      </Grid>

      <Grid item xs={12}>
        <Separator />
      </Grid>

      <Grid item xs={12}>
        <PharmacyMemberList />
      </Grid>

      <Grid item xs={12}>
        <Separator />
      </Grid>

      <Grid item xs={12}>
        <PharmacyPaymentMethods disabled={disabled} />
      </Grid>
    </SettingsTemplate>
  );
};
