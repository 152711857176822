import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: white;
  padding: 1rem;

  justify-content: space-between;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > div:first-child {
    margin-right: 1rem;
  }
`;
