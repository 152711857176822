import styled, { css } from 'styled-components';

import { Chip } from '@material-ui/core';
import { ThemeType } from 'rx-styles';

const getStatusColor = (status: string, { colors }: ThemeType): string => {
  switch (status) {
    case 'approved':
      return colors.green[100];
    case 'pending':
      return colors.yellow[200];
    case 'suspended':
      return colors.gray[400];
    case 'rejected':
      return colors.red[100];
    default:
      return colors.gray[400];
  }
};

export const PharmacyStatusChip = styled(Chip)<{
  status: string;
}>`
  ${({ theme, status }) => css`
    &.MuiChip-outlined {
      border: 1px solid ${getStatusColor(status, theme)};
      background-color: white;
    }

    .MuiChip-label {
      color: ${getStatusColor(status, theme)};
    }
  `}
`;
