import { GetFavorites } from 'rx-domain';

import { useGetRequest } from 'rx-api/utils';

import { GET_FAVORITES_KEY, GET_FAVORITES_URL } from './constants';

const useGetFavorites = () => {
  return useGetRequest<unknown, GetFavorites.Response>({
    url: GET_FAVORITES_URL,
    key: [GET_FAVORITES_KEY],
  });
};

export { useGetFavorites };
