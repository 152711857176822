import styled from 'styled-components';

export const TableRow = styled.tr`
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;

  background: rgb(255, 255, 255);
  box-shadow: 5px 5px 25px rgba(51, 60, 68, 0.08);
  border-radius: 10px;
  height: 76px;
`;
