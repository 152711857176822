import { GetSaleOrderPayments } from 'rx-domain';
import {
  GET_SALE_ORDER_PAYMENTS_KEY,
  GET_SALE_ORDER_PAYMENTS_URL,
} from 'rx-api/api/admin/sale-order-payments/constants';
import { useGetRequest } from 'rx-api/utils';

const makeParams = (req: Partial<GetSaleOrderPayments.Query>) => {
  let query = `?limit=9999999`;

  if (req.limit) {
    query = `?limit=${req.limit}`;
  }

  if (req.skip) {
    query += `&skip=${req.skip}`;
  }

  if (req.sellerId) {
    query += `&sellerId=${req.sellerId}`;
  }

  if (req.buyerId) {
    query += `&buyerId=${req.buyerId}`;
  }

  if (req.sortBy) {
    query += `&sortBy=${req.sortBy}`;
  }

  if (req.sortOrder) {
    query += `&sortOrder=${req.sortOrder}`;
  }

  if (req.status) {
    query += `&status=${req.status}`;
  }

  if (req.to) {
    query += `&to=${req.to}`;
  }

  if (req.from) {
    query += `&from=${req.from}`;
  }

  if (req.edi) {
    query += `&type=${req.edi}`;
  }

  return query;
};

const useGetSaleOrdePayments = (query: Partial<GetSaleOrderPayments.Query>) => {
  const { data: response, ...rest } = useGetRequest<
    Partial<GetSaleOrderPayments.Query>,
    GetSaleOrderPayments.Response
  >({
    url: GET_SALE_ORDER_PAYMENTS_URL,
    key: [
      GET_SALE_ORDER_PAYMENTS_KEY,
      query.limit,
      query.skip,
      query.sellerId,
      query.buyerId,
      query.sortBy,
      query.sortOrder,
      query.status,
      query.to,
      query.from,
      query.edi,
    ],
    makeParams,
    params: query,
  });

  if (!response?.data) {
    return { ...rest, data: response?.data };
  }

  const { pagination, meta } = response.data;

  const results = response.data.results.map((result) => {
    const paymentType = result.paymentType
      .split('_')
      .map((payment) => payment.toUpperCase())
      .join(' ');

    return { ...result, paymentType };
  });

  return { ...rest, data: { results, pagination, meta } };
};

export { useGetSaleOrdePayments };
