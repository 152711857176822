import React from 'react';
import styled from 'styled-components';

import PillOne from '../../../../assets/images/random/pill-one.png';

const Img = styled.img`
  width: 132px;
  height: 58px;

  position: absolute;
  bottom: 280px;
  left: calc(15vw - 66px);
  display: none;
`;

const FooterPillIconOne = (): JSX.Element => {
  return <Img src={PillOne} />;
};

export { FooterPillIconOne };
