import { useMutation } from 'react-query';

import { IPostOrderInvoiceDTO, IPostOrderInvoiceResponse } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePostOrdersInvoice = () => {
  const onRequest = async ({ id }: IPostOrderInvoiceDTO) => {
    return http.post<IPostOrderInvoiceResponse>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/orders/${id}/invoice`,
      {}
    );
  };

  return useMutation(onRequest, {});
};

export { usePostOrdersInvoice };
