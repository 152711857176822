import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Paper, TableBody } from '@material-ui/core';
import React, { useState } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { useGetEdiInventoryTransmitLogs } from 'rx-api/api/admin/logs/useGetEdiInventoryTransmitLogs';
import { EdiTableLoader } from './EdiTableLoader';
import { EdiTableEmpty } from './EdiTableEmpty';
import { EdiTableRows } from './EdiTableRows';
import { useStyles } from './useStyles';

interface Data {
  id: number;
  status: string;
  externalId: string | null;
  productCount: number;
  createdAt: string;
  updatedAt: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  sortable: boolean;
}

const EDICells: HeadCell[] = [
  {
    id: 'externalId',
    numeric: false,
    disablePadding: false,
    label: 'EDI ID',
    sortable: false,
  },
  {
    id: 'productCount',
    numeric: false,
    disablePadding: false,
    label: 'Product Count',
    sortable: false,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created On',
    sortable: false,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    sortable: false,
  },
];

export const EdiInventoryTransmitLogs: React.FC = (props) => {
  const [skip, setSkip] = useState<number>(0);
  const [currentPage, setCurrentPAge] = useState<number>(0);
  const [limit] = useState<number>(20);

  const classes = useStyles();

  const { data: logsData, isLoading } = useGetEdiInventoryTransmitLogs({
    limit,
    skip,
  });

  return (
    <Paper>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHead className={classes.tableHead}>
            <TableRow>
              {EDICells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                >
                  <>{headCell.label}</>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <EdiTableLoader />}

            {!isLoading && !logsData?.data?.results.length && <EdiTableEmpty />}

            {!isLoading && (
              <EdiTableRows results={logsData?.data?.results || []} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[15]}
        count={logsData?.data?.pagination?.total || 0}
        rowsPerPage={limit}
        page={currentPage}
        onPageChange={(event, page) => {
          setCurrentPAge(page);
          setSkip(page * limit);
        }}
        onRowsPerPageChange={() => {}}
      />
    </Paper>
  );
};
