import { useGetRequest } from 'rx-api/utils';
import { BUYER_URL_V1 } from '../constants';

export const usePharmacistGetPharmacyMemberList = (id: number | string) => {
  return useGetRequest<
    unknown,
    [
      {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        role: string;
      }
    ]
  >({
    url: `${BUYER_URL_V1}/pharmacies/${id}/members`,
    key: ['get-pharmacist-pharmacy-members'],
  });
};
