import React from 'react';
import { useQueryClient } from 'react-query';
import { Grid, Paper, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import { useUpdatePharmacyApprovalStatus } from 'rx-api';
import { useToast } from 'rx-hooks';

import { ApproveCardActions } from '../../atoms/card-actions';

interface IApprovePharmacyCardProps {
  title: string;
  data: {
    id: number;
    dea: string | null;
    stateLicense: string | null;
    pharmacyName: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    titleOrPosition: string;
    address: {
      address: string;
      city: string;
      state: string;
      zipCode: string;
    };
    legalDocuments: Array<{
      id: number;
      name: string;
      url: string;
    }>;
  };
}

export const ApprovePharmacyCard: React.FC<IApprovePharmacyCardProps> = (
  props
) => {
  const { onSuccessToast, onErrorToast } = useToast();
  const updateApprovalStatus = useUpdatePharmacyApprovalStatus();

  const queryClient = useQueryClient();

  const DEA_FILE = props.data.legalDocuments.find((af) => af.name === 'dea');
  const STATE_LICENSE_FILE = props.data.legalDocuments.find(
    (af) => af.name === 'state_license'
  );

  const onApprove = async () => {
    try {
      await updateApprovalStatus.mutateAsync({
        id: props.data.id,
        approvalStatus: 'approved',
      });
      await queryClient.refetchQueries('get-pharmacies', {
        active: true,
      });

      onSuccessToast('Pharmacy Approved Successfully ✅');
    } catch (err) {
      onErrorToast('Could not approve pharmacy');
    }
  };

  const onReject = async () => {
    try {
      await updateApprovalStatus.mutateAsync({
        id: props.data.id,
        approvalStatus: 'rejected',
      });
      await queryClient.refetchQueries('get-pharmacies', {
        active: true,
      });

      onSuccessToast('Pharmacy Rejected');
    } catch (err) {
      onErrorToast('Could not reject pharmacy');
    }
  };

  return (
    <Paper>
      <div style={{ padding: '1rem' }}>
        <header style={{ paddingBottom: '1rem' }}>
          <Typography variant="h5">{props.title}</Typography>
        </header>

        {DEA_FILE && STATE_LICENSE_FILE && (
          <div style={{ marginBottom: '1.5rem' }}>
            <div style={{ marginBottom: '0.75rem' }}>
              <Typography variant="subtitle1">Legal Documents</Typography>
            </div>

            <Grid container spacing={4}>
              <a href={DEA_FILE?.url}>
                <div style={{ display: 'flex', padding: '0.5rem' }}>
                  <GetAppIcon />
                  <Typography>Dea</Typography>
                </div>
              </a>

              <a href={STATE_LICENSE_FILE?.url}>
                <div style={{ display: 'flex', padding: '0.5rem' }}>
                  <GetAppIcon />
                  <Typography>State License</Typography>
                </div>
              </a>
            </Grid>
          </div>
        )}

        <Typography variant="h6">Personal Information</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            First Name
          </Grid>
          <Grid item xs={6}>
            {props.data.firstName}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            Last Name
          </Grid>
          <Grid item xs={6}>
            {props.data.lastName}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            Title or Position
          </Grid>
          <Grid item xs={6}>
            {props.data.titleOrPosition}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            Phone Number
          </Grid>
          <Grid item xs={6}>
            {props.data.phoneNumber}
          </Grid>
        </Grid>

        <div style={{ marginTop: '1.5rem' }}>
          <Typography variant="h6">Pharmacy Information</Typography>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            Company Name
          </Grid>
          <Grid item xs={6}>
            {props.data.pharmacyName}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            Street Address
          </Grid>
          <Grid item xs={6}>
            {props.data.address.address}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            State
          </Grid>
          <Grid item xs={6}>
            {props.data.address.state}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            City
          </Grid>
          <Grid item xs={6}>
            {props.data.address?.city}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            Zip Code
          </Grid>
          <Grid item xs={6}>
            {props.data.address.zipCode}
          </Grid>
        </Grid>

        <footer style={{ marginTop: '1rem' }}>
          <ApproveCardActions
            onApprove={onApprove}
            onReject={onReject}
            disabled={updateApprovalStatus.isLoading}
          />
        </footer>
      </div>
    </Paper>
  );
};
