import * as Yup from 'yup';
import { address, state, city, zipCode } from '../common.validator';

const ManufacturerBusinessSchema = Yup.object().shape({
  companyName: Yup.string().required('Required'),
  address,
  state,
  city,
  zipCode,
});

export { ManufacturerBusinessSchema };
