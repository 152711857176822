import React from 'react';
import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

import { LoginCard, VerificationTitle } from '../atoms';

type VerificationFormTemplateProps = LayoutProps & {
  children: React.ReactNode;
};

const Container = styled.div<LayoutProps>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${layout};

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipadPro')} {
      justify-content: flex-start;
      padding-top: 82px;
    }
  `}
`;

const FormColumn = styled.div`
  width: 100%;
  padding: 40px 118px 40px 92px;
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipadPro')} {
      width: 100%;
      padding: 40px 40px 0 94px;
    }

    ${lessThan('ipadPro')} {
      padding: 24px;
    }
  `}
`;

const CustomCard = styled(LoginCard)`
  height: 472px;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipad')} {
      width: 95%;
    }
  `}
`;

const TitleContainer = styled.div`
  width: 920px;
  padding-bottom: 36px;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipad')} {
      width: 100%;
    }
  `}
`;

const CustomVerificationTitle = styled(VerificationTitle)`
  padding-left: 118px;

  ${({ theme: { sizes, lessThan } }) => css`
    ${lessThan('ipadPro')} {
      padding-left: 24px;
      font-size: ${sizes.h2};
    }
  `}
`;

export const VerificationFormTemplate = ({
  children,
  ...props
}: VerificationFormTemplateProps): JSX.Element => {
  return (
    <Container {...props} data-cy="2fa-form">
      <TitleContainer>
        <CustomVerificationTitle />
      </TitleContainer>
      <CustomCard>
        <FormColumn>{children}</FormColumn>
      </CustomCard>
    </Container>
  );
};
