import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 8rem;
  display: flex;
  flex-direction: row;
  height: 1.5rem;
  align-items: center;
  position: relative;
`;

export const Input = styled.input<{ error: boolean }>`
  width: 5rem;
  height: 100%;
  border: 1px solid #9fa6b9;
  border-left: none;
  border-right: none;
  text-align: center;

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.colors.red['200']};
      color: ${theme.colors.red['200']};
    `}
`;

export const PlusButton = styled.button`
  ${({ theme }) => css`
    border-radius: 0 8px 8px 0;
    background: white;
    border: 1px solid #9fa6b9;
    width: 1.5rem;
    height: 100%;

    font-size: ${theme.sizes.medium};
    cursor: pointer;
  `}
`;

export const MinusButton = styled(PlusButton)`
  border-radius: 8px 0 0 8px;
`;

export const TooltipContent = styled.div`
  width: 8rem;
  display: flex;
  height: 1.5rem;
`;
