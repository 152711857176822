import React from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Search as SearchIcon } from '@material-ui/icons';

import { getSearchBuyerLoading } from 'rx-store';

import { usePaginationQueries } from 'rx-hooks';

import { onMakeURL } from 'rx-utils';

const Container = styled.button`
  ${({ theme }) => css`
    width: 8.75rem;
    border: none;
    background-color: ${theme.colors.blue[300]};
    border: 1px solid ${theme.colors.blue[300]};
    border-radius: 0 4px 4px 0;
    color: white;
    text-transform: uppercase;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: 100%;
    font-size: 12px;
    cursor: pointer;

    &:disabled {
      background-color: rgb(159, 166, 185, 0.7);
      border: none;
      cursor: no-drop;
    }

    ${theme.lessThan('ipadPro')} {
      width: 20%;
      font-size: ${theme.sizes.extraSmall};
    }
  `}
`;

const HiddenText = styled.span`
  ${({ theme }) => css`
    margin-left: 0.25rem;

    ${theme.lessThan('ipadPro')} {
      display: none;
    }
  `}
`;

const Icon = styled(SearchIcon)`
  ${({ theme }) => css`
    margin-right: 0.5rem;

    ${theme.lessThan('ipadPro')} {
      &.MuiSvgIcon-root {
        //display: none;
      }
    }
  `}
`;

const NewSearchFindButton = (): JSX.Element => {
  const history = useHistory();

  const {
    querySearchType,
    queryViewType,
    querySortBy,
    querySortOrder,
    queryMinPrice,
    queryMaxPrice,
  } = usePaginationQueries();

  const loading = useSelector(getSearchBuyerLoading);

  const onRefetch = () => {
    const searchText = document.getElementById(
      'search-buyer-name'
    ) as HTMLInputElement;

    if (searchText) {
      const searchURL = onMakeURL({
        viewType: queryViewType,
        o: querySortOrder,
        b: querySortBy,
        minPrice: queryMinPrice,
        maxPrice: queryMaxPrice,
        search: searchText.value.trim(),
        t: querySearchType,
      });

      history.push(`/products/${searchURL}`);
    }
  };

  return (
    <Container
      disabled={loading}
      onClick={onRefetch}
      data-cy="search-products-btn"
    >
      <Icon /> <HiddenText> Search</HiddenText>
    </Container>
  );
};

export { NewSearchFindButton };
