import React, { useState } from 'react';
import styled from 'styled-components';

import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { RxInputText } from '../../inputs/RxInputText';
import { RxTextarea } from '../../inputs/RxTextarea';
import { RxButton } from '../../buttons/RxButton';
import { RRModal } from '../RRModal/RRModal';
import FormikErrorsContext from '@rxrise/drugs-marketplace/src/pages/general/signup-buyer/context/FormikErrorsContext';

const Modal = styled(RRModal)`
  width: 600px;
  height: fit-content;
`;

const Column = styled.div`
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const FormColumn = styled(Column)`
  padding: 66px 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.h2`
  font-size: 24px;
  line-height: 28px;
  color: #2b3647;
  margin-bottom: 10px;
`;

const SubTitle = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #838ca5;
  margin-bottom: 12px;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
`;

const MessageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MessageTitle = styled.h2`
  font-family: Roboto Light;
  font-size: 40px;
  margin-bottom: 40px;
`;

const MessageAction = styled.span`
  cursor: pointer;
  color: black;
  text-decoration: underline;
  font-size: 18px;
`;

const SendEmailNotified = Yup.object().shape({
  name: Yup.string().required('Please enter your name.'),
  message: Yup.string().required('Make sure to fill the field.'),
  email: Yup.string()
    .email('The email format is invalid.')
    .required('Type an email.'),
});

export type ContactModalProps = {
  open: boolean;
  onClose: () => void;
};

type IForm = {
  email: string;
  name: string;
  message: string;
};

const getErrorFields = (errors: any): string[] => {
  let errorFields: string[] = [];

  for (let key in errors) {
    if (typeof errors[key] === 'string') {
      errorFields.push(key);
    } else if (typeof errors[key] === 'object' && errors[key] !== null) {
      errorFields = errorFields.concat(
        getErrorFields(errors[key]).map((subKey) => `${key}.${subKey}`)
      );
    }
  }

  return errorFields;
};

const ContactModal = ({ open, onClose }: ContactModalProps): JSX.Element => {
  const errors = React.useContext(FormikErrorsContext);

  const initialData: IForm = {
    email: '',
    name: '',
    message: '',
  };

  const [status, setStatus] = useState<string>('');

  const onSubmit = async (data: IForm, { resetForm }: FormikHelpers<IForm>) => {
    try {
      await fetch(
        'https://emails-to-adam.herokuapp.com/v1/email/website-form',
        {
          method: 'POST',
          body: new URLSearchParams(data),
          mode: 'no-cors',
        }
      );

      setStatus('SUCCESS');

      resetForm({});
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setStatus('ERROR');
    }
  };

  const onCloseModal = () => {
    onClose();
    setStatus('');
  };

  const handleAttachButton = (setFieldValue: any, values: any) => {
    setFieldValue(
      'message',
      values.message +
        '\n Fields with invalid data:\n' +
        getErrorFields(errors).join('\n')
    );
  };

  return (
    <Modal visible={open} onClose={onCloseModal}>
      <Container>
        {status && (
          <FormColumn>
            {status === 'SUCCESS' && (
              <MessageContainer>
                <MessageTitle>Thank you!</MessageTitle>
                <MessageAction onClick={onCloseModal}>Close Page</MessageAction>
              </MessageContainer>
            )}
          </FormColumn>
        )}
        {!status && (
          <FormColumn>
            <Title>Hi, there!</Title>
            <SubTitle>
              Welcome to RxRise. Our team is happy to help with any questions
              you may have.
            </SubTitle>
            <Formik
              initialValues={initialData}
              validationSchema={SendEmailNotified}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form>
                  <RxInputText
                    name="name"
                    placeholder="Your Name"
                    tabIndex={0}
                  />
                  <RxInputText
                    name="email"
                    type="emaail"
                    placeholder="your@email.com"
                    tabIndex={0}
                  />
                  <button
                    type="button"
                    onClick={() => handleAttachButton(setFieldValue, values)}
                  >
                    Attach Error logs to message
                  </button>
                  <RxTextarea
                    name="message"
                    cols={12}
                    rows={4}
                    placeholder="Your Message"
                  />
                  <ButtonContainer>
                    <RxButton
                      text="Confirm"
                      width="140px"
                      type="submit"
                      loading={isSubmitting}
                    />
                  </ButtonContainer>
                </Form>
              )}
            </Formik>
          </FormColumn>
        )}
      </Container>
    </Modal>
  );
};

ContactModal.defaultProps = {
  open: false,
  onClose: () => {},
};

export { ContactModal };
