import { FONTS, LIGHT, SIZES } from './themes';

export const BREAKPOINTS = {
  desktop: '968px',
  ipad: '834px',
  ipadPro: '768px',
  mobile: '414px',
};

const greaterThan = (
  breakpoint: keyof typeof BREAKPOINTS,
  vertical = false
): string => {
  return `@media (min-${vertical ? 'height' : 'width'}: calc(${
    BREAKPOINTS[breakpoint]
  } + 0.02px))`;
};

const lessThan = (
  breakpoint: keyof typeof BREAKPOINTS,
  vertical = false
): string => {
  return `@media (max-${vertical ? 'height' : 'width'}: ${
    BREAKPOINTS[breakpoint]
  })`;
};

const between = (
  breakpointMin: keyof typeof BREAKPOINTS,
  breakpointMax: keyof typeof BREAKPOINTS,
  vertical = false
): string => {
  return `@media (max-${vertical ? 'height' : 'width'}: ${
    BREAKPOINTS[breakpointMax]
  }) and (min-${vertical ? 'height' : 'width'}: calc(${
    BREAKPOINTS[breakpointMin]
  } + 0.02px))`;
};

const theme = {
  greaterThan,
  lessThan,
  between,
  fonts: FONTS,
  sizes: SIZES,
  ...LIGHT,
};

export { getSizeValue } from './sizes';

export { GlobalStyle } from './global';

export type { Colors } from './colors';
export * from './colors';

export type { Fonts } from './fonts';

export type { Sizes } from './sizes';

export type ThemeType = typeof theme;

export { theme };
