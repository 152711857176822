import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { upperFirst } from 'lodash';

import { usePharmacistGetPharmacyList } from 'rx-api';

import { PharmacyStatusChip } from 'pages/general/PharmacyStatusChip';
import { SettingsTemplate } from '../settingsV2/SettingsTemplate';
import { PharmacistPharmacyListLoading } from './PharmacistPharmacyListLoading';
import { settingsBuyerTabs } from '../settingsV2/SettingsTemplate/tabs';
import { TableColumn } from '../shopping-cart/ShopCartCardWarning/index.styles';

interface IPharmacyDto {
  id: number;
  pharmacyName: string;
  approvalStatus: string;
  dbaName: string;
  billingInformation: {
    name: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
  } | null;
  shippingAddress: {
    address: string;
    city: string;
    state: string;
    zipCode: string;
  } | null;
}

export const SettingsPharmacyListView: React.FC = () => {
  const { data, isLoading } = usePharmacistGetPharmacyList();

  return (
    <SettingsTemplate
      data-cy="account-settings"
      pageTitle="RxRise | Profile"
      links={settingsBuyerTabs()}
    >
      {isLoading && <PharmacistPharmacyListLoading />}

      {!isLoading && (
        <>
          <header style={{ marginBottom: '1rem' }}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                marginBottom: '1.5rem',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h5">Pharmacy Management</Typography>

              <Link
                to="/settings/pharmacies/add"
                style={{ textDecoration: 'none' }}
              >
                <Button variant="contained" color="primary">
                  Add Pharmacy
                </Button>
              </Link>
            </div>

            <Typography variant="h6">Manage Pharmacies</Typography>
            <Typography>
              If you have more than one pharmacy you can manage all of them here
              in this space. Edit, add or earse as needed.
            </Typography>
          </header>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Pharmacy Name</TableCell>
                  <TableCell>Doing Business As</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.data?.result.map((pharmacy: IPharmacyDto) => {
                  return (
                    <TableRow key={pharmacy.id}>
                      <TableColumn>{pharmacy.pharmacyName}</TableColumn>

                      <TableColumn>
                        {pharmacy.dbaName || pharmacy.pharmacyName}
                      </TableColumn>

                      <TableColumn>
                        <PharmacyStatusChip
                          status={pharmacy.approvalStatus}
                          label={upperFirst(pharmacy.approvalStatus)}
                          variant="outlined"
                        />
                      </TableColumn>

                      <TableColumn style={{ textAlign: 'right' }}>
                        {pharmacy.approvalStatus === 'approved' && (
                          <Link
                            to={`/settings/pharmacies/${pharmacy.id}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <Button color="primary" variant="outlined">
                              EDIT
                            </Button>
                          </Link>
                        )}
                      </TableColumn>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </SettingsTemplate>
  );
};
