import React, { ChangeEvent } from 'react';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { useToast } from 'rx-hooks';
import { US_STATES_WITH_CODES, pharmacyTypeList } from 'rx-utils';
import { useRxMutation } from 'rx-api/utils';

import { BUYER_URL_V1 } from 'rx-api/api/pharmacist/constants';
import { SettingsTemplate } from '../settingsV2/SettingsTemplate';
import { Separator } from '../settingsV2/index.styles';
import { settingsBuyerTabs } from '../settingsV2/SettingsTemplate/tabs';

const usePharmacistCreatePharmacy = () => {
  return useRxMutation<unknown, unknown>({
    method: 'post',
    url: `${BUYER_URL_V1}/pharmacies`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

interface IForm {
  pharmacyName: string;
  dbaName: string;
  type: string;
  phoneNumber: string;
  faxNumber: string;
  npi: string;
  yearsInBusiness: string;
  primaryWholesaler: string;
  account1: string;
  secondaryWholesaler: string;
  account2: string;
  taxId: string;
  billingInformationName: string;
  billingAddress: string;
  billingAddressCity: string;
  billingAddressState: string;
  billingAddressZipCode: string;
  shippingAddressStreet: string;
  shippingAddressCity: string;
  shippingAddressState: string;
  shippingAddressZipCode: string;
  sameAsBilling: boolean;
  stateLicense: File | undefined;
  dea: File | undefined;
  deaNumber: string;
  stateLicenseNumber: string;
}

export const SettingsNewPharmacyView: React.FC = () => {
  const createPharmacy = usePharmacistCreatePharmacy();
  const toast = useToast();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      pharmacyName: '',
      dbaName: '',
      type: '1',
      phoneNumber: '',
      faxNumber: '',
      npi: '',
      yearsInBusiness: '',
      primaryWholesaler: '',
      account1: '',
      secondaryWholesaler: '',
      account2: '',
      taxId: '',
      billingInformationName: '',
      billingAddress: '',
      billingAddressCity: '',
      billingAddressState: 'AK',
      billingAddressZipCode: '',
      shippingAddressStreet: '',
      shippingAddressCity: '',
      shippingAddressState: 'AK',
      shippingAddressZipCode: '',
      sameAsBilling: false,
      dea: undefined,
      deaNumber: '',
      stateLicense: undefined,
      stateLicenseNumber: '',
    },
    onSubmit: async ({ sameAsBilling, ...values }: IForm) => {
      if (!values.dea) return;
      if (!values.stateLicense) return;

      try {
        const formData: FormData = new FormData();
        const type = parseInt(values.type, 10);
        const faxNumber = values.faxNumber
          .replace(/[{()}]/g, '')
          .replaceAll(/\s/g, '');
        const phoneNumber = values.phoneNumber
          .replace(/[{()}]/g, '')
          .replaceAll(/\s/g, '');

        const shippingAddressStreet = sameAsBilling
          ? values.billingAddress
          : values.shippingAddressStreet;
        const shippingAddressCity = sameAsBilling
          ? values.billingAddressCity
          : values.shippingAddressCity;
        const shippingAddressState = sameAsBilling
          ? values.billingAddressState
          : values.shippingAddressState;
        const shippingAddressZipCode = sameAsBilling
          ? values.billingAddressZipCode
          : values.shippingAddressZipCode;

        formData.append('pharmacyName', values.pharmacyName);
        formData.append('dbaName', values.dbaName);
        formData.append('npi', values.npi);
        formData.append('yearsInBusiness', values.yearsInBusiness);
        formData.append('primaryWholesaler', values.primaryWholesaler);
        formData.append('account1', values.account1);
        formData.append('secondaryWholesaler', values.secondaryWholesaler);
        formData.append('account2', values.account2);
        formData.append('taxId', values.taxId);
        formData.append(
          'billingInformationName',
          values.billingInformationName
        );
        formData.append('billingAddress', values.billingAddress);
        formData.append('billingAddressCity', values.billingAddressCity);
        formData.append('billingAddressState', values.billingAddressState);
        formData.append('billingAddressZipCode', values.billingAddressZipCode);
        formData.append('shippingAddressStreet', shippingAddressStreet);
        formData.append('shippingAddressCity', shippingAddressCity);
        formData.append('shippingAddressState', shippingAddressState);
        formData.append(
          'shippingAddressZipCode',
          shippingAddressZipCode.toString()
        );
        formData.append('type', type.toString());
        formData.append('faxNumber', faxNumber);
        formData.append('phoneNumber', phoneNumber);
        formData.append('dea', values.dea, 'dea');
        formData.append('deaNumber', values.deaNumber);
        formData.append('stateLicenseNumber', values.stateLicenseNumber);
        formData.append('stateLicense', values.stateLicense, 'stateLicense');
        formData.append('deaTypeFile', values.dea.type);
        formData.append('stateTypeFile', values.stateLicense.type);
        await createPharmacy.mutateAsync(formData);
        toast.onSuccessToast(
          'Pharmacy added successfully! Wait for pharmacy to be approved. 🚀'
        );
        history.push('/settings/pharmacies');
      } catch (err) {
        toast.onErrorToast('Error: could not add pharmacy');
      }
    },
  });

  const handleChangeDea = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files;
    if (file && file.length > 0) {
      reader.readAsDataURL(file[0]);
      formik.setFieldValue('dea', file[0]);
    } else {
      formik.setFieldValue('dea', null);
    }
  };

  return (
    <SettingsTemplate
      data-cy="account-settings"
      pageTitle="RxRise | Profile"
      links={settingsBuyerTabs()}
    >
      <Grid item xs={12}>
        <Paper variant="outlined" style={{ padding: '1rem' }}>
          <form onSubmit={formik.handleSubmit}>
            <Typography
              variant="h5"
              style={{ marginBottom: '0.5rem', fontSize: '1.3rem' }}
            >
              Add Pharmacy
            </Typography>
            <p style={{ marginBottom: '1rem' }}>
              Please add your new pharmacy information.
            </p>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Pharmacy Name"
                  name="pharmacyName"
                  id="pharmacyName"
                  variant="outlined"
                  value={formik.values.pharmacyName}
                  onChange={formik.handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Doing Business As"
                  name="dbaName"
                  id="dbaName"
                  variant="outlined"
                  value={formik.values.dbaName}
                  onChange={formik.handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  select
                  name="type"
                  label="Type of Business"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  required
                >
                  {pharmacyTypeList.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <InputMask
                  name="phoneNumber"
                  mask="(999) 999 9999"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  disabled={false}
                  maskChar=""
                >
                  {() => (
                    <TextField
                      label="Contact Phone Number*"
                      placeholder="(xxx) xxx-xxxx"
                      variant="outlined"
                      name="phoneNumber"
                      error={!!formik.errors.phoneNumber}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={6}>
                <InputMask
                  name="faxNumber"
                  mask="(999) 999 9999"
                  value={formik.values.faxNumber}
                  onChange={formik.handleChange}
                  disabled={false}
                  maskChar=""
                >
                  {() => (
                    <TextField
                      label="Fax Number*"
                      placeholder="(xxx) xxx-xxxx"
                      variant="outlined"
                      name="faxNumber"
                      error={!!formik.errors.faxNumber}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Years In Business"
                  name="yearsInBusiness"
                  placeholder="1"
                  id="npi"
                  variant="outlined"
                  value={formik.values.yearsInBusiness}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="National Provider Number"
                  placeholder="NPI #"
                  name="npi"
                  id="npi"
                  variant="outlined"
                  value={formik.values.npi}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Tax ID #"
                  placeholder="#"
                  name="taxId"
                  id="taxId"
                  variant="outlined"
                  value={formik.values.taxId}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Separator />

            <Typography
              variant="h5"
              style={{ marginBottom: '0.5rem', fontSize: '1.3rem' }}
            >
              Documentation, Shipping and Billing Information
            </Typography>
            <Typography
              variant="h6"
              style={{ marginBottom: '0rem', fontSize: '1.2rem' }}
            >
              Pharmacy Documentation
            </Typography>
            <p style={{ marginBottom: '0.7rem', fontFamily: 'Roboto Light' }}>
              Please upload a jpg, png, or PDF file to validate your DEA and
              State License. The limit size of the file is 40MB.
            </p>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="DEA Number"
                  name="deaNumber"
                  id="deaNumber"
                  variant="outlined"
                  value={formik.values.deaNumber}
                  onChange={formik.handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  onChange={handleChangeDea}
                  label="DEA File(PDF/JPG/PNG)"
                  id="dea"
                  name="dea"
                  type="file"
                  required
                  variant="outlined"
                  inputProps={{
                    accept:
                      'application/pdf,application/vnd.ms-excel,image/png,image/jpeg',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="State License Number"
                  name="stateLicenseNumber"
                  id="stateLicenseNumber"
                  variant="outlined"
                  value={formik.values.stateLicenseNumber}
                  onChange={formik.handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    event.preventDefault();
                    const reader = new FileReader();
                    const file = event.target.files;
                    if (file && file.length > 0) {
                      reader.readAsDataURL(file[0]);
                      formik.setFieldValue('stateLicense', file[0]);
                    } else {
                      formik.setFieldValue('stateLicense', null);
                    }
                  }}
                  label="State License File(PDF/JPG/PNG)"
                  id="stateLicense"
                  name="stateLicense"
                  type="file"
                  required
                  variant="outlined"
                  inputProps={{
                    accept:
                      'application/pdf,application/vnd.ms-excel,image/png,image/jpeg',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Typography
              variant="h6"
              style={{
                marginBottom: '0rem',
                fontSize: '1.2rem',
                marginTop: '1rem',
              }}
            >
              Shipping Location and Billing Information
            </Typography>
            <p style={{ marginBottom: '0.7rem', fontFamily: 'Roboto Light' }}>
              Enter the billing information of your business and check on the
              box if the shipping information is the same as billing. Otherwise,
              fill in the required information.
            </p>
            <Typography
              variant="h6"
              style={{ marginBottom: '1rem', fontSize: '1.2rem' }}
            >
              Billing Information
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  name="billingInformationName"
                  id="billingInformationName"
                  variant="outlined"
                  value={formik.values.billingInformationName}
                  onChange={formik.handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Address"
                  name="billingAddress"
                  id="billingAddress"
                  variant="outlined"
                  value={formik.values.billingAddress}
                  onChange={formik.handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="City"
                  name="billingAddressCity"
                  id="billingAddressCity"
                  variant="outlined"
                  value={formik.values.billingAddressCity}
                  onChange={formik.handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  select
                  name="billingAddressState"
                  label="State"
                  value={formik.values.billingAddressState}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  required
                >
                  {US_STATES_WITH_CODES.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Zip Code"
                  name="billingAddressZipCode"
                  id="billingAddressZipCode"
                  variant="outlined"
                  value={formik.values.billingAddressZipCode}
                  onChange={formik.handleChange}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Separator />
            <Typography
              variant="h6"
              style={{ marginBottom: '0rem', fontSize: '1.2rem' }}
            >
              Shipping Location
            </Typography>

            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0 0 1rem -0.5rem',
                }}
              >
                <Checkbox
                  name="sameAsBilling"
                  id="sameAsBilling"
                  checked={formik.values.sameAsBilling}
                  onChange={formik.handleChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  color="primary"
                />

                <Typography>
                  Make shipping address same as billing address
                </Typography>
              </div>
            </Grid>

            <Grid container spacing={2}>
              {!formik.values.sameAsBilling && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      label="Address"
                      name="shippingAddressStreet"
                      id="shippingAddressStreet"
                      variant="outlined"
                      value={formik.values.shippingAddressStreet}
                      onChange={formik.handleChange}
                      fullWidth
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="City"
                      name="shippingAddressCity"
                      id="shippingAddressCity"
                      variant="outlined"
                      value={formik.values.shippingAddressCity}
                      onChange={formik.handleChange}
                      fullWidth
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      select
                      name="billingAddressStateshippingAddressState"
                      label="State"
                      value={formik.values.billingAddressState}
                      onChange={formik.handleChange}
                      variant="outlined"
                      fullWidth
                      required
                    >
                      {US_STATES_WITH_CODES.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Zip Code"
                      name="shippingAddressZipCode"
                      id="shippingAddressZipCode"
                      variant="outlined"
                      value={formik.values.shippingAddressZipCode}
                      onChange={formik.handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <footer style={{ paddingTop: '2rem' }}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
              >
                Add
              </Button>
            </footer>
          </form>
        </Paper>
      </Grid>
    </SettingsTemplate>
  );
};
