import { useMutation } from 'react-query';
import { DeleteAdminPictureProduct } from 'rx-domain';
import { http } from 'rx-api/http/repository';
import { DELETE_ADMIN_PRODUCT_PICTURE_URL } from 'rx-api/api/admin/products/constants';

const useDeletePictureProduct = () => {
  const onRequest = (params: DeleteAdminPictureProduct.Params) => {
    return http.delete(
      DELETE_ADMIN_PRODUCT_PICTURE_URL.replace('{id}', params.id.toString())
    );
  };

  return useMutation(onRequest);
};

export { useDeletePictureProduct };
