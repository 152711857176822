import { useEffect, useState } from 'react';

import { useGetSellers } from 'rx-api';

import { usePaginationQueries } from 'rx-hooks/utils';

type SellerPick = { title: string; value: number };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useReportFilterSeller = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<SellerPick | null>(null);
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<SellerPick[]>([]);

  const { querySeller } = usePaginationQueries();

  const { data: adminManufacturers, isLoading } = useGetSellers({
    approvalStatus: 'approved',
    shipper: search,
    skip: 0,
    limit: 100,
  });

  useEffect(() => {
    if (adminManufacturers?.data) {
      setOptions(
        adminManufacturers.data.results.map((result) => ({
          title: result.manufacturer.companyName,
          value: result.id,
        }))
      );
    }
  }, [adminManufacturers]);

  useEffect(() => {
    if (querySeller) setValue(null);
  }, [querySeller]);

  return {
    open,
    setOpen,
    value,
    setValue,
    search,
    setSearch,
    options,
    setOptions,
    isLoading,
  };
};

export { useReportFilterSeller };
