import React from 'react';
import styled, { css } from 'styled-components';

import { FilterItemMaterial } from 'rx-domain';
import { RxFilterMateriaList } from '../RxFilterMateriaList';

export type RxFilterMaterialProps<T> = {
  items: FilterItemMaterial<T>[];
  label: string;
  onClickItem: (value: T, title: string) => void;
  onClickFilter: () => void;
  visibleItems: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FilterContainer = styled.div<{ visible: boolean }>`
  ${({ theme, visible }) => css`
    max-width: 300px;
    width: 300px;
    height: 50px;
    padding: 16px 22px;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: ${theme.colors.black[100]};
    border-radius: ${visible ? `25px 25px 0 0 ` : `50px`};
  `}
`;

const SmallTriangle = styled.div`
  ${({ theme: { colors } }) => css`
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${colors.white[100]};
  `}
`;

const Text = styled.span`
  color: ${({ theme }) => theme.colors.white[100]};
  font-size: ${({ theme }) => theme.sizes.small};
`;

const RxFilterMaterial = <T extends string>({
  items,
  label,
  visibleItems,
  onClickItem,
  onClickFilter,
}: RxFilterMaterialProps<T>): JSX.Element => {
  return (
    <Container>
      <FilterContainer onClick={onClickFilter} visible={visibleItems}>
        <Text>{label}</Text>
        <SmallTriangle />
      </FilterContainer>
      <RxFilterMateriaList
        items={items}
        display={visibleItems ? 'initial' : 'none'}
        onClick={onClickItem}
      />
    </Container>
  );
};

RxFilterMaterial.defaultProps = {
  items: [],
  label: '',
  visibleItems: false,
  onClickItem: () => {},
  onClickFilter: () => {},
};

export { RxFilterMaterial };
