import React from 'react';

import { Title, LinkHome } from './index.styles';

type IProps = {
  productName: string;
};

const ProductLink = (props: IProps): JSX.Element => {
  return (
    <Title>
      <LinkHome to="/">Home </LinkHome> / {props.productName}
    </Title>
  );
};

export { ProductLink };
