import * as Yup from 'yup';
import { address, state, city, zipCode } from '../common.validator';

export const PharmacistBillingSchema = Yup.object().shape({
  name: Yup.string().required(),
  address,
  state,
  city,
  zipCode,
});
