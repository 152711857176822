import styled from 'styled-components';

import { RxButtonSimple } from '../../buttons/RxButtonSimple';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem;
`;

export const PrimaryButton = styled(RxButtonSimple)``;

export const SecondaryButton = styled(RxButtonSimple)`
  margin-right: 1rem;
`;
