import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

export const useSuspendAccount = () => {
  const onRequest = (id: string | number) => {
    return http.post(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/accounts/${id}/suspend`
    );
  };

  return useMutation(onRequest);
};
