import React from 'react';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import {
  useDeleteAdminAdCampaignItem,
  useGetAdminAdItemList,
} from '../../../rx-api/api/admin';
import { SEO, TemplateView, TemplateContent } from 'rx-core';
import { useToast } from 'rx-hooks';
import { AdListLoader } from './components/AdListLoader';
import { AdListDeleteItemModal } from './components/AdListDeleteItemModal';
import { AdminAdListHeader } from './components/AdminAdListHeader';
import { AdminAdListItemRow } from './components/AdminAdListItemRow';
import { AdminAdListItemContainer } from './components/AdminAdListItemContainer';
import { AdListAddItemModal } from './components/AdListAddItemModal';

export const AdminAdListView: React.FC = () => {
  const toast = useToast();
  const { data, isLoading, refetch } = useGetAdminAdItemList();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);
  const [itemIdToDelete, setItemIdToDelete] = React.useState<number | null>(
    null
  );
  const deleteAdCampaignItem = useDeleteAdminAdCampaignItem();

  const handleClickDelete = (id: number) => () => {
    setIsDeleteModalOpen(true);
    setItemIdToDelete(id);
  };

  const handleClickCancelDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setItemIdToDelete(null);
  };

  const handleClickConfirmDeleteModal = async () => {
    try {
      await deleteAdCampaignItem.mutateAsync(itemIdToDelete ?? 0);
      toast.onSuccessToast('Ad campaign item removed');
      refetch();
      setIsDeleteModalOpen(false);
      setItemIdToDelete(null);
    } catch (err) {
      toast.onErrorToast('Could not remove ad campaign item');
    }
  };

  const handleClickAdd = () => {
    setIsAddModalOpen(true);
  };

  const handleClickCancelAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleClickConfirmAddModal = () => {
    refetch();
    setIsAddModalOpen(false);
  };

  return (
    <TemplateView>
      <AdListDeleteItemModal
        isOpen={isDeleteModalOpen}
        isDisabled={deleteAdCampaignItem.isLoading}
        onClickCancel={handleClickCancelDeleteModal}
        onClickConfirm={handleClickConfirmDeleteModal}
      />

      <AdListAddItemModal
        isOpen={isAddModalOpen}
        onClickCancel={handleClickCancelAddModal}
        onClickConfirm={handleClickConfirmAddModal}
      />

      <SEO title="RxRise | Ad List" />
      <TemplateContent>
        <AdminAdListHeader>
          <Typography variant="h5">Ads</Typography>
          <Typography variant="subtitle2">
            Adding ads will active a pop up or banner that will be displayed on
            the market place.
          </Typography>
        </AdminAdListHeader>

        <Paper variant="outlined" style={{ padding: '1rem' }}>
          {isLoading && <AdListLoader />}

          {!isLoading && (
            <>
              <AdminAdListItemRow>
                <Typography variant="h6">Banner</Typography>
                <Typography>
                  The banner wil be displayed on the homepage of the
                  marketplace.
                </Typography>

                <AdminAdListItemContainer>
                  {data.banner === null && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleClickAdd}
                    >
                      Add
                    </Button>
                  )}

                  {data.banner !== null && (
                    <Grid container spacing={2}>
                      <Grid item xs={6} lg={4}>
                        <TextField
                          label="File Name"
                          value={data.banner.name}
                          variant="outlined"
                          size="small"
                          disabled
                          fullWidth
                        />
                      </Grid>

                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          startIcon={<DeleteForeverIcon />}
                          style={{ height: '100%' }}
                          onClick={handleClickDelete(data.banner.id)}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </AdminAdListItemContainer>
              </AdminAdListItemRow>

              <section>
                <Typography variant="h6">Popup</Typography>
                <Typography>
                  The pop-up will be displayed everytime a user logs in into
                  their account.
                </Typography>

                <AdminAdListItemContainer>
                  {data.popup === null && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleClickAdd}
                    >
                      Add
                    </Button>
                  )}

                  {data.popup !== null && (
                    <Grid container spacing={2}>
                      <Grid item xs={6} lg={4}>
                        <TextField
                          label="File Name"
                          value={data.popup.name}
                          variant="outlined"
                          size="small"
                          disabled
                          fullWidth
                        />
                      </Grid>

                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          startIcon={<DeleteForeverIcon />}
                          style={{ height: '100%' }}
                          onClick={handleClickDelete(data.popup.id)}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </AdminAdListItemContainer>
              </section>
            </>
          )}
        </Paper>
      </TemplateContent>
    </TemplateView>
  );
};
