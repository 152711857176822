import { POST_ADMIN_TOGGLE_ACCOUNT_2FA_SETTINGS_URL } from 'rx-api/api/admin/accounts/constants';
import { createUrlWithParams, useRxMutation } from 'rx-api/utils';

export const usePostAdminToggle2FASettings = (id: string) => {
  return useRxMutation<unknown, unknown>({
    method: 'post',
    url: createUrlWithParams(POST_ADMIN_TOGGLE_ACCOUNT_2FA_SETTINGS_URL, [
      {
        key: 'id',
        value: id,
      },
    ]),
  });
};
