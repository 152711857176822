import React, { InputHTMLAttributes } from 'react';

import { Tooltip } from '@material-ui/core';
import {
  Container,
  Input,
  MinusButton,
  PlusButton,
  TooltipContent,
} from './index.styles';

type IProps = InputHTMLAttributes<HTMLInputElement> & {
  onAddOne: () => void;
  onMinusOne: () => void;
  error: boolean;
};

const ShopCartInput = ({
  onAddOne,
  onMinusOne,
  ...props
}: IProps): JSX.Element => {
  return (
    <>
      <Container>
        <Tooltip
          title="Invalid quantity, please verify the minimum/maximum quantity and stock available."
          placement="top"
          open={props.error}
          arrow
        >
          <TooltipContent>
            <MinusButton onClick={() => onMinusOne()}>-</MinusButton>
            <Input type="text" {...props} />
            <PlusButton onClick={() => onAddOne()}>+</PlusButton>
          </TooltipContent>
        </Tooltip>
      </Container>
    </>
  );
};

export { ShopCartInput };
