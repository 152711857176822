import React from 'react';
import styled from 'styled-components';

type VerificationTitleProps = {
  className?: string;
};

const Text = styled.h1`
  font-family: ${({ theme }) => theme.fonts.roobertLight};
  font-size: 40px;
  line-height: 45px;
  color: ${({ theme }) => theme.colors.black[100]};
`;

const VerificationTitle = ({
  ...props
}: VerificationTitleProps): JSX.Element => {
  return <Text {...props}>2-Step Verification</Text>;
};

VerificationTitle.defaultProps = {
  className: '',
};

export { VerificationTitle };
