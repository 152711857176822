import React from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { AppDispatch, setCheckoutSummaryPaymentMethod } from 'rx-store';
import { useAuth } from 'rx-hooks';
import { formatMoney } from 'rx-utils';

import {
  RadioInput,
  Header,
  PaymentTermsText,
} from './CheckoutPaymentTerms.styles';

type IProps = {
  onChange: (type: 'ach' | 'card' | 'paymentTerms', value: string) => void;
  disabled: boolean;
};

const CheckoutPaymentTerms = (props: IProps): JSX.Element => {
  const { paymentTerm } = useAuth();

  const dispatch: AppDispatch = useDispatch();

  return (
    <div>
      <Header>
        <Typography variant="h6">PAYMENT TERMS</Typography>
        <Tooltip
          title={
            <>
              <Typography>
                Minimum amount purchase{' '}
                {formatMoney(paymentTerm?.minimumAmount ?? 0)}
              </Typography>
              <Typography>We will send you a reminder to pay </Typography>
              <Typography>No extra fees for this service</Typography>
            </>
          }
          placement="right"
        >
          <InfoIcon />
        </Tooltip>
      </Header>
      <PaymentTermsText>
        This option is available for you. Checkout now and pay within the next
        15 or 30 days. Minimum purchase amount is{' '}
        {formatMoney(paymentTerm?.minimumAmount ?? 0)}
      </PaymentTermsText>
      <RadioInput
        data-cy="checkout-payment-terms-15"
        name="checkout-payment"
        text="Payment 15 days from invoice date"
        value="paymentFifteenDays"
        disabled={props.disabled}
        onChange={() => {
          props.onChange('paymentTerms', 'paymentFifteenDays');
          dispatch(
            setCheckoutSummaryPaymentMethod('Payment 15 days from invoice date')
          );
        }}
      />
      <RadioInput
        data-cy="checkout-payment-terms-30"
        name="checkout-payment"
        text="Payment 30 days from invoice date"
        value="paymentThirtyDays"
        disabled={props.disabled}
        onChange={() => {
          props.onChange('paymentTerms', 'paymentThirtyDays');
          dispatch(
            setCheckoutSummaryPaymentMethod('Payment 30 days from invoice date')
          );
        }}
      />
    </div>
  );
};

export { CheckoutPaymentTerms };
