import { useQuery } from 'react-query';

import { IGetManufacturerBillingIdResponse } from 'rx-domain';
import { http } from 'rx-api/http/repository';

type IRequest = {
  id: string | number;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetManufacturerBillingId = ({ id }: IRequest) => {
  const onRequest = async (req: IRequest) => {
    return http.get<IGetManufacturerBillingIdResponse>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/manufacturers/${req.id}/billing`,
      {
        withCredentials: true,
      }
    );
  };

  return useQuery(['get-admin-manufacturer-billing-id', id], () =>
    onRequest({ id })
  );
};
