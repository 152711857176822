import * as Yup from 'yup';

import { password, address, city, state, zipCode } from '../common.validator';

const SellerSignupSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter your first name'),
  lastName: Yup.string().required('Pleaster enter your last name'),
  titleOrPosition: Yup.string().required(
    'Pleaste enter your title or position'
  ),
  phoneNumber: Yup.string().required('Please enter your phone number'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter your email'),
  password,
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  address: Yup.object({
    address,
    city,
    state,
    zipCode,
  }),
  company: Yup.object({
    name: Yup.string().required('Please enter your company name'),
    email: Yup.string()
      .email('Invalid email')
      .required(`Please enter your company's email`),
    phoneNumber: Yup.string().required(
      `Please enter your company's phone number`
    ),
  }),
  howDidYouHearAboutUs: Yup.string().required('Please select a option'),
  subscribeToMailingList: Yup.bool(),
  acceptTOS: Yup.bool().oneOf(
    [true],
    'You must agree with the terms and conditions'
  ),
});

export { SellerSignupSchema };
