import { IGetPharmacistAccountResponse } from 'rx-domain';

import { useGetRequest } from 'rx-api/utils';

import { GET_ACCOUNT_URL } from './constants';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetPharmacistAccount = () => {
  return useGetRequest<unknown, IGetPharmacistAccountResponse>({
    url: GET_ACCOUNT_URL,
    key: ['get-pharmacist-account'],
  });
};
