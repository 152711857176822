import React from 'react';
import axios from 'axios';
import styled, { css } from 'styled-components';
import { FormikProvider, Field, FormikHelpers, useFormik } from 'formik';
import StripeLogoImg from 'assets/logos/stripe_logo.svg';

import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { useAddPharmacyPaymentMethodCard, usePostAdminAddCard } from 'rx-api';
import { useAuth, useToast } from 'rx-hooks';
import { useAddPayment } from 'rx-contexts';

import { AddCardPaymentSchema } from 'utils/validators/account-settings/add-card-payment.validator';
import { AddPaymentMethodAutoSubmit } from '../atoms/AddPaymentMethodAutoSubmit';
import { MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';

const useStyles = makeStyles({
  whiteBackground: {
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
    },
  },
});

const FormContainer = styled.div`
  padding: 1rem;
  max-width: 588px;
  position: relative;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('desktop')} {
      width: 100%;
      max-width: 100%;
    }
  `}
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('desktop')} {
      flex-direction: column;
    }
  `}
`;

const CustomInputMaterial = styled(TextField)`
  margin-right: 20px;

  & > span {
    height: 30px;
  }

  &:last-child {
    margin-right: 0;
  }

  ${({ theme: { lessThan } }) => css`
    ${lessThan('desktop')} {
      width: 100%;
    }
  `}
`;

const DefaultLabel = styled.label`
  margin-left: 0.5rem;
`;

interface IAddCardPaymentMethodForm {
  number: string;
  cardHolderName: string;
  expMonth: number;
  expYear: number;
  cvc: string;
  setAsDefault: boolean;
}

function TextMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={(value) => {
        const numberLength = (value.match(/\d/g) || []).length;
        if (numberLength <= 16) {
          return [
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ];
        } else {
          return [
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
          ];
        }
      }}
      placeholderChar={'\u2000'}
      showMask={false}
      guide={false}
    />
  );
}

export const AddPaymentMethodDebitCreditForm: React.FC = () => {
  const { onSetFinish, onSetLoading, onOpenModal, pharmacyId } =
    useAddPayment();
  const toast = useToast();

  const { type } = useAuth();

  const queryClient = useQueryClient();
  const params = useParams<{ id: string }>();

  const mutation = useAddPharmacyPaymentMethodCard(pharmacyId || 0);
  const addAdminCard = usePostAdminAddCard({
    id: params.id,
  });
  const classes = useStyles();

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const menuItems: React.ReactElement[] = [];

  const initialValues: IAddCardPaymentMethodForm = {
    cardHolderName: '',
    number: '',
    expMonth: currentMonth,
    expYear: currentYear,
    cvc: '',
    setAsDefault: false,
  };

  const onSubmit = async (
    form: IAddCardPaymentMethodForm,
    { resetForm }: FormikHelpers<IAddCardPaymentMethodForm>
  ) => {
    onSetLoading(true);

    try {
      if (type !== 'admin') {
        const payloadDto = {
          setAsDefault: form.setAsDefault,
          card: {
            cardHolderName: form.cardHolderName,
            number: form.number.replaceAll(/\s+/g, ''),
            expMonth: form.expMonth,
            expYear: form.expYear,
            cvc: form.cvc,
          },
        };
        await mutation.mutateAsync(payloadDto);
        await queryClient.refetchQueries(
          ['get-pharmacy-payment-methods-cards', pharmacyId],
          {
            active: true,
          }
        );

        resetForm();
        onSetLoading(false);
        onSetFinish(true);
        onOpenModal(false);

        return;
      }

      await addAdminCard.mutateAsync({
        cardHolderName: form.cardHolderName,
        number: form.number.replaceAll(/\s+/g, ''),
        expMonth: form.expMonth.toString(),
        expYear: form.expYear.toString(),
        cvc: form.cvc,
      });
      await queryClient.refetchQueries(
        'get-admin-pharmacists-payment-methods',
        {
          active: true,
        }
      );

      resetForm();
      onSetLoading(false);
      onSetFinish(true);
      onOpenModal(false);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        toast.onErrorToast(
          err.response?.data?.message || 'Could not add payment method'
        );
      } else {
        toast.onErrorToast('Could not add payment method');
      }
    } finally {
      onSetLoading(false);
    }
  };

  for (let i = currentYear; i <= currentYear + 19; i++) {
    menuItems.push(
      <MenuItem key={i} value={i}>
        {`${i}`}
      </MenuItem>
    );
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: AddCardPaymentSchema,
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <FormContainer>
          <h3 style={{ marginBottom: '20px', marginTop: '10px' }}>
            Add New Credit/Debit Card
          </h3>
          <h4 style={{ marginBottom: '15px' }}>
            Please Enter Your Card Information
          </h4>
          <div style={{ display: 'flex' }}>
            <p style={{ marginBottom: '20px' }}>
              Your payment is secured by Stripe.
            </p>
            <img alt="" src={StripeLogoImg} />
          </div>

          <CustomInputMaterial
            data-cy="add-payment-method-cardholder-input"
            name="cardHolderName"
            id="cardHolderName"
            label="Card Holder Name"
            className={classes.whiteBackground}
            onChange={formik.handleChange}
            value={formik.values.cardHolderName}
            variant="outlined"
            fullWidth={true}
            error={
              formik.touched.cardHolderName &&
              (formik.errors?.cardHolderName?.length || 0) > 0
            }
            helperText={
              formik.touched.cardHolderName && formik.errors.cardHolderName
                ? formik.errors.cardHolderName
                : ''
            }
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            type="text"
          />
          <CustomInputMaterial
            data-cy="add-payment-method-cardnumber-input"
            name="number"
            id="number"
            label="Card Number"
            className={classes.whiteBackground}
            onChange={formik.handleChange}
            value={formik.values.number}
            variant="outlined"
            fullWidth={true}
            error={
              formik.touched.number && (formik.errors?.number?.length || 0) > 0
            }
            helperText={
              formik.touched.number && formik.errors.number
                ? formik.errors.number
                : ''
            }
            InputProps={{
              inputComponent: TextMaskCustom,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            type="text"
          />
          <InputContainer>
            <CustomInputMaterial
              data-cy="add-payment-method-exp-month-input"
              name="expMonth"
              id="expMonth"
              label="Expiration Month"
              className={classes.whiteBackground}
              value={formik.values.expMonth}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth={true}
              error={(formik.errors?.expMonth?.length || 0) > 0}
              helperText={formik.errors.expMonth}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              select
            >
              <MenuItem value="1">01 - January</MenuItem>
              <MenuItem value="2">02 - February</MenuItem>
              <MenuItem value="3">03 - March</MenuItem>
              <MenuItem value="4">04 - April</MenuItem>
              <MenuItem value="5">05 - May</MenuItem>
              <MenuItem value="6">06 - June</MenuItem>
              <MenuItem value="7">07 - July</MenuItem>
              <MenuItem value="8">08 - August</MenuItem>
              <MenuItem value="9">09 - September</MenuItem>
              <MenuItem value="10">10 - October</MenuItem>
              <MenuItem value="11">11 - November</MenuItem>
              <MenuItem value="12">12 - December</MenuItem>
            </CustomInputMaterial>
            <CustomInputMaterial
              data-cy="add-payment-method-exp-year-input"
              name="expYear"
              id="expYear"
              label="Expiration year"
              value={formik.values.expYear}
              className={classes.whiteBackground}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth={true}
              error={(formik.errors?.expYear?.length || 0) > 0}
              helperText={formik.errors.expYear}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              select
            >
              {menuItems}
            </CustomInputMaterial>
            <CustomInputMaterial
              data-cy="add-payment-method-cvv-input"
              name="cvc"
              id="cvc"
              label="CVV"
              className={classes.whiteBackground}
              onChange={formik.handleChange}
              value={formik.values.cvc}
              variant="outlined"
              fullWidth={true}
              error={
                formik.touched.cvc && (formik.errors?.cvc?.length || 0) > 0
              }
              helperText={
                formik.touched.cvc && formik.errors.cvc ? formik.errors.cvc : ''
              }
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              type="text"
            />
          </InputContainer>
          <InputContainer>
            <span>
              <Field name="setAsDefault" type="checkbox" id="setAsDefault" />
              <DefaultLabel htmlFor="setAsDefault">
                Set as default payment method
              </DefaultLabel>
            </span>
          </InputContainer>
          <AddPaymentMethodAutoSubmit type="debit-credit-card" />
        </FormContainer>
      </form>
    </FormikProvider>
  );
};
