import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';

export const AccountListEmpty: React.FC = () => {
  return (
    <TableRow>
      <TableCell colSpan={4} style={{ textAlign: 'center' }}>
        No accounts found
      </TableCell>
    </TableRow>
  );
};
