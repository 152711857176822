import { ShopCartItem, ShoppingCartState } from 'rx-domain';
import {
  SET_CART_ITEM,
  CLEAR_CART,
  SET_CART_ITEM_UNITS,
  REMOVE_CART_ITEM,
  CHANGE_CART_STATE,
} from './actionTypes';
import { IShoppingCartAction, ISetUnitItem } from './reducer';

const setCartItem = (
  cartItem: ShopCartItem,
  increase = false
): IShoppingCartAction => {
  return {
    type: SET_CART_ITEM,
    shopping_cart: {
      cartItem,
      cartItems: [],
      shoppingCartState: 'BUYING',
    },
    increase,
  };
};

const setCleanShoppingCart = (): IShoppingCartAction => {
  return {
    type: CLEAR_CART,
    shopping_cart: {
      cartItem: null,
      cartItems: [],
      shoppingCartState: 'BUYING',
    },
  };
};

const setUnitsItemShoppingCart = (
  unitItems: ISetUnitItem
): IShoppingCartAction => {
  return {
    type: SET_CART_ITEM_UNITS,
    shopping_cart: {
      cartItem: null,
      cartItems: [],
      shoppingCartState: 'BUYING',
    },
    unitItems,
  };
};

const removeCartItem = (id: number): IShoppingCartAction => {
  return {
    type: REMOVE_CART_ITEM,
    shopping_cart: {
      cartItem: null,
      cartItems: [],
      shoppingCartState: 'BUYING',
    },
    idItem: id,
  };
};

const changeStateShoppingCart = (
  shoppingCartState: ShoppingCartState
): IShoppingCartAction => {
  return {
    type: CHANGE_CART_STATE,
    shopping_cart: {
      cartItem: null,
      cartItems: [],
      shoppingCartState,
    },
  };
};

export {
  setCartItem,
  setCleanShoppingCart,
  setUnitsItemShoppingCart,
  removeCartItem,
  changeStateShoppingCart,
};
