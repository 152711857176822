import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { SpaceProps, space } from 'styled-system';

import { Colors, Fonts, Sizes, theme, getSizeValue } from 'rx-styles';

export type RxTextProps = HTMLAttributes<HTMLParagraphElement> &
  SpaceProps & {
    children: React.ReactNode;
    className?: string;
    font?: Fonts;
    color?: Colors;
    size?: Sizes;
  };

type IText = SpaceProps & {
  font?: Fonts;
  color?: Colors;
  size?: Sizes;
};

const Text = styled.p<IText>`
  font-family: ${({ font, theme: { fonts } }) => font || fonts.robotoRegular};
  font-size: ${({ size, theme: { sizes } }) =>
    size ? getSizeValue(size) : sizes.small};
  color: ${({ color, theme: { colors } }) => color || colors.black[100]};
  ${space}
`;

const RxText = ({ children, ...props }: RxTextProps): JSX.Element => {
  return <Text {...props}>{children}</Text>;
};

RxText.defaultProps = {
  className: '',
  font: theme.fonts.robotoRegular,
  color: theme.colors.black[100],
  size: theme.sizes.small,
};

export { RxText };
