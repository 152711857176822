import React, { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { CircularProgress } from '@material-ui/core';

import SearchIcon from '../../../assets/icons/search-icon.svg';

export type InputSearchProps = InputHTMLAttributes<HTMLInputElement> & {
  loading?: boolean;
};

const Container = styled.div`
  position: relative;
`;

const Icon = styled.img`
  position: absolute;
  left: 26px;
  top: 15px;
`;

const LoadingIcon = styled(CircularProgress)`
  position: absolute;
  left: 16px;
  top: 11px;

  ${({ theme }) => css`
    color: ${theme.colors.blue[100]} !important;
  `}
`;

const Input = styled.input`
  border-radius: 50px;
  height: 50px;
  width: 334px;
  outline: none;

  padding: 0 18px 0 64px;

  ${({ theme: { colors, fonts, sizes } }) => css`
    border: 1px solid ${colors.gray[500]};

    font-size: ${sizes.medium};
    font-family: ${fonts.robotoLight};

    &::placeholder {
      color: ${colors.gray[500]};
      font-size: ${sizes.medium};
      font-family: ${fonts.robotoLight};
    }
  `}
`;

const InputSearch = ({
  className,
  loading,
  ...props
}: InputSearchProps): JSX.Element => {
  return (
    <Container className={className}>
      {loading ? (
        <LoadingIcon size={28} thickness={5} />
      ) : (
        <Icon src={SearchIcon} />
      )}
      <Input placeholder="Search all orders" {...props} />
    </Container>
  );
};

InputSearch.defaultProps = {
  loading: false,
};

export { InputSearch };
