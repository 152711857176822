import styled, { css } from 'styled-components';

export const Container = styled.div<{ info: boolean }>`
  ${({ theme, info }) => css`
    max-width: 20.75rem;
    margin-top: 1rem;

    ${info &&
    css`
      border: 1px ${theme.colors.gray[300]} solid;

      & > div:first-child {
        background-color: ${theme.colors.gray[300]};
      }
    `}

    ${!info &&
    css`
      border: 1px ${theme.colors.gray[500]} solid;

      & > div:first-child {
        border-bottom: 1px ${theme.colors.gray[500]} solid;
        background-color: white;
      }
    `}
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${theme.sizes.medium};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 0.5rem;
    text-align: center;
    font-size: ${theme.sizes.medium};
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
  `}
`;
