import * as Yup from 'yup';
import valid from 'card-validator';

const AddCardPaymentSchema = Yup.object().shape({
  cardHolderName: Yup.string().required('Cardholder name is required.'),
  number: Yup.string()
    .test(
      'test-credit-card',
      'Credit card number is invalid',
      (value) => valid.number(value).isValid
    )
    .required('Please enter your account number.'),
  expMonth: Yup.string()
    .test(
      'test-exp-month',
      'Expiration month is invalid',
      (value) => valid.expirationMonth(value).isValid
    )
    .required('Enter expiration month.'),
  expYear: Yup.string()
    .test(
      'test-exp-year',
      'Expiration year is invalid',
      (value) => valid.expirationYear(value).isValid
    )
    .required('Enter expiration year.'),
  cvc: Yup.string()
    .test(
      'test-cvc',
      'The cvc is invalid',
      (value) => valid.cvv(value, [3, 4]).isValid
    )
    .required('CVC is required'),
});

export { AddCardPaymentSchema };
