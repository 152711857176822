import React from 'react';

import { ITransaction } from 'rx-domain';

import { TransactionOrder } from '../TransactionOrder';
import { TransactionPurchaseOrders } from '../TransactionPurchaseOrders';

import { Spacer } from './index.styles';

type IProps = {
  items: Array<ITransaction>;
};

export const TransactionOrders: React.FC<IProps> = (props) => {
  return (
    <>
      {props.items.map((item) => (
        <React.Fragment key={item.id}>
          <Spacer />
          <TransactionOrder {...item} />
          <TransactionPurchaseOrders items={item.order.purchaseOrders} />
        </React.Fragment>
      ))}
    </>
  );
};
