import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';

import SearchIcon from '@material-ui/icons/Search';
import { CircularProgress } from '@material-ui/core';

import { useFilter } from 'rx-contexts';

import { usePaginationQueries } from 'rx-hooks';
import { AdminProductsSearchTypeFilter } from './AdminProductsSearchTypeFilter';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const InputContainer = styled.div`
  position: relative;
`;

const SearchInputIcon = styled(SearchIcon)`
  ${({ theme }) => css`
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    color: ${theme.colors.gray[500]};
  `}
`;

const LoadingIcon = styled(CircularProgress)`
  ${({ theme }) => css`
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    color: ${theme.colors.blue[100]} !important;
  `}
`;

const Input = styled.input`
  ${({ theme }) => css`
    background-color: ${theme.colors.gray[100]};
    width: 34rem;
    height: 2.5rem;
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 0 3.25rem;
    font-size: ${theme.sizes.medium};

    &::placeholder {
      font-size: 16px;
      line-height: 22px;
      color: ${theme.colors.gray[500]};
    }
  `}
`;

const AdminProductsSearch = (): JSX.Element => {
  const timerRef = useRef<number | null>(null);

  const history = useHistory();
  const location = useLocation();

  const { onSetLazyLoading, onSearch, loading, lazyLoading } = useFilter();
  const { onMakeURL, querySearchType, querySearch, queryCategory } =
    usePaginationQueries();

  const onSearchInput = async (event: React.SyntheticEvent) => {
    onSetLazyLoading(true);
    if (timerRef.current) clearTimeout(timerRef.current);

    const target = event.target as HTMLInputElement;

    if (!target.value) {
      onSearch('');
      history.push(`${location.pathname}`);
      onSetLazyLoading(false);
      return;
    }

    timerRef.current = window.setTimeout(() => {
      onSearch(target.value.trim());

      const fullURL = onMakeURL({
        search: target.value.trim(),
        category: queryCategory,
        t: querySearchType,
      });

      history.push(fullURL);
      onSetLazyLoading(false);
    }, 750);
  };

  useEffect(() => {
    onSearch(querySearch);
  }, [onSearch, querySearch]);

  return (
    <Container>
      <InputContainer>
        {loading || lazyLoading ? (
          <LoadingIcon size={26} thickness={6} />
        ) : (
          <SearchInputIcon />
        )}
        <Input placeholder="Filter products" onKeyUp={onSearchInput} />
      </InputContainer>
      <AdminProductsSearchTypeFilter />
    </Container>
  );
};

export { AdminProductsSearch };
