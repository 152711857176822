import React, { useState } from 'react';

import { ITransactionPurchaseOrder } from 'rx-domain';
import { RxMenu } from 'rx-core';
import { useTransactionPOActions } from 'rx-hooks';

import { TransactionPurchaseOrderDetails } from '../TransactionPurchaseOrderDetails';

import {
  Container,
  Column,
  DetailContainer,
  DetailColumn,
  DetailsRow,
} from './index.styles';
import { TransactionPaymentStatus } from '../TransactionPaymentStatus';
import { TransactionShippingStatus } from '../TransactionShippingStatus';

type IProps = ITransactionPurchaseOrder;

const TransactionPurchaseOrder = (props: IProps): JSX.Element => {
  const [openDetails, setOpenDetails] = useState(false);

  const { anchorEl, options, onClose, onOpenMenu } =
    useTransactionPOActions(props);

  return (
    <>
      <Container
        onClick={() => {
          setOpenDetails((prev) => !prev);
        }}
        open={openDetails}
      >
        <Column colSpan={9} open={openDetails}>
          <DetailsRow open={openDetails}>
            <div>
              {props.poPrefix}-{props.id} Details
            </div>

            <div>
              <span style={{ marginRight: '0.5rem' }}>
                <TransactionPaymentStatus status={props.paymentStatus} />
              </span>
              <TransactionShippingStatus status={props.status} />
              <RxMenu
                anchorEl={anchorEl}
                onClose={onClose}
                onOpenMenu={onOpenMenu}
                options={options}
              />
            </div>
          </DetailsRow>
        </Column>
      </Container>
      {openDetails && (
        <DetailContainer>
          <DetailColumn colSpan={9}>
            <TransactionPurchaseOrderDetails {...props} />
          </DetailColumn>
        </DetailContainer>
      )}
    </>
  );
};

export { TransactionPurchaseOrder };
