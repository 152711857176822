import * as Yup from 'yup';

import {
  firstName,
  lastName,
  phoneNumber,
  titleOrPosition,
} from '../../common.validator';

const ManufacturerProfileInfoSchema = Yup.object().shape({
  firstName,
  lastName,
  phoneNumber,
  titleOrPosition,
  secondaryEmail: Yup.string().nullable().email('Invalid email'),
});

export { ManufacturerProfileInfoSchema };
