import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useQueryClient } from 'react-query';

import { RxButtonSimple, RRModalProps } from 'rx-core';

import { useUpdateTwoFactorConfig } from 'rx-api';

type IProps = RRModalProps;

const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoRegular};
    font-weight: 400;
    margin-bottom: 1rem;
    margin-top: 1rem;
  `}
`;

const Content = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoLight};
    font-weight: 400;
    margin-bottom: 1rem;
  `}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SubmitButton = styled(RxButtonSimple)`
  width: 7.375rem;
`;

const CancelButton = styled(RxButtonSimple)`
  width: 7.375rem;
  margin-right: 1rem;
`;

const TwoFactorAuthOnMessage = (props: IProps): JSX.Element => {
  const update2FA = useUpdateTwoFactorConfig();
  const [success, setSuccess] = useState(false);

  const queryClient = useQueryClient();

  const onTurnOn2FA = async () => {
    try {
      await update2FA.mutateAsync({ enabled: true });
      setSuccess(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const onFinish = async () => {
    try {
      await queryClient.refetchQueries(['get-account-2FA'], { active: true });
      props.onClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <Container data-cy="security-activate-2fa-modal">
      <Title>Two-Factor Authentication</Title>

      {success ? (
        <>
          <Content>
            Two-Factor Authentication is <b>ON</b>
          </Content>
          <Content>
            Now you will receive a code in your email every time you try to
            login into your RxRise account. If you want to turn off this option
            you can return to the two-factor authentification settings.
          </Content>
        </>
      ) : (
        <>
          <Content>
            Activate option if you want to receive your verification code every
            time you login in to your RxRise account.
          </Content>
          <Content>
            We will send an activation code to you email in order to continue
            with the setup of this feature.
          </Content>
        </>
      )}
      <div style={{ flex: 1 }}></div>
      {success ? (
        <ButtonContainer>
          <SubmitButton onClick={onFinish} data-cy="security-activate-2fa-ok">
            Done
          </SubmitButton>
        </ButtonContainer>
      ) : (
        <ButtonContainer>
          <CancelButton
            onClick={props.onClose}
            disabled={update2FA.isLoading}
            variant="outline"
          >
            Cancel
          </CancelButton>
          <SubmitButton
            onClick={onTurnOn2FA}
            loading={update2FA.isLoading}
            data-cy="security-activate-2fa-confirmation"
          >
            Turn On
          </SubmitButton>
        </ButtonContainer>
      )}
    </Container>
  );
};

export { TwoFactorAuthOnMessage };
