import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const RemoveProductDialogActions = withStyles(() => ({
  root: {
    margin: 0,
    padding: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))(DialogActions);

interface IProps {
  isOpen: boolean;
  onClickCancel: () => void;
  onClickRemove: () => void;
}

export const InventoryItemRemoveDialog: React.FC<IProps> = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClickCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to remove this product?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your product won't be available for sale in RxRise platform anymore!
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          Would you like to continue?
        </DialogContentText>
      </DialogContent>

      <Divider />

      <RemoveProductDialogActions>
        <Button
          variant="contained"
          onClick={props.onClickCancel}
          color="primary"
        >
          No, Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={props.onClickRemove}
          color="secondary"
          autoFocus
        >
          Yes, remove
        </Button>
      </RemoveProductDialogActions>
    </Dialog>
  );
};
