import { useMutation } from 'react-query';

import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePostSaleOrderInvoice = () => {
  const onRequest = async ({ id }: { id: number }) => {
    return http.post(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/sale-orders/${id}/invoice`,
      {},
      {
        responseType: 'arraybuffer',
      }
    );
  };

  return useMutation(onRequest, {});
};

export { usePostSaleOrderInvoice };
