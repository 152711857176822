import React, { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

import CheckErrorIcon from 'assets/icons/check-error-icon.svg';
import CheckInfoIcon from 'assets/icons/check-info-icon.svg';
import CheckSuccessIcon from 'assets/icons/check-success-icon.svg';

import { DataState } from 'rx-domain';

type IProps = ImgHTMLAttributes<HTMLImageElement> & {
  type: DataState;
};

const getIcon = (type: DataState): string => {
  switch (type) {
    case 'success':
      return CheckSuccessIcon;
    case 'info':
      return CheckInfoIcon;
    case 'error':
      return CheckErrorIcon;
    default:
      return CheckInfoIcon;
  }
};

const Icon = styled.img`
  background-color: white;
`;

const SignupSellerCheck = ({ type, ...props }: IProps): JSX.Element => {
  return <Icon src={getIcon(type)} {...props} />;
};

SignupSellerCheck.defaultProps = {
  type: 'none',
};

export { SignupSellerCheck };
