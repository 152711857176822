import React from 'react';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { Formik, Form } from 'formik';
import { useQueryClient } from 'react-query';

import { RxInputText } from 'rx-core';
import { Button, Grid } from '@material-ui/core';
import { useAddPayment } from 'rx-contexts';
import { useVerifyPharmacyPaymentMethodAch } from 'rx-api';

import { VerifyACHSchema } from 'utils/validators/account-settings/verify-ach.validator';

import { decimalMask, parserMoneyNumberWithDefault } from 'rx-utils';
import { AddPaymentMethodAutoSubmit } from '../atoms/AddPaymentMethodAutoSubmit';
import { AddPaymentMethodError } from '../atoms/AddPaymentMethodError';

interface IForm {
  depositOne: number;
  depositTwo: number;
}

const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(43, 54, 71, 0.35);
  `}
`;

const Content = styled.p`
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const FormContainer = styled.div`
  padding: 1rem;
  position: relative;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('desktop')} {
      width: 100%;
      max-width: 100%;
    }
  `}
`;

const ActionsContainer = styled.div`
  border-top: 1px solid rgb(43, 54, 71, 0.35);
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

export const AddPaymentMethodACHVerifiedForm: React.FC<{
  pharmacyId: number | string;
}> = (props) => {
  const initialValues: IForm = {
    depositOne: 0,
    depositTwo: 0,
  };

  const {
    loading,
    error,
    onSetError,
    onSetFinish,
    onSetLoading,
    onOpenVerifyModal,
    paymentId,
  } = useAddPayment();

  const queryClient = useQueryClient();

  const verifiedACH = useVerifyPharmacyPaymentMethodAch(
    props.pharmacyId,
    paymentId || 0
  );

  const onSubmit = async (values: IForm) => {
    onSetLoading(true);

    const depositOneClean = parserMoneyNumberWithDefault(
      '',
      values.depositOne.toString()
    );
    const depositTwoClean = parserMoneyNumberWithDefault(
      '',
      values.depositTwo.toString()
    );

    if (!paymentId) return;

    try {
      await verifiedACH.mutateAsync({
        amounts: [depositOneClean * 100, depositTwoClean * 100],
      });
      await queryClient.refetchQueries('get-pharmacy-payment-methods-ach', {
        active: true,
      });
      onSetLoading(false);
      onSetFinish(true);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          onSetError(err.response.data.message);
        }
      }

      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      onSetLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={VerifyACHSchema}
      validateOnMount
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          {!verifiedACH.isSuccess ? (
            <FormContainer data-cy="ach-verification-modal">
              <Title>ACH Verification ✅</Title>
              <Content>
                As part of the ACH verification process we need to do two
                micro-deposits. Once the ACH payment method is verified you will
                be able to buy using this option. Meanwhile you can use a credit
                or debit card. We recommended to deposit amounts in cents.
              </Content>
              <RxInputText
                name="depositOne"
                label="Deposit One *"
                placeholder="Deposit One"
                mask={decimalMask}
                data-cy="ach-verification-deposit-one-input"
                withMask
              />
              <RxInputText
                name="depositTwo"
                label="Deposit Two *"
                placeholder="Deposit Two"
                mask={decimalMask}
                data-cy="ach-verification-deposit-two-input"
                withMask
              />
              <AddPaymentMethodAutoSubmit type="ach" />
              {error && <AddPaymentMethodError>{error}</AddPaymentMethodError>}
              <ActionsContainer>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      type="button"
                      onClick={() => onOpenVerifyModal(false)}
                      color="primary"
                      variant="outlined"
                      data-cy="ach-verification-modal-cancel"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!isValid || isSubmitting || loading}
                      data-cy="ach-verification-modal-confirm"
                    >
                      Verify ACH
                    </Button>
                  </Grid>
                </Grid>
              </ActionsContainer>
            </FormContainer>
          ) : (
            <FormContainer data-cy="ach-verification-success-modal">
              <Title>Thank you! 😀</Title>
              <Content>
                Please wait for the process to be completed. Mean while you can
                still buy using a credit or debit card! Thank you for being a
                RxRise customer!
              </Content>
              <ActionsContainer>
                <Button
                  type="button"
                  onClick={() => onOpenVerifyModal(false)}
                  variant="contained"
                  color="primary"
                  data-cy="ach-verification-success-modal-close"
                >
                  Close
                </Button>
              </ActionsContainer>
            </FormContainer>
          )}
        </Form>
      )}
    </Formik>
  );
};
