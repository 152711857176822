import { RxInputText } from 'rx-core';
import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = styled(Grid)`
  & {
    background: #ffffff;
    border-radius: 0.5rem;
    padding: 1.5rem 1rem;
    margin-bottom: 1rem;
  }
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-weight: 400;
    margin-bottom: 0.5rem;
  `}
`;

export const Input = styled(RxInputText)``;

export const Subtitle = styled.h3`
  margin-bottom: 0.5rem;
`;

export const ImageText = styled.p`
  margin-bottom: 1rem;
`;
