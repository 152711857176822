import React from 'react';
import styled from 'styled-components';

import { DataState } from 'rx-domain';
import { StatusCheckIcon } from '../../icons';

export type RxInputIconProps = {
  state?: DataState;
};

const Icon = styled(StatusCheckIcon)`
  height: 28px;
  width: 28px;
  position: absolute;
  bottom: 11px;
  right: -42px;
`;

const RxInputIcon: React.FC<RxInputIconProps> = ({
  children,
  state,
}): JSX.Element => {
  return <Icon type={state}>{children}</Icon>;
};

export { RxInputIcon };
