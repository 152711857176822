import * as Yup from 'yup';

import { phoneNumber } from '../../common.validator';

const PharmacyInfoSchema = Yup.object().shape({
  pharmacyName: Yup.string().required('Required'),
  dbaName: Yup.string().notRequired(),
  dea: Yup.string().notRequired(),
  stateLicense: Yup.string().notRequired(),
  type: Yup.string().required('Required'),
  phoneNumber,
  faxNumber: phoneNumber.notRequired(),
  yearsInBusiness: Yup.number()
    .min(1, 'At least one year')
    .required('Please provide a value'),
  npi: Yup.string().notRequired(),
});

export { PharmacyInfoSchema };
