import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

export interface IUseDownloadProductsQuery {
  offset: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDownloadProductsV2 = () => {
  const onRequest = async (query: IUseDownloadProductsQuery) => {
    const { offset } = query;

    return http.get(
      `${process.env.REACT_APP_EDI_BACKEND_SERVICE_URL}/products?offset=${offset}`,
      {
        withCredentials: true,
      }
    );
  };

  return useMutation(onRequest, {});
};
