import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import capitalize from 'lodash.capitalize';

import { TemplateView, SEO } from 'rx-core';
import { useGetPurchaseOrder } from 'rx-api';
import { BillingAddressFactory } from 'domain/entities/BillingAddress';
import { ShippingAddressFactory } from 'domain/entities/ShippingAddress';

import { OrderDetailsLoader } from './OrderDetailsLoader/OrderDetailsLoader';
import { OrderDetails } from './OrderDetails/OrderDetails';
import { EventTimeline } from './EventTimeline';

const OrderDetailsViewContainer = styled.div`
  margin: 2rem 0;
`;

export const OrderDetailsView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, data: response } = useGetPurchaseOrder({
    id: parseInt(id, 10),
  });

  const shippingAddress = ShippingAddressFactory(
    response?.data.shippingAddress.address || '',
    response?.data.shippingAddress.city || '',
    response?.data.shippingAddress.state || '',
    response?.data.shippingAddress.zipCode || ''
  );

  const billingAddress = BillingAddressFactory(
    response?.data.buyer.billingInformation.name || '',
    response?.data.buyer.billingInformation.billingAddress.address || '',
    response?.data.buyer.billingInformation.billingAddress.city || '',
    response?.data.buyer.billingInformation.billingAddress.state || '',
    response?.data.buyer.billingInformation.billingAddress.zipCode || ''
  );

  const orderId = response?.data.isRequest
    ? `PO-${response?.data?.id}`
    : `${response?.data.poPrefix}-${response?.data.id}`;
  const lineItems = (response?.data.orderItems || []).map((orderItem) => ({
    id: orderItem.id,
    line: orderItem.line,
    price: `$${orderItem.price}`,
    quantity: orderItem.quantity,
    subtotal: `$${(orderItem.quantity * parseFloat(orderItem.price)).toFixed(
      2
    )}`,
  }));

  const eventTimeline = EventTimeline(response?.data.events || []);
  const events = eventTimeline.getTimeline();
  const paymentMethod = capitalize(
    response?.data.paymentMethod.split('_').join(' ') || ''
  );

  return (
    <TemplateView>
      <SEO title="RxRise | Order History" />

      <OrderDetailsViewContainer>
        {isLoading && <OrderDetailsLoader />}

        {!isLoading && (
          <OrderDetails
            orderId={orderId}
            billingName={billingAddress.getBillingName()}
            billingAddress={billingAddress.getBillingAddress()}
            shippingAddress={shippingAddress.getShippingAddress()}
            lineItems={lineItems}
            total={response?.data.subtotal || '0.00'}
            status={response?.data.status || ''}
            events={events}
            paymentMethod={paymentMethod}
          />
        )}
      </OrderDetailsViewContainer>
    </TemplateView>
  );
};
