import React from 'react';
import { useFormik } from 'formik';
import { useQueryClient } from 'react-query';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import { RRModalProps } from 'rx-core';
import { useToast } from 'rx-hooks';
import {
  usePharmacistUpdateBillingInformation,
  useUpdatePharmacistBillingInformation,
} from 'rx-api';
import { STATE_CODES_US } from 'rx-utils';

import { SCAddBillingInformationSchema } from 'utils/validators/shopping-cart-add-billing.validator';

import {
  Container,
  Modal,
  SubmitContainer,
  Title,
} from './CheckoutNewBillInfo.styles';

type IProps = RRModalProps;

interface IForm {
  firstName: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

export const CheckoutNewBillInfo: React.FC<IProps> = (props) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const updateBillingInformationV2 = usePharmacistUpdateBillingInformation(
    props.selectedPharmacy?.id || 0
  );

  const formik = useFormik({
    initialValues: {
      firstName: '',
      address: '',
      city: '',
      state: 'AL',
      zipCode: '',
    },
    validationSchema: SCAddBillingInformationSchema,
    onSubmit: async (values: IForm) => {
      try {
        await updateBillingInformationV2.mutateAsync({
          address: values.address,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          name: values.firstName,
        });

        await queryClient.refetchQueries('get-pharmacist-pharmacy-list', {
          active: true,
        });
        props.setCheckoutError && props.setCheckoutError(null);
        props.onClose();
        toast.onSuccessToast('Billing information saved!');
      } catch (err) {
        toast.onErrorToast('Error: could not save billing information');
      }
    },
  });

  return (
    <Modal {...props}>
      <Container>
        <Title color="#2b3647" size="h2">
          Billing Information
        </Title>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
            <Grid item xs={12}>
              <TextField
                name="firstName"
                label="Name"
                placeholder="Legal Name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
            <Grid item xs={6}>
              <TextField
                name="address"
                label="Address"
                placeholder="Address"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                value={formik.values.address}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="city"
                label="City"
                placeholder="City"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                value={formik.values.city}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  shrink
                  style={{
                    backgroundColor: 'white',
                    boxSizing: 'border-box',
                    margin: '-0.5rem 0 0 0.5rem',
                    padding: '0 0.5rem',
                    zIndex: 100,
                  }}
                >
                  State *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="state"
                  placeholder="State"
                  variant="outlined"
                  fullWidth
                  required
                  value={formik.values.state}
                  onChange={formik.handleChange}
                >
                  {STATE_CODES_US.map((state) => (
                    <MenuItem value={state} key={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="zipCode"
                label="Zip Code"
                placeholder="Zip Code"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                value={formik.values.zipCode}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <SubmitContainer>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Save
            </Button>
          </SubmitContainer>
        </form>
      </Container>
    </Modal>
  );
};
