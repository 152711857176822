import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

type IProps = HTMLAttributes<HTMLSpanElement>;

const Text = styled.span`
  ${({ theme }) => css`
    line-height: 19px;
    padding-bottom: 2.5rem;
    text-decoration: none;
    cursor: pointer;
    font-family: ${theme.fonts.robotoLight};
    color: ${theme.colors.blue[200]};
    font-size: ${theme.sizes.small};
  `}
`;

const VerificationBack = ({ ...props }: IProps): JSX.Element => {
  return <Text {...props}>Back</Text>;
};

export { VerificationBack };
