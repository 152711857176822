import { ADMIN_URL_V1 } from '../constants';

export const GET_LOGS_ACCOUNT_LOGIN_URL = `${ADMIN_URL_V1}/logs/account/login`;
export const GET_LOGS_ACCOUNT_LOGIN_KEY = 'GET_LOGS_ACCOUNT_LOGIN';

export const GET_EDI_INVENTORY_UPLOAD_URL = `${ADMIN_URL_V1}/logs/edi/inventory-upload`;
export const GET_EDI_INVENTORY_UPLOAD_KEY = 'GET_EDI_INVENTORY_UPLOAD_KEY';

export const GET_EDI_INVENTORY_TRANSMIT_URL = `${ADMIN_URL_V1}/logs/edi/inventory-transmit`;
export const GET_EDI_INVENTORY_TRANSMIT_KEY = 'GET_EDI_INVENTORY_TRANSMIT_KEY';

export const GET_LOGS_SHOPPING_CART_URL = `${ADMIN_URL_V1}/shopping-cart/events`;
export const GET_LOGS_SHOPPING_CART_KEY = 'GET_LOGS_SHOPPING_CART';

export const GET_LOGS_LOGIN_EMAIL_LIST_URL = `${ADMIN_URL_V1}/accounts`;
export const GET_LOGS_LOGIN_EMAIL_LIST_KEY = 'GET_LOGS_LOGIN_EMAIL_LIST_KEY';
