import React from 'react';
import styled, { css } from 'styled-components';

import { HeaderOption } from '../atoms';

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 8rem;

    ${theme.lessThan('ipadPro')} {
      display: none;
    }
  `}
`;

const CustomHeaderOption = styled(HeaderOption)`
  padding-right: 38px;
`;

export const HeaderAdminActions: React.FC = () => {
  return (
    <Container>
      <CustomHeaderOption to="/transactions" text="Transactions" />
      <CustomHeaderOption to="/pharmacies" text="Pharmacies" />
      <CustomHeaderOption to="/manufacturers" text="Manufacturers" />
      <CustomHeaderOption to="/products" text="Products" />
      <CustomHeaderOption to="/reports" text="Reports" />
      <CustomHeaderOption to="/ads" text="Ads" />
    </Container>
  );
};
