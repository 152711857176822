import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

type DeleteShoppingCartDTO = {
  id: number;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDeleteShoppingCart = () => {
  const onRequest = async (req: DeleteShoppingCartDTO) => {
    return http.delete(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/pharmacists/shopping-cart/${req.id}`
    );
  };

  return useMutation(onRequest, {});
};

export { useDeleteShoppingCart };
