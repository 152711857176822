import React from 'react';

import { GetOrders } from 'rx-domain';

import { OrderItemContent } from '../OrderItemContent';
import { OrderItemHeader } from '../OrderItemHeader';
import { Container } from './index.styles';

type IProps = {
  item: GetOrders.Order;
};

export const OrderItem: React.FC<IProps> = (props) => {
  return (
    <Container>
      <OrderItemHeader item={props.item} />
      <OrderItemContent items={props.item.items} />
    </Container>
  );
};
