import { ShopCartItem, ShoppingCartState } from 'rx-domain';
import {
  CLEAR_CART,
  SET_CART_ITEM,
  SET_CART_ITEM_UNITS,
  REMOVE_CART_ITEM,
  CHANGE_CART_STATE,
} from './actionTypes';

export interface IShoppingCartState {
  cartItems: ShopCartItem[];
  cartItem: ShopCartItem | null;
  shoppingCartState: ShoppingCartState;
}

export interface ISetUnitItem {
  id: number;
  amount: number;
}

export interface IShoppingCartAction {
  type: string;
  shopping_cart: IShoppingCartState;
  increase?: boolean;
  unitItems?: ISetUnitItem;
  idItem?: number;
}

const initialState: IShoppingCartState = {
  cartItems: [],
  cartItem: null,
  shoppingCartState: 'BUYING',
};

const shoppingCartReducer = (
  state: IShoppingCartState = initialState,
  action: IShoppingCartAction
): IShoppingCartState => {
  switch (action.type) {
    case SET_CART_ITEM: {
      const { cartItem } = action.shopping_cart;

      const currentItemIndex = state.cartItems.findIndex(
        (item) => item.product.id === cartItem?.product.id
      );

      if (!cartItem) return state;

      if (currentItemIndex === -1) {
        return {
          ...state,
          cartItems: [
            ...state.cartItems,
            { ...cartItem, count: cartItem.count || 1 },
          ],
        };
      }

      const cartItemCount = cartItem.count || 0;

      const cartItemsUpdated = state.cartItems.map((item, i) =>
        i === currentItemIndex
          ? {
              ...item,
              count: action.increase ? (item.count || 0) + 1 : cartItemCount,
            }
          : item
      );

      return {
        ...state,
        cartItems: cartItemsUpdated,
      };
    }
    case CLEAR_CART: {
      return {
        ...state,
        cartItem: null,
        cartItems: [],
      };
    }
    case SET_CART_ITEM_UNITS: {
      const { unitItems } = action;

      if (!unitItems) return state;

      const cartItems = state.cartItems.map((item) =>
        item.product.id === unitItems.id
          ? { ...item, count: unitItems.amount }
          : item
      );

      return {
        ...state,
        cartItems,
      };
    }
    case REMOVE_CART_ITEM: {
      const { idItem } = action;

      if (!idItem) return state;

      return {
        ...state,
        cartItems: state.cartItems.filter((item) => item.product.id !== idItem),
      };
    }
    case CHANGE_CART_STATE: {
      return {
        ...state,
        shoppingCartState: action.shopping_cart.shoppingCartState,
      };
    }
    default:
      return state;
  }
};

export { initialState, shoppingCartReducer };
