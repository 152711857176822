import React, { useMemo } from 'react';

import { IOrderProductItem } from 'rx-domain';

import { formatMoney } from 'rx-utils';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Container } from './index.styles';

type IProps = {
  items: Array<IOrderProductItem>;
};

export const PurchaseOrderRequestProducts: React.FC<IProps> = (props) => {
  const subtotal = useMemo(() => {
    if (!props.items) return 0;

    return props.items.reduce(
      (prev, current) => parseFloat(current.price) * current.quantity + prev,
      0
    );
  }, [props.items]);

  return (
    <Container>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>LINE</TableCell>
            <TableCell># OF UNITS</TableCell>
            <TableCell>SUBTOTAL</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.items.map((orderItem, i) => (
            <TableRow key={i}>
              <TableCell>{orderItem.line}</TableCell>
              <TableCell>{orderItem.quantity}</TableCell>
              <TableCell>{orderItem.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell />
            <TableCell>
              <b>Subtotal</b>
            </TableCell>
            <TableCell>{formatMoney(subtotal)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell>
              <b>FEES NOT INCLUDED IN THIS TOTAL</b>
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell>
              <b>Total</b>
            </TableCell>
            <TableCell>{formatMoney(subtotal)}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Container>
  );
};
