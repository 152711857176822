import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 2rem;
`;

export const ProductContainer = styled.div`
  height: 350px;
  overflow-y: scroll;
`;
