import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { http } from 'rx-api/http/repository';

type Params<T> = (req: T) => string;

function isString(x: unknown): x is string {
  return typeof x === 'string';
}

type IGetRequest<T, R> = {
  key: QueryKey;
  url: string;
  params?: T;
  makeParams?: Params<T> | string;
  config?: AxiosRequestConfig | undefined;
  reactQueryOptions?:
    | Omit<
        UseQueryOptions<AxiosResponse<R>, unknown, AxiosResponse<R>, QueryKey>,
        'queryKey' | 'queryFn'
      >
    | undefined;
};

type IRequestType = { [key: string]: string | number } | unknown;

export const useGetRequest = <Req extends IRequestType, Resp>({
  key,
  url,
  params,
  makeParams = '',
  config,
  reactQueryOptions,
}: IGetRequest<Req, Resp>) => {
  const onRequest = async (req: Req | undefined) => {
    let parameters = '';

    if (!isString(makeParams) && makeParams && req) {
      parameters = makeParams(req);
    } else if (typeof makeParams === 'string') {
      parameters = makeParams;
    }

    return http.get<Resp>(`${url}${parameters}`, config);
  };

  return useQuery(key, () => onRequest(params), reactQueryOptions);
};
