import { Button, Grid, Typography } from '@material-ui/core';

export interface IPharmacyLegalDocumentsViewFormProps {
  deaUrl: string;
  stateLicenseUrl: string;
  loading: boolean;
  disabled: boolean;
  onClickEditDEA: () => void;
  onClickEditState: () => void;
}

export const PharmacyLegalDocumentsViewForm = (
  props: IPharmacyLegalDocumentsViewFormProps
): JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={6}>
        <Typography>DEA File:</Typography>
        {!!props.deaUrl && (
          <a href={props.deaUrl} target="_blank">
            Download
          </a>
        )}

        {!props.deaUrl && <Typography>N/A</Typography>}
      </Grid>
      <Grid item xs={6} md={6}>
        <Typography>State License File:</Typography>
        {!!props.stateLicenseUrl && (
          <a href={props.stateLicenseUrl} target="_blank">
            Download
          </a>
        )}

        {!props.stateLicenseUrl && <Typography>N/A</Typography>}
      </Grid>
      <Grid item xs={6} md={6}>
        <Button
          style={{ marginTop: 16 }}
          variant="outlined"
          color="primary"
          disabled={props.loading || props.disabled}
          onClick={props.onClickEditDEA}
        >
          Edit
        </Button>
      </Grid>
      <Grid item xs={6} md={6}>
        <Button
          style={{ marginTop: 16 }}
          variant="outlined"
          color="primary"
          disabled={props.loading || props.disabled}
          onClick={props.onClickEditState}
        >
          Edit
        </Button>
      </Grid>
    </Grid>
  );
};
