import React from 'react';
import styled, { css } from 'styled-components';

import { Grid } from '@material-ui/core';

import { RxInputText } from 'rx-core';

const Container = styled.div`
  width: 100%;
`;

const PharmacyTitleSection = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoRegular};
    font-weight: 400;
    margin-bottom: 1.5rem;

    ${theme.lessThan('ipad')} {
      padding: 0 1.5rem;
    }
  `}
`;

const GridResponsive = styled(Grid)`
  ${({ theme }) => css`
    ${theme.lessThan('ipad')} {
      &.MuiGrid-spacing-xs-2 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }

      &.MuiGrid-spacing-xs-4 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }
    }
  `}
`;

const SignupBuyerAdditionalInfoForm = (): JSX.Element => {
  return (
    <Container>
      <PharmacyTitleSection>Account Information</PharmacyTitleSection>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="additionalInfo.firstName"
            label="Buyer First Name *"
            placeholder="First Name"
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="additionalInfo.lastName"
            label="Buyer Last Name *"
            placeholder="Last Name"
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="additionalInfo.email"
            label="Email*"
            placeholder="yourname@pharmacyname.com"
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="additionalInfo.phoneNumber"
            label="Phone Number *"
            placeholder="(XXX) XXX-XXXX"
            withMask
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
    </Container>
  );
};

export { SignupBuyerAdditionalInfoForm };
