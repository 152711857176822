import React, { useCallback, useMemo } from 'react';
import { ShopCartItem } from 'rx-domain';
import { TableCell, TableRow } from '@material-ui/core';
import debounce from 'lodash.debounce';

import { formatMoney, US_STATES_WITH_CODES } from 'rx-utils';
import {
  AppDispatch,
  removeCartItem,
  setUnitsItemShoppingCart,
} from 'rx-store';
import { useDispatch } from 'react-redux';
import { useShoppingCartOnline } from 'rx-hooks';

import { Icon } from '../../shopping-cart/ShopCartCardWarning/index.styles';
import { DeleteIcon } from '../../shopping-cart/ShopCartDrugItem/index.styles';
import { ShopCartInput } from '../../shopping-cart/ShopCartInput';

interface IProductCardListProps {
  product: ShopCartItem;
  shippingState: string;
}

export const CheckoutShopCartDrugItem: React.FC<IProductCardListProps> = ({
  product,
  shippingState,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { onDeleteProduct, onUpdateShoppingCart } = useShoppingCartOnline();
  const shopCartAmount = useMemo(() => {
    return parseInt(product.count?.toString() ?? '0', 10);
  }, [product.count]);

  const cannotShipToState = product.product.shippingConstraints.includes(
    `${shippingState}`
  );

  const removeProduct = async () => {
    if (product.id) {
      await onDeleteProduct(product.id);
      dispatch(removeCartItem(product.product.id));
    }
  };

  const updateShopCart = useCallback(
    debounce(async (amount) => {
      await onUpdateShoppingCart(product.id, amount);
    }, 750),
    [product.id]
  );

  const validateNewAmount = useCallback(
    async (value: string) => {
      if (!value) {
        await onUpdateShoppingCart(product.id, 0);
        dispatch(
          setUnitsItemShoppingCart({
            id: product.product.id,
            amount: 0,
          })
        );
        return;
      }

      const amount = parseInt(value, 10);

      if (amount < 0) return;

      dispatch(
        setUnitsItemShoppingCart({
          id: product.product.id,
          amount,
        })
      );

      await updateShopCart(amount);
    },
    [dispatch, product, updateShopCart, onUpdateShoppingCart]
  );

  const substractOneAmount = useCallback(async () => {
    const newAmount = shopCartAmount - 1;
    await validateNewAmount(newAmount.toString());
  }, [shopCartAmount, validateNewAmount]);

  const updateShopCartItem = async (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    await validateNewAmount(target.value);
  };

  const addOneAmount = useCallback(async () => {
    const newAmount = shopCartAmount + 1;

    await validateNewAmount(newAmount.toString());
  }, [shopCartAmount, validateNewAmount]);

  const validAmount = useMemo(() => {
    const isValidMaximumQuantity = product.product?.maximumQuantity
      ? shopCartAmount <= product.product.maximumQuantity
      : true;
    return (
      shopCartAmount >= product.product.minimumQuantity &&
      isValidMaximumQuantity &&
      shopCartAmount <= product.product.units
    );
  }, [
    product.product.minimumQuantity,
    product.product.maximumQuantity,
    product.product.units,
    shopCartAmount,
  ]);

  return (
    <>
      <TableRow key={product.product.id}>
        <TableCell>{product.product.name}</TableCell>
        <TableCell>
          <ShopCartInput
            value={product.count ?? 0}
            onChange={updateShopCartItem}
            onAddOne={addOneAmount}
            onMinusOne={substractOneAmount}
            error={!validAmount}
          />
        </TableCell>
        <TableCell>
          {formatMoney(
            product.count *
              parseFloat(product.product.newPrice || product.product.price)
          )}
        </TableCell>
        <TableCell>
          <DeleteIcon data-cy="shopping-cart-remove" onClick={removeProduct} />
        </TableCell>
      </TableRow>

      {cannotShipToState && (
        <TableRow>
          <TableCell colSpan={3}>
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon />
              These products do not ship to{' '}
              {US_STATES_WITH_CODES.find(
                (stateCode) => stateCode.value === shippingState
              )?.name || shippingState}
              . Your order might be canceled.
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
