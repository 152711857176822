import styled from 'styled-components';
import { RxInputTable } from '../../inputs';

export const TableCell = styled.td`
  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  color: black;

  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-all;
`;

export const Input = styled(RxInputTable)``;
