import Skeleton from '@material-ui/lab/Skeleton';

export const CheckoutSummaryLoading: React.FC = () => {
  return (
    <>
      <Skeleton height={60}></Skeleton>
      <Skeleton height={60}></Skeleton>

      <div style={{ margin: '1rem 0', width: '50%' }}>
        <Skeleton height={30}></Skeleton>
        <Skeleton height={30}></Skeleton>
        <Skeleton height={30}></Skeleton>
      </div>

      <div style={{ margin: '1rem 0' }}>
        <Skeleton height={50}></Skeleton>
        <Skeleton height={50}></Skeleton>
        <Skeleton height={50}></Skeleton>
      </div>

      <div style={{ width: '50%' }}>
        <Skeleton height={30}></Skeleton>
        <Skeleton height={30}></Skeleton>
      </div>
    </>
  );
};
