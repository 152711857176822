import React from 'react';

import { Paper } from '@material-ui/core';
import { Title, Container, Text, LinkStyled } from './index.styles';

export const ShopCartEmpty: React.FC = () => {
  return (
    <Paper
      variant="outlined"
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <Container>
        <Title data-cy="shopping-cart-empty">Empty Shopping Cart!</Title>
        <Text>You can start shopping in one of our categories available!</Text>
        <LinkStyled to="products/?category=drug">
          <Text>Go to Rx Products</Text>
        </LinkStyled>
        <LinkStyled to="products/?category=medical_supply">
          <Text>Go to Medical and Diabetes Supplies</Text>
        </LinkStyled>
        <LinkStyled to="products/?category=diagnostic_test">
          <Text>Covid and Other Tests</Text>
        </LinkStyled>
      </Container>
    </Paper>
  );
};
