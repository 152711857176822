import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = styled(Grid)``;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: black;
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoLight};
    margin-bottom: 1rem;
  `}
`;

export const Subtitle = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoMedium};
    font-weight: 500;
    margin-bottom: 0.5rem;
  `}
`;

export const Content = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoLight};
    font-weight: 400;
    margin-bottom: 1rem;
  `}
`;
