import { useQuery } from 'react-query';
import { http } from 'rx-api/http/repository';

const getQuery = (
  accountType: number | null = null,
  email: string,
  excludeAdmin: boolean = false
) => {
  const params = new URLSearchParams();

  if (excludeAdmin) {
    params.append('excludeAdmin', 'true');
  }

  if (accountType) {
    params.append('type', accountType.toString());
  }

  if (email) {
    params.append('email', email);
  }

  return params.toString() ? `?${params.toString()}` : '';
};

export function useAdminGetAccountList(
  accountType: number | null = null,
  email: string,
  excludeAdmin: boolean = false
) {
  return useQuery(
    ['admin-get-account-list', accountType, email, excludeAdmin],
    () => {
      const query = getQuery(accountType, email, excludeAdmin);

      return http.get(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/accounts${query}`,
        {
          withCredentials: true,
        }
      );
    }
  );
}
