import styled, { css } from 'styled-components';
import { RxButton } from 'rx-core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoLight};
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgb(43, 54, 71, 0.35);
  `}
`;

export const LabelTitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.small};
    font-family: ${theme.fonts.robotoLight};
    margin-top: 1rem;
    margin-bottom: 6px;
  `}
`;

export const FooterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export const FooterSpacer = styled.div`
  flex: 1;
  border-bottom: 1px solid rgb(43, 54, 71, 0.35);
`;
