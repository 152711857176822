import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { AddPaymentProvider } from 'rx-contexts';
import { TemplateView, SEO } from 'rx-core';
import { useCheckoutProccess } from 'rx-hooks';

import { Alert } from '@material-ui/lab';
import { CheckoutError } from './CheckoutError';
import { CheckoutTitle } from './CheckoutTitle';
import { CheckoutBillInfo } from './CheckoutBillInfo/CheckoutBillInfo';
import { CheckoutNewBillInfo } from './CheckoutNewBillInfo/CheckoutNewBillInfo';
import { CheckoutShipAddress } from './CheckoutShipAddress';
import { CheckoutNewShipAddress } from './CheckoutNewShipAddress/CheckoutNewShipAddress';
import { CheckoutPayments } from './CheckoutPayments/CheckoutPayments';
import { CheckoutPlaceOrder } from './CheckoutPlaceOrder';
import { CheckoutLoading } from './CheckoutLoading';

import { CheckoutProductsList } from './CheckoutProductsList';

export const CheckoutView: React.FC = () => {
  const {
    hasPaymentTermsPermission,
    isPaymentTermsEnabled,
    handleChangePaymentMethod,
    setDefaultPaymentMethod,
    onPlaceOrder,
    checkoutError,
    addShipAddressModal,
    setAddShipAddressModal,
    addBillInfoModal,
    setAddBillInfoModal,
    selectedPharmacy,
    setSelectedPharmacy,
    pharmacyResponse,
    shippingAddressResponse,
    pharmaciesListResponse,
    cardsResponse,
    ACHCardsResponse,
    // requiresDEA,
    cardsResponseLoading,
    ACHCardsResponseLoading,
    canCheckout,
    checkoutLoading,
    checkoutSuccess,
    isLoading,
    isLoadingShopCart,
    paymentType,
    setCheckoutError,
  } = useCheckoutProccess();

  const leftRef = useRef<HTMLDivElement | null>(null);
  const [leftContainerValue, setLeftContainerValue] = useState(0);

  useEffect(() => {
    const cards = cardsResponse?.data.results || [];
    const achs = ACHCardsResponse?.data.results || [];

    const defaultCard = cards.find((pm) => pm.isDefault === true);
    const defaultAch = achs.find((pm) => pm.isDefault === true);

    if (defaultCard) {
      handleChangePaymentMethod('card', `${defaultCard.id}`);
      setDefaultPaymentMethod({ ...defaultCard, type: 'card' });
    }

    if (defaultAch) {
      handleChangePaymentMethod('ach', defaultAch.id);
      setDefaultPaymentMethod({ ...defaultAch, type: 'ach' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, cardsResponse, ACHCardsResponse]);

  useEffect(() => {
    if (leftRef.current) {
      setLeftContainerValue(leftRef.current.offsetHeight);
    }
  }, [
    cardsResponseLoading,
    ACHCardsResponseLoading,
    cardsResponse,
    ACHCardsResponse,
  ]);

  return (
    <TemplateView>
      <SEO title="RxRise | Checkout" />
      <AddPaymentProvider>
        <Grid
          style={{ padding: '15px' }}
          container
          spacing={2}
          data-cy="checkout-page"
        >
          {/* <Grid item xs={12}> */}
          {/*  <CheckoutTitle /> */}
          {/*  {requiresDEA && ( */}
          {/*    <Alert color="error"> */}
          {/*      This order requires you to add your DEA Registration Number. You */}
          {/*      can add it by{' '} */}
          {/*      <Link to={`/settings/pharmacies/${selectedPharmacy?.id}`}> */}
          {/*        clicking here */}
          {/*      </Link> */}
          {/*      . */}
          {/*    </Alert> */}
          {/*  )} */}
          {/* </Grid> */}
          {!checkoutSuccess && (isLoading || isLoadingShopCart) && (
            <Grid item xs={12}>
              <CheckoutLoading />
            </Grid>
          )}
          {!checkoutSuccess && !isLoading && !isLoadingShopCart && (
            <>
              <Grid item xs={12}>
                <Typography>
                  1. Shipping Location and Billing Information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CheckoutShipAddress
                  setSelectedPharmacy={setSelectedPharmacy}
                  selectedPharmacy={selectedPharmacy}
                  pharmaciesList={pharmaciesListResponse?.data}
                  pharmacyData={pharmacyResponse?.data}
                  shipAddressData={shippingAddressResponse?.data.address}
                  onAddShipAddress={() => setAddShipAddressModal(true)}
                />
                <CheckoutNewShipAddress
                  visible={addShipAddressModal}
                  onClose={() => setAddShipAddressModal(false)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CheckoutBillInfo
                  billInfoData={selectedPharmacy}
                  onAddBillInfo={() => setAddBillInfoModal(true)}
                />
                <CheckoutNewBillInfo
                  visible={addBillInfoModal}
                  setCheckoutError={setCheckoutError}
                  selectedPharmacy={selectedPharmacy}
                  onClose={() => {
                    setCheckoutError(null);
                    setAddBillInfoModal(false);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography>2. Payment Methods</Typography>
                <CheckoutPayments
                  innerRef={leftRef}
                  pharmacyId={selectedPharmacy?.id || 0}
                  cards={cardsResponse?.data.results ?? []}
                  achs={ACHCardsResponse?.data.results ?? []}
                  isLoading={cardsResponseLoading || ACHCardsResponseLoading}
                  hasPaymentTerm={hasPaymentTermsPermission}
                  onChangePaymentMethod={handleChangePaymentMethod}
                  selectedPaymentMethod={paymentType}
                  disabled={!isPaymentTermsEnabled}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography>3. Review Items</Typography>
                <CheckoutProductsList
                  leftContainerValue={leftContainerValue}
                  shippingState={selectedPharmacy?.shippingAddress?.state || ''}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid container style={{ paddingLeft: '15px', paddingRight: '15px' }}>
          {!checkoutSuccess && !isLoading && !isLoadingShopCart && (
            <Grid item xs={12}>
              {checkoutError && <CheckoutError {...checkoutError} />}
              <CheckoutPlaceOrder
                onPlaceOrder={onPlaceOrder}
                validate={canCheckout}
                loading={checkoutLoading}
              />
            </Grid>
          )}
        </Grid>
      </AddPaymentProvider>
    </TemplateView>
  );
};
