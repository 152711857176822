import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { TableCell, TableRow, Typography } from '@material-ui/core';

import { GetSellers } from 'rx-domain';

import { TransactionsOrderMenu } from '../../transactions-legacy/molecules/TransactionsOrderMenu';
import { MenuIcon } from './index.styles';

type IProps = {
  seller: GetSellers.GetManufacturer;
  onSelectSeller: (id: number) => void;
};

export const SellerListItem: React.FC<IProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const history = useHistory();

  const showSellerData = () => {
    history.push(`/manufacturers/${props.seller.id}`);
  };

  const onSendAPIKeyEmail = () => {
    props.onSelectSeller(props.seller.id);
  };

  const onOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow>
      <TableCell>
        <Typography>{props.seller.manufacturer.companyName}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{props.seller.manufacturer.phoneNumber}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{props.seller.manufacturer.email}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{props.seller.address?.address ?? 'N/A'}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{props.seller.address?.city ?? 'N/A'}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{props.seller.address?.state ?? 'N/A'}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{props.seller.address?.zipCode ?? 'N/A'}</Typography>
      </TableCell>

      <TableCell style={{ position: 'relative' }}>
        <MenuIcon
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={onOpenMenu}
        >
          <MoreVertIcon />
        </MenuIcon>
        <TransactionsOrderMenu
          anchorEl={anchorEl}
          onClose={onClose}
          options={[
            {
              onClick: showSellerData,
              text: 'Edit manufacturer',
              type: 'SAVE',
            },
            {
              onClick: onSendAPIKeyEmail,
              text: 'Email API Key',
              type: 'CANCEL_EDIT',
            },
          ]}
        />
      </TableCell>
    </TableRow>
  );
};
