import { useMutation } from 'react-query';

import { IGetOrderIdUniFormDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGetOrdersIdUniForm = () => {
  const onRequest = async ({ id }: IGetOrderIdUniFormDTO) => {
    return http.get(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/orders/${id}/universal-form`,
      {
        responseType: 'arraybuffer',
      }
    );
  };

  return useMutation(onRequest, {});
};

export { useGetOrdersIdUniForm };
