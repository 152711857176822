import styled, { css } from 'styled-components';
import Chip from '@material-ui/core/Chip';

export const Container = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 0.5rem;
  width: 60%;
`;

export const StateChip = styled(Chip)<{ active: boolean }>`
  ${({ theme, active, disabled }) => css`
    margin: 0.25rem;
    color: ${disabled ? theme.colors.gray[400] : theme.colors.blue[300]};
    border: 1px solid
      ${disabled ? theme.colors.gray[400] : theme.colors.blue[300]};
    background-color: ${active ? '#EFF3FF' : 'transparent'};
  `}
`;
