import { useQuery } from 'react-query';
import { http } from 'rx-api/http/repository';

export function useGetOrderAmountsMetrics(
  filterFormat: string,
  from: string,
  ediType: string
) {
  return useQuery(
    ['get-order-amount-metrics', filterFormat, from, ediType],
    async () => {
      return http.get(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/metrics/order-amounts?format=${filterFormat}&from=${from}&ediType=${ediType}`
      );
    }
  );
}
