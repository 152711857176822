import React from 'react';
import styled, { css } from 'styled-components';

import { SEO, TemplateView } from 'rx-core';

import { useQuery } from 'rx-hooks';
import { ApprovePharmacist } from '../organims/ApprovePharmacist';
import { ApproveManufacturer } from '../organims/ApproveManufacturer';
import { ApprovePharmacy } from '../organims/ApprovePharmacy';
import { Typography } from '@material-ui/core';

const Container = styled.div`
  ${({ theme }) => css`
    padding: 2.5rem 1.5rem;

    ${theme.lessThan('ipadPro')} {
      padding: 1rem;
    }
  `}
`;

const ApproveView: React.FC = () => {
  const query = useQuery();

  const type = query.get('type');
  return (
    <TemplateView>
      <SEO title="RxRise | Approve" />
      <Container
        style={{
          margin: '2rem 1.5rem',
          padding: '1rem',
          backgroundColor: 'white',
          border: 'solid 1px #cbcbcb',
        }}
      >
        <Typography variant="h4" style={{ marginBottom: '1rem' }}>
          Pending Approvals
        </Typography>
        {type === 'manufacter' && <ApproveManufacturer />}
        {type === 'pharmacist' && <ApprovePharmacist />}
        {type === 'pharmacy' && <ApprovePharmacy />}
        {!type && (
          <>
            <ApprovePharmacy />
            <ApprovePharmacist />
            <ApproveManufacturer />
          </>
        )}
      </Container>
    </TemplateView>
  );
};

export { ApproveView };
