import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const StateTextInfo = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.small};
    font-weight: 400;
    margin: 0.5rem 0 1rem 0;
  `}
`;
