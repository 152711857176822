import { PutSinglePictureProduct } from 'rx-domain';
import { PUT_ADMIN_PRODUCT_PICTURE_URL } from 'rx-api/api/admin/products/constants';
import { useRxFormDataMutation } from 'rx-api/utils';

const usePutPictureProduct = (params: PutSinglePictureProduct.Params) => {
  return useRxFormDataMutation<PutSinglePictureProduct.DTO, unknown>({
    method: 'put',
    url: PUT_ADMIN_PRODUCT_PICTURE_URL.replace('{id}', params.id.toString()),
  });
};

export { usePutPictureProduct };
