import { useRxMutation } from 'rx-api/utils';
import { URL_V1 } from 'rx-api/api/constants';

type CreateAccountRequestPayloadDto = {
  firstName: string;
  lastName: string;
  email: string;
  secondaryEmail: string;
  phoneNumber: string;
  password: string;
  type: number;
};

export const useCreateAccount = () => {
  return useRxMutation<CreateAccountRequestPayloadDto, unknown>({
    method: 'post',
    url: `${URL_V1}/admin/accounts`,
  });
};
