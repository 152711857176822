import React from 'react';
import styled from 'styled-components';

import { SignupManufacturerProvider } from 'rx-contexts';

import { SEO } from 'rx-core';

import { SignupSellerHeader } from '../molecules/SignupSellerHeader';
import { SignupSellerWizard } from '../molecules/SignupSellerWizard';
import { SignupSellerForm } from '../organims/SignupSellerForm';

const Container = styled.div``;

const SignupSeller = (): JSX.Element => {
  return (
    <SignupManufacturerProvider>
      <SEO title="RxRise | Manufacturer Sign up" />
      <SignupSellerHeader />
      <SignupSellerWizard />
      <Container>
        <SignupSellerForm />
      </Container>
    </SignupManufacturerProvider>
  );
};

export { SignupSeller };
