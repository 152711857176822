import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Menu, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { usePaginationQueries } from 'rx-hooks';

type IFilterType = {
  type: 'name' | 'ndc' | 'shipper';
  tag: string;
};

const Container = styled.div`
  ${({ theme }) => css`
    height: 100%;

    ${theme.lessThan('ipadPro')} {
      width: 20%;
    }
  `}
`;

const SelectType = styled.button`
  ${({ theme }) => css`
    background-color: white;
    height: 2.5rem;
    border-radius: 10px;
    border: 1px solid #9fa6b9;
    margin-left: 1rem;
    font-size: ${theme.sizes.medium};
    padding: 0.5rem;
    outline: none;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `}
`;

const SelectTypeText = styled.span`
  margin-right: 0.25rem;
`;

const filters: Array<IFilterType> = [
  { type: 'name', tag: 'Name' },
  { type: 'ndc', tag: 'NDC' },
  { type: 'shipper', tag: 'Shipping name' },
];

const AdminProductsSearchTypeFilter = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const history = useHistory();

  const { onMakeURL, querySearch, querySearchType } = usePaginationQueries();

  const filterType = filters.find((f) => f.type === querySearchType)?.tag ?? '';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value: IFilterType) => {
    setAnchorEl(null);

    const fullURL = onMakeURL({
      search: querySearch,
      t: value.type,
    });

    history.push(fullURL);
  };

  return (
    <Container>
      <SelectType
        aria-controls="search-admin-filter-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <SelectTypeText>{filterType}</SelectTypeText>
        <KeyboardArrowDownIcon />
      </SelectType>
      <Menu
        id="search-admin-filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {filters.map((filter) => (
          <MenuItem onClick={() => handleClose(filter)} key={filter.tag}>
            {filter.tag}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};

export { AdminProductsSearchTypeFilter };
