import { PostAdminAddACH } from 'rx-domain';
import { POST_ADMIN_ADD_ACH } from 'rx-api/api/admin/pharmacies/constants';
import { createUrlWithParams, useRxMutation } from 'rx-api/utils';

export const usePostAdminAddACH = (params: PostAdminAddACH.Params) => {
  return useRxMutation<PostAdminAddACH.DTO, unknown>({
    method: 'post',
    url: createUrlWithParams(POST_ADMIN_ADD_ACH, [
      {
        key: 'id',
        value: params.id,
      },
    ]),
  });
};
