import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import ErrorIcon from '@material-ui/icons/Error';
import { Button } from '@material-ui/core';

import { RxButtonPrimary } from 'rx-core';
import { IGetManufacturerIdResponse } from 'rx-domain';
import {
  useGetAdminManufacturerIdApiKey,
  usePostAdminManufacturerIdApiKey,
} from 'rx-api';
import { useToast } from 'rx-hooks';

import { ManufacturerApiKeyInput } from './ManufacturerApiKeyInput';
import { SellerDeleteAPIKey } from '../SellerDeleteAPIModal';

type IProps = IGetManufacturerIdResponse;

const Title = styled.h2`
  padding-bottom: 1.5rem;
`;

const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    padding-bottom: 1.5rem;
  `}
`;

const Warning = styled.div`
  background: rgba(255, 234, 147, 0.5);
  border: 1px solid #ffce03;

  display: flex;
  align-items: center;
  padding: 0.75rem;
  width: fit-content;
  margin-bottom: 1.5rem;
`;

const WarningIcon = styled(ErrorIcon)`
  color: #ffce03;
  margin-right: 0.75rem;
`;

const DeleteButton = styled(RxButtonPrimary)`
  ${({ theme }) => css`
    color: ${theme.colors.red[100]};
    border: 1px ${theme.colors.red[100]} solid;
    background-color: white;
  `}
`;

const APIContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ManufacturerApiKey: React.FC<IProps> = (props) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const { data: apiKeyData, refetch } = useGetAdminManufacturerIdApiKey({
    id: props.id,
  });

  const genApiKey = usePostAdminManufacturerIdApiKey();

  const { onSuccessToast, onErrorToast } = useToast();

  const onCreateAPIKey = async () => {
    try {
      await genApiKey.mutateAsync({
        id: props.id,
      });
      await refetch();
      onSuccessToast('API Key created');
    } catch (err) {
      onErrorToast('Error: Could not generate API key');
    }
  };

  return (
    <>
      {apiKeyData?.data && apiKeyData?.data.apiKey ? (
        <>
          <Title>API Key</Title>
          <APIContainer>
            <ManufacturerApiKeyInput apiKey={apiKeyData?.data.apiKey ?? ''} />
            <DeleteButton
              text="DELETE API KEY"
              width="11.5rem"
              onClick={() => setDeleteModal(true)}
            />
          </APIContainer>
          <SellerDeleteAPIKey
            visible={deleteModal}
            onClose={() => setDeleteModal(false)}
            idKey={props.id}
          />
        </>
      ) : (
        <>
          <Title>Key Generation</Title>
          <Subtitle>
            Create an API key to make calls to RxRise services.
          </Subtitle>
          <Warning>
            <WarningIcon />
            <span>You have not generated any API keys yet.</span>
          </Warning>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={onCreateAPIKey}
            disabled={genApiKey.isLoading}
          >
            Create API Key
          </Button>
        </>
      )}
    </>
  );
};
