import React from 'react';
import { TemplateView, SEO } from 'rx-core';
import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { PurchaseOrdersTable } from './PurchaseOrdersTable';

export const Container = styled.div`
  padding: 0;
  margin: 1.5rem;
`;

export const PurchaseOrderList: React.FC = () => {
  return (
    <TemplateView>
      <SEO title="RxRise | Purchase Orders" />

      <Container>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography style={{ color: '#5a82ff', fontSize: '30px' }}>
            Orders
          </Typography>
          <Link style={{ textDecoration: 'none' }} to="/purchase-orders/new">
            <Button variant="contained" color="primary">
              Place Order
            </Button>
          </Link>
        </div>
        <PurchaseOrdersTable />
      </Container>
    </TemplateView>
  );
};
