import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { ITableHeader, UserApprovalStatus } from 'rx-domain';

import { RxPagination } from 'rx-core';
import { useGetTransactions } from 'rx-api';
import { usePaginationQueries, useSkipPage } from 'rx-hooks';

import { useFilter, useTransactions } from 'rx-contexts';
import { Skeleton } from '@material-ui/lab';
import { Container, PaginationContainer } from './index.styles';

import { TransactionOrders } from '../TransactionOrders';
import { TransactionGenerateModal } from '../../transactions-legacy/molecules/TransactionGenerateModal';

const TRANSACTION_HEADER: Array<ITableHeader> = [
  { title: 'ORDER ID', fontWeight: 'bold', span: 1 },
  { title: 'DATE', fontWeight: 'bold', span: 1 },
  { title: 'PAYMENT METHOD', fontWeight: 'bold', span: 1 },
  { title: 'DUE DATE', fontWeight: 'bold', span: 1 },
  { title: 'PAYOUT DATE', fontWeight: 'bold', span: 1 },
  { title: 'GROSS AMOUNT', fontWeight: 'bold', span: 1 },
  { title: 'NET AMOUNT', fontWeight: 'bold', span: 1 },
  { title: 'INVOICE STATUS', fontWeight: 'bold', span: 1 },
  { title: '', span: 1 },
];

export const TransactionTab: React.FC = () => {
  const [length, setLength] = useState(0);

  const {
    onSetModalGenerate,
    onSetTotalTransactions,
    percent,
    modalGenerate,
    typeDoc,
    error,
    success,
    loading,
  } = useTransactions();
  const { onSetLoading, search } = useFilter<UserApprovalStatus>();
  const { querySeller, queryBuyer, queryFrom, queryTo } =
    usePaginationQueries();

  const { skip, totalPages } = useSkipPage(length);

  const {
    data: transactions,
    isLoading,
    refetch,
  } = useGetTransactions({
    skip,
    compoundOrderId: search,
    buyer: queryBuyer,
    seller: querySeller,
    from: queryFrom,
    to: queryTo,
  });

  const onCloseModal = () => {
    onSetModalGenerate(false);
  };

  useEffect(() => {
    if (transactions?.data && !isLoading) {
      setLength(transactions.data.pagination.total);
      onSetTotalTransactions(transactions.data.pagination.total);
    }
  }, [transactions, isLoading, onSetTotalTransactions]);

  useEffect(() => {
    if (!modalGenerate) refetch();
  }, [modalGenerate, refetch]);

  useEffect(() => {
    onSetLoading(isLoading);
  }, [isLoading, onSetLoading]);

  return (
    <Container>
      {!isLoading && (
        <Table>
          <TableHead>
            <TableRow>
              {TRANSACTION_HEADER.map((header) => (
                <TableCell key={header.title}>{header.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {!isLoading && transactions?.data.results && (
              <TransactionOrders items={transactions.data.results} />
            )}

            {!isLoading && !transactions?.data?.results?.length && (
              <tr>
                <td colSpan={9}>
                  <Typography align="center" style={{ margin: '2rem 0' }}>
                    No transactions found
                  </Typography>
                </td>
              </tr>
            )}

            {isLoading && (
              <>
                <tr>
                  <td colSpan={9}>
                    <Skeleton height={50} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={9}>
                    <Skeleton height={50} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={9}>
                    <Skeleton height={50} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={9}>
                    <Skeleton height={50} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={9}>
                    <Skeleton height={50} />
                  </td>
                </tr>
              </>
            )}
          </TableBody>
        </Table>
      )}

      <TransactionGenerateModal
        onClose={onCloseModal}
        percent={percent}
        visible={modalGenerate}
        type={typeDoc}
        error={error}
        success={success}
        loading={loading}
      />
      {totalPages > 1 && (
        <PaginationContainer>
          <RxPagination path="transactions" totalPages={totalPages} />
        </PaginationContainer>
      )}
    </Container>
  );
};
