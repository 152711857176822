import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { LayoutProps, SpaceProps } from 'styled-system';
import { usePopper } from 'react-popper';

import { theme, Colors } from 'rx-styles';

export type PopperTableProps = LayoutProps &
  SpaceProps & {
    children: React.ReactNode;
    color: Colors;
  };

const ColumnClamp = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const PopperContainer = styled.div<{ color: Colors }>`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: white;
  padding: 16px;
  text-align: center;
  max-width: 220px;
  z-index: 999;

  font-family: ${({ theme: { fonts } }) => fonts.robotoLight};

  color: ${({ color }) => `${color} !important`};

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;

    &:after {
      content: ' ';
      position: absolute;
      top: -20px;
      left: -20.5px;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      background-color: white;
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    }
  }
`;

const PopperTable = ({
  children,
  color,
  ...props
}: PopperTableProps): JSX.Element => {
  const [isVisible, setVisibility] = useState(false);

  const referenceElement = useRef(null);
  const popperElement = useRef(null);
  const [arrowRef, setArrowRed] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      modifiers: [
        { name: 'arrow', options: { element: arrowRef } },
        {
          name: 'offset',
          options: {
            offset: [-40, 4],
          },
        },
      ],
    }
  );

  return (
    <>
      <ColumnClamp
        {...props}
        ref={referenceElement}
        onMouseEnter={() => setVisibility(true)}
        onMouseLeave={() => setVisibility(false)}
      >
        {children}
      </ColumnClamp>
      {isVisible && (
        <PopperContainer
          color={color}
          ref={popperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div ref={setArrowRed} style={styles.arrow} className="arrow" />
          {children}
        </PopperContainer>
      )}
    </>
  );
};

PopperTable.defaultProps = {
  color: theme.colors.black[100],
};

export { PopperTable };
