import styled, { css } from 'styled-components';
import { RRModal, RxButtonSimple } from 'rx-core';
import { Grid } from '@material-ui/core';

export const Modal = styled(RRModal)`
  height: fit-content;
  width: 31.5rem;
`;

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoRegular};
    margin-top: 1rem;
    font-size: ${theme.sizes.h2};
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(43, 54, 71, 0.35);
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoRegular};
    font-size: ${theme.sizes.medium};
    margin-top: 1rem;
    margin-bottom: 1rem;
  `}
`;

export const Spacer = styled.div`
  flex: 1 1;
`;

export const Actions = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

export const MainButton = styled(RxButtonSimple)`
  width: 13rem;
  margin-left: 10px;
`;
