import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';

import { useMOFilterStatus } from 'rx-hooks';

import { Container, SelectType, SelectTypeText } from './index.styles';

const MOFilterStatus = (): JSX.Element => {
  const { anchorEl, onClose, onOpenMenu, menuOptions, filterType } =
    useMOFilterStatus();

  return (
    <Container>
      <SelectType
        aria-controls="mo-filter-status-menu"
        aria-haspopup="true"
        onClick={onOpenMenu}
      >
        <SelectTypeText>{filterType}</SelectTypeText>
        <KeyboardArrowDownIcon />
      </SelectType>
      <Menu
        id="mo-filter-status-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => onClose()}
      >
        {menuOptions.map((filter) => (
          <MenuItem onClick={() => filter.onClick()} key={filter.type}>
            {filter.text}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};

export { MOFilterStatus };
