import styled from 'styled-components';

export const InfoColumn = styled.div`
  width: 20%;
  padding: 1rem;
  border-right: 1px #e9ecfe solid;
`;

export const InvoiceLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
