import { GetSellers } from 'rx-domain';
import {
  GET_SELLER_URL,
  GET_SELLER_KEY,
} from 'rx-api/api/admin/manufacturers/constants';
import { useGetRequest } from 'rx-api/utils';

const makeParams = (req: Partial<GetSellers.Query>) => {
  let query = `?limit=10`;

  if (req.limit) {
    query = `?limit=${req.limit}`;
  }

  if (req.skip) {
    query += `&skip=${req.skip}`;
  }

  if (req.approvalStatus) {
    query += `&approvalStatus=${req.approvalStatus}`;
  }

  if (req.shipper) {
    query += `&shipper=${req.shipper}`;
  }

  return query;
};

export const useGetSellers = (query: Partial<GetSellers.Query>) => {
  return useGetRequest<Partial<GetSellers.Query>, GetSellers.Response>({
    url: GET_SELLER_URL,
    key: [
      GET_SELLER_KEY,
      query.limit,
      query.skip,
      query.approvalStatus,
      query.shipper,
    ],
    makeParams,
    params: query,
  });
};
