import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px rgba(131, 140, 165, 0.35) solid;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    margin-left: 0.5rem;
    font-weight: 400;
    font-size: ${theme.sizes.h2};
  `}
`;
