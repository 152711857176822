import React from 'react';

import { SearchQueryParams } from 'rx-domain';
import { TableCell, TableFooter, TableRow } from '@material-ui/core';

import { ReportDownload } from '../PaymentsReportDownload';
import { ReportPagination } from '../PaymentsReportPagination';

import { TotalReports, ActionContainer, Actions } from './index.styles';

type IProps = {
  totalPages: number;
  queries: SearchQueryParams;
  totalItems: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  skip: number;
};

export const ReportFooter: React.FC<IProps> = (props): JSX.Element => {
  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={12}>
          <Actions>
            <TotalReports>{props.totalItems} total reports</TotalReports>

            <ActionContainer>
              {props.totalItems > 1 && (
                <ReportPagination
                  currentPage={props.currentPage}
                  setCurrentPage={props.setCurrentPage}
                  totalPages={props.totalPages}
                  queries={props.queries}
                />
              )}

              <ReportDownload skip={props.skip} totalItems={props.totalItems} />
            </ActionContainer>
          </Actions>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};
