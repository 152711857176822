import { GetShoppingCart, UserType } from 'rx-domain';
import {
  GET_SHOPPING_CART_ITEMS_KEY,
  GET_SHOPPING_CART_ITEMS_URL,
} from 'rx-api/api/pharmacist/shopping-cart/constants';
import { useGetRequest } from 'rx-api/utils';

export const useGetShoppingCart = (type: UserType) => {
  return useGetRequest<unknown, GetShoppingCart.Response>({
    url: GET_SHOPPING_CART_ITEMS_URL,
    key: [GET_SHOPPING_CART_ITEMS_KEY],
    reactQueryOptions: {
      enabled: type === 'pharmacist',
      cacheTime: 0,
    },
  });
};
