import { useQuery } from 'react-query';

import { GetPharmacyById } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetPharmacy = (id: string) => {
  return useQuery(
    ['get-admin-pharmacists-id', id],
    async () => {
      return http.get<GetPharmacyById.ResponsePayloadDto>(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/pharmacies/${id}`,
        { withCredentials: true }
      );
    },
    {}
  );
};
