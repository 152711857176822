import React from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { useQueryClient } from 'react-query';

import {
  useAddAccountEmailRecipient,
  useDeleteAccountEmailRecipient,
  useGetAccountEmailRecipients,
} from 'rx-api';
import { useToast } from 'rx-hooks';
import { Skeleton } from '@material-ui/lab';

export const SettingsEmailRecipients: React.FC = () => {
  const [newEmail, setNewEmail] = React.useState('');
  const [isDeleteEmailRecipientModalOpen, setIsDeleteEmailRecipientModalOpen] =
    React.useState(false);
  const [selectedEmailRecipientId, setSelectedEmailRecipientId] =
    React.useState<null | number>(null);
  const { isLoading, data } = useGetAccountEmailRecipients();
  const addAccountEmailRecipient = useAddAccountEmailRecipient();
  const deleteAccountEmailRecipient = useDeleteAccountEmailRecipient();
  const toast = useToast();
  const queryClient = useQueryClient();

  const handleSubmitAddEmailRecipient = async (event: any) => {
    try {
      event.preventDefault();

      await addAccountEmailRecipient.mutateAsync({
        email: event.target.email.value,
      });

      setNewEmail('');

      await queryClient.refetchQueries('get-account-email-recipients', {
        active: true,
      });

      toast.onSuccessToast('Email Recipient Added Successfully');
    } catch (err) {
      toast.onErrorToast('Error: could not add email recipient');
    }
  };

  const handleChangeNewEmail = (event: any) => {
    setNewEmail(event.target.value);
  };

  const onClickCloseDeleteEmailRecipientModal = () => {
    setIsDeleteEmailRecipientModalOpen(false);
  };

  const handleClickDeleteEmailRecipient = (id: number) => () => {
    setIsDeleteEmailRecipientModalOpen(true);
    setSelectedEmailRecipientId(id);
  };

  const onClickConfirmDeleteEmailRecipient = async () => {
    try {
      await deleteAccountEmailRecipient.mutateAsync(
        selectedEmailRecipientId || 0
      );

      await queryClient.refetchQueries('get-account-email-recipients', {
        active: true,
      });

      setIsDeleteEmailRecipientModalOpen(false);
    } catch (err) {
      toast.onErrorToast('Error: could not delete email recipient');
    }
  };

  return (
    <Paper style={{ padding: '1rem' }} variant="outlined">
      <Typography variant="h5">Email Recipients</Typography>
      <Typography variant="subtitle1" style={{ marginBottom: '0.75rem' }}>
        Current list of email recipients
      </Typography>

      {isLoading && (
        <>
          <Skeleton height={60} />
          <Skeleton height={60} />
          <Skeleton height={60} />
        </>
      )}

      {data?.data.map((emailRecipient: any) => {
        return (
          <Grid container spacing={2} alignItems="center" alignContent="center">
            <Grid item xs={6}>
              <TextField
                key={emailRecipient.id}
                value={emailRecipient.email}
                variant="outlined"
                margin="dense"
                disabled
                fullWidth
              />
            </Grid>

            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleClickDeleteEmailRecipient(emailRecipient.id)}
              >
                <DeleteForeverIcon />
              </Button>
            </Grid>
          </Grid>
        );
      })}

      <Divider style={{ margin: '1rem 0' }} />

      <form onSubmit={handleSubmitAddEmailRecipient}>
        <Typography variant="h6" style={{ marginBottom: '0.5rem' }}>
          Add a new email recipient
        </Typography>
        <Grid container spacing={2} alignItems="center" alignContent="center">
          <Grid item xs={6}>
            <TextField
              fullWidth
              name="email"
              id="emailRecipientNewEmail"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              label="Email"
              placeholder="email@test.com"
              type="email"
              value={newEmail}
              onChange={handleChangeNewEmail}
              margin="dense"
              required
            />
          </Grid>

          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={addAccountEmailRecipient.isLoading}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </form>

      <Dialog
        open={isDeleteEmailRecipientModalOpen}
        onClose={onClickCloseDeleteEmailRecipientModal}
        aria-labelledby="delete-email-recipient-dialog-title"
        aria-describedby="delete-email-recipient-dialog-description"
      >
        <DialogTitle id="delete-email-recipient-dialog-title">
          Are you sure you want to delete this email recipient?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-account-dialog-description">
            This action is permanent and cannot be undo.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
          }}
        >
          <Button
            onClick={onClickCloseDeleteEmailRecipientModal}
            color="primary"
            variant="outlined"
            disabled={deleteAccountEmailRecipient.isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={onClickConfirmDeleteEmailRecipient}
            color="secondary"
            variant="outlined"
            disabled={deleteAccountEmailRecipient.isLoading}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
