import { useMutation } from 'react-query';

import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGetAdminEDIInventory = () => {
  const onRequest = async () => {
    return http.get(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/edi/inventory`
    );
  };

  return useMutation(onRequest, {});
};

export { useGetAdminEDIInventory };
