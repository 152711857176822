import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { IAuthData } from 'rx-domain';

import { usePostAuthLogout } from 'rx-api';

import { useLocalStorage } from './useLocalStorage';

type UseAuthHook = IAuthData & {
  isAuth: boolean;
  setUserData: (value: unknown) => void;
  logout: () => Promise<void>;
};

const useAuth = (): UseAuthHook => {
  const [userData, setUserData]: [IAuthData, (value: unknown) => void] =
    useLocalStorage('_user', null);

  const mutation = usePostAuthLogout();
  const history = useHistory();

  const logout = useCallback(async () => {
    try {
      await mutation.mutateAsync();
      localStorage.clear();
      history.push('/');
      window.location.reload();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }, [history, mutation]);

  return {
    isAuth: !!userData,
    setUserData,
    logout,
    ...userData,
  };
};

export { useAuth };
