import { BREAKPOINTS } from 'rx-styles';
import { useMediaQuery } from 'rx-hooks/utils/useMediaQuery';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useResponsive = () => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.desktop})`);
  const isIpad = useMediaQuery(`(max-width: ${BREAKPOINTS.ipad})`);
  const isIpadPro = useMediaQuery(`(max-width: ${BREAKPOINTS.ipadPro})`);
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.mobile})`);

  const getMediaQueryProps = () => {
    return {
      isDesktop,
      isIpad,
      isIpadPro,
      isMobile,
    };
  };

  const getMediaQueryPropsTransient = () => {
    return {
      $isDesktop: isDesktop,
      $isIpad: isIpad,
      $isIpadPro: isIpadPro,
      $isMobile: isMobile,
    };
  };

  return {
    getMediaQueryProps,
    getMediaQueryPropsTransient,
    isDesktop,
    isIpad,
    isIpadPro,
    isMobile,
  };
};

export { useResponsive };
