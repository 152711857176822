import React, { useState } from 'react';
import { Button } from '@material-ui/core';

import { ReportDownloadPicker } from '../PaymentsReportDownloadPicker';

type IProps = {
  totalItems: number;
  skip: number;
};

export const ReportDownload: React.FC<IProps> = (props) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpenModal(true)}
        disabled={props.totalItems === 0}
        variant="contained"
        color="primary"
      >
        Download CSV
      </Button>

      <ReportDownloadPicker
        skip={props.skip}
        totalItems={props.totalItems}
        visible={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};
