import React from 'react';

import { IOrderManufacturer } from 'rx-domain';

import { Container, Spacer } from './index.styles';
import { MOItem } from '../MOItem';

type IProps = {
  items: Array<IOrderManufacturer>;
};

const MOItems = (props: IProps): JSX.Element => {
  return (
    <Container>
      {props.items.map((item) => (
        <React.Fragment key={item.id}>
          <Spacer />
          <MOItem {...item} />
        </React.Fragment>
      ))}
    </Container>
  );
};

export { MOItems };
