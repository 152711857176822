import React from 'react';

import { IGetPharmacistPaymentMethodsResponse } from 'rx-domain';

import { PaymentList } from 'pages/buyer/settingsV2/SettingsPaymentInfo/SettingsPaymentMethods/PaymentList';
import { AddPaymentMethodModal } from 'pages/shared/payment-method/organims';

import { Title } from '../styles/index.styles';

type IProps = {
  data: IGetPharmacistPaymentMethodsResponse;
};

export const PharmacyProfilePaymentMethods: React.FC<IProps> = (props) => {
  return (
    <>
      <Title>Payment methods</Title>
      <PaymentList achs={props.data.ach} cards={props.data.cards} />
      <AddPaymentMethodModal />
    </>
  );
};
