import React from 'react';
import styled, { css } from 'styled-components';
import { color, ColorProps } from 'styled-system';

import { FilterItemMaterial } from 'rx-domain';

export type RxFilterMaterialItemProps<T> = FilterItemMaterial<T> & {
  selected: boolean;
};

const Text = styled.li<ColorProps & { selected: boolean }>`
  ${({ theme, selected }) => css`
    margin-bottom: 16px;
    list-style: none;
    text-align: left;
    ${color};

    font-weight: 700;
    font-family: ${theme.fonts.robotoLight};
    cursor: pointer;

    ${selected &&
    css`
      background-color: ${theme.colors.white[100]};
      color: ${theme.colors.black[100]};
      padding: 4px;
      border-radius: 4px;
    `}
  `}
`;

const RxFilterMaterialItem = <T extends string>({
  title,
  selected,
  ...props
}: RxFilterMaterialItemProps<T>): JSX.Element => {
  return (
    <Text {...props} selected={selected}>
      {title}
    </Text>
  );
};

export { RxFilterMaterialItem };
