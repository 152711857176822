import { HTMLAttributes } from 'react';

import {
  ColorProps,
  LayoutProps,
  SpaceProps,
  TypographyProps,
} from 'styled-system';

export type { ShopCartItem } from './ShopCartItem';
export type { ISignupFormPharmacy } from './ISignupFormPharmacy';
export type { ISigninManufacturer } from './ISigninManufacturer';
export type { ISignupFormValidationView } from './ISignupFormValidationView';
export type { ISignupFormPersonalInfoView } from './ISignupFormPersonalInfoView';
export type { IBuyerSearchFilterForm } from './IBuyerSearchFilterForm';
export type { IBuyerSearchFilterSortBy } from './IBuyerSearchFilterSortBy';
export type { IBuyerSearchFilterType } from './IBuyerSearchFilterType';
export type { IRxTableSearchType } from './IRxTableSearchType';
export type { ShippingConfig } from './ShippingConfig';
export type { SearchQueryParams } from './SearchQueryParams';
export type { TableCellItem } from './TableCellItem';
export type { InventoryType } from './InventoryType';

export interface IPaymentMethod {
  cardType: string;
  expirationDate: string;
  maskedNumber: string;
  token: string;
  last4: string;
}

export interface IPaymentACHMethod {
  id: string;
  accountHolderName: string;
  bankName: string;
  country: string;
  currency: string;
  last4: string;
  routingNumber: string;
  isVerified: boolean;
}

export interface IPaymentACHMethodResult {
  results: IPaymentACHMethod[];
}

export type UserType = 'admin' | 'manufacturer' | 'pharmacist';

export type DataState = 'success' | 'error' | 'none' | 'info';

export type ITableHeader = SpaceProps &
  LayoutProps &
  TypographyProps & {
    title: string;
    type?: 'input' | 'checkbox';
    span: number;
  };

export type ShoppingCartState = 'BUYING' | 'CHECKOUT' | 'ORDER_CONFIRMATION';

export type UploadManualProductColumnProps = ITableHeader;

export type UserApprovalStatus = 'approved' | 'pending' | 'rejected' | 'any';

export type StepUploadType =
  | 'CHOOSE-MANUFACTURER'
  | 'CHOOSE-CSV-TYPE'
  | 'CHOOSE'
  | 'PREVIEW'
  | 'UPLOADING'
  | 'FINISH';

export type TransactionFileType =
  | 'CONSOLIDATED-INVOICE'
  | 'CONSOLIDATED-RECEIPT'
  | 'SALE-ORDER-INVOICE'
  | 'PURCHASE-ORDER-INVOICE'
  | 'PURCHASE-ORDER-RECEIPT';

export type ErrorCSV = {
  message: string;
  type: string;
  index: number;
};

export type FilterItem = HTMLAttributes<HTMLLIElement> &
  ColorProps & {
    title: string;
    value: UserApprovalStatus;
  };

export type FilterItemMaterial<T> = HTMLAttributes<HTMLLIElement> &
  ColorProps & {
    title: string;
    value: T;
  };

export type ManufacturerPurchaseOrderStatus =
  | 'all'
  | 'fulfilled'
  | 'disputed'
  | 'shipped'
  | 'pending'
  | 'delivered';

export type CheckoutOrderError =
  | 'BROKEN'
  | 'Required documents missing'
  | 'Payment Term Forbidden';

export type CheckoutRequireFileItemError = {
  templateId: number;
  name: string;
  url: string;
  manufacturer: string;
};

export type CheckoutRequireFilesError = {
  error: string;
  message: string;
  statusCode: string;
  meta: {
    items: Array<CheckoutRequireFileItemError>;
  };
};

export type CheckoutTypeError =
  | 'Insufficient funds'
  | 'BROKEN'
  | 'Payment Term Forbidden'
  | 'Minimum Order Total of $150 USD'
  | 'Unverified ACH Account'
  | 'Not Enough Inventory'
  | 'Minimum Purchase Orders Amount'
  | 'Empty Shopping Cart'
  | 'payment_term_minimum_amount'
  | 'Grace Period Forbidden'
  | 'No billing address'
  | 'No shipping address'
  | 'ACH limit exceeded'
  | 'Your card was declined'
  | 'Pharmacy is not approved'
  | 'Missing DEA and/or State License'
  | 'Products cannot be shipped to restricted states';

export enum CheckoutErrorEnum {
  PHARMACY_NOT_APPROVED = 'Pharmacy is not approved',
  InsufficientFunds = 'Insufficient funds',
  BROKEN = 'BROKEN',
  PAYMENT_TERM_FORBIDDEN = 'Payment Term Forbidden',
  MINIMUM_ORDER_AMOUNT = 'Minimum Order Total of $150 USD',
  UNVERIFIED_ACH = 'Unverified ACH Account',
  NOT_ENOUGH_INVENTORY = 'Not Enough Inventory',
  MINIMUM_PURCHASE_ORDER_AMOUNT = 'Minimum Purchase Orders Amount',
  EMPTY_SHOPPING_CART = 'Empty Shopping Cart',
  PAYMENT_TERM_MINIMUM_AMOUNT = 'payment_term_minimum_amount',
  GRACE_PERIOD_FORBIDDEN = 'Grace Period Forbidden',
  NO_BILLING_ADDRESS = 'No billing address',
  NO_SHIPPING_ADDRESS = 'No shipping address',
  ACH_LIMIT_EXCEEDED = 'ACH limit exceeded',
  CARD_DECLINED = 'Your card was declined',
  MISSING_LEGAL_DOCUMENTS = 'Missing DEA and/or State License',
  RestrictedState = 'Products cannot be shipped to restricted states',
}

export type MinPoAmountErrorItem = {
  minimumOrderAmount: string;
  seller: string;
};

export type CheckoutErrorMinPOAmount = {
  error: string;
  message: string;
  statusCode: number;
  meta: {
    items: Array<MinPoAmountErrorItem>;
  };
};
