import React from 'react';

import { GetFavorites, ITableHeader } from 'rx-domain';

import { RxTableHeader } from 'rx-core';

import { FavoritesItems } from '../FavoriteItems';

import { Table } from './index.styles';
import { FavoriteEmpty } from '../FavoriteEmpty';

const headerItems: ITableHeader[] = [
  {
    title: 'PRODUCT ID',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 1,
  },
  {
    title: 'NDC',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 1,
  },
  {
    title: 'NAME',
    width: '12%',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 1,
  },
  {
    title: 'DESCRIPTION',
    width: '10%',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 1,
  },
  {
    title: 'MANUFACTURER',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 1,
  },
  {
    title: 'SHIPPING NAME',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 1,
  },
  {
    title: 'PRICE',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 1,
  },
  {
    title: 'EXP. DATE',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 1,
  },
  {
    title: 'LOT NUMBER',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 1,
  },
  {
    title: 'QUANTITY',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 1,
  },
  {
    title: 'MIN ORDER',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 1,
  },
  {
    title: 'STATE RESTRICTIONS',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    width: '4rem',
    span: 1,
  },
  {
    title: '',
    span: 1,
  },
];

type IProps = {
  products: GetFavorites.Response | undefined;
};

const FavoriteTable = (props: IProps): JSX.Element => {
  return (
    <Table>
      <RxTableHeader items={headerItems} />
      {props.products && props.products.results.length > 0 && (
        <FavoritesItems items={props.products.results} />
      )}
      {!props.products && <FavoriteEmpty />}
      {props.products && props.products.results.length === 0 && (
        <FavoriteEmpty />
      )}
    </Table>
  );
};

export { FavoriteTable };
