import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import { RRModalProps } from 'rx-core';

import { useDeleteAPIKey } from 'rx-api';

import {
  Container,
  ActionContainer,
  Content,
  Modal,
  Title,
  ActionButton,
} from './index.styles';

type IProps = RRModalProps & {
  idKey: number;
};

const SellerDeleteAPIKey = (props: IProps): JSX.Element => {
  const [successDelete, setSuccessDelete] = useState(false);

  const queryClient = useQueryClient();

  const deleteAPIKey = useDeleteAPIKey();

  const onDeleteKey = async () => {
    try {
      await deleteAPIKey.mutateAsync({
        id: props.idKey,
      });

      setSuccessDelete(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const onCancel = async () => {
    try {
      await queryClient.refetchQueries('get-admin-manufacturers-id-api-key', {
        active: true,
      });

      props.onClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <Modal {...props}>
      <Container>
        {!successDelete ? (
          <>
            <Title>Remove API Key?</Title>
            <Content>
              If you remove the API Key key, the manufacturer wont authenticate
              calls to RxRise’s APIs anymore!
            </Content>
          </>
        ) : (
          <>
            <Title>API Key removed!</Title>
            <Content>Key removed with suceess!</Content>
          </>
        )}
        <ActionContainer>
          <ActionButton onClick={!successDelete ? onDeleteKey : onCancel}>
            {!successDelete ? 'Eliminate Key' : 'Close'}
          </ActionButton>
        </ActionContainer>
      </Container>
    </Modal>
  );
};

export { SellerDeleteAPIKey };
