import { IGetPharmacistPharmacyResponse } from 'rx-domain';

import { useGetRequest } from 'rx-api/utils';

import { GET_PHARMACY_DATA_URL } from './constants';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetPharmacistPharmacy = () => {
  return useGetRequest<unknown, IGetPharmacistPharmacyResponse>({
    url: GET_PHARMACY_DATA_URL,
    key: ['get-pharmacist-data'],
  });
};
