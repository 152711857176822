import styled from 'styled-components';
import {
  space,
  layout,
  typography,
  TypographyProps,
  SpaceProps,
  LayoutProps,
} from 'styled-system';

type TableCellProps = { isSelectedAll?: boolean } & SpaceProps &
  LayoutProps &
  TypographyProps;

export const TableHead = styled.thead`
  display: table-header-group;
`;

export const TableRow = styled.tr`
  color: inherit;
  display: table-row;
  vertical-align: middle;
`;

export const TableCell = styled.th<TableCellProps>`
  color: black;

  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: left;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  border-bottom: 1px solid #9fa6b9;

  ${space};
  ${layout};
  ${typography};
`;

export const TableAllCheckbox = styled.input`
  cursor: pointer;
`;
