import { Paper, Typography } from '@material-ui/core';

const usCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const OrderAmountReportsTooltip = ({ payload, label, active }: any) => {
  if (active && payload && payload.length) {
    return (
      <Paper variant="outlined" style={{ padding: '1rem' }}>
        <Typography variant="h6">{`${label}`}</Typography>
        <Typography>{`Total Order Amount: ${usCurrencyFormatter.format(
          payload[0].value + payload[1].value
        )}`}</Typography>
        <Typography
          style={{ color: '#008080' }}
        >{`Seller Split: ${usCurrencyFormatter.format(
          payload[1].value
        )}`}</Typography>
        <Typography
          style={{ color: '#FF7F50' }}
        >{`RxRise Split: ${usCurrencyFormatter.format(
          payload[0].value
        )}`}</Typography>
      </Paper>
    );
  }

  return null;
};
