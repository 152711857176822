import React from 'react';
import styled, { css } from 'styled-components';

import { SEO } from 'rx-core';

import { LoginProvider } from 'rx-contexts';

import { LoginHeader } from '../molecules';
import { LoginSteps } from '../organims/LoginSteps';

const Container = styled.div`
  ${({ theme: { colors } }) => css`
    height: 100vh;
    position: relative;
    background-color: ${colors.gray[100]};
  `}
`;

const Login = (): JSX.Element => {
  return (
    <Container>
      <SEO title="RxRise | Log in" />
      <LoginProvider>
        <LoginHeader />
        <LoginSteps />
      </LoginProvider>
    </Container>
  );
};

export { Login };
