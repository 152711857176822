import React from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

type FooterLinkProps = {
  href: string;
  children: React.ReactNode;
};

const Text = styled.a`
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  text-decoration: none;
  ${space}
`;

const FooterLink = ({
  children,
  href,
  ...props
}: FooterLinkProps & SpaceProps): JSX.Element => {
  return (
    <Text href={href} {...props}>
      {children}
    </Text>
  );
};

export { FooterLink };
