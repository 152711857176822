import React from 'react';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { Field, useFormik, FormikProvider } from 'formik';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAddPayment } from 'rx-contexts';
import { useAddPharmacyPaymentMethodAch, usePostAdminAddACH } from 'rx-api';
import { useAuth } from 'rx-hooks';
import { AddACHPaymentSchema } from 'utils/validators/account-settings/add-ach-payment.validator';

import StripeLogoImg from '../../../../assets/logos/stripe_logo.svg';
import { AddPaymentMethodAutoSubmit } from '../atoms/AddPaymentMethodAutoSubmit';
import { AddPaymentMethodError } from '../atoms/AddPaymentMethodError';

interface IAddAchForm {
  accountNumber: string;
  routingNumber: string;
  setAsDefault: boolean;
}

const useStyles = makeStyles({
  whiteBackground: {
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
    },
  },
});

const FormContainer = styled.div`
  padding: 1rem;
  background: white;
  position: relative;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('desktop')} {
      width: 100%;
      max-width: 100%;
    }
  `}
`;

const CustomInputMaterial = styled(TextField)`
  margin-right: 20px;

  & > span {
    height: 30px;
  }

  ${({ theme: { lessThan } }) => css`
    ${lessThan('desktop')} {
      width: 100%;
    }
  `}
`;

const DefaultLabel = styled.label`
  margin-left: 0.5rem;
`;

const AddPaymentMethodACHForm: React.FC = () => {
  const classes = useStyles();

  const initialValues: IAddAchForm = {
    accountNumber: '',
    routingNumber: '',
    setAsDefault: false,
  };

  const { type } = useAuth();

  const {
    error,
    onSetError,
    onSetFinish,
    onSetLoading,
    onOpenModal,
    pharmacyId,
  } = useAddPayment();

  const queryClient = useQueryClient();
  const params = useParams<{ id: string }>();

  const mutation = useAddPharmacyPaymentMethodAch(pharmacyId || 0);
  const addACHMethod = usePostAdminAddACH({
    id: params.id,
  });

  const onSubmit = async (form: IAddAchForm) => {
    onSetLoading(true);

    try {
      if (type !== 'admin') {
        await mutation.mutateAsync({
          setAsDefault: form.setAsDefault,
          data: {
            accountNumber: form.accountNumber,
            routingNumber: form.routingNumber,
          },
        });
        await queryClient.refetchQueries(
          ['get-pharmacy-payment-methods-ach', pharmacyId],
          {
            active: true,
          }
        );

        onSetLoading(false);
        onSetFinish(true);
        onOpenModal(false);

        return;
      }

      await addACHMethod.mutateAsync({
        accountNumber: form.accountNumber,
        routingNumber: form.routingNumber,
      });
      await queryClient.refetchQueries(
        'get-admin-pharmacists-payment-methods',
        {
          active: true,
        }
      );

      onSetLoading(false);
      onSetFinish(true);
      onOpenModal(false);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          onSetError(err.response.data.message);
        }
      }
    } finally {
      onSetLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: AddACHPaymentSchema,
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <FormContainer>
          <h3 style={{ marginBottom: '20px', marginTop: '10px' }}>
            Add New ACH
          </h3>
          <h4 style={{ marginBottom: '15px' }}>
            Please Enter Your Account Information
          </h4>
          <div style={{ display: 'flex' }}>
            <p style={{ marginBottom: '20px' }}>
              Your payment is secured by Stripe.
            </p>
            <img alt="" src={StripeLogoImg} />
          </div>
          <CustomInputMaterial
            data-cy="add-payment-method-ach-account-number-input"
            name="accountNumber"
            id="accountNumber"
            label="Account number"
            className={classes.whiteBackground}
            onChange={formik.handleChange}
            value={formik.values.accountNumber}
            variant="outlined"
            fullWidth={true}
            error={
              formik.touched.accountNumber &&
              (formik.errors?.accountNumber?.length || 0) > 0
            }
            helperText={
              formik.touched.accountNumber && formik.errors.accountNumber
                ? formik.errors.accountNumber
                : ''
            }
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            type="text"
          />
          <CustomInputMaterial
            data-cy="add-payment-method-ach-routing-number-input"
            name="routingNumber"
            id="routingNumber"
            label="Routing Number"
            className={classes.whiteBackground}
            onChange={formik.handleChange}
            value={formik.values.routingNumber}
            variant="outlined"
            fullWidth={true}
            error={
              formik.touched.routingNumber &&
              (formik.errors?.routingNumber?.length || 0) > 0
            }
            helperText={
              formik.touched.routingNumber && formik.errors.routingNumber
                ? formik.errors.routingNumber
                : ''
            }
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            type="text"
          />
          <span>
            <Field name="setAsDefault" type="checkbox" id="setAsDefault" />
            <DefaultLabel htmlFor="setAsDefault">
              Set as default payment method
            </DefaultLabel>
          </span>
          <AddPaymentMethodAutoSubmit type="ach" />
          {error && <AddPaymentMethodError>{error}</AddPaymentMethodError>}
        </FormContainer>
      </form>
    </FormikProvider>
  );
};

export { AddPaymentMethodACHForm };
