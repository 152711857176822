import { useQuery } from 'react-query';
import { IGetAccountUpdatePasswordCSFRResponse } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetAccountUpdatePasswordCSFR = () => {
  const onRequest = async () => {
    return http.get<IGetAccountUpdatePasswordCSFRResponse>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/account/update-password/csfr`,
      {
        withCredentials: true,
      }
    );
  };

  return useQuery(['get-account-update-password-csfr'], () => onRequest(), {});
};
