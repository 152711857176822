import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';
import { CircularProgress } from '@material-ui/core';

import { Colors, theme as themeSchema } from 'rx-styles';

import { ButtonBase } from '../ButtonBase';
import { ButtonText } from '../ButtonText';

export type ButtonNormalProps = HTMLAttributes<HTMLDivElement> &
  LayoutProps & {
    text: string;
    color?: Colors;
    textColor?: Colors;
    fontSize?: string;
    secondary?: boolean;
    loading: boolean;
    type: 'submit' | 'reset' | 'button' | undefined;
    disabled: boolean | undefined;
  };

const Container = styled.div<LayoutProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  ${layout}
`;

const Text = styled(ButtonText)<{ textColor?: Colors; fontSize?: string }>`
  left: initial;
  position: initial;
  color: ${({ textColor }) => textColor};
  font-size: ${({ fontSize }) => fontSize};
`;

const LoadingIcon = styled(CircularProgress)`
  ${({ theme }) => css`
    color: ${theme.colors.blue[100]} !important;
  `}
`;

const ButtonNormal = ({
  text,
  onClick,
  color,
  textColor,
  fontSize,
  secondary,
  type,
  disabled,
  loading,
  ...props
}: ButtonNormalProps): JSX.Element => {
  return (
    <Container onClick={onClick} {...props}>
      <ButtonBase
        color={color}
        secondary={secondary || false}
        type={type}
        disabled={disabled}
      >
        {!loading && (
          <Text
            textColor={secondary ? '#5077F2' : textColor}
            fontSize={fontSize}
          >
            {text}
          </Text>
        )}
        {loading && <LoadingIcon size={26} thickness={6} />}
      </ButtonBase>
    </Container>
  );
};

ButtonNormal.defaultProps = {
  color: themeSchema.colors.blue.gradient,
  textColor: themeSchema.colors.white[100],
  fontSize: '18px',
  secondary: false,
  type: 'submit',
  disabled: undefined,
  loading: false,
};

export { ButtonNormal };
