import React from 'react';
import styled from 'styled-components';

import Pagination, {
  PaginationRenderItemParams,
} from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

import { Link, Route } from 'react-router-dom';
import { usePaginationQueries } from 'rx-hooks';
import { Button } from '@material-ui/core';

export type RxPaginationProps = {
  totalPages: number;
  path: string;
  initialParams?: string;
  defaultQueries?: {
    sortByDefault?: string | null;
    sortOrderByDefault?: string | null;
  };
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const ScrollToTopButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  color: '#565656',
  lineHeight: 1,
  borderColor: '#565656',
  '&:hover': {
    color: '#5077F2',
    borderColor: '#5077F2',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    textTransform: 'none',
  },
});

const RxPagination = (props: RxPaginationProps): JSX.Element => {
  const {
    onMakeURLWithSlash,
    queryMaxPrice,
    queryMinPrice,
    querySearch,
    querySearchType,
    querySortBy,
    querySortOrder,
    queryBuyer,
    queryForm,
    querySeller,
    querySellerId,
    queryMOStatus,
    queryCategory,
    queryViewType,
    queryStrengthMin,
    queryStrengthMax,
    queryStrengthUnit,
    queryPharmacyName,
    limit,
  } = usePaginationQueries(props.defaultQueries ?? {});

  const handleOnClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Container>
      <Route>
        {({ location }) => {
          const query = new URLSearchParams(location.search);
          const page = parseInt(query.get('p') || '1', 10);

          const makeQuery = (item: PaginationRenderItemParams) => {
            if (item.page === 1) {
              return onMakeURLWithSlash(
                {
                  search: querySearch,
                  t: querySearchType,
                  b: querySortBy,
                  o: querySortOrder,
                  minPrice: queryMinPrice,
                  maxPrice: queryMaxPrice,
                  buyer: queryBuyer,
                  seller: querySeller,
                  form: queryForm,
                  mo: queryMOStatus,
                  category: queryCategory,
                  viewType: queryViewType,
                  strengthMin: queryStrengthMin,
                  strengthMax: queryStrengthMax,
                  strengthUnit: queryStrengthUnit,
                  pharmacy: queryPharmacyName,
                  limit: limit,
                  sellerId: querySellerId,
                },
                props.initialParams
              );
            }

            return onMakeURLWithSlash(
              {
                search: querySearch,
                p: item.page?.toString(),
                t: querySearchType,
                b: querySortBy,
                o: querySortOrder,
                minPrice: queryMinPrice,
                maxPrice: queryMaxPrice,
                buyer: queryBuyer,
                seller: querySeller,
                form: queryForm,
                mo: queryMOStatus,
                category: queryCategory,
                viewType: queryViewType,
                strengthMin: queryStrengthMin,
                strengthMax: queryStrengthMax,
                strengthUnit: queryStrengthUnit,
                pharmacy: queryPharmacyName,
                limit: limit,
                sellerId: querySellerId,
              },
              props.initialParams
            );
          };

          return (
            <>
              <Pagination
                page={page}
                count={props.totalPages}
                shape="rounded"
                renderItem={(item) => {
                  return (
                    <PaginationItem
                      component={Link}
                      to={`${
                        props.path !== '' ? `/${props.path}` : ''
                      }${makeQuery(item)}`}
                      {...item}
                      onClick={(e: React.SyntheticEvent<Element, Event>) => {
                        item.onClick(e);
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        });
                      }}
                    />
                  );
                }}
              />
              <ScrollToTopButton onClick={handleOnClick} disableRipple>
                Go to Top
              </ScrollToTopButton>
            </>
          );
        }}
      </Route>
    </Container>
  );
};

export { RxPagination };
