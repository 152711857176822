import React from 'react';
import { RxButtonSimple } from 'rx-core';
import { SearchQueryParams } from 'rx-domain';
import { Paper } from '@material-ui/core';

import { ReportFilterSeller } from '../PaymentsReportFilterSeller';
import { ReportFilterType } from '../PaymentsReportFilterType';
import { ReportFilterDatePicker } from '../PaymentsReportFilterDatePicker';
import { Container, Title, Subtitle, FilterContainer } from './index.styles';
import { ReportFilterBuyer } from '../PaymentsReportFilterBuyer/ReportFilterBuyer';
import { ReportFilterEdi } from '../PaymentsReportFilterEdi';

type IProps = {
  queries: SearchQueryParams;
  onClickClearFilters: () => void;
};

export const ReportFilters: React.FC<IProps> = (props) => {
  return (
    <Paper variant="outlined">
      <Container>
        <Title>Select your report criteria</Title>
        <Subtitle>
          Build your search in order to find the best results.
        </Subtitle>
        <FilterContainer>
          <ReportFilterSeller
            queries={props.queries}
            placeholder="Select Seller"
            defaultValue={{
              title: 'All Sellers',
              value: 0,
            }}
          />
          <ReportFilterBuyer
            queries={props.queries}
            placeholder="Select Buyer"
            defaultValue={{
              title: 'All Buyers',
              value: 0,
            }}
          />
          <ReportFilterType queries={props.queries} />
          <ReportFilterEdi queries={props.queries} />
          <ReportFilterDatePicker queries={props.queries} />
          <RxButtonSimple variant="ghost" onClick={props.onClickClearFilters}>
            Clear Filters
          </RxButtonSimple>
        </FilterContainer>
      </Container>
    </Paper>
  );
};
