import * as React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export interface IDashboardLogsAutocompleteProps {
  isOpen: boolean;
  options: string[];
  searchValue: string;
  placeholder: string;
  isLoading: boolean;
  onOpen: () => void;
  onClose: () => void;
  onChange: (e: any, value: string) => void;
}

export const DashboardLogsAutocomplete = (
  props: IDashboardLogsAutocompleteProps
): JSX.Element => {
  return (
    <Autocomplete
      id="seller-search"
      size="small"
      defaultValue=""
      open={props.isOpen}
      onOpen={props.onOpen}
      onClose={props.onClose}
      getOptionSelected={(option, autocompleteValue) =>
        option === autocompleteValue
      }
      getOptionLabel={(option) => option}
      options={props.options}
      value={props.searchValue}
      filterOptions={(x) => x}
      onInputChange={props.onChange}
      onChange={(event, newValue) => props.onChange({}, newValue || '')}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={props.placeholder}
          variant="outlined"
          focused={false}
          style={{
            width: '200px',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
