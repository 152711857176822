import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { AppDispatch, setBuyerSearchType } from 'rx-store';
import { useDispatch } from 'react-redux';
import { usePaginationQueries } from 'rx-hooks';

type IFilterType = {
  type: 'name' | 'ndc' | 'shippingName' | 'description';
  tag: string;
};

const Container = styled.div`
  ${({ theme }) => css`
    height: 100%;

    ${theme.lessThan('ipadPro')} {
      width: 20%;
    }
  `}
`;

const FilterButton = styled.button`
  ${({ theme }) => css`
    min-width: 4.375rem;
    height: 100%;
    background-color: white;
    cursor: pointer;
    border: 1px solid ${theme.colors.gray[400]};
    border-radius: 4px 0 0 4px;

    padding: 8px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    ${theme.lessThan('ipadPro')} {
      min-width: auto;
      width: 100%;
    }
  `}
`;

const ArrowIcon = styled(ArrowDropDownIcon)``;

const filters: Array<IFilterType> = [
  { type: 'name', tag: 'Name' },
  { type: 'ndc', tag: 'NDC' },
];

const NewSearchFilters = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const history = useHistory();

  const {
    onMakeURL,
    querySearch,
    queryMinPrice,
    queryMaxPrice,
    querySearchType,
    queryCategory,
    queryViewType,
    querySortBy,
    querySortOrder,
  } = usePaginationQueries();

  const filterType = filters.find((f) => f.type === querySearchType)?.tag ?? '';

  const dispatch: AppDispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value: IFilterType) => {
    setAnchorEl(null);
    dispatch(setBuyerSearchType(value.type));

    const fullURL = onMakeURL({
      category: queryCategory,
      viewType: queryViewType,
      o: querySortOrder,
      b: querySortBy,
      search: querySearch,
      t: value.type,
      minPrice: queryMinPrice,
      maxPrice: queryMaxPrice,
    });

    history.push(fullURL);
  };

  return (
    <Container>
      <FilterButton
        aria-controls="search-filter-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {filterType}
        <ArrowIcon />
      </FilterButton>
      <Menu
        id="search-filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleClose({ type: 'name', tag: 'Name' })}>
          Name
        </MenuItem>
        <MenuItem onClick={() => handleClose({ type: 'ndc', tag: 'NDC' })}>
          NDC
        </MenuItem>
      </Menu>
    </Container>
  );
};

export { NewSearchFilters };
