import { useRxMutation } from 'rx-api/utils';

export const useDeleteInventoryProductPricingTier = (
  productId: number,
  pricingTierId: number
) => {
  return useRxMutation<unknown, unknown>({
    method: 'delete',
    url: `${process.env.REACT_APP_WEBSITE_URL}/v1/manufacturer/inventory/products/${productId}/pricing-tiers/${pricingTierId}`,
  });
};
