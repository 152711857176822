import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useGetPendingTaskList } from 'rx-api';

import { Container, Header } from './index.styles';
import { PendingTaskList } from './PendingTaskList';
import { Link } from 'react-router-dom';

const dateFormat = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const formatDate = (task: {
  title: string;
  description: string;
  date: string;
  url: string;
}) => ({
  ...task,
  date: new Date(task.date).toLocaleDateString('en-US', dateFormat as any),
});

export const DashboardPendingTasks = (): JSX.Element => {
  const today = new Date().toLocaleDateString('en-US', dateFormat as any);

  const { isLoading, data: response } = useGetPendingTaskList();

  return (
    <Container>
      <Header>
        <Typography variant="h6">Pending Tasks</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {today}
        </Typography>
      </Header>
      <div style={{ display: 'flex' }}>
        <Link
          to="/approve"
          style={{
            display: 'block',
            textDecoration: 'none',
            margin: '0px auto',
          }}
        >
          <Button color="primary" variant="text">
            VIEW ALL
          </Button>
        </Link>
      </div>
      <PendingTaskList
        isLoading={isLoading}
        data={response?.data.map(formatDate) || []}
      />
    </Container>
  );
};
