import React from 'react';
import { Card } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const PharmacistPharmacyListLoading: React.FC = () => {
  return (
    <>
      <Card
        variant="outlined"
        style={{ padding: '0 1rem', marginBottom: '1rem' }}
      >
        <Skeleton height={100} />
      </Card>
      <Card
        variant="outlined"
        style={{ padding: '0 1rem', marginBottom: '1rem' }}
      >
        <Skeleton height={100} />
      </Card>
      <Card
        variant="outlined"
        style={{ padding: '0 1rem', marginBottom: '1rem' }}
      >
        <Skeleton height={100} />
      </Card>
    </>
  );
};
