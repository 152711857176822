import React from 'react';

import { RxTableTabs, SEO, TemplateView } from 'rx-core';
import { ManageItemsUploadProvider } from 'rx-contexts';
import { useInventory } from 'rx-hooks';

import { CsvUploadModal } from 'pages/general/csv-upload/organims';
import { InventoryTable } from './InventoryTable';
import { InventoryActions } from './InventoryActions';
import { InventoryFilters } from './InventoryFilters';

import { Container, TabContainer } from './index.styles';

export const InventoryView: React.FC = () => {
  const { queryType, changeView } = useInventory();

  return (
    <TemplateView>
      <SEO title="RxRise | Inventory" />
      <ManageItemsUploadProvider>
        <Container>
          <InventoryActions />
          <RxTableTabs
            items={[
              {
                active: queryType === 'drug',
                title: 'RxProducts',
                onSetView: () => changeView('drug'),
                value: 'RX_PRODUCTS',
              },
              {
                active: queryType === 'medical_supply',
                title: 'Medical and Diabetes Supplies',
                onSetView: () => changeView('medical_supply'),
                value: 'MED_SUP',
              },
              {
                active: queryType === 'diagnostic_test',
                title: 'Covid and Other Tests',
                onSetView: () => changeView('diagnostic_test'),
                value: 'DIAG_TESTS',
              },
            ]}
            filterComp={<InventoryFilters />}
          >
            <TabContainer>
              <InventoryTable queries={{ type: queryType }} />
            </TabContainer>
          </RxTableTabs>
          <CsvUploadModal onUploadSuccess={() => {}} />
        </Container>
      </ManageItemsUploadProvider>
    </TemplateView>
  );
};
