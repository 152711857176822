import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { usePopper } from 'react-popper';

import { PopperInfoIcon } from '../PopperInfoIcon';

export type PopperInfoProps = {
  text: string;
};

const PopperContainer = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  text-align: left;
  max-width: 310px;
  z-index: 999;

  font-family: ${({ theme }) => theme.fonts.robotoLight};
  font-size: ${({ theme }) => theme.sizes.medium};

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;

    &:before {
      content: ' ';
      position: absolute;
      bottom: -30px;
      left: -10px;
      transform: rotate(315deg);
      width: 20px;
      height: 20px;
      background-color: white;
      z-index: -1;
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    }
  }
  &[data-popper-placement^='right'] > .arrow {
    left: -4px;

    :after {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
`;

const IconContainer = styled.figure`
  height: 20px;
  width: 20px;
`;

const PopperInfo = ({ text, ...props }: PopperInfoProps): JSX.Element => {
  const [isVisible, setVisibility] = useState(false);

  const referenceElement = useRef(null);
  const popperElement = useRef(null);
  const [arrowRef, setArrowRed] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      modifiers: [
        { name: 'arrow', options: { element: arrowRef } },
        {
          name: 'offset',
          options: {
            offset: [180, -55],
          },
        },
      ],
    }
  );

  return (
    <>
      <IconContainer
        {...props}
        ref={referenceElement}
        onMouseEnter={() => setVisibility(true)}
        onMouseLeave={() => setVisibility(false)}
      >
        <PopperInfoIcon />
      </IconContainer>
      {isVisible && (
        <PopperContainer
          ref={popperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div ref={setArrowRed} style={styles.arrow} className="arrow" />
          {text}
        </PopperContainer>
      )}
    </>
  );
};

export { PopperInfo };
