import React from 'react';
import {
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { Link, useHistory } from 'react-router-dom';

import { SEO, TemplateView } from 'rx-core';
import { US_STATES_WITH_CODES } from 'rx-utils';
import { useToast } from 'rx-hooks';
import { http } from 'rx-api';
import { useMutation } from 'react-query';
import axios from 'axios';

const useAdminAddManufacturer = () => {
  type Dto = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    companyName: string;
    companyEmail: string;
    companyPhoneNumber: string;
    billingName: string;
    billingAddress: string;
    billingCity: string;
    billingState: string;
    billingZipCode: string;
  };

  return useMutation(
    'admin-add-manufacturer',
    (data: Dto) => {
      return http.post(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/manufacturers`,
        data
      );
    },
    { retry: false }
  );
};

export const NewSellerView: React.FC = () => {
  const addManufacturer = useAdminAddManufacturer();
  const history = useHistory();

  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      companyName: '',
      companyEmail: '',
      companyPhoneNumber: '',
      billingName: '',
      billingAddress: '',
      billingCity: '',
      billingState: 'AK',
      billingZipCode: '',
    },
    onSubmit: async (values) => {
      try {
        await addManufacturer.mutateAsync(values);
        toast.onSuccessToast('Manufacturer added!');
        history.push('/manufacturers');
      } catch (err) {
        if (axios.isAxiosError(err)) {
          toast.onErrorToast(`Error: ${err.response?.data?.message}`);
          return;
        }

        toast.onErrorToast('Error: could not add manufacturer');
      }
    },
  });

  return (
    <TemplateView>
      <SEO title="RxRise | Manufacturers" />
      <div style={{ marginTop: '1rem' }}>
        <Typography variant="h5">New Manufacturer</Typography>

        <Paper
          variant="outlined"
          style={{ padding: '1rem', marginTop: '1rem' }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Typography variant="h6" style={{ marginBottom: '1rem' }}>
              Account Information
            </Typography>

            <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
              <Grid item xs={6}>
                <TextField
                  id="firstName"
                  name="firstName"
                  variant="outlined"
                  label="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="lastName"
                  name="lastName"
                  variant="outlined"
                  label="Last Name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="email"
                  name="email"
                  variant="outlined"
                  type="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="password"
                  name="password"
                  variant="outlined"
                  type="password"
                  label="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Typography variant="h6" style={{ marginBottom: '1rem' }}>
              Bussines Information
            </Typography>

            <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
              <Grid item xs={6}>
                <TextField
                  id="companyName"
                  name="companyName"
                  variant="outlined"
                  label="Company Name"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="companyEmail"
                  name="companyEmail"
                  variant="outlined"
                  type="email"
                  label="Company Contact Email"
                  value={formik.values.companyEmail}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="companyPhoneNumber"
                  name="companyPhoneNumber"
                  variant="outlined"
                  label="Company Phone Number"
                  value={formik.values.companyPhoneNumber}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Typography variant="h6" style={{ marginBottom: '1rem' }}>
              Billing Information
            </Typography>

            <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
              <Grid item xs={12}>
                <TextField
                  id="billingName"
                  name="billingName"
                  variant="outlined"
                  label="Billing Name"
                  value={formik.values.billingName}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="billingAddress"
                  name="billingAddress"
                  variant="outlined"
                  label="Billing Address"
                  value={formik.values.billingAddress}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="billingCity"
                  name="billingCity"
                  variant="outlined"
                  label="City"
                  value={formik.values.billingCity}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  select
                  name="billingState"
                  label="State"
                  variant="outlined"
                  value={formik.values.billingState}
                  onChange={formik.handleChange}
                  fullWidth
                  required
                >
                  {US_STATES_WITH_CODES.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="billingZipCode"
                  name="billingZipCode"
                  variant="outlined"
                  label="Zip Code"
                  value={formik.values.billingZipCode}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: '1rem' }}
              disabled={addManufacturer.isLoading}
              type="submit"
            >
              Create
            </Button>

            <Link to="/manufacturers">
              <Button variant="outlined" disabled={addManufacturer.isLoading}>
                Cancel
              </Button>
            </Link>
          </form>
        </Paper>
      </div>
    </TemplateView>
  );
};
