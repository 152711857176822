import React from 'react';
import styled, { css } from 'styled-components';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: center;
    margin: 50px auto;

    ${theme.lessThan('desktop')} {
      display: none;
    }
  `}
`;

function getSteps(): string[] {
  return [
    'Personal Information',
    'Pharmacy Information',
    'Address Information',
    'Additional Information',
    'Finish Up',
  ];
}

interface ISignupBuyerWizardProps {
  activeStep: number;
  errorStep: number | null;
}

export const SignupBuyerWizard: React.FC<ISignupBuyerWizardProps> = (props) => {
  const steps = getSteps();

  return (
    <Container>
      <Stepper activeStep={props.activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel error={props.errorStep === index}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Container>
  );
};
