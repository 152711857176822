import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { SEO, TemplateView } from 'rx-core';
import { useToast } from 'rx-hooks';
import {
  useGetManufacturerShippingPreferences,
  usePutSellerManufacturerShippingPreferences,
} from 'rx-api';

import { SettingsNav } from '../../shared/SettingsNav';
import { SellerShippingSettingsEditForm } from './SellerShippingSettingsEditForm';
import { SellerShippingSettingsViewForm } from './SellerShippingSettingsViewForm';

const SettingsShippingSellerView = (): JSX.Element => {
  const [mode, setMode] = React.useState('view');
  const [minimumOrderAmount, setMinimumOrderAmount] = React.useState('0');
  const [minimumOrderAmountError, setMinimumOrderAmountError] =
    React.useState(false);
  const [groundShipping, setGroundShipping] = React.useState('0');
  const [groundShippingError, setGroundShippingError] = React.useState(false);
  const [overnightShipping, setOvernightShipping] = React.useState('0');
  const [overnightShippingError, setOvernightShippingError] =
    React.useState(false);
  const { data: response, isLoading } = useGetManufacturerShippingPreferences();
  const updateShippingConfiguration =
    usePutSellerManufacturerShippingPreferences();
  const toast = useToast();

  React.useEffect(() => {
    if (!isLoading) {
      setMinimumOrderAmount(`${response?.data.minimumOrderAmount || '0'}`);
      setGroundShipping(`${response?.data.groundShipping || '0'}`);
      setOvernightShipping(`${response?.data.overnightShipping || '0'}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, response?.data]);

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();

      if (parseFloat(minimumOrderAmount) <= 0) {
        return setMinimumOrderAmountError(true);
      }

      if (parseFloat(groundShipping) < 0) {
        return setGroundShippingError(true);
      }

      if (parseFloat(overnightShipping) < 0) {
        return setOvernightShippingError(true);
      }

      await updateShippingConfiguration.mutateAsync({
        groundShipping,
        overnightShipping,
        minimumOrderAmount,
        shippingConstraints: '' as string,
      });

      setMinimumOrderAmountError(false);
      setGroundShippingError(false);
      setOvernightShippingError(false);
      setMode('view');
      return toast.onSuccessToast('Shipping Configuration Updated!');
    } catch (_err) {
      return toast.onErrorToast('Could not update shipping configuration');
    }
  };

  return (
    <TemplateView>
      <SEO title="RxRise | Settings" />
      <header style={{ padding: '2rem' }}>
        <Typography color="primary" variant="h4">
          Shipping Configuration
        </Typography>
      </header>
      <div style={{ padding: '0 2rem', marginBottom: '2rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={3}>
            <SettingsNav
              links={[
                {
                  text: 'My Profile',
                  to: '/settings/profile',
                  dataCy: 'account-settings-profile',
                },
                {
                  text: 'Shipping',
                  to: '/settings/shipping',
                  dataCy: 'account-settings-shipping',
                },
                {
                  text: 'Security',
                  to: '/settings/security',
                  dataCy: 'account-settings-security',
                },
              ]}
            />
          </Grid>
          <Grid item md={8} lg={8}>
            {mode === 'view' && (
              <SellerShippingSettingsViewForm
                isLoading={isLoading}
                minimumOrderAmount={minimumOrderAmount}
                groundShipping={groundShipping}
                overnightShipping={overnightShipping}
                onClickEdit={() => setMode('edit')}
              />
            )}

            {mode === 'edit' && (
              <SellerShippingSettingsEditForm
                isLoading={isLoading}
                minimumOrderAmount={minimumOrderAmount}
                groundShipping={groundShipping}
                overnightShipping={overnightShipping}
                minimumOrderAmountError={minimumOrderAmountError}
                groundShippingError={groundShippingError}
                overnightShippingError={overnightShippingError}
                onSubmit={handleSubmit}
                onChangeMinimumOrderAmount={(event) =>
                  setMinimumOrderAmount(event.target.value)
                }
                onChangeOvernightShipping={(event) =>
                  setOvernightShipping(event.target.value)
                }
                onChangeGroundShipping={(event) =>
                  setGroundShipping(event.target.value)
                }
                onClickCancel={() => setMode('view')}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </TemplateView>
  );
};

export { SettingsShippingSellerView };
