import React from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useFormik } from 'formik';

import { Button, Grid, Paper, TextField } from '@material-ui/core';
import { IGetPharmacistPharmacyResponse } from 'rx-domain';
import { usePutPharmacistPharmacy } from 'rx-api';
import { useToast } from 'rx-hooks';

import { BuyerTradeRefSchema } from 'utils/validators/account-settings/pharmacy/trade-ref.validator';
import { Title } from './index.styles';

type IProps = {
  data: IGetPharmacistPharmacyResponse | undefined;
  disabled: boolean;
};

type IForm = {
  pharmacyName: string;
  type: number;
  faxNumber: string;
  npi: string;
  yearsInBusiness: number;
  primaryWholesaler: string;
  account1: string;
  secondaryWholesaler: string;
  account2: string;
  dbaName: string;
  phoneNumber: string;
};

export const SettingsTradeRef: React.FC<IProps> = (props) => {
  const initialValues: IForm = {
    pharmacyName: props.data?.pharmacyName ?? '',
    dbaName: props.data?.dbaName ?? '',
    phoneNumber: props.data?.phoneNumber ?? '',
    faxNumber: props.data?.faxNumber ?? '',
    npi: props.data?.npi ?? '',
    type: props.data?.type ?? 0,
    yearsInBusiness: props.data?.yearsInBusiness ?? 0,
    primaryWholesaler: props.data?.primaryWholesaler ?? '',
    account1: props.data?.account1 ?? '',
    secondaryWholesaler: props.data?.secondaryWholesaler ?? '',
    account2: props.data?.account2 ?? '',
  };

  const queryClient = useQueryClient();

  const params = useParams<{ id: string }>();
  const updatePharmacyData = usePutPharmacistPharmacy(params.id);

  const toast = useToast();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: BuyerTradeRefSchema,
    onSubmit: async (values: IForm) => {
      try {
        await updatePharmacyData.mutateAsync(values);
        await queryClient.refetchQueries('get-pharmacist-data', {
          active: true,
        });
        toast.onSuccessToast('Changes saved succesfully 🚀');
      } catch (err) {
        toast.onErrorToast('Error: could not save changes');
      }
    },
  });

  return (
    <Paper variant="outlined" style={{ padding: '1rem' }}>
      <Title>Trade reference</Title>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              name="primaryWholesaler"
              label="Primary Wholesaler"
              placeholder="Primary Wholesaler"
              variant="outlined"
              value={formik.values.primaryWholesaler}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="account1"
              label="Account #1"
              placeholder="Account #1"
              variant="outlined"
              value={formik.values.account1}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              name="secondaryWholesaler"
              label="Secondary Wholesaler"
              placeholder="Secondary Wholesaler"
              variant="outlined"
              value={formik.values.secondaryWholesaler}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="account2"
              label="Account #2"
              placeholder="Account #2"
              variant="outlined"
              value={formik.values.account2}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={6}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ marginTop: '1rem' }}
              disabled={props.disabled}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
