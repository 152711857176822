import { SEO, TemplateView } from 'rx-core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAdminPutEditPurchaseOrderItems, useGetPurchaseOrder } from 'rx-api';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import { Button, Typography } from '@material-ui/core';
import { useToast } from 'rx-hooks';

export const SellerTitle = styled.div`
  padding: 0 0 1rem 0;
  font-size: 20px;
`;

export const UpdateButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
`;

export const DetailsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 20px;
  padding: 1rem 1rem;
`;

export const FlexPaymentDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PaperDiv = styled.div`
  flex: 1; /* Esto hará que cada hijo ocupe el mismo espacio dentro del contenedor. */
  width: 50%; /* Esto garantiza que cada hijo no crezca más del 50% del contenedor. */
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 440,
      overflow: 'scroll-y', // or 'auto' if you want the scroll bar to appear when the content is overflow
      '&::-webkit-scrollbar': {
        display: 'block',
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '10px',
        backgroundColor: '#F0F0F0', // change this to match the color of your table or the color you want
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        backgroundColor: '#B0B0B0', // change this to match the color of your table or the color you want
      },
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableHead: {
      backgroundColor: '#F7F9FC',
    },
    paid: {
      background: '#DDE0E4',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    refunded: {
      background: '#DDE0E4',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    cancelled: {
      color: 'white',
      background: '#FF6969',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    pending: {
      color: 'white',
      background: '#FF6969',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    fulfilled: {
      background: '#DDE0E4',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    unfulfilled: {
      background: '#FFEA93',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    bodyRow: {
      cursor: 'pointer',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

const headCells = [
  {
    id: 'product',
    numeric: false,
    disablePadding: false,
    label: 'Product',
    sortable: false,
  },
  {
    id: 'available',
    numeric: false,
    disablePadding: false,
    label: 'Available',
    sortable: false,
  },
  {
    id: 'quantity',
    numeric: false,
    disablePadding: false,
    label: 'Quantity Order',
    sortable: false,
  },
  {
    id: 'price',
    numeric: false,
    disablePadding: false,
    label: 'Price',
    sortable: false,
  },
  {
    id: 'subtotal',
    numeric: false,
    disablePadding: false,
    label: 'Sub Total',
    sortable: false,
  },
];

export const AdminEditPurchaseOrder: React.FC = () => {
  const toast = useToast();

  const [rows, setRows] = useState<
    {
      line: string;
      quantity: number;
      price: string;
      subtotal: number;
    }[]
  >([]);

  const { id } = useParams<{ id: string }>();
  const updatePurchaseOrderItems = useAdminPutEditPurchaseOrderItems({
    id: id ? parseInt(id ?? '0', 10) : 0,
  });
  const { data: response } = useGetPurchaseOrder({
    id: parseInt(id, 10),
  });
  const classes = useStyles();

  useEffect(() => {
    if (response?.data) {
      const transformedData = response?.data?.orderItems.map(
        (item: {
          id: number;
          quantity: number;
          price: string;
          line: string;
          purchaseOrderId: number;
          createdAt: string;
          updatedAt: string;
        }) => {
          return {
            line: item.line,
            quantity: item.quantity,
            price: item.price,
            subtotal: parseFloat(item.price) * item.quantity,
          };
        }
      );
      setRows(transformedData);
    }
  }, [response]);

  const handleOnClick = async () => {
    try {
      const formattedData = rows.map((item: any) => {
        return {
          quantity: item.quantity,
          price: item.price,
          line: item.line,
        };
      });
      await updatePurchaseOrderItems.mutateAsync({
        items: formattedData,
      });

      toast.onSuccessToast('Changes saved! 🚀');
    } catch (err) {
      toast.onErrorToast('Could not edit order');
    }
  };

  function calculateResult(input: number, integer: number) {
    return input * integer;
  }

  function handleInputChange(index: number, newValue: string) {
    // Copia las filas actuales
    const updatedRows = [...rows];
    const value = parseInt(newValue || '0', 10);

    if (Number.isNaN(value)) {
      return;
    }

    // Actualiza el valor del input y recalcula el resultado
    updatedRows[index].quantity = value;
    updatedRows[index].subtotal = calculateResult(
      value,
      parseFloat(updatedRows[index].price)
    );

    // Actualiza el estado
    setRows(updatedRows);
  }

  return (
    <TemplateView>
      <SEO title="RxRise | Edit Purchase Order" />

      <section>
        <Typography
          style={{ color: '#5a82ff', fontSize: '30px', marginTop: '1rem' }}
        >
          Edit PO-{id}
        </Typography>

        <div>
          <Paper variant="outlined">
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length > 0 &&
                    rows.map((row, index: number) => {
                      return (
                        <TableRow className={classes.bodyRow} hover key={index}>
                          <TableCell align="left">{row.line}</TableCell>
                          <TableCell align="left">995</TableCell>
                          <TableCell align="left">
                            <TextField
                              id="outlined-required"
                              label="Quantity"
                              value={row.quantity}
                              onChange={(e) =>
                                handleInputChange(index, e.target.value)
                              }
                              variant="outlined"
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              disabled
                              id="outlined-required"
                              label="Price"
                              defaultValue={row.price}
                              variant="outlined"
                            />
                          </TableCell>
                          <TableCell align="left">
                            ${' '}
                            {`${(row.quantity * parseFloat(row.price)).toFixed(
                              2
                            )}`}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <div style={{ margin: '1rem 0' }}>
            <Paper variant="outlined">
              <DetailsContainer>
                <PaperDiv>
                  <div>
                    <h3>Customer Information</h3>
                  </div>
                  <p>{response?.data?.buyer?.pharmacy?.pharmacyName}</p>
                </PaperDiv>
                <PaperDiv>
                  <div>
                    <h3>Contact Information</h3>
                  </div>
                  <p>
                    {response?.data?.buyer?.firstName}{' '}
                    {response?.data?.buyer?.lastName}
                  </p>
                  <p>{response?.data?.buyer?.email}</p>
                  <p>{response?.data?.buyer?.pharmacy?.phoneNumber}</p>
                </PaperDiv>
              </DetailsContainer>

              <DetailsContainer>
                <PaperDiv>
                  <div>
                    <h3>Shipping Address</h3>
                  </div>
                  <p>{response?.data?.shippingAddress?.address}</p>
                  <p>
                    {response?.data?.shippingAddress?.city},{' '}
                    {response?.data?.shippingAddress?.state}
                  </p>
                  <p>{response?.data?.shippingAddress?.zipCode}</p>
                </PaperDiv>
                <PaperDiv>
                  <div>
                    <h3>Billing Address</h3>
                  </div>
                  <p>
                    {
                      response?.data?.buyer?.billingInformation?.billingAddress
                        ?.address
                    }
                  </p>
                  <p>
                    {
                      response?.data?.buyer?.billingInformation?.billingAddress
                        ?.city
                    }
                    ,{' '}
                    {
                      response?.data?.buyer?.billingInformation?.billingAddress
                        ?.state
                    }
                  </p>
                  <p>
                    {
                      response?.data?.buyer?.billingInformation?.billingAddress
                        ?.zipCode
                    }
                  </p>
                </PaperDiv>
              </DetailsContainer>
            </Paper>
          </div>

          <div>
            <Paper variant="outlined" style={{ padding: '1rem' }}>
              <FlexPaymentDetails>
                <div>Subtotal</div>
                <div>
                  ${' '}
                  {rows.reduce((accumulator: number, currentItem) => {
                    return (
                      Number(accumulator) +
                      Number(currentItem.price) * currentItem.quantity
                    );
                  }, 0)}
                </div>
              </FlexPaymentDetails>
            </Paper>
          </div>

          <UpdateButtonContainer>
            <Button
              type="submit"
              disabled={updatePurchaseOrderItems.isLoading}
              variant="contained"
              color="primary"
              onClick={handleOnClick}
            >
              Update Order
            </Button>
          </UpdateButtonContainer>
        </div>
      </section>
    </TemplateView>
  );
};
