import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { VerifyPharmacyAchForm } from './VerifyPharmacyAchForm';

interface IProps {
  open: boolean;
  achId: number;
  pharmacyId: number;
  onCloseModal(): void;
  onSuccess(): void;
}

export const VerifyPharmacyAchModal: React.FC<IProps> = (props) => {
  return (
    <Dialog open={props.open}>
      <DialogContent style={{ paddingTop: '1rem' }}>
        <VerifyPharmacyAchForm
          achId={props.achId}
          pharmacyId={props.pharmacyId}
          onSuccess={props.onSuccess}
          onClickCancel={props.onCloseModal}
        />
      </DialogContent>
    </Dialog>
  );
};
