import * as Yup from 'yup';
import { parserMoneyNumberWithDefault } from 'rx-utils';

const VerifyACHSchema = Yup.object().shape({
  depositOne: Yup.number()
    .moreThan(0, 'Must be more than zero')
    .lessThan(1, 'Must be less than one dollar')
    .transform(parserMoneyNumberWithDefault)
    .required('Required.')
    .typeError('You Must specify a number'),
  depositTwo: Yup.number()
    .moreThan(0, 'Must be more than zero')
    .lessThan(1, 'Must be less than one dollar')
    .transform(parserMoneyNumberWithDefault)
    .required('Required.')
    .typeError('You must specify a number'),
});

export { VerifyACHSchema };
