import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  border-radius: 10px;
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
  border-bottom: 1px solid #9fa6b9;

  background-color: white;
`;
