import React from 'react';
import styled, { css } from 'styled-components';
import { useQueryClient } from 'react-query';

import { RxButtonSimple, RRModalProps } from 'rx-core';

import { useUpdateTwoFactorConfig } from 'rx-api';

type IProps = RRModalProps;

const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoRegular};
    font-weight: 400;
    margin-bottom: 1rem;
    margin-top: 1rem;
  `}
`;

const Content = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoLight};
    font-weight: 400;
    margin-bottom: 1rem;
  `}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SubmitButton = styled(RxButtonSimple)`
  width: 7.375rem;
`;

const CancelButton = styled(RxButtonSimple)`
  width: 7.375rem;
  margin-right: 1rem;
`;

const TwoFactorAuthOffMessage = (props: IProps): JSX.Element => {
  const update2FA = useUpdateTwoFactorConfig();

  const queryClient = useQueryClient();

  const onTurnOff2FA = async () => {
    try {
      await update2FA.mutateAsync({ enabled: false });
      await queryClient.refetchQueries(['get-account-2FA'], { active: true });
      props.onClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <Container data-cy="security-deactivate-2fa-modal">
      <Title>Two-Factor Authentication</Title>
      <Content>
        <b>This Will Turn Off Two-Factor Authentication</b>
      </Content>
      <Content>
        You will no longer receive a code in your email when you attempt to
        login into your RxRise account. You will only need to enter your email
        and password in order to login.
      </Content>
      <div style={{ flex: 1 }}></div>
      <ButtonContainer>
        <CancelButton
          onClick={props.onClose}
          disabled={update2FA.isLoading}
          variant="outline"
        >
          Cancel
        </CancelButton>
        <SubmitButton
          data-cy="security-deactivate-2fa-confirmation"
          onClick={onTurnOff2FA}
          loading={update2FA.isLoading}
        >
          Turn Off
        </SubmitButton>
      </ButtonContainer>
    </Container>
  );
};

export { TwoFactorAuthOffMessage };
