import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const AccountListLoader: React.FC = () => {
  return (
    <>
      <TableRow>
        <TableCell colSpan={4}>
          <Skeleton height={40} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4}>
          <Skeleton height={40} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4}>
          <Skeleton height={40} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4}>
          <Skeleton height={40} />
        </TableCell>
      </TableRow>
    </>
  );
};
