import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { SEO, TemplateView } from 'rx-core';
import { HARDCODED_CATEGORIES } from 'rx-utils';

import { AddProductActions } from 'pages/general/add-product/AddProductActions';
import { AddProductForm } from 'pages/general/add-product/AddProductForm';
import { useToast } from 'rx-hooks';
import { usePostSingleProduct } from 'rx-api';
import { BulkDrugsSchema } from 'validations';

export const SellerAddProductView: React.FC = () => {
  const history = useHistory();
  const toast = useToast();
  const addProduct = usePostSingleProduct();

  const handleSubmit = async (values: any) => {
    try {
      await addProduct.mutateAsync({
        ...values,
        category:
          HARDCODED_CATEGORIES[
            values.categoryId as keyof typeof HARDCODED_CATEGORIES
          ],
      });
      toast.onSuccessToast('Product added!');
      history.push('/');
    } catch (err) {
      toast.onErrorToast('Could not add product');
    }
  };

  const formik = useFormik({
    initialValues: {
      ndc: '',
      name: '',
      description: '',
      manufacturer: '',
      price: 0,
      expirationDate: '',
      lotNumber: '',
      units: 0,
      minimumQuantity: 0,
      maximumQuantity: null,
      productId: '',
      categoryId: 1,
      strengthValue: null,
      strengthUnit: 'none',
    },
    validationSchema: BulkDrugsSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <TemplateView>
      <SEO title="RxRise | Add Product" />

      <form onSubmit={formik.handleSubmit}>
        <div>
          <AddProductActions isEdit={false} productName={''} />

          <AddProductForm
            isLoading={false}
            errors={formik.errors}
            onChange={formik.handleChange}
            values={formik.values}
            onClickCancel={() => history.push('/')}
            isDisabled={formik.isSubmitting}
          />
        </div>
      </form>
    </TemplateView>
  );
};
