import styled, { css } from 'styled-components';

export const TabItem = styled.span<{ active: boolean }>`
  ${({ theme, active }) => css`
    font-size: ${theme.sizes.small};
    cursor: pointer;
    margin-right: 1rem;
    font-weight: 400;
    padding: 0.75rem;

    ${active &&
    css`
      border-bottom: 1px solid ${theme.colors.blue[300]};
    `}
  `}
`;
