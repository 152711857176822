import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { CircularProgress } from '@material-ui/core';

import { Formik, Form, Field } from 'formik';

import { DataState } from 'rx-domain';

import { useQuery } from 'rx-hooks';
import { useHistory } from 'react-router-dom';
import { usePostAuthResetPassword } from 'rx-api';

import { RxText, Input, ButtonPrimary, RxInputText } from 'rx-core';
import { LoginCreateAccount } from 'pages/general/login/atoms';
import { ResetPasswordSchema } from 'utils/validators/reset-password.validator';

interface IForm {
  email: string;
  token: string;
  password: string;
  confirmPassword: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 90px 18px 38px 22px;
  height: 100%;
  width: 100%;
  position: relative;
`;

const Title = styled(RxText)`
  margin-bottom: 16px;
`;

const Subtitle = styled(RxText)`
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  width: 386px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

const LoadingIcon = styled(CircularProgress)`
  ${({ theme }) => css`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: ${theme.colors.blue[100]} !important;
  `}
`;

const MessageText = styled(RxText)`
  margin-top: 18px;
  margin-bottom: 30px;
`;

const Spacer = styled.div`
  height: 66px;
`;

const LoginCreateAccountForm = styled.div`
  bottom: 36px;
  right: 0;
  width: 100%;
  position: absolute;
  text-align: center;
`;

const ResetPasswordForm = (): JSX.Element => {
  const initialValues: IForm = {
    email: '',
    token: '',
    password: '',
    confirmPassword: '',
  };

  const [stateForm, setStateForm] = useState<DataState>('none');
  const [loading, setLoading] = useState<boolean>(false);

  const query = useQuery();
  const history = useHistory();

  const token = query.get('token');
  const email = query.get('email');

  const forgotPasswordRequest = usePostAuthResetPassword();

  const onSubmit = async ({ confirmPassword, ...request }: IForm) => {
    try {
      setLoading(true);
      await forgotPasswordRequest.mutateAsync(request);
      setStateForm('success');

      history.push('/');
    } catch (err) {
      setStateForm('error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {loading && <LoadingIcon size={26} thickness={6} />}
      <Title font="Roboto Light" size="h2" color="#2b3647">
        Reset your password
      </Title>
      <Subtitle font="Roboto Light" color="#838ca5" size="small">
        It&apos;s important to use a secure password. You can create this with
        any combination of 8 or more mixed case letters, numbers or special
        characters (*? etc).
      </Subtitle>
      <Formik
        initialValues={
          token && email ? { ...initialValues, email, token } : initialValues
        }
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={ResetPasswordSchema}
      >
        <Form>
          <Field name="email" label="Email *" width="386px" component={Input} />
          <RxInputText
            name="password"
            label="Set Password *"
            placeholder=""
            type="password"
            width="386px"
            showPassword={true}
            showPasswordHelper
          />
          <RxInputText
            name="confirmPassword"
            label="Confirm password *"
            placeholder=""
            type="password"
            width="386px"
            showPassword={true}
            showPasswordHelper
          />
          <ButtonContainer>
            <ButtonPrimary text="Login" />
          </ButtonContainer>
        </Form>
      </Formik>
      {stateForm === 'success' && (
        <MessageText color="#3EB77D" size="small" font="Roboto Light">
          Great! You should be able to login.
        </MessageText>
      )}
      {stateForm === 'error' && (
        <MessageText color="#FF6969" size="small" font="Roboto Light">
          We&apos;re sorry. We weren&apos;t able to reset your password. Try
          again.
        </MessageText>
      )}
      {stateForm === 'none' && <Spacer />}
      <LoginCreateAccountForm>
        <LoginCreateAccount />
      </LoginCreateAccountForm>
    </Container>
  );
};

export { ResetPasswordForm };
