import { useGetRequest } from 'rx-api/utils';
import {
  GET_BUYER_PURCHASE_ORDER_URL,
  GET_BUYER_PURCHASE_ORDER_KEY,
  GetPurchaseOrderById,
} from './constants';

export const useGetPurchaseOrder = (
  query: Partial<GetPurchaseOrderById.IRequestParamsDto>
) => {
  const user = localStorage.getItem('_user') || '';
  const { type } = user ? JSON.parse(user) : { type: '' };
  return useGetRequest<unknown, GetPurchaseOrderById.IResponsePayloadDto>({
    url: GET_BUYER_PURCHASE_ORDER_URL(query.id || '', type === 'manufacturer'),
    key: [GET_BUYER_PURCHASE_ORDER_KEY, query.id],
    params: query,
  });
};
