import { useQuery } from 'react-query';
import { IGetBillingInformationDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetPharmacistBillingInformation = () => {
  const onRequest = async () => {
    return http.get<IGetBillingInformationDTO>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/pharmacist/billing-information`,
      { withCredentials: true }
    );
  };

  return useQuery(['get-pharmacist-billing-information'], () => onRequest());
};
