import { useQuery } from 'react-query';
import { http } from 'rx-api/http/repository';

export function useAdminGetManufacturerEdiSettings(id: number | string) {
  return useQuery(['admin-get-manufacturer-edi-settings', id], () => {
    return http.get(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/accounts/${id}/manufacturer/edi-settings`,
      { withCredentials: true }
    );
  });
}
