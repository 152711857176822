import { useGetRequest } from 'rx-api/utils';
import {
  GET_ADMIN_PENDING_TASK_KEY,
  GET_ADMIN_PENDING_TASK_URL,
} from 'rx-api/api/admin/pending-tasks/constants';

const useGetPendingTaskList = () => {
  return useGetRequest<
    unknown,
    Array<{
      title: string;
      description: string;
      date: string;
      url: string;
      resourceId: number;
    }>
  >({
    url: GET_ADMIN_PENDING_TASK_URL,
    key: [GET_ADMIN_PENDING_TASK_KEY],
  });
};

export { useGetPendingTaskList };
