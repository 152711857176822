import { useMutation } from 'react-query';

import { IPutManufacturersIdDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePutManufacturersId = () => {
  return useMutation(
    ['admin-update-manufacturer-by-id'],
    async ({ id, ...request }: IPutManufacturersIdDTO) => {
      return http.put(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/manufacturers/${id}`,
        request
      );
    },
    {}
  );
};
