import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const EmptyText = styled.div`
  padding: 3rem 1rem;
  text-align: center;
`;

export const LinkText = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.blue[500]};
    text-decoration: none;
  `}
`;
