import React from 'react';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  CheckoutErrorEnum,
  CheckoutTypeError,
  MinPoAmountErrorItem,
} from 'rx-domain';

import { formatMoney, makeStringWithCommas } from 'rx-utils';
import { Link } from 'react-router-dom';

type IProps = {
  type: CheckoutTypeError;
  reason?: string;
  meta?: {
    items:
      | Array<number>
      | Array<MinPoAmountErrorItem>
      | Array<{ id: number; product: { name: string; units: number } }>;
  };
};

export const CheckoutError: React.FC<IProps> = (props) => {
  return (
    <Alert
      elevation={1}
      variant="filled"
      severity="error"
      style={{ marginTop: '1rem' }}
    >
      {props.type === CheckoutErrorEnum.PAYMENT_TERM_MINIMUM_AMOUNT && (
        <>{props?.reason}</>
      )}

      {props.type === CheckoutErrorEnum.ACH_LIMIT_EXCEEDED && (
        <>{props?.reason}</>
      )}

      {props.type === CheckoutErrorEnum.NOT_ENOUGH_INVENTORY &&
        props.meta &&
        props.meta.items.map((item: any) => (
          <Typography key={item.id}>
            The product {item.product.name} doesn't have enough inventory (
            {item.product.units} available units).
          </Typography>
        ))}

      {props.type === CheckoutErrorEnum.MINIMUM_PURCHASE_ORDER_AMOUNT &&
        props.meta &&
        props.meta.items.map((item: any) => (
          <Typography key={item.seller}>
            You have not reach the minimum purchase amount (
            {formatMoney(item.minimumOrderAmount)}) for <b>{item.seller}</b>.
            Please return to <Link to="/shopping-cart">shopping cart</Link> and
            modify amount or add more items from same seller.
          </Typography>
        ))}

      {props.type === CheckoutErrorEnum.RestrictedState &&
        props.meta &&
        props.meta.items.map((item: any) => (
          <Typography key={item.id}>
            The product "{item.name}" cannot be shipped to{' '}
            {item.shippingConstraints}
          </Typography>
        ))}

      {props.type === CheckoutErrorEnum.UNVERIFIED_ACH && (
        <>Please verify your ACH account and retry.</>
      )}

      {props.type === CheckoutErrorEnum.PAYMENT_TERM_FORBIDDEN && (
        <>Your account doesn't have this operation allowed.</>
      )}

      {props.type === CheckoutErrorEnum.MINIMUM_ORDER_AMOUNT && (
        <>
          The minumum order total is $150 USD. Please return to{' '}
          <Link to="/shopping-cart">shopping cart</Link> and modify amount or
          add more items.
        </>
      )}

      {props.type === CheckoutErrorEnum.EMPTY_SHOPPING_CART && (
        <>
          Your shopping cart is empty. Please return to the{' '}
          <Link to="/">products</Link> page and add a few items.
        </>
      )}

      {props.type === CheckoutErrorEnum.NO_BILLING_ADDRESS && (
        <>Please add a billing address.</>
      )}

      {props.type === CheckoutErrorEnum.NO_SHIPPING_ADDRESS && (
        <>Please add a shipping address.</>
      )}

      {props.type === CheckoutErrorEnum.CARD_DECLINED && (
        <>Your card was declined.</>
      )}

      {props.type === CheckoutErrorEnum.PHARMACY_NOT_APPROVED && (
        <>The pharmacy you selected has not been approved yet.</>
      )}

      {props.type === CheckoutErrorEnum.MISSING_LEGAL_DOCUMENTS && (
        <>Pharmacy is missing DEA and/or State License</>
      )}

      {props.type === CheckoutErrorEnum.BROKEN && (
        <>Something went wrong, please contact with the administrator.</>
      )}

      {props.type === CheckoutErrorEnum.InsufficientFunds && (
        <>Insufficient funds.</>
      )}

      {props.type === CheckoutErrorEnum.GRACE_PERIOD_FORBIDDEN && (
        <>
          Your account doesn't allow the selected payment term. You can use{' '}
          {makeStringWithCommas(props.meta?.items as number[])} days for payment
          terms.
        </>
      )}
    </Alert>
  );
};
