import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border: none;
  background-color: white;

  border-collapse: separate;
  border-spacing: 0px;
`;
