import styled from 'styled-components';
import { OrderPagination } from 'pages/buyer/orders/OrderPagination';

export const Pagination = styled(OrderPagination)`
  & > ul {
    width: 100%;

    background-color: white;
    justify-content: center;
    border-radius: 0;
    border: none;
    margin-top: 1rem;
  }
`;
