// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDownloadFile = () => {
  const onDownloadByLink = (
    url: string,
    filename: string,
    withBlank = false
  ) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);

    if (withBlank) {
      link.setAttribute('target', '_blank');
    }

    document.body.appendChild(link);
    link.click();
  };

  const onDownload = (
    blobData: Blob | string,
    fileTile: string,
    ext = 'csv',
    blobSettings?: BlobPropertyBag
  ): void => {
    const url = window.URL.createObjectURL(new Blob([blobData], blobSettings));

    onDownloadByLink(url, `${fileTile}.${ext}`, true);
  };

  return { onDownload, onDownloadByLink };
};

export { useDownloadFile };
