import React from 'react';

import { SearchQueryParams } from 'rx-domain';
import { ReportFilterDatePicker } from 'pages/admin/reports/PaymentsReports/PaymentsReportFilterDatePicker';

import { Container } from './index.styles';

type IProps = {
  queries: SearchQueryParams;
};

export const OrderFilters: React.FC<IProps> = (props) => {
  return (
    <Container>
      <ReportFilterDatePicker queries={props.queries} />
    </Container>
  );
};
