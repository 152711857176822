import { TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';

export const EdiTableEmpty: React.FC = () => (
  <TableRow>
    <TableCell colSpan={5}>
      <Typography align="center">No EDI Inventory Download logs yet</Typography>
    </TableCell>
  </TableRow>
);
