import styled, { css } from 'styled-components';

export const Title = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoRegular};
    font-size: ${theme.sizes.h2};
    font-weight: 400;
    margin-bottom: 1.5rem;
  `}
`;

export const Spacer = styled.hr`
  opacity: 0.35;
  border: 1px solid #838ca5;
  margin: 2rem 0;
`;
