import { useMutation } from 'react-query';

import { UserApprovalStatus } from 'rx-domain';
import { http } from 'rx-api/http/repository';

type IRequest = {
  approvalStatus: UserApprovalStatus;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDownloadPharmacies = () => {
  const onRequest = async (req: IRequest) => {
    return http.get(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/pharmacists?approvalStatus=${req.approvalStatus}`,
      {
        withCredentials: true,
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
      }
    );
  };

  return useMutation(onRequest, {});
};

export { useDownloadPharmacies };
