import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

const getUrl = (id: string | number): string => {
  return `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/pharmacies/${id}/approval-status`;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUpdatePharmacyApprovalStatus = () => {
  return useMutation(
    'admin-update-pharmacy-approval-status',
    async (data: { id: number; approvalStatus: string }) => {
      return http.put(getUrl(data.id), {
        approvalStatus: data.approvalStatus,
      });
    }
  );
};
