import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

import { useFormikContext } from 'formik';

import { SignupSellerForm } from 'rx-domain';

import { Grid } from '@material-ui/core';

import {
  RxInputText,
  RxCheckbox,
  RxButton,
  RRSelect as RxSelect,
} from 'rx-core';
import { MASK_ZIP_CODE, STATE_CODES_US } from 'rx-utils';

const Container = styled.div`
  width: 100%;
`;

const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h1};
    font-family: ${theme.fonts.roobertLight};
    font-weight: normal;
    text-align: center;
    margin-top: 5rem;

    ${theme.lessThan('ipad')} {
      font-size: ${theme.sizes.h2};
      margin-top: 2.5rem;
    }
  `}
`;

const TextInformation = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoLight};
    font-weight: 400;
    text-align: center;
    color: ${theme.colors.gray[500]};
    line-height: 28px;
    margin-top: 1rem;
    margin-bottom: 2.5rem;

    ${theme.lessThan('ipad')} {
      font-size: ${theme.sizes.small};
      padding: 0 1.5rem;
    }
  `}
`;

const CheckboxText = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoLight};
    font-size: ${theme.sizes.small};
  `}
`;

const CheckboxLink = styled(Link)`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoMedium};
    text-decoration: underline;
    color: black;
  `}
`;

const GridResponsive = styled(Grid)`
  ${({ theme }) => css`
    ${theme.lessThan('ipad')} {
      &.MuiGrid-spacing-xs-2 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }

      &.MuiGrid-spacing-xs-4 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }
    }
  `}
`;

const ButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    ${theme.lessThan('ipad')} {
      padding: 0 1.5rem;
    }
  `}
`;

const SubmitButton = styled(RxButton)`
  margin-top: 12px;
  margin-bottom: 40px;
  max-width: 450px;
`;

export const SignupSellerBussinessForm = (): JSX.Element => {
  const { isValid, isSubmitting } = useFormikContext<SignupSellerForm>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Title>Business information</Title>
      <TextInformation>Tell us more abour your business</TextInformation>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={12}>
          <RxInputText
            name="company.name"
            label="Company Name *"
            placeholder="Your Company"
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="address.address"
            label="Address *"
            placeholder="Business Address"
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="address.city"
            label="City *"
            placeholder="Enter City"
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxSelect
            name="address.state"
            label="State *"
            placeholder="Choose State"
            items={STATE_CODES_US.map((state) => ({
              value: state,
              name: state,
            }))}
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="address.zipCode"
            label="Zip Code *"
            placeholder="Enter Zip Code"
            mask={MASK_ZIP_CODE}
            withMask
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="company.phoneNumber"
            label="Company Phone Number *"
            placeholder="Enter 10 digit number "
            upperLabel={false}
            withMask
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="company.email"
            label="Company Email *"
            placeholder="Enter your company email address"
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={12}>
          <RxCheckbox
            name="subscribeToMailingList"
            text={
              <CheckboxText>
                I would like to have exclusive news and research sent to my
                inbox.
              </CheckboxText>
            }
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={12}>
          <RxCheckbox
            name="acceptTOS"
            text={
              <CheckboxText>
                I agree to the RxRise{' '}
                <CheckboxLink to="/legal/end-user-agreement" target="_blank">
                  Terms and Conditions & Privacy Policy*
                </CheckboxLink>
              </CheckboxText>
            }
          />
        </Grid>
      </GridResponsive>
      <ButtonContainer>
        <SubmitButton
          text="Next"
          type="submit"
          data-testid="submit-button"
          disabled={!isValid || isSubmitting}
          loading={isSubmitting}
        />
      </ButtonContainer>
    </Container>
  );
};
