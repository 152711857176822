import React, { useMemo } from 'react';
import styled from 'styled-components';

import { TransactionFileType } from 'rx-domain';

import { RxProgressBar } from 'rx-core';

type IProps = {
  type: TransactionFileType;
  percent: number;
};

const Container = styled.div``;

const Title = styled.h2`
  font-size: ${({ theme: { sizes } }) => `${sizes.h2}`};
  font-family: ${({ theme: { fonts } }) => `${fonts.robotoLight}`};
  padding-bottom: 16px;
  text-align: center;
`;

const ProgressContainer = styled.div`
  margin-top: 32px;
`;

const TransactionGenerateProgress = ({
  type,
  percent,
}: IProps): JSX.Element => {
  const getTitle = useMemo(() => {
    switch (type) {
      case 'CONSOLIDATED-INVOICE':
        return 'invoice';
      case 'CONSOLIDATED-RECEIPT':
        return 'receipt';
      default:
        return 'invoice';
    }
  }, [type]);

  return (
    <Container>
      <Title>Generating your {getTitle}...</Title>
      <ProgressContainer>
        <RxProgressBar percent={percent} />
      </ProgressContainer>
    </Container>
  );
};

export { TransactionGenerateProgress };
