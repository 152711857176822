import { SEO, TemplateView } from 'rx-core';
import React, { useState } from 'react';
import { Link as LinkRouter } from 'react-router-dom';

import styled, { css } from 'styled-components';
import { Box, Chip, Grid, useMediaQuery } from '@material-ui/core';
import { GetFeaturedProducts } from 'rx-domain';
import { useGetFeaturedProducts, useGetCategoriesList } from 'rx-api';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './custom.css';

import { FormatListBulletedOutlined, GridOnOutlined } from '@material-ui/icons';
import {
  useTheme,
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core/styles';
import { NewMainSearch } from 'rx-core/components/home/search/molecules/NewMainSearch';
import { ProductsTable } from '../products/organims/ProductsTable';
import { FeaturedProduct } from './FeaturedProduct/FeaturedProduct';
import CategoryCardMobile from './CategoryCardMobile/CategoryCardMobile';
import { CategoryCard } from './CategoryCard/CategoryCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '30px',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const Container = styled(Grid)`
  ${({ theme }) => css`
    padding: 1.25rem 1.5rem;

    ${theme.lessThan('ipadPro')} {
      padding: 0.5rem 0;
      margin-bottom: 0;
    }
  `}
`;

const ContainerSlider = styled.div`
  max-width: 330px;
  margin-left: 2px;
`;

const TopContainer = styled(Grid)`
  ${({ theme }) => css`
    padding: 0 1.5rem;

    ${theme.lessThan('ipadPro')} {
      padding: 1.25rem 0;
      margin-bottom: 1.25rem;

      & > div {
        margin-left: 0.7rem;
        margin-right: 0.7rem;
      }
    }
  `}
`;

const FeaturedContainer = styled(Grid)`
  ${({ theme }) => css`
    padding: 1rem 1.5rem 0 1.5rem;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${theme.lessThan('ipadPro')} {
      padding: 1.25rem 0;
      margin-bottom: 1.25rem;

      & > div {
        margin-left: 0.7rem;
        margin-right: 0.7rem;
      }
    }
  `}
`;

interface CategoryContainerProps {
  isMobile?: boolean;
}

const CategoryContainer = styled.div<CategoryContainerProps>`
  max-width: 100%;
  width: 100%;
  background-color: ${(props) => (props.isMobile ? 'none' : 'white')};
  border: ${(props) => (props.isMobile ? 'none' : '1px #b9b9b9 solid')};
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const CarouselContainer = styled.div`
  ${({ theme }) => css`
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    ${theme.lessThan('ipadPro')} {
      margin-left: 0.7rem;
      margin-right: 0.7rem;
    }
  `}
`;

const CarouselElements = styled.div`
  overflow: auto;
  background-color: white;
  margin-top: 20px;
  border-radius: 6px;
  border: 1px #b9b9b9 solid;
  padding: 18px;
`;

const FeaturedFilterContainer = styled.div`
  display: flex;
  align-items: center;
`;

const responsiveCarousel = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 667 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 667, min: 0 },
    items: 1,
  },
};

export const HomeView: React.FC = () => {
  const classes = useStyles();

  const theme = useTheme();

  const { data } = useGetCategoriesList();
  const [typeView, setTypeView] = useState('grid');
  const { data: products, isLoading } = useGetFeaturedProducts({ limit: 3 });
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const productsLoaded = products?.data?.data
    ? products?.data?.data
        .sort(
          (productA, productB) =>
            new Date(productB.updatedAt).getTime() -
            new Date(productA.updatedAt).getTime()
        )
        .map((product) => (
          <ContainerSlider key={product.id}>
            <FeaturedProduct
              {...(product as GetFeaturedProducts.ProductItem)}
            />
          </ContainerSlider>
        ))
    : [];

  return (
    <TemplateView isHomePage={true}>
      <SEO title="RxRise | Products" />
      {process.env.REACT_APP_IS_AI_ENABLED !== 'true' && (
        <Container container></Container>
      )}

      {process.env.REACT_APP_IS_AI_ENABLED !== 'true' && (
        <TopContainer container>
          <div>
            <h2>Welcome to the RxRise Marketplace!</h2>
            <h5 style={{ marginBottom: '10px' }}>
              Explore our thousands of healthcare products.
            </h5>
          </div>
          <CategoryContainer isMobile={isMobile}>
            {data
              ?.sort((a, b) => a.id - b.id)
              .map((category) => (
                <>
                  {isMobile ? (
                    <CategoryCardMobile
                      path={`/products/?category=${category.text}`}
                      background={category.pictureUrl}
                      text={category.slug}
                    />
                  ) : (
                    <CategoryCard
                      path={`/products/?category=${category.text}`}
                      background={category.pictureUrl}
                      text={category.slug}
                    />
                  )}
                </>
              ))}
          </CategoryContainer>
        </TopContainer>
      )}

      {process.env.REACT_APP_IS_AI_ENABLED === 'true' && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          style={{ minHeight: 500 }}
        >
          <h1 style={{ textAlign: 'center', fontSize: '50px' }}>
            Welcome to the RxRise Marketplace!
          </h1>
          <h3
            style={{
              textAlign: 'center',
              fontSize: '20px',
              marginBottom: '30px',
            }}
          >
            Explore our thousands of healthcare products.
          </h3>
          <NewMainSearch />
          <div className={classes.root}>
            <LinkRouter to={`/products`}>
              <Chip label="All Products" variant="outlined" />
            </LinkRouter>
            {data
              ?.sort((a, b) => a.id - b.id)
              .map((category) => (
                <LinkRouter to={`/products/?category=${category.text}`}>
                  <Chip label={category.slug} variant="outlined" />
                </LinkRouter>
              ))}
          </div>
        </Box>
      )}
      <FeaturedContainer>
        <div>
          <h2>Featured Products</h2>
          <h5>Find the best products for your business here at RxRise!</h5>
        </div>
        <FeaturedFilterContainer>
          <span style={{ paddingRight: '10px' }}>Items </span>
          <GridOnOutlined
            style={{
              fontSize: '30px',
              color: typeView === 'grid' ? '#5077F2' : 'initial',
            }}
            onClick={() => setTypeView('grid')}
            fontSize="large"
          />
          <FormatListBulletedOutlined
            style={{
              fontSize: '30px',
              color: typeView === 'list' ? '#5077F2' : 'initial',
            }}
            onClick={() => setTypeView('list')}
            fontSize="large"
          />
        </FeaturedFilterContainer>
      </FeaturedContainer>
      <CarouselContainer>
        <CarouselElements>
          {typeView === 'grid' ? (
            <Carousel
              itemClass="homeFeaturedProduct"
              responsive={responsiveCarousel}
            >
              {productsLoaded}
            </Carousel>
          ) : (
            <ProductsTable
              isLoading={isLoading}
              products={products?.data?.data || []}
            />
          )}
        </CarouselElements>
      </CarouselContainer>
    </TemplateView>
  );
};
