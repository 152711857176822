import React, { useState } from 'react';
import { useToast } from 'rx-hooks';

import { Grid, Menu, MenuItem } from '@material-ui/core';
import axios from 'axios';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';

import { RxButtonSimple } from 'rx-core';

import {
  usePostAdminOrdersIdCancel,
  usePostAdminPurchaseOrdersIdCancel,
  usePostPurchaseOrderCancel,
} from 'rx-api';
import { useOrder } from 'rx-contexts';
import { useQueryClient } from 'react-query';
import {
  Actions,
  CancelButton,
  Container,
  Modal,
  OtherContainer,
  SelectContainer,
  SelectType,
  SelectTypeText,
  Spacer,
  Text,
  TextArea,
  Title,
} from './index.styles';

const options = [
  { text: 'Purchased by mistake', value: 'Purchased by mistake' },
  { text: 'Found cheaper option', value: 'Found cheaper option' },
  { text: 'Wrong product', value: 'Wrong product' },
  { text: 'Other...', value: 'Other...' },
];

export const TransactionCancel: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [cancelReason, setCancelReason] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { cancelModalVisible, onCloseCancelModal, cancelAction } = useOrder();
  const toast = useToast();

  const queryClient = useQueryClient();

  const cancelPO = usePostPurchaseOrderCancel();
  const adminCancelPO = usePostAdminPurchaseOrdersIdCancel();
  const cancelOrderMutation = usePostAdminOrdersIdCancel();

  const commonCancelValidation =
    cancelPO.isLoading || !!errorMessage === true || !selectedOption;

  const cancelButtonValidation =
    selectedOption !== 'Other...'
      ? commonCancelValidation
      : commonCancelValidation || !cancelReason;

  const onAdminCancel = async () => {
    try {
      if (!cancelAction) return;
      if (cancelAction.idCancel === null) return;

      if (cancelAction.type === 'purchaseOrder') {
        await adminCancelPO.mutateAsync({
          id: cancelAction.idCancel,
          reason: selectedOption === 'Other...' ? cancelReason : selectedOption,
        });
      }

      if (cancelAction.type === 'order') {
        await cancelOrderMutation.mutateAsync({
          id: cancelAction.idCancel,
          reason: selectedOption === 'Other...' ? cancelReason : selectedOption,
        });
      }

      await queryClient.refetchQueries('get-transactions', {
        active: true,
      });

      onCloseCancelModal();
      setCancelReason('');
      setErrorMessage('');
      toast.onSuccessToast(`Order cancelled`);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setErrorMessage(err.response?.data.message);
      } else {
        toast.onErrorToast('Error: Could not cancel order');
      }
    }
  };

  const onReturnList = () => {
    onCloseCancelModal();
    setErrorMessage('');
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value: string) => {
    setAnchorEl(null);
    setSelectedOption(value);
    setCancelReason('');
  };

  return (
    <Modal
      visible={cancelModalVisible}
      onClose={onCloseCancelModal}
      closeButton={false}
    >
      <Container>
        {!errorMessage && <Title>Cancel Order?</Title>}
        <Text>
          {errorMessage || 'Would you like to cancel your purchase order?'}
        </Text>
        {!errorMessage && (
          <SelectContainer>
            <SelectType
              aria-controls="search-admin-filter-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <SelectTypeText>
                {selectedOption || 'Choose reason'}
              </SelectTypeText>
              <KeyboardArrowDownIcon />
            </SelectType>
            <Menu
              id="search-admin-filter-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {options.map((op) => (
                <MenuItem onClick={() => handleClose(op.value)} key={op.value}>
                  {op.text}
                </MenuItem>
              ))}
            </Menu>
            {selectedOption === 'Other...' && (
              <OtherContainer>
                <TextArea
                  cols={10}
                  rows={10}
                  onChange={(e) => {
                    setCancelReason(e.target.value);
                  }}
                ></TextArea>
              </OtherContainer>
            )}
          </SelectContainer>
        )}
        <Spacer />
        <Actions
          container
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <RxButtonSimple
              onClick={onReturnList}
              disabled={cancelPO.isLoading}
              variant="outline"
            >
              {errorMessage ? 'Cancel' : 'No, return to list'}
            </RxButtonSimple>
          </Grid>
          <Grid item>
            <CancelButton
              onClick={onAdminCancel}
              disabled={cancelButtonValidation}
              loading={cancelPO.isLoading}
            >
              Proceed to cancel
            </CancelButton>
          </Grid>
        </Actions>
      </Container>
    </Modal>
  );
};
