import styled, { css } from 'styled-components';
import { LayoutProps, layout } from 'styled-system';

export const Container = styled.div`
  border-top: 1px solid #929292;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
`;

export const RowContainer = styled.div`
  display: flex;
`;

export const RowItemContainer = styled.div<LayoutProps & { header?: boolean }>`
  ${({ theme, header }) => css`
    text-align: left;
    font-size: ${theme.sizes.small};
    padding: 0 0.5rem 0.5rem 0.5rem;
    ${layout};

    ${header &&
    css`
      font-weight: bold;
    `}
  `}
`;

export const HeaderContainer = styled.div<LayoutProps>`
  ${layout};
  display: flex;
  text-align: left;
`;
