import React from 'react';
import { GetProductById } from 'rx-domain';
import { US_STATES_WITH_CODES } from 'rx-utils';

import { ProductRestriction } from '../ProductRestriction';

import { Container } from './index.styles';

type IProps = {
  product: GetProductById.Response;
};

export const ProductRestrictions: React.FC<IProps> = (props) => {
  return (
    <Container>
      {props.product.shippingConstraints && (
        <ProductRestriction
          info
          title="State Restrictions"
          content={`This item does not ship to the following states: ${props.product.shippingConstraints
            .split(',')
            .map(
              (state) =>
                US_STATES_WITH_CODES.find(
                  (stateCode) => stateCode.value === state
                )?.name || state
            )
            .join(', ')}`}
        />
      )}
      {props.product.shippingName?.toLowerCase() ===
        'republic pharmaceuticals' && (
        <ProductRestriction
          title="Shipping minimum"
          content="$150 Ground and $300 Overnight"
        />
      )}
    </Container>
  );
};
