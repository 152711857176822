import React from 'react';
import { Grid } from '@material-ui/core';

import { SEO, BannerTitle, TemplateView } from 'rx-core';

import { Settings2FA } from './Settings2FA';
import { SettingsNav } from '../../shared/SettingsNav';
import { SettingsChangePass } from './SettingsChangePass';

import {
  Container,
  PageContainer,
  SettingsList,
  Separator,
  Title,
} from './index.styles';

const SettingsSecuritySellerView = (): JSX.Element => {
  return (
    <TemplateView data-cy="account-settings">
      <SEO title="RxRise | Settings Security" />
      <BannerTitle>
        <Title>Account Settings</Title>
      </BannerTitle>
      <div style={{ padding: 16, flexGrow: 1 }}>
        <Container container wrap="nowrap">
          <SettingsList item xs={4}>
            <Grid item xs={9}>
              <SettingsNav
                links={[
                  {
                    text: 'My Profile',
                    to: '/settings/profile',
                    dataCy: 'account-settings-profile',
                  },
                  {
                    text: 'Shipping',
                    to: '/settings/shipping',
                    dataCy: 'account-settings-shipping',
                  },
                  {
                    text: 'Security',
                    to: '/settings/security',
                    dataCy: 'account-settings-security',
                  },
                ]}
              />
            </Grid>
          </SettingsList>
          <PageContainer item xs={9}>
            <Grid item md={8} lg={8}>
              <SettingsChangePass />
              <Separator />
              <Settings2FA />
            </Grid>
          </PageContainer>
        </Container>
      </div>
    </TemplateView>
  );
};

export { SettingsSecuritySellerView };
