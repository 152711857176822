import React from 'react';
import styled from 'styled-components';
import { SpaceProps, space } from 'styled-system';

type TermsAndConditionsContentProps = SpaceProps & {
  children: React.ReactNode;
};

const Text = styled.p`
  font-family: Roboto Light;
  font-style: normal;
  font-size: 18px;
  color: #2b3647;
  ${space};
`;

const TermsAndConditionsContent = ({
  children,
  ...props
}: TermsAndConditionsContentProps): JSX.Element => {
  return <Text {...props}>{children}</Text>;
};

export { TermsAndConditionsContent };
