import React from 'react';
import { useDispatch } from 'react-redux';
import { Chip } from '@material-ui/core';

import { PaymentMethodCard } from 'rx-domain';
import { AppDispatch, setCheckoutSummaryPaymentMethod } from 'rx-store';
import { getPaymentMethodName } from 'rx-utils';

import { Container } from '../CheckoutPaymentACH/CheckoutPaymentACH.styles';
import { CheckoutPaymentCardACHInput } from '../CheckoutPaymentCardACHInput';

type Cards = PaymentMethodCard & {
  type: 'card';
};

type ICheckoutPaymentCardProps = {
  selectedPaymentMethod: {
    type: 'ach' | 'card' | 'paymentTerms';
    value: string;
  } | null;
  paymentMethod: Cards;
  onChange: (type: 'card', value: string) => void;
};

function isExpired(expirationObj: { expMonth: number; expYear: number }) {
  const currentDate = new Date();
  const expDate = new Date(expirationObj.expYear, expirationObj.expMonth - 1);

  return expDate < currentDate;
}

export const CheckoutPaymentCard: React.FC<ICheckoutPaymentCardProps> = ({
  paymentMethod,
  selectedPaymentMethod,
  onChange,
}) => {
  const dispatch: AppDispatch = useDispatch();

  function handleChange() {
    onChange('card', paymentMethod.id.toString());
    dispatch(
      setCheckoutSummaryPaymentMethod(getPaymentMethodName(paymentMethod))
    );
  }

  return (
    <Container>
      <td data-cy="checkout-payment-method-item">
        <CheckoutPaymentCardACHInput
          disabled={isExpired(paymentMethod)}
          text={getPaymentMethodName(paymentMethod)}
          value={paymentMethod.id.toString()}
          onChange={handleChange}
          name="checkout-payment"
          checked={`${selectedPaymentMethod?.value}` === `${paymentMethod.id}`}
        />
      </td>
      <td>
        {paymentMethod.expMonth}/{paymentMethod.expYear}{' '}
      </td>
      <td>
        {isExpired(paymentMethod) ? (
          <Chip label="EXPIRED" size="small" />
        ) : (
          <>Verified ✅</>
        )}{' '}
      </td>
    </Container>
  );
};
