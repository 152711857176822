import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { NewSearchFilters } from './NewSearchFilters';
import { NewSearchFindButton } from './NewSearchFindButton';

import NewSearchAutoCompleteInput from './NewSearchAutoCompleteInput';
import {
  AppBar,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

import SearchByActiveIngredient from './SearchByActiveIngredient';
import SearchByName from './SearchByName';
import SearchByNDC from './SearchByNDC';

type IProps = React.HTMLAttributes<HTMLDivElement>;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    height: '3.5rem',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
  },
  tabContainer: {
    width: '800px',
    backgroundColor: 'white',
    boxShadow:
      '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: '0 0 10px 10px ',
  },
  tabAdvancedContainer: {
    width: '800px',
    height: '180px',
    backgroundColor: 'white',
    boxShadow:
      '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: '0 0 10px 10px ',
  },
  tabHeader: {
    borderRadius: '10px 10px 0 0',
  },
}));

const Container = styled.div`
  ${({ theme }) => css`
    height: 13.5rem;
    border-radius: 4px;
    display: flex;
  `}
`;

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const NewMainSearch = (props: IProps): JSX.Element => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(0);
  const [activeRadio, setActiveRadio] = useState('active');

  const handleChange = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const onRadioChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    setActiveRadio(target.value);
  };

  return (
    <Container {...props}>
      {process.env.REACT_APP_IS_AI_ENABLED === 'true' && (
        <div className={classes.root}>
          <AppBar position="static" className={classes.tabHeader}>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Global Search" />
              <Tab label="Advanced Drug Search" />
            </Tabs>
          </AppBar>
          <TabPanel
            value={activeTab}
            index={0}
            className={classes.tabContainer}
          >
            <div className={classes.container}>
              <NewSearchFilters />
              <NewSearchAutoCompleteInput />
              <NewSearchFindButton />
            </div>
          </TabPanel>
          <TabPanel
            value={activeTab}
            index={1}
            className={classes.tabAdvancedContainer}
          >
            <div>
              <div style={{ color: 'black' }}>
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="active"
                  onChange={onRadioChange}
                >
                  <FormControlLabel
                    value="active"
                    control={<Radio color="primary" />}
                    label="Active Ingredient"
                  />
                  <FormControlLabel
                    value="name"
                    control={<Radio color="primary" />}
                    label="Name"
                  />
                  <FormControlLabel
                    value="ndc"
                    control={<Radio color="primary" />}
                    label="NDC"
                  />
                </RadioGroup>
              </div>
              {activeRadio === 'active' && <SearchByActiveIngredient />}
              {activeRadio === 'name' && <SearchByName />}
              {activeRadio === 'ndc' && <SearchByNDC />}
            </div>
          </TabPanel>
        </div>
      )}
    </Container>
  );
};

export { NewMainSearch };
