import React from 'react';
import styled, { css } from 'styled-components';

const ErrorMessage = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    padding: 0.75rem;
    background-color: ${theme.colors.gray[200]};
  `}
`;

const AddPaymentMethodError: React.FC = ({ children }): JSX.Element => {
  return <ErrorMessage>{children}</ErrorMessage>;
};

export { AddPaymentMethodError };
