import React from 'react';

import { GetSaleOrderPayments } from 'rx-domain';

import { formatMoney } from 'rx-utils';

import {
  TableColumn,
  TableRow,
  TableColumnTitle,
  TableAmount,
} from './index.styles';

type IProps = {
  meta: GetSaleOrderPayments.SaleOrderPaymentMeta;
};

const ReportSummary = (props: IProps): JSX.Element => {
  return (
    <>
      <TableRow>
        <TableColumnTitle colSpan={11}>Summary</TableColumnTitle>
      </TableRow>
      <TableRow>
        <TableColumn>Total Sold</TableColumn>
        <TableColumn>Total Paid</TableColumn>
        <TableColumn>Total Pending</TableColumn>
      </TableRow>
      <TableRow>
        <TableAmount>{formatMoney(props.meta.totalAmount)}</TableAmount>
        <TableAmount>
          {formatMoney(props.meta.totalDepositedAmount)}
        </TableAmount>
        <TableAmount>{formatMoney(props.meta.totalDueAmount)}</TableAmount>
      </TableRow>
    </>
  );
};

export { ReportSummary };
