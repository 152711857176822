import * as Yup from 'yup';

import { password } from '../common.validator';

const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('The old password is required'),
  newPassword: password,
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Required'),
});

export { ChangePasswordSchema };
