import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const ProductDetailLodaer: React.FC = () => {
  return (
    <Paper variant="outlined" style={{ padding: '1rem' }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Skeleton height={30} variant="text" />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Skeleton height={250} variant="rect" />
        </Grid>
        <Grid item xs={8}>
          <Skeleton height={50} variant="text" />
          <Skeleton height={50} variant="text" />
          <Skeleton height={50} variant="text" />
          <Skeleton width="30%" height={50} variant="text" />
        </Grid>
      </Grid>
    </Paper>
  );
};
