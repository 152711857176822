import React from 'react';
import styled from 'styled-components';

type FooterAddressTitleProps = {
  children: React.ReactNode;
};

const Text = styled.span`
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 16px;
`;

const FooterAddressTitle = ({
  children,
}: FooterAddressTitleProps): JSX.Element => {
  return <Text>{children}</Text>;
};

export { FooterAddressTitle };
