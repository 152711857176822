import { useMutation } from 'react-query';
import { IDeleteInventoryProductsDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDeleteProduct = () => {
  const onRequest = (req: IDeleteInventoryProductsDTO) => {
    return http.delete(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/products/${req.id}`
    );
  };

  return useMutation(onRequest);
};
