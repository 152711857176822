import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { GetAdminProducts } from 'rx-domain';

interface IProps {
  products: Array<
    GetAdminProducts.ProductItem & {
      quantity: number;
    }
  >;
  onChangeProductQuantity: (id: number) => (e: any) => void;
  onChangeProductPrice: (id: number) => (e: any) => void;
  onClickDeleteProduct: (id: number) => (e: any) => void;
}

const hasQuantityError = (
  product: GetAdminProducts.ProductItem & {
    quantity: number;
  }
): boolean => {
  return (
    product.quantity <= 0 ||
    product.units < product.quantity ||
    product.quantity < product.minimumQuantity ||
    (product.maximumQuantity !== null &&
      product.maximumQuantity !== 0 &&
      product.quantity >= product.maximumQuantity)
  );
};

const getQuantityError = (
  product: GetAdminProducts.ProductItem & {
    quantity: number;
  }
): string => {
  if (product.quantity <= 0) {
    return 'Quantity must be greater than 0';
  }

  if (product.units < product.quantity) {
    return 'Not enough stock';
  }

  if (product.quantity < product.minimumQuantity) {
    return `Minimum quantity is ${product.minimumQuantity}`;
  }

  if (
    product.maximumQuantity !== null &&
    product.maximumQuantity !== 0 &&
    product.quantity >= product.maximumQuantity
  ) {
    return `Maximum quantity is ${product.maximumQuantity}`;
  }

  return '';
};

const hasPriceError = (
  product: GetAdminProducts.ProductItem & {
    quantity: number;
  }
): boolean => {
  return parseFloat(product.price) <= 0;
};

const getPriceError = (
  product: GetAdminProducts.ProductItem & {
    quantity: number;
  }
): string => {
  if (parseFloat(product.price) <= 0) {
    return 'Price must be greater than 0';
  }

  return '';
};

export const NewPurchaseOrderSeletedProducts: React.FC<IProps> = (props) => {
  return (
    <Table style={{ marginTop: '1rem' }}>
      <TableHead>
        <TableRow>
          <TableCell>NDC</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Units in Stock</TableCell>
          <TableCell>Quantity</TableCell>
          <TableCell>Price</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.products.map((product) => {
          return (
            <TableRow key={product.id}>
              <TableCell>{product.ndc}</TableCell>
              <TableCell>
                {product.name} {product.description}
              </TableCell>
              <TableCell>{product.units}</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={product.quantity}
                  onChange={props.onChangeProductQuantity(product.id)}
                  variant="outlined"
                  error={hasQuantityError(product)}
                  helperText={getQuantityError(product)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={product.price}
                  onChange={props.onChangeProductPrice(product.id)}
                  variant="outlined"
                  error={hasPriceError(product)}
                  helperText={getPriceError(product)}
                />
              </TableCell>
              <TableCell>
                <Button
                  variant="text"
                  onClick={props.onClickDeleteProduct(product.id)}
                >
                  <CloseIcon />
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
