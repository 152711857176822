import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

type PostShoppingCartRequestPayloadDTO = {
  productId: number;
  quantity: number;
};

type PostShoppingCartResponsePayloadDto = {
  createdAt: string;
  id: number;
  productId: number;
  quantity: number;
  shoppingCartId: number;
  updatedAt: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePostShoppingCart = () => {
  const onRequest = async (req: PostShoppingCartRequestPayloadDTO) => {
    return http.post<PostShoppingCartResponsePayloadDto>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/pharmacists/shopping-cart`,
      req
    );
  };

  return useMutation(onRequest, {});
};
