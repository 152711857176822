import React from 'react';
import styled from 'styled-components';

import { LoginFormTemplate } from 'pages/general/login/template';
import { LoginHeader } from 'pages/general/login/molecules';

import { SEO } from 'rx-core';
import { ForgotPasswordForm } from '../organims';

const Container = styled.div`
  height: 100vh;
  position: relative;
`;

const ForgotPasswordView = (): JSX.Element => {
  return (
    <Container>
      <SEO title="RxRise | Forgot Password" />
      <LoginHeader />
      <LoginFormTemplate>
        <ForgotPasswordForm />
      </LoginFormTemplate>
    </Container>
  );
};

export { ForgotPasswordView };
