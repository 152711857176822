import React from 'react';

import { RxTableTab } from '../RxTableTab';
import { TabsContainer, Container } from './index.styles';

export type RxTableTabsProps = {
  items: Array<{
    title: string;
    active: boolean;
    value: string;
    onSetView: (value: string) => void;
  }>;
  filterComp?: React.ReactNode;
};

const RxTableTabs: React.FC<RxTableTabsProps> = ({
  children,
  ...props
}): JSX.Element => {
  return (
    <Container>
      <TabsContainer>
        {props.items.map((item) => (
          <RxTableTab {...item} key={item.value} />
        ))}
      </TabsContainer>
      {props.filterComp}
      {children}
    </Container>
  );
};

export { RxTableTabs };
