import React, { useMemo, useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useGetAdminManufacturers } from 'rx-api';
import { RxPagination } from 'rx-core';
import { useSkipPage } from 'rx-hooks';
import { ApproveManufacturerCard } from '../../molecules/ApproveManufacturerCard';

export const ApproveManufacturer = (): JSX.Element => {
  const [length, setLength] = useState(0);

  const { skip, totalPages } = useSkipPage(length);

  const { data: sellerData, isLoading } = useGetAdminManufacturers({
    skip,
  });

  const totalSellers = useMemo(() => {
    if (sellerData?.data) return sellerData.data.pagination.total;

    return 0;
  }, [sellerData]);

  useEffect(() => {
    if (sellerData?.data && !isLoading) {
      setLength(sellerData.data.pagination.total);
    }
  }, [sellerData, isLoading]);

  return (
    <div style={{ marginBottom: '2rem' }}>
      <header style={{ marginBottom: '0.2rem' }}>
        <Typography variant="h5">New Manufacturer Sign Up</Typography>
        <Typography variant="h6">
          You have {totalSellers} requests to approve:
        </Typography>
      </header>

      <Grid spacing={4} container>
        {sellerData?.data &&
          sellerData.data.results.map((seller) => (
            <Grid xs={4} key={seller.id} item>
              <ApproveManufacturerCard
                title="Manufacturer"
                account={{
                  id: seller.id,
                  firstName: seller.firstName,
                  lastName: seller.lastName,
                  phoneNumber: seller.phoneNumber,
                  titleOrPosition: seller.titleOrPosition,
                  companyName: seller.manufacturer.companyName,
                  accountFiles: [],
                  address: seller.address,
                }}
              />
            </Grid>
          ))}
      </Grid>

      <div>
        {totalPages > 1 && (
          <RxPagination
            path="approve"
            initialParams="type=manufacter&"
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
};
