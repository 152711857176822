import { IAddress, IGetAccountPharmacyDataDTO } from 'rx-domain';
import { CS_SET_SUMMARY, CS_SET_PAYMENT_METHOD } from './actionTypes';

export interface CheckoutSummaryState {
  delivered: {
    shipAddressData?: IAddress;
    pharmacyData?: IGetAccountPharmacyDataDTO;
  };
  paymentMethod: string;
  subtotal: number;
  orderNumber: string;
}

export interface CheckoutSummaryAction {
  type: string;
  summary: CheckoutSummaryState;
}

const initialState: CheckoutSummaryState = {
  delivered: {},
  paymentMethod: '',
  subtotal: 0,
  orderNumber: '',
};

const checkoutSummaryReducer = (
  state: CheckoutSummaryState = initialState,
  action: CheckoutSummaryAction
): CheckoutSummaryState => {
  switch (action.type) {
    case CS_SET_SUMMARY: {
      const { paymentMethod, ...summary } = action.summary;

      return {
        ...state,
        ...summary,
      };
    }
    case CS_SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: action.summary.paymentMethod,
      };
    }
    default:
      return state;
  }
};

export { initialState, checkoutSummaryReducer };
