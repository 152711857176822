import React from 'react';
import { SEO, TemplateView } from 'rx-core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useAdminUploadDrugsBulk } from 'rx-api';
import { useToast } from 'rx-hooks';

import { AddProductActions } from '../../../general/add-product/AddProductActions';
import { AdminAddIndividualProductForm } from '../organims/AdminAddIndividualProductForm';
import { EditDrugValidator } from '../../../../validations/EditDrugValidator';

export const AdminAddIndividualProductView: React.FC = () => {
  const history = useHistory();
  const toast = useToast();

  const handleSubmit = async (values: any) => {
    try {
      if (formik.values.sellerId) {
        const { categoryId, sellerId, ...destructuredValues } = values;
        await adminBulkInventory.mutateAsync({
          type: categoryId,
          overrideInventory: false,
          data: [destructuredValues],
        });
      }
      toast.onSuccessToast('Product added!');
      history.push('/products');
    } catch (e) {
      toast.onErrorToast('Could not add product');
    }
  };

  const formik = useFormik({
    initialValues: {
      ndc: '',
      name: '',
      description: '',
      manufacturer: '',
      price: 0,
      expirationDate: '',
      lotNumber: '',
      units: 0,
      minimumQuantity: 0,
      maximumQuantity: undefined,
      productId: '',
      categoryId: 'drug',
      sellerId: 0,
      shippingName: '',
      shippingConstraints: '',
      groundShipping: '',
      strengthValue: undefined,
      strengthUnit: 'none',
    },
    validationSchema: EditDrugValidator,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const adminBulkInventory = useAdminUploadDrugsBulk(
    formik.values.sellerId ? formik.values.sellerId : 0
  );

  return (
    <TemplateView>
      <SEO title="RxRise | Add New Individual Product" />
      <form onSubmit={formik.handleSubmit}>
        <AddProductActions isEdit={false} productName={''} />
        <AdminAddIndividualProductForm
          errors={formik.errors}
          isDisabled={false}
          isLoading={false}
          onChange={formik.handleChange}
          onClickCancel={() => history.push('/')}
          values={formik.values}
          setFieldValue={formik.setFieldValue}
        />
      </form>
    </TemplateView>
  );
};
