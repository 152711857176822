import React from 'react';
import { CircularProgress, Box } from '@material-ui/core';

import { PaymentMethodCard, PaymentMethodACH } from 'rx-domain';

import { useAddPayment } from 'rx-contexts';

// TODO: Refactor to checkout
import { AddPaymentMethodModal } from '../../../shared/payment-method/organims';
// TODO: Refactor to checkout
import { VerifyPaymentACH } from '../../../shared/verify-payment/organims/VerifyPaymentACH';
import { CheckoutCard } from '../CheckoutCard/CheckoutCard';
import { CheckoutPaymentTerms } from '../CheckoutPaymentTerms/CheckoutPaymentTerms';
import { CheckoutPaymentMethods } from '../CheckoutPaymentMethods/CheckoutPaymentMethods';
import { CheckoutNewPaymentCardACH } from '../CheckoutNewPaymentCardACH';

import { Container } from './CheckoutPayments.styles';
import { Separator } from '../../settingsV2/index.styles';

type ICheckoutPaymentsProps = {
  cards: PaymentMethodCard[];
  achs: PaymentMethodACH[];
  hasPaymentTerm: boolean;
  selectedPaymentMethod: {
    type: 'ach' | 'card' | 'paymentTerms';
    value: string;
  } | null;
  onChangePaymentMethod: (
    type: 'ach' | 'card' | 'paymentTerms',
    value: string
  ) => void;
  disabled: boolean;
  pharmacyId: number | string;
  isLoading?: boolean;
  innerRef?: any;
};

export const CheckoutPayments: React.FC<ICheckoutPaymentsProps> = (props) => {
  const { onOpenModal, onOpenVerifyModal, onSetPaymentId } = useAddPayment();

  const hasPaymentMethod = props.cards.length > 0 || props.achs.length > 0;

  const handleVerify = (id: number) => {
    onSetPaymentId(id);
    onOpenVerifyModal(true);
  };

  return (
    <CheckoutCard>
      <Container ref={props.innerRef}>
        {props.isLoading && (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress size="5rem" />
          </Box>
        )}

        {hasPaymentMethod && (
          <CheckoutPaymentMethods
            achs={props.achs}
            cards={props.cards}
            selectedPaymentMethod={props.selectedPaymentMethod}
            onChange={props.onChangePaymentMethod}
            onVerify={handleVerify}
          />
        )}

        <CheckoutNewPaymentCardACH onClick={() => onOpenModal(true)} />

        {props.hasPaymentTerm && (
          <>
            <Separator />
            <CheckoutPaymentTerms
              disabled={props.disabled}
              onChange={props.onChangePaymentMethod}
            />
          </>
        )}

        <AddPaymentMethodModal />
        <VerifyPaymentACH pharmacyId={props.pharmacyId} />
      </Container>
    </CheckoutCard>
  );
};
