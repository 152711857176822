import React from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useToast } from 'rx-hooks';
import { Button, Divider, Grid, Paper, Typography } from '@material-ui/core';

import { SEO, TemplateContent, TemplateView } from 'rx-core';

import { password } from 'utils/validators/common.validator';
import { ReactivateAccountModal } from 'pages/general/ReactivateAccountModal';
import { SuspendAccountModal } from 'pages/general/SuspendAccountModal';
import { SuspendedAccountBanner } from 'pages/general/SuspendedAccountBanner';
import { AccountPasswordForm } from '../../general/AccountPasswordForm';
import { AdminAccountDeleteModal } from './AdminAccountDeleteModal';
import { AdminAccountForm } from './AdminAccountForm';
import {
  useAdminUpdateAccountPassword,
  useDeleteAccount,
  useGetAccountById,
  useReactivateAccount,
  useSuspendAccount,
  useUpdateAccount,
} from 'rx-api/api';

export const AdminAccountView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetAccountById(id);
  const toast = useToast();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isSuspendModalOpen, setIsSuspendModalOpen] = React.useState(false);
  const [isReactivateModalOpen, setIsReactivateModalOpen] =
    React.useState(false);
  const deleteAccount = useDeleteAccount();
  const suspendAccount = useSuspendAccount();
  const reactivateAccount = useReactivateAccount();
  const updateAccount = useUpdateAccount(id);
  const queryClient = useQueryClient();
  const updatePassword = useAdminUpdateAccountPassword(id);

  const accountFormik = useFormik({
    initialValues: {
      firstName: data?.data.firstName || '',
      lastName: data?.data.lastName || '',
      email: data?.data.email || '',
      secondaryEmail: data?.data.secondaryEmail || '',
      phoneNumber: data?.data.phoneNumber || '',
      titleOrPosition: data?.data.titleOrPosition || '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await updateAccount.mutateAsync(values);
        toast.onSuccessToast('Account information updated successfully!');
      } catch (err) {
        toast.onErrorToast('Error: could not update account information');
      }
    },
  });

  const passwordFormik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      newPassword: password.required(),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], "Passwords dont't match")
        .required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        await updatePassword.mutateAsync(values);
        toast.onSuccessToast('Password changed!');
      } catch (err) {
        toast.onErrorToast('Error: could not change password');
      }
    },
  });

  const handleClickCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClickDelete = () => {
    setIsModalOpen(true);
  };

  const handleClickConfirmDelete = async () => {
    try {
      await deleteAccount.mutateAsync(id);
      toast.onSuccessToast('Account deleted');
      history.push('/pharmacies');
    } catch (err) {
      toast.onErrorToast('Error: could not delete account');
    }
  };

  const handleClickSuspend = () => {
    setIsSuspendModalOpen(true);
  };

  const handleClickCloseSuspendModal = () => {
    setIsSuspendModalOpen(false);
  };

  const handleClickConfirmSuspendModal = async () => {
    try {
      await suspendAccount.mutateAsync(id);
      await queryClient.refetchQueries('admin-get-account-by-id', {
        active: true,
      });

      setIsSuspendModalOpen(false);
      toast.onSuccessToast('Account Suspended');
    } catch (err) {
      toast.onErrorToast('Error: could not suspend account');
    }
  };

  const handleClickReactivate = () => {
    setIsReactivateModalOpen(true);
  };

  const handleClickCloseReactivateModal = () => {
    setIsReactivateModalOpen(false);
  };

  const handleClickConfirmReactivateModal = async () => {
    try {
      await reactivateAccount.mutateAsync(id);
      await queryClient.refetchQueries('admin-get-account-by-id', {
        active: true,
      });

      setIsReactivateModalOpen(false);
      toast.onSuccessToast('Account Reactivated');
    } catch (err) {
      toast.onErrorToast('Error: could not reactivate account');
    }
  };

  return (
    <TemplateView>
      <SEO title="RxRise | Ad List" />
      <TemplateContent>
        <header>
          <Typography variant="h5">Account</Typography>
        </header>

        {data?.data.approvalStatus === 'suspended' && (
          <div>
            <SuspendedAccountBanner />
          </div>
        )}

        <Paper
          variant="outlined"
          style={{ padding: '1rem', marginTop: '1rem' }}
        >
          <AdminAccountForm
            firstName={accountFormik.values.firstName}
            lastName={accountFormik.values.lastName}
            email={accountFormik.values.email}
            secondaryEmail={accountFormik.values.secondaryEmail}
            titleOrPosition={accountFormik.values.titleOrPosition}
            phoneNumber={accountFormik.values.phoneNumber}
            isLoading={isLoading}
            onChange={accountFormik.handleChange}
            onSubmit={accountFormik.handleSubmit}
          />
        </Paper>

        <Divider style={{ margin: '1rem 0' }} />

        <Paper variant="outlined" style={{ padding: '1rem' }}>
          <AccountPasswordForm
            onSubmit={passwordFormik.handleSubmit}
            onChange={passwordFormik.handleChange}
            values={passwordFormik.values}
            errors={passwordFormik.errors}
            disabled={passwordFormik.isSubmitting}
          />
        </Paper>

        <Divider style={{ margin: '1rem 0' }} />

        <Paper variant="outlined" style={{ padding: '1rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Danger Zone</Typography>
            </Grid>

            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {data?.data.approvalStatus !== 'suspended' && (
                  <>
                    <div>
                      <Typography variant="h6">Suspend Account</Typography>
                      <Typography>
                        Temporarily disable the account to prevent logging in.
                      </Typography>
                    </div>

                    <Button
                      variant="outlined"
                      onClick={handleClickSuspend}
                      disabled={isLoading}
                    >
                      Suspend Account
                    </Button>
                  </>
                )}

                {data?.data.approvalStatus === 'suspended' && (
                  <>
                    <div>
                      <Typography variant="h6">Reactivate Account</Typography>
                      <Typography>
                        Reactivate account and allow to log-in.
                      </Typography>
                    </div>

                    <Button
                      variant="outlined"
                      onClick={handleClickReactivate}
                      disabled={isLoading}
                    >
                      Reactivate Account
                    </Button>
                  </>
                )}
              </div>

              <Divider style={{ margin: '1rem 0' }} />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Typography variant="h6">Delete Account</Typography>
                  <Typography>
                    The account will be erased from RxRise.
                  </Typography>
                </div>

                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleClickDelete}
                  disabled={isLoading}
                >
                  Delete Account
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>

        <AdminAccountDeleteModal
          isOpen={isModalOpen}
          onClickClose={handleClickCloseModal}
          onClickConfirm={handleClickConfirmDelete}
        />

        <SuspendAccountModal
          isOpen={isSuspendModalOpen}
          onClickClose={handleClickCloseSuspendModal}
          onClickConfirm={handleClickConfirmSuspendModal}
        />

        <ReactivateAccountModal
          isOpen={isReactivateModalOpen}
          onClickClose={handleClickCloseReactivateModal}
          onClickConfirm={handleClickConfirmReactivateModal}
        />
      </TemplateContent>
    </TemplateView>
  );
};
