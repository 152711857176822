import { getDateValue } from 'rx-utils';

type IPurchaseOrder = {
  id: number;
  buyerId: number;
  createdAt: string;
  orderId: string;
  poPrefix: string;
  sellerId: number;
  shippingCarrier: string;
  soPrefix: string;
  status: string;
  subtotal: string;
  trackingNumber: string;
  pharmacyName: string;
  trackingUrl: string;
  items: {
    id: string;
    quantity: number;
    price: string;
    purchaseOrderId: number;
    line: string;
    createdAt: string;
  }[];
};

export const formatPurchaseOrders = (
  data: IPurchaseOrder[],
  includeOrderItems: boolean
) => {
  const basicHeaders = [
    'Order ID',
    'Purchase Order ID',
    'Pharmacy Name',
    'Created At',
    'Seller ID',
    'Shipping Carrier',
    'Status',
    'Subtotal',
    'Tracking Number',
    'Tracking URL',
  ];

  const orderItemsHeaders = [
    'Order ID',
    'Purchase Order ID',
    'Pharmacy Name',
    'Status',
    'Line',
    'Quantity',
    'Price',
    'Date',
  ];

  const headers = includeOrderItems ? orderItemsHeaders : basicHeaders;

  if (includeOrderItems) {
    const lineCsv = [
      headers,
      ...data.flatMap((order) =>
        order.items.map((item) => [
          order.orderId,
          `${order.poPrefix}-${order.id}`,
          order.pharmacyName,
          order.status,
          item.line.replace(/,/g, ''),
          item.quantity,
          item.price,
          getDateValue(order.createdAt),
        ])
      ),
    ]
      .map((e) => e.join(','))
      .join('\n');
    return lineCsv;
  } else {
    const lineCsv = [
      headers,
      ...data.map((item) => [
        item.orderId,
        `${item.poPrefix}-${item.id}`,
        item.pharmacyName,
        getDateValue(item.createdAt),
        item.sellerId,
        item.shippingCarrier,
        item.status,
        item.subtotal,
        item.trackingNumber,
        item.trackingUrl,
      ]),
    ]
      .map((e) => e.join(','))
      .join('\n');
    return lineCsv;
  }
};
