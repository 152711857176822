import { useGetRequest } from 'rx-api/utils';

type AdCampaignItemDto = {
  id: number;
  link: string;
  name: string;
  pictureUrl: string;
  type: 'popup' | 'banner';
  createdAt: string;
  updatedAt: string;
};

export const useGetPharmacistAdCampaigns = () => {
  const { isLoading, data, ...rest } = useGetRequest<
    unknown,
    {
      banner: AdCampaignItemDto | null;
      popup: AdCampaignItemDto | null;
    }
  >({
    key: 'get-pharmacist-ad-campaign-item-list',
    url: `${process.env.REACT_APP_WEBSITE_URL}/v3/pharmacist/ad-campaigns`,
  });

  if (isLoading) {
    return { ...rest, data: { banner: null, popup: null }, isLoading };
  }

  return {
    ...rest,
    isLoading,
    data: data?.data ?? { banner: null, popup: null },
  };
};
