import { useQuery } from 'react-query';
import { http } from 'rx-api/http/repository';

export const useGetAccountEmailRecipients = () => {
  return useQuery('get-account-email-recipients', async () => {
    return http.get(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/account/email-recipients`,
      { withCredentials: true }
    );
  });
};
