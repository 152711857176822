import { useMutation } from 'react-query';

import { IPutOrdersIdInvoiceStatusDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePutOrdersIdInvoiceStatus = () => {
  const onRequest = async ({
    id,
    ...request
  }: IPutOrdersIdInvoiceStatusDTO) => {
    return http.put(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/orders/${id}/payment-status`,
      request
    );
  };

  return useMutation(onRequest, {});
};

export { usePutOrdersIdInvoiceStatus };
