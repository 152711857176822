import React from 'react';
import { Grid } from '@material-ui/core';

import { Settings2FA } from '../../../shared/settings-security/Settings2FA';
import { SettingsChangePass } from '../../../shared/settings-security/SettingsChangePass';
import { SettingsTemplate } from '../SettingsTemplate';

import { Separator } from './index.styles';
import { settingsBuyerTabs } from '../SettingsTemplate/tabs';

const SettingsSecurityView = (): JSX.Element => {
  return (
    <SettingsTemplate pageTitle="RxRise | Security" links={settingsBuyerTabs()}>
      <Grid item md={8} lg={8}>
        <SettingsChangePass />
        <Separator />
        <Settings2FA />
      </Grid>
    </SettingsTemplate>
  );
};

export { SettingsSecurityView };
