import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { useDownloadProduct } from 'rx-contexts';
import React, { useEffect, useState } from 'react';
import { LinearProgress } from '@material-ui/core';
import XLSX from 'xlsx';
import { useDownloadProductsV2 } from 'rx-api/api/admin/products/useDownloadProductsV2';
import { useDownloadTotalProductsV2 } from 'rx-api/api/admin/products/useDownloadTotalProductsV2';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const OFFSET = 10000;

const AdminDownloadProductsModal: React.FC = () => {
  const classes = useStyles();
  const downloadProducts = useDownloadProductsV2();
  const downloadTotalProducts = useDownloadTotalProductsV2();
  const { downloadProductsVisible, onSetDownloadProductsVisible } =
    useDownloadProduct();

  const [totalCount, setTotalCount] = useState(null);
  const [progress, setProgress] = useState(0);
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    const getTotalCount = async () => {
      const { data } = await downloadTotalProducts.mutateAsync();
      setTotalCount(data.total);
    };
    getTotalCount();
  }, []);

  useEffect(() => {
    const downloadAllProducts = async () => {
      let offset = 0;
      let products: any = [];

      while (offset < (totalCount || 0)) {
        const { data } = await downloadProducts.mutateAsync({ offset });
        products = [...products, ...data];
        offset += OFFSET;
        setProgress(products.length);
      }

      setAllProducts(products);
    };

    if (totalCount && totalCount > 0) {
      downloadAllProducts();
    }
  }, [totalCount]);

  const normalise = () =>
    (((progress || 0) - 0) * 100) / ((totalCount || 0) - 0);

  const convertArrayOfObjectsToXLSX = (array: any) => {
    const ws = XLSX.utils.json_to_sheet(array);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Products');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'products.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const downloadCSV = (array: any) => {
      convertArrayOfObjectsToXLSX(array);
    };

    if (totalCount && allProducts.length > 0) {
      if (totalCount <= progress) {
        downloadCSV(allProducts);
        onSetDownloadProductsVisible(false);
      }
    }
  }, [progress, allProducts]);

  return (
    <div>
      <Modal
        open={downloadProductsVisible}
        onClose={() => onSetDownloadProductsVisible(false)}
      >
        <div className={classes.paper}>
          <h2 id="simple-modal-title">Generating CSV</h2>
          <h3 id="simple-modal-title">Total Products: {totalCount}</h3>
          <p id="simple-modal-description" />
          {totalCount && (
            <LinearProgress variant="determinate" value={normalise()} />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AdminDownloadProductsModal;
