import React, { createContext, FC, useContext, useState } from 'react';

type ContextProps = {
  csrfToken: string;
  email: string;
  onSetCSRFToken: (value: string) => void;
  onSetEmail: (value: string) => void;
};

const LoginContext = createContext<ContextProps | undefined>(undefined);

const LoginProvider: FC = ({ children }) => {
  const [csrfToken, setCSRFToken] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const onSetCSRFToken = (value: string) => {
    setCSRFToken(value);
  };

  const onSetEmail = (value: string) => {
    setEmail(value);
  };

  return (
    <LoginContext.Provider
      value={{ csrfToken, email, onSetCSRFToken, onSetEmail }}
    >
      {children}
    </LoginContext.Provider>
  );
};

const useLogin = (): ContextProps => {
  const context = useContext(LoginContext);

  if (context === undefined) {
    throw new Error('useLogin must be used within a LoginProvider');
  }

  return context;
};

export { useLogin, LoginProvider };
