import React from 'react';
import styled from 'styled-components';

import { RxHeader } from '../../home/RxHeader';
import { Footer } from '../../home/footer/organims/Footer';

const Container = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.gray[100]};

  display: flex;
  flex-direction: column;
`;

const CustomFooter = styled(Footer)`
  position: relative;
  bottom: 0;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-bottom: 2rem;
`;

type IProps = {
  isHomePage?: boolean;
  children: React.ReactNode[];
};

export const TemplateView = ({ isHomePage, children }: IProps) => {
  return (
    <Container>
      <RxHeader isHomePage={isHomePage} />
      <Content>{children}</Content>
      <CustomFooter />
    </Container>
  );
};

export const TemplateContent = styled.section`
  margin: 2rem 0;
`;
