import React from 'react';
import styled, { css } from 'styled-components';

import { useSignupManufacturer } from 'rx-contexts';

import { SignupSellerWizardSteps } from './SignupSellerWizardSteps';

const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    height: 74px;

    ${theme.lessThan('desktop')} {
      display: none;
    }
  `}
`;

const SignupSellerWizard = (): JSX.Element => {
  const { items, onChangeWizard } = useSignupManufacturer();

  return (
    <Container>
      <SignupSellerWizardSteps items={items} onClick={onChangeWizard} />
    </Container>
  );
};

export { SignupSellerWizard };
