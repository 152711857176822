import React from 'react';

import { useTransactionFilter } from 'rx-hooks';

import { TransactionSearch } from '../TransactionSearch';
import { TransactionFilterBuyer } from '../TransactionFilterBuyer';
import { TransactionFilterSeller } from '../TransactionFilterSeller';
import { TransactionDatePicker } from '../TransactionDatePicker';

import { Container, FilterContainer } from './index.styles';

const TransactionFilter = (): JSX.Element => {
  const { onSearchBuyer, onSearchSeller, onFilterTransactionsByDate } =
    useTransactionFilter();

  return (
    <>
      <Container>
        <TransactionSearch />
      </Container>
      <Container>
        <FilterContainer>
          <TransactionFilterSeller onGetSeller={onSearchSeller} />
          <TransactionFilterBuyer onGetBuyer={onSearchBuyer} />
          <TransactionDatePicker callback={onFilterTransactionsByDate} />
        </FilterContainer>
      </Container>
    </>
  );
};

export { TransactionFilter };
