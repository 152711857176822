import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;
`;

export const MoneyText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.blue[300]};
  `}
`;

export const ShopCartContainer = styled.div`
  margin: 1rem 0;
`;

export const Input = styled.input<{ $error: boolean }>`
  background-color: white;
  height: 2.5rem;
  border: 1px grey solid;
  border-radius: 0.5rem;
  padding-left: 1rem;
  width: 100%;

  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ${({ $error }) => css`
    ${$error &&
    css`
      border: 1px red solid;
    `}
  `}
`;
