import React, { LabelHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

export type InputTitleProps = {
  title: string;
  error?: boolean;
  upperLabel?: boolean;
  disabled?: boolean | undefined;
};

const Text = styled.label<{
  error?: boolean;
  upperLabel?: boolean;
  disabled?: boolean | undefined;
}>`
  font-size: ${({ theme }) => `${theme.sizes.small}`};
  font-family: ${({ theme }) => `${theme.fonts.robotoRegular}`};
  line-height: 25px;

  color: ${({ theme }) => `${theme.colors.black[100]}`};
  font-weight: 400;
  ${({ error, theme }) =>
    error &&
    css`
      color: ${theme.colors.red[100]};
    `};

  ${({ upperLabel }) =>
    upperLabel &&
    css`
      text-transform: uppercase;
      font-size: ${({ theme }) => `${theme.sizes.extraSmall}`};
      font-family: ${({ theme }) => `${theme.fonts.robotoRegular}`};
    `}

  ${({ theme, disabled }) => css`
    ${disabled &&
    css`
      color: ${theme.colors.gray[400]};
    `}
  `}
`;

const InputTitle = ({
  title,
  ...props
}: LabelHTMLAttributes<HTMLLabelElement> & InputTitleProps): JSX.Element => {
  return <Text {...props}>{title}</Text>;
};

InputTitle.defaultProps = {
  error: false,
  upperLabel: true,
};

export { InputTitle };
