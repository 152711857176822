import {
  SearchBuyerAction,
  SearchBuyerFilterType,
  SearchBuyerSortByType,
  SearchBuyerSortOrderType,
} from './reducer';

const setBuyerSearchText = (text: string): SearchBuyerAction => {
  return {
    type: 'SET_SEARCH_TEXT',
    search: {
      text,
    },
  };
};

const setBuyerSearchCleanText = (): SearchBuyerAction => {
  return {
    type: 'SET_SEARCH_CLEAN',
    search: {
      text: '',
    },
  };
};

const setBuyerSearchLoading = (loading: boolean): SearchBuyerAction => {
  return {
    type: 'SET_SEARCH_LOADING',
    search: {
      loading,
    },
  };
};

const setBuyerSearchType = (
  searchType: SearchBuyerFilterType
): SearchBuyerAction => {
  return {
    type: 'SET_SEARCH_TYPE',
    search: {
      searchType,
    },
  };
};

const setBuyerSearchSortByType = (
  sortBy: SearchBuyerSortByType
): SearchBuyerAction => {
  return {
    type: 'SET_SEARCH_SORT_BY_TYPE',
    search: {
      sortBy,
    },
  };
};

const setBuyerSearchSortOrderType = (
  sortOrder: SearchBuyerSortOrderType
): SearchBuyerAction => {
  return {
    type: 'SET_SEARCH_ORDER_TYPE',
    search: {
      sortOrder,
    },
  };
};

export {
  setBuyerSearchText,
  setBuyerSearchCleanText,
  setBuyerSearchLoading,
  setBuyerSearchType,
  setBuyerSearchSortByType,
  setBuyerSearchSortOrderType,
};
