import React from 'react';

import { useAuth } from 'rx-hooks';

import { Title } from './index.styles';

const DashboardTitle = (): JSX.Element => {
  const { firstName } = useAuth();

  return <Title>Welcome back {firstName}!</Title>;
};

export { DashboardTitle };
