import { Paper } from '@material-ui/core';
import React from 'react';

type ICheckoutCardProps = {
  children?: React.ReactNode;
  isFullHeight?: boolean;
};

export const CheckoutCard: React.FC<ICheckoutCardProps> = (props) => {
  return (
    <div
      style={{
        height: props.isFullHeight ? '100%' : 'initial',
      }}
    >
      <Paper
        variant="elevation"
        style={{
          height: '100%',
        }}
      >
        <div
          style={{
            padding: '1rem',
            boxSizing: 'border-box',
          }}
        >
          {props.children}
        </div>
      </Paper>
    </div>
  );
};
