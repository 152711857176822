import React from 'react';
import styled from 'styled-components';

type LoginSubtitleProps = {
  text: string;
  className?: string;
};

const Text = styled.p`
  font-family: Roboto Regular;
  font-size: 16px;
  line-height: 28px;

  color: ${({ theme }) => theme.colors.gray[500]};
`;

const LoginSubtitle = ({ text, ...props }: LoginSubtitleProps): JSX.Element => {
  return <Text {...props}>{text}</Text>;
};

LoginSubtitle.defaultProps = {
  className: '',
};

export { LoginSubtitle };
