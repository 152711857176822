import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';

import { ShopCartItem, ShippingConfig } from 'rx-domain';

import { ShopCartCardTitle } from '../ShopCartCardTitle';
import { ShopCartDrugItems } from '../ShopCartDrugItems';
import { ShopCartCardTotal } from '../ShopCartCardTotal';

import { Container, Table } from './index.styles';

type IShopCartCardProps = {
  title: string;
  products: ShopCartItem[];
  shipConfig: ShippingConfig;
  mode?: 'summary' | 'shop-cart';
};

export const ShopCartCard: React.FC<IShopCartCardProps> = (props) => {
  return (
    <Container>
      <ShopCartCardTitle title={props.title} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>NDC</TableCell>
            <TableCell>NAME</TableCell>
            <TableCell>PRICE</TableCell>
            <TableCell>EXP. DATE</TableCell>
            <TableCell>QUANTITY</TableCell>
            <TableCell>SUB TOTAL</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <ShopCartDrugItems
          products={props.products}
          shipConfig={props.shipConfig}
          grantTotal={props.shipConfig.grantTotal}
          mode={props.mode}
        />

        {props.mode === 'shop-cart' && (
          <ShopCartCardTotal grantTotal={props.shipConfig.grantTotal} />
        )}
      </Table>
    </Container>
  );
};

ShopCartCard.defaultProps = {
  mode: 'shop-cart',
};
