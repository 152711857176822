import * as Yup from 'yup';

import { zipCode, city, state, address } from '../../common.validator';

const PharmacyShippingAddressSchema = Yup.object().shape({
  address,
  city,
  state,
  zipCode,
});

export { PharmacyShippingAddressSchema };
