import React from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { Grid, TextField, Button } from '@material-ui/core';
import { useAddPharmacyPaymentMethodCard } from 'rx-api';
import { useToast } from 'rx-hooks';
import { useQueryClient } from 'react-query';

interface IProps {
  onSuccess(): void;
  onClickCancel(): void;
}

export const AddPharmacyCardPaymentMethod: React.FC<IProps> = (props) => {
  const toast = useToast();
  const params = useParams<{ id: string }>();
  const addPaymentMethod = useAddPharmacyPaymentMethodCard(params.id);
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      cardHolderName: '',
      cardNumber: '',
      expMonth: '',
      expYear: '',
      cvv: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      cardHolderName: Yup.string().required(
        "Please enter the card holder's name"
      ),
      cardNumber: Yup.string().required(
        'Please enter the 16 digits of your card number'
      ),
      expMonth: Yup.number()
        .lessThan(13, 'Invalid expiration month')
        .moreThan(0, 'Invalid expiration month')
        .required('Please enter the expiration month'),
      expYear: Yup.string()
        .length(4, 'Invalid expiration year')
        .required('Please enter the expiration year'),
      cvv: Yup.string()
        .min(3, 'Please enter a valid CVV')
        .max(6, 'Please enter a valid CVV')
        .required('Please enter the CVV'),
    }),
    onSubmit: async (values) => {
      try {
        await addPaymentMethod.mutateAsync({
          setAsDefault: false,
          card: {
            cardHolderName: values.cardHolderName,
            number: values.cardNumber.split(' ').join(''),
            expMonth: parseInt(values.expMonth, 10),
            expYear: parseInt(values.expYear, 10),
            cvc: values.cvv,
          },
        });
        await queryClient.refetchQueries('get-pharmacy-payment-methods-cards', {
          active: true,
        });
        toast.onSuccessToast('Card added successfully!');
        props.onSuccess();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toast.onErrorToast(`Error:  ${error.response?.data.message}`);
        } else {
          toast.onErrorToast('Could not add card!');
        }
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="cardHolderName"
            variant="outlined"
            label="Card Holder Name"
            value={formik.values.cardHolderName}
            onChange={formik.handleChange}
            error={!!formik.errors.cardHolderName}
            helperText={formik.errors.cardHolderName}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12}>
          <InputMask
            mask="9999 9999 9999 9999"
            value={formik.values.cardNumber}
            onChange={formik.handleChange}
            maskChar=" "
          >
            {() => (
              <TextField
                variant="outlined"
                name="cardNumber"
                label="Card Number"
                error={!!formik.errors.cardNumber}
                helperText={formik.errors.cardNumber}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
              />
            )}
          </InputMask>
        </Grid>

        <Grid item xs={4}>
          <InputMask
            mask="99"
            value={formik.values.expMonth}
            onChange={formik.handleChange}
            maskChar=" "
          >
            {() => (
              <TextField
                placeholder="12"
                name="expMonth"
                variant="outlined"
                label="Expiration Month"
                error={!!formik.errors.expMonth}
                helperText={formik.errors.expMonth}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
              />
            )}
          </InputMask>
        </Grid>

        <Grid item xs={4}>
          <InputMask
            mask="9999"
            value={formik.values.expYear}
            onChange={formik.handleChange}
            maskChar=" "
          >
            {() => (
              <TextField
                placeholder={`${new Date().getFullYear()}`}
                name="expYear"
                variant="outlined"
                label="Expiration Year"
                error={!!formik.errors.expYear}
                helperText={formik.errors.expYear}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
              />
            )}
          </InputMask>
        </Grid>

        <Grid item xs={4}>
          <TextField
            placeholder="1234"
            name="cvv"
            variant="outlined"
            label="CVV"
            value={formik.values.cvv}
            onChange={formik.handleChange}
            error={!!formik.errors.cvv}
            helperText={formik.errors.cvv}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <div
        style={{
          padding: '1rem 0',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={props.onClickCancel}
          disabled={addPaymentMethod.isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={addPaymentMethod.isLoading}
        >
          Add
        </Button>
      </div>
    </form>
  );
};
