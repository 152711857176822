import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Modal, Paper, Typography } from '@material-ui/core';
import { ModalContainer } from './ModalContainer';
import { ModalContent } from './ModalContent';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
import { ModalCloseButton } from './ModalCloseButton';

interface IAdListDeleteItemModalProps {
  isOpen: boolean;
  isDisabled: boolean;
  onClickCancel(): void;
  onClickConfirm(): void;
}

export const AdListDeleteItemModal: React.FC<IAdListDeleteItemModalProps> = (
  props
) => {
  return (
    <Modal open={props.isOpen} onClose={props.onClickCancel}>
      <ModalContainer>
        <Paper variant="outlined" style={{ padding: '1rem', width: '40rem' }}>
          <ModalHeader>
            <Typography variant="h6">Delete ad?</Typography>
            <ModalCloseButton onClick={props.onClickCancel}>
              <CloseIcon />
            </ModalCloseButton>
          </ModalHeader>

          <ModalContent>
            <Typography>
              You Ad <strong>won't be</strong> available for display of the
              RxRise platform anymore!
            </Typography>
            <Typography>Would you like to continue?</Typography>
          </ModalContent>

          <ModalFooter>
            <Button
              variant="contained"
              color="primary"
              onClick={props.onClickCancel}
              disabled={props.isDisabled}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              disabled={props.isDisabled}
              onClick={props.onClickConfirm}
            >
              Confirm
            </Button>
          </ModalFooter>
        </Paper>
      </ModalContainer>
    </Modal>
  );
};
