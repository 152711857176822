import React, { useState } from 'react';
import ReactGA from 'react-ga';
import styled, { css } from 'styled-components';
import { CircularProgress } from '@material-ui/core';

import { Formik, Form, Field } from 'formik';
import { usePostAuthLogin2FA } from 'rx-api';

import { DataState } from 'rx-domain';

import { Input, ButtonPrimary, RxText } from 'rx-core';

import { VerificationSchema } from 'utils/validators/login/login-2fa.validator';
import { useLogin } from 'rx-contexts';
import { VerificationFormTemplate } from '../template';
import {
  VerificationFormTitle,
  VerificationFormSubtitle,
  VerificationBack,
} from '../atoms';

interface IVerificationForm {
  code: string;
}

const initialValues: IVerificationForm = {
  code: '',
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding-bottom: 26px;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipad')} {
      flex-direction: column;
    }
  `}
`;

const InputContainer = styled.div`
  width: 386px;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipadPro')} {
      width: 100%;
    }
  `}
`;

const ActionContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipad')} {
      flex-direction: column-reverse;
      align-items: flex-start;
      width: 100%;
    }
  `}
`;

const MessageError = styled(RxText)`
  margin-top: 12px;
  margin-bottom: 28px;
`;

const Spacer = styled.div`
  height: 62px;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipad')} {
      display: none;
    }
  `}
`;

const LoadingIcon = styled(CircularProgress)`
  ${({ theme }) => css`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: ${theme.colors.blue[100]} !important;
  `}
`;

const VerificationForm = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [stateForm, setStateForm] = useState<DataState>('none');

  const mutation = usePostAuthLogin2FA();
  const { email, csrfToken, onSetCSRFToken, onSetEmail } = useLogin();

  const onBack = () => {
    onSetCSRFToken('');
    onSetEmail('');
  };

  const onSubmit = async (values: IVerificationForm) => {
    try {
      setLoading(true);
      const data = await mutation.mutateAsync({
        ...values,
        csfr: csrfToken,
        email,
      });

      ReactGA.event({
        category: 'User',
        action: '2FA process finished',
      });

      localStorage.setItem(
        '_user',
        JSON.stringify({
          firstName: data.data.firstName,
          lastName: data.data.lastName,
          email: data.data.email,
          type: data.data.type,
          companyName: data.data.companyName,
          paymentTerm: data.data.paymentTerm,
          permissions: data.data.permissions,
          isEdiEnabled: !!data.data?.isEdiEnabled,
        })
      );

      window.location.reload();
      setStateForm('success');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setStateForm('error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <VerificationFormTemplate>
      {loading && <LoadingIcon size={26} thickness={6} />}

      <VerificationFormTitle />
      <VerificationFormSubtitle email={email} />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={VerificationSchema}
      >
        <Form>
          <InputContainer>
            <Field
              name="code"
              label="Enter the code"
              type="text"
              state={stateForm}
              inputError
              component={Input}
              data-testid="code"
              data-cy="2fa-code"
            />
          </InputContainer>
          {stateForm === 'error' && (
            <MessageError color="#FF6969" size="small">
              We&apos;re sorry. We weren&apos;t able to identify your code. Try
              again.
            </MessageError>
          )}
          {stateForm !== 'error' && <Spacer />}
          <ActionContainer>
            <VerificationBack onClick={onBack} />
            <ButtonContainer>
              <ButtonPrimary text="Login" data-cy="2fa-submit" />
            </ButtonContainer>
          </ActionContainer>
        </Form>
      </Formik>
    </VerificationFormTemplate>
  );
};

export { VerificationForm };
