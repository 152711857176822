import styled, { css } from 'styled-components';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

export const Container = styled.div``;

export const PickerContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1rem;
  border: 1px solid #a0b0f7;
  border-radius: 8px;
  background: #ffffff;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DateText = styled.div<{ $active: boolean }>`
  ${({ theme, $active }) => css`
    padding: 0.5rem;
    font-size: ${theme.sizes.small};
    background-color: white;
    color: black;
    padding-left: 1.25rem;

    ${$active &&
    css`
      color: white;
      background-color: ${theme.colors.blue[500]};
    `}
  `}
`;

export const InputPicker = styled.button`
  background-color: white;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #9fa6b9;
  margin-left: 1rem;
  font-size: 18px;
  padding: 0.5rem;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  min-width: 14rem;
`;

export const CalendarIcon = styled(CalendarTodayIcon)`
  margin-left: 0.5rem;
`;
