import React from 'react';
import { useParams } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';

import { usePharmacistGetPharmacyMemberList } from 'rx-api';
import { PharmacyMemberListLoader } from './PharmacyMemberListLoader';

interface IMemberDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

export const PharmacyMemberList: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = usePharmacistGetPharmacyMemberList(id);

  return (
    <Paper variant="outlined" style={{ padding: '1rem' }}>
      <Typography variant="h5">Members</Typography>

      {isLoading && <PharmacyMemberListLoader />}

      {!isLoading && (
        <List dense={true}>
          {data?.data.map((member: IMemberDto) => (
            <>
              <ListItem>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${member.firstName} ${member.lastName}`}
                  secondary={member.email}
                />
                <ListItemSecondaryAction>
                  <Chip label={member.role} />
                </ListItemSecondaryAction>
              </ListItem>
            </>
          ))}
        </List>
      )}
    </Paper>
  );
};
