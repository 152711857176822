import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { http } from 'rx-api';
import { useToast } from 'rx-hooks';

function useGetManufacturerEdiSettings() {
  return useQuery('get-manufacturer-edi-settings', () => {
    return http.get(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/manufacturer/edi-settings`,
      { withCredentials: true }
    );
  });
}

function useUpdateManufacturerEdiSettings() {
  return useMutation(
    'update-manufacturer-edi-settings',
    (data: { ediPartnerId: string }) => {
      return http.put(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/manufacturer/edi-settings`,
        data
      );
    },
    {}
  );
}

export const SettingsEdi: React.FC = () => {
  const response = useGetManufacturerEdiSettings();
  const updateEdiSettings = useUpdateManufacturerEdiSettings();
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      ediPartnerId: response?.data?.data?.ediPartnerId ?? '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await updateEdiSettings.mutateAsync(values);
        toast.onSuccessToast('EDI Settings save!');
      } catch (err) {
        toast.onErrorToast('Error: could not save EDI Settings');
      }
    },
  });

  return (
    <Paper variant="outlined" style={{ padding: '1rem' }}>
      <Typography variant="h5" style={{ marginBottom: '1rem' }}>
        EDI Settings
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={6}>
            <TextField
              name="ediPartnerId"
              label="EDI Partner ID"
              variant="outlined"
              value={formik.values.ediPartnerId}
              onChange={formik.handleChange}
              error={!!formik.errors.ediPartnerId}
              disabled={formik.isSubmitting}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
