import React from 'react';
import { useQueryClient } from 'react-query';
import { Grid, Paper, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import { usePostPharmaciesApprove, usePostPharmaciesReject } from 'rx-api';
import { useToast } from 'rx-hooks';

import { ApproveCardActions } from '../../atoms/card-actions';

interface IApproveAccountCardProps {
  title: string;
  account: {
    id: number;
    firstName: string;
    lastName: string;
    companyName: string;
    phoneNumber: string;
    titleOrPosition: string;
    accountFiles: Array<{ name: string; url: string }>;
    address?: {
      address: string;
      city: string;
      state: string;
      zipCode: string;
    };
  };
}

export const ApproveAccountCard: React.FC<IApproveAccountCardProps> = (
  props
): JSX.Element => {
  const { onSuccessToast, onErrorToast } = useToast();

  const approveBuyer = usePostPharmaciesApprove();
  const rejectBuyer = usePostPharmaciesReject();

  const queryClient = useQueryClient();

  const DEA_FILE = props.account.accountFiles.find((af) => af.name === 'dea');
  const STATE_LICENSE_FILE = props.account.accountFiles.find(
    (af) => af.name === 'state_license'
  );

  const onApprove = async () => {
    try {
      await approveBuyer.mutateAsync({ id: props.account.id.toString() });
      await queryClient.refetchQueries('get-pharmacies', {
        active: true,
      });
      await queryClient.refetchQueries('get-manufacturers', {
        active: true,
      });

      onSuccessToast('Approved successfully ✅');
    } catch (err) {
      onErrorToast('Could not approve account');
    }
  };

  const onReject = async () => {
    try {
      await rejectBuyer.mutateAsync({ id: props.account.id.toString() });
      await queryClient.refetchQueries('get-pharmacies', {
        active: true,
      });
      await queryClient.refetchQueries('get-manufacturers', {
        active: true,
      });

      onSuccessToast('Account Rejected');
    } catch (err) {
      onErrorToast('Could not reject account');
    }
  };

  return (
    <Paper>
      <div style={{ padding: '1rem' }}>
        <header style={{ paddingBottom: '1rem' }}>
          <Typography variant="h5">{props.title}</Typography>
        </header>

        {DEA_FILE && STATE_LICENSE_FILE && (
          <div style={{ marginBottom: '1.5rem' }}>
            <div style={{ marginBottom: '0.75rem' }}>
              <Typography variant="subtitle1">Legal Documents</Typography>
            </div>

            <Grid container spacing={4}>
              <a href={DEA_FILE?.url}>
                <div style={{ display: 'flex', padding: '0.5rem' }}>
                  <GetAppIcon />
                  <Typography>Dea</Typography>
                </div>
              </a>

              <a href={STATE_LICENSE_FILE?.url}>
                <div style={{ display: 'flex', padding: '0.5rem' }}>
                  <GetAppIcon />
                  <Typography>State License</Typography>
                </div>
              </a>
            </Grid>
          </div>
        )}

        <div>
          <Typography variant="h6">Personal Information</Typography>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            First Name
          </Grid>
          <Grid item xs={6}>
            {props.account.firstName}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            Last Name
          </Grid>
          <Grid item xs={6}>
            {props.account.lastName}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            Title or Position
          </Grid>
          <Grid item xs={6}>
            {props.account.titleOrPosition}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            Phone Number
          </Grid>
          <Grid item xs={6}>
            {props.account.phoneNumber}
          </Grid>
        </Grid>

        <div style={{ marginTop: '1.5rem' }}>
          <Typography variant="h6">Company Information</Typography>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            Company Name
          </Grid>
          <Grid item xs={6}>
            {props.account.companyName}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            Street Address
          </Grid>
          <Grid item xs={6}>
            {props.account.address?.address}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            State
          </Grid>
          <Grid item xs={6}>
            {props.account.address?.state}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            City
          </Grid>
          <Grid item xs={6}>
            {props.account.address?.city}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            Zip Code
          </Grid>
          <Grid item xs={6}>
            {props.account.address?.zipCode}
          </Grid>
        </Grid>

        <footer style={{ marginTop: '1rem' }}>
          <ApproveCardActions
            onApprove={onApprove}
            onReject={onReject}
            disabled={rejectBuyer.isLoading || approveBuyer.isLoading}
          />
        </footer>
      </div>
    </Paper>
  );
};
