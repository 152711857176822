import {
  GET_ADMIN_METRICS_URL,
  GET_ADMIN_METRICS_KEY,
} from 'rx-api/api/admin/metrics/constants';
import {
  useGetRequest,
  convertUrlWithQuery,
  makeArrayReactQueryKey,
} from 'rx-api/utils';

export namespace GetAdminMetrics {
  export type Query = {
    from: string;
    to: string;
  };

  export type Response = {
    buyers: number;
    sellers: number;
    transactions: number;
    products: number;
    ediProducts: number;
  };
}

const useAdminGetMetrics = (query: Partial<GetAdminMetrics.Query>) => {
  return useGetRequest<
    Partial<GetAdminMetrics.Query>,
    GetAdminMetrics.Response
  >({
    url: convertUrlWithQuery(query, GET_ADMIN_METRICS_URL),
    key: makeArrayReactQueryKey(GET_ADMIN_METRICS_KEY, query),
  });
};

export { useAdminGetMetrics };
