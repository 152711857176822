import { SEO, TemplateView } from 'rx-core';
import React from 'react';
import styled from 'styled-components';

import { SystemLogFilters } from './SystemLogFilters';
import { UserLoginLogs } from './UserLoginLogs';
import { EdiInventoryUploadLogs } from './EdiInventoryUploadLogs';
import { ShoppingCartTableContainer } from './ShoppingCartLogs';
import { EdiInventoryTransmitLogs } from './EdiInventoryTransmitLogs';
import { Typography } from '@material-ui/core';

export const Container = styled.div`
  padding: 0;
  margin: 1.5rem;
`;

export const SystemLogsView: React.FC = () => {
  const [filter, setFilter] = React.useState<
    'login' | 'edi-download' | 'shopping-cart' | 'edi-transmit'
  >('login');
  const [seller, setSeller] = React.useState('all');

  const handleFilterChange = (
    _filterName: string,
    filterValue: 'login' | 'edi-download' | 'edi-transmit' | 'shopping-cart'
  ) => {
    setFilter(filterValue);
    setSeller('all');
  };

  const handleChangeSeller = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSeller(event.target.value);
  };

  return (
    <TemplateView>
      <SEO title="RxRise | System Logs" />
      <Container>
        <Typography style={{ color: '#000000', fontSize: '24px' }}>
          System Logs
        </Typography>
        <SystemLogFilters
          filter={filter}
          onFilterChange={handleFilterChange}
          seller={seller}
          onChangeSeller={handleChangeSeller}
        />

        <>
          {filter === 'login' && <UserLoginLogs />}
          {filter === 'shopping-cart' && <ShoppingCartTableContainer />}
          {filter === 'edi-download' && (
            <EdiInventoryUploadLogs seller={seller} />
          )}
          {filter === 'edi-transmit' && <EdiInventoryTransmitLogs />}
        </>
      </Container>
    </TemplateView>
  );
};
