import React from 'react';
import styled from 'styled-components';
import { position, PositionProps, space, SpaceProps } from 'styled-system';

type FooterBottomTextProps = PositionProps &
  SpaceProps & {
    children: React.ReactNode;
  };

const Text = styled.span`
  color: #9fa6b9;
  font-size: 16px;
  line-height: 19px;
  ${position}
  ${space}
`;

const FooterBottomText = ({
  children,
  ...props
}: FooterBottomTextProps): JSX.Element => {
  return <Text {...props}>{children}</Text>;
};

export { FooterBottomText };
