import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';

interface IProps {
  isOpen: boolean;
  onClickClose(): void;
  onClickConfirm(): void;
}

export const AdminSellerDangerZoneDeleteModal: React.FC<IProps> = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClickClose}
      aria-labelledby="delete-manufacturer-dialog-title"
      aria-describedby="delete-manufacturer-dialog-description"
    >
      <DialogTitle id="delete-manufacturer-dialog-title">
        Delete manufacturer
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-manufacturer-dialog-description">
          Are you sure you want to delete this manufacturer?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem',
        }}
      >
        <Button onClick={props.onClickClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={props.onClickConfirm}
          color="secondary"
          variant="outlined"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
