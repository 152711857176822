const PRIMARY_COLOR = '#2b3647';
const ERROR_COLOR = '#ff8484';
const BLACK_COLOR = '#2b3647';
const BLUE_GRADIENT = '#64a8ff';
const BLUE_PALE_COLOR = '#5A88FF';
const BLUE_LIGHT_COLOR = '#4588F5';
const BLUE_DARK_COLOR = '#5077F2';
const BLUE_400 = '#2957A3';
const GRAY_DARK_COLOR = '#838ca5';
const GRAY_LIGHT_TWO_COLOR = '#9FA6B9';
const GRAY_LIGHT_COLOR = '#cfd3de';
const GRAY_LIGHT_OPACITY_COLOR = '#cfd3de';
const LIGHT_GRAY_COLOR = '#F7F9FC';
const WHITE_DARK_COLOR = '#F2F6FA';
const GREEN_COLOR = '#3EB77D';
const RED_COLOR = '#FF6969';
const YELLOW_COLOR = '#FFEA93';
const YELLOW_DARK_COLOR = '#e8c329';
const GRAY_50 = '#D6D6D6';
const RXRISE_BLUE_400 = '#7590F5';

export type Colors =
  | typeof PRIMARY_COLOR
  | typeof ERROR_COLOR
  | typeof BLACK_COLOR
  | typeof GRAY_LIGHT_COLOR
  | typeof WHITE_DARK_COLOR
  | typeof GRAY_DARK_COLOR
  | typeof BLUE_LIGHT_COLOR
  | typeof BLUE_DARK_COLOR
  | typeof GREEN_COLOR
  | typeof RED_COLOR
  | typeof YELLOW_COLOR
  | typeof YELLOW_DARK_COLOR
  | typeof BLUE_GRADIENT
  | typeof LIGHT_GRAY_COLOR
  | typeof GRAY_LIGHT_OPACITY_COLOR
  | typeof BLUE_PALE_COLOR
  | typeof GRAY_LIGHT_TWO_COLOR
  | typeof RXRISE_BLUE_400;

export {
  PRIMARY_COLOR,
  ERROR_COLOR,
  BLACK_COLOR,
  GRAY_LIGHT_COLOR,
  GRAY_DARK_COLOR,
  WHITE_DARK_COLOR,
  BLUE_LIGHT_COLOR,
  BLUE_DARK_COLOR,
  GREEN_COLOR,
  RED_COLOR,
  BLUE_GRADIENT,
  LIGHT_GRAY_COLOR,
  GRAY_LIGHT_OPACITY_COLOR,
  GRAY_LIGHT_TWO_COLOR,
  BLUE_PALE_COLOR,
  YELLOW_COLOR,
  YELLOW_DARK_COLOR,
  GRAY_50,
  BLUE_400,
  RXRISE_BLUE_400,
};
