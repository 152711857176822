import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

type IManufacturerTitleProps = {
  pharmacyName: string;
  createdAt: string;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextDate = styled.span`
  font-size: ${({ theme }) => theme.sizes.small};
  font-family: ${({ theme }) => theme.fonts.robotoRegular};
  color: ${({ theme }) => theme.colors.black[100]};
`;

export const ManufacturerTitle: React.FC<IManufacturerTitleProps> = ({
  createdAt,
  pharmacyName,
}) => {
  return (
    <Container>
      <Typography variant="h5" color="primary">
        {pharmacyName}
      </Typography>
      <TextDate> Since: Approved date {createdAt}</TextDate>
    </Container>
  );
};
