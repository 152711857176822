import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type VerificationResendProps = {
  className?: string;
};

const Text = styled.p`
  font-size: 16px;
  line-height: 19px;

  font-family: ${({ theme }) => theme.fonts.robotoLight};
  color: ${({ theme }) => theme.colors.black[100]};
`;

const SubText = styled(Link)`
  color: ${({ theme }) => theme.colors.blue[200]};
  cursor: pointer;
  text-decoration: none;
`;

const VerificationResend = ({
  ...props
}: VerificationResendProps): JSX.Element => {
  return (
    <Text {...props}>
      Didn’t receive code? <SubText to="/login">Resend code</SubText>
    </Text>
  );
};

VerificationResend.defaultProps = {
  className: '',
};

export { VerificationResend };
