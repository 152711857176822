import { useMutation } from 'react-query';

import { IDeletePaymentMethodsCardsDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDeletePharmacyPaymentMethod = (
  pharmacyId: number,
  paymentMethodId: number,
  type: string
) => {
  return useMutation(
    ['delete-pharmacy-payment-method', pharmacyId, paymentMethodId, type],
    () => {
      const resource = type === 'card' ? 'cards' : 'ach';

      return http.delete(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/pharmacist/pharmacies/${pharmacyId}/payment-methods/${resource}/${paymentMethodId}`
      );
    }
  );
};
