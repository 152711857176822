import React from 'react';
import styled from 'styled-components';

export type CardProps = {
  children: React.ReactNode;
};

const Container = styled.div`
  background: #ffffff;
  box-shadow: 5px 5px 25px rgba(51, 60, 68, 0.08);
  border-radius: 10px;
`;

const Card = ({ children, ...props }: CardProps): JSX.Element => {
  return <Container {...props}>{children}</Container>;
};

export { Card };
