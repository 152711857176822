import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Grid, TextField } from '@material-ui/core';

import { useUpdatePharmacyById } from 'rx-api';
import { useToast } from 'rx-hooks';
import { GetPharmacyById } from 'rx-domain';

import { Title } from '../styles/index.styles';

type IBuyerPharmacyInfoProps = {
  buyerData: GetPharmacyById.ResponsePayloadDto;
};

type IBuyerPharmacyInfoForm = {
  pharmacyName: string;
  type: number;
  primaryWholesaler: string;
  account1: string;
  secondaryWholesaler: string;
  account2: string;
  dbaName: string;
  dea: string;
  stateLicense: string;
  faxNumber: string;
  npi: string;
  yearsInBusiness: number;
  taxId: string;
  phoneNumber: string;
};

export const PharmacyProfileInfo: React.FC<IBuyerPharmacyInfoProps> = (
  props
) => {
  const { onSuccessToast, onErrorToast } = useToast();
  const updatePharmacy = useUpdatePharmacyById(props.buyerData.id);

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      pharmacyName: Yup.string().required('Pharmacy Name is Required'),
      dbaName: Yup.string().default('').nullable(true),
      primaryWholesaler: Yup.string().default('').nullable(true),
      secondaryWholesaler: Yup.string().default('').nullable(true),
      account1: Yup.string().default('').nullable(true),
      account2: Yup.string().default('').nullable(true),
      faxNumber: Yup.string().default('').nullable(true),
      dea: Yup.string().default('').nullable(true),
      stateLicense: Yup.string().default('').nullable(true),
      yearsInBusiness: Yup.number().default(1).nullable(true),
      taxId: Yup.string().nullable(true).default(''),
    }),
    initialValues: {
      pharmacyName: props.buyerData.pharmacyName,
      type: props.buyerData.type,
      primaryWholesaler: props.buyerData.primaryWholesaler,
      account1: props.buyerData.account1,
      secondaryWholesaler: props.buyerData.secondaryWholesaler,
      account2: props.buyerData.account2,
      dbaName: props.buyerData.dbaName,
      dea: props.buyerData.dea,
      stateLicense: props.buyerData.stateLicense,
      faxNumber: props.buyerData.faxNumber,
      npi: props.buyerData.npi,
      yearsInBusiness: props.buyerData.yearsInBusiness,
      taxId: props.buyerData.taxId,
      phoneNumber: props.buyerData.phoneNumber,
    },
    enableReinitialize: true,
    onSubmit: async (values: IBuyerPharmacyInfoForm) => {
      try {
        await updatePharmacy.mutateAsync(values);

        onSuccessToast('Changes saved succesfully 🚀');
      } catch (err) {
        onErrorToast('Could not save data');
      }
    },
  });

  return (
    <section>
      <Title>Pharmacy information</Title>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={4}>
            <TextField
              id="pharmacyName"
              name="pharmacyName"
              label="Pharmacy Name"
              value={formik.values.pharmacyName}
              onChange={formik.handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="dbaName"
              name="dbaName"
              label="Doing Business As"
              value={formik.values.dbaName}
              onChange={formik.handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              required
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={4}>
            <TextField
              id="primaryWholesaler"
              name="primaryWholesaler"
              label="Primary Wholesaler"
              value={formik.values.primaryWholesaler}
              onChange={formik.handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="secondaryWholesaler"
              name="secondaryWholesaler"
              label="Secondary Wholesaler"
              value={formik.values.secondaryWholesaler}
              onChange={formik.handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={4}>
            <TextField
              id="account1"
              name="account1"
              label="Account 1"
              value={formik.values.account1}
              onChange={formik.handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="account2"
              name="account2"
              label="Account 2"
              value={formik.values.account2}
              onChange={formik.handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={4}>
            <TextField
              id="faxNumber"
              name="faxNumber"
              label="Fax Number"
              value={formik.values.faxNumber}
              onChange={formik.handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="npi"
              name="npi"
              label="NPI"
              value={formik.values.npi}
              onChange={formik.handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={4}>
            <TextField
              id="yearsInBusiness"
              name="yearsInBusiness"
              label="Years In Business"
              value={formik.values.yearsInBusiness}
              onChange={formik.handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="taxId"
              name="taxId"
              label="Tax ID #"
              value={formik.values.taxId}
              onChange={formik.handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={4}>
            <TextField
              id="dea"
              name="dea"
              label="DEA Number"
              value={formik.values.dea}
              onChange={formik.handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="stateLicense"
              name="stateLicense"
              label="State License Number"
              value={formik.values.stateLicense}
              onChange={formik.handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={4}>
            <TextField
              id="phoneNumber"
              name="phoneNumber"
              label="Pharmacy Phone Number"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!formik.isValid || formik.isSubmitting}
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      </form>
    </section>
  );
};
