import React from 'react';
import styled, { css } from 'styled-components';

import { useAuth } from 'rx-hooks';

import { SearchHeader } from '../../search/molecules/SearchHeader';
import { HeaderLogo } from '../atoms/HeaderLogo';
import {
  HeaderActions,
  HeaderAdminActions,
  HeaderManufacturerActions,
} from '../molecules';

const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 1.75rem;
    height: 4.375rem;

    display: flex;
    flex-direction: row;
    align-items: center;

    box-shadow: 5px 5px 25px rgba(51, 60, 68, 0.08);

    ${theme.lessThan('ipadPro')} {
      padding: 0 0 0 1.75rem;
    }
  `}
`;

const Spacer = styled.div`
  flex: 1;
`;

const Header = (): JSX.Element => {
  const { type } = useAuth();

  return (
    <Container>
      <HeaderLogo />
      {type === 'pharmacist' && <SearchHeader />}
      {type === 'manufacturer' && <HeaderManufacturerActions />}
      {type === 'admin' && <HeaderAdminActions />}
      <Spacer />
      <HeaderActions />
    </Container>
  );
};

export { Header };
