import React, { useEffect, useMemo } from 'react';
import { Formik, Form } from 'formik';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';

import { InventoryType, PutInventoryItem, SearchQueryParams } from 'rx-domain';
import { useDeleteProductFromInventory, useGetInventory } from 'rx-api';
import { useDrugsInventory, useToast } from 'rx-hooks';
import { getDateValue } from 'rx-utils';

import {
  BulkDrugsArrayValidator,
  BulkProductsArrayValidator,
} from 'validations';

import { InventoryItem } from '../InventoryItem';
import { InventoryEmpty } from '../InventoryEmpty';
import { InventoryItemRemoveDialog } from '../InventoryItemRemoveDialog';
import { InventoryTableLoader } from '../InventoryTableLoader';
import { Pagination } from './index.styles';

type IProps = {
  queries: SearchQueryParams;
};

type IForm = {
  items: PutInventoryItem.DTO[];
};

export const InventoryTable = (props: IProps): JSX.Element => {
  const { skip, totalPages, setLength } = useDrugsInventory();
  const {
    data: inventoryRes,
    isLoading,
    refetch,
  } = useGetInventory({
    skip,
    type: (props.queries.type as InventoryType) ?? 'drug',
  });

  const initialValues = useMemo(() => {
    return (
      !inventoryRes?.data
        ? { items: [] }
        : {
            items: inventoryRes.data.results.map((item) => ({
              ndc: item.ndc,
              name: item.name,
              description: item.description,
              manufacturer: item.manufacturer,
              expirationDate: getDateValue(item.expirationDate),
              price: item.price,
              units: item.units,
              lotNumber: item.lotNumber,
              minimumQuantity: item.minimumQuantity,
              shippingName: item.shippingName,
              shippingConstraints: item.shippingConstraints,
              groundShipping: item.groundShipping.toString(),
              productId: item.productId,
              type: item.type,
              category: item.category,
            })),
          }
    ) as IForm;
  }, [inventoryRes]);

  useEffect(() => {
    if (inventoryRes?.data && !isLoading) {
      setLength(inventoryRes.data.pagination.total);
    }
  }, [inventoryRes, isLoading, setLength]);

  const toast = useToast();
  const [isDeleteModalOpen, setDeleteIsModalOpen] = React.useState(false);
  const [productIdToRemove, setProductIdToRemove] = React.useState<
    number | null
  >(null);
  const deleteProduct = useDeleteProductFromInventory();

  const handleRemove = async () => {
    try {
      await deleteProduct.mutateAsync(
        productIdToRemove === null ? '' : `${productIdToRemove}`
      );
      toast.onSuccessToast('Product removed!');
      refetch();
      setDeleteIsModalOpen(false);
      setProductIdToRemove(null);
    } catch (err) {
      toast.onErrorToast('Could not remove product');
    }
  };

  const handleCloseDialog = () => {
    setDeleteIsModalOpen(false);
    setProductIdToRemove(null);
  };

  const handleClickRemove = (id: number) => () => {
    setProductIdToRemove(id);
    setDeleteIsModalOpen(true);
  };
  const isInventoryEmpty =
    inventoryRes?.data && inventoryRes.data.results.length === 0;

  return (
    <div>
      <InventoryItemRemoveDialog
        isOpen={isDeleteModalOpen}
        onClickCancel={handleCloseDialog}
        onClickRemove={handleRemove}
      />

      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={
          props.queries.type === 'drugs'
            ? BulkDrugsArrayValidator
            : BulkProductsArrayValidator
        }
        enableReinitialize
      >
        <Form>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Picture</TableCell>
                <TableCell>NDC</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Exp. Date</TableCell>
                <TableCell>Lot Number</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && <InventoryTableLoader />}

              {!isLoading && isInventoryEmpty && (
                <InventoryEmpty type={props.queries.type as InventoryType} />
              )}

              {!isLoading &&
                inventoryRes?.data &&
                inventoryRes.data.results.map((item) => (
                  <InventoryItem
                    key={item.id}
                    data={item}
                    onClickRemove={handleClickRemove}
                  />
                ))}
            </TableBody>
          </Table>
        </Form>
      </Formik>
      <Pagination queries={props.queries} totalPages={totalPages} />
    </div>
  );
};
