import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.sizes.small};
    margin-bottom: 1rem;
  `}
`;

export const LinkHome = styled(Link)`
  text-decoration: none;
  color: black;
`;
