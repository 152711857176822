import { BUYER_URL_V1 } from '../constants';

export const GET_PHARMACY_DATA_URL = `${BUYER_URL_V1}/pharmacy`;
export const GET_PHARMACY_FILES_URL = `${BUYER_URL_V1}/pharmacy-files`;
export const GET_PHARMACY_FILES_KEY = 'pharmacist-get-pharmacy-files';

export const GET_PHARMACIST_PHARMACY_BY_ID_URL = (
  id: number | string
): string => `${BUYER_URL_V1}/pharmacies/${id}`;

export const PUT_PHARMACY_DATA_URL = (id: number | string): string =>
  `${BUYER_URL_V1}/pharmacies/${id}`;
