import React from 'react';
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { useToast } from 'rx-hooks';
import { US_STATES_WITH_CODES, pharmacyTypeList } from 'rx-utils';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { http } from 'rx-api';
import { AddPharmacist } from 'rx-domain';
import axios from 'axios';

interface IForm {
  email: string;
  firstName: string;
  password: string;
  lastName: string;
  pharmacyName: string;
  type: string;
  billingName: string;
  billingAddress: string;
  billingAddressCity: string;
  billingAddressState: string;
  billingAddressZipCode: string;
  stateLicense: File | undefined;
  dea: File | undefined;
}

interface IProps {
  onClickCancel(): void;
  onAddPharmacy(pharmacy: AddPharmacist.ResponsePayloadDto): void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAdminCreatePharmacist = () => {
  return useMutation(
    'admin-create-pharmacist',
    (data: AddPharmacist.RequestPayloadDto) => {
      return http.post(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/pharmacists`,
        data
      );
    },
    { retry: false }
  );
};

export const NewPharmacistForm: React.FC<IProps> = (props) => {
  const toast = useToast();
  const adminCreatePharmacist = useAdminCreatePharmacist();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      pharmacyName: '',
      type: '1',
      billingName: '',
      billingAddress: '',
      billingAddressCity: '',
      billingAddressState: 'AK',
      billingAddressZipCode: '',
      dea: undefined,
      stateLicense: undefined,
    },
    onSubmit: async (values: IForm) => {
      if (!values.dea) return;
      if (!values.stateLicense) return;

      try {
        const formData: FormData = new FormData();
        const type = parseInt(values.type, 10);

        formData.append('firstName', values.firstName);
        formData.append('lastName', values.lastName);
        formData.append('email', values.email);
        formData.append('password', values.password);

        formData.append('pharmacyName', values.pharmacyName);
        formData.append('pharmacyType', type.toString());

        formData.append('dea', values.dea, 'dea');
        formData.append('deaTypeFile', values.dea.type);

        formData.append('stateLicense', values.stateLicense, 'stateLicense');
        formData.append('stateTypeFile', values.stateLicense.type);

        formData.append('billingAddress', values.billingAddress);
        formData.append('billingCity', values.billingAddressCity);
        formData.append('billingState', values.billingAddressState);
        formData.append('billingZipCode', values.billingAddressZipCode);
        formData.append('billingName', values.billingName);

        const result = await adminCreatePharmacist.mutateAsync(formData);
        toast.onSuccessToast('Pharmacist added successfully!');
        props.onAddPharmacy(result.data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          toast.onErrorToast(`Error: ${err.response?.data?.message}`);
          return;
        }

        toast.onErrorToast('Error: could not add pharmacist');
      }
    },
  });

  const handleChangeDea = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files;
    if (file && file.length > 0) {
      reader.readAsDataURL(file[0]);
      formik.setFieldValue('dea', file[0]);
    } else {
      formik.setFieldValue('dea', null);
    }
  };

  const handleChangeStateLicense = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files;

    if (file && file.length > 0) {
      reader.readAsDataURL(file[0]);
      formik.setFieldValue('stateLicense', file[0]);
    } else {
      formik.setFieldValue('stateLicense', null);
    }
  };

  return (
    <>
      <Typography variant="h5" style={{ marginBottom: '1rem' }}>
        Register New Pharmacist
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h6" style={{ marginBottom: '1rem' }}>
          Buyer Info
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              name="firstName"
              label="First Name"
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              variant="outlined"
              name="lastName"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              variant="outlined"
              name="email"
              type="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              variant="outlined"
              name="password"
              type="password"
              label="Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          style={{ marginTop: '1rem', marginBottom: '1rem' }}
        >
          Pharmacy Info
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              name="pharmacyName"
              label="Pharmacy Name"
              value={formik.values.pharmacyName}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              name="type"
              label="Type of Business"
              variant="outlined"
              value={formik.values.type}
              onChange={formik.handleChange}
              fullWidth
              required
            >
              {pharmacyTypeList.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          style={{ marginBottom: '0rem', fontSize: '1.2rem' }}
        >
          Pharmacy Documentation
        </Typography>
        <p style={{ marginBottom: '0.7rem', fontFamily: 'Roboto Light' }}>
          Please upload a jpg, png, or PDF file to validate your DEA and State
          License. The limit size of the file is 40MB.
        </p>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              onChange={handleChangeDea}
              label="DEA File(PDF/JPG/PNG)"
              id="dea"
              name="dea"
              type="file"
              required
              variant="outlined"
              inputProps={{
                accept:
                  'application/pdf,application/vnd.ms-excel,image/png,image/jpeg',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              onChange={handleChangeStateLicense}
              label="State License File(PDF/JPG/PNG)"
              id="stateLicense"
              name="stateLicense"
              type="file"
              required
              variant="outlined"
              inputProps={{
                accept:
                  'application/pdf,application/vnd.ms-excel,image/png,image/jpeg',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Typography variant="h6" style={{ marginTop: '1rem' }}>
          Billing Info
        </Typography>

        <Typography variant="subtitle1" style={{ marginBottom: '1rem' }}>
          Billing info is going to be used as shipping info
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              name="billingName"
              label="Billing Name"
              value={formik.values.billingName}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              variant="outlined"
              name="billingAddress"
              label="Billing Address"
              value={formik.values.billingAddress}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              variant="outlined"
              name="billingAddressCity"
              label="City"
              value={formik.values.billingAddressCity}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              name="billingAddressState"
              label="State"
              variant="outlined"
              value={formik.values.billingAddressState}
              onChange={formik.handleChange}
              fullWidth
              required
            >
              {US_STATES_WITH_CODES.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              variant="outlined"
              name="billingAddressZipCode"
              label="Zip Code"
              value={formik.values.billingAddressZipCode}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: '0.75rem' }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={adminCreatePharmacist.isLoading}
            >
              Register
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              onClick={props.onClickCancel}
              disabled={adminCreatePharmacist.isLoading}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
