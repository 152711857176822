import { useMutation } from 'react-query';

import { IPutAdminManufacturerIdFeesDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePutAdminManufacturerIdFees = () => {
  const onRequest = async ({
    id,
    ...request
  }: IPutAdminManufacturerIdFeesDTO) => {
    return http.put(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/manufacturers/${id}/fees`,
      request
    );
  };

  return useMutation(onRequest, {});
};

export { usePutAdminManufacturerIdFees };
