import { useRxMutation } from 'rx-api/utils';

export const useAddInventoryProductPricingTier = (
  productId: number | string
) => {
  return useRxMutation<
    {
      price: string;
      logicalOperator: string;
      minAmount: number | null;
      maxAmount: number | null;
    },
    unknown
  >({
    method: 'post',
    url: `${process.env.REACT_APP_WEBSITE_URL}/v1/manufacturer/inventory/products/${productId}/pricing-tiers`,
  });
};
