import React from 'react';
import { useField } from 'formik';
import MaskedInput from 'react-text-mask';
import { Button, Grid, Typography } from '@material-ui/core';

import { GetProductById } from 'rx-domain';
import { buildNumberMask, formatMoney } from 'rx-utils';
import { theme } from 'rx-styles';

import { Container, MoneyText, Input } from './index.styles';

type IProps = {
  product: GetProductById.Response;
};

export const ProductAddShopCart: React.FC<IProps> = (props) => {
  const [field, meta] = useField<number>('units');

  const formikValidation = meta.touched && !!meta.error === true;
  const maxOrderIsExceeded = Boolean(
    props.product.maximumQuantity && field.value > props.product.maximumQuantity
  );
  const checkoutValidation =
    field.value >= props.product.minimumQuantity &&
    field.value <= props.product.units &&
    !maxOrderIsExceeded;

  const total = field.value * parseFloat(props.product.price);
  const isOnPricingTier =
    field.value !== 0 &&
    props.product.pricingTiers.reduce((isOnTier: boolean, tier) => {
      if (isOnTier) {
        return true;
      }

      if (tier.logicalOperator === 'less_than') {
        return field.value <= tier.maxAmount;
      }

      if (tier.logicalOperator === 'more_than') {
        return field.value >= tier.minAmount;
      }

      if (tier.logicalOperator === 'between') {
        return field.value >= tier.minAmount && field.value <= tier.maxAmount;
      }

      return false;
    }, false);

  const newPrice = props.product.pricingTiers.reduce((price: string, tier) => {
    if (tier.logicalOperator === 'less_than') {
      return field.value <= tier.maxAmount ? tier.price : price;
    }

    if (tier.logicalOperator === 'more_than') {
      return field.value >= tier.minAmount ? tier.price : price;
    }

    if (tier.logicalOperator === 'between') {
      return field.value >= tier.minAmount && field.value <= tier.maxAmount
        ? tier.price
        : price;
    }

    return price;
  }, props.product.price);

  const newTotal = parseFloat(newPrice) * field.value;

  return (
    <Container>
      <Typography
        style={{
          marginBottom: isOnPricingTier ? '0' : '0.75rem',
          textDecoration: isOnPricingTier ? 'line-through' : '',
        }}
      >
        <strong>Price:</strong>{' '}
        {isOnPricingTier ? (
          <span>{formatMoney(props.product.price)}</span>
        ) : (
          <MoneyText>{formatMoney(props.product.price)}</MoneyText>
        )}
      </Typography>

      {isOnPricingTier && (
        <Typography
          style={{
            marginBottom: '0.75rem',
          }}
        >
          <strong>New Price:</strong>{' '}
          <MoneyText>{formatMoney(newPrice)}</MoneyText>
        </Typography>
      )}

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography>Quantity:</Typography>
          <MaskedInput
            mask={buildNumberMask(9999999)}
            guide={false}
            {...field}
            render={(
              ref: (inputElement: HTMLInputElement) => void,
              maskProps
            ) => {
              return (
                <Input
                  ref={ref}
                  {...maskProps}
                  $error={
                    formikValidation || (meta.touched && !checkoutValidation)
                  }
                  type="text"
                />
              );
            }}
          />
          <Button
            disabled={!checkoutValidation}
            type="submit"
            variant="contained"
            fullWidth
            style={{
              backgroundColor: theme.colors.black[100],
              color: theme.colors.yellow[100],
              fontWeight: 'bold',
              margin: '1rem 0',
            }}
          >
            Add to cart
          </Button>
        </Grid>
      </Grid>

      <Typography
        style={{
          textDecoration: isOnPricingTier ? 'line-through' : '',
        }}
      >
        <strong>Total: </strong>
        {isOnPricingTier ? (
          <span>{formatMoney(total)}</span>
        ) : (
          <MoneyText>{formatMoney(total)}</MoneyText>
        )}
      </Typography>

      {isOnPricingTier && (
        <Typography>
          <strong>New Total: </strong>
          <MoneyText>{formatMoney(newTotal)}</MoneyText>
        </Typography>
      )}
    </Container>
  );
};
