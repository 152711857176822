import styled, { css } from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const SimpleButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border: none;
    color: white;
    background-color: ${theme.colors.blue[100]};

    padding: 0.5rem 1.5rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    font-size: ${theme.sizes.small};
    font-family: ${theme.fonts.robotoRegular};
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    cursor: pointer;
  `}
`;

export const Button = styled(SimpleButton)<{
  $loading?: boolean;
  variant?: 'outline' | 'normal' | 'ghost';
}>`
  ${({ theme, $loading, variant }) => css`
    min-width: 7.75rem;

    ${variant === 'outline' &&
    css`
      border: 1px solid ${theme.colors.blue[100]};
      color: ${theme.colors.blue[100]};
      background-color: transparent;

      ${$loading &&
      css`
        background-color: transparent;
      `}

      &:disabled {
        background-color: transparent;
        border: 1px solid rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
      }
    `}

    ${variant === 'normal' &&
    css`
      border: none;
      color: white;
      background-color: ${theme.colors.blue[100]};

      ${$loading &&
      css`
        background-color: rgba(0, 0, 0, 0.12);
      `}

      &:disabled {
        background-color: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
      }
    `}

    ${variant === 'ghost' &&
    css`
      border: none;
      color: ${theme.colors.gray[400]};
      background-color: transparent;

      ${$loading &&
      css`
        background-color: rgba(0, 0, 0, 0.12);
      `}

      &:disabled {
        background-color: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
      }
    `}
  `}
`;

export const Loading = styled(CircularProgress)<{
  type?: 'outline' | 'normal' | 'ghost';
}>`
  &.MuiCircularProgress-colorPrimary {
    ${({ theme, type }) => css`
      ${type === 'outline' &&
      css`
        color: ${theme.colors.blue[100]};
      `}

      ${type === 'normal' &&
      css`
        color: white;
      `}
    `}
  }
`;
