import React from 'react';
import { Paper, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { SEO, TemplateContent, TemplateView } from 'rx-core';
import { useAdminGetAccountList } from 'rx-api';
import { Account } from 'domain/entities/Account';
import { Link } from 'react-router-dom';
import { AccountAdminList } from './AccountAdminList';
import { AccountRegularList } from './AccountRegularList';

export const AccountListView: React.FC = () => {
  const [value, setValue] = React.useState(0);
  const [email, setEmail] = React.useState('');
  const response = useAdminGetAccountList(
    Account.AccountTypes.Admin,
    email,
    value === 1
  );

  const handleTabChange = (
    _event: React.ChangeEvent<any>,
    newValue: number
  ) => {
    setValue(newValue);
    setEmail('');
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <TemplateView>
      <SEO title="RxRise | Ad List" />
      <TemplateContent>
        <Paper
          variant="outlined"
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Tabs value={value} onChange={handleTabChange}>
            <Tab label="Admin List" />
            <Tab label="Regular User List" />
          </Tabs>

          <Link to="/accounts/new" style={{ textDecoration: 'none' }}>
            <Typography color="primary" style={{ marginRight: '1rem' }}>
              CREATE ACCOUNT
            </Typography>
          </Link>
        </Paper>

        <Paper
          style={{ margin: '1rem 0 0 0', padding: '1rem' }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            name="emailSearch"
            placeholder="Search by email"
            type="search"
            margin="dense"
            value={email}
            onChange={handleEmailChange}
            fullWidth
          />
        </Paper>

        {value === 0 && (
          <AccountAdminList
            isLoading={response.isLoading}
            data={response?.data?.data || []}
          />
        )}

        {value === 1 && (
          <AccountRegularList
            isLoading={response.isLoading}
            data={response?.data?.data || []}
          />
        )}
      </TemplateContent>
    </TemplateView>
  );
};
