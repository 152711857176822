import React, { ButtonHTMLAttributes } from 'react';
import { layout, LayoutProps } from 'styled-system';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import { theme, Colors } from 'rx-styles';

import { ButtonLoadingIcon } from '../ButtonLoadingIcon/ButtonLoadingIcon';

export type RxButtonPrimaryProps = ButtonHTMLAttributes<HTMLButtonElement> &
  LayoutProps & {
    text: string;
    loading: boolean;
    color?: Colors;
    textColor?: Colors;
  };

const LoadingContainer = styled(motion.figure)`
  height: fit-content;
  width: fit-content;
`;

const Button = styled.button<LayoutProps>`
  ${({ theme: { colors, sizes, fonts } }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 0.5rem;
    font-size: ${sizes.small};
    font-family: ${fonts.robotoRegular};
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    cursor: pointer;

    width: 7.75rem;
    height: 2.625rem;

    border: none;
    color: white;
    padding: 0.5rem 1.5rem;
    background-color: ${colors.blue[100]};

    &:disabled {
      background-color: ${colors.gray[400]};
    }

    ${layout};
  `}
`;

const RxButtonPrimary = ({
  text,
  color,
  loading,
  onClick,
  textColor,
  ...props
}: RxButtonPrimaryProps): JSX.Element => {
  return (
    <Button {...props} disabled={props.disabled || loading} onClick={onClick}>
      {!loading && text}
      <AnimatePresence>
        {loading && (
          <LoadingContainer
            animate={{ rotate: 360 }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            <ButtonLoadingIcon />
          </LoadingContainer>
        )}
      </AnimatePresence>
    </Button>
  );
};

RxButtonPrimary.defaultProps = {
  color: theme.colors.blue.gradient,
  textColor: theme.colors.white[100],
  type: 'submit',
  disabled: undefined,
  loading: false,
};

export { RxButtonPrimary };
