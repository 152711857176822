import { DeleteAdminPaymentMethod } from 'rx-domain';
import { DELETE_ADMIN_CARD } from 'rx-api/api/admin/pharmacies/constants';
import { createUrlWithParams, useRxMutation } from 'rx-api/utils';

const useDeleteAdminCard = (params: DeleteAdminPaymentMethod.Params) => {
  return useRxMutation<unknown, unknown>({
    url: createUrlWithParams(DELETE_ADMIN_CARD, [
      {
        key: 'id',
        value: params.id,
      },
      {
        key: 'cardId',
        value: params.paymentId,
      },
    ]),
    method: 'delete',
  });
};

export { useDeleteAdminCard };
