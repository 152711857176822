import React from 'react';

import { useAdminProducts } from 'rx-contexts';

import { useDeleteAdminBannedProductsId } from 'rx-api';

import { useToast } from 'rx-hooks';
import { useQueryClient } from 'react-query';
import { AdminProductsDeleteModal } from './AdminProductsDeleteModal';

export const AdminProductsDeleteBlackItem: React.FC = () => {
  const { onSuccessToast, onErrorToast } = useToast();
  const queryClient = useQueryClient();
  const { modalDelete, onModalDelete, deleteBlackItem } = useAdminProducts();

  const adminDeleteBlackItem = useDeleteAdminBannedProductsId();

  const onDeleteBlackItem = async () => {
    try {
      if (!deleteBlackItem) return;

      await adminDeleteBlackItem.mutateAsync({
        id: deleteBlackItem.id.toString(),
      });

      await queryClient.refetchQueries('get-admin-black-list', {
        active: true,
      });

      onModalDelete();
      onSuccessToast('Banned Product Deleted 🗑️');
    } catch (error) {
      onErrorToast('Could not delete product 🗑️');
    }
  };

  return (
    <AdminProductsDeleteModal
      visible={modalDelete}
      onClose={() => onModalDelete()}
      loading={adminDeleteBlackItem.isLoading}
      onDeleteProduct={onDeleteBlackItem}
    />
  );
};
