import styled, { css } from 'styled-components';
import { IconButton } from '@material-ui/core';

export const MenuIcon = styled(IconButton)`
  ${({ theme }) => css`
    cursor: pointer;

    &.MuiSvgIcon-root {
      fill: ${theme.colors.blue[400]};
    }
  `}
`;
