import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { useAuth, useSyncShoppingCart } from 'rx-hooks';

import {
  ShopCartView,
  CheckoutView,
  FavoritesView,
  OrdersView,
  OrderDetailsView,
  ProductView,
  CheckoutSuccessView,
  SettingsProfileView,
  SettingsSecurityView,
  CheckoutSummaryView,
  ProductsView,
  SettingsPharmacyByIdView,
  SettingsPharmacyListView,
  SettingsNewPharmacyView,
} from 'pages/buyer';
import {
  ManageOrdersView,
  PurchaseOrderRequestView,
  AddProductView,
  SettingsProfileSellerView,
  SettingsSecuritySellerView,
  SettingsShippingSellerView,
} from 'pages/seller';
import { SellerEditProductView } from 'pages/seller/products/SellerEditProductView';
import { SellerAddProductView } from 'pages/seller/products/SellerAddProductView';
import {
  AccountListView,
  AdminAccountView,
  AdminAddBannedProductView,
  AdminAdListView,
  AdminEditBannedProductView,
  AdminNewAccountView,
  AdminProducts,
  ApproveView,
  BuyerListView,
  ManufacturerView,
  PharmacyProfileView,
  ReportsView,
  SellerListView,
  TransactionViewV2,
  CategoriesView,
  AdminAddIndividualProductView,
  SystemLogsView,
} from 'pages/admin';
import {
  Login,
  DashboardView,
  ResetPasswordView,
  ForgotPasswordView,
  TermsAndConditionsView,
  SignupSeller,
  NotFoundView,
  SignupBuyer,
  PictureProductView,
} from 'pages/general';

import { AdminEditPurchaseOrder } from 'pages/admin/purchaseOrders/EditPurchaseOrder';
import { PurchaseOrderList } from 'pages/admin/purchaseOrders/PurchaseOrderList';
import { NewPurchaseOrder } from 'pages/admin/purchaseOrders/NewPurchaseOrder';
import { SellerEditPurchaseOrder } from 'pages/seller/SellerEditPurchaseOrder';
import { NewPharmacistView } from 'pages/admin/NewPharmacist';
import { NewSellerView } from 'pages/admin/sellers/NewSeller';
import { GuardedRoute } from './GuardedRoute';
import { RedictectDashboard } from './RedirectLogin';

const RouteProvider: React.FC = () => {
  const { type } = useAuth();

  useSyncShoppingCart();

  return (
    <Router>
      <Switch>
        <GuardedRoute exact path="/" component={DashboardView} />
        <RedictectDashboard path="/login" component={Login} />
        <RedictectDashboard
          path="/forgot-password"
          component={ForgotPasswordView}
        />
        <RedictectDashboard
          path="/reset-password"
          component={ResetPasswordView}
        />
        <RedictectDashboard path="/signup" component={SignupBuyer} exact />
        <RedictectDashboard
          path="/signup/manufacturer"
          component={SignupSeller}
          exact
        />
        <Route
          path="/legal/end-user-agreement"
          component={TermsAndConditionsView}
          exact
        />

        {type === 'pharmacist' && (
          <Switch>
            <GuardedRoute path="/products" component={ProductsView} />
            <GuardedRoute path="/shopping-cart" component={ShopCartView} />
            <GuardedRoute path="/checkout" component={CheckoutView} exact />
            <GuardedRoute
              path="/checkout/success"
              component={CheckoutSuccessView}
            />
            <GuardedRoute
              path="/checkout/summary/:orderId"
              component={CheckoutSummaryView}
            />
            <GuardedRoute
              path="/settings/profile"
              component={SettingsProfileView}
            />

            <GuardedRoute
              exact
              path="/settings/pharmacies"
              component={SettingsPharmacyListView}
            />

            <GuardedRoute
              path="/settings/pharmacies/add"
              component={SettingsNewPharmacyView}
            />

            <GuardedRoute
              path="/settings/pharmacies/:id"
              component={SettingsPharmacyByIdView}
            />

            <GuardedRoute
              path="/settings/security"
              component={SettingsSecurityView}
            />
            <GuardedRoute path="/product/:id" component={ProductView} />

            <GuardedRoute path="/orders" exact={true} component={OrdersView} />
            <GuardedRoute path="/orders/:id" component={OrderDetailsView} />

            <GuardedRoute path="/favorites" component={FavoritesView} />
            <Route>
              <NotFoundView />
            </Route>
          </Switch>
        )}

        {type === 'manufacturer' && (
          <Switch>
            <GuardedRoute
              path="/seller/products/add"
              component={SellerAddProductView}
            />

            <GuardedRoute
              path="/seller/products/:id"
              component={SellerEditProductView}
            />

            <GuardedRoute path="/manage-orders" component={ManageOrdersView} />
            <GuardedRoute path="/add-product" component={AddProductView} />
            <GuardedRoute
              path="/order-selected/:id"
              component={PurchaseOrderRequestView}
              exact
            />
            <GuardedRoute
              path="/order-selected/:id/edit"
              component={SellerEditPurchaseOrder}
              exact
            />
            <GuardedRoute
              path="/settings/profile"
              exact
              component={SettingsProfileSellerView}
            />
            <GuardedRoute
              path="/settings/security"
              exact
              component={SettingsSecuritySellerView}
            />
            <GuardedRoute
              path="/settings/shipping"
              exact
              component={SettingsShippingSellerView}
            />
            <Route>
              <NotFoundView />
            </Route>
          </Switch>
        )}

        {type === 'admin' && (
          <Switch>
            <GuardedRoute path="/approve" component={ApproveView} />
            <GuardedRoute path="/system-logs" component={SystemLogsView} />
            <GuardedRoute
              path="/purchase-orders"
              component={PurchaseOrderList}
              exact
            />

            <GuardedRoute
              path="/purchase-orders/new"
              component={NewPurchaseOrder}
              exact
            />

            <GuardedRoute
              path="/purchase-orders/:id"
              component={AdminEditPurchaseOrder}
              exact
            />

            <GuardedRoute
              path="/transactions"
              component={TransactionViewV2}
              exact
            />

            <GuardedRoute path="/accounts" component={AccountListView} exact />
            <GuardedRoute path="/products" component={AdminProducts} exact />
            <GuardedRoute
              path="/products/add"
              component={AdminAddIndividualProductView}
              exact
            />
            <GuardedRoute
              path="/products/:id"
              component={AddProductView}
              exact
            />
            <GuardedRoute
              path="/products/picture/:id"
              component={PictureProductView}
            />
            <GuardedRoute path="/reports" component={ReportsView} />
            <GuardedRoute path="/pharmacies" component={BuyerListView} exact />

            <GuardedRoute
              path="/pharmacies/new"
              component={NewPharmacistView}
              exact
            />

            <GuardedRoute
              path="/pharmacies/:id"
              component={PharmacyProfileView}
              exact
            />
            <GuardedRoute
              path="/manufacturers"
              component={SellerListView}
              exact
            />

            <GuardedRoute
              path="/manufacturers/new"
              component={NewSellerView}
              exact
            />

            <GuardedRoute
              path="/manufacturers/:id"
              component={ManufacturerView}
              exact
            />
            <GuardedRoute
              path="/accounts/new"
              component={AdminNewAccountView}
              exact
            />
            <GuardedRoute
              path="/accounts/:id"
              component={AdminAccountView}
              exact
            />
            <GuardedRoute
              path="/products/banned/add"
              component={AdminAddBannedProductView}
              exact
            />
            <GuardedRoute
              path="/products/banned/:id"
              component={AdminEditBannedProductView}
              exact
            />
            <GuardedRoute path="/ads" component={AdminAdListView} exact />
            <GuardedRoute path="/categories" component={CategoriesView} exact />
            <Route>
              <NotFoundView />
            </Route>
          </Switch>
        )}
        <Route>
          <NotFoundView />
        </Route>
      </Switch>
    </Router>
  );
};

export { RouteProvider };
