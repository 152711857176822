import React from 'react';
import { Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export const PharmacyProfileLoader: React.FC = () => (
  <Paper style={{ marginTop: '2rem', padding: '0 2rem' }}>
    <Skeleton height={100}></Skeleton>
    <Skeleton height={100}></Skeleton>
    <Skeleton height={100}></Skeleton>
    <Skeleton height={100}></Skeleton>
  </Paper>
);
