import React from 'react';
import styled from 'styled-components';

import PillFour from '../../../../assets/images/random/pill-four.png';

const Img = styled.img`
  width: 120px;
  height: 134px;

  position: absolute;
  top: 370px;
  left: calc(65vw - 60px);
  display: none;
`;

const FooterPillIconFour = (): JSX.Element => {
  return <Img src={PillFour} />;
};

export { FooterPillIconFour };
