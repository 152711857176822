import React from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { Button, Grid, TextField } from '@material-ui/core';

import { IGetManufacturerIdResponse } from 'rx-domain';
import { useToast } from 'rx-hooks';
import { usePutManufacturersId } from 'rx-api';

import { ManufacturerAccountSchema } from 'utils/validators/admin/manufacturer-account.validator';
import { useQueryClient } from 'react-query';

type IProps = IGetManufacturerIdResponse;

const Title = styled.h2`
  padding-bottom: 1.5rem;
`;

type IForm = {
  firstName: string;
  lastName: string;
  titleOrPosition: string;
  phoneNumber: string;
  email: string;
  secondaryEmail: string;
};

export const ManufacturerAccountForm: React.FC<IProps> = ({
  firstName,
  lastName,
  titleOrPosition,
  phoneNumber,
  email,
  secondaryEmail,
  ...manufacturer
}) => {
  const initialValues: IForm = {
    firstName,
    lastName,
    titleOrPosition,
    phoneNumber,
    email,
    secondaryEmail: secondaryEmail ?? '',
  };

  const updateAccount = usePutManufacturersId();
  const queryClient = useQueryClient();

  const { onSuccessToast, onErrorToast } = useToast();

  const formik = useFormik({
    initialValues,
    validationSchema: ManufacturerAccountSchema,
    enableReinitialize: true,
    onSubmit: async ({ ...values }: IForm) => {
      try {
        await updateAccount.mutateAsync({
          ...values,
          manufacturer: {
            companyName: manufacturer.manufacturer.companyName,
            email: manufacturer.manufacturer.email,
            phoneNumber: manufacturer.manufacturer.phoneNumber,
            isVawd: manufacturer.manufacturer.isVawd,
          },
          id: manufacturer.id,
        });

        await queryClient.refetchQueries('get-admin-manufacturer-id', {
          active: true,
        });

        onSuccessToast('Changes saved succesfully 🚀');
      } catch (err) {
        if (axios.isAxiosError(err)) {
          onErrorToast(`Error: ${err.response?.data.message}`);
        } else {
          onErrorToast('Could not save');
        }
      }
    },
  });

  return (
    <div>
      <Title>Account Information</Title>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              name="firstName"
              label="First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              variant="outlined"
              name="lastName"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              name="titleOrPosition"
              label="Title / company position"
              value={formik.values.titleOrPosition}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={6}>
            <InputMask
              mask="999 999 9999"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              disabled={false}
              maskChar=" "
            >
              {() => (
                <TextField
                  variant="outlined"
                  name="phoneNumber"
                  label="Contact Phone Number"
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              name="email"
              label="Main Email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              name="secondaryEmail"
              label="Secondary Email"
              value={formik.values.secondaryEmail}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" color="primary" variant="contained">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
