import styled from 'styled-components';

export const MetricsContainer = styled.div`
  margin-top: 2rem;
`;

export const MetricsCard = styled.div`
  align-items: center;
  background: #ffffff;
  box-shadow: 5px 5px 25px rgba(51, 60, 68, 0.08);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 1.75rem 1rem;
`;

export const MetricsSection = styled.div`
  border-right: 1px solid #d6d6d6;
  padding: 1rem;
  text-align: center;
  width: 25%;

  &:last-child {
    border-right: 0;
  }
`;

export const MetricsCount = styled.p`
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
`;

export const MetricsTitle = styled.h2`
  font-size: 24px;
  font-family: Roobert Regular;
  font-weight: 400;
`;
