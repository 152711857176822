import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { RxText } from 'rx-core/components/texts/RxText';

type HeaderActionUserPopperNameProps = HTMLAttributes<HTMLDivElement> & {
  text: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  border-bottom: 1px solid rgba(131, 140, 165, 0.35);
  width: 100%;
  padding-bottom: 8px;
`;

const UserContainer = styled.div`
  height: 28px;
  width: 28px;
  background-color: #9fa6b9;
  opacity: 0.5;
  color: white;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  font-size: 14px;
  margin-right: 10px;

  & > span {
    margin-bottom: 2px;
  }
`;

const HeaderActionUserPopperName = ({
  text,
  ...props
}: HeaderActionUserPopperNameProps): JSX.Element => {
  return (
    <Container {...props}>
      <UserContainer>
        <span>{text.charAt(0)}</span>
      </UserContainer>
      <RxText font="Roboto Regular" size="small">
        Hi, {text}
      </RxText>
    </Container>
  );
};

export { HeaderActionUserPopperName };
