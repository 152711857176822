import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';

import { useManageItemsUpload } from 'rx-contexts';

import { Container, Title, ButtonContainer } from './index.styles';

export const InventoryActions: React.FC = () => {
  const history = useHistory();

  const { onSetUploadModalVisible, onStepUpload } = useManageItemsUpload();

  const openUploadMultipleWizard = () => {
    onStepUpload('CHOOSE-CSV-TYPE');
    onSetUploadModalVisible(true);
  };

  const addProduct = () => {
    history.push(`/seller/products/add`);
  };

  const user = localStorage.getItem('_user') || '';
  const { isEdiEnabled, companyName } = user
    ? JSON.parse(user)
    : { isEdiEnabled: false, companyName: '' };

  return (
    <Container>
      <Title>Inventory</Title>
      <ButtonContainer>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => openUploadMultipleWizard()}
              disabled={isEdiEnabled}
            >
              {isEdiEnabled || companyName.includes('Real Value')
                ? 'CSV Upload is currently disabled'
                : 'Upload Multiple Products'}
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              variant="contained"
              color="primary"
              onClick={() => addProduct()}
            >
              Add new product
            </Button>
          </Grid>
        </Grid>
      </ButtonContainer>
    </Container>
  );
};
