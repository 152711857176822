import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Anchor = styled(Link)``;

export const Logo = styled.img`
  height: 1.5rem;
  width: 7.75rem;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipad')} {
      width: 5.25rem;
      height: 1.5rem;
    }
  `}
`;
