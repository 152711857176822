import * as Yup from 'yup';

const AddACHPaymentSchema = Yup.object().shape({
  accountNumber: Yup.number()
    .positive()
    .required('Please enter your account number.')
    .typeError('You must specify a number'),
  routingNumber: Yup.number()
    .required('Routing number is required to continue.')
    .positive()
    .max(999999999)
    .typeError('You must specify a number'),
});

export { AddACHPaymentSchema };
