import { useState } from 'react';
import { useQuery, useSkipPage } from 'rx-hooks/utils';

type EdiStatus = 'all' | 'edi' | 'non-edi';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useReportQueryString = () => {
  const [length, setLength] = useState(0);

  const query = useQuery();
  const { skip, totalPages } = useSkipPage(length);

  const seller = query.get('seller') ?? '';
  const sellerId = query.get('sellerId') ?? '';
  const buyerId = query.get('buyerId') ?? '';
  const buyer = query.get('buyer') ?? '';
  const sortBy = (query.get('s') as 'createdAt' | 'amount') ?? '';
  const sortOrder = (query.get('o') as 'asc' | 'desc') ?? '';
  const status = (query.get('status') as 'paid' | 'pending') ?? '';
  const to = query.get('to') ?? '';
  const from = query.get('from') ?? '';
  const edi = (query.get('edi') as EdiStatus) ?? 'all';

  return {
    edi,
    seller,
    sellerId,
    buyer,
    buyerId,
    sortBy,
    sortOrder,
    status,
    to,
    from,
    skip,
    totalPages,
    setLength,
    length,
  };
};
