import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;
`;

export const Header = styled.header`
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const Content = styled.div`
  padding: 1rem;
`;

export const PendingTaskHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;
