import React from 'react';
import styled from 'styled-components';
import { color, ColorProps } from 'styled-system';

import { FilterItem } from 'rx-domain';

export type RxFilterItemProps = FilterItem;

const Text = styled.li<ColorProps>`
  ${color};
  padding-bottom: 16px;
`;

const RxFilterItem = ({ title, ...props }: RxFilterItemProps): JSX.Element => {
  return <Text {...props}>{title}</Text>;
};

export { RxFilterItem };
