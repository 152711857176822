import { Grid, Typography } from '@material-ui/core';
// import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import Container from '@material-ui/core/Container';
// import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';
import { useGetCategoriesList } from '../../../../rx-api';

const styles = {
  Card: {
    width: '330px',
    height: '60px',
    backgroundColor: '#ffffff',
    borderRadius: '24px',
    border: '1px solid #c0c0c0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Text: {
    color: '#313131',
    fontSize: '17px',
    fontWeight: 500,
    lineHeight: '22px',
    textAlign: 'center' as const,
    // marginLeft: '23%',
  },
  Icon: {
    color: '#717274',
    fill: '#717274',
    marginLeft: '20px',
    // fontSize: '38px',
    width: '30px',
    // height: '52px',
  },
};

const LinkStyled = styled(LinkRouter)`
  text-decoration: none;
  margin-top: 15px;
`;

// const MedicalIconComponent = () => (
//   <svg style={styles.Icon} viewBox="0 0 24 24">
//     <path d="M0 0h24v24H0z" fill="none"></path>
//     <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49A1.003 1.003 0 0 0 20 4H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path>
//   </svg>
// );
//
// const IconComponent = () => (
//   <svg style={styles.Icon} viewBox="0 0 448 512">
//     <path d="M440.1 448.4l-96.28-96.21l95.87-95.95c9.373-9.381 9.373-24.59 0-33.97l-22.62-22.64c-9.373-9.381-24.57-9.381-33.94 0L288.1 295.6L220.5 228c46.86-22.92 76.74-75.46 64.95-133.1C273.9 38.74 221.8 0 164.6 0H31.1C14.33 0 0 14.34 0 32.03v264.1c0 13.26 10.75 24.01 23.1 24.01l31.1 .085c13.25 0 23.1-10.75 23.1-24.02V240.2H119.4l112.1 112L135.4 448.4c-9.373 9.381-9.373 24.59 0 33.97l22.62 22.64c9.373 9.38 24.57 9.38 33.94 0l96.13-96.21l96.28 96.21c9.373 9.381 24.57 9.381 33.94 0l22.62-22.64C450.3 472.9 450.3 457.7 440.1 448.4zM79.1 80.06h87.1c22.06 0 39.1 17.95 39.1 40.03s-17.94 40.03-39.1 40.03H79.1V80.06z"></path>
//   </svg>
// );
//
// const MaskIconComponent = () => (
//   <svg style={styles.Icon} viewBox="0 0 640 512">
//     <path d="M396.4 87.12L433.5 111.9C449.3 122.4 467.8 128 486.8 128H584C614.9 128 640 153.1 640 184V269C640 324.1 602.5 372.1 549.1 385.5L441.1 412.5C406.2 434.1 364.6 448 320 448C275.4 448 233.8 434.1 198.9 412.5L90.9 385.5C37.48 372.1 0 324.1 0 269V184C0 153.1 25.07 128 56 128H153.2C172.2 128 190.7 122.4 206.5 111.9L243.6 87.12C266.2 72.05 292.8 64 320 64C347.2 64 373.8 72.05 396.4 87.12zM132.3 346.3C109.4 311.2 96 269.1 96 224V176H56C51.58 176 48 179.6 48 184V269C48 302.1 70.49 330.9 102.5 338.9L132.3 346.3zM592 269V184C592 179.6 588.4 176 584 176H544V224C544 269.1 530.6 311.2 507.7 346.3L537.5 338.9C569.5 330.9 592 302.1 592 269H592zM208 224H432C440.8 224 448 216.8 448 208C448 199.2 440.8 192 432 192H208C199.2 192 192 199.2 192 208C192 216.8 199.2 224 208 224zM208 256C199.2 256 192 263.2 192 272C192 280.8 199.2 288 208 288H432C440.8 288 448 280.8 448 272C448 263.2 440.8 256 432 256H208zM240 352H400C408.8 352 416 344.8 416 336C416 327.2 408.8 320 400 320H240C231.2 320 224 327.2 224 336C224 344.8 231.2 352 240 352z"></path>
//   </svg>
// );

const Categories = (): JSX.Element => {
  const { data } = useGetCategoriesList();
  // const theme = useTheme();

  // const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          <div>
            <Typography variant="h5" style={{ marginTop: '30px' }}>
              Categories
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              marginTop: '5px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              {data
                ?.sort((a, b) => a.id - b.id)
                .map((category) => (
                  <>
                    <LinkStyled to={`/products/?category=${category.text}`}>
                      <div style={styles.Card}>
                        <div style={styles.Text}>{category.slug}</div>
                      </div>
                    </LinkStyled>
                  </>
                ))}
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Categories;
