import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';
import { BUYER_URL_V1 } from '../constants';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePharmacistUpdateBillingInformation = (id: number) => {
  const onRequest = async (data: {
    name: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
  }) => {
    return http.put(
      `${BUYER_URL_V1}/pharmacies/${id}/billing-information`,
      data
    );
  };

  return useMutation(onRequest, {});
};
