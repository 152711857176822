import React from 'react';
import styled, { css } from 'styled-components';
import { Grid, CircularProgress, Button } from '@material-ui/core';

import { useAddPayment } from 'rx-contexts';

import StripeLogoImg from 'assets/logos/stripe_logo.svg';

type IProps = {
  onCancel: () => void;
};

const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    height: 4.625rem;
    padding: 1rem;
  `}
`;

const HRLine = styled.hr`
  width: 95%;
  text-align: center;
  margin: 0 auto;
  border: none;
  height: 1px;
  background-color: #b4b4b4;
`;

const DivActionsContainer = styled.div`
  background-color: white;
`;

const Loading = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: white;
  }
`;

export const AddPaymentMethodActions: React.FC<IProps> = ({ onCancel }) => {
  const { onSavePayment, loading, view } = useAddPayment();

  const okButtonText = view === 'payment-terms' ? 'Select' : 'Save';

  return (
    <DivActionsContainer>
      <HRLine />
      <Container>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Button
              data-cy="add-payment-method-modal-cancel"
              variant="text"
              color="secondary"
              onClick={onCancel}
              disabled={loading}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-cy="add-payment-method-modal-save"
              variant="text"
              color="primary"
              onClick={() => onSavePayment(true)}
              disabled={loading && view !== 'payment-terms'}
            >
              {loading ? <Loading size={20} thickness={6} /> : okButtonText}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </DivActionsContainer>
  );
};
