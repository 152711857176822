import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

export const useAdminDeleteManufacturerEmailRecipient = (
  manufacturerId: number | string
) => {
  return useMutation(
    'delete-manufacturer-email-recipient',
    (emailRecipientId: number) => {
      return http.delete(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/manufacturers/${manufacturerId}/email-recipients/${emailRecipientId}`
      );
    }
  );
};
