import React from 'react';
import StoreIcon from '@material-ui/icons/Store';

import { Container, Title } from './index.styles';

type IProps = {
  title: string;
};

const ShopCartCardTitle = (props: IProps): JSX.Element => {
  return (
    <Container>
      <StoreIcon />
      <Title>{props.title}</Title>
    </Container>
  );
};

export { ShopCartCardTitle };
