import {
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import React from 'react';

interface IProps {
  paymentMethodId: string | null;
  onChangePaymentMethod(e: any, value: string): void;
  paymentMethods: {
    id: number;
    brand: string;
    last4: string;
    expMonth: number;
    expYear: number;
  }[];
}

export const NewPurchaseOrderPaymentMethods: React.FC<IProps> = (props) => {
  return (
    <section>
      <Divider style={{ margin: '1rem 0' }} />

      <FormControl component="fieldset">
        <RadioGroup
          aria-label="paymentMethod"
          name="paymentMethod"
          value={props.paymentMethodId}
          onChange={props.onChangePaymentMethod}
        >
          <Typography variant="h6">Payment Methods</Typography>

          {!props.paymentMethods.length && (
            <Typography style={{ margin: '1rem 0' }}>
              Pharmacy has no payment methods
            </Typography>
          )}

          {props.paymentMethods.map((card) => {
            return (
              <FormControlLabel
                key={card.id}
                value={`${card.id}`}
                control={<Radio color="primary" />}
                label={`${card.brand.toUpperCase()} ending with ${
                  card.last4
                } - expires on ${card.expMonth}/${card.expYear}`}
              />
            );
          })}

          <Typography variant="h6">Payment Terms</Typography>

          <FormControlLabel
            value="payment_terms_7"
            control={<Radio color="primary" />}
            label="7 Days"
          />

          <FormControlLabel
            value="payment_terms_15"
            control={<Radio color="primary" />}
            label="15 Days"
          />

          <FormControlLabel
            value="payment_terms_30"
            control={<Radio color="primary" />}
            label="30 Days"
          />
        </RadioGroup>
      </FormControl>
    </section>
  );
};
