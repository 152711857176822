import React, { useState, useEffect } from 'react';

import { CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import { IGetManufacturer } from 'rx-domain';

import { useGetSellers } from 'rx-api';

type StyleProps = {
  withValue: boolean;
};

type IProps = {
  onGetManufacturer: (manufacturer: IGetManufacturer | null) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    option: {
      '&:hover': {
        color: 'white',
        backgroundColor: '#5077F2',
      },
    },
    input: (props: StyleProps) =>
      props.withValue
        ? {
            backgroundColor: '#5077F2',
            color: 'white',
            borderRadius: '10px',
          }
        : {
            borderRadius: '10px',
          },
    root: (props: StyleProps) =>
      props.withValue
        ? {
            backgroundColor: '#5077F2',
            borderRadius: '10px',
          }
        : {
            borderRadius: '10px',
          },
    clearIndicator: (props: StyleProps) =>
      props.withValue
        ? {
            color: 'white',
          }
        : {},
    popupIndicator: (props: StyleProps) =>
      props.withValue
        ? {
            color: 'white',
          }
        : {},
  })
);

const MISearchManufacturer = ({ onGetManufacturer }: IProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<IGetManufacturer | null>(null);
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<IGetManufacturer[]>([]);

  const classes = useStyles({ withValue: !!value });

  const { data: adminManufacturers, isLoading } = useGetSellers({
    limit: 1000,
    approvalStatus: 'approved',
    shipper: search,
    skip: 0,
  });

  useEffect(() => {
    if (adminManufacturers?.data) {
      setOptions(adminManufacturers.data.results);
    }
  }, [adminManufacturers]);

  return (
    <Autocomplete
      id="manufacturer-search"
      style={{ width: 400 }}
      classes={classes}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, autocompleteValue) =>
        option.id === autocompleteValue.id
      }
      getOptionLabel={(option) => option.manufacturer.companyName}
      options={options}
      loading={isLoading}
      value={value}
      filterOptions={(x) => x}
      onChange={(event: unknown, newValue: IGetManufacturer | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onGetManufacturer(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Pharmaceuticals"
          variant="outlined"
          focused={false}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export { MISearchManufacturer };
