import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { useGetProductsByActiveIngredient } from '../../../../../rx-api/api/pharmacist/v2Products/useGetProductsByActiveIngredient/useGetProductsByActiveIngredient';
import { Search as SearchIcon } from '@material-ui/icons';
import { AppDispatch, setBuyerSearchText } from '../../../../../rx-store';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';

const Input = styled(TextField)`
  ${({ theme }) => css`
    background-color: white;
    height: 100%;
    width: 100%;
    margin: 0;
    // ${theme.lessThan('ipadPro')} {
    //   width: 20%;
    // }
  `}
`;

const ButtonContainer = styled.button`
  ${({ theme }) => css`
    width: 8.75rem;
    height: 40px;
    background-color: ${theme.colors.blue[300]};
    border: 1px solid ${theme.colors.blue[300]};
    border-radius: 4px;
    color: white;
    text-transform: uppercase;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    cursor: pointer;

    &:disabled {
      background-color: rgb(159, 166, 185, 0.7);
      border: none;
      cursor: no-drop;
    }

    ${theme.lessThan('ipadPro')} {
      width: 20%;
      font-size: ${theme.sizes.extraSmall};
    }
  `}
`;

const Icon = styled(SearchIcon)`
  ${({ theme }) => css`
    margin-right: 0.5rem;

    ${theme.lessThan('ipadPro')} {
      &.MuiSvgIcon-root {
        //display: none;
      }
    }
  `}
`;

const HiddenText = styled.span`
  ${({ theme }) => css`
    margin-left: 0.25rem;

    ${theme.lessThan('ipadPro')} {
      display: none;
    }
  `}
`;

const SearchByNDC = () => {
  const getProductsByActiveIngredient = useGetProductsByActiveIngredient();
  const [results, setResults] = useState<any>([]);
  const dispatch: AppDispatch = useDispatch();
  const [uniqueResults, setUniqueResults] = useState<any>([]);
  const [activeIngredientInput, setActiveIngredientInput] = useState('');

  const handleSearchByActiveIngredient = async (
    event: React.SyntheticEvent
  ) => {
    const target = event.target as HTMLInputElement;
    setActiveIngredientInput(target.value.trim());
    await getProductsByActiveIngredient.mutateAsync({
      activeIngredient: target.value.trim(),
    });
  };

  const onSearchInput = async (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;

    if (!target.value) {
      dispatch(setBuyerSearchText(''));
      return;
    }
    dispatch(setBuyerSearchText(target.value.trim()));
  };

  const capitalizeFirstLetter = (string: string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  useEffect(() => {
    const onGetFetch = async () => {
      if (activeIngredientInput) {
        const res = await getProductsByActiveIngredient.mutateAsync({
          activeIngredient: activeIngredientInput.trim(),
        });
        setResults(res.data.results);
      }
    };

    onGetFetch();
  }, [activeIngredientInput]);

  useEffect(() => {
    if (results.length > 0) {
      const uniqueArray = [];
      const names = new Set();

      for (const obj of results) {
        if (!names.has(obj.displayName)) {
          names.add(obj.displayName);
          uniqueArray.push(obj);
        }
      }
      setUniqueResults(uniqueArray);
    }
  }, [results]);

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <Autocomplete
          style={{ width: '800px' }}
          freeSolo
          disableClearable
          options={activeIngredientInput !== '' ? uniqueResults : []}
          renderOption={(option: any) => {
            if (option?.inputValue) {
              return <b>{option.displayName}</b>;
            }
            return (
              <Typography noWrap>
                {capitalizeFirstLetter(option.displayName)}
              </Typography>
            );
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }

            return capitalizeFirstLetter(option.displayName);
          }}
          onInputChange={(event, newInputValue) => {
            setActiveIngredientInput(newInputValue);
          }}
          renderInput={(params) => (
            <Input
              {...params}
              margin="normal"
              label="Search Term"
              variant="outlined"
              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
        <ButtonContainer
          style={{ height: '56px', marginLeft: '16px' }}
          data-cy="search-products-btn"
        >
          <Icon /> <HiddenText> Search</HiddenText>
        </ButtonContainer>
        {/*<TextField*/}
        {/*  style={{ width: '250px' }}*/}
        {/*  id="demo-simple-select"*/}
        {/*  value={form}*/}
        {/*  onChange={(e) => {*/}
        {/*    setForm(e.target.value);*/}
        {/*  }}*/}
        {/*  label="Form"*/}
        {/*  variant="outlined"*/}
        {/*  select*/}
        {/*>*/}
        {/*  <MenuItem value={'All'}>All</MenuItem>*/}
        {/*  {forms?.data?.data?.results?.map((form: any) => (*/}
        {/*    <MenuItem value={form.name}>{form.name}</MenuItem>*/}
        {/*  ))}*/}
        {/*</TextField>*/}
        {/*<Input*/}
        {/*  style={{ marginLeft: '20px', width: '450px' }}*/}
        {/*  placeholder="100 mg"*/}
        {/*  variant="outlined"*/}
        {/*  label="Strength"*/}
        {/*/>*/}
      </div>
    </div>
  );
};

export default SearchByNDC;
