import { useGetRequest } from 'rx-api/utils';

export const useGetInventoryProductPricingTiers = (productId: number) => {
  const { isLoading, data, ...result } = useGetRequest({
    key: 'get-product-pricing-tier',
    url: `${process.env.REACT_APP_WEBSITE_URL}/v1/manufacturer/inventory/products/${productId}/pricing-tiers`,
  });

  if (isLoading) {
    return { ...result, isLoading, data: [] };
  }

  return { ...result, isLoading, data: data?.data || [] };
};
