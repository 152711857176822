import React from 'react';
import DoneIcon from '@material-ui/icons/Done';

import { US_STATES_WITH_CODES } from 'rx-utils';

import { Container, StateChip } from './index.styles';

type IProps = {
  values: Array<{ name: string; value: string }>;
  onChange: (state: { name: string; value: string }) => void;
  formState: 'VIEW' | 'EDIT';
};

const AdminSellerShippingInfoStatesInput = ({
  onChange,
  values,
  formState,
}: IProps): JSX.Element => {
  return (
    <Container>
      {US_STATES_WITH_CODES.map((state) => {
        const active = !!values.find((val) => val.value === state.value);

        return (
          <StateChip
            active={active}
            disabled={formState === 'VIEW'}
            key={state.value}
            icon={active ? <DoneIcon /> : undefined}
            onClick={() => onChange(state)}
            label={state.name}
            clickable
            color="primary"
            variant="outlined"
          />
        );
      })}
    </Container>
  );
};

export { AdminSellerShippingInfoStatesInput };
