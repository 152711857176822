import React from 'react';

import { Container, Title } from './index.styles';

const MOActions = (): JSX.Element => {
  return (
    <Container>
      <Title>Manage Orders</Title>
    </Container>
  );
};

export { MOActions };
