import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { IBlackListItem, ITableHeader } from 'rx-domain';
import { useGetAdminBannedProducts } from 'rx-api';
import { useSkipPage } from 'rx-hooks';
import { RxPagination } from 'rx-core';

import { ManageItemschema } from 'utils/validators/manage-items.validator';
import { useAdminProducts } from 'rx-contexts';
import { AdminProductsDeleteBlackItem } from '../molecules/AdminProductsDeleteBlackItem';
import { AdminProductsBlackListDataItems } from '../molecules/AdminProductsBlackListDataItems';
import { AdminProductsTableLoader } from '../atoms/AdminProductsTableLoader';
import { AdminBannedProductsEmpty } from '../atoms/AdminBannedProductsEmpty';

const TableContainer = styled.div`
  width: 100%;
  background-color: white;
  margin-bottom: 1rem;
`;

const headerItems: ITableHeader[] = [
  {
    title: 'NDC',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    width: '12%',
    span: 1,
  },
  {
    title: 'NAME',
    width: '12%',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 1,
  },
  {
    title: 'DESCRIPTION',
    width: '10%',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 1,
  },
  {
    title: '',
    span: 1,
  },
];

type IForm = {
  blackItems: Array<IBlackListItem>;
};

interface IAdminBlackListTableProps {
  onChangeCount: (count: number) => void;
}

const AdminBlackListTable: React.FC<IAdminBlackListTableProps> = (props) => {
  const initialValues: IForm = {
    blackItems: [],
  };

  const { onSetLengthBlackListData } = useAdminProducts();

  const [length, setLength] = useState(0);

  const { totalPages } = useSkipPage(length);

  const { data: blackList, isLoading } = useGetAdminBannedProducts();

  const blackListItems = useMemo(() => {
    return !blackList?.data
      ? initialValues
      : {
          blackItems: blackList?.data.results.map(
            ({ createdAt, updatedAt, createdBy, ...item }) => item
          ),
        };
  }, [blackList, initialValues]);

  const onSubmit = () => {};

  useEffect(() => {
    if (blackList?.data && !isLoading) {
      setLength(0);
      onSetLengthBlackListData(0);
      props.onChangeCount(blackList.data.results.length);
    }
  }, [blackList, isLoading, onSetLengthBlackListData, props]);

  return (
    <>
      <TableContainer>
        <Formik
          initialValues={blackListItems}
          onSubmit={onSubmit}
          validationSchema={ManageItemschema}
          enableReinitialize
        >
          <Form>
            <AdminProductsDeleteBlackItem />
            <Table>
              <TableHead>
                <TableRow>
                  {headerItems.map(({ title, span }, index) => (
                    <TableCell colSpan={span} key={index}>
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {isLoading && <AdminProductsTableLoader />}

                {!isLoading && Number(blackList?.data?.results?.length) === 0 && (
                  <AdminBannedProductsEmpty>
                    <Typography align="center" variant="h6">
                      No Products Found
                    </Typography>
                  </AdminBannedProductsEmpty>
                )}

                {!isLoading && Number(blackList?.data?.results?.length) > 0 && (
                  <AdminProductsBlackListDataItems
                    items={blackList?.data.results ?? []}
                  />
                )}
              </TableBody>
            </Table>
          </Form>
        </Formik>
      </TableContainer>
      {totalPages > 1 && (
        <RxPagination
          path="products"
          totalPages={totalPages}
          defaultQueries={{
            sortByDefault: 'createdAt',
          }}
        />
      )}
    </>
  );
};

export { AdminBlackListTable };
