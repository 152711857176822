import { ADMIN_URL_V1 } from '../constants';

export const GET_PHARMACY_LIST_URL = `${ADMIN_URL_V1}/pharmacies`;
export const GET_PHARMACY_LIST_KEY = `GET_PHARMACY_LIST_KEY`;

export const DELETE_ADMIN_ACH = `${ADMIN_URL_V1}/pharmacies/{id}/payment-methods/ach/{achId}`;
export const DELETE_ADMIN_CARD = `${ADMIN_URL_V1}/pharmacies/{id}/payment-methods/cards/{cardId}`;

export const POST_ADMIN_ADD_ACH = `${ADMIN_URL_V1}/pharmacies/{id}/payment-methods/ach`;
export const POST_ADMIN_ADD_CARD = `${ADMIN_URL_V1}/pharmacies/{id}/payment-methods/cards`;

export const GET_ADMIN_PHARMACY_LEGAL_DOCUMENTS_URL = `${ADMIN_URL_V1}/pharmacies/{id}/pharmacy-files`;
export const GET_ADMIN_PHARMACY_LEGAL_DOCUMENTS_KEY = `get-admin-pharmacy-legal-documents`;

export const GET_ADMIN_PHARMACY_PAYMENT_METHODS_KEY =
  'admin-get-pharmacy-payment-methods';

export const GET_ADMIN_PHARMACY_SHIPPING_ADDRESS_KEY =
  'get-admin-pharmacist-shipping-address';
export const GET_ADMIN_PHARMACY_SHIPPING_ADDRESS_URL = (id: number) =>
  `${ADMIN_URL_V1}/pharmacists/${id}/shipping-address`;

export const PUT_ADMIN_PHARMACIST_SHIPPING_ADDRESS_URL = (id: number) =>
  `${ADMIN_URL_V1}/pharmacists/${id}/shipping-address`;

export const PUT_ADMIN_PHARMACY_SHIPPING_ADDRESS_URL = (id: number) =>
  `${ADMIN_URL_V1}/pharmacies/${id}/shipping-address`;

export const GET_ADMIN_PHARMACY_PAYMENT_METHODS_URL = (id: number) =>
  `${ADMIN_URL_V1}/pharmacies/${id}/payment-methods`;
