import { IPostAuthSigninManufacturerDTO } from 'rx-domain';
import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePostAuthSigninManufacturer = () => {
  const onRequest = (request: IPostAuthSigninManufacturerDTO) => {
    return http.post(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/auth/signin/manufacturer`,
      request
    );
  };

  return useMutation(onRequest, {});
};

export { usePostAuthSigninManufacturer };
