import React from 'react';
import { Paper } from '@material-ui/core';

import { Content } from '../index.styles';
import { PendingTask } from '../PendingTask';
import { PendingTaskListLoader } from './PendingTaskListLoader';

export interface IPendingTaskListProps {
  isLoading: boolean;
  data: Array<{
    description: string;
    title: string;
    date: string;
  }>;
}

const getUrl = (description: string): string => {
  if (description.includes('Buyer')) {
    return '/approve?type=pharmacist';
  }

  if (description.includes('Seller:')) {
    return '/approve?type=manufacter';
  }

  return `/approve?type=pharmacy`;
};

export const PendingTaskList: React.FC<IPendingTaskListProps> = (
  props: IPendingTaskListProps
): JSX.Element => {
  if (props.isLoading) {
    return <PendingTaskListLoader />;
  }

  return (
    <>
      {!props.data.length && (
        <Paper elevation={1} variant="outlined">
          <Content>
            <p>You are up to date!</p>
          </Content>
        </Paper>
      )}

      {props.data?.map(
        (pendingTask: { description: string; title: string; date: string }) => {
          const url = getUrl(pendingTask.description);
          return <PendingTask url={url} pendingTask={pendingTask} />;
        }
      )}
    </>
  );
};
