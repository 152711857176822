import React from 'react';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import {
  useGetAccountManufacturerData,
  usePutAccountManufacturerData,
} from 'rx-api';
import { useToast } from 'rx-hooks';
import { clearPhonenumber } from 'rx-utils';

export const SettingsBizInfoForm: React.FC = () => {
  const { data } = useGetAccountManufacturerData();
  const updateManufacturerData = usePutAccountManufacturerData();
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      companyName: data?.data?.companyName || '',
      companyEmail: data?.data?.companyEmail || '',
      ediPartnerId: data?.data?.ediPartnerId || '',
      phoneNumber: data?.data?.phoneNumber || '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await updateManufacturerData.mutateAsync({
          phoneNumber: clearPhonenumber(values.phoneNumber || ''),
          companyEmail: values.companyEmail || '',
          companyName: values.companyName || '',
          ediPartnerId: values.ediPartnerId || '',
        });

        toast.onSuccessToast('Success: information saved');
      } catch (err) {
        toast.onErrorToast('Error: could not save business information');
      }
    },
  });

  return (
    <Paper variant="outlined" style={{ padding: '1rem' }}>
      <Typography variant="h5" style={{ marginBottom: '1rem' }}>
        Bussiness Information
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="companyName"
              label=" Company Name*"
              placeholder="Company Name"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="companyEmail"
              label=" Company Contact Email*"
              value={formik.values.companyEmail}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="phoneNumber"
              label=" Company Contact Phone Number*"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
