import React from 'react';
import styled, { css } from 'styled-components';

import { Grid } from '@material-ui/core';
import { RxInputText, RRSelect as RxSelect, RxInputFile } from 'rx-core';

const Container = styled.div`
  width: 100%;
`;

const DEAStateTitleSection = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoRegular};
    font-weight: 400;
    margin-bottom: 1.5rem;

    ${theme.lessThan('ipad')} {
      padding: 0 1.5rem;
    }
  `}
`;

const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoLight};
    font-weight: 400;
    padding-bottom: 1rem;

    ${theme.lessThan('ipad')} {
      padding: 0 1.5rem;
    }
  `}
`;

const PharmacyTitleSection = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoRegular};
    font-weight: 400;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    ${theme.lessThan('ipad')} {
      padding: 0 1.5rem;
    }
  `}
`;

const GridResponsive = styled(Grid)`
  ${({ theme }) => css`
    ${theme.lessThan('ipad')} {
      &.MuiGrid-spacing-xs-2 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }

      &.MuiGrid-spacing-xs-4 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }
    }
  `}
`;

export const SignupBuyerPharmacyInfoForm: React.FC = () => {
  return (
    <Container>
      <DEAStateTitleSection>Pharmacy Information</DEAStateTitleSection>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="pharmacy.name"
            label="Business/Pharmacy Name *"
            placeholder="Pharmacy Name"
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="pharmacy.dbaName"
            label="Doing Business As *"
            placeholder="DBA Name"
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
      <PharmacyTitleSection>Pharmacy Documentation</PharmacyTitleSection>
      <Subtitle>
        Please upload a jpg, png, or PDF file to validate your DEA and State
        License. The limit size of the file is 40MB.
      </Subtitle>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="pharmacy.deaNumber"
            placeholder="DEA Number"
            label="DEA Number *"
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputFile
            label="DEA File *"
            name="pharmacy.dea"
            placeholder="DEA Number"
            accept="application/pdf,application/vnd.ms-excel,image/png,image/jpeg"
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="pharmacy.stateLicenseNumber"
            placeholder="State License Number"
            label="State License Number *"
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputFile
            label="State License File *"
            name="pharmacy.stateLicense"
            placeholder="State License"
            accept="application/pdf,application/vnd.ms-excel,image/png,image/jpeg"
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxSelect
            name="pharmacy.type"
            label="Type of pharmacy *"
            placeholder="Choose State"
            items={[
              {
                value: '1',
                name: 'Retail Pharmacy',
              },
              {
                value: '2',
                name: 'Dr./Physician Office',
              },
              {
                value: '3',
                name: 'Clinic',
              },
              {
                value: '4',
                name: 'Hospital',
              },
              {
                value: '5',
                name: 'Long Term Care Facility',
              },
              {
                value: '6',
                name: 'Chain Pharmacy',
              },
            ]}
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>

      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="pharmacy.phoneNumber"
            label="Pharmacy’s Phone Number *"
            placeholder="(XXX) XXX-XXXX"
            withMask
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="pharmacy.faxNumber"
            label="Fax Number"
            placeholder="(XXX) XXX-XXXX"
            withMask
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="pharmacy.email"
            label="Pharmacy Email*"
            placeholder="yourname@pharmacyname.com"
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="pharmacy.npi"
            label="National Provider Number (NPI#) *"
            placeholder="NPI #"
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="pharmacy.yearsBusiness"
            label="Years in business*"
            type="number"
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="pharmacy.taxId"
            label="Tax ID"
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
    </Container>
  );
};
