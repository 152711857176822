import React from 'react';

import { BackLink, Icon } from './index.styles';

export const PurchaseOrderRequestBackPage: React.FC = () => {
  return (
    <BackLink to="/manage-orders?mo=all">
      <Icon />
      Return to list of orders
    </BackLink>
  );
};
