import {
  useGetAccountPharmacyData,
  useGetAddressShipping,
  useGetPharmacistPharmacy,
  useGetPharmacistBillingInformation,
  usePharmacistGetPharmacyList,
} from 'rx-api';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useBankingAccountData = () => {
  const { data: fullPharmacyResponse, ...fullPharmacyOpts } =
    useGetPharmacistPharmacy();
  const { data: pharmacyResponse, ...pharmacyOpts } =
    useGetAccountPharmacyData();
  const { data: billingInfoResponse, ...billingInfoOpts } =
    useGetPharmacistBillingInformation();
  const { data: shippingAddressResponse, ...shippingAddressOpts } =
    useGetAddressShipping();
  const { data: pharmaciesListResponse, ...pharmaciesOpts } =
    usePharmacistGetPharmacyList('approved');

  const isLoading =
    pharmacyOpts.isLoading ||
    billingInfoOpts.isLoading ||
    shippingAddressOpts.isLoading ||
    fullPharmacyOpts.isLoading ||
    pharmaciesOpts.isLoading;

  return {
    pharmacyResponse,
    billingInfoResponse,
    shippingAddressResponse,
    fullPharmacyResponse,
    pharmaciesListResponse,
    pharmacyOpts,
    billingInfoOpts,
    shippingAddressOpts,
    fullPharmacyOpts,
    pharmaciesOpts,
    isLoading,
  };
};
