import { useState } from 'react';
import { useQuery, useSkipPage } from 'rx-hooks/utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useOrders = () => {
  const [length, setLength] = useState(0);

  const query = useQuery();
  const { skip, totalPages } = useSkipPage(length);

  const queryId = parseInt(query.get('orderId') ?? '0', 10);
  const queryFrom = query.get('from') ?? '';
  const queryTo = query.get('to') ?? '';
  const queryStatus =
    (query.get('status') as
      | 'pending'
      | 'fulfilled'
      | 'shipped'
      | 'cancelled'
      | 'all') ?? 'all';

  return {
    queryId,
    queryStatus,
    queryFrom,
    queryTo,
    skip,
    totalPages,
    setLength,
    length,
  };
};

export { useOrders };
