import { useMutation } from 'react-query';

import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePutPharmacistBillingId = (id: string | number) => {
  return useMutation(
    ['put-admin-pharmacist-billing-info', id],
    async (request: {
      name: string;
      billingAddress: {
        address: string;
        state: string;
        city: string;
        zipCode: string;
      };
    }) => {
      return http.put(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/pharmacies/${id}/billing`,
        request
      );
    },
    {}
  );
};
