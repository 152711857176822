import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = styled(Grid)`
  margin-bottom: 1rem;
`;

export const RowContainer = styled(Grid)`
  &.MuiGrid-spacing-xs-4 > .MuiGrid-item {
    padding-bottom: 0;
  }
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoRegular};
    font-size: ${theme.sizes.h2};
    font-weight: 400;
    margin-bottom: 1.5rem;
  `}
`;
