import React, { useEffect, useState } from 'react';

import { IGetAdminManufacturersIdShipConfigResponse } from 'rx-domain';
import { US_STATES_WITH_CODES } from 'rx-utils';

import { FormikErrors } from 'formik/dist/types';
import { StateTextInfo, Container } from './index.styles';

import { AdminSellerShippingInfoStatesInput } from '../AdminSellerShippingInfoStatesInput';

type IProps = {
  data: IGetAdminManufacturersIdShipConfigResponse | undefined;
  setValue: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<any>> | Promise<void>;
};

const AdminSellerShippingInfoStatesForm = (props: IProps): JSX.Element => {
  const [states, setStates] = useState<Array<{ name: string; value: string }>>(
    []
  );

  const onStoreStates = (state: { name: string; value: string }) => {
    setStates((prev) => {
      const exist = !!prev.find((prevState) => prevState.value === state.value);

      const filteredStates = exist
        ? prev.filter((prevState) => prevState.value !== state.value)
        : prev.concat(state);

      props.setValue(
        'shippingConstraints',
        filteredStates.map((currentState) => currentState.value).join(',')
      );
      return filteredStates;
    });
  };

  useEffect(() => {
    setStates(() => {
      return US_STATES_WITH_CODES.filter((state) =>
        props.data ? props.data.shippingConstraints.includes(state.value) : true
      );
    });
  }, [props.data]);

  return (
    <Container>
      <AdminSellerShippingInfoStatesInput
        formState={'EDIT'}
        values={states}
        onChange={onStoreStates}
      />
      <StateTextInfo>
        You have {states.length} states added as restricted.
      </StateTextInfo>
    </Container>
  );
};

export { AdminSellerShippingInfoStatesForm };
