import React, { InputHTMLAttributes } from 'react';
import { LayoutProps } from 'styled-system';
import { FieldProps, getIn } from 'formik';

import { InputTable } from '../InputTable/InputTable';
import { TextAreaTable } from '../TextAreaTable/TextAreaTable';

export type InputTableFormikProps = FieldProps &
  InputHTMLAttributes<HTMLInputElement> &
  InputHTMLAttributes<HTMLTextAreaElement> &
  LayoutProps & {
    className?: string;
    inputType: 'normal' | 'textarea';
  };

const InputTableFormik = ({
  field: { name, ...fieldProps },
  form: { errors, touched },
  inputType,
  ...props
}: InputTableFormikProps): JSX.Element => {
  const fieldError = getIn(errors, name);
  const touchedError = getIn(touched, name);

  const isError = !!fieldError && !!touchedError;

  if (inputType === 'textarea') {
    return (
      <TextAreaTable name={name} error={isError} {...props} {...fieldProps} />
    );
  }

  return <InputTable name={name} error={isError} {...props} {...fieldProps} />;
};

InputTableFormik.defaultProps = {
  inputType: 'normal',
};

export { InputTableFormik };
