import styled, { css } from 'styled-components';
import InfoIcon from '@material-ui/icons/Info';

export const TableRow = styled.tr`
  & > td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left-style: solid;
  }

  & > td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right-style: solid;
  }
`;

export const TableColumn = styled.td`
  ${({ theme }) => css`
    text-align: left;
    padding: 0.5rem;
    font-size: ${theme.sizes.small};
    background-color: white;
    border: 1px #64a8ff solid;
    color: black;
    border-style: solid none;

    display: table-cell;
    vertical-align: middle;
  `}
`;

export const Spacer = styled.tr`
  height: 0.5rem;
`;

export const Icon = styled(InfoIcon)`
  &.MuiSvgIcon-root {
    fill: #64a8ff;
    margin-right: 0.5rem;
  }
`;

export const WarningContainer = styled.div`
  display: flex;
  align-items: center;
`;
