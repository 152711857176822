import React from 'react';
import styled, { css } from 'styled-components';
import { Formik, Form } from 'formik';
import { Chip, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { RRModal, RRModalProps, RxButtonSimple } from 'rx-core';

import { isArrayOfStrings } from 'rx-utils';

import { useAdminManufacturersIdApiKeyEmail } from 'rx-api';

import { useToast } from 'rx-hooks';

import { EmailInvoiceSchema } from 'utils/validators/admin/email-invoice.validator';

type IProps = RRModalProps & {
  sellerId: number;
};

const Modal = styled(RRModal)`
  height: fit-content;
  width: 38rem;
`;

const Container = styled.div`
  padding: 1rem;
`;

const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoRegular};
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(43, 54, 71, 0.35);
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;

  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(43, 54, 71, 0.35);
`;

const EmailInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;

  & > .MuiAutocomplete-root {
    flex: 1;
  }
`;

const EmailError = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.red['100']};
  `}
`;

const EmailLabel = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoRegular};
    font-weight: 500;
    padding-right: 1rem;
  `}
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
`;

const CancelButton = styled(RxButtonSimple)`
  margin-right: 1rem;
`;

const SendButton = styled(RxButtonSimple)``;

type IForm = {
  emails: Array<string>;
};

const SellerSendAPIKey = (props: IProps): JSX.Element => {
  const onCloseModal = () => {
    props.onClose();
  };

  const { onSuccessToast } = useToast();

  const sendAPIKey = useAdminManufacturersIdApiKeyEmail();

  const onSubmit = async (values: IForm) => {
    try {
      await sendAPIKey.mutateAsync({
        id: props.sellerId,
        emails: values.emails,
      });
      onSuccessToast('Emails send successfully ✅');
      onCloseModal();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <Modal {...props}>
      <Formik
        initialValues={{
          emails: [],
        }}
        onSubmit={onSubmit}
        validationSchema={EmailInvoiceSchema}
      >
        {(formikProps) => (
          <Form>
            <Container>
              <Title>Email invoice 📧</Title>
              <Content>
                <EmailInputContainer>
                  <EmailLabel>Email to:</EmailLabel>
                  <Autocomplete
                    multiple
                    value={formikProps.values.emails}
                    onChange={(_, newValue) => {
                      if (isArrayOfStrings(newValue)) {
                        formikProps.setFieldValue('emails', newValue);
                      }
                    }}
                    onBlur={formikProps.handleBlur}
                    id="tags-filled"
                    options={[]}
                    freeSolo
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Favorites"
                        name="email"
                      />
                    )}
                  />
                </EmailInputContainer>
                {formikProps.errors.emails && (
                  <EmailError>
                    One of these emails are wrong, please remove it.
                  </EmailError>
                )}
              </Content>
              <ActionsContainer>
                <CancelButton
                  type="button"
                  variant="outline"
                  onClick={() => onCloseModal()}
                >
                  Cancel
                </CancelButton>
                <SendButton
                  disabled={
                    formikProps.values.emails.length === 0 ||
                    !formikProps.isValid
                  }
                  type="submit"
                  loading={formikProps.isSubmitting}
                >
                  Send
                </SendButton>
              </ActionsContainer>
            </Container>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export { SellerSendAPIKey };
