import React from 'react';

import { Anchor, Logo } from './index.styles';

import HeaderLogoSVG from '../../../../assets/logos/header-logo.svg';

const HeaderLogo: React.FC = () => {
  return (
    <Anchor to="/">
      <Logo src={HeaderLogoSVG} />
    </Anchor>
  );
};

export { HeaderLogo };
