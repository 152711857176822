import React from 'react';
import styled, { css } from 'styled-components';

import { StepUploadType } from 'rx-domain';

import { useManageItemsUpload } from 'rx-contexts';

import { RRModal } from 'rx-core';
import {
  CsvUploadPickManufacturer,
  CsvUploadPickFile,
  CsvUploadReview,
} from '../molecules';
import { BulkUpSelectTypeStep } from '../molecules/BulkUpSelectTypeStep';

const Modal = styled(RRModal)<{ step: StepUploadType; confirmUpload: boolean }>`
  max-height: 20rem;
  height: fit-content;
  min-width: 57.5rem;
  overflow-x: auto;

  ${({ confirmUpload }) =>
    confirmUpload &&
    css`
      max-height: 14.75rem;
    `}

  ${({ step }) => css`
    ${step === 'PREVIEW' &&
    css`
      max-height: 29rem;
    `}
  `}

  ${({ theme }) => css`
    ${theme.lessThan('ipad')} {
      width: 100%;
    }
  `}

  @media screen and (max-height: 25rem) {
    max-height: 95%;
  }
`;

const Container = styled.div`
  padding: 2rem;
  padding-bottom: 1.5rem;
  height: 100%;
`;

interface ICsvUploadModalProps {
  onUploadSuccess: () => void;
}

export const CsvUploadModal: React.FC<ICsvUploadModalProps> = (props) => {
  const {
    stepUpload,
    uploadModalVisible,
    onSetUploadModalVisible,
    confirmUpload,
  } = useManageItemsUpload();

  return (
    <Modal
      step={stepUpload}
      visible={uploadModalVisible}
      closeButton={false}
      confirmUpload={confirmUpload}
      onClose={() => onSetUploadModalVisible(false)}
    >
      <Container>
        {stepUpload === 'CHOOSE-MANUFACTURER' && <CsvUploadPickManufacturer />}
        {stepUpload === 'CHOOSE-CSV-TYPE' && <BulkUpSelectTypeStep />}
        {stepUpload === 'CHOOSE' && <CsvUploadPickFile />}
        {stepUpload === 'PREVIEW' && (
          <CsvUploadReview onUploadSuccess={props.onUploadSuccess} />
        )}
      </Container>
    </Modal>
  );
};
