import styled from 'styled-components';

export const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TotalContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px #e9ecfe solid;
`;
