import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import { useField } from 'formik';

export type RxCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  text: React.ReactNode;
  name: string;
  className?: string;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 36px;
`;

const Text = styled.span`
  font-size: ${({ theme }) => theme.sizes.medium};
  font-family: ${({ theme }) => theme.fonts.robotoLight};
`;

const Checkbox = styled.input`
  margin-right: 12px;
  height: 20px;
  width: 20px;
`;

const RxCheckbox = ({
  text,
  className,
  ...props
}: RxCheckboxProps): JSX.Element => {
  const [field] = useField(props);

  return (
    <Container className={className}>
      {field.value !== undefined && (
        <Checkbox type="checkbox" {...field} {...props} checked={field.value} />
      )}
      {text && <Text>{text}</Text>}
    </Container>
  );
};

export { RxCheckbox };
