import {
  Button,
  Chip,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { usePutManufacturersId } from 'rx-api';
import { IGetManufacturerIdResponse } from 'rx-domain';
import { useToast } from 'rx-hooks';
import { useFormik } from 'formik';
import React from 'react';
import { useQueryClient } from 'react-query';

type IProps = IGetManufacturerIdResponse;

export const ManufacturerCompanyInformation: React.FC<IProps> = (props) => {
  const toast = useToast();

  const updateAccount = usePutManufacturersId();
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      companyName: props.manufacturer.companyName || '',
      email: props.manufacturer.email || '',
      phoneNumber: props.manufacturer.phoneNumber || '',
      isVawd: props.manufacturer.isVawd || false,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await updateAccount.mutateAsync({
          manufacturer: {
            companyName: values.companyName,
            email: values.email,
            phoneNumber: values.phoneNumber,
            isVawd: values.isVawd,
          },
          firstName: props.firstName,
          lastName: props.lastName,
          titleOrPosition: props.titleOrPosition,
          phoneNumber: props.phoneNumber,
          email: props.email,
          secondaryEmail: props.secondaryEmail ?? '',
          id: props.id,
        });

        await queryClient.refetchQueries('get-admin-manufacturer-id', {
          active: true,
        });

        toast.onSuccessToast('Manufacturer Business Information Saved!');
      } catch (err) {
        toast.onErrorToast('Error: could not save manufacturer information');
      }
    },
  });

  return (
    <>
      <Typography variant="h5" style={{ marginBottom: '1rem' }}>
        Business Information
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={6}>
            <TextField
              name="companyName"
              label="Company Name"
              variant="outlined"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={!!formik.errors.companyName}
              disabled={formik.isSubmitting}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="email"
              label="Company Contact Email"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={!!formik.errors.email}
              disabled={formik.isSubmitting}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="phoneNumber"
              label="Company Contact Phone Number"
              variant="outlined"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={!!formik.errors.phoneNumber}
              disabled={formik.isSubmitting}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Chip label="Is VAWD?" color="primary" variant="outlined" />
            <Switch
              checked={formik.values.isVawd}
              onChange={formik.handleChange}
              color="primary"
              name="isVawd"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
