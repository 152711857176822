const AccountTypes = {
  Pharmacist: 0,
  Manufacturer: 1,
  Admin: 2,
};

const accountTypeToText = (accountType: number): string => {
  if (accountType === AccountTypes.Pharmacist) {
    return 'PHARMACIST';
  }
  if (accountType === AccountTypes.Manufacturer) {
    return 'MANUFACTURER';
  }

  return 'ADMIN';
};

export const Account = {
  AccountTypes,
  accountTypeToText,
};
