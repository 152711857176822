import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Separator } from 'pages/seller/settings-profile/index.styles';

const Card = styled(Paper)`
  padding: 1rem;
`;

const Section = styled.section`
  margin-bottom: 1rem;
`;

const SectionHeader = styled.header`
  border-bottom: 1px solid gray;
  margin-bottom: 1rem;
  padding: 0 0.5rem 0.5rem 0.5rem;
`;

const OrderDetailsSeparator = styled(Separator)`
  margin: 0.25rem 0 1rem 0;
`;

interface IOrderDetailsProps {
  orderId: string;
  billingAddress: string;
  billingName: string;
  shippingAddress: string;
  lineItems: Array<{
    id: number;
    line: string;
    price: string;
    quantity: number;
    subtotal: string;
  }>;
  total: string;
  status: string;
  events: Array<{
    id: number;
    name: string;
    textColor: string;
    color: string;
    createdAt: string;
  }>;
  paymentMethod: string;
}

const EventContainer = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`;

const EventLine = styled.div`
  background-color: #979797;
  position: absolute;
  height: 60px;
  left: 5px;
  top: 12px;
  width: 2px;
`;

const EventDot = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 12px;
  height: 12px;
  margin: 8px 8px 0 0;
  width: 12px;
  z-index: 10;
`;

export const OrderDetails: React.FC<IOrderDetailsProps> = (props) => {
  return (
    <>
      <nav>
        <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
          <Link
            to="/orders"
            style={{ textDecoration: 'none', textTransform: 'uppercase' }}
          >
            My Purchase Orders
          </Link>{' '}
          / {props.orderId}
        </Typography>
      </nav>

      <section
        style={{
          display: 'flex',
          marginBottom: '1rem',
          marginTop: '1rem',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6" color="primary">
          Order Details
        </Typography>
      </section>

      <OrderDetailsSeparator />

      <Grid container spacing={2}>
        <Grid item xs={9} md={9}>
          <Card variant="outlined" style={{ marginBottom: '1rem' }}>
            <Typography>
              <strong>Order Number</strong>: {props.orderId}
              <div>
                <strong>Payment Method</strong>: {props.paymentMethod}
              </div>
            </Typography>
          </Card>

          <Section>
            <Card variant="outlined">
              <SectionHeader>
                <Typography>Address Information</Typography>
              </SectionHeader>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    style={{ fontWeight: 'bold' }}
                  >
                    Shipping Address
                  </Typography>
                  <Typography>{props.shippingAddress}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    style={{ fontWeight: 'bold' }}
                  >
                    Billing Address
                  </Typography>
                  <Typography>{props.billingName}</Typography>
                  <Typography>{props.billingAddress}</Typography>
                </Grid>
              </Grid>
            </Card>
          </Section>

          <Section>
            <Card variant="outlined">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Line Item</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Amount Ordered</TableCell>
                    <TableCell align="right">Subtotal</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.lineItems.map((lineItem) => (
                    <TableRow key={lineItem.id}>
                      <TableCell>{lineItem.line}</TableCell>
                      <TableCell align="right">{lineItem.price}</TableCell>
                      <TableCell align="right">{lineItem.quantity}</TableCell>
                      <TableCell align="right">{lineItem.subtotal}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
          </Section>

          <Card variant="outlined">
            <div style={{ textAlign: 'right' }}>
              <Typography>Order Total: ${props.total}</Typography>
            </div>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Section>
            <Card variant="outlined">
              <SectionHeader>
                <Typography>Event History</Typography>
              </SectionHeader>
              {props.events.map((event, index) => {
                const isLast = props.events.length - 1 === index;

                return (
                  <EventContainer key={event.id}>
                    {!isLast && <EventLine />}
                    <EventDot color={event.color} />

                    <div>
                      <Typography
                        variant="subtitle1"
                        style={{ color: event.textColor }}
                      >
                        {event.name}
                      </Typography>
                      <Typography variant="subtitle2">
                        {event.createdAt}
                      </Typography>
                    </div>
                  </EventContainer>
                );
              })}
            </Card>
          </Section>
        </Grid>
      </Grid>
    </>
  );
};
