import React from 'react';
import styled from 'styled-components';
import { flexbox, FlexboxProps, space, SpaceProps } from 'styled-system';

import { FooterBottom } from '../molecules';
import { FooterAddress } from '../molecules/FooterAddress';

type FooterProps = {
  onChangeEmail?: () => void;
  onKeypressInput?: () => void;
  onClickArrow?: () => void;
  onClickInput?: () => void;
  email?: string;
};

const Container = styled.div<FlexboxProps & SpaceProps>`
  background: #2b3647;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  ${flexbox}
  ${space}
`;

export const Footer: React.FC<FooterProps> = ({
  onChangeEmail,
  onKeypressInput,
  onClickArrow,
  onClickInput,
  email,
  ...props
}) => {
  return (
    <Container
      flexDirection={['column', 'column', 'column', 'column', 'row']}
      p={[
        '42px 25px 42px 21px',
        '42px 25px 42px 21px',
        '36px 44px 42px 44px',
        '36px 44px 42px 44px',
        '36px 44px 42px 44px',
      ]}
      {...props}
    >
      <FooterAddress
        display={['inherit', 'inherit', 'inherit', 'inherit', 'none']}
      />
      <FooterBottom />
    </Container>
  );
};

Footer.defaultProps = {
  onChangeEmail: () => {},
  onKeypressInput: () => {},
  onClickArrow: () => {},
  onClickInput: () => {},
  email: '',
};
