import React from 'react';
import styled, { css } from 'styled-components';

import { RxText } from 'rx-core';
import { SignupSellerCheck } from '../atoms/SignupSellerCheck';

type IProps = {
  text: string;
  checked?: boolean;
  onClick: (id: string) => void;
  left?: string;
};

type CustomRxTextProps = {
  checked?: boolean;
};

const Container = styled.div<{ left?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ left }) =>
    left &&
    css`
      position: absolute;
      left: ${left};
    `};
`;

const CustomSignupSellerCheck = styled(SignupSellerCheck)`
  margin-bottom: 20px;
  cursor: pointer;
`;

const CustomRxText = styled(RxText)<CustomRxTextProps>`
  margin-top: ${({ checked }) => (!checked ? `49px` : 0)};
  cursor: pointer;
`;

const SignupSellerWizardItem = ({
  text,
  checked,
  onClick,
  ...props
}: IProps): JSX.Element => {
  return (
    <Container {...props}>
      {checked && (
        <CustomSignupSellerCheck
          type="info"
          onClick={() => checked && onClick(text)}
        />
      )}
      <CustomRxText
        font={checked ? 'Roobert Regular' : 'Roboto Light'}
        size="medium"
        checked={checked}
        onClick={() => checked && onClick(text)}
      >
        {text}
      </CustomRxText>
    </Container>
  );
};

SignupSellerWizardItem.defaultProps = {
  checked: false,
};

export { SignupSellerWizardItem };
