import React from 'react';
import * as Yup from 'yup';
import { Typography, Grid, TextField, Button } from '@material-ui/core';
import axios from 'axios';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import { useToast } from 'rx-hooks';
import { useQueryClient } from 'react-query';
import { useVerifyPharmacyPaymentMethodAch } from 'rx-api';

interface IProps {
  achId: number;
  pharmacyId: number;
  onSuccess(): void;
  onClickCancel(): void;
}

export const VerifyPharmacyAchForm: React.FC<IProps> = (props) => {
  const verifyAch = useVerifyPharmacyPaymentMethodAch(
    props.pharmacyId,
    props.achId
  );
  const toast = useToast();
  const queryClient = useQueryClient();

  const verifyAchFormik = useFormik({
    initialValues: {
      deposit1: '',
      deposit2: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      deposit1: Yup.string()
        .length(6, 'Please enter a 2 decimal number')
        .required('Please enter the first deposit'),
      deposit2: Yup.string()
        .length(6, 'Please enter a 2 decimal number')
        .required('Please enter the second deposit'),
    }).required(),
    onSubmit: async (values) => {
      try {
        const deposit1 = Math.round(
          parseFloat(values.deposit1.replaceAll('$', '').trim()) * 100
        );
        const deposit2 = Math.round(
          parseFloat(values.deposit2.replaceAll('$', '').trim()) * 100
        );
        await verifyAch.mutateAsync({
          amounts: [deposit1, deposit2],
        });
        toast.onSuccessToast('ACH Account verified!');
        await queryClient.refetchQueries('get-pharmacy-payment-methods-ach', {
          active: true,
        });
        props.onSuccess();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toast.onErrorToast(`Error:  ${error.response?.data.message}`);
        } else {
          toast.onErrorToast('Could not verify ACH account!');
        }
      }
    },
  });

  return (
    <>
      <Typography style={{ marginBottom: '1rem' }}>
        Verify ACH ACcount
      </Typography>
      <form onSubmit={verifyAchFormik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputMask
              name="deposit1"
              mask="$ 0.99"
              value={verifyAchFormik.values.deposit1}
              onChange={verifyAchFormik.handleChange}
              disabled={false}
              maskChar=""
            >
              {() => (
                <TextField
                  name="deposit1"
                  variant="outlined"
                  label="Deposit 1"
                  placeholder="$ 0.99"
                  helperText={verifyAchFormik.errors.deposit1}
                  error={!!verifyAchFormik.errors.deposit1}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              )}
            </InputMask>
          </Grid>

          <Grid item xs={12}>
            <InputMask
              name="deposit2"
              mask="$ 0.99"
              value={verifyAchFormik.values.deposit2}
              onChange={verifyAchFormik.handleChange}
              disabled={false}
              maskChar=""
            >
              {() => (
                <TextField
                  name="deposit2"
                  variant="outlined"
                  label="Deposit 2"
                  placeholder="$ 0.99"
                  helperText={verifyAchFormik.errors.deposit2}
                  error={!!verifyAchFormik.errors.deposit2}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              )}
            </InputMask>
          </Grid>
        </Grid>

        <div
          style={{
            padding: '1rem 0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={props.onClickCancel}
            disabled={verifyAch.isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={verifyAch.isLoading}
          >
            Add
          </Button>
        </div>
      </form>
    </>
  );
};
