import {
  GET_ADMIN_PHARMACY_PAYMENT_METHODS_KEY,
  GET_ADMIN_PHARMACY_PAYMENT_METHODS_URL,
} from 'rx-api/api/admin/pharmacies/constants';
import { useGetRequest } from 'rx-api/utils';

export const useGetAdminPharmacyPaymentMethodList = (id: number) => {
  return useGetRequest<
    unknown,
    {
      ach: [];
      cards: {
        id: number;
        last4: string;
        brand: string;
        expMonth: number;
        expYear: number;
      }[];
    }
  >({
    url: GET_ADMIN_PHARMACY_PAYMENT_METHODS_URL(id),
    key: [GET_ADMIN_PHARMACY_PAYMENT_METHODS_KEY, id],
  });
};
