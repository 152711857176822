import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDownloadTotalProductsV2 = () => {
  const onRequest = async () => {
    return http.get(
      `${process.env.REACT_APP_EDI_BACKEND_SERVICE_URL}/products/total`,
      {
        withCredentials: true,
      }
    );
  };

  return useMutation(onRequest, {});
};
