import React from 'react';
import {
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { useToast, useShoppingCartOnline, useQuery } from 'rx-hooks';
import { AppDispatch, setCartItem } from 'rx-store';
import { GetProducts } from 'rx-domain';

import { ProductListItem } from '../molecules/ProductListItem';
import { ProductsTableLoader } from '../atoms/ProductsTableLoader';
import { ProductsTableEmpty } from '../atoms/ProductsTableEmpty';
import formatProduct from '../../../../rx-utils/gtmFormatters';

interface IProductsTableProps {
  isLoading: boolean;
  products: GetProducts.ProductItem[];
}

export const ProductsTable: React.FC<IProductsTableProps> = (props) => {
  const dispatch: AppDispatch = useDispatch();
  const { onStoreShoppingCart } = useShoppingCartOnline();
  const { onSuccessToast, onErrorToast } = useToast();
  const query = useQuery();
  const category = query.get('category');
  const isDrug = category === 'drug' || !category;

  const handleClickAdd =
    (product: GetProducts.ProductItem, units: number) => async () => {
      try {
        const shopCartId = await onStoreShoppingCart(product.id, units);

        if (!shopCartId) {
          return;
        }

        dispatch(
          setCartItem({
            count: units,
            product: { ...product, minimumOrderAmount: '' },
            id: shopCartId,
          })
        );

        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push({ ecommerce: null });
        // @ts-ignore
        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce: {
            currency: 'USD',
            value: 0,
            items: [
              {
                ...formatProduct({
                  product: product as unknown as GetProducts.ProductItem,
                  index: 0,
                  quantity: units,
                }),
              },
            ],
          },
          user_id: localStorage.getItem('_userid'),
        });

        onSuccessToast(`Added to Cart! 🛒`);
      } catch (err) {
        onErrorToast('Something goes wrong, please retry later');
      }
    };

  return (
    <Table component={Paper}>
      <TableHead style={{ fontSize: '0.8rem' }}>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>NDC</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Manufacturer</TableCell>
          <TableCell>Str</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>Exp Date</TableCell>
          <TableCell>Qty Avl</TableCell>
          <TableCell>Min Qty</TableCell>
          {isDrug && <TableCell>Is VAWD?</TableCell>}
          <TableCell colSpan={3}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody style={{ fontSize: '0.8rem' }}>
        {props.isLoading && <ProductsTableLoader />}

        {!props.isLoading && !props.products.length && <ProductsTableEmpty />}

        {!props.isLoading &&
          props.products.map((product) => (
            <ProductListItem
              key={product.id}
              data={product}
              onClickAdd={handleClickAdd}
            />
          ))}
      </TableBody>
    </Table>
  );
};
