import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { theme } from 'rx-styles';
import DownloadIcon from '../../../assets/icons/download-icon.svg';

export type ButtonDownloadProps = ButtonHTMLAttributes<HTMLButtonElement>;

const Button = styled.button`
  border: none;
  background-color: transparent;
  height: fit-content;
  border-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Text = styled.span`
  font-family: ${theme.fonts.robotoRegular};
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  padding-right: 4px;
`;

const Icon = styled.img``;

const ButtonDownload = (props: ButtonDownloadProps): JSX.Element => {
  return (
    <Button {...props}>
      <Text>Download CSV</Text>
      <Icon src={DownloadIcon} />
    </Button>
  );
};

export { ButtonDownload };
