import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';
import { BUYER_URL_V1 } from '../constants';

const getUrl = (id: number | string) =>
  `${BUYER_URL_V1}/purchase-orders/${id}/invoice`;

export const useDownloadPurchaseOrderInvoice = (id: number | string) => {
  return useMutation(
    ['buyer-download-purchase-order-invoice', id],
    () => {
      const url = getUrl(id);

      return http.get(url, {
        responseType: 'arraybuffer',
      });
    },
    {}
  );
};
