import React from 'react';
import styled from 'styled-components';

import { TransactionFileType } from 'rx-domain';

import { RRModal, RRModalProps } from 'rx-core';
import { TransactionGenerateFailed } from './TransactionGenerateFailed';
import { TransactionGenerateSuccess } from './TransactionGenerateSuccess';
import { TransactionGenerateProgress } from './TransactionGenerateProgress';

type IProps = RRModalProps & {
  type: TransactionFileType;
  percent: number;
  success: boolean;
  error: boolean;
  loading: boolean;
};

const Modal = styled(RRModal)`
  height: 198px;
  width: 568px;
`;

const Container = styled.div`
  padding: 40px 20px;
  height: 100%;
`;

const TransactionGenerateModal = ({
  loading,
  success,
  error,
  percent,
  type,
  ...props
}: IProps): JSX.Element => {
  return (
    <Modal closeButton={false} {...props}>
      <Container>
        {loading && (
          <TransactionGenerateProgress type={type} percent={percent} />
        )}
        {!loading && (
          <>
            {success && <TransactionGenerateSuccess />}
            {error && <TransactionGenerateFailed />}
          </>
        )}
      </Container>
    </Modal>
  );
};

export { TransactionGenerateModal };
