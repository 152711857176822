import React from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Grid, Tooltip, Paper } from '@material-ui/core';

import { GetProducts } from 'rx-domain';
import { formatMoney, getDateValue } from 'rx-utils';
import RxRiseRecommendedIcon from 'assets/icons/rxrise-icon.svg';
import RxRiseLogo from 'assets/logos/rxrise-gray-logo.svg';

import { ProductItemInfo } from './ProductItemInfo';
import { ProductItemActions } from './ProductItemActions';

type IProductItemProps = GetProducts.ProductItem;

const Container = styled(Paper)`
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  min-height: 14.25rem;
  padding: 1rem 1rem 0.5rem 1rem;
  position: relative;
`;

const Spacer = styled.div`
  flex: 1;
`;

const Section = styled(Grid)`
  margin-bottom: 0.5rem;
`;

const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.blue[300]};
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoRegular};
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

const Text = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoRegular};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

const RegisterAText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.blue[300]};
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoRegular};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

const ImageProduct = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ImageProductContainer = styled.figure`
  width: 100%;
  height: 16rem;
`;

const ImagePlaceholder = styled.div`
  width: 100%;
  height: 16rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Featured = styled.div`
  background-color: #ffeb94;
  border-top-left-radius: 0.5rem;
  left: 0;
  padding: 0.1rem 1rem 0.1rem 0.75rem;
  position: absolute;
  top: 0;
`;

export const FeaturedIcon = styled.img`
  display: inline-block;
  vertical-align: middle;
`;

export const FeaturedLabel = styled.p`
  display: inline-block;
  vertical-align: middle;
`;

const ProductItem: React.FC<IProductItemProps> = (props) => {
  const history = useHistory();

  const onDisplayItem = () => {
    history.push(`/product/${props.id}`);
  };

  return (
    <Container elevation={3} data-cy="product-card">
      {props.isFeatured && (
        <Featured>
          <FeaturedIcon src={RxRiseRecommendedIcon} alt="" />
          <FeaturedLabel>recommends</FeaturedLabel>
        </Featured>
      )}
      <Section
        container
        justifyContent="space-between"
        wrap="nowrap"
        style={{ marginTop: '0.8rem' }}
      >
        <Grid item xs={7} md={9} zeroMinWidth>
          <Tooltip title={props.name} arrow placement="bottom-start">
            <Title
              data-testid={`products-list-item-${props.id}`}
              onClick={onDisplayItem}
            >
              {props.name}
            </Title>
          </Tooltip>
        </Grid>
        <Grid container item xs={5} md={3} justifyContent="flex-end">
          <Text>{formatMoney(props.price)}</Text>
        </Grid>
      </Section>
      {props.ndc && (
        <Section container>
          <Grid item xs={12}>
            <Text>
              <b>NDC :</b> {props.ndc}
            </Text>
          </Grid>
        </Section>
      )}
      <Section container direction="row">
        <Grid item xs={9} zeroMinWidth>
          <Tooltip title={props.shippingName} arrow placement="bottom-start">
            <Text>
              <b>Sold by :</b> {props.shippingName}
            </Text>
          </Tooltip>
        </Grid>
        <Grid container item xs={3} justifyContent="flex-end">
          <Tooltip
            title={props.isVawd ? 'VAWD' : 'No VAWD'}
            arrow
            placement="bottom-start"
          >
            <RegisterAText>{props.isVawd ? 'VAWD' : 'No VAWD'}</RegisterAText>
          </Tooltip>
        </Grid>
      </Section>
      <Section container wrap="nowrap">
        <Grid item xs={12} zeroMinWidth>
          <Tooltip title={props.description} arrow placement="bottom-start">
            <Text>{props.description}</Text>
          </Tooltip>
        </Grid>
      </Section>
      <Section container wrap="nowrap">
        <ProductItemInfo
          title="EXP. DATE"
          content={getDateValue(props.expirationDate)}
        />
        <ProductItemInfo title="LOT NUMBER" content={props.lotNumber} />
        <ProductItemInfo title="MIN. ORDER" content={props.minimumQuantity} />
      </Section>
      <Section container wrap="nowrap">
        <ProductItemInfo title="AVAILABLE" content={props.units} />
      </Section>
      <Spacer />
      <hr />
      <ProductItemActions {...props} />
    </Container>
  );
};

export { ProductItem };
