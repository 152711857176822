import React, { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

export type TextAreaTableProps = InputHTMLAttributes<HTMLTextAreaElement> &
  LayoutProps & {
    error: boolean;
  };

const Input = styled.textarea<{ error: boolean }>`
  ${({ theme: { colors, fonts } }) => css`
    background: ${colors.white[100]};
    font-family: ${fonts.robotoLight};
    border-radius: 50px;
    border: none;
    font-size: 14px;
    height: 36px;
    padding-left: 16px;
    padding-right: 6px;
    padding-top: 6px;
    outline: none;
    resize: none;
    overflow: hidden;
    ${layout};

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${colors.gray[500]};
      font-family: ${fonts.robotoRegular};
      font-style: normal;
      font-weight: normal;
    }
  `}

  ${({ error, theme: { colors } }) =>
    error &&
    css`
      border: 1px ${colors.red[100]} solid;
    `};
`;

const TextAreaTable = ({ ...props }: TextAreaTableProps): JSX.Element => {
  return <Input {...props} />;
};

TextAreaTable.defaultProps = {
  error: false,
};

export { TextAreaTable };
