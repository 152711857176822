import React, { ButtonHTMLAttributes } from 'react';
import { layout, LayoutProps } from 'styled-system';
import styled, { css } from 'styled-components';

export type RxButtonSecondaryProps = ButtonHTMLAttributes<HTMLButtonElement> &
  LayoutProps & {
    text: string;
  };

const Button = styled.button<LayoutProps>`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    background: white;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: 1px solid ${theme.colors.blue[100]};
    color: ${theme.colors.blue[100]};
    font-size: ${theme.sizes.small};
    font-family: ${theme.fonts.robotoRegular};
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    cursor: pointer;

    width: 7.75rem;
    height: 2.625rem;

    ${layout};
  `}
`;

const RxButtonSecondary = ({
  text,
  color,
  ...props
}: RxButtonSecondaryProps): JSX.Element => {
  return <Button {...props}>{text}</Button>;
};

export { RxButtonSecondary };
