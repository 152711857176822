import React from 'react';

import { useLogin } from 'rx-contexts';

import { LoginForm } from './LoginForm';
import { VerificationForm } from './VerificationForm';

const LoginSteps = (): JSX.Element => {
  const { csrfToken, email } = useLogin();

  const show2FAView = !!csrfToken && !!email;

  return <>{!show2FAView ? <LoginForm /> : <VerificationForm />}</>;
};

export { LoginSteps };
