import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import HeaderLogoSVG from '../../../../assets/logos/header-logo.svg';

const Anchor = styled(Link)``;

const Img = styled.img`
  height: 28px;
  width: 124px;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipad')} {
      width: 84px;
      height: 22px;
    }
  `}
`;

const HeaderLogo: React.FC = () => {
  return (
    <Anchor to="/">
      <Img src={HeaderLogoSVG} />
    </Anchor>
  );
};

export { HeaderLogo };
