import styled, { css } from 'styled-components';

export const Separator = styled.hr`
  border-top: 1px solid rgba(131, 140, 165, 0.35);
  margin: 2rem 0;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h1};
    font-family: ${theme.fonts.roobertRegular};
    font-weight: 400;
    color: white;
  `}
`;
