import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { RemovePaymentMethodDialogActions } from './index.styles';

type IProps = {
  open: boolean;
  handleClose: () => void;
  action: () => void;
  disableButtons: boolean;
};

const PaymentListDialog = (props: IProps): JSX.Element => {
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>
        Are you sure you want to remove this payment method?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          This action is permanent and cannot be undone.
        </DialogContentText>
      </DialogContent>
      <RemovePaymentMethodDialogActions>
        <Button
          onClick={props.handleClose}
          color="primary"
          variant="outlined"
          disabled={props.disableButtons}
        >
          No, cancel
        </Button>
        <Button
          onClick={props.action}
          color="secondary"
          variant="outlined"
          disabled={props.disableButtons}
        >
          Yes, remove
        </Button>
      </RemovePaymentMethodDialogActions>
    </Dialog>
  );
};

export { PaymentListDialog };
