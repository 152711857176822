export namespace GetFavorites {
  export type FavoriteItem = {
    createdAt: string;
    favoriteListId: number;
    id: number;
    productId: number;
    updatedAt: string;
    product: {
      category: string;
      createdAt: string;
      deletedAt: string | null;
      description: string;
      expirationDate: string;
      form: string;
      groundShipping: string;
      id: number;
      inventoryId: number;
      lotNumber: string;
      manufacturer: string;
      minimumQuantity: number;
      name: string;
      ndc: string;
      overnightShipping: string;
      price: string;
      productId: string;
      shippingConstraints: string;
      shippingName: string;
      type: string;
      units: number;
      updatedAt: string;
    };
  };

  export type Response = {
    results: FavoriteItem[];
    pagination: {
      skip: number;
      limit: number;
      total: number;
    };
  };
}
