import React from 'react';
import styled from 'styled-components';

import { SignupPharmacistProvider } from 'rx-contexts';

import { SEO } from 'rx-core';

import { SignupBuyerHeader } from '../molecules/SignupBuyerHeader';
import { SignupBuyerForm } from '../organims/SignupBuyerForm';
import { SignupBuyerWizard } from '../molecules/SignupBuyerWizard';

const Container = styled.div``;

const SignupBuyer = (): JSX.Element => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [errorStep, setErrorStep] = React.useState<number | null>(null);

  const handleClickNext = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setActiveStep(activeStep + 1);
    setErrorStep(null);
  };

  const handleClickBack = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setActiveStep(activeStep - 1);
  };

  return (
    <SignupPharmacistProvider>
      <SEO title="RxRise | Pharmacist Sign up" />
      <SignupBuyerHeader />
      <SignupBuyerWizard activeStep={activeStep} errorStep={errorStep} />
      <Container>
        <SignupBuyerForm
          activeStep={activeStep}
          setStepError={setErrorStep}
          onClickBack={handleClickBack}
          onClickNext={handleClickNext}
        />
      </Container>
    </SignupPharmacistProvider>
  );
};

export { SignupBuyer };
