import React from 'react';

import { ShippingConfig } from 'rx-domain';

import { formatMoney } from 'rx-utils';

import {
  TableColumn,
  TableRow,
  Spacer,
  Icon,
  WarningContainer,
} from './index.styles';

type IProps = Omit<ShippingConfig, 'shippingConstraints' | 'grantTotal'> & {
  shippingConstraints?: string | null;
  type: 'ship-constraints' | 'ship-type' | 'min-amount';
};

export const ShopCartCardWarning: React.FC<IProps> = (props) => {
  return (
    <>
      <Spacer />
      <TableRow>
        {props.type === 'ship-type' && (
          <TableColumn colSpan={7}>
            <WarningContainer>
              <Icon />
              {formatMoney(props.groundShipping)} or more for free ground
              shipping. {formatMoney(props.overnightShipping)} or more for
              overnight.{' '}
            </WarningContainer>
          </TableColumn>
        )}

        {props.type === 'ship-constraints' && (
          <TableColumn colSpan={7}>
            <WarningContainer>
              <Icon />
              These products does not ship to {props.shippingConstraints}{' '}
              states. Your order might be canceled.
            </WarningContainer>
          </TableColumn>
        )}

        {props.type === 'min-amount' && (
          <TableColumn colSpan={7}>
            <WarningContainer>
              <Icon />
              You have not reach the minimum purchase amount (
              {formatMoney(props.minimumAmount)}) . Please modify amount or add
              more items from this seller.
            </WarningContainer>
          </TableColumn>
        )}
      </TableRow>
    </>
  );
};
