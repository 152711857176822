import { useMutation } from 'react-query';

import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePostAdminSaleOrdersIdPaymentStatus = (id: number | string) => {
  return useMutation(
    ['admin-mark-sale-purchase-order-as-paid', id],
    async () => {
      return http.put(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/purchase-orders/${id}/payment-status`,
        { status: 'paid' }
      );
    },
    {}
  );
};
