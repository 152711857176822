import { RootState } from 'rx-store/store';
import {
  SearchBuyerFilterType,
  SearchBuyerSortByType,
  SearchBuyerSortOrderType,
} from './reducer';

const getSearchBuyerText = (state: RootState): string =>
  state.searchBuyer.text ?? '';

const getSearchBuyerLoading = (state: RootState): boolean =>
  state.searchBuyer.loading ?? false;

const getSearchBuyerFilter = (state: RootState): SearchBuyerFilterType =>
  state.searchBuyer.searchType ?? 'name';

const getSearchBuyerSortByType = (state: RootState): SearchBuyerSortByType =>
  state.searchBuyer.sortBy ?? 'name';

const getSearchBuyerSortOrderType = (
  state: RootState
): SearchBuyerSortOrderType => state.searchBuyer.sortOrder ?? 'asc';

export {
  getSearchBuyerText,
  getSearchBuyerLoading,
  getSearchBuyerFilter,
  getSearchBuyerSortByType,
  getSearchBuyerSortOrderType,
};
