import React from 'react';
import { Button } from '@material-ui/core';

import { useManageItemsUpload } from 'rx-contexts';

import { BulkUpSelectInput } from '../BulkUpSelectInput';
import {
  Container,
  FooterActions,
  FooterSpacer,
  LabelTitle,
  Title,
} from './index.styles';

export const BulkUpSelectTypeStep: React.FC = () => {
  const {
    onSetRawCsvData,
    onSetCsvData,
    onSetUploadModalVisible,
    onSetManufacturer,
    onStepUpload,
    onSetBulkUploadType,
  } = useManageItemsUpload();

  const onCancel = () => {
    onSetCsvData([]);
    onSetRawCsvData(null);
    onSetUploadModalVisible(false);
    onSetManufacturer(null);
  };

  return (
    <Container>
      <Title>What would you like to upload?</Title>
      <LabelTitle>Select what would you like to upload</LabelTitle>
      <BulkUpSelectInput onChange={(type) => onSetBulkUploadType(type)} />
      <FooterSpacer />
      <FooterActions>
        <Button
          variant="outlined"
          onClick={onCancel}
          color="primary"
          style={{ marginRight: '1rem' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onStepUpload('CHOOSE')}
        >
          Next
        </Button>
      </FooterActions>
    </Container>
  );
};
