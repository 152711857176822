import React from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';

import { SEO, TemplateView } from 'rx-core';

import { EditDrugValidator } from 'validations/EditDrugValidator';
import { AddProductActions } from 'pages/general/add-product/AddProductActions';
import { AddProductForm } from 'pages/general/add-product/AddProductForm';
import { useSellerEditProduct, useToast } from 'rx-hooks';
import { ProductPricingTierDeleteModal } from 'pages/general/ProductPricingTier/ProductPricingTierDeleteModal';
import { ProductPricingTierList } from 'pages/general/ProductPricingTier/ProductPricingTierList';
import { Card, Divider, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { AddPricingTierForm } from 'pages/general/add-product/AddPricingTierForm';
import {
  useAddInventoryProductPricingTier,
  useDeleteInventoryProductPricingTier,
  useGetInventoryProductPricingTiers,
} from 'rx-api';

export const SellerEditProductView: React.FC = () => {
  const { initialValues, isLoading, onSubmit } = useSellerEditProduct();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [selectedPricingTierId, setSelectedPricingTierId] = React.useState(0);
  const { id = '0' } = useParams<{ id?: string }>();
  const history = useHistory();
  const toast = useToast();
  const {
    isLoading: isLoadingTiers,
    data,
    refetch,
  } = useGetInventoryProductPricingTiers(parseInt(id, 10));
  const useDeletePricingTier = useDeleteInventoryProductPricingTier(
    parseInt(id, 10),
    selectedPricingTierId
  );

  const productFormik = useFormik({
    initialValues: {
      ndc: initialValues.ndc || '',
      name: initialValues.name || '',
      description: initialValues.description || '',
      manufacturer: initialValues.manufacturer || '',
      price: initialValues.price || 0,
      expirationDate: initialValues.expirationDate || '',
      lotNumber: initialValues.lotNumber || '',
      units: initialValues.units || 0,
      minimumQuantity: initialValues.minimumQuantity || 0,
      maximumQuantity: initialValues.maximumQuantity || null,
      productId: initialValues.productId || '',
      categoryId: initialValues.categoryId || 1,
      isVawd: false,
    },
    validationSchema: EditDrugValidator,
    onSubmit,
    enableReinitialize: true,
  });

  const addPricingTier = useAddInventoryProductPricingTier(parseInt(id, 10));

  const pricingTierFormik = useFormik({
    initialValues: {
      minAmount: 1,
      maxAmount: 2,
      price: `${initialValues?.price}` ?? '1.00',
      logicalOperator: 'between',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        const payload = ((pricingTier: {
          price: string;
          logicalOperator: string;
          minAmount: number | null;
          maxAmount: number | null;
        }) => {
          if (pricingTier.logicalOperator === 'less_than') {
            return { ...pricingTier, minAmount: null };
          }

          if (pricingTier.logicalOperator === 'more_than') {
            return { ...pricingTier, maxAmount: null };
          }

          return pricingTier;
        })(values);

        await addPricingTier.mutateAsync(
          payload as {
            price: string;
            logicalOperator: string;
            minAmount: number | null;
            maxAmount: number | null;
          }
        );
        toast.onSuccessToast('Pricing tier added!');
        formikHelpers.setFieldValue('minAmount', 0);
        formikHelpers.setFieldValue('minAmount', 0);
        formikHelpers.setFieldValue('logicalOperator', 'between');
        refetch();
      } catch (err) {
        toast.onErrorToast('Adding pricing tier failed!');
      }
    },
    enableReinitialize: true,
  });

  const handleCloseModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedPricingTierId(0);
  };

  const handleClickRemove = (pricingTierId: number) => () => {
    setIsDeleteModalOpen(true);
    setSelectedPricingTierId(pricingTierId);
  };

  const handleClickConfirmDelete = async () => {
    try {
      await useDeletePricingTier.mutateAsync({});
      toast.onSuccessToast('Pricing tier removed');
      refetch();
      setIsDeleteModalOpen(false);
    } catch (err) {
      toast.onErrorToast('Could not delete pricing tier');
    }
  };

  return (
    <TemplateView>
      <SEO title="RxRise | Edit Product" />

      <form onSubmit={productFormik.handleSubmit}>
        <div>
          <AddProductActions isEdit={true} productName={initialValues.name} />
          <AddProductForm
            isLoading={false}
            errors={
              productFormik.errors as {
                ndc?: string;
                name?: string;
                description?: string;
                manufacturer?: string;
                price?: string;
                expirationDate?: string;
                lotNumber?: string;
                units?: string;
                minimumQuantity?: string;
                productId?: string;
              }
            }
            onChange={productFormik.handleChange}
            onClickCancel={() => history.push('/')}
            isDisabled={productFormik.isSubmitting || isLoading}
            values={productFormik.values}
          />
        </div>
      </form>

      <Card
        variant="outlined"
        style={{ padding: '1rem', marginBottom: '1rem' }}
      >
        <Typography variant="h6">Pricing Tiers</Typography>

        {!isLoadingTiers && (
          <ProductPricingTierList
            productId={id}
            data={
              data as {
                id: number;
                minAmount: number;
                maxAmount: number;
                price: number;
                logicalOperator: string;
              }[]
            }
            onClickRemove={handleClickRemove}
          />
        )}

        {isLoadingTiers && (
          <>
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
          </>
        )}

        <Divider style={{ margin: '1rem 0' }} />

        <AddPricingTierForm
          onSubmit={pricingTierFormik.handleSubmit}
          values={pricingTierFormik.values}
          errors={pricingTierFormik.errors}
          onChange={pricingTierFormik.handleChange}
          isDisabled={pricingTierFormik.isSubmitting}
        />

        <ProductPricingTierDeleteModal
          open={isDeleteModalOpen}
          onClickConfirm={handleClickConfirmDelete}
          onClose={handleCloseModal}
        />
      </Card>
    </TemplateView>
  );
};
