import React from 'react';
import styled from 'styled-components';

const Line = styled.hr`
  position: absolute;
  top: 12px;
  left: 52px;
  width: 720px;

  z-index: -2;
  border-color: #eceff2;
`;

const SignupSellerWizardLine = (): JSX.Element => {
  return <Line />;
};

export { SignupSellerWizardLine };
