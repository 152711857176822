import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export type ButtonTextProps = HTMLAttributes<HTMLSpanElement>;

const Text = styled.span`
  font-size: 18px;
  color: white;
  cursor: pointer;
`;

const ButtonText = (props: ButtonTextProps): JSX.Element => {
  return <Text {...props} />;
};

export { ButtonText };
