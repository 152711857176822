import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';
import { ADMIN_URL_V1 } from '../constants';

const getUrl = (orderId: number | string): string =>
  `${ADMIN_URL_V1}/orders/${orderId}/invoice`;

export const useAdminDownloadConsolidatedOrderInvoice = (
  orderId: number | string
) => {
  return useMutation(
    ['admin-download-consolidated-order-invoice', orderId],
    () => {
      const url = getUrl(orderId);
      return http.get(url, { responseType: 'arraybuffer' });
    },
    { retry: false }
  );
};
