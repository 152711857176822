import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

const BackLink = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: ${theme.colors.blue[100]};
    text-decoration: none;
    margin-bottom: 1rem;
  `}
`;

const Icon = styled(ArrowBackIcon)`
  margin-right: 1rem;
`;

const ManufacturerBackList = (): JSX.Element => {
  return (
    <BackLink to="/manufacturers">
      <Icon />
      Return to list of Manufacturers
    </BackLink>
  );
};

export { ManufacturerBackList };
