import React from 'react';
import { TableRow, TableCell, Typography } from '@material-ui/core';
import JCBCardIcon from 'assets/icons/empty-icon.svg';

export const ProductsTableEmpty: React.FC = () => {
  return (
    <>
      <TableRow>
        <TableCell colSpan={12} align="center">
          <img src={JCBCardIcon} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={12} align="center">
          <Typography variant="h6">
            There are no products added yet. <br />
            Please come back soon or choose another tab
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};
