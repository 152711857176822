import { useQuery } from 'react-query';
import { IGetAddressShippingDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetAddressShipping = () => {
  const onRequest = async () => {
    return http.get<IGetAddressShippingDTO>(
      `${
        process.env.REACT_APP_WEBSITE_URL || process.env.API_SERVER
      }/v1/address/shipping`,
      {
        withCredentials: true,
      }
    );
  };

  return useQuery(['get-pharmacist-shipping-address'], () => onRequest(), {});
};
