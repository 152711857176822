import { useQuery } from 'react-query';

import { IGetManufacturerIdResponse } from 'rx-domain';
import { http } from 'rx-api/http/repository';

type IRequest = {
  id: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGetManufacturerId = ({ id }: IRequest) => {
  const onRequest = async (req: IRequest) => {
    return http.get<IGetManufacturerIdResponse>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/manufacturers/${req.id}`,
      { withCredentials: true }
    );
  };

  return useQuery(['get-admin-manufacturer-id', id], () => onRequest({ id }));
};

export { useGetManufacturerId };
