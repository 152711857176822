import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { GetSellers } from 'rx-domain';

import { SellerListItem } from '../SellerListItem';
import { SellerSendAPIKey } from '../SellerSendAPIKey/SellerSendAPIKey';

type IProps = {
  items: GetSellers.GetManufacturer[];
};

export const SellerList: React.FC<IProps> = (props) => {
  const [sellerId, setSellerId] = useState(0);
  const [sendAPIKeyModal, setSendAPIKeyModal] = useState(false);

  const handleClickClose = () => {
    setSendAPIKeyModal(false);
  };

  return (
    <div>
      <SellerSendAPIKey
        sellerId={sellerId}
        visible={sendAPIKeyModal}
        onClose={handleClickClose}
      />

      <Paper variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Manufacturer Name</TableCell>
              <TableCell>Company Phone</TableCell>
              <TableCell>Company Email</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>City</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Zip Code</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.items.map((item) => (
              <SellerListItem
                key={item.id}
                seller={item}
                onSelectSeller={(id: number) => {
                  setSellerId(id);
                  setSendAPIKeyModal(true);
                }}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};
