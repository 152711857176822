import { useRxMutation } from 'rx-api/utils';

const SELLER_UPDATE_PRODUCT_URL = (id: string) =>
  `${process.env.REACT_APP_WEBSITE_URL}/v2/inventory/products/${id}`;

type UpdateProductByIdRequestPaylaodDto = {
  category: string;
  ndc: string;
  name: string;
  description: string;
  manufacturer: string;
  expirationDate: string;
  price: number;
  units: number;
  lotNumber: string;
  minimumQuantity: number;
  productId: string;
};

export const useUpdateProductById = (id: string) => {
  const url = SELLER_UPDATE_PRODUCT_URL(id);

  return useRxMutation<UpdateProductByIdRequestPaylaodDto, unknown>({
    method: 'put',
    url,
  });
};
