import * as Yup from 'yup';

import { address, city, state, zipCode } from '../../common.validator';

export const ManufacturerBusinessInfoSchema = Yup.object().shape({
  name: Yup.string().min(1).max(255).required(),
  address,
  city,
  state,
  zipCode,
});
