import styled from 'styled-components';

export const Container = styled.ul`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const Spacer = styled.div`
  flex: 1;
`;
