import { GetProducts } from '../rx-domain';

type IProps = {
  product: GetProducts.ProductItem;
  index: number;
  quantity?: number;
};

const formatProduct = ({ product, index, quantity }: IProps) => {
  return {
    item_id: `${product.productId}${product.ndc}`,
    item_name: product.name,
    affiliation: product.manufacturer,
    // coupon: 'SUMMER_FUN',
    // discount: 2.22,
    index: index,
    item_category: product.category,
    // item_category2: 'Adult',
    // item_category3: 'Shirts',
    // item_category4: 'Crew',
    // item_category5: 'Short sleeve',
    // item_list_id: 'related_products',
    // item_list_name: 'Related Products',
    // item_variant: 'green',
    // location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
    price: 0,
    quantity: quantity || product.units,
  };
};

export default formatProduct;
