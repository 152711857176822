import { useMutation } from 'react-query';

import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePutAdminManufacturerBillingInformation = () => {
  const onRequest = async ({
    id,
    ...request
  }: {
    id: string | number;
    name: string;
    address: { address: string; city: string; state: string; zipCode: string };
  }) => {
    return http.put(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/manufacturers/${id}/billing`,
      request
    );
  };

  return useMutation(onRequest, {});
};
