import React from 'react';
import styled, { css } from 'styled-components';
import XLSX from 'xlsx';

import { useManageItemsUpload } from 'rx-contexts';
import { Button } from '@material-ui/core';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoLight};
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(43, 54, 71, 0.35);
  `}
`;

const Text = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoLight};
    margin: 1rem 0;
  `}
`;

const RulesContainer = styled.ul`
  margin-left: 2rem;
`;

const RulesItem = styled.li`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoLight};
    margin-bottom: 0.25rem;
  `}
`;

const DonwloadLink = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.blue[100]};
    cursor: pointer;
  `}
`;

const FooterSpacer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const CsvUploadError: React.FC = () => {
  const { onStepUpload, onSetUploadModalVisible, onSetRawCsvData } =
    useManageItemsUpload();

  const onRetry = () => {
    onStepUpload('CHOOSE');
    onSetRawCsvData(null);
  };

  const onDownloadLink = () => {
    const data = [
      {
        ndc: '51293-607-01',
        name: 'CHLORDIAZEPOXIDE HYDROCHLORIDE AND CLIDINIUM BROMIDE',
        description: '5mg-2.5mg 100 count',
        manufacturerName: 'ECI Pharmaceuticals',
        price: 32.0,
        expirationDate: '10/20/2030',
        lotNumber: '1901-26',
        units: 1000,
        minimumQuantity: 1,
        groundShipping: 1,
        overnightShipping: 1,
        shippingConstraints: 'AL, AK',
        productId: '',
      },
    ];

    const ws = XLSX.utils.json_to_sheet(data);
    const csv = XLSX.utils.sheet_to_csv(ws);

    const blob = new Blob([csv], { type: 'text/plain;charset=UTF-8' });

    const csvBlob = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = csvBlob;
    link.download = 'TEMPLATE.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      <Title>Error, verify the CSV file</Title>
      <Text>
        Please verify information of the <b>CSV file.</b> Here are some
        recommendations.
      </Text>
      <RulesContainer>
        <RulesItem>
          Make sure headers match to current RxRise marketplace file.
        </RulesItem>
        <RulesItem>
          If you are not sure about our file headers{' '}
          <DonwloadLink onClick={onDownloadLink}>click here</DonwloadLink> to
          download our template.
        </RulesItem>
        <RulesItem>Don’t include special characters on file.</RulesItem>
        <RulesItem>
          You can leave blank the column for <b>productId</b>
        </RulesItem>
      </RulesContainer>
      <FooterSpacer>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => onSetUploadModalVisible(false)}
          style={{ marginRight: '1rem' }}
        >
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={onRetry}>
          Start Again
        </Button>
      </FooterSpacer>
    </Container>
  );
};
