import { Grid } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const MainContainer = styled(Grid)`
  flex: 1;
`;

export const Container = styled(Grid)`
  background: #ffffff;
  opacity: 0.8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  margin: 2.5rem 0;
  height: 100%;
  padding: 1rem;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.green[100]};
    margin-bottom: 2rem;
    font-size: ${theme.sizes.h2Big};
    font-family: ${theme.fonts.roobertRegular};
  `}
`;

export const OrderNumberText = styled.h3`
  ${({ theme }) => css`
    margin-bottom: 1rem;
    letter-spacing: 0.1rem;
    font-size: ${theme.sizes.h2};
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    margin-bottom: 1.5rem;
    font-size: ${theme.sizes.medium};
  `}
`;

export const ShipAddressContainer = styled.div`
  display: flex;
  margin-top: 1rem;

  & > p {
    margin-right: 1rem;
  }
`;

export const Button = styled.button`
  ${({ theme }) => css`
    border: none;
    color: ${theme.colors.blue[100]};
    font-size: ${theme.sizes.medium};
    background-color: white;
    cursor: pointer;
    margin-bottom: 1rem;
  `}
`;

export const SubtotalContainer = styled.div`
  margin-top: 1rem;
`;

export const CheckoutSaleOrder = styled.section`
  margin-top: 1.5rem;
`;
