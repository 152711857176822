import React from 'react';
import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';

import { RxInputText, RRSelect as RxSelect, RxCheckbox } from 'rx-core';
import { SignupBuyerForm } from 'rx-domain';

const Container = styled.div``;

const PharmacyTitleSection = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoRegular};
    font-weight: 400;
    margin-bottom: 1.5rem;

    ${theme.lessThan('ipad')} {
      padding: 0 1.5rem;
    }
  `}
`;

const GridResponsive = styled(Grid)`
  ${({ theme }) => css`
    ${theme.lessThan('ipad')} {
      &.MuiGrid-spacing-xs-2 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }

      &.MuiGrid-spacing-xs-4 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }
    }
  `}
`;

const Checkbox = styled(RxCheckbox)`
  margin-bottom: 1rem;
`;

const CheckboxText = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoLight};
    font-size: ${theme.sizes.small};
  `}
`;

const CheckboxLink = styled(Link)`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoMedium};
    text-decoration: underline;
    color: black;
  `}
`;

const CheckboxTextStyled = styled(CheckboxText)<{ isChecked: boolean }>`
  ${({ isChecked }) =>
    isChecked
      ? css`
          background-color: white;
          color: black;
        `
      : css`
          background-color: white;
          color: red;
        `}
`;

const CheckboxTextLinkStyled = styled(CheckboxLink)<{ isChecked: boolean }>`
  ${({ isChecked }) =>
    isChecked
      ? css`
          background-color: white;
          color: black;
        `
      : css`
          background-color: white;
          color: red;
        `}
`;

const SignupBuyerAboutUsForm = (): JSX.Element => {
  const { values } = useFormikContext<SignupBuyerForm>();

  return (
    <Container>
      <PharmacyTitleSection>How did you hear about us?</PharmacyTitleSection>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxSelect
            name="others.howDidYouHearAboutUs"
            label="How did you hear about us? *"
            items={[
              {
                value: '',
                name: 'Please Select',
              },
              {
                value: 'Linkedin',
                name: 'Linkedin',
              },
              {
                value: 'Twitter',
                name: 'Twitter',
              },
              {
                value: 'Facebook',
                name: 'Facebook',
              },
              {
                value: 'Instagram',
                name: 'Instagram',
              },
              {
                value: 'SaleRep',
                name: 'Sales Representative',
              },
              {
                value: 'Other',
                name: 'Other',
              },
            ]}
            upperLabel={false}
          />
        </Grid>
        {values?.others.howDidYouHearAboutUs === 'SaleRep' && (
          <Grid item xs={12} sm={6}>
            <RxInputText
              name="others.saleRep"
              label="Sales Representative Name *"
              placeholder=""
              upperLabel={false}
            />
          </Grid>
        )}
      </GridResponsive>
      <GridResponsive container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <Checkbox
            name="others.subscribeToMailingList"
            text={
              <CheckboxText>
                I would like to have exclusive news and research sent to my
                inbox.
              </CheckboxText>
            }
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <Checkbox
            name="others.acceptTOS"
            text={
              <CheckboxTextStyled isChecked={values.others.acceptTOS}>
                I agree to the RxRise{' '}
                <CheckboxTextLinkStyled
                  isChecked={values.others.acceptTOS}
                  to="/legal/end-user-agreement"
                  target="_blank"
                >
                  Terms and Conditions & Privacy Policy*
                </CheckboxTextLinkStyled>
              </CheckboxTextStyled>
            }
          />
        </Grid>
      </GridResponsive>
    </Container>
  );
};

export { SignupBuyerAboutUsForm };
