import moment from 'moment';
import numeral from 'numeral';
import { is } from 'ramda';

export const formatMoney = (money: string | number): string => {
  return numeral(money).format('$0,0.00');
};

export const formatQuantity = (quantity: string | number): string => {
  return numeral(quantity).format('0,0');
};

export const formatDate = (str: string, format = 'MM/DD/YYYY'): string =>
  moment(str).format(format);

export const capitalize = (s: string): string => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
};

export const descriptionFormat = (description: string): string => {
  if (!description) return '';

  const withoutComas = description.split(',')[0];

  return withoutComas.split('(')[0];
};

export const makeStringWithCommas = (gracePeriods: number[] = []) => {
  if (gracePeriods.length === 1) return gracePeriods[0];
  const firsts = gracePeriods.slice(0, gracePeriods.length - 1);
  const last = gracePeriods[gracePeriods.length - 1];
  return `${firsts.join(', ')} and ${last}`;
};

export const getDateValue = (
  expirationDate: string,
  normalize = false
): string => {
  if (!is(Date, expirationDate)) {
    const date = expirationDate as string;

    if (
      date.trim().toLowerCase() === 'various' ||
      date.trim().toLowerCase() === 'n/a'
    )
      return 'Various';
  }

  if (normalize) return moment(expirationDate).format('YYYY-MM-DD');

  return formatDate(expirationDate);
};

export const clearPhonenumber = (phoneNumber: string): string => {
  return phoneNumber
    .replace(/\(+/g, '')
    .replace(/\)+/g, '')
    .replace(/-+/g, '')
    .replace(/\s+/g, '');
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  const splitNumber = phoneNumber.split('');

  return `(${splitNumber[0]}${splitNumber[1]}${splitNumber[2]}) ${splitNumber[3]}${splitNumber[4]}${splitNumber[5]}-${splitNumber[6]}${splitNumber[7]}${splitNumber[8]}${splitNumber[9]}`;
};

export const getRequestData = (variables: unknown): unknown => {
  if (is(FormData, variables)) {
    const request: { [key: string]: string } = {};

    (variables as FormData).forEach((value, key) => {
      request[key] = value.toString();
    });

    return request;
  }

  return variables;
};
