import * as React from 'react';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { useUpdatePharmacyShippingAddress } from 'rx-api';
import { useToast } from 'rx-hooks';
import { useFormik } from 'formik';
import { US_STATES_WITH_CODES } from 'rx-utils';

export interface IProps {
  shippingAddress: {
    address: string;
    city: string;
    state: string;
    zipCode: string;
  };
}

export interface IForm {
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

const validationSchema = yup.object({
  address: yup.string().required('Street Address is required'),
  city: yup.string().required('City is required'),
  state: yup
    .string()
    .length(2, 'Please use 2 digit format')
    .required('State is required'),
  zipCode: yup
    .string()
    .min(5, 'Invalid zip code')
    .max(6, 'Invalid zip code')
    .required('Zip Code is required'),
});

export const PharmacyProfileShippingAddress: React.FC<IProps> = (props) => {
  const { id = '0' } = useParams<{ id: string }>();
  const { onErrorToast, onSuccessToast } = useToast();
  const doUpdateShippingInformation = useUpdatePharmacyShippingAddress(
    parseInt(id, 10)
  );

  const formik = useFormik({
    initialValues: {
      address: props.shippingAddress?.address,
      state: props.shippingAddress?.state,
      city: props.shippingAddress?.city,
      zipCode: props.shippingAddress?.zipCode,
    },
    validationSchema,
    onSubmit: async (values: IForm) => {
      try {
        await doUpdateShippingInformation.mutateAsync(values);
        onSuccessToast('Shipping Address Updated');
      } catch (err) {
        onErrorToast('Could not update shipping address');
      }
    },
  });

  return (
    <section>
      <header style={{ marginBottom: '1rem' }}>
        <Typography variant="h5">Shipping Address</Typography>
      </header>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              placeholder="Street Address"
              name="address"
              variant="outlined"
              label="Street Address"
              id="address"
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} style={{ marginTop: '1rem' }}>
            <TextField
              placeholder="City"
              name="city"
              variant="outlined"
              label="city"
              id="city"
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
              value={formik.values.city}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={3} style={{ marginTop: '1rem' }}>
            <TextField
              select
              name="state"
              label="State"
              variant="outlined"
              id="id"
              value={formik.values.state}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              required
            >
              {US_STATES_WITH_CODES.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={3} style={{ marginTop: '1rem' }}>
            <TextField
              placeholder="Zip Code"
              name="zipCode"
              variant="outlined"
              label="Zip Code"
              id="zipCode"
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
              value={formik.values.zipCode}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: '1rem' }}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={doUpdateShippingInformation.isLoading}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </section>
  );
};
