import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

export const BackLink = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${theme.colors.blue[100]};
    text-decoration: none;
    margin-bottom: 2rem;
  `}
`;

export const Icon = styled(ArrowBackIcon)`
  margin-right: 1rem;
`;
