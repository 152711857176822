import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

export type RxSimpleCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  text: React.ReactNode;
  name: string;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 36px;
`;

const Text = styled.span`
  font-size: ${({ theme }) => theme.sizes.medium};
  font-family: ${({ theme }) => theme.fonts.robotoLight};
`;

const Checkbox = styled.input`
  margin-right: 12px;
  height: 20px;
  width: 20px;
`;

const RxSimpleCheckbox = ({
  text,
  className,
  ...props
}: RxSimpleCheckboxProps): JSX.Element => {
  return (
    <Container className={className}>
      <Checkbox type="checkbox" {...props} />
      <Text>{text}</Text>
    </Container>
  );
};

export { RxSimpleCheckbox };
