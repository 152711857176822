import React, { createContext, FC, useContext, useState } from 'react';

type EditionMode = 'READ' | 'EDIT';
type EditionType = 'ACCOUNT' | 'BUSINESS' | 'BILLING' | 'FEE';

type ContextProps = {
  accountInfoMode: EditionMode;
  billingInfoMode: EditionMode;
  businessInfoMode: EditionMode;
  feeInfoMode: EditionMode;
  changeMode: (mode: EditionMode, type: EditionType) => void;
};

const ManufacturerContext = createContext<ContextProps | undefined>(undefined);

const ManufacturerProvider: FC = ({ children }) => {
  const [accountInfoMode, setAccountMode] = useState<EditionMode>('READ');
  const [billingInfoMode, setBillingInfoMode] = useState<EditionMode>('READ');
  const [businessInfoMode, setBusinessInfoMode] = useState<EditionMode>('READ');
  const [feeInfoMode, setFeeInfoMode] = useState<EditionMode>('READ');

  const onChange = (mode: EditionMode, type: EditionType) => {
    if (type === 'ACCOUNT') {
      setAccountMode(mode);
      return;
    }

    if (type === 'BUSINESS') {
      setBusinessInfoMode(mode);
      return;
    }

    if (type === 'BILLING') {
      setBillingInfoMode(mode);
    }

    if (type === 'FEE') {
      setFeeInfoMode(mode);
    }
  };

  return (
    <ManufacturerContext.Provider
      value={{
        accountInfoMode,
        billingInfoMode,
        businessInfoMode,
        feeInfoMode,
        changeMode: onChange,
      }}
    >
      {children}
    </ManufacturerContext.Provider>
  );
};

const useManufacturer = (): ContextProps => {
  const context = useContext(ManufacturerContext);

  if (context === undefined) {
    throw new Error(
      'useManufacturer must be used within a ManufacturerProvider'
    );
  }

  return context;
};

export { useManufacturer, ManufacturerProvider };
