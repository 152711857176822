import { useState } from 'react';

type Mode = 'view' | 'edit';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useViewEditMode = () => {
  const [modeDEA, setModeDEA] = useState<Mode>('view');
  const [modeState, setModeState] = useState<Mode>('view');

  const setViewModeDEA = () => setModeDEA('view');
  const setEditModeDEA = () => setModeDEA('edit');
  const setViewModeState = () => setModeState('view');
  const setEditModeState = () => setModeState('edit');

  return {
    modeDEA,
    modeState,
    setViewModeDEA,
    setEditModeDEA,
    setViewModeState,
    setEditModeState,
  };
};
