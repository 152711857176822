import { useMutation } from 'react-query';

import { http } from 'rx-api/http/repository';

type IRequest = {
  id: number;
  ndc: string;
  description: string;
  name: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePutAdminBannedProductsId = () => {
  const onRequest = async ({ id, ...request }: IRequest) => {
    return http.put(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/banned-products/${id}`,
      request
    );
  };

  return useMutation(onRequest, {});
};

export { usePutAdminBannedProductsId };
