import React from 'react';
import {
  Button,
  Chip,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useToast } from 'rx-hooks';
import { usePostAdminToggle2FASettings } from 'rx-api';
import { Link } from 'react-router-dom';

interface IProps {
  id: number;
  role: string;
  email: string;
  name: string;
  twoFactorEnabled: boolean;
  onToggle2FASettings(): void;
}

export const PharmacyProfileMemberItem: React.FC<IProps> = (props) => {
  const toast = useToast();
  const toggle2FASettings = usePostAdminToggle2FASettings(`${props.id}`);

  const handleChangeToggle = (id: number) => async () => {
    try {
      await toggle2FASettings.mutateAsync(`${id}`);
      props.onToggle2FASettings();
      toast.onSuccessToast('2FA Settings updated!');
    } catch (err) {
      toast.onErrorToast('Could not update 2FA settings, try later');
    }
  };

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary={props.name} secondary={props.email} />
        <ListItemSecondaryAction>
          <Chip label={props.role.toUpperCase()} />
          <Link
            to={`/accounts/${props.id}`}
            style={{ marginLeft: '0.5rem', textDecoration: 'none' }}
          >
            <Button variant="outlined" color="primary">
              Edit
            </Button>
          </Link>
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <ListItemText primary="Is 2FA Enabled?" />
        </ListItemIcon>
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            onClick={handleChangeToggle(props.id)}
            checked={props.twoFactorEnabled}
            disabled={toggle2FASettings.isLoading}
            color="primary"
          />
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};
