import React from 'react';
import { IconButton, Menu } from '@material-ui/core';
import { AccountCircle, AccountCircleOutlined } from '@material-ui/icons';

import { useAuth } from 'rx-hooks';

import { HeaderSettingsAdmin } from '../HeaderSettingsAdmin';
import { HeaderSettingsBuyer } from '../HeaderSettingsBuyer';
import { HeaderSettingsSeller } from '../HeaderSettingsSeller';
import { HeaderSettingsUsername } from '../HeaderSettingsUsername';

import { Container } from './index.styles';

const HeaderSettings = (): JSX.Element => {
  const { firstName, type, logout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container data-cy="navbar-settings">
      <IconButton type="button" onClick={handleClick}>
        <AccountCircleOutlined />
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <HeaderSettingsUsername username={firstName} />
        {type === 'admin' && <HeaderSettingsAdmin logout={logout} />}
        {type === 'pharmacist' && <HeaderSettingsBuyer logout={logout} />}
        {type === 'manufacturer' && <HeaderSettingsSeller logout={logout} />}
      </Menu>
    </Container>
  );
};

export { HeaderSettings };
