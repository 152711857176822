import React from 'react';
import styled, { css } from 'styled-components';

type IProps = React.ThHTMLAttributes<HTMLTableCellElement> & {
  message: React.ReactNode;
};

const Container = styled.tbody``;

const Row = styled.tr``;

const Column = styled.td`
  ${({ theme }) => css`
    padding: 5rem;
    font-size: ${theme.sizes.h2};
    text-align: center;
  `}
`;

const ProductsEmptyState = ({ message, ...props }: IProps): JSX.Element => {
  return (
    <Container>
      <Row>
        <Column {...props}>{message}</Column>
      </Row>
    </Container>
  );
};

export { ProductsEmptyState };
