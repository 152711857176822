import styled, { css } from 'styled-components';

type DetailsProps = {
  open: boolean;
};

export const Container = styled.tr<DetailsProps>`
  & > td:first-child {
    border-left-style: solid;
  }
`;

export const Column = styled.td<DetailsProps>`
  padding: 0rem 1rem;
  font-weight: 500;
  border: 1px #c7cffa solid;
  border-style: solid none;

  cursor: pointer;

  &:hover {
    background-color: rgb(199, 207, 250, 0.3);
  }

  ${({ open, theme }) =>
    open &&
    css`
      background-color: ${theme.colors.blue[500]};
      color: white;

      &:hover {
        background-color: rgb(0, 72, 213, 0.7);
      }
    `}
`;

export const DetailContainer = styled.tr`
  & > td:first-child {
    border-left-style: solid;
  }

  & > td:last-child {
    border-right-style: solid;
  }
`;

export const DetailColumn = styled.td`
  padding: 0.5rem 1rem;
  font-weight: 500;
  border: 1px #c7cffa solid;
  border-style: solid none;
`;

export const DetailsRow = styled.div<DetailsProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${({ open }) =>
    open &&
    css`
      .MuiSvgIcon-root {
        fill: white;
      }
    `}
`;
