import React from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';

import { useToast } from 'rx-hooks';

import { ManufacturerBusinessSchema } from 'utils/validators/admin/manufacturer-business.validator';

import {
  useGetManufacturerBillingId,
  usePutAdminManufacturerBillingInformation,
} from 'rx-api';

import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import { US_STATES_WITH_CODES } from 'rx-utils';

type IProps = {
  id: string;
};

const Title = styled.h2`
  padding-bottom: 24px;
`;

type IForm = {
  companyName: string;
  address: string;
  state: string;
  city: string;
  zipCode: string;
};

export const ManufacturerBillingInformation: React.FC<IProps> = (props) => {
  const { isLoading, data } = useGetManufacturerBillingId({ id: props.id });
  const updateBillingInfo = usePutAdminManufacturerBillingInformation();
  const { onSuccessToast, onErrorToast } = useToast();

  const formik = useFormik({
    initialValues: {
      companyName: data?.data.name || '',
      address: data?.data.billingAddress?.address || '',
      state: data?.data.billingAddress?.state || 'AK',
      city: data?.data.billingAddress?.city || '',
      zipCode: data?.data.billingAddress?.zipCode || '',
    },
    validationSchema: ManufacturerBusinessSchema,
    enableReinitialize: true,
    onSubmit: async (values: IForm) => {
      try {
        await updateBillingInfo.mutateAsync({
          id: props.id,
          name: values.companyName,
          address: {
            address: values.address,
            state: values.state,
            city: values.city,
            zipCode: values.zipCode,
          },
        });

        onSuccessToast('Changes saved succesfully 🚀');
      } catch (err) {
        onErrorToast('Error: Could not update information');
      }
    },
  });

  return (
    <>
      <Title>Billing Information</Title>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={6}>
            <TextField
              name="companyName"
              label="Company Name"
              variant="outlined"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={!!formik.errors.companyName}
              disabled={isLoading || updateBillingInfo.isLoading}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={3}>
            <TextField
              name="address"
              label="Address"
              variant="outlined"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={!!formik.errors.address}
              disabled={isLoading || updateBillingInfo.isLoading}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="city"
              label="City"
              variant="outlined"
              value={formik.values.city}
              error={!!formik.errors.city}
              onChange={formik.handleChange}
              disabled={isLoading || updateBillingInfo.isLoading}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={3}>
            <TextField
              name="state"
              label="State"
              variant="outlined"
              value={formik.values.state}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              required
              select
            >
              {US_STATES_WITH_CODES.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={3}>
            <TextField
              name="zipCode"
              label="Zip Code"
              variant="outlined"
              value={formik.values.zipCode}
              error={!!formik.errors.zipCode}
              onChange={formik.handleChange}
              disabled={isLoading || updateBillingInfo.isLoading}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isLoading || updateBillingInfo.isLoading}
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
