import * as Yup from 'yup';
import { validateName, validatePrice, validateExpDate } from './common';
import moment from 'moment';

const BulkProductSchema = Yup.object().shape({
  name: validateName,
  ndc: Yup.string().nullable(true).default(''),
  description: Yup.string().nullable(true).default('N/A').min(1).max(255),
  manufacturer: Yup.string().min(1).max(255).required('Manufacturer Required'),
  expirationDate: validateExpDate,
  price: validatePrice,
  units: Yup.number()
    .positive()
    .min(0, 'Units cannot be negative')
    .typeError('Units must be a number')
    .required('Units cannot be empty'),
  lotNumber: Yup.string().max(255).required('Lot Number is Required'),
  minimumQuantity: Yup.number()
    .min(0)
    .required('Minimum Quantity is Required')
    .typeError('Units must be a number')
    .test(
      'is-less-than-fieldB',
      'Minimum quantity cannot be greater than maximum quantity',
      function (value = 0) {
        const { maximumQuantity } = this.parent;

        if (
          maximumQuantity !== '' &&
          maximumQuantity !== undefined &&
          maximumQuantity !== null
        ) {
          return value <= maximumQuantity;
        }
        return true;
      }
    ),

  maximumQuantity: Yup.mixed()
    .oneOf(
      [Yup.ref('$emptyString')],
      'Maximum Quantity can only be a positive number'
    )
    .when('$emptyString', (emptyString, schema) =>
      emptyString !== ''
        ? Yup.number()
            .positive()
            .nullable()
            .min(1)
            .typeError('Maximum Quantity must be a number')
        : schema
    ),
  productId: Yup.string().min(0).max(255).nullable().notRequired(),
});

const BulkProductExpDate = Yup.object().shape({
  expirationDate: Yup.string()
    .test('is-date-or-various', 'Invalid value', (value) => {
      if (value && value.toLowerCase() === 'various') {
        return true;
      }

      const validFormats = ['MM/DD/YYYY', 'MM-DD-YYYY'];
      return validFormats.some((format) =>
        moment(value, format, true).isValid()
      );
    })
    .required('Value is required'),
});

const BulkProductsValidator = Yup.array()
  .of(BulkProductSchema)
  .min(1, 'products field must have at least 1 items')
  .required('must have one product added');

const BulkExpDateValidator = Yup.array().of(BulkProductExpDate);

const BulkProductsArrayValidator = Yup.object().shape({
  items: BulkProductsValidator,
});

export {
  BulkProductSchema,
  BulkProductsValidator,
  BulkProductsArrayValidator,
  BulkExpDateValidator,
};
