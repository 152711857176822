import React from 'react';
import styled from 'styled-components';

import { HeaderOption } from '../atoms';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8rem;
`;

const CustomHeaderOption = styled(HeaderOption)`
  padding-right: 38px;
`;

const HeaderManufacturerActions: React.FC = () => {
  return (
    <Container>
      <CustomHeaderOption to="/upload-manual-product" text="Upload items" />
      <CustomHeaderOption to="/manage-items" text="Manage Items" />
      <CustomHeaderOption to="/manage-orders" text="Manage Orders" />
    </Container>
  );
};

export { HeaderManufacturerActions };
