import { PUT_SELLER_SHIPPING_CONFIGURATION_URL } from './constants';
import { useRxMutation } from 'rx-api/utils';

export interface IPutSellerManufacturerShippingPreferencesRequestPayloadDto {
  groundShipping: string;
  overnightShipping: string;
  minimumOrderAmount: string;
  shippingConstraints: string;
}

export const usePutSellerManufacturerShippingPreferences = () => {
  return useRxMutation<
    IPutSellerManufacturerShippingPreferencesRequestPayloadDto,
    unknown
  >({
    method: 'put',
    url: PUT_SELLER_SHIPPING_CONFIGURATION_URL,
  });
};
