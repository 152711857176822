import React, { InputHTMLAttributes } from 'react';
import { LayoutProps } from 'styled-system';
import { FieldProps, getIn } from 'formik';
import MaskedInput, { Mask, MaskedInputProps } from 'react-text-mask';

import { InputTableContainer } from '../InputTable';

export type InputTableMaskFormikProps = FieldProps &
  InputHTMLAttributes<HTMLInputElement> &
  InputHTMLAttributes<HTMLTextAreaElement> &
  LayoutProps &
  MaskedInputProps & {
    className?: string;
    inputType: 'normal' | 'textarea';
    mask: Mask | ((value: string) => Mask);
  };

const InputTableMaskFormik: React.FC<InputTableMaskFormikProps> = ({
  mask,
  field: { name, ...fieldProps },
  form: { errors, touched },
  ...props
}: InputTableMaskFormikProps) => {
  const fieldError = getIn(errors, name);
  const touchedError = getIn(touched, name);

  const isError = !!fieldError && !!touchedError;

  return (
    <MaskedInput
      name={name}
      mask={mask}
      guide={false}
      {...fieldProps}
      {...props}
      render={(ref: (inputElement: HTMLInputElement) => void, renderProps) => {
        return (
          <InputTableContainer ref={ref} error={isError} {...renderProps} />
        );
      }}
    />
  );
};

InputTableMaskFormik.defaultProps = {
  className: '',
  mask: [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};

export { InputTableMaskFormik };
