import React from 'react';
import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';

interface IProps {
  open: boolean;
  id: number;
  pharmacyId: number;
  type: string;
  onCloseModal(): void;
  onClickConfirm(): void;
  disabled: boolean;
}

export const RemovePharmacyPaymentMethodModal: React.FC<IProps> = (props) => {
  return (
    <Dialog open={props.open}>
      <DialogContent style={{ paddingTop: '1rem' }}>
        <Typography variant="h5">Delete Payment Method</Typography>
        <Typography style={{ margin: '1rem 0' }}>
          This action cannot be undone. Are you sure you want to delete this
          payment method?
        </Typography>

        <div
          style={{
            padding: '1rem 0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={props.onCloseModal}
            disabled={props.disabled}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            type="submit"
            onClick={props.onClickConfirm}
            disabled={props.disabled}
          >
            Delete
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
