import { IGetManufacturerDTO } from 'rx-domain';
import { useGetRequest } from 'rx-api/utils';

type IRequest = {
  skip: number;
};

const makeParams = (req: IRequest) => {
  return `&skip=${req.skip}&limit=10`;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetAdminManufacturers = (request: IRequest) => {
  return useGetRequest<IRequest, IGetManufacturerDTO>({
    url: `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/manufacturers?approvalStatus=pending`,
    key: ['get-manufacturers', request.skip],
    params: request,
    makeParams,
  });
};
