import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.blue[500]};
    font-size: ${theme.sizes.h1};
    font-weight: 400;
    margin-bottom: 3.5rem;
  `}
`;

export const ThanksContainer = styled.div`
  text-align: center;
`;

export const ThanksTitle = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.blue[500]};
    font-size: ${theme.sizes.h2Big};
    font-family: ${theme.fonts.roobertRegular};
    margin-bottom: 2.5rem;
  `}
`;

export const ThanksText = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    text-align: center;
    margin-bottom: 1.5rem;
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    border-radius: 12px;
    border: none;
    background: #ffffff;
    border: 1px solid #5077f2;
    color: #5077f2;
    padding: 1rem;
    width: 25rem;
    cursor: pointer;
  `}
`;
