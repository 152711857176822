import styled from 'styled-components';

export const InfoColumn = styled.div`
  padding: 1rem;
  border-bottom: 1px #e9ecfe solid;
`;

export const InfoContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
`;

export const FeeContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 25%;
`;
