export const ShippingAddressFactory = (
  street: string,
  city: string,
  state: string,
  zipCode: string
): RxRise.Entities.IShippingAddress => {
  const getShippingAddress = () => {
    return `${street}, ${city}, ${state}, ${zipCode} United States`;
  };

  return { getShippingAddress };
};
