import React from 'react';
import { Button, Grid } from '@material-ui/core';

type IProps = {
  onReject(): void;
  onApprove(): void;
  disabled: boolean;
};

export const ApproveCardActions: React.FC<IProps> = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Button
          onClick={props.onReject}
          variant="outlined"
          color="secondary"
          disabled={props.disabled}
        >
          Deny
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={props.onApprove}
          variant="contained"
          color="primary"
          disabled={props.disabled}
        >
          Approve
        </Button>
      </Grid>
    </Grid>
  );
};
