import React from 'react';
import { useFormikContext } from 'formik';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { IAddProductForm } from 'rx-domain';

import { Container } from './index.styles';

export const AddProductActionButtons: React.FC = () => {
  const history = useHistory();
  const { submitForm, isSubmitting, isValid } =
    useFormikContext<IAddProductForm>();

  return (
    <Container>
      <Button
        type="button"
        variant="outlined"
        color="primary"
        style={{ marginRight: '1rem' }}
        onClick={() => history.push('/products')}
      >
        CANCEL
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={() => submitForm()}
        disabled={!isValid || isSubmitting}
        type="submit"
      >
        Save
      </Button>
    </Container>
  );
};
