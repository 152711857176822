import { GetEdiTransmitLogs } from 'rx-domain';
import { useGetRequest } from 'rx-api/utils';
import {
  GET_EDI_INVENTORY_TRANSMIT_URL,
  GET_EDI_INVENTORY_TRANSMIT_KEY,
} from 'rx-api/api/admin/logs/constants';

const makeParams = (req: GetEdiTransmitLogs.QUERY) => {
  let url = `?limit=10`;

  if (req.limit) {
    url = `?limit=${req.limit}`;
  }

  if (req.skip > 0) {
    url += `&skip=${req.skip}`;
  }

  return url;
};

export const useGetEdiInventoryTransmitLogs = (
  query: GetEdiTransmitLogs.QUERY
) => {
  return useGetRequest<GetEdiTransmitLogs.QUERY, GetEdiTransmitLogs.Response>({
    url: GET_EDI_INVENTORY_TRANSMIT_URL,
    key: [GET_EDI_INVENTORY_TRANSMIT_KEY, query.limit, query.skip],
    params: query,
    makeParams,
  });
};
