import styled from 'styled-components';
import { RxTable } from 'rx-core';

export const Container = styled.div`
  margin-top: 1rem;
`;

export const SortHeader = styled.div`
  align-content: center;
  align-items: center;
  display: flex;

  &:hover {
    cursor: pointer;
  }
`;

export const Table = styled(RxTable)`
  border-radius: 10px;
`;

export const TableBody = styled.tbody``;

export const TableColumn = styled.td`
  padding: 1rem;
  font-size: 0.875rem;
  color: black;
`;

export const TableColumnHeader = styled.th`
  text-align: left;
  padding: 1rem;
  font-size: 0.75rem;
`;

export const TableHeader = styled.thead``;

export const Spacer = styled.tr`
  padding: 0.5rem 0;
  height: 0.5rem;
`;

export const FiltersContainer = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
`;
