import styled, { css } from 'styled-components';
import { ButtonNormal, RxText, RRModal } from 'rx-core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 1.5rem;

  margin-bottom: 12px;
  box-shadow: 5px 5px 25px rgba(51, 60, 68, 0.08);
  border-radius: 10px;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('desktop')} {
      padding: 24px;
    }
  `}
`;

export const Modal = styled(RRModal)`
  background-color: transparent;
  border-radius: 10px;

  min-width: 40rem;
  width: 40rem;
  max-height: fit-content;
  height: fit-content;
`;

export const Title = styled(RxText)`
  margin-bottom: 1.5rem;
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SubmitButton = styled(ButtonNormal)`
  margin-top: 1.5rem;
`;
