import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import { Card } from '@material-ui/core';

export const CheckoutLoading: React.FC = () => {
  return (
    <>
      <Card style={{ padding: '0 1rem 1rem 1rem' }}>
        <Skeleton height={60} width={350} />
        <Skeleton height={60} />
        <Skeleton height={60} />
        <Skeleton height={60} />
        <Skeleton height={60} />
        <Skeleton height={60} width="50%" />
        <Skeleton height={60} width="50%" />
        <Skeleton height={60} />
        <Skeleton height={60} />
      </Card>
      <Card style={{ marginTop: '1rem', padding: '0 1rem 1rem 1rem' }}>
        <Skeleton height={60} />
      </Card>
    </>
  );
};
