import { useQuery } from 'react-query';

import { IGetPharmacistsDTO, UserApprovalStatus } from 'rx-domain';
import { http } from 'rx-api/http/repository';

type IRequest = {
  approvalStatus: UserApprovalStatus;
  search: string;
  skip: number;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetPharmacies = ({
  status = 'any',
  search,
  skip,
}: {
  status: UserApprovalStatus;
  search: string;
  skip: number;
}) => {
  const onRequest = async (req: IRequest) => {
    let params = '';

    if (req.search) {
      params = `&search=${req.search}`;
    }

    return http.get<{
      results: {
        id: number;
        pharmacyName: string;
        dea: string;
        stateLicense: string;
        legalDocuments: [];
        billingAddress: {
          name: string;
          address: string;
          city: string;
          state: string;
          zipCode: string;
        };
        shippingAddress: {
          address: string;
          city: string;
          state: string;
          zipCode: string;
        };
      }[];
      pagination: { skip: number; limit: number; total: number };
    }>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/pharmacies?approvalStatus=${req.approvalStatus}${params}&skip=${req.skip}&limit=10`,
      { withCredentials: true }
    );
  };

  return useQuery(['get-admin-pharmacies', status, search, skip], () =>
    onRequest({ approvalStatus: status, search, skip })
  );
};
