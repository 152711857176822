import React from 'react';
import { useOrder } from 'rx-contexts';
import {
  Modal,
  Container,
  Title,
  Text,
  Actions,
  Spacer,
  MainButton,
} from './index.styles';
import { Grid } from '@material-ui/core';
import { RxButtonSimple } from 'rx-core';
import { useToast } from 'rx-hooks';
import { usePostAdminSendOrderToEDI } from 'rx-api';

export const TransactionEDI: React.FC = () => {
  const toast = useToast();
  const { onCloseEDIModal, EDIModalVisible, cancelAction } = useOrder();
  const adminSendOrderToEDI = usePostAdminSendOrderToEDI();

  const onReturnList = () => {
    onCloseEDIModal();
  };

  const onAdminSendToEDI = async () => {
    try {
      if (!cancelAction) return;
      if (cancelAction.idCancel === null) return;

      if (cancelAction.type === 'purchaseOrder') {
        await adminSendOrderToEDI.mutateAsync({
          id: cancelAction.idCancel,
        });
      }

      onCloseEDIModal();
      toast.onSuccessToast(`Order sent to EDI`);
    } catch (err: any) {
      if (err.response) {
        toast.onErrorToast(err.response.data.message);
      } else {
        toast.onErrorToast('Error: Could not send order to EDI');
      }
    }
  };

  return (
    <Modal
      visible={EDIModalVisible}
      onClose={onCloseEDIModal}
      closeButton={false}
    >
      <Container>
        <Title>Send order to EDI?</Title>
        <Text>
          Would you like to send the purchase order PO-{cancelAction?.idCancel}{' '}
          to EDI?
        </Text>
        <Spacer />
        <Actions>
          <Grid item>
            <RxButtonSimple onClick={onReturnList} variant="outline">
              Cancel
            </RxButtonSimple>
          </Grid>
          <Grid item>
            <MainButton onClick={onAdminSendToEDI}>
              Proceed to send order
            </MainButton>
          </Grid>
        </Actions>
      </Container>
    </Modal>
  );
};
