import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

import { GRAY_LIGHT_COLOR } from 'rx-styles/colors';
import { useFormik } from 'formik';
import { useToast } from 'rx-hooks';
import { createUrlWithParams, useRxMutation } from 'rx-api/utils';
import { PreviewPicture } from '../../Ads/components/PreviewPicture';

const ModalContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
});

const ModalHeader = styled.header({
  borderBottom: `1px solid ${GRAY_LIGHT_COLOR}`,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0.5rem 0',
});

const ModalCloseButton = styled.button`
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;

const DeleteCategoryButton = styled(Button)`
  color: #ff3360;
`;

const ModalContent = styled.div({
  padding: '1.5rem 0',
});

const DeleteCategoryContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

const SelectCategoriesContainer = styled(FormControl)`
  min-width: 200px;
`;

const DangerZoneContainer = styled.div`
  border: 1px #a6a6a6 solid;
  border-radius: 6px;
  width: 100%;
  padding: 8px;
  margin: 8px;
`;

const ModalFooter = styled.footer({
  borderTop: `1px solid ${GRAY_LIGHT_COLOR}`,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1rem 0 0 0',
});

type CategoryItemDto = {
  id: number;
  text: string;
  slug: string;
  pictureUrl: string;
  productCount: number;
  createdAt: string;
  updatedAt: string;
};

interface ICategoryModalProps {
  isOpen: boolean;
  onClickCancel(): void;
  onClickDelete(): void;
  data: CategoryItemDto[] | null;
  categoryId: number;
}

const useAdminPostReassignCategory = (categoryId: number) => {
  return useRxMutation<any, unknown>({
    method: 'post',
    url: createUrlWithParams(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/products/categories/{categoryId}/reassign`,
      [
        {
          key: 'categoryId',
          value: `${categoryId}`,
        },
      ]
    ),
  });
};

const useAdminPostDeleteCategory = (id: number) => {
  return useRxMutation<any, unknown>({
    method: 'delete',
    url: createUrlWithParams(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/products/categories/{id}`,
      [
        {
          key: 'id',
          value: `${id}`,
        },
      ]
    ),
  });
};

const useAdminPutEditCategory = (id: number) => {
  return useRxMutation<any, unknown>({
    method: 'put',
    url: createUrlWithParams(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/products/categories/{id}`,
      [
        {
          key: 'id',
          value: `${id}`,
        },
      ]
    ),
  });
};

export const CategoryEditModal: React.FC<ICategoryModalProps> = (props) => {
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);
  const currentCategory = props.data?.find(
    (item) => item.id === props.categoryId
  );
  const [pictureUrl, setPictureUrl] = React.useState<
    null | string | undefined | ArrayBuffer
  >(null);
  const toast = useToast();

  useEffect(() => {
    setPictureUrl(currentCategory?.pictureUrl);
  }, [currentCategory]);
  const [reassignedCategory, setReassignedCategory] = React.useState('1');
  const reassignCategory = useAdminPostReassignCategory(
    currentCategory?.id || 0
  );
  const deleteCategory = useAdminPostDeleteCategory(currentCategory?.id || 0);
  const editCategory = useAdminPutEditCategory(currentCategory?.id || 0);

  const handleReassignedCategoryOnChange = (e: React.ChangeEvent<any>) => {
    setReassignedCategory(e.target.value);
  };

  const formik = useFormik({
    initialValues: {
      text: currentCategory?.text || '',
      slug: currentCategory?.slug || '',
      file: null,
      picture: new Blob(),
    },
    onSubmit: async (values) => {
      try {
        const data = new FormData();

        data.append('text', values.text);
        data.append('slug', values.slug);
        if (values.file) {
          data.append('picture', values.picture);
        }

        await editCategory.mutateAsync(data);

        toast.onSuccessToast('Category updated!');
        formik.resetForm({});
        props.onClickDelete();
        setPictureUrl(null);
      } catch (e) {
        formik.resetForm({});
        toast.onErrorToast('We could not update the category');
      }
    },
    enableReinitialize: true,
  });

  const handleDeleteCategoryOnClick = async () => {
    try {
      if (currentCategory?.id) {
        if (currentCategory.productCount > 0) {
          await reassignCategory.mutateAsync({
            newCategoryId: reassignedCategory,
          });
        }

        await deleteCategory.mutateAsync({});
        props.onClickDelete();
        setDeleteConfirmation(false);
      }
    } catch (e) {}
  };

  const handleReassignCategoryOnClick = async () => {
    try {
      if (currentCategory?.id) {
        if (currentCategory.productCount > 0) {
          await reassignCategory.mutateAsync({
            newCategoryId: reassignedCategory,
          });
          props.onClickDelete();
        }
      }
    } catch (e) {}
  };

  const handleDeleteConfirmationOnClick = () => {
    props.onClickCancel();
    setDeleteConfirmation(true);
  };

  return (
    <>
      <Modal open={deleteConfirmation}>
        <ModalContainer>
          <Paper variant="outlined" style={{ padding: '1rem', width: '40rem' }}>
            <ModalHeader>
              <Typography variant="h6">Erase Category?</Typography>
            </ModalHeader>
            <ModalContent>
              <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                <p> Beware! </p>
                <br />
                <br />
                <p>
                  If you want to erase the category your products will be
                  reassigned to{' '}
                  <strong>
                    {
                      props.data?.find(
                        (item) => item.id === Number(reassignedCategory || 0)
                      )?.text
                    }
                  </strong>{' '}
                  for best product management practices.
                </p>
              </Grid>
            </ModalContent>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => setDeleteConfirmation(false)}
                disabled={false}
              >
                Cancel
              </Button>
              <DeleteCategoryButton
                onClick={handleDeleteCategoryOnClick}
                disabled={false}
              >
                Delete
              </DeleteCategoryButton>
            </ModalFooter>
          </Paper>
        </ModalContainer>
      </Modal>
      <Modal open={props.isOpen}>
        <ModalContainer>
          <Paper variant="outlined" style={{ padding: '1rem', width: '40rem' }}>
            <ModalHeader>
              <Typography variant="h6">Edit Category</Typography>
              <ModalCloseButton onClick={props.onClickCancel}>
                <CloseIcon />
              </ModalCloseButton>
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
              <ModalContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          name="text"
                          label="Category ID"
                          placeholder="my_category"
                          variant="outlined"
                          size="small"
                          value={formik.values.text}
                          onChange={formik.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                      <Grid item xs={12}>
                        <TextField
                          name="slug"
                          label="Category Name"
                          placeholder="My Category"
                          variant="outlined"
                          size="small"
                          value={formik.values.slug}
                          onChange={formik.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                      <Grid item xs={12}>
                        <TextField
                          name="file"
                          type="file"
                          variant="outlined"
                          size="small"
                          value={formik.values.file}
                          onChange={(e: any) => {
                            const reader = new FileReader();

                            reader.onloadend = () => {
                              setPictureUrl(reader?.result);
                            };

                            reader.readAsDataURL(e?.currentTarget?.files[0]);

                            formik.setFieldValue('file', e?.target?.value);
                            formik.setFieldValue(
                              'picture',
                              e?.currentTarget?.files[0]
                            );
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {pictureUrl !== null && (
                    <Grid item xs={6}>
                      <Typography title="h6">
                        <strong>Preview</strong>
                      </Typography>

                      <PreviewPicture src={pictureUrl?.toString()} />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item xs={12}>
                    <Typography color={'error'}>Danger Zone</Typography>
                  </Grid>
                  <DangerZoneContainer>
                    <Grid item xs={12}>
                      <p>
                        Currently there are {currentCategory?.productCount}{' '}
                        products on this category.
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <strong>Reassign Products</strong>
                      <p>
                        Assign products inside this category into current
                        existing categories.
                      </p>
                      <br />
                      <SelectCategoriesContainer>
                        <InputLabel id="demo-simple-select-label">
                          Reassigned Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={reassignedCategory}
                          onChange={handleReassignedCategoryOnChange}
                          label="Reassigned Category"
                        >
                          {props.data?.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                              {category.slug}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectCategoriesContainer>
                    </Grid>
                    <DeleteCategoryContainer item xs={12}>
                      <DeleteCategoryButton
                        onClick={handleDeleteConfirmationOnClick}
                      >
                        Delete Category
                      </DeleteCategoryButton>
                    </DeleteCategoryContainer>
                  </DangerZoneContainer>
                </Grid>
              </ModalContent>
              <ModalFooter>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => props.onClickCancel()}
                  disabled={false}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={false}
                >
                  Save
                </Button>
              </ModalFooter>
            </form>
          </Paper>
        </ModalContainer>
      </Modal>
    </>
  );
};
