import React from 'react';

import { Container, TableColumn, TableRow } from './index.styles';

const FavoriteEmpty = (): JSX.Element => {
  return (
    <Container>
      <TableRow>
        <TableColumn colSpan={13}>
          <span>There is no favorite products stored.</span>
        </TableColumn>
      </TableRow>
    </Container>
  );
};

export { FavoriteEmpty };
