import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';
import { GET_BUYER_ORDERS_URL } from 'rx-api/api/pharmacist/orders/constants';

const useDownloadPurchaseOrders = () => {
  const onRequest = () => {
    return http.get(`${GET_BUYER_ORDERS_URL}`, {
      withCredentials: true,
      params: {
        limit: 10000,
        status: 'all',
      },
    });
  };

  return useMutation(onRequest, {});
};

export { useDownloadPurchaseOrders };
