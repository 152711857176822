import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { Container } from './index.styles';

interface IBuyerActionsProps {
  name: string;
}

const BuyerActions: React.FC<IBuyerActionsProps> = (props) => {
  return (
    <Container
      container
      item
      justifyContent="space-between"
      alignItems="center"
      style={{ marginBottom: '1rem' }}
    >
      <Grid item>
        <Typography variant="h5">{props.name}</Typography>
      </Grid>
      <Grid item>
        <Link to="/pharmacies">Return to list of buyers</Link>
      </Grid>
    </Container>
  );
};

export { BuyerActions };
