import React from 'react';
import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from '@material-ui/core';

import { ITransactionPurchaseOrder } from 'rx-domain';

import { Container } from './index.styles';

type IProps = ITransactionPurchaseOrder;

export const TransactionPODetailsProducts: React.FC<IProps> = (props) => {
  return (
    <Container>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>LINE</TableCell>
            <TableCell># OF UNITS</TableCell>
            <TableCell>SUBTOTAL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.orderItems.map((orderItem, i) => (
            <TableRow key={i}>
              <TableCell>{orderItem.line}</TableCell>
              <TableCell>{orderItem.quantity}</TableCell>
              <TableCell>{orderItem.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};
