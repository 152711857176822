import { IPostSellerEmailRecipients } from 'rx-domain';
import { POST_ADMIN_SELLER_EMAIL_RECIPIENTS } from 'rx-api/api/admin/manufacturers/constants';
import { createUrlWithParams, useRxMutation } from 'rx-api/utils';

export const usePostSellerEmailRecipients = (
  params: IPostSellerEmailRecipients.Params
) => {
  return useRxMutation<IPostSellerEmailRecipients.DTO, unknown>({
    url: createUrlWithParams(POST_ADMIN_SELLER_EMAIL_RECIPIENTS, {
      key: 'id',
      value: params.id,
    }),
    method: 'post',
  });
};
