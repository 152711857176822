import { useMutation } from 'react-query';
import { IPostOrdersCheckourDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

export type CheckoutResponse = {
  id: number;
  status: string;
  pharmacistId: number;
  shippingAddressId: number;
  dueDate: string;
  payoutDate: string;
  purchaseOrders: Array<{
    id: number;
    subtotal: string;
    status: string;
    sellerId: number;
    buyerId: number;
    shippingCarrier: string;
    trackingNumber: string;
    trackingUrl: string;
    orderItems: Array<{
      id: number;
      line: string;
      quantity: number;
      price: string;
      purchaseOrderId: number;
      updatedAt: string;
      createdAt: string;
    }>;
    orderId: number;
    updatedAt: string;
    createdAt: string;
    seller: {
      id: number;
      lastName: string;
      email: string;
    };
  }>;
  updatedAt: '2022-05-26T18:22:13.784Z';
  createdAt: '2022-05-26T18:22:13.784Z';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useOrdersCheckout = () => {
  const onRequest = async (request: IPostOrdersCheckourDTO) => {
    return http.post<CheckoutResponse>(
      `${process.env.REACT_APP_WEBSITE_URL}/v2/orders/checkout`,
      request
    );
  };

  return useMutation(onRequest, {});
};

export { useOrdersCheckout };
