import React, { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

import BellShapeUp from '../../../assets/images/random/bell-shepe-up.png';
import BellShapeDown from '../../../assets/images/random/bell-shepe-down.png';

export type ButtonShapeProps = ImgHTMLAttributes<HTMLImageElement> & {
  up?: boolean;
  down?: boolean;
};

const ShapeDown = styled.img`
  position: absolute;
  right: 30px;
  bottom: -1px;
  height: 20px;
  filter: contrast(160%);
  -webkit-filter: contrast(160%);
  cursor: default;
`;

const ShapeUp = styled(ShapeDown)`
  top: -1px;
  bottom: initial;
`;

const ButtonShape = ({ up, down, ...props }: ButtonShapeProps): JSX.Element => {
  if (up) {
    return <ShapeDown src={BellShapeDown} {...props} />;
  }

  if (down) {
    return <ShapeUp src={BellShapeUp} {...props} />;
  }

  return <div />;
};

ButtonShape.defaultProps = {
  up: false,
  down: true,
};

export { ButtonShape };
