import { useMutation } from 'react-query';

import { IPostOrderInvoiceDTO, IPostOrdersIdCancelResponse } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePostAdminOrdersIdCancel = () => {
  const onRequest = async ({ id, ...request }: IPostOrderInvoiceDTO) => {
    return http.post<IPostOrdersIdCancelResponse>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/orders/${id}/cancel`,
      request
    );
  };

  return useMutation(onRequest, {});
};

export { usePostAdminOrdersIdCancel };
