import * as Yup from 'yup';
import { validateNDC } from './common';
import { BulkProductSchema } from './BulkProductsValidator';

const BulkDrugsSchema = BulkProductSchema.shape({
  ndc: validateNDC,
});

const BulkDrugsValidator = Yup.array()
  .of(BulkDrugsSchema)
  .min(1, 'products field must have at least 1 items')
  .required('must have one product added');

const BulkDrugsArrayValidator = Yup.object().shape({
  items: BulkDrugsValidator,
});

export { BulkDrugsSchema, BulkDrugsValidator, BulkDrugsArrayValidator };
