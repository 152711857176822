import React from 'react';
import styled from 'styled-components';

import { RRModal, RRModalProps } from 'rx-core';

import { TwoFactorAuthOn } from './TwoFactorAuthOn';
import { TwoFactorAuthOff } from './TwoFactorAuthOff';

type IProps = RRModalProps & {
  type: 'ON' | 'OFF';
};

const Container = styled.div`
  height: 100%;
`;

const Modal = styled(RRModal)`
  max-height: 19.375rem;
  max-width: 35.5rem;
`;

const TwoFactorModal = ({ type, ...props }: IProps): JSX.Element => {
  return (
    <Modal {...props}>
      <Container>
        {type === 'ON' && <TwoFactorAuthOn {...props} />}
        {type === 'OFF' && <TwoFactorAuthOff {...props} />}
      </Container>
    </Modal>
  );
};

export { TwoFactorModal };
