import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

import { useField } from 'formik';

import { RxInputErrorMessage } from '../RxInputErrorMessage';

type RxInputProps = {
  label: string;
  name: string;
  infoText?: string;
};

export type RxInputFileProps = React.InputHTMLAttributes<HTMLInputElement> &
  RxInputProps;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.625rem;
`;

const LabelText = styled.label`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoRegular};
    font-size: ${theme.sizes.small};
    color: ${theme.colors.black[100]};
    line-height: 25px;
    font-weight: 400;
  `}
`;

const InputContainer = styled.div<{ error: boolean }>`
  ${({ error }) =>
    !error &&
    css`
      margin-bottom: 30px;
    `}
`;

const FakeInputText = styled.div`
  ${({ theme: { sizes, fonts, colors } }) => css`
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    line-height: 25px;
    padding: 0 24px;
    font-size: ${sizes.small};
    background: white;
    font-family: ${fonts.robotoRegular};
    border: 1px solid ${colors.gray[300]};

    display: flex;
    align-items: center;
    justify-content: space-between;

    color: rgba(0, 0, 0, 0.5);
  `}
`;

const UploadText = styled.span`
  ${({ theme: { sizes, fonts, colors } }) => css`
    font-size: ${sizes.small};
    font-family: ${fonts.robotoRegular};
    color: ${colors.blue[100]};
    cursor: pointer;
    margin-left: 0.5rem;
    white-space: nowrap;
  `}
`;

const FileNameText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FileInput = styled.input`
  display: none;
`;

const RxInputFile = (props: RxInputFileProps): JSX.Element => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const [field, meta, helpers] = useField<File>({ ...props, type: props.type });

  const hasError = !!(meta.touched && meta.error);

  const onSelectFile = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();

    const reader = new FileReader();
    const file = evt.target.files;

    if (file && file.length > 0) {
      reader.readAsDataURL(file[0]);
      helpers.setValue(file[0]);
    }
  };

  const onClickUpload = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  return (
    <Container>
      <LabelContainer>
        <LabelText htmlFor={props.label}>{props.label}</LabelText>
      </LabelContainer>
      <InputContainer error={hasError}>
        <FileInput
          {...field}
          {...props}
          type="file"
          ref={inputFileRef}
          onChange={onSelectFile}
          value={undefined}
        />
        <FakeInputText>
          <FileNameText>
            {field.value ? field.value.name : props.placeholder}
          </FileNameText>
          <UploadText onClick={onClickUpload}>Upload file</UploadText>
        </FakeInputText>
      </InputContainer>
      {hasError && <RxInputErrorMessage>{meta.error}</RxInputErrorMessage>}
    </Container>
  );
};

export { RxInputFile };
