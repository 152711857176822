import { useQuery } from 'react-query';
import { IGetPaymentMethodsCardsDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// TODO: Only works in pharmacy users
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetPharmacyPaymentMethodsCards = (
  pharmacyId: number | string
) => {
  return useQuery(
    ['get-pharmacy-payment-methods-cards', pharmacyId],
    async () => {
      return http.get<IGetPaymentMethodsCardsDTO>(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/pharmacist/pharmacies/${pharmacyId}/payment-methods/cards`,
        { withCredentials: true }
      );
    }
  );
};
