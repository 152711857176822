import React from 'react';
import { Button } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { useTransactions } from 'rx-contexts';
import { useDownloadTransactions } from 'rx-api';
import { useDownloadFile } from 'rx-hooks';
import { getDateValue } from 'rx-utils';

import { Container, Title } from './index.styles';

const TransactionActions = (): JSX.Element => {
  const { totalTransactions } = useTransactions();
  const { onDownload } = useDownloadFile();
  const downloadTransactions = useDownloadTransactions();

  const onDownloadTransactions = async () => {
    try {
      const { data } = await downloadTransactions.mutateAsync({
        limit: totalTransactions,
        compoundOrderId: '',
      });

      const currentDate = new Date();

      onDownload(
        data,
        `transactions-${getDateValue(currentDate.toUTCString(), true)}`
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <Container>
      <Title>Transactions</Title>
      {totalTransactions > 0 && (
        <Button
          color="primary"
          variant="contained"
          onClick={onDownloadTransactions}
        >
          <ArrowDownwardIcon />
          Download Transactions
        </Button>
      )}
    </Container>
  );
};

export { TransactionActions };
