import numeral from 'numeral';
import { is } from 'ramda';
import { parse, isDate } from 'date-fns';
import { PaymentMethodACH, PaymentMethodCard } from 'rx-domain';
import { capitalize } from 'rx-utils/formatters';

type ACH = PaymentMethodACH & {
  type: 'ach';
};

type Cards = PaymentMethodCard & {
  type: 'card';
};

export const parseDateString = (
  _: string,
  originalValue: string
): string | Date => {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'MM/dd/yy', new Date());

  return parsedDate;
};

export const parseDateStringFullYear = (
  _: string,
  originalValue: string
): string | Date => {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'MM/dd/yyyy', new Date());

  return parsedDate;
};

export const parseMoneyNumber = (
  _: string,
  originalValue: string | number
): string | number | null => {
  const parsedNumber = Number.isNaN(originalValue)
    ? originalValue
    : numeral(originalValue).value();

  return parsedNumber;
};

export const parserMoneyNumberWithDefault = (
  _: string,
  originalValue: string
): number => {
  const cleanNumber = numeral(originalValue).value();

  return cleanNumber ?? 0;
};

export const discountWac = (wac: number, newWac: number): string => {
  const parsedWac = parseMoneyNumber('', wac) as number;
  const parsedNewWac = parseMoneyNumber('', newWac) as number;

  const discount = 100 - (parsedNewWac * 100) / parsedWac;

  if (Number.isNaN(discount)) return '0%';
  if (discount <= 0) return '0%';

  return numeral(discount / 100).format('0%');
};

export const parseReqDate = (expirationDate: string): string => {
  if (
    !is(Date, expirationDate) &&
    (expirationDate as string).toLowerCase() === 'various'
  ) {
    return 'various';
  }

  return parse(expirationDate, 'MM/dd/yyyy', new Date()).toISOString();
};

export const getPaymentMethodName = (payment: ACH | Cards): string => {
  if (payment.type === 'ach') {
    return `${payment.bankName} - ${payment.routingNumber}`;
  }

  return `${capitalize(payment.brand)} ending in ${payment.last4}`;
};
