import React from 'react';
import styled from 'styled-components';

import { useManageItemsUpload } from 'rx-contexts';

import { useAuth } from 'rx-hooks';
import { isEmpty } from 'ramda';

type ICsvUploadSummaryProps = {
  totalItems: number;
  loading: boolean;
  missingColumns: string[];
  errors: any;
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: ${({ theme: { sizes } }) => `${sizes.h2}`};
  font-family: ${({ theme: { fonts } }) => `${fonts.robotoLight}`};

  padding-bottom: 16px;
  border-bottom: 1px solid rgb(43, 54, 71, 0.35);
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.sizes.medium};
  font-family: ${({ theme }) => theme.fonts.robotoLight};
  margin: 1rem 0;
`;

const FooterSpacer = styled.div`
  border-bottom: 1px solid rgb(43, 54, 71, 0.35);
`;

export const CsvUploadSummary: React.FC<ICsvUploadSummaryProps> = ({
  totalItems,
  loading,
  missingColumns,
  errors,
}) => {
  const { type } = useAuth();

  const { manufacturer } = useManageItemsUpload();

  const uploadingMessage =
    type === 'admin'
      ? `You are doing an import of multiple products into RxRise marketplace on behald of ${manufacturer?.manufacturer.companyName}`
      : 'You are doing an import of multiple products into RxRise marketplace.';

  return (
    <Container>
      {!loading && (
        <>
          <Title>Multiple Product Summary</Title>
          {missingColumns.length > 0 && (
            <>
              <Text>
                System could not detect the following columns:{' '}
                <b>{missingColumns.join(', ')}</b>. Some products might miss
                important information.
              </Text>
              <FooterSpacer />
            </>
          )}
          <Text>{uploadingMessage}</Text>
          <FooterSpacer />
          <Text>
            There are about {totalItems} products that will be uploaded into
            Rxrise marketplace. Would you like to continue with the upload
            process?
          </Text>
          {!isEmpty(errors) && (
            <>
              <FooterSpacer />
              <Text>The expiration date from some rows are invalid.</Text>
            </>
          )}
        </>
      )}
    </Container>
  );
};
