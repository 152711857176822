import * as Yup from 'yup';
import { firstName, address, city, state, zipCode } from './common.validator';

const SCAddBillingInformationSchema = Yup.object().shape({
  firstName,
  address,
  city,
  state,
  zipCode,
});

export { SCAddBillingInformationSchema };
