import React from 'react';
import styled from 'styled-components';

import { RRModal, RRModalProps } from 'rx-core';
import { Button, Divider, Typography } from '@material-ui/core';

type IProps = RRModalProps & {
  onDeleteProduct: () => void;
  loading: boolean;
};

const Modal = styled(RRModal)`
  max-width: 48rem;
  height: fit-content;
`;

const Container = styled.div`
  padding: 1rem;
`;

const Content = styled.p`
  margin-bottom: 3.75rem;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AdminProductsDeleteModal: React.FC<IProps> = ({
  loading,
  onDeleteProduct,
  ...props
}) => {
  return (
    <Modal closeButton={false} {...props}>
      <Container>
        <Typography variant="h6">Delete Item?</Typography>
        <Divider style={{ margin: '1rem 0' }} />
        <Content>
          Your product <b>won't be</b> available for sale in RxRise platform
          anymore!
          <br />
          <br />
          Would you like to continue?
        </Content>

        <Divider style={{ margin: '1rem 0' }} />

        <ActionContainer>
          <Button
            onClick={() => props.onClose()}
            color="primary"
            variant="contained"
            style={{ marginRight: '1rem' }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onDeleteProduct()}
            disabled={loading}
            variant="outlined"
            color="secondary"
          >
            Yes, delete
          </Button>
        </ActionContainer>
      </Container>
    </Modal>
  );
};
