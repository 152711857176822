import React from 'react';
import styled from 'styled-components';

import { Menu, MenuItem } from '@material-ui/core';

type ITransactionItem = {
  type: string;
  text: string;
  onClick: () => Promise<void> | void;
};

type IProps = {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  options: Array<ITransactionItem>;
};

const ITEM_HEIGHT = 48;

const Option = styled(MenuItem)`
  &:hover {
    color: ${({ theme }) => theme.colors.blue[100]};
  }
`;

const TransactionsOrderMenu = ({
  anchorEl,
  onClose,
  options,
}: IProps): JSX.Element => {
  const open = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onClose();
      }}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
        },
      }}
    >
      {options.map((option) => (
        <Option
          key={option.type}
          onClick={(e) => {
            e.stopPropagation();
            option.onClick();
            onClose();
          }}
        >
          {option.text}
        </Option>
      ))}
    </Menu>
  );
};

export { TransactionsOrderMenu };
