import React, { SelectHTMLAttributes } from 'react';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';
import { FieldProps } from 'formik';

import { DataState } from 'rx-domain';

import { StatusCheckIcon } from '../../icons';
import { InputTitle } from '../InputTitle';
import { InputSelect } from '../InputSelect';
import { RxInputErrorMessage } from '../RxInputErrorMessage';

interface ISelectItem {
  value: string;
  name: string;
}

export type RxSelectProps = FieldProps &
  SelectHTMLAttributes<HTMLSelectElement> &
  LayoutProps & {
    label: string;
    state?: DataState;
    className?: string;
    inputError: boolean;
    items: Array<ISelectItem>;
  };

const Container = styled.div<LayoutProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${layout}
`;

const CheckIcon = styled(StatusCheckIcon)`
  height: 28px;
  width: 28px;
  position: absolute;
  bottom: 11px;
  right: -42px;
`;

const InputContainer = styled.div<LayoutProps>`
  position: relative;
  ${layout};
`;

const CustomInputTitle = styled(InputTitle)`
  margin-bottom: 10px;
`;

const RxSelect: React.FC<RxSelectProps> = ({
  label,
  className,
  state,
  width,
  inputError,
  items,
  field: { name, ...fieldProps },
  form: { errors, touched },
  ...props
}: RxSelectProps) => {
  return (
    <Container className={className}>
      {label && (
        <CustomInputTitle
          title={label}
          htmlFor={name}
          error={state === 'error' && inputError}
        />
      )}
      <InputContainer width={width}>
        <InputSelect
          id={name}
          name={name}
          {...fieldProps}
          {...props}
          error={state === 'error' && inputError}
        >
          {items.map((item) => (
            <option value={item.value} key={item.value}>
              {item.name}
            </option>
          ))}
        </InputSelect>
        {state !== 'none' && !inputError && <CheckIcon type={state} />}
      </InputContainer>
      <RxInputErrorMessage>
        {touched[name] && errors[name] && errors[name]}
      </RxInputErrorMessage>
    </Container>
  );
};

RxSelect.defaultProps = {
  className: '',
  state: 'none',
};

export { RxSelect };
