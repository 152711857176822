import React from 'react';
import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

export type InputTextareaProps =
  React.TextareaHTMLAttributes<HTMLTextAreaElement> &
    LayoutProps & {
      error: boolean;
    };

export const InputTextareaContainer = styled.textarea<InputTextareaProps>`
  ${({ theme: { colors, fonts, sizes } }) => css`
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    line-height: 25px;
    padding: 0 24px;
    font-size: ${sizes.medium};
    font-family: ${fonts.robotoRegular};
    border: 1px solid ${colors.gray[300]};
    outline: none;
    resize: none;
    ${layout};

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${colors.gray[500]};
      font-family: ${fonts.robotoRegular};
      font-style: normal;
      font-weight: normal;
    }
  `}

  ${({ error, theme: { colors } }) =>
    error &&
    css`
      border-color: ${colors.red[200]};
      color: ${colors.red[200]};
    `};
`;

const InputTextarea = ({ ...props }: InputTextareaProps): JSX.Element => {
  return <InputTextareaContainer {...props} />;
};

export { InputTextarea };
