import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-bottom: 1rem;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h1};
    color: ${theme.colors.blue[500]};
    border-bottom: 1px solid #9fa6b9;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  `}
`;

export const Subtitle = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
  `}
`;
