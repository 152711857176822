import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { GetSaleOrderPayments, SearchQueryParams } from 'rx-domain';

import { ReportItems } from '../PaymentsReportItems';
import { ReportFooter } from '../PaymentsReportFooter';
import { ReportsTableContainer } from './ReportsTableContainer';

type IProps = {
  payments: GetSaleOrderPayments.SaleOrderPayment[] | undefined;
  meta: GetSaleOrderPayments.SaleOrderPaymentMeta | undefined;
  totalPages: number;
  totalItems: number;
  queries: SearchQueryParams;
  isLoading: boolean;
  setCurrentPage: (value: number) => void;
  currentPage: number;
  skip: number;
  handleEditRow: any;
};

export const ReportsTable: React.FC<IProps> = (props) => {
  return (
    <ReportsTableContainer>
      <Paper variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Purchase Order ID</TableCell>
              <TableCell>Seller</TableCell>
              <TableCell>Buyer</TableCell>
              <TableCell>Payment Status</TableCell>
              <TableCell>Order Amount</TableCell>
              <TableCell>RxRise Split</TableCell>
              <TableCell>Stripe Split</TableCell>
              <TableCell>Seller Split</TableCell>
              <TableCell>Order Date</TableCell>
              <TableCell>Payment Date</TableCell>
              <TableCell>Payment Method</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          {props.isLoading && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={13}>
                  <Skeleton height={40} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={13}>
                  <Skeleton height={40} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={13}>
                  <Skeleton height={40} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {!props.isLoading && props.payments && props.meta && (
            <ReportItems
              handleEditRow={props.handleEditRow}
              payments={props.payments}
              meta={props.meta}
              totalItems={props.totalItems}
            />
          )}

          <ReportFooter
            skip={props.skip}
            currentPage={props.currentPage}
            setCurrentPage={props.setCurrentPage}
            totalPages={props.totalPages}
            queries={props.queries}
            totalItems={props.totalItems}
          />
        </Table>
      </Paper>
    </ReportsTableContainer>
  );
};
