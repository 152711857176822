import { GetOrderById } from 'rx-domain';
import {
  GET_BUYER_ORDER_BY_ID_URL,
  GET_BUYER_ORDER_BY_ID_KEY,
} from 'rx-api/api/pharmacist/orders/constants';
import { useGetRequest } from 'rx-api/utils';

const useGetOrderById = (query: Partial<GetOrderById.IRequestParamsDto>) => {
  return useGetRequest<unknown, GetOrderById.IResponsePayloadDto>({
    url: GET_BUYER_ORDER_BY_ID_URL(query.id || ''),
    key: [GET_BUYER_ORDER_BY_ID_KEY, query.id],
    params: query,
  });
};

export { useGetOrderById };
