import React, { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

import LoadingIcon from '../../../assets/icons/loading-icon.svg';

export type ButtonLoadingIconProps = ImgHTMLAttributes<HTMLImageElement>;

const Icon = styled.img`
  right: 20px;
  height: 16px;
`;

const ButtonLoadingIcon = (props: ButtonLoadingIconProps): JSX.Element => {
  return <Icon src={LoadingIcon} {...props} />;
};

export { ButtonLoadingIcon };
