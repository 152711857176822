import React from 'react';

type IProps = {
  id: string;
};

const GTag: React.FunctionComponent<IProps> = (props: IProps) => {
  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${props.id}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${props.id}');`,
        }}
      />
    </>
  );
};

export { GTag };
