import styled, { css } from 'styled-components';

export const TableRow = styled.tr`
  & > td:first-child {
    padding-left: 2.5rem;
  }

  & > td:last-child {
    padding-right: 2.5rem;
  }
`;

export const TableColumnTitle = styled.td`
  ${({ theme }) => css`
    border-top: 1px solid #d6d6d6;
    padding: 5rem;
    padding-left: 0;
    font-size: ${theme.sizes.h2};
    text-align: center;
  `}
`;
