import React from 'react';
import { useHistory } from 'react-router-dom';
import { usePagination } from '@material-ui/lab/Pagination';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Button } from '@material-ui/core';
import { SearchQueryParams } from 'rx-domain';

import { onMakeURL } from 'rx-utils';

import { Container, List, PageItem, ItemButton } from './index.styles';

type IProps = {
  totalPages: number;
  queries: SearchQueryParams;
};

const OrderPagination = ({
  queries,
  totalPages,
  ...props
}: IProps): JSX.Element => {
  const history = useHistory();
  const { items } = usePagination({
    count: totalPages,
  });

  const goToPage = (page: number) => {
    const newUrl = onMakeURL({ ...queries, p: page.toString() });
    history.push(newUrl);
  };

  return (
    <Container {...props}>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <ItemButton
                type="button"
                style={{
                  fontWeight: selected ? 'bold' : undefined,
                }}
                {...item}
                onClick={(event: React.SyntheticEvent<Element, Event>) => {
                  item.onClick(event);
                  goToPage(page);
                }}
              >
                {page}
              </ItemButton>
            );
          } else if (type === 'previous') {
            children = (
              <Button
                variant="text"
                {...item}
                onClick={(event: React.SyntheticEvent<Element, Event>) => {
                  item.onClick(event);
                  goToPage(page);
                }}
              >
                <NavigateBeforeIcon />
              </Button>
            );
          } else if (type === 'next') {
            children = (
              <Button
                variant="text"
                {...item}
                onClick={(event: React.SyntheticEvent<Element, Event>) => {
                  item.onClick(event);
                  goToPage(page);
                }}
              >
                <NavigateNextIcon />
              </Button>
            );
          }

          return <PageItem key={index}>{children}</PageItem>;
        })}
      </List>
    </Container>
  );
};

export { OrderPagination };
