import React from 'react';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Chip,
  Button,
} from '@material-ui/core';
import { Account } from 'domain/entities/Account';
import { Link } from 'react-router-dom';
import { AccountListLoader } from './AccountListLoader';
import { AccountListEmpty } from './AccountListEmpty';

export const AccountAdminList: React.FC<{
  data: {
    createdAt: string;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    type: number;
  }[];
  isLoading: boolean;
}> = (props) => {
  return (
    <Paper variant="outlined" style={{ marginTop: '1rem' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.isLoading && <AccountListLoader />}

          {props.data.length === 0 && !props.isLoading && <AccountListEmpty />}

          {!props.isLoading &&
            props.data.map((account) => {
              return (
                <TableRow key={account.id}>
                  <TableCell>
                    <Typography>{account.email}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {account.firstName} {account.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      variant="outlined"
                      label={Account.accountTypeToText(account.type)}
                    />
                  </TableCell>

                  <TableCell colSpan={1}>
                    <Link to={`/accounts/${account.id}`}>
                      <Button variant="outlined" color="primary">
                        Edit
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Paper>
  );
};
