import React, { createContext, FC, useContext, useState } from 'react';
import { UserApprovalStatus } from 'rx-domain';

export type FilterContextProps<T> = {
  status: T;
  titleFilter: string;
  visibleListFilter: boolean;
  search: string;
  loading: boolean;
  lazyLoading: boolean;
  onSetFilterListVisibility: () => void;
  onSetStatus: (value: T[keyof T], title: string) => void;
  onSearch: (value: string) => void;
  onSetLoading: (state: boolean) => void;
  onSetLazyLoading: (state: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FilterContext = createContext<FilterContextProps<any>>({
  status: 'any',
  titleFilter: 'All',
  visibleListFilter: false,
  search: '',
  loading: false,
  lazyLoading: false,
  onSetStatus: () => {},
  onSetFilterListVisibility: () => {},
  onSearch: () => {},
  onSetLoading: () => {},
  onSetLazyLoading: () => {},
});

const FilterProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [lazyLoading, setLazyLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [status, setStatus] = useState<UserApprovalStatus>('any');
  const [titleFilter, setTitleFilter] = useState<string>('All');
  const [visibleListFilter, setVisibleListFilter] = useState<boolean>(false);

  const onSetStatus = (value: UserApprovalStatus, title: string) => {
    setStatus(value);
    setTitleFilter(title);
  };

  const onSetFilterListVisibility = () => {
    setVisibleListFilter((val) => !val);
  };

  const onSearch = (value: string) => {
    setSearch(value);
  };

  const onSetLoading = (state: boolean) => {
    setLoading(state);
  };

  const onSetLazyLoading = (state: boolean) => {
    setLazyLoading(state);
  };

  return (
    <FilterContext.Provider
      value={{
        status,
        onSetStatus,
        visibleListFilter,
        onSetFilterListVisibility,
        titleFilter,
        search,
        onSearch,
        loading,
        onSetLoading,
        lazyLoading,
        onSetLazyLoading,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

const useFilter = <T extends string>(): FilterContextProps<T> => {
  const context = useContext<FilterContextProps<T>>(FilterContext);

  if (context === undefined) {
    throw new Error('useFilter must be used within a FilterProvider');
  }

  return context;
};

export { useFilter, FilterProvider };
