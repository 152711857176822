import React from 'react';
import { useParams } from 'react-router-dom';
import { SEO, TemplateView } from 'rx-core';
import { useGetPharmacy, useGetPharmacyPaymentMethods } from 'rx-api';
import { Paper, Grid, Divider } from '@material-ui/core';
import { AddPaymentProvider } from 'rx-contexts';

import { BuyerActions } from './PharmacyProfileActions';
import { Container } from './index.styles';
import { PharmacyProfileLoader } from './PharmacyProfileLoader';
import { PharmacyProfileLegalDocuments } from './PharmacyProfileLegalDocuments';
import { PharmacyProfilePaymentMethods } from './PharmacyProfilePaymentMethods';
import { PharmacyProfilePaymentTerms } from './PharmacyProfilePaymentTerms';
import { PharmacyProfileInfo } from './PharmacyProfileInfo';
import { PharmacyProfileShippingAddress } from './PharmacyProfileShippingAddress';
import { PharmacyProfileMemberList } from './PharmacyProfileMemberList/PharmacyProfileMemberList';
import { PharmacyDangerZone } from './PharmacyDangerZone';
import { PharmacyProfileBillingInformation } from './PharmacyProfileBillingInformation';

export const PharmacyProfileView: React.FC = () => {
  const query = useParams<{ id: string }>();

  const { data: pharmacy, isLoading, refetch } = useGetPharmacy(query.id ?? '');

  const { data: paymentMethods } = useGetPharmacyPaymentMethods({
    id: query.id ?? '',
  });

  return (
    <TemplateView>
      <SEO title="RxRise | Pharmacy" />
      <Container container direction="column">
        <BuyerActions name={pharmacy?.data.pharmacyName || ''} />

        {!isLoading && pharmacy && (
          <>
            <Paper variant="outlined">
              <Container container>
                <Grid item xs={12}>
                  <PharmacyProfileInfo buyerData={pharmacy.data} />
                </Grid>
              </Container>
            </Paper>

            <Grid container>
              <Grid xs={12} item>
                <Divider variant="fullWidth" style={{ margin: '1.5rem 0' }} />
              </Grid>
            </Grid>

            <Paper variant="outlined">
              <Container container>
                <Grid xs={12} item>
                  <PharmacyProfileLegalDocuments />
                </Grid>
              </Container>
            </Paper>

            <Grid container>
              <Grid xs={12} item>
                <Divider variant="fullWidth" style={{ margin: '1.5rem 0' }} />
              </Grid>
            </Grid>

            <Paper variant="outlined">
              <Container container>
                <Grid xs={6} item>
                  <PharmacyProfileShippingAddress
                    shippingAddress={pharmacy.data.shippingAddress}
                  />
                </Grid>
              </Container>
            </Paper>

            <Grid container>
              <Grid xs={12} item>
                <Divider variant="fullWidth" style={{ margin: '1.5rem 0' }} />
              </Grid>
            </Grid>

            <Paper variant="outlined">
              <Container container>
                <Grid xs={12} item>
                  <PharmacyProfileBillingInformation
                    id={query.id}
                    data={{
                      name: pharmacy?.data?.billingAddress?.name || '',
                      address: pharmacy?.data?.billingAddress?.address || '',
                      state: pharmacy?.data?.billingAddress?.state || '',
                      city: pharmacy?.data?.billingAddress?.city || '',
                      zipCode: pharmacy?.data?.billingAddress?.zipCode || '',
                    }}
                  />
                </Grid>
              </Container>
            </Paper>

            <Grid container>
              <Grid xs={12} item>
                <Divider variant="fullWidth" style={{ margin: '1.5rem 0' }} />
              </Grid>
            </Grid>

            <Paper variant="outlined">
              <Container container>
                <Grid item xs={12}>
                  <AddPaymentProvider>
                    <PharmacyProfilePaymentMethods
                      data={paymentMethods?.data || { ach: [], cards: [] }}
                    />
                  </AddPaymentProvider>
                </Grid>
              </Container>
            </Paper>

            <Grid container>
              <Grid xs={12} item>
                <Divider variant="fullWidth" style={{ margin: '1.5rem 0' }} />
              </Grid>
            </Grid>

            <Paper variant="outlined">
              <Container container>
                <Grid xs={12} item>
                  <PharmacyProfileMemberList
                    data={pharmacy.data.members}
                    onToggle2FASettings={refetch}
                  />
                </Grid>
              </Container>
            </Paper>

            <Grid container>
              <Grid xs={12} item>
                <Divider variant="fullWidth" style={{ margin: '1.5rem 0' }} />
              </Grid>
            </Grid>

            <Paper variant="outlined">
              <Container container>
                <Grid item xs={12}>
                  <PharmacyProfilePaymentTerms
                    permissions={pharmacy.data.permissions}
                    paymentTermsData={pharmacy.data.paymentTerm}
                    onPaymentTermAccessChange={refetch}
                  />
                </Grid>
              </Container>
            </Paper>

            <Grid container>
              <Grid xs={12} item>
                <Divider variant="fullWidth" style={{ margin: '1.5rem 0' }} />
              </Grid>
            </Grid>

            <Paper variant="outlined">
              <Container container>
                <Grid xs={12} item>
                  <PharmacyDangerZone
                    accountId={pharmacy?.data?.members[0].id}
                    isDisabled={pharmacy?.data?.approvalStatus === 'suspended'}
                  />
                </Grid>
              </Container>
            </Paper>
          </>
        )}

        {isLoading && <PharmacyProfileLoader />}
      </Container>
    </TemplateView>
  );
};
