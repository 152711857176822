import { useMutation } from 'react-query';
import { IPostPaymentMethodsACHDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAddPharmacyPaymentMethodAch = (pharmacyId: string | number) => {
  return useMutation(
    'add-pharmacy-payment-method-ach',
    (payloadDto: IPostPaymentMethodsACHDTO) => {
      return http.post(
        `${process.env.REACT_APP_WEBSITE_URL}/v2/pharmacist/pharmacies/${pharmacyId}/payment-methods/ach`,
        payloadDto
      );
    },
    { retry: false }
  );
};
