import React from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';

import { STATE_CODES_US } from 'rx-utils';
import { usePharmacistUpdateShippingAddress } from 'rx-api';
import { useToast } from 'rx-hooks';
import { PharmacyShippingAddressSchema } from 'utils/validators/account-settings/pharmacy/shipping-address.validator';

import { Title } from './index.styles';

type IProps = {
  data?: {
    address: string;
    city: string;
    state: string;
    zipCode: string;
  } | null;
  disabled: boolean;
};

type IForm = {
  address: string;
  city: string;
  state: string;
  zipCode: string;
};

export const SettingsShipAddress: React.FC<IProps> = (props) => {
  const toast = useToast();
  const params = useParams<{ id: string }>();
  const updateShippingAddress = usePharmacistUpdateShippingAddress(params.id);

  const formik = useFormik({
    initialValues: {
      address: props.data?.address ?? '',
      city: props.data?.city ?? '',
      state: props.data?.state ?? 'AL',
      zipCode: props.data?.zipCode ?? '',
    },
    enableReinitialize: true,
    validationSchema: PharmacyShippingAddressSchema,
    onSubmit: async (values: IForm) => {
      try {
        await updateShippingAddress.mutateAsync(values);

        toast.onSuccessToast('Changes saved succesfully 🚀');
      } catch (err) {
        toast.onErrorToast('We were not able to save your shipping address');
      }
    },
  });

  return (
    <Paper variant="outlined" style={{ padding: '1rem' }}>
      <Title>Shipping Address</Title>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              error={!!formik.errors?.address}
              name="address"
              label="Address *"
              placeholder="Address"
              value={formik.values.address}
              onChange={formik.handleChange}
              variant="outlined"
              helperText={formik.errors?.address}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              error={!!formik.errors?.city}
              name="city"
              label="City *"
              placeholder="City"
              value={formik.values.city}
              onChange={formik.handleChange}
              variant="outlined"
              helperText={formik.errors?.city}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormControl error={!!formik.errors?.state} fullWidth>
              <Select
                name="state"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.state}
                onChange={formik.handleChange}
                variant="outlined"
                disabled={props.disabled}
                fullWidth
              >
                {STATE_CODES_US.map((state: string) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors?.state}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              error={!!formik.errors?.zipCode}
              name="zipCode"
              label="Zip Code *"
              placeholder="Zip code"
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              variant="outlined"
              helperText={formik.errors?.zipCode}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '1rem' }}>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={props.disabled}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
