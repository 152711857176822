import { IGetAdminManufacturersIdShipConfigResponse } from 'rx-domain';
import { useGetRequest } from 'rx-api/utils';

type IRequest = {
  id: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGetAdminManufacturersIdShipConfig = (request: IRequest) => {
  return useGetRequest<IRequest, IGetAdminManufacturersIdShipConfigResponse>({
    url: `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/manufacturers/${request.id}/shipping-configuration`,
    key: ['get-admin-manufacturers-id-ship-config', request.id],
  });
};

export { useGetAdminManufacturersIdShipConfig };
