import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import { Summary, Footer } from './index.styles';

export const ShopCartLoading: React.FC = () => {
  return (
    <div>
      <Summary>
        <Skeleton height={100} />
        <Skeleton height={100} />
      </Summary>

      <Footer>
        <Skeleton height={60} />
      </Footer>
    </div>
  );
};
