import React from 'react';

import { Tooltip } from '@material-ui/core';

import { Container, Content, Header } from './index.styles';

type IProps = {
  info?: boolean;
  title: string;
  content: string;
};

const ProductRestriction = (props: IProps): JSX.Element => {
  return (
    <Container info={!!props.info}>
      <Header>{props.title}</Header>
      <Tooltip title={props.content} arrow placement="bottom-start">
        <Content>{props.content}</Content>
      </Tooltip>
    </Container>
  );
};

export { ProductRestriction };
