import { IGetAdminBannedProductsResponse } from 'rx-domain';
import { useGetRequest } from 'rx-api/utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGetAdminBannedProducts = () => {
  return useGetRequest<unknown, IGetAdminBannedProductsResponse>({
    url: `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/banned-products`,
    key: ['get-admin-black-list'],
  });
};

export { useGetAdminBannedProducts };
