import React, { useMemo } from 'react';

import { StatusChip } from './index.styles';

type IProps = {
  status: string;
};

export const TransactionShippingStatus = ({ status }: IProps): JSX.Element => {
  const chipLabel = useMemo(() => {
    switch (status) {
      case 'pending':
        return 'Pending Shipping';
      case 'shipped':
        return 'Shipped';
      case 'cancelled':
        return 'Shipping Cancelled';
      default:
        return 'N/A';
    }
  }, [status]);

  return <StatusChip status={status} label={chipLabel} variant="outlined" />;
};
