import React from 'react';
import styled from 'styled-components';

import { CsvUploadSuccess } from './CsvUploadSuccess';

type ICsvUploadProgressProps = {
  success: boolean;
  loading: boolean;
};

const Container = styled.div`
  width: 100%;
`;

const Title = styled.h2`
  font-size: ${({ theme: { sizes } }) => `${sizes.h2}`};
  font-family: ${({ theme: { fonts } }) => `${fonts.robotoLight}`};
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(43, 54, 71, 0.35);
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.sizes.medium};
  font-family: ${({ theme }) => theme.fonts.robotoLight};
  margin: 1rem 0;
`;

const CsvUploadProgress: React.FC<ICsvUploadProgressProps> = ({
  success,
  loading,
}) => {
  return (
    <Container>
      {loading && (
        <>
          <Title>Your products are being uploaded.</Title>
          <Text>
            We are importing your products to the RxRise marketplace. Please
            wait.
          </Text>
        </>
      )}
      {success && <CsvUploadSuccess />}
    </Container>
  );
};

export { CsvUploadProgress };
