import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useGetCategoriesList } from 'rx-api';

import { InventoryType } from 'rx-domain';

import { Container } from './index.styles';

type IProps = {
  onChange: (value: InventoryType) => void;
};

const BULK_UPLOAD_TYPES = [
  {
    title: 'RxProducts',
    value: 'drug',
  },
  {
    title: 'Medical and Diabetes Supplies',
    value: 'medical_supply',
  },
  {
    title: 'Covid and Other Tests',
    value: 'diagnostic_test',
  },
];

type formattedCategories = {
  value: string;
  title: string;
}[];

const BulkUpSelectInput = (props: IProps): JSX.Element => {
  const { data: categoriesList, refetch } = useGetCategoriesList();
  const [formattedCategories, setFormattedCategories] =
    useState<formattedCategories>([]);
  const [value, setValue] = useState({ value: '', title: '' });
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (categoriesList) {
      const results = categoriesList.map((category) => {
        return {
          title: category.slug,
          value: category.text,
        };
      });
      setFormattedCategories(results);
      setValue(results[0]);
    }
  }, [categoriesList]);

  return (
    <Container>
      <Autocomplete
        id="bulk-upload-type-picker"
        value={value}
        onChange={(event, newValue) => {
          if (newValue) {
            setValue(newValue);
            props.onChange(newValue.value as InventoryType);
          }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={formattedCategories}
        getOptionLabel={(option) => option.title}
        style={{ width: 400 }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select type"
            variant="outlined"
            focused={false}
          />
        )}
      />
    </Container>
  );
};

export { BulkUpSelectInput };
