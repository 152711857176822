import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { usePopper } from 'react-popper';

import { PopperPasswordList } from '../PopperPasswordList';

export type PopperPasswordProps = {
  visible: boolean;
  password: string;
};

const PopperContainer = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: white;
  padding: 24px 20px;
  padding-bottom: 0;
  text-align: left;
  width: 374px;
  z-index: 999;

  font-family: ${({ theme }) => theme.fonts.robotoLight};
  font-size: ${({ theme }) => theme.sizes.medium};

  ${({ theme: { lessThan } }) => css`
    ${lessThan('mobile')} {
      width: 95vw;
    }
  `}

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;

    &:before {
      top: -35px;
      left: -20.5px;
      transform: rotate(45deg);

      width: 20px;
      height: 20px;
      content: ' ';
      position: absolute;
      background-color: white;
      z-index: -1;
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    }
  }
  &[data-popper-placement^='top'] > .arrow {
    left: -4px;

    :after {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
`;

const Container = styled.div``;

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.robotoRegular};
  color: ${({ theme }) => theme.colors.black[100]};
  font-size: ${({ theme }) => theme.sizes.small};
  margin-bottom: 30px;
`;

const PopperPassword: React.FC<PopperPasswordProps> = ({
  visible,
  children,
  password,
  ...props
}): JSX.Element => {
  const referenceElement = useRef(null);
  const popperElement = useRef(null);
  const [arrowRef, setArrowRed] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      modifiers: [
        { name: 'arrow', options: { element: arrowRef } },
        {
          name: 'offset',
          options: {
            offset: [0, 24],
          },
        },
        {
          name: 'flip',
          enabled: false,
        },
      ],
    }
  );

  return (
    <>
      <Container {...props} ref={referenceElement}>
        {children}
      </Container>
      {visible && (
        <PopperContainer
          ref={popperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div ref={setArrowRed} style={styles.arrow} className="arrow" />
          <Title>Password Must:</Title>
          <PopperPasswordList password={password} />
        </PopperContainer>
      )}
    </>
  );
};

export { PopperPassword };
