import React, { createContext, FC, useContext, useState } from 'react';

import { useWizard, UseWizardHook } from 'rx-hooks';

import {
  ISignupFormPharmacy,
  ISignupFormValidationView,
  ISignupFormPersonalInfoView,
} from 'rx-domain';
import { StepperViews } from 'rx-hooks/utils/useWizard';

type ContextProps = UseWizardHook & {
  form: ISignupFormPharmacy;
  formError: string;
  onChangeWizard: (id: string) => void;
  onChangeWizardWithError: (id: string, error: string) => void;
  onSetValidationStep: (form: ISignupFormValidationView) => void;
  onSetPersonalInfoStep: (form: ISignupFormPersonalInfoView) => void;
};

const initialForm: ISignupFormPharmacy = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  phoneNumber: '',
  titleOrPosition: '',
  howDidYouHearAboutUs: '',
  subscribeToMailingList: false,
  acceptTOS: false,
  saleRep: '',
  address: {
    zipCode: '',
    address: '',
    city: '',
    state: 'AL',
  },
  pharmacy: {
    type: 0,
    dea: '',
    stateLicense: '',
    pharmacyName: '',
  },
};

const SignupPharmacistContext = createContext<ContextProps | undefined>(
  undefined
);

const SignupPharmacistProvider: FC = ({ children }) => {
  const [formData, setFormData] = useState<ISignupFormPharmacy>(initialForm);
  const [formError, setError] = useState<string>('');

  const wizardProps = useWizard([
    {
      title: 'Personal Information',
      left: '-5%',
      id: StepperViews.PERSONAL_INFORMATION,
    },
    {
      title: 'Pharmacy Information',
      left: '20%',
      id: StepperViews.PHARMACY_INFORMATION,
    },
    {
      title: 'Shipping Information',
      left: '40%',
      id: StepperViews.SHIPPING_INFORMATION,
    },
    { title: 'Finish Up', left: '90%', id: StepperViews.FINISH_UP },
  ]);

  const onSetValidationStep = (form: ISignupFormValidationView) => {
    setFormData((prev) => ({
      ...prev,
      ...form,
      phoneNumber: form.phoneNumber
        .replace(/\(+/g, '')
        .replace(/\)+/g, '')
        .replace(/-+/g, '')
        .replace(/\s+/g, ''),
    }));
  };

  const onSetPersonalInfoStep = (form: ISignupFormPersonalInfoView) => {
    setFormData((prev) => ({
      ...prev,
      ...form,
    }));
  };

  const onChangeWizardWithError = (id: string, error: string) => {
    setError(error);
    wizardProps.onChangeWizard(id);
  };

  return (
    <SignupPharmacistContext.Provider
      value={{
        formError,
        form: formData,
        onSetValidationStep,
        onSetPersonalInfoStep,
        onChangeWizardWithError,
        ...wizardProps,
      }}
    >
      {children}
    </SignupPharmacistContext.Provider>
  );
};

const useSignupPharmacist = (): ContextProps => {
  const context = useContext(SignupPharmacistContext);

  if (context === undefined) {
    throw new Error(
      'useSignupPharmacist must be used within a SignupPharmacistProvider'
    );
  }

  return context;
};

export { useSignupPharmacist, SignupPharmacistProvider };
