import styled from 'styled-components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export const Container = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h2`
  margin-left: 1rem;
`;

export const BackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BackIcon = styled(ArrowBackIcon)`
  cursor: pointer;
`;
