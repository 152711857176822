import React, { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

import ArrowRightIcon from '../../../assets/icons/arrow-right.png';

export type ButtonIconProps = ImgHTMLAttributes<HTMLImageElement> & {
  className?: string;
};

const ArrowRightContainerIcon = styled.img`
  position: absolute;
  right: 20px;
  height: 16px;
  cursor: pointer;
`;

const ButtonIcon = (props: ButtonIconProps): JSX.Element => {
  return <ArrowRightContainerIcon src={ArrowRightIcon} {...props} />;
};

ButtonIcon.defaultProps = {
  className: '',
};

export { ButtonIcon };
