import { useEffect, useState } from 'react';

import { useGetPharmacies } from 'rx-api';

import { usePaginationQueries } from 'rx-hooks/utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTransactionFilterBuyer = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<{
    id: number;
    pharmacyName: string;
  } | null>(null);
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<
    {
      id: number;
      pharmacyName: string;
    }[]
  >([]);

  const { querySeller } = usePaginationQueries();

  const { data: sellersResponse, isLoading } = useGetPharmacies({
    status: 'approved',
    search,
    skip: 0,
  });

  useEffect(() => {
    if (sellersResponse?.data) {
      setOptions(sellersResponse.data.results);
    }
  }, [sellersResponse]);

  useEffect(() => {
    if (querySeller) setValue(null);
  }, [querySeller]);

  return {
    open,
    setOpen,
    value,
    setValue,
    search,
    setSearch,
    options,
    setOptions,
    isLoading,
  };
};

export { useTransactionFilterBuyer };
