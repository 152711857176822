import React from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

type FooterAddressContentProps = {
  children: React.ReactNode;
};

const Text = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  text-decoration: none;
  ${space}
`;

const FooterAddressContent = ({
  children,
  ...props
}: FooterAddressContentProps & SpaceProps): JSX.Element => {
  return <Text {...props}>{children}</Text>;
};

export { FooterAddressContent };
