import { useMutation } from 'react-query';

import { IPostPharmacistsIdPaymentTermResponse } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUpdatePharmacyPaymentTerm = () => {
  const onRequest = async ({
    id,
    ...req
  }: IPostPharmacistsIdPaymentTermResponse) => {
    return http.post(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/pharmacies/${id}/payment-term`,
      req
    );
  };

  return useMutation(onRequest, {});
};
