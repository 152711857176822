import React from 'react';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { Formik, Form } from 'formik';

import { SignupSellerForm as ISignupSellerFormState } from 'rx-domain';
import { clearPhonenumber } from 'rx-utils';
import { useSignupManufacturer } from 'rx-contexts';
import { usePostAuthSigninManufacturer } from 'rx-api';

import { SellerSignupSchema } from 'utils/validators/signup/seller-signup.validator';
import { StepperViews } from 'rx-hooks/utils/useWizard';
import { SignupHelpSelect } from '../../signup/molecules/SignupHelpSelect';
import { SignupSellerPersonalForm } from './SignupSellerPersonalForm';
import { SignupSellerBussinessForm } from './SignupSellerBussinessForm';
import { SignupFinishUp } from '../../signup/molecules/SignupFinishUp';

const Container = styled.div`
  ${({ theme }) => css`
    max-width: 57.5rem;
    margin: auto;

    ${theme.lessThan('ipad')} {
      width: 100%;
    }
  `}
`;

const SignupSellerForm = (): JSX.Element => {
  const initialValues: ISignupSellerFormState = {
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    titleOrPosition: '',
    company: {
      name: '',
      phoneNumber: '',
      email: '',
    },
    address: {
      address: '',
      city: '',
      state: 'AL',
      zipCode: '',
    },
    howDidYouHearAboutUs: '',
    subscribeToMailingList: false,
    acceptTOS: false,
  };

  const { currentView, onChangeWizardWithError, onChangeWizard } =
    useSignupManufacturer();
  const signupManufacturer = usePostAuthSigninManufacturer();

  const onSubmit = async ({
    confirmPassword,
    ...values
  }: ISignupSellerFormState) => {
    try {
      await signupManufacturer.mutateAsync({
        ...values,
        phoneNumber: clearPhonenumber(values.phoneNumber),
        company: {
          ...values.company,
          phoneNumber: clearPhonenumber(values.company.phoneNumber),
        },
      });

      onChangeWizard(StepperViews.FINISH_UP);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (
          err.response &&
          err.response.data.message === 'Email already registered'
        ) {
          onChangeWizardWithError(
            StepperViews.PERSONAL_INFORMATION,
            'EMAIL_ALREADY_USE'
          );
        }
      }
    }
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validateOnBlur={true}
        onSubmit={onSubmit}
        validationSchema={SellerSignupSchema}
      >
        <Form>
          {currentView?.id === StepperViews.PERSONAL_INFORMATION && (
            <SignupSellerPersonalForm />
          )}
          {currentView?.id === StepperViews.BUSINESS_INFORMATION && (
            <SignupSellerBussinessForm />
          )}
          {currentView?.id === StepperViews.FINISH_UP && <SignupFinishUp />}
        </Form>
      </Formik>
      {currentView?.id !== StepperViews.FINISH_UP && <SignupHelpSelect />}
    </Container>
  );
};

export { SignupSellerForm };
