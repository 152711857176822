import { useState } from 'react';
import { useQuery, useSkipPage } from 'rx-hooks/utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useBuyers = () => {
  const [length, setLength] = useState(0);

  const query = useQuery();
  const { skip, totalPages } = useSkipPage(length);

  const queryPharmacy = query.get('search') ?? '';
  const queryStatus =
    (query.get('approvalStatus') as
      | 'approved'
      | 'rejected'
      | 'pending'
      | 'any') ?? 'any';

  return {
    queryPharmacy,
    queryStatus,
    skip,
    totalPages,
    setLength,
    length,
  };
};

export { useBuyers };
