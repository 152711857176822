import React, { createContext, FC, useContext, useState } from 'react';

import {
  ITransaction,
  TransactionFileType,
  ITransactionPurchaseOrder,
} from 'rx-domain';

type ContextProps = {
  percent: number;
  modalGenerate: boolean;
  typeDoc: TransactionFileType;
  success: boolean;
  error: boolean;
  loading: boolean;
  url: string;
  transaction: ITransaction | undefined;
  purchaseSaleOrder: ITransactionPurchaseOrder | undefined;
  totalTransactions: number;
  onSetPercent: (value: number) => void;
  onSetModalGenerate: (value: boolean) => void;
  onSetTypeDoc: (value: TransactionFileType) => void;
  onSetSuccess: (value: boolean) => void;
  onSetError: (value: boolean) => void;
  onSetLoading: (value: boolean) => void;
  onSetURL: (value: string) => void;
  onInit: () => void;
  onSetTransaction: (value: ITransaction | undefined) => void;
  onSetPurchaseSaleOrder: (
    value: ITransactionPurchaseOrder | undefined
  ) => void;
  onSetTotalTransactions: (total: number) => void;
};

const TransactionsContext = createContext<ContextProps | undefined>(undefined);

const TransactionsProvider: FC = ({ children }) => {
  const [transaction, setTransaction] = useState<ITransaction | undefined>();
  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [purchaseSaleOrder, setPurchaseSaleOrder] = useState<
    ITransactionPurchaseOrder | undefined
  >();
  const [typeDoc, setTypeDoc] = useState<TransactionFileType>(
    'CONSOLIDATED-INVOICE'
  );
  const [modalGenerate, setModalGenerate] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(0);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [url, setUrl] = useState('');

  const onInit = () => {
    setTypeDoc('CONSOLIDATED-INVOICE');
    setPercent(0);
    setSuccess(false);
    setLoading(false);
    setError(false);
    setUrl('');
  };

  const onSetPercent = (value: number) => {
    setPercent(value);
  };

  const onSetModalGenerate = (value: boolean) => {
    setModalGenerate(value);
  };

  const onSetTypeDoc = (value: TransactionFileType) => {
    setTypeDoc(value);
  };

  const onSetSuccess = (value: boolean) => {
    setSuccess(value);
  };

  const onSetError = (value: boolean) => {
    setError(value);
  };

  const onSetLoading = (value: boolean) => {
    setLoading(value);
  };

  const onSetURL = (value: string) => {
    setUrl(value);
  };

  const onSetTransaction = (value: ITransaction | undefined) => {
    setTransaction(value);
  };

  const onSetPurchaseSaleOrder = (
    value: ITransactionPurchaseOrder | undefined
  ) => {
    setPurchaseSaleOrder(value);
  };

  const onSetTotalTransactions = (total: number) => {
    setTotalTransactions(total);
  };

  return (
    <TransactionsContext.Provider
      value={{
        transaction,
        totalTransactions,
        percent,
        modalGenerate,
        typeDoc,
        success,
        error,
        loading,
        url,
        purchaseSaleOrder,
        onSetPercent,
        onSetModalGenerate,
        onSetTypeDoc,
        onSetSuccess,
        onSetError,
        onSetLoading,
        onSetURL,
        onInit,
        onSetTransaction,
        onSetPurchaseSaleOrder,
        onSetTotalTransactions,
      }}
    >
      {children}
    </TransactionsContext.Provider>
  );
};

const useTransactions = (): ContextProps => {
  const context = useContext(TransactionsContext);

  if (context === undefined) {
    throw new Error(
      'useTransactions must be used within a TransactionsProvider'
    );
  }

  return context;
};

export { useTransactions, TransactionsProvider };
