import React from 'react';
import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';

import { useAddPayment } from 'rx-contexts';

const Container = styled(Grid)`
  width: 100%;
  height: 3.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
`;

const TabItem = styled(Grid)<{
  $active: boolean;
  $last: boolean;
  $first: boolean;
}>`
  ${({ theme: { colors }, $active, $last, $first }) => css`
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    ${$active &&
    css`
      background-color: ${colors.blue[300]};

      & > span {
        color: white;
      }
    `}

    ${$first &&
    css`
      border-radius: 0.5rem 0 0 0;
    `}

    ${$last &&
    css`
      border-radius: 0 0.5rem 0 0;
    `}
  `}
`;

const TabItemText = styled.span`
  ${({ theme: { colors, fonts, sizes } }) => css`
    color: ${colors.black[100]};
    font-family: ${fonts.robotoRegular};
    font-style: normal;
    font-weight: 500;
    font-size: ${sizes.small};
    line-height: 25px;
  `}
`;

const tabs: Array<{
  dataCy: string;
  type: 'debit-credit-card' | 'ach' | 'payment-terms';
  title: string;
}> = [
  {
    type: 'debit-credit-card',
    title: 'Debit/Credit Card',
    dataCy: 'add-payment-method-modal-card-tab',
  },
  { type: 'ach', title: 'ACH', dataCy: 'add-payment-method-modal-ach-tab' },
];

export const AddPaymentMethodTabs = (): JSX.Element => {
  const { view, onSetView, onSetError } = useAddPayment();

  return (
    <Container container spacing={0}>
      {tabs.map((tab, index) => (
        <TabItem
          data-cy={tab.dataCy}
          key={tab.type}
          item
          xs
          onClick={() => {
            onSetView(tab.type);
            onSetError('');
          }}
          $active={view === tab.type}
          $last={tabs.length - 1 === index}
          $first={index === 0}
        >
          <TabItemText>{tab.title}</TabItemText>
        </TabItem>
      ))}
    </Container>
  );
};
