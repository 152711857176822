import { GetEdiDownloadLogs } from 'rx-domain';
import { useGetRequest } from 'rx-api/utils';
import {
  GET_EDI_INVENTORY_UPLOAD_URL,
  GET_EDI_INVENTORY_UPLOAD_KEY,
} from 'rx-api/api/admin/logs/constants';

const makeParams = (req: GetEdiDownloadLogs.QUERY) => {
  let url = `?limit=10`;

  if (req.limit) {
    url = `?limit=${req.limit}`;
  }

  if (req.skip > 0) {
    url += `&skip=${req.skip}`;
  }

  if (req.seller && req.seller !== 'all') {
    url += `&seller=${req.seller}`;
  }

  return url;
};

export const useGetEdiInventoryDownloadLogs = (
  query: GetEdiDownloadLogs.QUERY
) => {
  return useGetRequest<GetEdiDownloadLogs.QUERY, GetEdiDownloadLogs.Response>({
    url: GET_EDI_INVENTORY_UPLOAD_URL,
    key: [GET_EDI_INVENTORY_UPLOAD_KEY, query.limit, query.skip, query.seller],
    params: query,
    makeParams,
  });
};
