import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { useGetSaleOrder } from 'rx-api';

import { SEO, TemplateView } from 'rx-core';

import { Paper } from '@material-ui/core';
import { PurchaseOrderRequestProducts } from './PurchaseOrderRequestProducts';
import { PurchaseOrderRequestInfo } from './PurchaseOrderRequestInfo';
import { PurchaseOrderRequestActions } from './PurchaseOrderRequestActions';

const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 1rem;
`;

const DataContainer = styled.div`
  display: flex;
`;

export const PurchaseOrderRequestView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useGetSaleOrder({ id: id ? parseInt(id, 10) : 0 });

  return (
    <TemplateView>
      <SEO title="RxRise | Sale Order" />
      {data?.data && (
        <Container variant="outlined">
          <PurchaseOrderRequestActions {...data.data} />

          <DataContainer>
            <PurchaseOrderRequestInfo {...data.data} />
            <PurchaseOrderRequestProducts items={data.data.orderItems} />
          </DataContainer>
        </Container>
      )}
    </TemplateView>
  );
};
