import {
  IBuyerSearchFilterForm,
  IBuyerSearchFilterSortBy,
  SearchQueryParams,
} from 'rx-domain';
import { IBuyerSearchFilterType } from 'rx-domain/types';
import { useQuery } from './useQuery';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePaginationQueries = ({
  sortByDefault = 'price',
  sortOrderByDefault = 'asc',
}: {
  sortByDefault?: string | null;
  sortOrderByDefault?: string | null;
} = {}) => {
  const query = useQuery();

  const querySearch = query.get('search') ?? '';
  const queryFrom = query.get('from') ?? '';
  const queryTo = query.get('to') ?? '';
  const queryPage = parseInt(query.get('p') ?? '', 10);
  const querySortOrder =
    (query.get('o') as 'asc' | 'desc') ?? sortOrderByDefault;
  const querySearchType = (query.get('t') as IBuyerSearchFilterType) ?? 'name';
  const querySortBy =
    (query.get('b') as IBuyerSearchFilterSortBy) ?? sortByDefault;
  const queryForm = (query.get('form') as IBuyerSearchFilterForm) ?? '';

  const queryMaxPrice = query.get('maxPrice') ?? '';
  const queryMinPrice = query.get('minPrice') ?? '';
  const querySeller = query.get('seller') ?? '';
  const queryBuyer = query.get('buyer') ?? '';

  const queryMOStatus = query.get('mo') ?? 'all';
  const queryCategory = query.get('category') ?? '';

  const queryViewType = query.get('viewType') ?? 'list';
  const limit = query.get('limit') ?? '15';
  const querySellerId = query.get('sellerId') ?? '';
  const queryStrengthMin = query.get('strengthMin') ?? '';
  const queryStrengthMax = query.get('strengthMax') ?? '';
  const queryStrengthUnit = query.get('strengthUnit') ?? '';
  const queryPharmacyName = query.get('pharmacy') ?? '';

  const onMakeURL = (values: SearchQueryParams, initialParams = '') => {
    const params = Object.entries(values)
      .map(([key, value]) => (value ? `${key}=${value}` : ''))
      .filter((parameter) => parameter);

    return `?${initialParams}${params.join('&')}`;
  };

  const onMakeURLWithSlash = (
    values: SearchQueryParams,
    initialParams = ''
  ) => {
    const params = Object.entries(values)
      .map(([key, value]) => (value ? `${key}=${value}` : ''))
      .filter((parameter) => parameter);

    return `/?${initialParams}${params.join('&')}`;
  };

  return {
    queryPage,
    querySearch,
    querySortBy,
    querySearchType,
    querySortOrder,
    queryMaxPrice,
    queryMinPrice,
    queryForm,
    querySeller,
    queryBuyer,
    queryCategory,
    queryMOStatus,
    queryViewType,
    queryFrom,
    queryTo,
    querySellerId,
    limit,
    queryStrengthMin,
    queryStrengthMax,
    queryStrengthUnit,
    queryPharmacyName,
    onMakeURL,
    onMakeURLWithSlash,
  };
};

export { usePaginationQueries };
