import { SEO, TemplateView } from 'rx-core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Button,
  ThanksText,
  ThanksTitle,
  Title,
  ThanksContainer,
} from './index.styles';

const CheckoutSuccessView = (): JSX.Element => {
  const history = useHistory();

  return (
    <TemplateView>
      <SEO title="RxRise | Checkout Success" />
      <Container>
        <Title>Order placed succesfully!</Title>
        <ThanksContainer>
          <ThanksTitle>Thank you!</ThanksTitle>
          <ThanksText>
            Your order has been placed.
            <br />
            You will receive an e-mail confirmation with your order details.
          </ThanksText>
          <Button onClick={() => history.push('/')}>
            Return to marketplace
          </Button>
        </ThanksContainer>
      </Container>
    </TemplateView>
  );
};

export { CheckoutSuccessView };
