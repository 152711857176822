import React from 'react';
import { useHistory } from 'react-router-dom';

import { IOrderManufacturer } from 'rx-domain';

import { capitalize, formatDate, formatMoney } from 'rx-utils';

import { Container, Column } from './index.styles';

type IProps = IOrderManufacturer;

const MOItem = (props: IProps): JSX.Element => {
  const history = useHistory();

  const onRedirectToSaleOrder = (id: number) => {
    history.push(`/order-selected/${id}`);
  };

  return (
    <Container onClick={() => onRedirectToSaleOrder(props.id)}>
      <Column>
        {props.soPrefix}-{props.id}
      </Column>
      <Column>{formatDate(props.createdAt)}</Column>
      <Column>{capitalize(props.status)}</Column>
      <Column>{props.items.length}</Column>
      <Column>{formatMoney(props.subtotal)}</Column>
    </Container>
  );
};

export { MOItem };
