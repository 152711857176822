import React from 'react';
import styled from 'styled-components';

import { RxSimpleCheckbox } from '../../inputs/RxSimpleCheckbox';

export type PopperPasswordListProps = {
  password: string;
};

const Container = styled.div``;

const PopperPasswordList = ({
  password,
}: PopperPasswordListProps): JSX.Element => {
  // const haveLength = password.length >= 8;
  const haveNumber = /[0-9]+/g.test(password);
  const haveLetter = /[A-Za-z]+/g.test(password);
  const haveSpecialChar = /[@$!%*#?&]+/g.test(password);

  return (
    <Container>
      <RxSimpleCheckbox
        text="Have a number (0,1,2,3,4,5,6,7,8,9)"
        name="number"
        disabled
        checked={haveNumber}
      />
      <RxSimpleCheckbox
        text="Have at least one capital letter"
        name="letter"
        disabled
        checked={haveLetter}
      />
      <RxSimpleCheckbox
        text="Have a special character (@$!%*#?&)"
        name="special"
        disabled
        checked={haveSpecialChar}
      />
      {/*<RxSimpleCheckbox*/}
      {/*  text="Have at least 8 characters"*/}
      {/*  name="length"*/}
      {/*  disabled*/}
      {/*  checked={haveLength}*/}
      {/*/>*/}
    </Container>
  );
};

export { PopperPasswordList };
