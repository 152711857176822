import React from 'react';

import { Grid, InputAdornment, TextField } from '@material-ui/core';
import { Container } from './index.styles';

type IProps = {
  formik: any;
};

const AdminSellerShippingInfoRateForm = ({ formik }: IProps): JSX.Element => {
  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <TextField
            error={!!formik.errors?.groundShipping}
            name="groundShipping"
            label="Ground Shipping *"
            variant="outlined"
            value={formik.values.groundShipping}
            onChange={formik.handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            helperText={formik.errors?.groundShipping}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            error={!!formik.errors?.overnightShipping}
            name="overnightShipping"
            label="Overnight Shipping *"
            variant="outlined"
            value={formik.values.overnightShipping}
            onChange={formik.handleChange}
            helperText={formik.errors?.overnightShipping}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export { AdminSellerShippingInfoRateForm };
