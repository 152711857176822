import React, { InputHTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import { LayoutProps, layout } from 'styled-system';
import { DatePicker } from '@material-ui/pickers';
import { useField } from 'formik';
import { formatDate } from 'rx-utils';
import { InputTable } from '../InputTable/InputTable';

export type RxDatePickerProps = InputHTMLAttributes<HTMLInputElement> &
  LayoutProps & {
    name: string;
  };

const Input = styled(InputTable)`
  width: 100%;
  ${layout};
`;

const RxDatePicker = (props: RxDatePickerProps): JSX.Element => {
  const [dialog, setDialog] = useState(false);

  const [field, meta, helper] = useField({ ...props, name: props.name });

  const formError = !!meta.error;

  return (
    <React.Fragment>
      <Input
        error={Boolean(formError)}
        onClick={() => setDialog(true)}
        value={formatDate(field.value)}
        name={field.name}
      />
      <DatePicker
        {...field}
        variant="dialog"
        open={dialog}
        value={field.value}
        format="MM/DD/YY"
        error={Boolean(formError)}
        onOpen={() => setDialog(true)}
        onClose={() => setDialog(false)}
        onChange={(date) => helper.setValue(date?.toDate())}
        InputProps={{
          style: {
            display: 'none',
          },
        }}
        InputLabelProps={{
          style: {
            display: 'none',
          },
        }}
      />
    </React.Fragment>
  );
};

export { RxDatePicker };
