import React, { useEffect } from 'react';

import { IAddress, IGetAccountPharmacyDataDTO } from 'rx-domain';
import { MenuItem, TextField, Typography } from '@material-ui/core';
import { useAddPayment } from 'rx-contexts';

import { CheckoutCard } from '../CheckoutCard/CheckoutCard';
import { CheckoutCardEmpty } from '../CheckoutCardEmpty/CheckoutCardEmpty';

type IProps = {
  pharmaciesList:
    | {
        result: Array<{
          id: number;
          pharmacyName: string;
          dbaName: string;
          dea: string;
          type: string;
          stateLicense: string;
          approvalStatus: string;
          billingInformation: {
            name: string;
            address: string;
            city: string;
            state: string;
            zipCode: string;
          } | null;
          shippingAddress: {
            address: string;
            city: string;
            state: string;
            zipCode: string;
          } | null;
        }>;
      }
    | undefined;
  setSelectedPharmacy: (
    value:
      | {
          id: number;
          pharmacyName: string;
          dbaName: string;
          dea: string;
          type: string;
          stateLicense: string;
          approvalStatus: string;
          billingInformation: {
            name: string;
            address: string;
            city: string;
            state: string;
            zipCode: string;
          } | null;
          shippingAddress: {
            address: string;
            city: string;
            state: string;
            zipCode: string;
          } | null;
        }
      | undefined
  ) => void;
  selectedPharmacy:
    | {
        id: number;
        pharmacyName: string;
        dbaName: string;
        dea: string;
        type: string;
        stateLicense: string;
        approvalStatus: string;
        billingInformation: {
          name: string;
          address: string;
          city: string;
          state: string;
          zipCode: string;
        } | null;
        shippingAddress: {
          address: string;
          city: string;
          state: string;
          zipCode: string;
        } | null;
      }
    | undefined;
  shipAddressData: IAddress | undefined;
  pharmacyData: IGetAccountPharmacyDataDTO | undefined;
  onAddShipAddress: () => void;
};

export const CheckoutShipAddress: React.FC<IProps> = ({
  selectedPharmacy,
  pharmaciesList,
  setSelectedPharmacy,
  onAddShipAddress,
}) => {
  const { onSetPharmacyId } = useAddPayment();

  useEffect(() => {
    if (selectedPharmacy) {
      onSetPharmacyId(selectedPharmacy.id);
    }
  }, [onSetPharmacyId, selectedPharmacy]);
  const onShippingAddressChange = (e: any) => {
    const pharmacyFound = pharmaciesList?.result.find(
      (pharmacy) => e.target.value === pharmacy.id
    );
    setSelectedPharmacy(pharmacyFound);
  };
  return (
    <>
      <CheckoutCard isFullHeight={true}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Verify Shipping Address
        </Typography>
        {pharmaciesList?.result?.length === 1 ||
        pharmaciesList?.result?.length === undefined ? (
          <></>
        ) : (
          <TextField
            variant="outlined"
            value={selectedPharmacy?.id || ''}
            select
            fullWidth
            onChange={onShippingAddressChange}
          >
            {pharmaciesList?.result.map((pharmacy: any) => {
              return (
                <MenuItem key={pharmacy.id} value={pharmacy.id}>
                  {pharmacy.pharmacyName}
                </MenuItem>
              );
            })}
          </TextField>
        )}
        <>
          {selectedPharmacy?.shippingAddress &&
          selectedPharmacy?.shippingAddress.address &&
          selectedPharmacy ? (
            <>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                {selectedPharmacy?.pharmacyName}
              </Typography>
              <Typography>
                {`${selectedPharmacy?.shippingAddress?.address} ${selectedPharmacy?.shippingAddress?.city}, ${selectedPharmacy?.shippingAddress?.state} ${selectedPharmacy?.shippingAddress?.zipCode} Unites States`}
              </Typography>
            </>
          ) : (
            <CheckoutCardEmpty
              subtitle="You have no shipping address available."
              action={{
                text: 'Add Shipping Address',
                action: onAddShipAddress,
              }}
            />
          )}
        </>
      </CheckoutCard>
    </>
  );
};
