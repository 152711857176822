import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import { TemplateView, SEO } from 'rx-core';
import { useHistory } from 'react-router-dom';
import { NewPharmacistForm } from '../purchaseOrders/NewPurchaseOrder/NewPharmacistForm';

export const NewPharmacistView: React.FC = () => {
  const history = useHistory();

  const handleClickCancel = () => {
    history.push('/pharmacies');
  };

  const handleClickAdd = () => {
    history.push('/pharmacies');
  };

  return (
    <TemplateView>
      <SEO title="RxRise | New Pharmacist" />

      <Paper variant="outlined" style={{ marginTop: '1rem' }}>
        <div style={{ padding: '1rem' }}>
          <NewPharmacistForm
            onClickCancel={handleClickCancel}
            onAddPharmacy={handleClickAdd}
          />
        </div>
      </Paper>
    </TemplateView>
  );
};
