import { IPostAuthResetPasswordDTO } from 'rx-domain';
import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePostAuthResetPassword = () => {
  const onRequest = (request: IPostAuthResetPasswordDTO) => {
    return http.post<IPostAuthResetPasswordDTO>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/auth/reset-password/confirm`,
      request
    );
  };

  const mutation = useMutation(onRequest, {});

  return mutation;
};

export { usePostAuthResetPassword };
