import styled from 'styled-components';
import { FormControlLabel, Switch } from '@material-ui/core';

export const PTSwitchContainer = styled(FormControlLabel)`
  margin-bottom: 1.5rem;
`;

export const PTSwitch = styled(Switch)`
  & > .MuiSwitch-colorSecondary.Mui-checked {
    color: #1b8df2;
  }

  & > .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: rgba(27, 141, 242, 0.3);
  }
`;
