import React from 'react';

import { TemplateView, SEO, RxTableTabs } from 'rx-core';

import { useGetFavorites } from 'rx-api';

import { FavoriteTable } from './FavoriteTable';

import { Container, Title } from './index.styles';

const FavoritesView = (): JSX.Element => {
  const { data: favoritesRes } = useGetFavorites();

  return (
    <TemplateView>
      <SEO title="RxRise | Favorites" />
      <Container>
        <Title>Favorites</Title>
        <RxTableTabs
          items={[
            {
              active: true,
              title: 'All favorites',
              onSetView: () => {},
              value: 'ALL_FAV',
            },
          ]}
        >
          <FavoriteTable products={favoritesRes?.data} />
        </RxTableTabs>
      </Container>
    </TemplateView>
  );
};

export { FavoritesView };
