import React from 'react';
import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

export type InputTextProps = React.InputHTMLAttributes<HTMLInputElement> &
  LayoutProps & {
    error: boolean;
  };

export const InputTextContainer = styled.input<InputTextProps>`
  ${({ theme: { colors, fonts, sizes } }) => css`
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    line-height: 25px;
    padding: 0 24px;
    font-size: ${sizes.medium};
    font-family: ${fonts.robotoRegular};
    border: 1px solid ${colors.gray[300]};
    ${layout};

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${colors.gray[500]};
      font-family: ${fonts.robotoRegular};
      font-style: normal;
      font-weight: normal;
      font-size: ${sizes.small};
    }

    &:disabled {
      color: ${colors.gray[400]};
      border: 1px solid ${colors.gray[400]};
      cursor: not-allowed;
    }
  `}

  ${({ error, theme: { colors } }) =>
    error &&
    css`
      border-color: ${colors.red[200]};
      color: ${colors.red[200]};
    `};
`;

const InputText = ({ ...props }: InputTextProps): JSX.Element => {
  return <InputTextContainer {...props} />;
};

export { InputText };
