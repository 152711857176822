import React from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { Footer } from 'rx-core';
import {
  TermsAndConditionsTitle,
  TermsAndConditionsDescription,
  TermsAndConditionsSubtitle,
  TermsAndConditionsContent,
} from '../atoms';

const Container = styled.div``;

const ContainerPending = styled.div`
  padding: 0 24px;
  margin-bottom: 40px;
  max-width: 918px;
`;

const Spacer = styled.div<SpaceProps>`
  ${space};
`;

const Content = styled(TermsAndConditionsContent)`
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 24px;
`;

const Subtitle = styled(TermsAndConditionsSubtitle)`
  margin-bottom: 20px;
`;

const TermsAndConditionsView: React.FC = () => {
  return (
    <Container>
      <ContainerPending>
        <TermsAndConditionsTitle>Terms and conditions</TermsAndConditionsTitle>
        <Spacer mb={40} />
        <TermsAndConditionsDescription
          texts={['RxRise Terms of Use', 'Last Updated: April 13, 2022']}
        />
        <Spacer mb={60} />
        <Content>
          These terms and conditions of use (“Terms of Use”) govern your use of
          our online interfaces and properties (e.g., websites and applications)
          owned and controlled by RxRise LLC (“RxRise”, “we,” “us,” and “our”),
          including the www.rxrise.com website (the “Site”), as well as the
          services provided by third-parties and made available to users through
          the Site (“Services”). The terms “you” and “your” means you, your
          dependent(s) or representative(s) if any, and any other person
          accessing your account on the Site. Your acceptance of, and compliance
          with, these Terms of Use is a condition to your use of the Site and
          Services. By using the Site, you acknowledge that you have read,
          understand, and expressly agree to all terms and conditions contained
          within the Terms of Use and Privacy Policy. If you do not agree to be
          bound by these terms, you are not authorized to access or use this
          Site or Services for any purpose; promptly exit this Site.
        </Content>
        <Subtitle textAlign="center">
          Terms of Use Related to Pharmaceutical Transactions and Payments
        </Subtitle>
        <Spacer mb={16} />
        <Subtitle>DEFINITIONS:</Subtitle>
        <Spacer mb={20} />
        <Content ml="162px">
          <ol>
            <li>
              “Buyer” means an individual or entity who (i) is permitted and
              qualified to buy a Product pursuant to local, state, or federal
              law and (ii) intends to, or does, purchase any Product from a
              Seller on the Site.
            </li>
            <li>
              “Seller” means an individual or entity who (i) is permitted and
              qualified to sell a Product pursuant to local, state, or federal
              law and (ii) intends to, or does, sell any Product to any Buyer on
              the Site.
            </li>
            <li>
              “Product” means any item or product, including pharmaceuticals or
              prescription drug products, that are legally eligible to be
              available for sale within the United States by a Seller.
            </li>
          </ol>
        </Content>
        <Content>
          <b>PURPOSE OF SITE:</b> RxRise is an online technology platform that
          provides a marketplace for Buyers to purchase Products from Sellers
          (“Marketplace”). Upon placement of an order on the Marketplace, a
          Seller must conduct due diligence on the Buyer and make a decision as
          to whether to fulfill the Marketplace order. If a Seller chooses to
          fulfill a Marketplace order, the Seller will directly deliver, ship,
          or transport purchased Products to the Buyer. RxRise serves only as
          the technology provider of an online marketplace. RxRise does not
          participate in the transactions which occur on or in the Marketplace,
          except as it relates to providing (i) maintenance of the online
          platform and (ii) payment processing services. RxRise shall not and
          does not direct the sale or resale of any Product, and it shall not
          and does not take title or possession of any Product.
        </Content>
        <Content>
          <b>BUYER AND SELLER QUALIFICATION:</b> RxRise will attempt to validate
          the credentials of each Buyer prior to granting them access to the
          Site or Marketplace in order to ensure that that the Buyer is
          qualified to purchase Products within the United States (“Buyer
          Qualification Process”). The Buyer Qualification Process may involve
          multiple steps, including, but not limited to, a verification of a
          Buyer’s state license and Drug Enforcement Administration (“DEA”)
          credentials.{' '}
          <b>
            By accepting these terms, a Buyer consents to the Buyer
            Qualification Process and agrees to assist RxRise in its efforts to
            conduct the Buyer Qualification Process, to the extent practicable.
          </b>
        </Content>
        <Content>
          RxRise will similarly adopt policies to validate the identity of
          Sellers and confirm a Seller’s ability to offer a Product for sale
          (“Seller Qualification Process”). The Seller Qualification Process may
          involve multiple steps, including, but not limited to, a verification
          of a Seller’s state or federal license, as applicable, and DEA
          credentials.{' '}
          <b>
            By accepting these terms, Seller consents to the Seller
            Qualification Process and agrees to assist RxRise in its efforts to
            conduct the Seller Qualification Process, to the extent practicable.
          </b>
        </Content>
        <Content>
          The Buyer and Seller Qualification Processes, described above, is
          conducted voluntarily by RxRise and is conducted solely for the
          purposes of determining whether a Buyer or Seller will be granted
          access to the Marketplace. The Buyer and Seller Qualification
          Processes may not be relied upon by Sellers in determining whether to
          fulfill a Marketplace order, or by Buyers in determining the validity
          of a Seller’s credentials, or by either Buyer or Seller in determining
          compliance with any applicable law. Buyers and Sellers must conduct
          their own evaluation and make an independent determination as to
          whether to submit or fulfill a Marketplace order, respectively,
          pursuant to each of Buyer’s and Seller’s legal obligations including,
          but not limited to, those related to licensing, registration,
          accreditation, and supply chain management and transparency.{' '}
          <b>
            By accepting these terms, (i) each Seller represents and warrants
            that it will independently verify the eligibility of a Buyer to
            purchase their Products, as required by federal or state law; and
            (ii) each Buyer represents and warrants that it will independently
            verify the eligibility of Seller to sell Products, as required by
            federal or state law.
          </b>
        </Content>
        <Subtitle>BUYER REPRESENTATIONS AND OBLIGATIONS:</Subtitle>
        <Content>You represent and warrant that:</Content>
        <Content ml="116px">
          (i) You are qualified and permitted to purchase Products within the
          United States pursuant to applicable local, state, and federal law;
          <br />
          (ii) Upon placing an order on the Marketplace, and pursuant to the
          payment terms described in “Payment for Pharmaceutical Transactions,”
          you will pay any amounts necessary or due to the Seller in order for
          the order to be fulfilled;
          <br />
          (iii) You will not attempt to offer or engage any Seller to complete a
          transaction involving Products on the Marketplace in a manner designed
          to circumvent any transaction fees associated with the Marketplace by
          executing the transaction off the Marketplace or otherwise. You
          understand that any such activity will be grounds for your immediate
          termination from the Marketplace, and grounds for initiation by us of
          a claim for damages;
          <br />
          (iv) You will abide by all local, state, and federal laws; and, (v)
          You will indemnify and hold RxRise harmless from any and all loss,
          liability, cost, damage and expense associated with your use of the
          Site, Services, or Marketplace.
          <br />
          (vi){' '}
          <b>
            You will use the Marketplace for the purposes of making a bona fide
            purchase of Products. Use of the Marketplace for any other purpose
            is unauthorized. You will not use the site in a fraudulent or
            unauthorized manner, including but not limited to, signing up for a
            Buyer account while assuming another’s identity.
          </b>
        </Content>
        <Subtitle>SELLER REPRESENTATIONS AND OBLIGATIONS:</Subtitle>
        <Content>You represent and warrant that:</Content>
        <Content ml="116px">
          (i) You are qualified and permitted to sell Products within the United
          States pursuant to applicable local, state, and federal law;
          <br />
          (ii) Upon receiving an order on the Marketplace, you will timely
          conduct due diligence of the Buyer to ensure that the Buyer is legally
          eligible to purchase the Product;
          <br />
          (iii) Upon receiving payment from a Buyer, you will fulfill the order
          within the time period identified during the Marketplace check out
          process, or upon a period of time agreed upon between you and the
          Buyer;
          <br />
          (iv) You will not attempt to offer or engage any Buyer to complete a
          transaction involving Products on the Marketplace in a manner designed
          to circumvent any transaction fees associated with the Marketplace by
          executing the transaction off the Marketplace or otherwise. You
          understand that any such activity will be grounds for your immediate
          termination from the Marketplace, and grounds for initiation by us of
          a claim for damages;
          <br />
          (v) You will abide by all local, state, and federal laws; and,
          <br />
          (vi) You will indemnify and hold RxRise harmless from any and all
          loss, liability, cost, damage and expense associated with your use of
          the Site, Services, or Marketplace.
        </Content>
        <Content>
          <b>PAYMENT FOR PHARMACEUTICAL TRANSACTIONS:</b> To place an order for
          any Product on the Marketplace, the Buyer will be prompted to make a
          payment through a third-party payment processor pursuant to payment
          terms approved and adopted by the Seller. The Buyer will follow all
          steps necessary to complete the payment and may utilize any payment
          method available to the Buyer through the third party payment
          processor, including, but not limited to, credit card, debit card,
          ACH, and/or wire transfer.{' '}
          <b>
            Buyer agrees to promptly make payment such that the payment will be
            received by the Seller within three (3) business days of Buyer’s
            placement of the order on the Marketplace. Buyer understands that
            Seller will not ship Product until receipt of payment. In the event
            that Buyer’s payment is not received by the Seller within three (3)
            business days, Seller may cancel Buyer’s order.
          </b>
        </Content>
        <Content>
          Each of Buyer and Seller understands and agrees that, upon placement
          of an order, any payment made by the Buyer will be transmitted to and
          received by an account controlled by the Seller. Seller shall submit a
          transaction fee to RxRise for use of the Marketplace.
        </Content>
        <Content>
          <b>RISK OF LOSS:</b> Buyers and Sellers understand and agree that
          title and risk of loss shall pass from Seller to Buyer upon delivery
          of Product(s) to the Buyer. Seller shall ship all Product(s) in
          suitable and commercially reasonable packaging. In the event that a
          Product is lost in transit from Seller to Buyer, Seller shall replace
          the lost Product at Seller’s cost and expense or refund Buyer if
          replacement is infeasible.
        </Content>
        <Content>
          <b>REFUNDS AND ORDER CANCELLATION:</b> In the event that an order for
          Products is wholly or partially cancelled by RxRise or a Seller, or in
          the event an order cannot be fulfilled by the Seller, Buyer shall be
          entitled to a refund from Seller equivalent to the sum paid for the
          cancelled Product(s).
        </Content>
        <Content>No Returns</Content>
        <Content>
          <b>
            All sales on the Marketplace are final and no returns are accepted.
          </b>
          Notwithstanding the foregoing, a Seller may otherwise adopt a return
          policy, or permit a return on a case by case basis, if so desired by
          the Seller. In the event that a Seller elects to permit a return, and
          elects to initiate a refund, a refund will be issued to the Buyer,
          less a processing fee in an amount equal to two-hundred fifty dollars
          ($250).
        </Content>
        <Content>
          <i>
            Refunds Where Order Cancelled Due To Inability to Verify Buyer
            Credentials
          </i>
          <br />
          In the event that an order for Products is cancelled by RxRise or a
          Seller due to the inability of Seller to verify whether the Buyer is
          eligible to purchase Products pursuant to local, state, and federal
          law, and after Seller has attempted at least once to communicate with
          Buyer in writing to verify Buyer’s pharmacy license or other
          applicable credentials, Buyer shall be entitled to a refund equivalent
          to the sum paid for the cancelled Product(s), less a processing fee in
          an amount equal to two-hundred fifty dollars ($250).
        </Content>
        <Content>
          <i>
            Refunds Where Order is Cancelled by Buyer or Payment is not Timely
            Received
          </i>
          <br />
          If an order is cancelled by a Buyer or if payment for an order is not
          received within three (3) business days and that order is cancelled by
          the Seller, Buyer shall receive a refund equivalent to the sum paid
          for the cancelled Product(s), less a processing fee in an amount equal
          to two-hundred fifty dollars ($250).
        </Content>
        <Content>
          <i>
            Refunds Where Payment for Order Is Received After Order is Cancelled
            Due to Nonpayment
          </i>
          <br />
          If payment for an order is not received within three (3) business
          days, and if that order is then cancelled by the Seller, but payment
          for the Products is ultimately received after the order was cancelled,
          Seller may elect to fulfill the order. In the event that Seller
          chooses to maintain the order’s cancelled status, Buyer shall receive
          a refund equivalent to the sum paid for the cancelled Product(s).
        </Content>
        <Subtitle textAlign="center">
          Terms of Use Related to Pharmaceutical Transactions and Payments
        </Subtitle>
        <Content>
          <b>USER REPRESENTATIONS:</b> You represent and warrant that you are of
          legal age to agree to the Terms of Use set forth herein. You further
          represent and warrant that: (i) you have the legal ability and
          authority to agree to these Terms of Use and use the Site; (ii) the
          information you provided during your registration is accurate and
          complete; (iii) you will comply with all applicable laws and are
          qualified to purchase and/or Sell Products within the United States;
          and (iv) you will not interfere with, disrupt, or replicate, or
          attempt to interfere with, disrupt, or replicate this Site and its
          security measures and protocols. If any information you provided to us
          becomes inaccurate, incomplete, or otherwise false and/or misleading,
          you will immediately notify us. You are responsible for ensuring that
          any information you provide is complete, up to date, and accurate.
        </Content>
        <Content>
          <b>PAYMENT:</b> You agree that we, or our contractors and agents,
          including any third-party payment processors, may charge your credit
          card or other payment mechanism on file, if any, for all amounts due
          and owing for any transaction occurring on the Site, including taxes
          and service fees, or any other fee associated with use of your
          registered account. You agree that in the event we are unable to
          collect the fees owed for the Services through your account, we may
          take any other steps we deem necessary to collect such fees from you
          and that you will be responsible for all costs and expenses incurred
          by us in connection with such collection activity, including
          collection fees, court costs and attorneys’ fees.
        </Content>
        <Content>
          <b>MODIFICATION OF TERMS:</b> We reserve the right to update and/or
          modify the Terms of Use at any time and for any reason, without
          penalty or liability to you or any third party. Any changes of the
          Terms of Use are effective immediately upon posting. By continuing to
          access and use the Site, you expressly agree to follow and be bound by
          all applicable updates and changes to the Terms of Use.
        </Content>
        <Content>
          <b>MODIFICATION OF SITE:</b> We reserve complete and sole discretion
          with respect to the operation of the Site and the Services. We may
          withdraw, suspend, or otherwise discontinue any functionality or
          feature of the Site. We reserve the right to maintain, delete, or
          destroy all communications and user content posted or uploaded to the
          Site pursuant to applicable law and our internal record retention
          and/or destruction policies. Occasionally, we may perform maintenance
          on or upgrade the Site or the underlying structure that enables use of
          the Site. This may require us to temporarily suspend or limit your use
          of the Site or Services until such time the maintenance or upgrade is
          completed. To the extent possible and unless otherwise stated, we will
          endeavor to publish the time and date of such expected suspension or
          limitation on its Site in advance when possible. You agree that you
          are not entitled to claim any damages for such suspension or
          limitation during such maintenance or upgrade.
        </Content>
        <Content>
          <b>LICENSE:</b> Upon accepting the Terms of Use, you are granted a
          limited, non-assignable, non-sublicensable, non-exclusive license to
          use the Site on a personal computer, mobile phone, or other electronic
          device for commercial use through your individual account. You agree
          not to grant any right to third-parties to your use of our software.
        </Content>
        <Content>
          <b>USE OF SERVICES:</b> You are required to provide us with an email
          address upon registering. We will use email communications to send you
          important updates about the Site, as well as notifications when
          information and documents are added that you need to be aware of. In
          some instances, but not all, registered users may be permitted to opt
          out of receiving emails.
        </Content>
        <Content>
          Use of the Site described herein is subject to our Privacy Policy. The
          Privacy Policy is hereby incorporated by reference into these Terms of
          Use.
        </Content>
        <Content>
          Portions of the Site are only available to users who have registered
          and created an account with an appropriate username and password
          (“Registered Users”).
        </Content>
        <Content>
          Each Registered User is responsible for controlling the privacy,
          dissemination, access to, and use of their username and password, and
          promptly informing us of any need to deactivate a password. You also
          agree to promptly notify us of any unauthorized use of your username,
          password or any other breach of security that you become aware of
          involving or relating to the Site by emailing us at hello@rxrise.com.
          We explicitly disclaim liability for any and all losses and damages
          arising from your failure to comply with this section.
        </Content>
        <Content>
          <b>TERMINATION:</b> Unless expressed otherwise in writing, we reserve
          the right to terminate, rescind, revoke, or modify your access to the
          Site without notice at any time for any reason. Any violation of these
          Terms of Use will result in immediate termination. We shall not be
          liable to you or any third party for any such termination. You may opt
          out of the Site at any time.
        </Content>
        <Content>
          <b>INTELLECTUAL PROPERTY:</b> All materials on the Site, including the
          design, layout, and organization (collectively referred to as
          “Content”) are owned and copyrighted by us or our affiliates and are
          protected by all applicable intellectual property laws. All rights and
          title to the Content, trademarks and service marks herein remain with
          us or our licensors. You are authorized to view the Site and Content.
          All rights not expressly granted herein are reserved to us. You may
          not reproduce, retransmit, distribute, disseminate, sell, publish,
          broadcast, circulate or exploit for commercial gain any portion of the
          Site or the Content. You further agree that any information you
          provide or use on the Site, and your use of the Site or the Services,
          will not infringe or facilitate infringement on any copyright, patent,
          trademark, trade secret, or other proprietary, publicity, or privacy
          rights of any party, including such rights of third parties.
        </Content>
        <Content>
          You further agree that you shall not, and shall not permit any
          individual or entity to (a) reverse engineer, disassemble, decompile,
          decode, or adapt the Site or Content, or otherwise attempt to derive
          or gain access to the source code of the Site, in whole or in part;
          (b) bypass or breach any security device or protection used for or
          contained in the Site; (c) use the Site for purposes of: (i)
          benchmarking or competitive analysis of the Site; (ii) developing,
          using or providing a competing software product or service; or (iii)
          any other purpose that is to Our commercial disadvantage; or, (d) use
          the Site or Content for any purpose or application not expressly
          permitted by these Terms of Use.
        </Content>
        <Content>
          <b>THIRD PARTY WEBSITES:</b> We may provide external links to third
          party web sites. These Terms of Use apply only to your relationship
          with us and the Site, and do not describe the terms and conditions,
          privacy policies or other policies of third parties. Your use of third
          party websites is at your own risk and subject to the third party’s
          terms and conditions. We disclaim any and all liability for any
          information set forth on linked sites.
        </Content>
        <Content>
          <b>NO ENDORSEMENT:</b> We do not endorse the promotions, products,
          publications or services of any third parties. We do not warrant or
          validate the advertisements, promotions, communications, or other
          materials of any third party. Any views expressed by third parties on
          this Site are solely the views of such third party and we assume no
          responsibility or liability for the accuracy of any statement made by
          such third party.
        </Content>
        <Content>
          <b>INDEMNIFICATION:</b> You hereby agree to hold harmless, defend and
          indemnify us, our principals, owners, officers, directors, managers,
          employees, contractors, agents, other affiliated companies, suppliers,
          successors, and assigns from all liabilities, claims, demands, and
          expenses, including attorney’s fees, that arise from or are related to
          (a) your use or misuse of the Site or Services, (b) your breach of
          these Terms of Use, (c) the content or subject matter of any
          information you provide to us, and/or (d) any negligent or wrongful
          act or omission by you in the use or misuse of the Site or the
          Services, including without limitation, infringement of third party
          intellectual property rights. You agree to waive, to the fullest
          extent permitted by law, all laws that may limit the efficacy of such
          indemnifications or releases.
        </Content>
        <Content>
          <b>INDEMNIFICATION:</b> You hereby agree to hold harmless, defend and
          indemnify us, our principals, owners, officers, directors, managers,
          employees, contractors, agents, other affiliated companies, suppliers,
          successors, and assigns from all liabilities, claims, demands, and
          expenses, including attorney’s fees, that arise from or are related to
          (a) your use or misuse of the Site or Services, (b) your breach of
          these Terms of Use, (c) the content or subject matter of any
          information you provide to us, and/or (d) any negligent or wrongful
          act or omission by you in the use or misuse of the Site or the
          Services, including without limitation, infringement of third party
          intellectual property rights. You agree to waive, to the fullest
          extent permitted by law, all laws that may limit the efficacy of such
          indemnifications or releases.
        </Content>
        <Content>
          <b>LIMITATION ON LIABILITY:</b> IN NO EVENT SHALL WE BE LIABLE FOR ANY
          DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, EXEMPLARY, OR
          CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM ANY
          LOSS OF USE, LOSS OF PROFITS, LITIGATION, OR ANY OTHER PECUNIARY LOSS,
          WHETHER BASED ON BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE),
          PRODUCT LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN ANY WAY
          CONNECTED WITH THIS SITE OR THE PROVISION OF OR FAILURE TO MAKE
          AVAILABLE ANY PRODUCTS, GOODS, OR SERVICES, EVEN IF ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. YOU FURTHER AGREE AND ACKNOWLEDGE THAT WE
          SHALL NOT BE LIABLE FOR ANY DAMAGES THAT ARISE FROM YOUR SALE OR
          PURCHASE OF ANY PRODUCTS, INCLUDING PHARMACEUTICAL PRODUCTS, MADE
          AVAILABLE FOR SALE, OR PURCHASED BY, PERSONS OR ENTITIES USING THE
          SITE. IN NO EVENT SHALL OUR TOTAL LIABILITY EXCEED THE AGGREGATE FEES
          PAID TO US AS PART OF THE TRANSACTION GIVING RISE TO THE CLAIM.
        </Content>
        <Content>
          <b>DISCLAIMER:</b> THE SITE IS PROVIDED BY THE US ON AN “AS IS” AND
          “AS AVAILABLE” BASIS. WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY
          KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
          WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. WE
          DO NOT WARRANT THAT THE SITE WILL BE UNINTERRUPTED, FREE FROM ERROR,
          OR FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS.
        </Content>
        <Content>
          We will use reasonable efforts to keep the Site content timely and
          accurate, but we make no guarantees, and disclaim any implied warranty
          or representation about its accuracy, relevance, timeliness,
          completeness, or appropriateness for a particular purpose. We assume
          no liability arising from or relating to the delay, failure,
          interruption, or corruption of any data or other information
          transmitted in connection with use of the Site.
        </Content>
        <Content>
          <b>GOVERNING LAW:</b> These Terms of Use shall be governed by and
          construed in accordance with the laws of the State of North Carolina,
          as amended from time to time, without regard to principles of
          conflicts of law.
        </Content>
        <Content>
          <b>AGREEMENT TO ARBITRATE, JURISDICTION, VENUE:</b> YOU AGREE THAT ANY
          DISPUTE, CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING TO THESE
          TERMS OF USE OR THE BREACH, TERMINATION, ENFORCEMENT, INTERPRETATION
          OR VALIDITY THEREOF OR THE USE OF THE SERVICES (COLLECTIVELY,
          “DISPUTE(S)”) WILL BE SETTLED BY BINDING ARBITRATION. This arbitration
          clause governs all disputes, except that each party retains the right
          to seek injunctive or other equitable relief in a court of competent
          jurisdiction to prevent the actual or threatened infringement,
          misappropriation or violation of a party’s copyrights, trademarks,
          trade secrets, patents or other intellectual property rights (an “IP
          Protection Action”).
        </Content>
        <Content>
          Without limiting the foregoing, you may decline the above arbitration
          provisions and you may retain the right to litigate any other Dispute
          if you provide us with written notice of your desire to do so, by U.S.
          mail or express courier to the address contained in the “Notices”
          section below, within fifteen (15) days following the date you first
          agree to these Terms of Use (such notice, an “Arbitration Opt-out
          Notice”). If you don’t provide us with an Arbitration Opt-out Notice
          within the fifteen (15) day period, you will be deemed to have
          knowingly and intentionally waived your right to litigate any Dispute
          except as expressly set forth above. The exclusive jurisdiction and
          venue of any IP Protection Action or, if you timely provide us with an
          Arbitration Opt-out Notice, any other claims, will be the state and
          federal courts located in Wake County, North Carolina and each of the
          parties hereto waives any objection to jurisdiction and venue in such
          courts.
        </Content>
        <Content>
          UNLESS YOU TIMELY PROVIDE US WITH AN ARBITRATION OPT-OUT NOTICE, YOU
          ACKNOWLEDGE AND AGREE THAT YOU AND WE ARE WAIVING THE RIGHT TO A TRIAL
          BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY
          PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING.
        </Content>
        <Content>
          THE PARTIES AGREE TO ARBITRATE A DISPUTE SOLELY ON AN INDIVIDUAL BASIS
          AND EACH WAIVES THE RIGHT TO PARTICIPATE IN A CLASS ACTION, PRIVATE
          ATTORNEY GENERAL, OR OTHER REPRESENTATIVE OR CONSOLIDATED ARBITRATION
          OR PROCEEDING IN CONNECTION WITH ANY DISPUTE. THE ARBITRATOR MAY NOT
          CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS, AND MAY NOT OTHERWISE
          PRESIDE OVER ANY FORM OF ANY CLASS OR REPRESENTATIVE PROCEEDING.
          MOREOVER, THE ARBITRATOR WILL HAVE NO AUTHORITY TO AWARD PUNITIVE,
          EXEMPLARY, INDIRECT, SPECIAL DAMAGES OR ANY OTHER DAMAGES NOT MEASURED
          BY THE PREVAILING PARTY’S ACTUAL DAMAGES, EXCEPT AS REQUIRED BY LAW.
        </Content>
        <Content>
          Any arbitration occurring pursuant to this section will be
          administered by the American Health Lawyers Association (“AHLA”) in
          accordance with the Rules of Procedure for Commercial Arbitration
          before a single arbitrator with the locale of all hearing requiring
          physical attendance of the parties to occur in Wake County, North
          Carolina. Any judgment award rendered by the arbitrator may be entered
          in any court having jurisdiction thereof.
        </Content>
        <Content>
          This section will survive any termination of these Terms of Use.
        </Content>
        <Content>
          <b>ARBIRTRATION FEES AND TIME TO BRING A CLAIM:</b> In the event any
          litigation or controversy, including arbitration, arises out of or in
          connection with this Agreement between the parties hereto, the
          prevailing party in such litigation, arbitration or controversy, shall
          be entitled to recover from the other party or parties, all reasonable
          attorneys’ fees, expenses and suit costs, including those associated
          within the appellate or post judgment collection proceedings.
          Notwithstanding the foregoing, the costs of any arbitration, as
          described in the previous section, including without limitation, the
          fees of the arbitrator (but excluding each party’s attorney’s fees)
          shall be paid by the claimant who initiates an arbitration.
        </Content>
        <Content>
          Any cause of action or claim you may have with respect to us must be
          commenced within one (1) year after it arises. This section will
          survive any termination of these Terms of Use.
        </Content>
        <Content>
          NOTICES: We may provide notice to you by e-mail to the e-mail address
          You provided during registration, by a general notice on the Site, or
          by written communication delivered by first class U.S. mail or express
          courier to your address on record in your account information. You may
          give notice to us at any time, in writing, delivered by first class
          U.S. mail or express courier to 2141 East Williams St., Suite 106,
          Apex, NC, 27539.
        </Content>
      </ContainerPending>
      <Footer />
    </Container>
  );
};

export { TermsAndConditionsView };
