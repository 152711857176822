import { URL_V1 } from 'rx-api/api/constants';

export const GET_PRODUCT_BY_ID_URL = `${URL_V1}/products`;
export const GET_PRODUCTS_URL = `${URL_V1}/products`;
export const GET_FEATURED_PRODUCTS_URL = `${URL_V1}/pharmacist/catalog/featured`;

export const GET_PRODUCT_BY_ID_KEY = `GET_PRODUCT_BY_ID_KEY`;
export const GET_PRODUCTS_KEY = `GET_PRODUCTS_KEY`;

export const GET_FEATURED_PRODUCTS_KEY = 'GET_FEATURED_PRODUCTS_KEY';
