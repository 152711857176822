import { IPutPharmacistPharmacyDTO } from 'rx-domain';
import { useRxMutation } from 'rx-api/utils';
import { PUT_PHARMACY_DATA_URL } from './constants';

export const usePutPharmacistPharmacy = (id: string | number) => {
  return useRxMutation<IPutPharmacistPharmacyDTO, unknown>({
    method: 'put',
    url: PUT_PHARMACY_DATA_URL(id),
  });
};
