import { Skeleton } from '@material-ui/lab';
import React from 'react';

export const NewPurchaseOrderLoadingPharmacy: React.FC = () => {
  return (
    <section>
      <Skeleton height={50} />
      <Skeleton height={50} />
      <Skeleton height={50} />
    </section>
  );
};
