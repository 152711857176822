import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { IconButton, ClickAwayListener } from '@material-ui/core';

import { AccountCircle as AccountCircleIcon } from '@material-ui/icons';

import { useAuth } from 'rx-hooks';
import { usePopper } from 'react-popper';
import { useHistory } from 'react-router-dom';
import { usePostAuthLogout } from 'rx-api';

import BoxIcon from '../../../../assets/icons/box-icon.svg';
import LogoutIcon from '../../../../assets/icons/logout-icon.svg';
import UserDarkIcon from '../../../../assets/icons/user-icon-dark.svg';

import {
  HeaderActionUserPopperName,
  HeaderActionUserPopperItem,
} from '../atoms';

const UserIcon = styled(AccountCircleIcon)`
  cursor: pointer;
`;

const PopperContainer = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
  padding: 10px;
  text-align: center;
  width: 200px;
  z-index: 999;

  font-family: ${({ theme }) => theme.fonts.robotoLight};

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;

    &:after {
      content: ' ';
      position: absolute;
      top: -15px;
      left: 0px;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      background-color: white;
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    }
  }
`;

const HeaderActionUserPopper = (): JSX.Element => {
  const [isVisible, setVisibility] = useState(false);

  const referenceElement = useRef(null);
  const popperElement = useRef(null);
  const [arrowRef, setArrowRed] = useState<HTMLDivElement | null>(null);

  const history = useHistory();
  const { firstName, type } = useAuth();
  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      modifiers: [
        { name: 'arrow', options: { element: arrowRef } },
        {
          name: 'offset',
          options: {
            offset: [-40, 4],
          },
        },
      ],
    }
  );

  const mutation = usePostAuthLogout();

  const redirectOrders = () => {
    history.push('/orders');
  };

  const redirectAccount = () => {
    history.push('/settings/profile');
  };

  const redirectLogout = async () => {
    try {
      await mutation.mutateAsync();
      localStorage.clear();
      history.push('/');
      window.location.reload();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setVisibility(false)}>
      <div>
        <IconButton
          aria-label="settings"
          ref={referenceElement}
          onClick={() => setVisibility((val) => !val)}
        >
          <UserIcon color="inherit" fontSize="medium" />
        </IconButton>

        {isVisible && (
          <PopperContainer
            ref={popperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <div ref={setArrowRed} style={styles.arrow} className="arrow" />
            <HeaderActionUserPopperName text={firstName} />
            {type === 'pharmacist' && (
              <HeaderActionUserPopperItem
                text="My Orders"
                src={BoxIcon}
                onClick={redirectOrders}
              />
            )}
            {type !== 'admin' && (
              <HeaderActionUserPopperItem
                text="Account"
                src={UserDarkIcon}
                onClick={redirectAccount}
              />
            )}
            <HeaderActionUserPopperItem
              text="Log Out"
              src={LogoutIcon}
              onClick={redirectLogout}
            />
          </PopperContainer>
        )}
      </div>
    </ClickAwayListener>
  );
};

export { HeaderActionUserPopper };
