import React from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';

export interface ISellerShippingSettingsEditFormProps {
  minimumOrderAmount: string;
  groundShipping: string;
  overnightShipping: string;
  minimumOrderAmountError: boolean;
  groundShippingError: boolean;
  overnightShippingError: boolean;
  isLoading: boolean;
  onSubmit: (e: any) => void;
  onChangeMinimumOrderAmount: (e: any) => void;
  onChangeGroundShipping: (e: any) => void;
  onChangeOvernightShipping: (e: any) => void;
  onClickCancel: (e: any) => void;
}

export const SellerShippingSettingsEditForm = (
  props: ISellerShippingSettingsEditFormProps
): JSX.Element => {
  return (
    <form onSubmit={props.onSubmit}>
      <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Minimum Order Amount</Typography>
          <Typography variant="subtitle2">
            Set the minimum amount of money for buyer to proceed to the
            checkout.
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <TextField
            label="Minimum Order Amout"
            placeholder="$150.00"
            variant="outlined"
            name="minimumOrder"
            id="minimumOrderAmount"
            InputLabelProps={{
              shrink: true,
            }}
            value={props.minimumOrderAmount}
            onChange={props.onChangeMinimumOrderAmount}
            type="number"
            helperText="Cannot be negative or zero"
            error={props.minimumOrderAmountError}
            required
            fullWidth
          ></TextField>
        </Grid>
      </Grid>

      <Typography variant="subtitle1">Free Shipping Rates</Typography>
      <Typography variant="subtitle2">
        Set how much is required in order to buyers have FREE shipping if
        allowed.
      </Typography>

      <div style={{ marginTop: '1rem' }}>
        <Grid
          container
          spacing={2}
          style={{ marginTop: '1rem', marginBottom: '1rem' }}
        >
          <Grid item xs={3}>
            <TextField
              label="Ground Shipping"
              placeholder="$150.00"
              variant="outlined"
              name="groundShipping"
              id="groundShipping"
              onChange={props.onChangeGroundShipping}
              value={props.groundShipping}
              type="number"
              helperText="Cannot be negative"
              error={props.groundShippingError}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            ></TextField>
          </Grid>

          <Grid item xs={3}>
            <TextField
              label="Overnight Shipping"
              placeholder="$150.00"
              variant="outlined"
              name="overnightShipping"
              id="overnightShipping"
              value={props.overnightShipping}
              type="number"
              helperText="Cannot be negative"
              error={props.overnightShippingError}
              onChange={props.onChangeOvernightShipping}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            ></TextField>
          </Grid>
        </Grid>
      </div>

      <Grid container spacing={2}>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            disabled={props.isLoading}
            onClick={props.onClickCancel}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={props.isLoading}
            type="submit"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
