import React, { InputHTMLAttributes, useState } from 'react';
import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';
import MaskedInput, { Mask } from 'react-text-mask';

import { useField } from 'formik';

import { DataState } from 'rx-domain';
import { MASK_PHONE_NUMBER } from 'rx-utils';

import { PopperInfo } from '../../popper/PopperInfo';
import { PopperPassword } from '../../popper/PopperPassword';
import { InputText, InputTextContainer } from '../InputText';
import { InputErrorEmailMessage } from '../InputErrorEmailMessage';
import { InputTitle } from '../InputTitle';
import { RxInputIcon } from '../RxInputIcon';
import { RxInputMessage } from '../RxInputMessage';
import { RxInputPasswordIcon } from '../RxInputPasswordIcon';
import { RxInputErrorMessage } from '../RxInputErrorMessage';

export type RxInputTextProps = InputHTMLAttributes<HTMLInputElement> &
  LayoutProps & {
    label?: string;
    state?: DataState;
    message?: string;
    showPasswordHelper?: boolean;
    inputError: boolean;
    showPassword: boolean;
    name: string;
    popperInfoText?: string;
    mask?: Mask;
    withMask?: boolean;
    upperLabel?: boolean;
  };

const Container = styled.div<LayoutProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${layout}
`;

const InputContainer = styled.div<LayoutProps & { error: boolean }>`
  position: relative;
  ${layout};

  ${({ error }) =>
    !error &&
    css`
      margin-bottom: 30px;
    `}
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const PopperIcon = styled(PopperInfo)`
  margin-left: 12px;
`;

const RxInputText = ({
  mask,
  label,
  state,
  width,
  message,
  withMask,
  className,
  upperLabel,
  inputError,
  showPassword,
  popperInfoText,
  showPasswordHelper,
  ...props
}: RxInputTextProps): JSX.Element => {
  const [inputType, setInputType] = useState(props.type);
  const [passwordPopup, setPasswordPopup] = useState<boolean>(false);

  const [field, meta] = useField({ ...props, type: inputType });

  const formError = meta.touched && meta.error;
  const iconState = state !== 'none';
  const inputErrorState = state === 'error' && inputError;

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur(event);
    setPasswordPopup(false);
  };

  const onFocus = () => {
    setPasswordPopup(true);
  };

  const onShowPassword = () => {
    if (inputType === 'text' || !inputType) {
      setInputType('password');
    } else if (inputType === 'password') {
      setInputType('text');
    }
  };

  const passwordHelpers = showPasswordHelper
    ? {
        onBlur,
        onFocus,
      }
    : {
        onBlur: field.onBlur,
      };

  return (
    <Container className={className}>
      <LabelContainer>
        {label && (
          <InputTitle
            title={label}
            htmlFor={props.name}
            error={state === 'error' && inputError}
            upperLabel={upperLabel}
            disabled={props.disabled}
          />
        )}
        {popperInfoText && <PopperIcon text={popperInfoText} />}
      </LabelContainer>
      <InputContainer width={width} error={!!formError}>
        <PopperPassword visible={passwordPopup} password={field.value}>
          {!withMask && (
            <InputText
              {...field}
              {...passwordHelpers}
              {...props}
              id={props.name}
              error={inputErrorState}
              type={inputType}
            />
          )}
          {withMask && mask && (
            <MaskedInput
              mask={mask}
              guide={false}
              {...field}
              {...passwordHelpers}
              {...props}
              render={(
                ref: (inputElement: HTMLInputElement) => void,
                maskProps
              ) => {
                return (
                  <InputTextContainer
                    ref={ref}
                    {...maskProps}
                    id={props.name}
                    error={inputErrorState}
                    type={inputType}
                  />
                );
              }}
            />
          )}
          {iconState && <RxInputIcon state={state} />}
          {showPassword && <RxInputPasswordIcon onClick={onShowPassword} />}
        </PopperPassword>
      </InputContainer>
      {formError && (
        <RxInputErrorMessage data-testid={`${field.name}-error`}>
          {meta.error === 'EMAIL_ALREADY_USE' ? (
            <InputErrorEmailMessage />
          ) : (
            meta.error
          )}
        </RxInputErrorMessage>
      )}
      {message && (
        <RxInputMessage error={!!formError}>{message}</RxInputMessage>
      )}
    </Container>
  );
};

RxInputText.defaultProps = {
  mask: MASK_PHONE_NUMBER,
  label: '',
  upperLabel: true,
  message: '',
  state: 'none',
  showPassword: false,
  inputError: false,
  showPasswordHelper: false,
};

export { RxInputText };
