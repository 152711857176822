import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

type IRequest = {
  id: number;
};

export const useDeleteAPIKey = () => {
  const onRequest = (req: IRequest) => {
    return http.delete(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/manufacturers/${req.id}/api-key`
    );
  };

  return useMutation(onRequest);
};
