import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Menu, MenuItem } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import { useHistory } from 'react-router-dom';

import { usePaginationQueries } from 'rx-hooks';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  ${({ theme }) => css`
    height: 100%;

    ${theme.lessThan('ipadPro')} {
      width: 20%;
    }
  `}
`;

const SortBySelect = styled.button`
  ${({ theme }) => css`
    background-color: white;
    height: 2.5rem;
    border-radius: 10px;
    border: 1px solid #9fa6b9;
    margin-left: 1.5rem;
    font-size: ${theme.sizes.medium};
    padding: 0.5rem;
    outline: none;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `}
`;

type IFilterType = {
  order: 'id' | 'createdAt' | 'ndc' | 'shippingName';
  orderCriteria: 'asc' | 'desc';
  tag: string;
};

const filters: Array<IFilterType> = [
  {
    order: 'createdAt',
    orderCriteria: 'asc',
    tag: 'Created At Soonest',
  },
  {
    order: 'createdAt',
    orderCriteria: 'desc',
    tag: 'Created At Furthest',
  },
  {
    order: 'ndc',
    orderCriteria: 'asc',
    tag: 'NDC A-Z',
  },
  {
    order: 'ndc',
    orderCriteria: 'desc',
    tag: 'NDC Z-A',
  },
  {
    order: 'shippingName',
    orderCriteria: 'asc',
    tag: 'Seller A-Z',
  },
  {
    order: 'shippingName',
    orderCriteria: 'desc',
    tag: 'Seller Z-A',
  },
];

export const AdminProductsSortButton = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const history = useHistory();

  const {
    onMakeURL,
    querySearch,
    querySortBy,
    querySortOrder,
    querySearchType,
  } = usePaginationQueries();

  const filterType =
    filters.find(
      (f) => f.order === querySortBy && f.orderCriteria === querySortOrder
    )?.tag ?? '';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value: IFilterType) => {
    setAnchorEl(null);

    const fullURL = onMakeURL({
      search: querySearch,
      t: querySearchType,
      o: value.orderCriteria,
      b: value.order,
    });

    history.push(fullURL);
  };

  return (
    <Container>
      <SortBySelect
        aria-controls="search-admin-orderby-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ImportExportIcon />
        <span>{filterType || 'Sort By'}</span>
      </SortBySelect>
      <Menu
        id="search-admin-orderby-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {filters.map((filter) => (
          <MenuItem onClick={() => handleClose(filter)} key={filter.tag}>
            {filter.tag}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};
