import { URL_V1 } from 'rx-api/api/constants';
import { BUYER_URL_V1 } from '../constants';

export const GET_BUYER_ORDERS_URL = `${URL_V1}/purchase-orders`;
export const DOWNLOAD_BUYER_ORDERS_INVOICE_URL = `${BUYER_URL_V1}/orders/{id}/invoice`;
export const DOWNLOAD_BUYER_ORDERS_RECEIPT_URL = `${BUYER_URL_V1}/orders/{id}/receipt`;

export const GET_BUYER_ORDERS_KEY = 'GET_BUYER_ORDERS_KEY';

export const GET_BUYER_ORDER_BY_ID_URL = (id: string) =>
  `${BUYER_URL_V1}/orders/${id}`;
export const GET_BUYER_ORDER_BY_ID_KEY = 'get-buyer-order-by-id';
