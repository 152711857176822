import React from 'react';

import { InfoContainer, InfoContent, InfoTitle } from './index.styles';

type IProps = {
  title: string;
  content: string | JSX.Element;
};

export const TransactionPODetailsInfoRow: React.FC<IProps> = (props) => {
  return (
    <InfoContainer>
      <InfoTitle>{props.title}</InfoTitle>
      <InfoContent>{props.content}</InfoContent>
    </InfoContainer>
  );
};
