import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { useGetProductsByActiveIngredient } from '../../../../../rx-api/api/pharmacist/v2Products/useGetProductsByActiveIngredient/useGetProductsByActiveIngredient';
import { Search as SearchIcon } from '@material-ui/icons';
import { AppDispatch, setBuyerSearchText } from '../../../../../rx-store';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';
import { onMakeURL } from '../../../../../rx-utils';
import { useHistory } from 'react-router-dom';
import { usePaginationQueries } from '../../../../../rx-hooks';
import { useGetBuyerProducts } from '../../../../../rx-api';

const Input = styled(TextField)`
  ${({ theme }) => css`
    background-color: white;
    height: 100%;
    width: 100%;
    margin: 0;
    // ${theme.lessThan('ipadPro')} {
    //   width: 20%;
    // }
  `}
`;

const ButtonContainer = styled.button`
  ${({ theme }) => css`
    width: 8.75rem;
    height: 40px;
    background-color: ${theme.colors.blue[300]};
    border: 1px solid ${theme.colors.blue[300]};
    border-radius: 4px;
    color: white;
    text-transform: uppercase;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    cursor: pointer;

    &:disabled {
      background-color: rgb(159, 166, 185, 0.7);
      border: none;
      cursor: no-drop;
    }

    ${theme.lessThan('ipadPro')} {
      width: 20%;
      font-size: ${theme.sizes.extraSmall};
    }
  `}
`;

const Icon = styled(SearchIcon)`
  ${({ theme }) => css`
    margin-right: 0.5rem;

    ${theme.lessThan('ipadPro')} {
      &.MuiSvgIcon-root {
        //display: none;
      }
    }
  `}
`;

const HiddenText = styled.span`
  ${({ theme }) => css`
    margin-left: 0.25rem;

    ${theme.lessThan('ipadPro')} {
      display: none;
    }
  `}
`;

const useDebounce = (value: string, delay: number) => {
  const [isLoadingDebounce, setIsLoadingDebounce] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    setIsLoadingDebounce(true);
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsLoadingDebounce(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return { debouncedValue, isLoadingDebounce };
};

const SearchByName = () => {
  const getProductsByActiveIngredient = useGetProductsByActiveIngredient();
  const dispatch: AppDispatch = useDispatch();

  const [inputValue, setInputValue] = useState('');

  const history = useHistory();
  const {
    queryViewType,
    querySortOrder,
    querySortBy,
    queryMinPrice,
    queryMaxPrice,
  } = usePaginationQueries();

  const { debouncedValue, isLoadingDebounce } = useDebounce(inputValue, 1000);
  const { data, isLoading } = useGetBuyerProducts({
    limit: debouncedValue !== '' ? '4' : '1',
    name: debouncedValue,
  });

  const onSearchInput = async (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;

    if (!target.value) {
      dispatch(setBuyerSearchText(''));
      return;
    }
    dispatch(setBuyerSearchText(target.value.trim()));
  };

  const capitalizeFirstLetter = (string: string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <Autocomplete
          style={{ width: '800px' }}
          freeSolo
          id="search-buyer-name"
          disableClearable
          options={
            inputValue !== '' && !isLoading && !isLoadingDebounce
              ? data?.data?.results || []
              : []
          }
          renderOption={(option: any) => {
            if (option?.inputValue) {
              return <b>{option.name}</b>;
            }
            return (
              <Typography noWrap>
                {capitalizeFirstLetter(option.name)}
              </Typography>
            );
          }}
          onChange={(event, value: any) => {
            let valueUsed: string;
            if (value && value.inputValue) {
              valueUsed = value.inputValue;
            } else if (value && value.name) {
              valueUsed = value.name;
            } else {
              valueUsed = value;
            }
            const searchURL = onMakeURL({
              viewType: queryViewType,
              o: querySortOrder,
              b: querySortBy,
              minPrice: queryMinPrice,
              maxPrice: queryMaxPrice,
              search: valueUsed.trim(),
              t: 'name',
            });
            history.push(`/products/${searchURL}`);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={(option: any) => {
            if (option?.inputValue) {
              return option.name;
            }
            return capitalizeFirstLetter(option.name);
          }}
          filterOptions={(options: any, params) => {
            const filtered = [...options];

            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Search for all products that match "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          renderInput={(params) => (
            <Input
              {...params}
              margin="normal"
              onKeyUp={onSearchInput}
              label="Search Term"
              variant="outlined"
              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
        <ButtonContainer
          style={{ height: '56px', marginLeft: '16px' }}
          data-cy="search-products-btn"
        >
          <Icon /> <HiddenText> Search</HiddenText>
        </ButtonContainer>
      </div>
    </div>
  );
};

export default SearchByName;
