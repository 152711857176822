import { useState } from 'react';

import { useSkipPage } from 'rx-hooks/utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDrugsInventory = () => {
  const [length, setLength] = useState(0);
  const { skip, totalPages } = useSkipPage(length);

  return {
    setLength,
    skip,
    totalPages,
  };
};

export { useDrugsInventory };
