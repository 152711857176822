import React, { createContext, useContext, useState } from 'react';
import { IOrderPharmacist, ITransactionPurchaseOrder } from 'rx-domain';

type CancelAction = {
  idCancel: number | null;
  type: 'order' | 'purchaseOrder' | 'purchaseOrderBuyer';
};

type ContextProps = {
  adminPurchaseOrder: null | ITransactionPurchaseOrder;
  purcharseOrder: null | IOrderPharmacist;
  status: string;
  onSetStatus: (value: string) => void;
  cancelModalVisible: boolean;
  EDIModalVisible: boolean;
  onOpenCancelModal: (
    po?: null | IOrderPharmacist,
    cancelActionObj?: CancelAction | null
  ) => void;
  onOpenEDIModal: (
    po?: null | IOrderPharmacist,
    cancelActionObj?: CancelAction | null
  ) => void;
  onCloseCancelModal: () => void;
  onCloseEDIModal: () => void;
  cancelAction: CancelAction | null;
  onSetCancel: (cancelAction: CancelAction | null) => void;
};

const OrderContext = createContext<ContextProps | undefined>(undefined);

export const OrderProvider: React.FC = ({ children }) => {
  const [status, setStatus] = useState<string>('all');
  const [cancelAction, setCancelAction] = useState<CancelAction | null>(null);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [sendEDIModal, setSendEDIModal] = useState<boolean>(false);
  const [purcharseOrder, setPurcharseOrder] = useState<null | IOrderPharmacist>(
    null
  );
  const [adminPurchaseOrder, setAdminPurchaseOrder] =
    useState<null | ITransactionPurchaseOrder>(null);

  const onSetStatus = (value: string) => {
    setStatus(value);
  };

  const onCloseCancelModal = () => {
    setAdminPurchaseOrder(null);
    setPurcharseOrder(null);
    setCancelAction(null);
    setCancelModal(false);
  };

  const onCloseEDIModal = () => {
    setAdminPurchaseOrder(null);
    setPurcharseOrder(null);
    setCancelAction(null);
    setSendEDIModal(false);
  };

  const onOpenCancelModal = (
    po: null | IOrderPharmacist = null,
    cancelActionObj: CancelAction | null = null
  ) => {
    setCancelModal(true);
    setPurcharseOrder(po);
    setCancelAction(cancelActionObj);
  };

  const onOpenEDIModal = (
    po: null | IOrderPharmacist = null,
    cancelActionObj: CancelAction | null = null
  ) => {
    setSendEDIModal(true);
    setPurcharseOrder(po);
    setCancelAction(cancelActionObj);
  };

  const onSetCancel = (cancelActionObj: CancelAction | null) => {
    setCancelAction(cancelActionObj);
  };

  return (
    <OrderContext.Provider
      value={{
        cancelAction,
        onSetCancel,
        adminPurchaseOrder,
        purcharseOrder,
        status,
        onSetStatus,
        onCloseCancelModal,
        onCloseEDIModal,
        onOpenCancelModal,
        onOpenEDIModal,
        cancelModalVisible: cancelModal,
        EDIModalVisible: sendEDIModal,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrder = (): ContextProps => {
  const context = useContext(OrderContext);

  if (context === undefined) {
    throw new Error('Order must be used within a OrderProvider');
  }

  return context;
};
