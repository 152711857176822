import React, { useState } from 'react';
import ReactGA from 'react-ga';
import styled, { css } from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { Formik, Form, Field, FormikHelpers } from 'formik';

import { useLogin } from 'rx-contexts';
import { Input, RxInputText, ButtonNormal } from 'rx-core';
import { usePostAuthLogin } from 'rx-api';

import { LoginSchema } from 'utils/validators/login/login.validator';
import { LoginTitle, LoginForgot, LoginCreateAccount } from '../atoms';
import { LoginFormTemplate } from '../template';

type IForm = {
  email: string;
  password: string;
};

const Container = styled.div`
  padding: 90px 62px 38px 20px;
  height: 100%;
  width: 100%;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipad')} {
      padding: 84px 30px 0px 22px;
    }

    ${lessThan('ipadPro')} {
      padding: 40px 24px 40px 24px;
    }
  `}
`;

const ButtonContainer = styled.div`
  margin-top: 8px;
  text-align: center;
  width: 100%;
`;

const LoginTitleForm = styled(LoginTitle)`
  margin-bottom: 72px;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipadPro')} {
      margin-bottom: 40px;
    }
  `}
`;

const LoginCreateAccountForm = styled.div`
  bottom: 36px;
  right: 0;
  width: 100%;
  position: absolute;
  text-align: center;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipad')} {
      text-align: justify;
      position: inherit;
      margin-top: 36px;
    }
  `}
`;

const LoadingIcon = styled(CircularProgress)`
  ${({ theme }) => css`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: ${theme.colors.blue[100]} !important;
  `}
`;

const FormButton = styled(ButtonNormal)`
  ${() => css`
    margin-bottom: 14px;
    width: 100%;
  `}
`;

const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
`;

const LoginForm = (): JSX.Element => {
  const initialValues: IForm = {
    email: '',
    password: '',
  };

  const [loading, setLoading] = useState<boolean>(false);

  const mutation = usePostAuthLogin();

  const { onSetCSRFToken, onSetEmail } = useLogin();

  const onSubmit = async (
    values: IForm,
    { resetForm, setErrors }: FormikHelpers<IForm>
  ) => {
    try {
      setLoading(true);
      const loginResponse = await mutation.mutateAsync(values);

      ReactGA.event({
        category: 'User',
        action: 'Log in process finished',
      });

      if (loginResponse.data.csfr) {
        onSetEmail(values.email);
        onSetCSRFToken(loginResponse.data.csfr);
        resetForm({});
        return;
      }

      localStorage.setItem('_userid', loginResponse.data.id.toString());
      localStorage.setItem(
        '_user',
        JSON.stringify({
          firstName: loginResponse.data.firstName,
          lastName: loginResponse.data.lastName,
          email: loginResponse.data.email,
          type: loginResponse.data.type,
          companyName: loginResponse.data.companyName,
          paymentTerm: loginResponse.data.paymentTerm,
          permissions: loginResponse.data.permissions,
          isEdiEnabled: !!loginResponse.data?.isEdiEnabled,
        })
      );

      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({ ecommerce: null });
      // @ts-ignore
      window.dataLayer.push({
        event: 'user_login',
        user_id: loginResponse.data.id,
      });

      window.location.reload();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          setErrors({
            password: err.response.data.message,
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginFormTemplate>
      <Container>
        {loading && <LoadingIcon size={26} thickness={6} />}
        <LoginTitleForm text="Login into to your account" />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={LoginSchema}
        >
          <Form>
            <Field
              name="email"
              placeholder="you@email.com"
              label="Company Email Address"
              type="email"
              data-testid="email"
              component={Input}
              data-cy="login-email"
            />
            <ForgotPasswordContainer>
              <RxInputText
                name="password"
                label="Password"
                type="password"
                data-testid="password"
                showPassword={true}
                data-cy="login-password"
              />
            </ForgotPasswordContainer>
            <ButtonContainer>
              <FormButton
                text="Login"
                type="submit"
                onClick={() => onSubmit}
                data-cy="login-submit"
              />
              <LoginForgot />
            </ButtonContainer>
          </Form>
        </Formik>
        <LoginCreateAccountForm>
          <LoginCreateAccount />
        </LoginCreateAccountForm>
      </Container>
    </LoginFormTemplate>
  );
};

export { LoginForm };
