import React from 'react';
import styled, { css } from 'styled-components';
import { Link, useRouteMatch } from 'react-router-dom';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

type ISettingsNavItemProps = {
  text: string;
  to: string;
  dataCy: string;
};

const Container = styled(Link)<{ $active: boolean }>`
  display: flex;
  align-items: center;

  justify-content: space-between;

  background: #ffffff;
  box-shadow: 5px 5px 25px rgba(51, 60, 68, 0.08);
  border-radius: 0.25rem;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  height: 76px;
  width: 100%;

  text-decoration: none;
  color: black;

  ${({ $active }) =>
    $active &&
    css`
      background: #64a8ff;
    `}
`;

const Text = styled.span<{ $active: boolean }>`
  ${({ theme, $active }) => css`
    font-family: ${theme.fonts.robotoRegular};
    font-size: ${theme.sizes.medium};

    ${$active &&
    css`
      color: ${theme.colors.white[100]};
    `}
  `}
`;

const SettingsNavItem: React.FC<ISettingsNavItemProps> = (props) => {
  const match = useRouteMatch({
    path: props.to,
  });

  const isActive = !!match;

  return (
    <Container to={props.to} $active={isActive} data-cy={props.dataCy}>
      <Text $active={isActive}>{props.text}</Text>
      {!isActive && <ArrowForwardIcon />}
    </Container>
  );
};

export { SettingsNavItem };
