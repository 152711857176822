import React from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';

export interface ISellerShippingSettingsViewFormProps {
  minimumOrderAmount: string;
  groundShipping: string;
  overnightShipping: string;
  isLoading: boolean;
  onClickEdit: (e: any) => void;
}

export const SellerShippingSettingsViewForm = (
  props: ISellerShippingSettingsViewFormProps
): JSX.Element => {
  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Minimum Order Amount</Typography>
          <Typography variant="subtitle2">
            Set the minimum amount of money for buyer to proceed to the
            checkout.
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <TextField
            label="Minimum Order Amout"
            placeholder="$150.00"
            variant="outlined"
            name="minimumOrder"
            id="minimumOrderAmount"
            InputLabelProps={{
              shrink: true,
            }}
            value={props.minimumOrderAmount}
            type="number"
            required
            fullWidth
            disabled
          ></TextField>
        </Grid>
      </Grid>

      <Typography variant="subtitle1">Free Shipping Rates</Typography>
      <Typography variant="subtitle2">
        Set how much is required in order to buyers have FREE shipping if
        allowed.
      </Typography>

      <div style={{ marginTop: '1rem' }}>
        <Grid
          container
          spacing={2}
          style={{ marginTop: '1rem', marginBottom: '1rem' }}
        >
          <Grid item xs={3}>
            <TextField
              label="Ground Shipping"
              placeholder="$150.00"
              variant="outlined"
              name="groundShipping"
              id="groundShipping"
              value={props.groundShipping}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              required
              fullWidth
            ></TextField>
          </Grid>

          <Grid item xs={3}>
            <TextField
              label="Overnight Shipping"
              placeholder="$150.00"
              variant="outlined"
              name="overnightShipping"
              id="overnightShipping"
              value={props.overnightShipping}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
              disabled
            ></TextField>
          </Grid>
        </Grid>
      </div>

      <div>
        <Button
          variant="contained"
          color="primary"
          disabled={props.isLoading}
          onClick={props.onClickEdit}
        >
          Edit
        </Button>
      </div>
    </div>
  );
};
