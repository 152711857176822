import * as colors from 'rx-styles/colors';
import * as fonts from './fonts';

const LIGHT = {
  colors: {
    primary: colors.PRIMARY_COLOR,
    error: colors.ERROR_COLOR,
    info: colors.GRAY_LIGHT_TWO_COLOR,
    blue: {
      gradient: colors.BLUE_GRADIENT,
      100: colors.BLUE_PALE_COLOR,
      200: colors.BLUE_LIGHT_COLOR,
      300: colors.BLUE_DARK_COLOR,
      400: colors.BLUE_400,
      500: colors.RXRISE_BLUE_400,
    },
    gray: {
      50: colors.GRAY_50,
      100: colors.LIGHT_GRAY_COLOR,
      200: colors.GRAY_LIGHT_OPACITY_COLOR,
      300: colors.GRAY_LIGHT_COLOR,
      400: colors.GRAY_LIGHT_TWO_COLOR,
      500: colors.GRAY_DARK_COLOR,
    },
    black: {
      100: colors.BLACK_COLOR,
    },
    white: {
      100: colors.WHITE_DARK_COLOR,
    },
    green: {
      100: colors.GREEN_COLOR,
    },
    red: {
      100: colors.ERROR_COLOR,
      200: colors.RED_COLOR,
    },
    yellow: {
      100: colors.YELLOW_COLOR,
      200: colors.YELLOW_DARK_COLOR,
    },
  },
};

const SIZES = {
  extraSmall: '12px',
  small: '16px',
  medium: '18px',
  h2: '24px',
  h2Big: '36px',
  h1: '40px',
  h1Big: '46px',
};

const FONTS = {
  robotoLight: fonts.ROBOTO_LIGHT,
  robotoRegular: fonts.ROBOTO_REGULAR,
  robotoMedium: fonts.ROBOTO_MEDIUM,
  roobertLight: fonts.ROOBERT_LIGHT,
  roobertRegular: fonts.ROOBERT_REGULAR,
  roobertMedium: fonts.ROOBERT_MEDIUM,
  roobertBold: fonts.ROOBERT_BOLD,
};

export { LIGHT, SIZES, FONTS };
