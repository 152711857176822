import React from 'react';

import { TableRow, TableColumnTitle } from './index.styles';

export const ReportEmpty: React.FC = () => {
  return (
    <TableRow>
      <TableColumnTitle colSpan={12}>
        <span>"There is no orders matching your criteria"</span>
        <br />
        <span>Please try another filtering combination.</span>
      </TableColumnTitle>
    </TableRow>
  );
};
