import React from 'react';

import { GetSaleOrderPayments } from 'rx-domain';

import { Container } from './index.styles';
import { ReportItem } from '../PaymentsReportItem';
import { ReportEmpty } from '../PaymentsReportEmpty';
import { ReportSummary } from '../PaymentsReportSummary';

type IProps = {
  payments: GetSaleOrderPayments.SaleOrderPayment[];
  meta: GetSaleOrderPayments.SaleOrderPaymentMeta;
  totalItems: number;
  handleEditRow: any;
};

export const ReportItems: React.FC<IProps> = (props) => {
  return (
    <Container>
      {props.payments.map((payment, index) => (
        <ReportItem
          handleEditRow={props.handleEditRow}
          index={index}
          key={payment.saleOrderId}
          payment={payment}
        />
      ))}
      {props.payments.length === 0 && <ReportEmpty />}
      {props.totalItems > 1 && <ReportSummary meta={props.meta} />}
    </Container>
  );
};
