import { Button, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useDeletePharmacy,
  useReactivateAccount,
  useSuspendAccount,
} from 'rx-api';
import { useToast } from 'rx-hooks';
import { PharmacyDangerZoneDeleteModal } from './PharmacyDangerZoneDeleteModal';
import { SuspendAccountModal } from '../../../general/SuspendAccountModal';
import { ReactivateAccountModal } from '../../../general/ReactivateAccountModal';
import { useQueryClient } from 'react-query';

type PharmacyDangerZoneProps = {
  isDisabled: boolean;
  accountId: number;
};

export const PharmacyDangerZone: React.FC<PharmacyDangerZoneProps> = (
  props
) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isSuspendModalOpen, setIsSuspendModalOpen] = React.useState(false);
  const [isReactivateModalOpen, setIsReactivateModalOpen] =
    React.useState(false);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const deletePharmacy = useDeletePharmacy();
  const suspendAccount = useSuspendAccount();
  const reactivateAccount = useReactivateAccount();
  const queryClient = useQueryClient();
  const toast = useToast();

  const handleClickSuspend = () => {
    setIsSuspendModalOpen(true);
  };

  const handleClickReactivate = () => {
    setIsReactivateModalOpen(true);
  };

  const handleClickCloseSuspendModal = () => {
    setIsSuspendModalOpen(false);
  };

  const handleClickCloseReactivateModal = () => {
    setIsReactivateModalOpen(false);
  };

  const handleClickDelete = () => {
    setIsModalOpen(true);
  };

  const handleClickCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClickConfirmModal = async () => {
    try {
      await deletePharmacy.mutateAsync(id);
      toast.onSuccessToast('Pharmacy deleted');
      history.push('/pharmacies');
    } catch (err) {
      toast.onErrorToast('Error: could not delete pharmacy');
    }
  };

  const handleClickConfirmSuspendModal = async () => {
    try {
      await suspendAccount.mutateAsync(props.accountId);
      await queryClient.refetchQueries('get-admin-pharmacists-id', {
        active: true,
      });
      toast.onSuccessToast('Pharmacy suspended');
      setIsSuspendModalOpen(false);
    } catch (err) {
      toast.onErrorToast('Error: could not suspend pharmacy');
    }
  };

  const handleClickConfirmReactivateModal = async () => {
    try {
      await reactivateAccount.mutateAsync(props.accountId);
      await queryClient.refetchQueries('get-admin-pharmacists-id', {
        active: true,
      });
      toast.onSuccessToast('Pharmacy reactivated');
      setIsReactivateModalOpen(false);
    } catch (err) {
      toast.onErrorToast('Error: could not suspend pharmacy');
    }
  };

  return (
    <>
      <Typography variant="h5">Danger Zone</Typography>

      <div
        style={{
          marginTop: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {!props.isDisabled && (
          <>
            <div>
              <Typography variant="h6">Suspend Account</Typography>
              <Typography>
                Temporarily disable the account to prevent logging in.
              </Typography>
            </div>
            <Button variant="outlined" onClick={handleClickSuspend}>
              Suspend Pharmacy
            </Button>
          </>
        )}

        {props.isDisabled && (
          <>
            <div>
              <Typography variant="h6">Suspend Account</Typography>
              <Typography>Reactivate account and allow to log-in.</Typography>
            </div>
            <Button variant="outlined" onClick={handleClickReactivate}>
              Reactivate Account
            </Button>
          </>
        )}
      </div>

      <Divider style={{ margin: '1rem 0' }} />

      <div
        style={{
          marginTop: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Typography variant="h6">Delete Pharmacy</Typography>
          <Typography>Beware, this action is permanent.</Typography>
        </div>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClickDelete}
        >
          Delete Pharmacy
        </Button>
      </div>

      <PharmacyDangerZoneDeleteModal
        isOpen={isModalOpen}
        onClickClose={handleClickCloseModal}
        onClickConfirm={handleClickConfirmModal}
      />

      <SuspendAccountModal
        isOpen={isSuspendModalOpen}
        onClickClose={handleClickCloseSuspendModal}
        onClickConfirm={handleClickConfirmSuspendModal}
      />

      <ReactivateAccountModal
        isOpen={isReactivateModalOpen}
        onClickClose={handleClickCloseReactivateModal}
        onClickConfirm={handleClickConfirmReactivateModal}
      />
    </>
  );
};
