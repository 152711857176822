import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    margin-bottom: 1rem;
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
  `}
`;

export const TitleContainer = styled.div`
  display: block;
  margin-right: 9.25rem;
`;

export const PaymentMethodPicture = styled.img`
  display: block;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;
