import styled from 'styled-components';
import { RxInputText } from 'rx-core';

export const Container = styled.div``;

export const Input = styled(RxInputText)`
  width: 25%;
  margin-right: 1rem;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;
