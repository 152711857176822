import { Divider, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

interface IProps {
  error: string;
  products: {
    id: number;
    ndc: string;
    name: string;
    description: string;
    manufacturer: string;
  }[];
}

export const NewPurchaseOrderErrorList: React.FC<IProps> = (props) => {
  return (
    <>
      <Divider style={{ margin: '1rem 0' }} />

      <Alert elevation={1} variant="filled" severity="error">
        <Typography>{props.error}</Typography>

        {!!props.products.length &&
          props.products.map((product) => (
            <Typography key={product.id}>
              {product.ndc} {product.name} {product.description} (
              {product.manufacturer})
            </Typography>
          ))}
      </Alert>
    </>
  );
};
