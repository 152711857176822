import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useField, useFormikContext } from 'formik';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import { GetProducts } from 'rx-domain';
import { useShoppingCartOnline } from 'rx-hooks';

type IProps = GetProducts.ProductItem;

const Button = styled.button`
  ${({ theme }) => css`
    height: 3rem;
    width: 3rem;
    padding: 0.5rem;
    background-color: ${theme.colors.blue[300]};
    color: white;
    border: none;
    border-radius: 12px;
    font-size: ${theme.sizes.medium};
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
      background-color: ${theme.colors.gray[300]};
    }
  `}
`;

const ProductItemAddCart = (props: IProps): JSX.Element => {
  const [field, meta] = useField<number>('units');
  const { isSubmitting } = useFormikContext();

  const { cartItems } = useShoppingCartOnline();

  const matchItem = useMemo(() => {
    return cartItems.find((ci) => ci.product.id === props.id);
  }, [cartItems, props.id]);

  const formikValidation = meta.touched && !!meta.error;
  const checkoutValidation =
    field.value >= props.minimumQuantity && field.value <= props.units;

  return (
    <Button
      data-cy="products-shopping-cart-button"
      disabled={
        formikValidation ||
        (meta.touched && !checkoutValidation) ||
        isSubmitting
      }
      type="submit"
    >
      {matchItem ? <ReplayOutlinedIcon /> : <AddShoppingCartIcon />}
    </Button>
  );
};

export { ProductItemAddCart };
