import { ITransactionPurchaseOrder, ITransaction } from 'rx-domain';

import { useTransactions } from 'rx-contexts';

import {
  useAdminDownloadPurchaseOrderInvoice,
  useAdminDownloadPurchaseOrderReceipt,
  usePostOrdersInvoice,
  usePostOrdersReceipt,
  usePostSaleOrderInvoice,
} from 'rx-api';

type IParams = {
  transaction?: ITransaction;
  purchaseSaleOrder?: ITransactionPurchaseOrder;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTransactionGenerateDocs = (props: IParams) => {
  const {
    onSetError,
    onSetLoading,
    onSetModalGenerate,
    onSetPercent,
    onSetSuccess,
    onSetTypeDoc,
    onSetURL,
    onSetTransaction,
    onSetPurchaseSaleOrder,
    transaction,
    purchaseSaleOrder,
  } = useTransactions();

  const generateInvoice = usePostOrdersInvoice();
  const generateReceipt = usePostOrdersReceipt();
  const generateSaleOrderInvoice = usePostSaleOrderInvoice();
  const generatePharmacistPurchaseOrderInvoice =
    useAdminDownloadPurchaseOrderInvoice({
      id: props?.purchaseSaleOrder?.id ?? 0,
    });
  const generatePharmacistPurchaseOrderReceipt =
    useAdminDownloadPurchaseOrderReceipt({
      id: props?.purchaseSaleOrder?.id ?? 0,
    });

  const onGenerateInvoice = async () => {
    if (props.transaction) {
      onSetTransaction(props.transaction);
    }

    onSetTypeDoc('CONSOLIDATED-INVOICE');
    onSetModalGenerate(true);
    onSetLoading(true);

    try {
      onSetPercent(50);

      const response = await generateInvoice.mutateAsync({
        id: props.transaction?.order.id ?? transaction?.order.id ?? 0,
      });

      onSetURL(response.data.url);
      onSetSuccess(true);
      onSetLoading(false);
    } catch (e) {
      onSetError(true);
      onSetLoading(false);
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const onGenerateReceipt = async () => {
    if (props.transaction) {
      onSetTransaction(props.transaction);
    }

    onSetTypeDoc('CONSOLIDATED-RECEIPT');
    onSetModalGenerate(true);
    onSetLoading(true);

    try {
      onSetPercent(50);
      const response = await generateReceipt.mutateAsync({
        id: props.transaction?.order.id ?? transaction?.order.id ?? 0,
      });
      onSetURL(response.data.url);
      // onSetError(true);
      onSetSuccess(true);
      onSetLoading(false);
    } catch (e) {
      onSetError(true);
      onSetLoading(false);
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const onGenerateManufacturerInvoice = async () => {
    if (props.purchaseSaleOrder) {
      onSetPurchaseSaleOrder(props.purchaseSaleOrder);
    }

    onSetTypeDoc('SALE-ORDER-INVOICE');
    onSetModalGenerate(true);
    onSetLoading(true);

    try {
      onSetPercent(50);
      const response = await generateSaleOrderInvoice.mutateAsync({
        id: props.purchaseSaleOrder?.id ?? purchaseSaleOrder?.id ?? 0,
      });

      const blobFile = new Blob([response.data], {
        type: 'application/pdf',
      });

      const fileURL = blobFile && window.URL.createObjectURL(blobFile);

      onSetURL(fileURL);
      onSetSuccess(true);
      onSetLoading(false);
    } catch (e) {
      onSetError(true);
      onSetLoading(false);
    }
  };

  const onGeneratePharmacistInvoice = async () => {
    if (props.purchaseSaleOrder) {
      onSetPurchaseSaleOrder(props.purchaseSaleOrder);
    }

    onSetTypeDoc('PURCHASE-ORDER-INVOICE');
    onSetModalGenerate(true);
    onSetLoading(true);

    try {
      onSetPercent(50);
      const response =
        await generatePharmacistPurchaseOrderInvoice.mutateAsync();

      const blobFile = new Blob([response.data], {
        type: 'application/pdf',
      });

      const fileURL = blobFile && window.URL.createObjectURL(blobFile);

      onSetURL(fileURL);
      onSetSuccess(true);
      onSetLoading(false);
    } catch (e) {
      onSetError(true);
      onSetLoading(false);
    }
  };

  const onGeneratePharmacistReceipt = async () => {
    if (props.purchaseSaleOrder) {
      onSetPurchaseSaleOrder(props.purchaseSaleOrder);
    }

    onSetTypeDoc('PURCHASE-ORDER-RECEIPT');
    onSetModalGenerate(true);
    onSetLoading(true);

    try {
      onSetPercent(50);
      const response =
        await generatePharmacistPurchaseOrderReceipt.mutateAsync();

      const blobFile = new Blob([response.data], {
        type: 'application/pdf',
      });

      const fileURL = blobFile && window.URL.createObjectURL(blobFile);

      onSetURL(fileURL);
      onSetSuccess(true);
      onSetLoading(false);
    } catch (e) {
      onSetError(true);
      onSetLoading(false);
    }
  };

  return {
    onGenerateInvoice,
    onGenerateReceipt,
    onGenerateManufacturerInvoice,
    onGeneratePharmacistInvoice,
    onGeneratePharmacistReceipt,
  };
};

export { useTransactionGenerateDocs };
