import React, { useCallback } from 'react';
import { useAuth } from 'rx-hooks';
import { useHistory } from 'react-router-dom';

import { Container, BackContainer, Title, BackIcon } from './index.styles';

type IAddProductActionsProps = {
  isEdit: boolean;
  productName: string;
};

export const AddProductActions: React.FC<IAddProductActionsProps> = (props) => {
  const history = useHistory();
  const { type } = useAuth();

  const onBack = useCallback(() => {
    if (type === 'admin') {
      history.goBack();
      return;
    }

    history.push('/');
  }, [history, type]);

  return (
    <Container>
      <BackContainer>
        <BackIcon onClick={() => onBack()} />
        <Title>
          {props.isEdit ? `Edit ${props.productName}` : 'Add New Product'}
        </Title>
      </BackContainer>
    </Container>
  );
};
