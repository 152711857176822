import styled, { css } from 'styled-components';

export const TableRow = styled.tr``;

export const TableColumn = styled.td`
  ${({ theme }) => css`
    padding: 5rem;
    font-size: ${theme.sizes.h2};
    text-align: center;
  `}
`;
