import React, { SelectHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

import { useField } from 'formik';

import { DataState } from 'rx-domain';

import { PopperInfo } from '../../popper/PopperInfo';
import { InputTitle } from '../InputTitle';
import { InputSelect } from '../InputSelect';
import { RxInputIcon } from '../RxInputIcon/RxInputIcon';
import { RxInputErrorMessage } from '../RxInputErrorMessage/RxInputErrorMessage';
import { RxInputMessage } from '../RxInputMessage/RxInputMessage';

interface ISelectItem {
  value: string;
  name: string;
}

export type RRSelectProps = SelectHTMLAttributes<HTMLSelectElement> &
  LayoutProps & {
    name: string;
    label?: string;
    state?: DataState;
    items: Array<ISelectItem>;
    message?: string;
    inputError: boolean;
    popperInfoText?: string;
    upperLabel?: boolean;
  };

const Container = styled.div<LayoutProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${layout}
`;

const InputContainer = styled.div<LayoutProps & { error: boolean }>`
  position: relative;
  ${layout};

  ${({ error }) =>
    !error &&
    css`
      margin-bottom: 30px;
    `}
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const PopperIcon = styled(PopperInfo)`
  margin-left: 12px;
`;

const RRSelect = ({
  label,
  state,
  width,
  items,
  message,
  className,
  upperLabel,
  inputError,
  popperInfoText,
  ...props
}: RRSelectProps): JSX.Element => {
  const [field, meta] = useField({ ...props });

  const formError = meta.touched && meta.error;
  const iconState = state !== 'none';
  const inputErrorState = state === 'error' && inputError;

  return (
    <Container className={className}>
      <LabelContainer>
        {label && (
          <InputTitle
            title={label}
            htmlFor={props.name}
            error={state === 'error' && inputError}
            upperLabel={upperLabel}
            disabled={props.disabled}
          />
        )}
        {popperInfoText && <PopperIcon text={popperInfoText} />}
      </LabelContainer>
      <InputContainer width={width} error={!!formError}>
        <InputSelect
          {...field}
          {...props}
          error={inputErrorState}
          id={props.name}
        >
          {items.map((item) => (
            <option value={item.value} key={item.value}>
              {item.name}
            </option>
          ))}
        </InputSelect>
        {iconState && <RxInputIcon state={state} />}
      </InputContainer>
      {formError && <RxInputErrorMessage>{meta.error}</RxInputErrorMessage>}
      {message && (
        <RxInputMessage error={!!formError}>{message}</RxInputMessage>
      )}
    </Container>
  );
};

RRSelect.defaultProps = {
  label: '',
  message: '',
  state: 'none',
  inputError: false,
  upperLabel: true,
};

export { RRSelect };
