import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { SearchQueryParams } from 'rx-domain';

import { onMakeURL } from 'rx-utils';

import { Container, SelectType, SelectTypeText } from './index.styles';

type IFilterType = {
  type: 'any' | 'approved' | 'pending' | 'rejected';
  tag: string;
};

const filters: Array<IFilterType> = [
  { type: 'any', tag: 'All' },
  { type: 'approved', tag: 'Approved' },
  { type: 'pending', tag: 'Pending' },
  { type: 'rejected', tag: 'Rejected' },
];

type IProps = {
  queries: SearchQueryParams;
};

const SellerStatusFilter = (props: IProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const history = useHistory();

  const filterType =
    filters.find((f) => f.type === props.queries.approvalStatus)?.tag ?? '';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value: IFilterType) => {
    setAnchorEl(null);

    const fullURL = onMakeURL({ ...props.queries, approvalStatus: value.type });

    history.push(fullURL);
  };

  return (
    <Container>
      <SelectType
        aria-controls="search-seller-filter-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <SelectTypeText>Filter by: {filterType}</SelectTypeText>
        <KeyboardArrowDownIcon />
      </SelectType>
      <Menu
        id="search-seller-filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {filters.map((filter) => (
          <MenuItem onClick={() => handleClose(filter)} key={filter.tag}>
            {filter.tag}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};

export { SellerStatusFilter };
