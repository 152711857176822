import { UpdateTwoFactorConfig } from 'rx-domain';
import { useRxMutation } from 'rx-api/utils';
import { PUT_ACCOUNT_2FA_URL } from './constanst';

export const useUpdateTwoFactorConfig = () => {
  return useRxMutation<UpdateTwoFactorConfig.DTO, unknown>({
    method: 'put',
    url: PUT_ACCOUNT_2FA_URL,
  });
};
