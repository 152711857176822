import { useMutation } from 'react-query';
import {
  GetSaleOrder,
  GetSaleOrders,
  PostSaleOrderInvoice,
  PostSaleOrderFulfill,
} from 'rx-domain';
import { SELLER_URL_V1 } from 'rx-api/api/manufacturer/constants';
import {
  useRxMutation,
  useGetRequest,
  createUrlWithParams,
  convertUrlWithQuery,
  makeArrayReactQueryKey,
} from 'rx-api/utils';
import { http } from 'rx-api/http/repository';

const BASE_URL = `${SELLER_URL_V1}/sale-orders`;

export const GET_SALE_ORDERS_URL = `${BASE_URL}`;
export const GET_SALE_ORDER_URL = `${BASE_URL}/{id}`;
export const GET_SALE_ORDER_INVOICE = `${BASE_URL}/{id}/invoice`;
export const POST_SALE_ORDER_FULFILL = `${BASE_URL}/{id}/ship`;

export const GET_SALE_ORDERS_KEY = 'GET_SALE_ORDERS_KEY';
export const GET_SALE_ORDER_KEY = 'GET_SALE_ORDER_KEY';

export const useGetSaleOrder = (params: GetSaleOrder.Params) => {
  return useGetRequest<unknown, GetSaleOrder.Response>({
    url: createUrlWithParams(GET_SALE_ORDER_URL, {
      key: 'id',
      value: params.id.toString(),
    }),
    key: makeArrayReactQueryKey(GET_SALE_ORDER_KEY, params),
  });
};

export const useGetSaleOrders = ({
  status = 'all',
  limit = 10,
  skip = 0,
  id = '',
}: GetSaleOrders.QUERY) => {
  return useGetRequest<GetSaleOrders.QUERY, GetSaleOrders.Response>({
    url: convertUrlWithQuery({ status, limit, skip, id }, GET_SALE_ORDERS_URL),
    key: makeArrayReactQueryKey(GET_SALE_ORDERS_KEY, {
      status,
      limit,
      skip,
      id,
    }),
  });
};

export const useGetSaleOrdersIdInvoice = () => {
  const onRequest = (params: PostSaleOrderInvoice.Params) => {
    return http.get(
      createUrlWithParams(GET_SALE_ORDER_INVOICE, {
        key: 'id',
        value: params.id.toString(),
      }),
      {
        responseType: 'arraybuffer',
      }
    );
  };

  return useMutation(onRequest, {});
};

export const usePostSaleOrdersFulfill = (
  params: PostSaleOrderFulfill.Params
) => {
  return useRxMutation<PostSaleOrderFulfill.DTO, unknown>({
    method: 'post',
    url: createUrlWithParams(POST_SALE_ORDER_FULFILL, {
      key: 'id',
      value: params.id.toString(),
    }),
  });
};

export { useSellerEditPurchaseOrderItems } from './useSellerEditPurchaseOrderItems';
