import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 1rem 7.75rem;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.blue[500]};
    font-family: ${theme.fonts.roobertLight};
    font-size: ${theme.sizes.h1};
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #9fa6b9;
  `}
`;
