import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { IconButton } from '@material-ui/core';
import { FavoriteBorder as FavoriteBorderIcon } from '@material-ui/icons';

import { getTotalCartItems } from 'rx-store';

import { useAuth } from 'rx-hooks';

import { HeaderActionUserPopper } from './HeaderActionUserPopper';
import { HeaderLink } from '../atoms/HeaderLink';
import { HeaderShoppingCartIcon } from './HeaderShoppingCartIcon';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('desktop')} {
      margin-right: 16px;
    }
  `}
`;

const HeaderActions: React.FC = () => {
  const { type } = useAuth();

  const totalCartItems = useSelector(getTotalCartItems);

  return (
    <Container>
      {type === 'pharmacist' && (
        <HeaderLink to="/favorites">
          <IconButton aria-label="favorites">
            <FavoriteBorderIcon fontSize="medium" color="inherit" />
          </IconButton>
        </HeaderLink>
      )}
      <HeaderActionUserPopper />
      {type === 'pharmacist' && (
        <HeaderLink to="/shopping-cart">
          <HeaderShoppingCartIcon totalItems={totalCartItems} />
        </HeaderLink>
      )}
    </Container>
  );
};

export { HeaderActions };
