import React from 'react';
import { TableRow, TableCell, Typography } from '@material-ui/core';

export const ShoppingCartTableEmpty: React.FC = () => (
  <TableRow>
    <TableCell colSpan={5}>
      <Typography align="center">
        No products added to the shopping cart yet
      </Typography>
    </TableCell>
  </TableRow>
);
