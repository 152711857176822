import React from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { formatDate } from 'rx-utils';
import { ManufacturerProvider } from 'rx-contexts';
import { useParams } from 'react-router-dom';
import {
  useGetManufacturerId,
  useGetAdminManufacturersIdShipConfig,
  useGetSellerEmailRecipients,
  usePostAdminToggle2FASettings,
  useAdminUpdateAccountPassword,
} from 'rx-api';
import { useToast } from 'rx-hooks';
import { SEO, TemplateView } from 'rx-core';

import { SuspendedAccountBanner } from 'pages/general/SuspendedAccountBanner';
import { AccountPasswordForm } from 'pages/general/AccountPasswordForm';
import { password } from 'utils/validators/common.validator';
import { ManufacturerBackList } from '../atoms/ManufacturerBackList';
import { AdminSellerShippingInfo } from '../AdminSellerShippingInfo';
import { PaperCard } from '../atoms/PaperCard';
import { ManufacturerAccountForm } from '../AdminManufacturerAccountForm';
import { SellerEmailReceipt } from '../SellerEmailReceipt';
import { ManufacturerBillingInformation } from '../AdminManufacturerBillingInformation';
import { ManufacturerFees } from '../AdminManufacturerFees';
import { AdminSellerDangerZone } from '../AdminSellerDangerZone';
import { Manufacturer2FASettings } from '../AdminManufacturer2FASettings';
import { ManufacturerApiKey } from '../AdminManufacturerApiKey';
import { ManufacturerTitle } from '../AdminManufacturerTitle/ManufacturerTitle';
import { ManufacturerCompanyInformation } from '../AdminManufacturerCompanyInformation';
import { AdminManufacturerEdiSettings } from '../AdminManufacturerEdiSettings';

const Container = styled.div`
  padding: 58px 24px 32px 24px;
`;

export const ManufacturerView: React.FC = () => {
  const query = useParams<{ id: string }>();

  const { data: manufacturer, refetch } = useGetManufacturerId({
    id: query.id ?? '',
  });

  const { data: shippingInfo } = useGetAdminManufacturersIdShipConfig({
    id: query.id ?? '',
  });

  const { data: emailRecipientsRes } = useGetSellerEmailRecipients({
    id: query.id,
  });

  const updatePassword = useAdminUpdateAccountPassword(query.id);

  const toast = useToast();
  const toggle2FASettings = usePostAdminToggle2FASettings(`${query.id}`);

  const handleClickToggle2FA = async () => {
    try {
      await toggle2FASettings.mutateAsync({});
      toast.onSuccessToast('2FA settings updated!');
      refetch();
    } catch (err) {
      toast.onErrorToast('Could not update 2FA settings');
    }
  };

  const passwordFormik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      newPassword: password.required(),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], "Passwords dont't match")
        .required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        await updatePassword.mutateAsync(values);
        toast.onSuccessToast('Password changed!');
      } catch (err) {
        toast.onErrorToast('Error: could not change password');
      }
    },
  });

  return (
    <TemplateView>
      <SEO title="RxRise | Manufacturer" />
      <ManufacturerProvider>
        {manufacturer?.data && (
          <Container>
            <ManufacturerBackList />

            <ManufacturerTitle
              pharmacyName={manufacturer.data.manufacturer.companyName}
              createdAt={formatDate(manufacturer.data.createdAt)}
            />

            {manufacturer?.data?.approvalStatus === 'suspended' && (
              <SuspendedAccountBanner />
            )}

            <PaperCard variant="outlined">
              <ManufacturerAccountForm {...manufacturer.data} />
            </PaperCard>

            <PaperCard variant="outlined">
              <ManufacturerCompanyInformation {...manufacturer.data} />
            </PaperCard>

            <PaperCard variant="outlined">
              <AdminManufacturerEdiSettings manufacturerId={query.id ?? '0'} />
            </PaperCard>

            <PaperCard variant="outlined">
              <ManufacturerBillingInformation id={query.id} />
            </PaperCard>

            <PaperCard variant="outlined">
              <AccountPasswordForm
                disabled={passwordFormik.isSubmitting}
                values={passwordFormik.values}
                errors={passwordFormik.errors}
                onChange={passwordFormik.handleChange}
                onSubmit={passwordFormik.handleSubmit}
              />
            </PaperCard>

            <PaperCard variant="outlined">
              <Manufacturer2FASettings
                disabled={toggle2FASettings.isLoading}
                onClickToggle={handleClickToggle2FA}
                twoFactorEnabled={manufacturer.data.twoFactorEnabled}
              />
            </PaperCard>

            <PaperCard variant="outlined">
              <SellerEmailReceipt emails={emailRecipientsRes?.data ?? []} />
            </PaperCard>

            <PaperCard variant="outlined">
              <ManufacturerFees
                accountId={parseInt(query.id, 10) ?? 0}
                fee={manufacturer.data.fee}
                feeSettings={manufacturer.data.fee}
              />
            </PaperCard>

            <PaperCard variant="outlined">
              <AdminSellerShippingInfo
                data={shippingInfo?.data}
                idOwner={query.id ?? ''}
              />
            </PaperCard>

            <PaperCard variant="outlined">
              <ManufacturerApiKey {...manufacturer.data} />
            </PaperCard>

            <PaperCard variant="outlined">
              <AdminSellerDangerZone
                isDisabled={manufacturer?.data?.approvalStatus === 'suspended'}
              />
            </PaperCard>
          </Container>
        )}
      </ManufacturerProvider>
    </TemplateView>
  );
};
