import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { MenuItem, TextField, Typography } from '@material-ui/core';

import { InventoryType } from 'rx-domain';

import { AdminProductsFilters } from './AdminProductsFilters';
import { useGetCategoriesList } from 'rx-api';

type AdminInventoryType = string | 'black_list';

type IProps = {
  view: AdminInventoryType;
  onSetView: (value: AdminInventoryType) => void;
  count: number;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  border-radius: 10px;
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
`;

const TabsItem = styled.span<{ active: boolean }>`
  ${({ theme, active }) => css`
    font-size: ${theme.sizes.small};
    cursor: pointer;
    margin-right: 1rem;
    font-weight: 400;
    padding: 0.75rem;

    ${active &&
    css`
      border-bottom: 1px solid ${theme.colors.blue[300]};
    `}
  `}
`;

const AdminProductsTabsHeader = styled.header`
  background-color: white;
  border-bottom: 1px solid #9fa6b9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
`;

const CountContainer = styled.div`
  display: flex;
  padding-right: 1rem;
  align-items: center;
`;

type formattedCategories = {
  value: string;
  title: string;
}[];

const AdminProductsTabs: React.FC<IProps> = ({
  children,
  ...props
}): JSX.Element => {
  const [value, setValue] = useState(props.view || 'drug');

  const { data: categoriesList, refetch } = useGetCategoriesList();
  const [formattedCategories, setFormattedCategories] =
    useState<formattedCategories>([]);

  useEffect(() => {
    if (categoriesList) {
      const results = categoriesList.map((category) => {
        return {
          title: category.slug,
          value: category.text,
        };
      });
      setFormattedCategories(results);
    }
  }, [categoriesList]);
  return (
    <Container>
      <AdminProductsTabsHeader>
        <TabsContainer></TabsContainer>

        <CountContainer>
          <TextField
            name="categories"
            id="categories"
            label="Categories"
            variant="outlined"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              props.onSetView(e.target.value);
            }}
            select
          >
            {formattedCategories.map((category) => (
              <MenuItem key={category.value} value={category.value}>
                {category.title}
              </MenuItem>
            ))}
            <MenuItem value="black_list">Black Listed</MenuItem>
          </TextField>
          <Typography style={{ width: '330px', marginLeft: '5px' }}>
            {props.count} {props.count === 1 ? 'product' : 'products'} in total
          </Typography>
        </CountContainer>
      </AdminProductsTabsHeader>
      <AdminProductsFilters />
      {children}
    </Container>
  );
};

export { AdminProductsTabs };
