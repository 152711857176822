import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { useState } from 'react';
import { useAdminGetPurchaseOrders } from 'rx-api';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface Data {
  order: string;
  creationDate: string;
  customer: string;
  total: string;
  paymentStatus: string;
  fulfillmentStatus: string;
  items: string;
  deliveryStatus: string;
  ediStatus: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  sortable: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'order',
    numeric: false,
    disablePadding: false,
    label: 'Order',
    sortable: true,
  },
  {
    id: 'creationDate',
    numeric: true,
    disablePadding: false,
    label: 'Creation Date',
    sortable: true,
  },
  {
    id: 'customer',
    numeric: true,
    disablePadding: false,
    label: 'Customer',
    sortable: true,
  },
  {
    id: 'total',
    numeric: true,
    disablePadding: false,
    label: 'Total',
    sortable: true,
  },
  {
    id: 'paymentStatus',
    numeric: true,
    disablePadding: false,
    label: 'Payment Status',
    sortable: false,
  },
  {
    id: 'fulfillmentStatus',
    numeric: true,
    disablePadding: false,
    label: 'Fulfillment status',
    sortable: false,
  },
  {
    id: 'items',
    numeric: true,
    disablePadding: false,
    label: 'Items',
    sortable: false,
  },
  {
    id: 'deliveryStatus',
    numeric: true,
    disablePadding: false,
    label: 'Delivery status',
    sortable: false,
  },
  {
    id: 'ediStatus',
    numeric: false,
    disablePadding: false,
    label: 'EDI Status',
    sortable: false,
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 440,
      overflow: 'scroll-y',
      '&::-webkit-scrollbar': {
        display: 'block',
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '10px',
        backgroundColor: '#F0F0F0',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        backgroundColor: '#B0B0B0',
      },
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableHead: {
      backgroundColor: '#F7F9FC',
    },
    paid: {
      background: '#DDE0E4',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    refunded: {
      background: '#DDE0E4',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    cancelled: {
      color: 'white',
      background: '#FF6969',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    pending: {
      color: 'white',
      background: '#FF6969',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    fulfilled: {
      background: '#DDE0E4',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    unfulfilled: {
      background: '#FFEA93',
      padding: '2px 9px 2px 9px',
      borderRadius: '9px',
    },
    bodyRow: {
      cursor: 'pointer',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

type Order = 'asc' | 'desc';

export const PurchaseOrdersTable = () => {
  const history = useHistory();

  const classes = useStyles();
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('order');
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [dense, setDense] = useState(false);
  const [limit, setLimit] = useState<number>(50);
  const [skip, setSkip] = useState<number>(0);
  const [numSelected, setNumSelected] = useState<number>(0);
  const [currentPage, setCurrentPAge] = useState<number>(0);
  const { data, isLoading } = useAdminGetPurchaseOrders({
    limit,
    skip,
    order,
    orderBy,
  });

  const addItem = (row: number) => {
    setSelectedRows((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.add(row);
      return newSet;
    });
  };

  const removeItem = (row: number) => {
    setSelectedRows((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.delete(row);
      return newSet;
    });
  };

  const removeAllItems = () => {
    setSelectedRows(new Set());
  };

  const containsItem = (row: number) => {
    return selectedRows.has(row);
  };

  const dateFormat = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const messages = {
    pending: 'Payment Pending',
    paid: 'Paid',
    refunded: 'Refunded',
    cancelled: 'Cancelled',
  };

  const numRows = data?.data?.orders.length || 0;

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeCheckbox = (_e: any, checked: boolean) => {
    if (!checked) {
      removeAllItems();
      setNumSelected(0);
    } else {
      setNumSelected(numRows);
      data?.data?.orders?.forEach((row: any) => {
        addItem(row.id);
      });
    }
  };

  return (
    <Paper>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
          aria-label="enhanced table"
        >
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < numRows}
                  checked={numRows > 0 && numSelected === numRows}
                  onChange={handleChangeCheckbox}
                />
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.sortable ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={() => handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    <>{headCell.label}</>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={10}>
                  <Skeleton height={80} />
                  <Skeleton height={80} />
                  <Skeleton height={80} />
                  <Skeleton height={80} />
                </TableCell>
              </TableRow>
            )}

            {!isLoading && data?.data?.orders?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10}>
                  <Typography align="center">No orders placed yet</Typography>
                </TableCell>
              </TableRow>
            )}

            {!isLoading &&
              data?.data?.orders &&
              data?.data?.orders?.map((row: any, index: number) => {
                const paidStatus:
                  | 'paid'
                  | 'cancelled'
                  | 'refunded'
                  | 'pending' = row.parentOrder.status;

                const fulfilledStatus: 'fulfilled' | 'unfulfilled' =
                  row.shippingCarrier === '' && row.trackingNumber === ''
                    ? 'unfulfilled'
                    : 'fulfilled';

                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    className={classes.bodyRow}
                    hover
                    onClick={() => history.push(`/purchase-orders/${row.id}`)}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(e, checked) => {
                          if (checked) {
                            addItem(row.id);
                            setNumSelected(numSelected + 1);
                          } else {
                            removeItem(row.id);
                            setNumSelected(numSelected - 1);
                          }
                        }}
                        checked={containsItem(row.id)}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      PO-{row.id}
                    </TableCell>
                    <TableCell align="right">
                      {new Date(row.createdAt).toLocaleDateString(
                        'en-US',
                        dateFormat as any
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {row.pharmacy.pharmacyName}
                    </TableCell>
                    <TableCell align="right">$ {row.subtotal}</TableCell>
                    <TableCell align="right">
                      <span>
                        <span className={classes[paidStatus]}>
                          {
                            messages[
                              row.parentOrder.status as keyof typeof messages
                            ]
                          }
                        </span>
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <span className={classes[fulfilledStatus]}>
                        {row.shippingCarrier === '' && row.trackingNumber === ''
                          ? 'Unfulfilled'
                          : 'Fulfilled'}
                      </span>
                    </TableCell>
                    <TableCell align="right">{row.orderItems.length}</TableCell>
                    <TableCell align="right">
                      {row.trackingNumber !== '' ? 'Tracking added' : ''}
                    </TableCell>
                    <TableCell align="center">
                      {row.ediStatus === 'success' && (
                        <CheckCircleOutlineIcon style={{ color: '#3EB77D' }} />
                      )}
                      {row.ediStatus === 'failure' && (
                        <CancelIcon style={{ color: '#FF6969' }} />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {data?.data?.total > 0 && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[50]}
          count={data?.data?.total || 0}
          rowsPerPage={limit}
          page={currentPage}
          onPageChange={(event, page) => {
            setNumSelected(0);
            setCurrentPAge(page);
            setSkip(page * limit);
          }}
          onRowsPerPageChange={() => {}}
        />
      )}
    </Paper>
  );
};
