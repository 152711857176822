import styled, { css } from 'styled-components';
import ImageIcon from '@material-ui/icons/Image';
import CancelIcon from '@material-ui/icons/Cancel';

export const Container = styled.div`
  border: 1px dashed #9fa6b9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 10rem;
  max-width: 26rem;
  cursor: pointer;
`;

export const DragContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DragText = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-weight: 400;
    font-family: ${theme.fonts.robotoRegular};
    text-align: center;
  `}
`;

export const DragTextBlue = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.blue[500]};
  `}
`;

export const Icon = styled(ImageIcon)`
  &.MuiSvgIcon-root {
    fill: #5077f2;
  }
`;

export const RemoveIcon = styled(CancelIcon)`
  &.MuiSvgIcon-root {
    fill: red;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
`;

export const ImgContainer = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
`;

export const PreviewImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.small};
    font-family: ${theme.fonts.robotoLight};
    color: #9fa6b9;
    margin-top: 0.5rem;
    font-weight: 700;
  `}
`;
