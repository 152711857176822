import { ADMIN_URL_V1, ADMIN_URL_V2 } from '../constants';

export const PUT_ADMIN_PRODUCT_DATA_URL = `${ADMIN_URL_V2}/products/{id}`;

export const PUT_ADMIN_PRODUCT_PICTURE_URL = `${ADMIN_URL_V1}/products/{id}/picture`;

export const DELETE_ADMIN_PRODUCT_PICTURE_URL = `${ADMIN_URL_V1}/products/{id}/picture`;

export const GET_ADMIN_PRODUCTS_URL = `${ADMIN_URL_V1}/products`;
export const GET_ADMIN_PRODUCTS_KEY = 'GET_ADMIN_PRODUCTS_KEY';

export const GET_ADMIN_PRODUCT_URL = `${ADMIN_URL_V1}/products/{id}`;
export const GET_ADMIN_PRODUCT_KEY = 'GET_ADMIN_PRODUCT_KEY';

export const POST_TOGGLE_FEATURED_PRODUCT = `${ADMIN_URL_V1}/products/{id}/featured`;

export const PUT_ADMIN_EDIT_PURCHASE_ORDER_ITEMS_URL = `${ADMIN_URL_V1}/purchase-orders/{id}/items`;
