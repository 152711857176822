import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
`;

export const Tab = styled.button`
  background-color: transparent;
  border: 2px solid transparent;
  font-size: 1.1rem;
  text-align: center;
  padding: 1rem;

  &[aria-active='true'] {
    border-bottom-color: #1b8df2;
    color: #1b8df2;
  }
`;
