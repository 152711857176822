import { useQuery } from 'react-query';
import { IGetAccountPharmacyDataDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetAccountPharmacyData = () => {
  const onRequest = async () => {
    return http.get<IGetAccountPharmacyDataDTO>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/account/pharmacy-data`,
      {
        withCredentials: true,
      }
    );
  };

  return useQuery(['get-account-pharmacy-data'], () => onRequest(), {});
};
