import React from 'react';

import { CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { IGetManufacturer } from 'rx-domain';

import { useTransactionFilterSeller } from 'rx-hooks';

import { useStyles } from './index.styles';

type IProps = {
  onGetSeller: (manufacturer: IGetManufacturer | null) => void;
};

const TransactionFilterSeller = ({ onGetSeller }: IProps): JSX.Element => {
  const {
    value,
    open,
    options,
    isLoading,
    setOpen,
    setOptions,
    setSearch,
    setValue,
  } = useTransactionFilterSeller();
  const classes = useStyles({ withValue: !!value });

  return (
    <Autocomplete
      id="seller-search"
      size="small"
      classes={classes}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionSelected={(option, autocompleteValue) =>
        option.id === autocompleteValue.id
      }
      getOptionLabel={(option) => option.manufacturer.companyName}
      options={options}
      loading={isLoading}
      value={value}
      filterOptions={(x) => x}
      onChange={(event: unknown, newValue: IGetManufacturer | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onGetSeller(newValue);
      }}
      onInputChange={(event, newInputValue) => setSearch(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Seller"
          variant="outlined"
          focused={false}
          style={{
            width: '200px',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export { TransactionFilterSeller };
