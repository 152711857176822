import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { ITransactionPurchaseOrder } from 'rx-domain';

import { useOrder } from 'rx-contexts';

import {
  usePostAdminSaleOrdersIdPaymentStatus,
  usePostAdminSaleOrdersIdShippingStatus,
} from 'rx-api';

import { useToast, useTransactionGenerateDocs } from 'rx-hooks/utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTransactionPOActions = (
  purchaseOrder: ITransactionPurchaseOrder
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const queryClient = useQueryClient();
  const toast = useToast();
  const { onOpenCancelModal, onOpenEDIModal } = useOrder();

  const markAsShipped = usePostAdminSaleOrdersIdShippingStatus(
    purchaseOrder.id
  );
  const markAsPaid = usePostAdminSaleOrdersIdPaymentStatus(purchaseOrder.id);

  const {
    onGenerateManufacturerInvoice,
    onGeneratePharmacistInvoice,
    onGeneratePharmacistReceipt,
  } = useTransactionGenerateDocs({
    purchaseSaleOrder: purchaseOrder,
  });

  const onOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onMarkAsShipped = useCallback(async () => {
    try {
      if (!purchaseOrder) {
        return;
      }

      await markAsShipped.mutateAsync();

      await queryClient.refetchQueries('get-transactions', { active: true });

      toast.onSuccessToast('Purchase order marked as shipped!');
    } catch (err) {
      toast.onErrorToast('Could not mark purchase order as shipped!');
    }
  }, [markAsShipped, purchaseOrder.saleOrderInvoice, queryClient, toast]);

  const onMarkAsPaid = useCallback(async () => {
    try {
      if (!purchaseOrder) {
        return;
      }

      await markAsPaid.mutateAsync();

      await queryClient.refetchQueries('get-transactions', { active: true });

      toast.onSuccessToast('Purchase order marked as paid!');
    } catch (err) {
      toast.onErrorToast('Could not mark purchase order as paid!');
    }
  }, [markAsPaid, purchaseOrder.saleOrderInvoice, queryClient, toast]);

  const onCancelPurchaseOrder = useCallback(async () => {
    onOpenCancelModal(null, {
      idCancel: purchaseOrder.id,
      type: 'purchaseOrder',
    });
  }, [onOpenCancelModal, purchaseOrder]);

  const onSendOrderToEDI = useCallback(async () => {
    onOpenEDIModal(null, {
      idCancel: purchaseOrder.id,
      type: 'purchaseOrder',
    });
  }, [onOpenEDIModal, purchaseOrder]);

  const generateOptions = useMemo(() => {
    const options = [
      {
        type: 'MANUFACTURER-INVOICE',
        text: purchaseOrder.isRequest
          ? 'Download Purchase Order Request'
          : `Download Manufacturer's Invoice`,
        onClick: onGenerateManufacturerInvoice,
      },
      {
        type: 'PHARMACIST-INVOICE',
        text: `Download Buyer's Invoice`,
        onClick: onGeneratePharmacistInvoice,
      },
      purchaseOrder.paymentStatus === 'paid'
        ? {
            type: 'PHARMACIST-RECEIPT',
            text: `Download Buyer's Receipt`,
            onClick: onGeneratePharmacistReceipt,
          }
        : null,
      purchaseOrder.status !== 'shipped'
        ? {
            type: 'MANUFACTURER-SALE-ORDER-SHIPPED',
            text: 'Mark as shipped',
            onClick: onMarkAsShipped,
          }
        : null,
      purchaseOrder.paymentStatus !== 'paid'
        ? {
            type: 'MANUFACTURER-SALE-ORDER-PAID',
            text: 'Mark as Paid',
            onClick: onMarkAsPaid,
          }
        : null,
      purchaseOrder.status !== 'shipped'
        ? {
            type: 'CANCEL_ORDER',
            text: 'Cancel purchase order',
            onClick: onCancelPurchaseOrder,
          }
        : null,
      {
        type: 'PLACE_ORDER_EDI',
        text: `Send order to EDI`,
        onClick: onSendOrderToEDI,
      },
    ];

    return options.filter((a) => a !== null) as Array<{
      type: string;
      text: string;
      onClick: () => Promise<void>;
    }>;
  }, [
    onCancelPurchaseOrder,
    onSendOrderToEDI,
    onGenerateManufacturerInvoice,
    onGeneratePharmacistInvoice,
    onGeneratePharmacistReceipt,
    onMarkAsPaid,
    onMarkAsShipped,
    purchaseOrder.isRequest,
    purchaseOrder.paymentStatus,
    purchaseOrder.status,
  ]);

  return {
    onOpenMenu,
    onClose,
    anchorEl,
    options: generateOptions,
  };
};
