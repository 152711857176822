import React from 'react';
import {
  Box,
  Typography,
  Dialog,
  Tabs,
  Tab,
  DialogContent,
} from '@material-ui/core';
import { AddPharmacyCardPaymentMethod } from './AddPharmacyCardPaymentMethod';
import { AddPharmacyAchPaymentMethod } from './AddPharmacyAchPaymentMethod';

interface IProps {
  pharmacyId: number;
  open: boolean;
  onCloseModal(): void;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const AddPaymentMethodModal: React.FC<IProps> = (props) => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleChangeTab = (_e: unknown, value: number) => {
    setTabValue(value);
  };

  return (
    <Dialog open={props.open}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          indicatorColor="primary"
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab label="Debit/Credit Card" {...a11yProps(0)} />
          <Tab label="ACH Account" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <DialogContent style={{ paddingTop: '1rem' }}>
        <TabPanel value={tabValue} index={0}>
          <AddPharmacyCardPaymentMethod
            onSuccess={props.onCloseModal}
            onClickCancel={props.onCloseModal}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <AddPharmacyAchPaymentMethod
            pharmacyId={props.pharmacyId}
            onSuccess={props.onCloseModal}
            onClickCancel={props.onCloseModal}
          />
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};
