export const GET_BUYER_PURCHASE_ORDER_KEY = 'get-buyer-purchase-order';
export const GET_BUYER_PURCHASE_ORDER_URL = (
  id: string | number,
  isSeller = false
) =>
  isSeller
    ? `${process.env.REACT_APP_WEBSITE_URL}/v1/manufacturer/purchase-orders/${id}`
    : `${process.env.REACT_APP_WEBSITE_URL}/v1/purchase-orders/${id}`;

export namespace GetPurchaseOrderById {
  export interface IRequestParamsDto {
    id: string | number;
  }

  export interface IResponsePayloadDto {
    id: number;
    subtotal: string;
    status: string;
    trackingNumber: string;
    trackingUrl: string;
    shippingCarrier: string;
    isRequest: boolean;
    buyerId: number;
    sellerId: number;
    orderId: number;
    createdAt: string;
    updatedAt: string;
    orderItems: Array<{
      id: number;
      quantity: number;
      price: string;
      line: string;
      purchaseOrderId: number;
      createdAt: string;
      updatedAt: string;
    }>;
    saleOrderInvoice: {
      id: number;
      purchaseOrderId: number;
      status: string;
      prefix: string;
      createdAt: string;
      updatedAt: string;
    };
    events: Array<{
      id: number;
      event: string;
      purchaseOrderId: number;
      createdBy: number;
      createdAt: string;
      updatedAt: string;
    }>;
    buyer: {
      billingInformation: {
        id: number;
        name: string;
        accountId: number;
        addressId: number;
        createdAt: string;
        updatedAt: string;
        billingAddress: {
          id: number;
          accountId: number;
          address: string;
          city: string;
          state: string;
          zipCode: string;
          createdAt: string;
          updatedAt: string;
        };
      };
      pharmacy: any;
      firstName: string;
      lastName: string;
      email: string;
    };
    shippingAddress: {
      id: number;
      orderId: number;
      address: string;
      city: string;
      state: string;
      zipCode: string;
      createdAt: string;
      updatedAt: string;
    };
    poPrefix: string;
    soPrefix: string;
    paymentMethod: string;
  }
}
