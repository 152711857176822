import * as Yup from 'yup';

const CustomFeesSchema = Yup.object().shape({
  percentage: Yup.number()
    .transform((_: string, originalValue: string) => {
      const value = parseFloat(
        originalValue.toString().replace(/,+/g, '').replace('%', '').trim()
      );

      return Number.isNaN(value) ? 0 : value;
    })
    .min(0)
    .required('Please enter custom fee'),
});

export { CustomFeesSchema };
