import React from 'react';
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

interface IProductPricingTierListProps {
  productId: number | string;
  data: {
    id: number;
    minAmount: number;
    maxAmount: number;
    price: number;
    logicalOperator: string;
  }[];
  onClickRemove: (id: number) => () => void;
}

const mapLogicalOperatorToLabel = (logicalOperator: string): string => {
  if (logicalOperator === 'less_than') {
    return 'Less Than';
  }

  if (logicalOperator === 'more_than') {
    return 'More Than';
  }

  return 'Between';
};

export const ProductPricingTierList: React.FC<IProductPricingTierListProps> = (
  props
) => {
  if (props.data.length === 0) {
    return (
      <div style={{ marginTop: '1rem' }}>
        <Typography>This product doesn't have any pricing tier</Typography>
      </div>
    );
  }

  return (
    <>
      <div style={{ marginTop: '1rem' }}>
        {props.data.map((tier) => {
          const columnSize = tier.logicalOperator === 'between' ? 3 : 6;
          const logicalOperatorText = mapLogicalOperatorToLabel(
            tier.logicalOperator
          );

          return (
            <Grid
              container
              spacing={2}
              key={tier.id}
              style={{ marginBottom: '0.75rem' }}
            >
              <Grid item xs={2}>
                <TextField
                  disabled
                  value={logicalOperatorText}
                  label="Rule"
                  variant="standard"
                  type="string"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>

              {(tier.logicalOperator === 'between' ||
                tier.logicalOperator === 'more_than') && (
                <Grid item xs={columnSize}>
                  <TextField
                    disabled
                    value={tier.minAmount}
                    placeholder="10"
                    label={
                      tier.logicalOperator === 'between'
                        ? 'Minimum Amount'
                        : 'Amount'
                    }
                    variant="standard"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
              )}

              {(tier.logicalOperator === 'between' ||
                tier.logicalOperator === 'less_than') && (
                <Grid item xs={columnSize}>
                  <TextField
                    disabled
                    value={tier.maxAmount}
                    placeholder="10"
                    label={
                      tier.logicalOperator === 'between'
                        ? 'Maximum Amount'
                        : 'Amount'
                    }
                    variant="standard"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
              )}

              <Grid item xs={3}>
                <TextField
                  disabled
                  value={tier.price}
                  placeholder="90.00"
                  label="Price Amount"
                  variant="standard"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={1}>
                <IconButton onClick={props.onClickRemove(tier.id)}>
                  <DeleteForeverIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </div>
    </>
  );
};
