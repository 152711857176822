import React from 'react';
import styled from 'styled-components';

import { motion, AnimatePresence } from 'framer-motion';

export type RROverlayProps = {
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const Overlay = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
`;

const OVERLAY_VARIANT = {
  initial: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

const RROverlay = ({
  onClose,
  visible,
  children,
}: RROverlayProps): JSX.Element => {
  return (
    <AnimatePresence>
      {visible && (
        <Overlay
          initial="initial"
          animate="visible"
          exit="exit"
          variants={OVERLAY_VARIANT}
          transition={{ duration: 0.2, ease: 'easeOut' }}
          onClick={onClose}
        >
          {children}
        </Overlay>
      )}
    </AnimatePresence>
  );
};

export { RROverlay };
