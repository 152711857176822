import React from 'react';
import { Helmet } from 'react-helmet';

export type SEOProps = {
  description?: string;
  title: string;
};

const SEO = ({ title, description }: SEOProps): JSX.Element => {
  const metaDescription = description || 'Official site of RxRise';

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'RxRise',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  );
};

SEO.defaultProps = {
  description: '',
};

export { SEO };
