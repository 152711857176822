import React, { useMemo } from 'react';
import { InventoryType } from 'rx-domain';
import { TableColumn, TableRow } from './index.styles';

type IInventoryEmptyProps = {
  type: InventoryType;
};

export const InventoryEmpty: React.FC<IInventoryEmptyProps> = (props) => {
  const productCategory = useMemo(() => {
    switch (props.type) {
      case 'drug':
        return 'Rx products';
      case 'medical_supply':
        return 'medical supplies';
      case 'diagnostic_test':
        return 'Diabetes Supplies';
      case 'otc':
        return "otc 's";
      default:
        return 'Rx products';
    }
  }, [props.type]);

  return (
    <TableRow>
      <TableColumn colSpan={13}>
        <span>There are no {productCategory} stored.</span>
      </TableColumn>
    </TableRow>
  );
};
