import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';

export const DashboardTopProductsEmpty: React.FC = () => {
  return (
    <TableRow>
      <TableCell colSpan={4} align="center">
        No products sold in this period of time
      </TableCell>
    </TableRow>
  );
};
