const ROBOTO_LIGHT = 'Roboto Light';
const ROBOTO_REGULAR = 'Roboto Regular';
const ROBOTO_MEDIUM = 'Roboto Medium';

const ROOBERT_LIGHT = 'Roobert Light';
const ROOBERT_REGULAR = 'Roobert Regular';
const ROOBERT_MEDIUM = 'Roobert Medium';
const ROOBERT_BOLD = 'Roobert Bold';

export type Fonts =
  | typeof ROBOTO_LIGHT
  | typeof ROBOTO_REGULAR
  | typeof ROBOTO_MEDIUM
  | typeof ROOBERT_LIGHT
  | typeof ROOBERT_REGULAR
  | typeof ROOBERT_MEDIUM
  | typeof ROOBERT_BOLD;

export {
  ROBOTO_LIGHT,
  ROBOTO_REGULAR,
  ROBOTO_MEDIUM,
  ROOBERT_LIGHT,
  ROOBERT_REGULAR,
  ROOBERT_MEDIUM,
  ROOBERT_BOLD,
};
