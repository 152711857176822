import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import ArrowIconRight from 'assets/icons/arrow-right-material-icon.svg';
import { ContactModal } from 'rx-core';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
`;

const LinksContainer = styled.div``;

const OptionsContainer = styled.div`
  margin-top: 8px;
`;

const Title = styled.h2`
  ${({ theme: { sizes } }) => css`
    font-size: ${sizes.small};
    font-weight: normal;
    margin-bottom: 8px;
  `}
`;

const TextLink = styled(Link)`
  ${({ theme: { sizes, colors } }) => css`
    font-size: ${sizes.small};
    font-weight: normal;
    color: ${colors.blue[100]};
    text-decoration: none;
  `}
`;

const LinkOption = styled(TextLink)`
  margin-left: 32px;
`;

const TextStrong = styled.p`
  margin-top: 8px;
  margin-left: 24px;

  ${({ theme: { sizes, colors } }) => css`
    font-size: ${sizes.small};
    font-weight: normal;
    color: ${colors.blue[100]};
    text-decoration: none;
    cursor: pointer;
  `}
`;

const ActionOption = styled(TextStrong)`
  margin-left: 32px;
`;

const CopyText = styled.p`
  margin-top: 40px;
  margin-left: 32px;

  ${({ theme: { sizes, colors } }) => css`
    font-size: ${sizes.small};
    font-weight: normal;
    color: ${colors.gray[500]};
  `}
`;

const StrongTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  margin-top: 8px;
`;

const SignupHelpSelect = (): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  return (
    <Container>
      <LinksContainer>
        <Title>
          If you are already a member you can{' '}
          <TextLink to="/login">Log in</TextLink>
        </Title>
        <StrongTextContainer>
          <Icon src={ArrowIconRight} />
          <TextStrong onClick={() => setOpen((prev) => !prev)}>
            Do you need help?
          </TextStrong>
        </StrongTextContainer>
        {open && (
          <OptionsContainer>
            <LinkOption to="/forgot-password">Forgot password?</LinkOption>
            <ActionOption onClick={() => setModal(true)}>
              Contact Support for assistance
            </ActionOption>
          </OptionsContainer>
        )}
        <CopyText>
          © {new Date().getFullYear()}, RxRise.com, or it’s affiliates
        </CopyText>
      </LinksContainer>
      <ContactModal open={modal} onClose={() => setModal(false)} />
    </Container>
  );
};

export { SignupHelpSelect };
