import { useHistory } from 'react-router-dom';
import { IGetManufacturer } from 'rx-domain';
import { usePaginationQueries } from 'rx-hooks/utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTransactionFilter = () => {
  const history = useHistory();

  const {
    onMakeURL,
    querySearch,
    querySeller,
    queryBuyer,
    queryFrom,
    queryTo,
  } = usePaginationQueries();

  const onSearchSeller = (seller: IGetManufacturer | null) => {
    const fullUrl = onMakeURL({
      search: querySearch,
      seller: !!seller === false ? '' : seller?.manufacturer.companyName,
      buyer: !!seller === false ? queryBuyer : '',
      from: queryFrom,
      to: queryTo,
    });

    history.push(fullUrl);
  };

  const onSearchBuyer = (
    buyer: {
      id: number;
      pharmacyName: string;
    } | null
  ) => {
    const fullUrl = onMakeURL({
      search: querySearch,
      buyer: !!buyer === false ? '' : buyer?.pharmacyName,
      seller: !!buyer === false ? querySeller : '',
      from: queryFrom,
      to: queryTo,
    });

    history.push(fullUrl);
  };

  const onFilterTransactionsByDate = (from: string, to: string) => {
    const fullUrl = onMakeURL({
      search: querySearch,
      buyer: '',
      seller: querySeller,
      from: to !== '' && from !== '' ? from : '',
      to: to !== '' && from !== '' ? to : '',
    });

    history.push(fullUrl);
  };

  return {
    onSearchSeller,
    onSearchBuyer,
    onFilterTransactionsByDate,
  };
};
