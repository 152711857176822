import { useCallback, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import numeral from 'numeral';

import { IAddProductForm } from 'rx-domain';

import {
  useDeletePictureProduct,
  useGetAdminProduct,
  usePostSingleProduct,
  usePutAdminProductData,
  usePutPictureProduct,
} from 'rx-api';
import { HARDCODED_CATEGORIES } from 'rx-utils';

import { useToast } from 'rx-hooks/utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAddProduct = (modePicture = false) => {
  const { onSuccessToast, onErrorToast } = useToast();
  const storeProduct = usePostSingleProduct();

  const deletePicture = useDeletePictureProduct();

  const { id } = useParams<{ id?: string }>();

  const isEdit = !!id;

  const { data: productRes, isLoading } = useGetAdminProduct({
    id: id ? parseInt(id ?? '0', 10) : 0,
  });

  const updatePicture = usePutPictureProduct({
    id: id ? parseInt(id ?? '0', 10) : 0,
  });

  const updateProduct = usePutAdminProductData({
    id: id ? parseInt(id ?? '0', 10) : 0,
  });

  const pictureUrl = productRes?.data.pictureUrl;

  const initialValues = useMemo(() => {
    if (productRes?.data) {
      return {
        name: productRes.data.name,
        ndc: productRes.data?.ndc,
        productId: productRes.data.productId,
        description: productRes.data.description,
        expirationDate: productRes.data.expirationDate,
        lotNumber: productRes.data.lotNumber,
        manufacturer: productRes.data.manufacturer,
        minimumQuantity: productRes.data.minimumQuantity,
        categoryId: productRes.data.categoryId,
        price: productRes.data.price,
        units: productRes.data.units,
        picture: undefined,
      } as IAddProductForm;
    }

    return {
      ndc: '',
      name: '',
      productId: '',
      description: '',
      expirationDate: '',
      lotNumber: '',
      manufacturer: '',
      minimumQuantity: 0,
      price: 0,
      units: 0,
      picture: undefined,
      categoryId: 4,
    } as IAddProductForm;
  }, [productRes]);

  const onSubmit = useCallback(
    async (
      values: IAddProductForm,
      helpers: FormikHelpers<IAddProductForm>
    ) => {
      try {
        if (!isEdit) {
          await storeProduct.mutateAsync({
            categoryId: values.categoryId,
            description: values.description,
            expirationDate: values.expirationDate,
            lotNumber: values.lotNumber,
            manufacturer: values.manufacturer,
            minimumQuantity: values.minimumQuantity,
            maximumQuantity: values.maximumQuantity,
            name: values.name,
            picture: values.picture,
            price: numeral(values.price).value() ?? 0,
            productId: values.productId,
            units: values.units,
          });

          onSuccessToast('Changes saved! 🚀');
          helpers.resetForm();

          return;
        }
        if (modePicture && pictureUrl && values.picture === null) {
          try {
            await deletePicture.mutateAsync({
              id: Number(id),
            });

            onSuccessToast('Image deleted');
            return;
          } catch (err) {
            onErrorToast('Could not delete the previous image');
            return;
          }
        }

        if (modePicture && values.picture) {
          await updatePicture.mutateAsync({
            picture: values.picture,
          });

          onSuccessToast('Picture updated! 🚀');
          return;
        }

        await updateProduct.mutateAsync({
          categoryId: values.categoryId,
          category:
            HARDCODED_CATEGORIES[
              values.categoryId as keyof typeof HARDCODED_CATEGORIES
            ],
          description: values.description,
          expirationDate: values.expirationDate,
          lotNumber: values.lotNumber,
          manufacturer: values.manufacturer,
          minimumQuantity: values.minimumQuantity,
          maximumQuantity: values.maximumQuantity,
          name: values.name,
          price: numeral(values.price).value() ?? 0,
          productId: values.productId,
          units: values.units,
          ndc: values.ndc,
        });

        onSuccessToast('Product updated! 🚀');
      } catch (error) {
        onErrorToast('Could not save product');
      }
    },
    [
      isEdit,
      modePicture,
      pictureUrl,
      updateProduct,
      onSuccessToast,
      storeProduct,
      deletePicture,
      id,
      onErrorToast,
      updatePicture,
    ]
  );

  return {
    isLoading,
    initialValues,
    onSubmit,
    isEdit,
    product: productRes?.data,
  };
};
