import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-bottom: 2rem;
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableRow = styled.tr``;

export const TableColumn = styled.th`
  ${({ theme }) => css`
    text-align: left;
    font-size: ${theme.sizes.extraSmall};
    padding-bottom: 0.5rem;
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    margin-bottom: 1rem;
  `}
`;
