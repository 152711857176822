import React from 'react';
import { Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const ProductFormLoader: React.FC = () => (
  <Paper style={{ padding: '1rem', marginBottom: '1rem' }}>
    <Skeleton height={100} />
    <Skeleton height={100} />
    <Skeleton height={100} />
    <Skeleton height={100} />
  </Paper>
);
