import React, { createContext, FC, useContext, useState } from 'react';

import { useWizard, UseWizardHook } from 'rx-hooks';
import {
  ISigninManufacturer,
  SignupBuyerBusinessForm,
  SignupBuyerPersonalForm,
} from 'rx-domain';
import { clearPhonenumber } from 'rx-utils';
import { StepperViews } from 'rx-hooks/utils/useWizard';

type ContextProps = UseWizardHook & {
  form: ISigninManufacturer;
  formError: string;
  onChangeWizardWithError: (id: string, error: string) => void;
  onSetPersonalInfoStep: (form: SignupBuyerPersonalForm) => void;
  onSetBusinessInfoStep: (form: SignupBuyerBusinessForm) => void;
};

const initialForm = {
  address: {
    address: '',
    city: '',
    state: 'AL',
    zipCode: '',
  },
  company: {
    email: '',
    name: '',
    phoneNumber: '',
  },
  email: '',
  firstName: '',
  howDidYouHearAboutUs: '',
  lastName: '',
  password: '',
  phoneNumber: '',
  titleOrPosition: '',
  subscribeToMailingList: false,
  acceptTOS: false,
};

const SignupManufacturerContext = createContext<ContextProps | undefined>(
  undefined
);

const SignupManufacturerProvider: FC = ({ children }) => {
  const [formData, setFormData] = useState<ISigninManufacturer>(initialForm);
  const [formError, setError] = useState<string>('');

  const wizardProps = useWizard([
    {
      title: 'Personal Information',
      left: '-5%',
      id: StepperViews.PERSONAL_INFORMATION,
    },
    {
      title: 'Business Information',
      left: '40%',
      id: StepperViews.BUSINESS_INFORMATION,
    },
    { title: 'Finish Up', left: '90%', id: StepperViews.FINISH_UP },
  ]);

  const onSetBusinessInfoStep = (form: SignupBuyerBusinessForm) => {
    setFormData((prev) => ({
      ...prev,
      company: {
        name: form.companyName,
        email: form.companyEmail,
        phoneNumber: clearPhonenumber(form.companyPhoneNumber),
      },
      address: {
        address: form.address,
        city: form.city,
        state: form.state,
        zipCode: form.zipCode,
      },
      phoneNumber: clearPhonenumber(prev.phoneNumber),
      subscribeToMailingList: form.subscribeToMailingList,
    }));
  };

  const onChangeWizardWithError = (id: string, error: string) => {
    setError(error);
    wizardProps.onChangeWizard(id);
  };

  const onSetPersonalInfoStep = ({
    confirmPassword,
    ...form
  }: SignupBuyerPersonalForm) => {
    setFormData((prev) => ({
      ...prev,
      ...form,
    }));
  };

  return (
    <SignupManufacturerContext.Provider
      value={{
        formError,
        form: formData,
        onSetBusinessInfoStep,
        onSetPersonalInfoStep,
        onChangeWizardWithError,
        ...wizardProps,
      }}
    >
      {children}
    </SignupManufacturerContext.Provider>
  );
};

const useSignupManufacturer = (): ContextProps => {
  const context = useContext(SignupManufacturerContext);

  if (context === undefined) {
    throw new Error(
      'useSignupManufacturer must be used within a SignupManufacturerProvider'
    );
  }

  return context;
};

export { useSignupManufacturer, SignupManufacturerProvider };
