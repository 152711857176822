import React from 'react';
import RxRiseLogo from 'assets/logos/rxrise-gray-logo.svg';

import { Container, Image, PlaceholderContainer } from './index.styles';

interface IProductImageProps {
  imgUrl?: string;
  productName: string;
}

export const ProductImage: React.FC<IProductImageProps> = (props) => {
  return props.imgUrl ? (
    <Container>
      <Image src={props.imgUrl} alt={props.productName} />
    </Container>
  ) : (
    <PlaceholderContainer>
      <Image src={RxRiseLogo} alt="not available" />
      <p>Product Image Not Available</p>
    </PlaceholderContainer>
  );
};
