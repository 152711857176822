import { useHistory } from 'react-router-dom';

import { InventoryType } from 'rx-domain';

import { onMakeURL } from 'rx-utils';

import { useQuery } from 'rx-hooks/utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useInventory = () => {
  const query = useQuery();
  const history = useHistory();

  const queryType = (query.get('type') as InventoryType) ?? 'drug';

  const changeView = (type: InventoryType) => {
    history.push(
      onMakeURL({
        type,
      })
    );
  };

  return {
    queryType,
    changeView,
  };
};

export { useInventory };
