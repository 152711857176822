import React, { useCallback, useMemo } from 'react';

import { GetSaleOrder } from 'rx-domain';
import { useGetSaleOrdersIdInvoice } from 'rx-api';
import { capitalize, formatDate, formatPhoneNumber } from 'rx-utils';
import { useToast } from 'rx-hooks';

import { TransactionPODetailsInfoRow } from 'pages/admin/transactions/TransactionPODetailsInfoRow';
import { TransactionShippingStatus } from 'pages/admin/transactions/TransactionShippingStatus';
import { InfoColumn, InvoiceLink } from './index.styles';

type IProps = GetSaleOrder.Response;

export const PurchaseOrderRequestInfo = (props: IProps): JSX.Element => {
  const invoiceResponse = useGetSaleOrdersIdInvoice();
  const toast = useToast();

  const onDownloadInvoice = useCallback(async () => {
    try {
      const response = await invoiceResponse.mutateAsync({
        id: props.id,
      });

      const blobFile = new Blob([response.data], {
        type: 'application/pdf',
      });

      const fileURL = blobFile && window.URL.createObjectURL(blobFile);

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = 'invoice.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.onErrorToast('Error: could not download invoice');
    }
  }, [invoiceResponse, props.id, toast]);

  const poDetails = useMemo(() => {
    return [
      {
        title: 'STATUS',
        content: (
          <span>
            <TransactionShippingStatus status={props.status} />
          </span>
        ),
      },
      {
        title: 'ORDER HISTORY',
        content: (
          <>
            {props.events.map((event) => (
              <React.Fragment key={event.id}>
                <span>
                  {capitalize(event.event)} - {formatDate(event.createdAt)}
                </span>
                <br />
              </React.Fragment>
            ))}
          </>
        ),
      },
      {
        title: 'BUYER INFO',
        content: (
          <>
            <span>
              {props.buyer?.firstName} {props.buyer?.lastName}
            </span>
            <br />
            <span>{formatPhoneNumber(props.buyer?.phoneNumber)}</span>
          </>
        ),
      },
      {
        title: 'BILLING INFO',
        content: (
          <>
            <span>{props.billingInformation?.address}</span>
            <br />
            <span>
              {props.billingInformation?.city},{' '}
              {props.billingInformation?.state},{' '}
              {props.billingInformation?.zipCode}
            </span>
          </>
        ),
      },
      {
        title: 'SHIPPING DETAILS',
        content: (
          <>
            <span>{props.shippingAddress?.address}</span>
            <br />
            <span>
              {props.shippingAddress?.city}, {props.shippingAddress?.state},{' '}
              {props.shippingAddress?.zipCode}
            </span>
          </>
        ),
      },
      {
        title: 'INVOICE',
        content: (
          <>
            {props.saleOrderInvoice && props && (
              <InvoiceLink onClick={onDownloadInvoice}>
                Download Invoice
              </InvoiceLink>
            )}
          </>
        ),
      },
    ];
  }, [onDownloadInvoice, props]);

  return (
    <InfoColumn>
      {poDetails.map((detail) => (
        <TransactionPODetailsInfoRow
          key={detail.title}
          content={detail.content}
          title={detail.title}
        />
      ))}
    </InfoColumn>
  );
};
