/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useGetBuyerProducts } from '../../../../../rx-api';
import { AppDispatch, setBuyerSearchText } from '../../../../../rx-store';
import { usePaginationQueries } from '../../../../../rx-hooks';
import { onMakeURL } from '../../../../../rx-utils';
import formatProduct from '../../../../../rx-utils/gtmFormatters';

const Input = styled(TextField)`
  ${({ theme }) => css`
    background-color: white;
    height: 100%;
    width: 100%;
    margin: 0;
    // ${theme.lessThan('ipadPro')} {
    //   width: 20%;
    // }
  `}
`;

const ContainerSearch = styled.div`
  ${({ theme }) => css`
    width: 450px;

    ${theme.lessThan('ipadPro')} {
      width: 400px;
    }
    ${theme.lessThan('ipad')} {
      width: 320px;
    }
    ${theme.lessThan('mobile')} {
      width: 220px;
    }
  `}
`;

const useDebounce = (value: string, delay: number) => {
  const [isLoadingDebounce, setIsLoadingDebounce] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    setIsLoadingDebounce(true);
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsLoadingDebounce(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return { debouncedValue, isLoadingDebounce };
};

const capitalizeFirstLetter = (string: string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export default function NewSearchAutoCompleteInput() {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState('');

  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const {
    querySearchType,
    queryViewType,
    querySortOrder,
    querySortBy,
    queryMinPrice,
    queryMaxPrice,
  } = usePaginationQueries();

  const onSearchInput = async (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;

    if (!target.value) {
      dispatch(setBuyerSearchText(''));
      return;
    }
    dispatch(setBuyerSearchText(target.value.trim()));
  };

  const { debouncedValue, isLoadingDebounce } = useDebounce(inputValue, 1000);
  const { data, isLoading } = useGetBuyerProducts({
    limit: debouncedValue !== '' ? '4' : '1',
    ...(querySearchType !== 'ndc' && { name: debouncedValue }),
    ...(querySearchType === 'ndc' && { ndc: debouncedValue }),
  });

  useEffect(() => {
    if (debouncedValue !== '') {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({ ecommerce: null });

      // @ts-ignore
      window.dataLayer.push({
        event: 'search',
        search_term: debouncedValue,
        ecommerce: {
          search_term: debouncedValue,
        },
        user_id: localStorage.getItem('_userid'),
      });
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (data?.data?.results && !isLoading && debouncedValue !== '') {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({ ecommerce: null });
      // @ts-ignore
      window.dataLayer.push({
        event: 'view_item_list',
        input: debouncedValue,
        ecommerce: {
          items: data?.data?.results
            ? data?.data?.results.map((product, index) => {
                return formatProduct({ product: product, index: index });
              })
            : [],
        },
        user_id: localStorage.getItem('_userid'),
      });
    }
  }, [data]);

  return (
    <ContainerSearch>
      <Autocomplete
        freeSolo
        id="search-buyer-name"
        disableClearable
        options={
          inputValue !== '' && !isLoading && !isLoadingDebounce
            ? data?.data?.results || []
            : []
        }
        renderOption={(option: any) => {
          if (option?.inputValue) {
            return <b>{option.name}</b>;
          }
          return (
            <Typography noWrap>{capitalizeFirstLetter(option.name)}</Typography>
          );
        }}
        onChange={(event, value: any) => {
          let valueUsed: string;
          if (value && value.inputValue) {
            valueUsed = value.inputValue;
          } else if (value && value.name && querySearchType !== 'ndc') {
            valueUsed = value.name;
          } else if (value && value.ndc && querySearchType === 'ndc') {
            valueUsed = value.ndc;
          } else {
            valueUsed = value;
          }
          const searchURL = onMakeURL({
            viewType: queryViewType,
            o: querySortOrder,
            b: querySortBy,
            minPrice: queryMinPrice,
            maxPrice: queryMaxPrice,
            search: valueUsed.trim(),
            t: querySearchType,
          });
          history.push(`/products/${searchURL}`);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option: any) => {
          if (option?.inputValue) {
            return option.name;
          }
          return capitalizeFirstLetter(option.name);
        }}
        filterOptions={(options: any, params) => {
          const filtered = [...options];

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Search for all products that match "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        renderInput={(params) => (
          <Input
            {...params}
            onKeyUp={onSearchInput}
            style={{ borderRadius: 0 }}
            ref={inputRef}
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: 'search' }}
          />
        )}
      />
    </ContainerSearch>
  );
}
