import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePostAuthLogout = () => {
  const onRequest = async () => {
    return http.post(`${process.env.REACT_APP_WEBSITE_URL}/v1/auth/logout`, {});
  };

  const mutation = useMutation(onRequest, {});

  return mutation;
};

export { usePostAuthLogout };
