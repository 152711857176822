import capitalize from 'lodash.capitalize';

type PurchaseOrderEventDto = {
  id: number;
  event: string;
  purchaseOrderId: number;
  createdBy: number;
  createdAt: string;
  updatedAt: string;
};

export interface IEventTimeline {
  getTimeline(): Array<{
    id: number;
    name: string;
    color: string;
    textColor: string;
    createdAt: string;
  }>;
}

export const EventTimeline = (
  events: PurchaseOrderEventDto[] = []
): IEventTimeline => {
  const formatEventName = (name: string) => {
    if (name === 'created') {
      return 'Order Created';
    }

    if (name === 'paid') {
      return 'Order Paid';
    }

    if (name === 'shipped') {
      return 'Order Shipped';
    }

    if (name === 'cancelled') {
      return 'Order Cancelled';
    }

    return name.split('_').map(capitalize).join(' ');
  };

  const getColorFromEvent = (name: string) => {
    if (name === 'cancelled') {
      return '#E75A10';
    }

    if (name === 'invoice_marked_as_delinquent') {
      return '#E75A10';
    }

    return '#3EB77D';
  };

  const getTextColorFromEvent = (name: string) => {
    if (name === 'cancelled') {
      return '#E75A10';
    }

    if (name === 'invoice_marked_as_delinquent') {
      return '#E75A10';
    }

    return '#3EB77D';
  };

  const isLastEvent = (eventName: string, eventList: any[] = []) => {
    return eventList[eventList.length - 1]?.name === eventName;
  };

  const toHumanDate = (isoDate: string) => {
    return new Date(isoDate).toUTCString().replace(' GMT', '');
  };

  const getTimeline = () => {
    const currentEvents = events.map((event) => {
      const name = formatEventName(event.event);
      const color = getColorFromEvent(event.event);
      const textColor = getTextColorFromEvent(event.event);

      return {
        id: event.id,
        name,
        color,
        textColor,
        createdAt: toHumanDate(event.createdAt),
      };
    });

    if (isLastEvent('Order Paid', currentEvents)) {
      currentEvents.push({
        id: currentEvents.length,
        name: 'Order Shipped',
        color: '#ED9F2D',
        textColor: '#2B3647',
        createdAt: 'Waiting for shipping',
      });
    }

    return currentEvents;
  };

  return { getTimeline };
};
