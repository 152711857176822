import styled, { css } from 'styled-components';

export const Container = styled.figure`
  ${({ theme }) => css`
    border: 1px ${theme.colors.gray[400]} solid;
    width: 100%;
  `}
`;

export const Image = styled.img`
  object-fit: fill;
  width: 100%;
`;

export const PlaceholderContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px ${theme.colors.gray[400]} solid;
  `}
`;
