import React from 'react';

import { ITableHeader } from 'rx-domain';

import {
  TableHead,
  TableRow,
  TableCell,
  TableAllCheckbox,
} from './index.styles';

export type RxTableHeaderProps = {
  items: ITableHeader[];
  selectAllComp?: React.ReactNode;
  selectAll?: boolean;
  onSelectAll?: () => void;
};

const RxTableHeader = (props: RxTableHeaderProps): JSX.Element => {
  return (
    <TableHead>
      <TableRow>
        {props.items.map(({ title, type, ...itemProps }, index) =>
          type === 'checkbox' ? (
            <TableCell
              {...itemProps}
              isSelectedAll={props.selectAll}
              key={index}
            >
              {!props.selectAll && props.onSelectAll && (
                <TableAllCheckbox
                  type="checkbox"
                  onChange={() => props.onSelectAll && props.onSelectAll()}
                  checked={props.selectAll}
                />
              )}
            </TableCell>
          ) : (
            <TableCell
              {...itemProps}
              isSelectedAll={props.selectAll}
              key={index}
            >
              {title}
            </TableCell>
          )
        )}
      </TableRow>
      {props.selectAll && (
        <TableRow>
          <th colSpan={props.items.length}>{props.selectAllComp}</th>
        </TableRow>
      )}
    </TableHead>
  );
};

RxTableHeader.defaultProps = {
  selectAllComp: <></>,
  selectAll: false,
  onSelectAll: () => {},
};

export { RxTableHeader };
