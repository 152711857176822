import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: white;
  border: 1px solid #9fa6b9;
  margin-top: 1.5rem;
  padding: 0.5rem 1rem 1rem 1rem;
`;

export const TabContainer = styled.div`
  border-bottom: 1px solid #9fa6b9;
  display: flex;
  flex-direction: row;
`;

export const SecondaryCTAContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const TabTitle = styled.p<{ active?: boolean }>`
  ${({ theme, active }) => css`
    padding: 0 1rem 0.5rem 1rem;
    cursor: pointer;

    ${active &&
    css`
      border-bottom: 1px solid ${theme.colors.blue[500]};
    `}
  `}
`;
