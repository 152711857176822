import React from 'react';

import { GetOrders, SearchQueryParams } from 'rx-domain';

import { OrderPagination } from 'pages/buyer/orders/OrderPagination';
import { OrderItem } from '../OrderItem';
import { PageContainer } from './index.styles';

type IProps = {
  totalPages: number;
  queries: SearchQueryParams;
  items: GetOrders.Order[];
};

export const OrderItems: React.FC<IProps> = (props) => {
  return (
    <div>
      {props.items.map((item) => (
        <OrderItem key={item.id} item={item} />
      ))}

      {props.totalPages > 1 && (
        <PageContainer>
          <OrderPagination
            totalPages={props.totalPages}
            queries={props.queries}
          />
        </PageContainer>
      )}
    </div>
  );
};
