import React, { createContext, FC, useContext } from 'react';
import { useLocalStorage } from 'rx-hooks';

type IFeatureFlag = {
  paymentTermsAccess: boolean;
  ordersV2: boolean;
};

type ContextProps = {
  featuresFlags: IFeatureFlag;
};

const FeatureFlagsContext = createContext<ContextProps | undefined>(undefined);

const FeatureFlagsProvider: FC = ({ children }) => {
  const [featuresFlags] = useLocalStorage('FG', {
    paymentTermsAccess: true,
    ordersV2: false,
  });

  return (
    <FeatureFlagsContext.Provider
      value={{ featuresFlags: featuresFlags as IFeatureFlag }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

const useFeatureFlags = (): ContextProps => {
  const context = useContext(FeatureFlagsContext);

  if (context === undefined) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagsProvider'
    );
  }

  return context;
};

export { useFeatureFlags, FeatureFlagsProvider };
