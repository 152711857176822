import styled, { css } from 'styled-components';

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2Big};
    font-family: ${theme.fonts.roobertBold};
    font-weight: 400;

    ${theme.lessThan('ipadPro')} {
      font-size: ${theme.sizes.medium};
    }
  `}
`;
