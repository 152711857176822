import { Button, Grid, TextField } from '@material-ui/core';
import React from 'react';

interface IProps {
  firstName: string;
  lastName: string;
  email: string;
  secondaryEmail: string;
  phoneNumber: string;
  titleOrPosition: string;

  isLoading: boolean;
  onSubmit(values: any): void;
  onChange(value: any): void;
}

export const AdminAccountForm: React.FC<IProps> = (props) => {
  return (
    <form onSubmit={props.onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="First Name"
            name="firstName"
            value={props.firstName}
            onChange={props.onChange}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Last Name"
            name="lastName"
            value={props.lastName}
            onChange={props.onChange}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Email"
            name="email"
            value={props.email}
            onChange={props.onChange}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Secondary Email"
            name="secondaryEmail"
            value={props.secondaryEmail}
            onChange={props.onChange}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Phone Number"
            name="phoneNumber"
            value={props.phoneNumber}
            onChange={props.onChange}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Title or Position"
            name="titleOrPosition"
            value={props.titleOrPosition}
            onChange={props.onChange}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={props.isLoading}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
