import { useMutation } from 'react-query';

import { http } from 'rx-api/http/repository';

type IRequest = {
  id: number;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePostAdminSendOrderToEDI = () => {
  const onRequest = async ({ id }: IRequest) => {
    return http.post(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/edi/purchase-orders`,
      {
        purchaseOrderId: id,
      }
    );
  };

  return useMutation(onRequest, {});
};

export { usePostAdminSendOrderToEDI };
