import React, { useEffect, useMemo } from 'react';

import { TemplateView, SEO } from 'rx-core';
import { useGetOrders } from 'rx-api';
import { useOrders } from 'rx-hooks';

import { OrderTabs } from './OrderTabs';
import { OrderItems } from './OrderItems';
import { OrdersEmpty } from './OrdersEmpty';
import { OrdersLoading } from './OrdersLoading';

import { Container, Title } from './index.styles';

export const OrdersView: React.FC = () => {
  const {
    queryFrom,
    queryTo,
    skip,
    totalPages,
    queryId,
    queryStatus,
    setLength,
  } = useOrders();

  const { data: ordersResponse, isLoading } = useGetOrders({
    id: queryId,
    status: queryStatus,
    from: queryFrom,
    to: queryTo,
    skip,
  });

  const emptyValues = useMemo(() => {
    return (
      !!ordersResponse?.data === true &&
      ordersResponse?.data.results.length === 0
    );
  }, [ordersResponse]);

  useEffect(() => {
    if (ordersResponse?.data) {
      setLength(ordersResponse.data.pagination.total);
    }
  }, [ordersResponse, setLength]);

  return (
    <TemplateView>
      <SEO title="RxRise | Order History" />
      <Container>
        <Title>My Orders</Title>
        <OrderTabs
          queries={{
            to: queryTo,
            from: queryFrom,
            status: queryStatus,
          }}
        >
          {isLoading && <OrdersLoading />}

          <OrdersEmpty
            noResults={
              !isLoading &&
              emptyValues &&
              queryId === 0 &&
              !!queryFrom === false &&
              !!queryTo === false
            }
            noSearchResults={
              !isLoading &&
              emptyValues &&
              (queryId > 0 || !!queryFrom === true || !!queryTo === true)
            }
          />

          {!isLoading &&
            ordersResponse?.data &&
            ordersResponse.data.results.length > 0 && (
              <OrderItems
                items={ordersResponse.data.results}
                queries={{
                  to: queryTo,
                  from: queryFrom,
                  status: queryStatus,
                }}
                totalPages={totalPages}
              />
            )}
        </OrderTabs>
      </Container>
    </TemplateView>
  );
};
