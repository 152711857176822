import React from 'react';
import styled from 'styled-components';

import { SignupSellerWizardLine } from '../atoms/SignupSellerWizardLine';
import { SignupSellerWizardItem } from './SignupSellerWizardItem';

type IProps = {
  items: Array<{ checked: boolean; title: string; left?: string }>;
  onClick: (id: string) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 814px;

  position: relative;
`;

const SignupSellerWizardSteps = ({ items, onClick }: IProps): JSX.Element => {
  return (
    <Container>
      {items.map((wi) => (
        <SignupSellerWizardItem
          text={wi.title}
          checked={wi.checked}
          key={wi.title}
          onClick={onClick}
          left={wi.left}
        />
      ))}
      <SignupSellerWizardLine />
    </Container>
  );
};

export { SignupSellerWizardSteps };
