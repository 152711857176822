import styled from 'styled-components';

export const Container = styled.tr`
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;

  cursor: pointer;

  background: rgb(255, 255, 255);
  box-shadow: 5px 5px 25px rgba(51, 60, 68, 0.08);
  border-radius: 10px;

  &:hover {
    background-color: rgb(199, 207, 250, 0.3);
  }
`;

export const Column = styled.td`
  font-size: 0.875rem;
  color: black;

  padding: 1rem;
`;
