import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { uniqBy } from 'lodash';
import {
  Breadcrumbs,
  Grid,
  Link,
  ListSubheader,
  MenuItem,
  Paper,
  Popper,
  TextField,
} from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { RxPagination, SearchHeader, SEO, TemplateView } from 'rx-core';
import { GetProducts, InventoryType } from 'rx-domain';
import {
  useGetBuyerProducts,
  useGetCatalogSellerList,
  useGetFeaturedProducts,
  useGetPharmacistAdCampaigns,
} from 'rx-api';
import { usePaginationQueries, useSkipPage } from 'rx-hooks';
import { filtersList, onMakeURL } from 'rx-utils';

import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { ProductsList } from '../organims/ProductsList';
import { ProductsEmpty } from '../organims/ProductsEmpty';
import { ProductsTable } from '../organims/ProductsTable';
import { AdModal } from '../molecules/AdModal';
import { AdBanner } from '../molecules/AdBanner';

const Container = styled(Grid)`
  border-bottom: 1px solid #9fa6b9;
  margin-bottom: 1rem;
  ${({ theme }) => css`
    padding: 1.25rem 1.5rem;

    ${theme.lessThan('ipadPro')} {
      padding: 1.25rem 0;
      margin-bottom: 1.25rem;
    }
  `}
`;

const useStyles = makeStyles(() => ({
  popper: {
    width: 'fit-content !important',
    maxWidth: 'none !important',
  },
}));

const BreadcumbsContainer = styled(Grid)`
  border-bottom: 1px solid #9fa6b9;
  margin-bottom: 0.2rem;
  justify-content: space-between;
  ${({ theme }) => css`
    padding: 1.25rem 0;
    ${theme.lessThan('ipadPro')} {
    }
  `}
`;

const ProductsContainer = styled(Grid)``;

const PaginationContainer = styled(Grid)`
  &.MuiGrid-item {
    margin-top: 1rem;
  }
`;

const ProductSearch = styled(SearchHeader)`
  ${({ theme }) => css`
    display: none;

    ${theme.lessThan('ipadPro')} {
      display: flex;
      min-width: auto;
      width: 100%;
      margin: 0 1rem;
      margin-bottom: 0.5rem;
    }
  `}
`;

const ProductItemContainer = styled(Grid)`
  height: fit-content;
`;

const ListHeader = styled(ListSubheader)`
  background-color: white;
  font-weight: bold;
  color: black;
`;

const ListItem = styled(MenuItem)``;

export const ProductsView: React.FC = () => {
  const {
    querySearch,
    querySearchType,
    querySortBy,
    queryPage,
    querySortOrder,
    queryMaxPrice,
    queryMinPrice,
    queryForm,
    queryCategory,
    queryViewType,
    querySellerId,
    queryStrengthMin,
    queryStrengthMax,
    queryStrengthUnit,
    limit,
  } = usePaginationQueries();
  const { data, isLoading: isLoadingSellerList } = useGetCatalogSellerList();
  const classes = useStyles();

  const [length, setLength] = useState(0);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = React.useState('all');
  const [sellerFilter, setSellerFilter] = React.useState<any>(
    parseInt(querySellerId, 10) || 'all'
  );

  const [mergedProducts, setMergedProducts] = useState<
    GetProducts.ProductItem[]
  >([]);
  const history = useHistory();

  useEffect(() => {
    const adStatus = localStorage.getItem('ad_status');
    if (!adStatus) {
      setOpen(true);
      localStorage.setItem('ad_status', '1');
    }
  }, []);

  const {
    data: { banner, popup },
  } = useGetPharmacistAdCampaigns();
  const { skip, totalPages } = useSkipPage(length, 15);
  const { data: products, isLoading } = useGetBuyerProducts({
    skip,
    limit,
    category: queryCategory as InventoryType,
    form: queryForm,
    [querySearchType]: querySearch,
    sortBy: querySortBy,
    sortOrder: querySortOrder,
    minPrice: parseInt(queryMinPrice ?? '0', 10),
    maxPrice: parseInt(queryMaxPrice ?? '0', 10),
    viewType: queryViewType as 'list' | 'grid' | undefined,
    sellerId: querySellerId,
    strengthMin: queryStrengthMin,
    strengthMax: queryStrengthMax,
    strengthUnit: queryStrengthUnit,
  });

  const { data: featuredProducts } = useGetFeaturedProducts({ limit: 99 });

  useEffect(() => {
    const filteredFeatureProducts =
      featuredProducts?.data?.data
        .filter((product) =>
          queryCategory
            ? product.category === queryCategory
            : product.name.toLowerCase().includes(querySearch.toLowerCase())
        )
        .sort(
          (productA, productB) =>
            new Date(productB.updatedAt).getTime() -
            new Date(productA.updatedAt).getTime()
        ) || [];

    const loadedProducts = products?.data?.results || [];
    if (Number.isNaN(queryPage)) {
      setMergedProducts(
        uniqBy([...filteredFeatureProducts, ...loadedProducts], 'id')
      );
    } else {
      setMergedProducts(
        loadedProducts.filter((product) => product.isFeatured === false)
      );
    }
  }, [featuredProducts, products, queryCategory, queryPage, querySearch]);

  useEffect(() => {
    if (products?.data && !isLoading) {
      setLength(products.data.pagination.total);
    }
  }, [products, isLoading]);

  const emptyProductList =
    products?.data &&
    products.data.results.length === 0 &&
    (querySearch || queryForm);

  const handleClose = () => {
    setOpen(false);
  };

  const onSearchByForm = (event: any) => {
    const { value } = event.target;
    setFilter(event.target.value);
    const fullURL = onMakeURL({
      search: querySearch,
      t: querySearchType,
      b: querySortBy,
      o: querySortOrder,
      category: queryCategory as InventoryType,
      viewType: queryViewType,
      sellerId: querySellerId,
      form: value !== 'all' ? value.toLowerCase() : null,
    });

    history.push(fullURL);
  };

  const handleChangePageSize = (event: React.ChangeEvent<any>) => {
    const url = onMakeURL({
      search: querySearch,
      b: querySortBy,
      o: querySortOrder,
      minPrice: queryMinPrice,
      maxPrice: queryMaxPrice,
      category: queryCategory,
      viewType: queryViewType,
      limit: event.target.value,
      sellerId: querySellerId,
    });
    history.push(url);
  };

  const handleSelect = (event: any) => {
    const [sortBy, sortOrder] = event.target.value.split('-');

    const fullURL = onMakeURL({
      search: querySearch,
      viewType: queryViewType,
      minPrice: queryMinPrice,
      maxPrice: queryMaxPrice,
      category: queryCategory,
      limit,
      form: queryForm,
      o: sortOrder,
      b: sortBy,
      sellerId: querySellerId,
    });

    history.push(fullURL);
  };

  const onSearchBySellerV2 = (id: any) => {
    setSellerFilter(id);
    const fullURL = onMakeURL({
      search: querySearch,
      t: querySearchType,
      b: querySortBy,
      o: querySortOrder,
      category: queryCategory as InventoryType,
      viewType: queryViewType,
      form: queryForm,
      sellerId: id === 'all' ? null : id,
    });

    history.push(fullURL);
  };

  const handleChangeViewTypeButton = (event: any) => {
    const { value } = event.target;

    const url = onMakeURL({
      search: querySearch,
      b: querySortBy,
      o: querySortOrder,
      minPrice: queryMinPrice,
      maxPrice: queryMaxPrice,
      category: queryCategory,
      limit,
      viewType: value,
      sellerId: querySellerId,
    });
    history.push(url);
  };

  const queryCategories = {
    drug: 'RxProducts',
    medical_supply: 'Medical and Diabetes Supplies',
    diagnostic_test: 'Covid and Other Tests',
  };

  const options = [{ id: 'all', name: 'All' }, ...(data?.data || [])].sort(
    (a, b) => {
      // No ordenar si es la opción "All"
      if (a.id === 'all') return -1;
      if (b.id === 'all') return 1;

      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      return nameA.localeCompare(nameB);
    }
  );

  const filterOptions = (
    optionsList: any,
    { inputValue }: { inputValue: string }
  ) => {
    const filtered = optionsList.filter((option: any) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    return filtered;
  };

  return (
    <TemplateView>
      <SEO title="RxRise | Products" />
      {popup && (
        <AdModal
          url={popup?.link}
          open={open}
          onClose={handleClose}
          image={popup?.pictureUrl}
        />
      )}
      <Container container>
        <ProductsContainer container item direction="row" xs={12} md={12}>
          <ProductSearch />
          <ProductItemContainer container item xs={12} md={12}>
            <BreadcumbsContainer container>
              <div style={{ display: 'flex' }}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    color="inherit"
                    component={RouterLink as any}
                    to="/"
                  >
                    Home
                  </Link>
                  {queryCategory && (
                    <Link
                      underline="hover"
                      color="primary"
                      component={RouterLink as any}
                      to={`/products?category=${queryCategory}`}
                    >
                      {
                        queryCategories[
                          queryCategory as keyof typeof queryCategories
                        ]
                      }
                    </Link>
                  )}
                </Breadcrumbs>
                <span style={{ paddingLeft: '5px' }}>/</span>
              </div>
            </BreadcumbsContainer>
            <Paper
              style={{
                marginBottom: '10px',
                width: '100%',
              }}
            >
              <div
                style={{ background: 'white', width: '100%', display: 'flex' }}
              >
                <div
                  style={{
                    flex: '1',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                >
                  <TextField
                    variant="outlined"
                    label="Type"
                    margin="normal"
                    name="test"
                    onChange={onSearchByForm}
                    value={filter}
                    select
                    fullWidth={true}
                  >
                    <MenuItem value="all">
                      <em>All</em>
                    </MenuItem>
                    {Array.from(filtersList.entries()).map((item) => {
                      const [key, values] = item;
                      const category = <ListHeader>{key}</ListHeader>;
                      const menuItems = values.map((value) => (
                        <ListItem value={value}>{value}</ListItem>
                      ));
                      return [category, menuItems];
                    })}
                  </TextField>
                </div>
                <div
                  style={{
                    flex: '1',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                >
                  <TextField
                    variant="outlined"
                    label="Sort By"
                    margin="normal"
                    name="sort-by"
                    onChange={handleSelect}
                    value={`${querySortBy}-${querySortOrder}`}
                    select
                    fullWidth={true}
                  >
                    <MenuItem value="price-asc">Price: Low to High</MenuItem>
                    <MenuItem value="price-desc">Price: High to Low</MenuItem>
                    <MenuItem value="name-asc">Name: A to Z</MenuItem>
                    <MenuItem value="name-desc">Name: Z to A</MenuItem>
                  </TextField>
                </div>
                <div
                  style={{
                    flex: '1',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                >
                  {!isLoadingSellerList && (
                    <Autocomplete
                      value={sellerFilter}
                      PopperComponent={(props) => (
                        <Popper {...props} className={classes.popper} />
                      )}
                      onChange={(event, newValue) => {
                        if (!newValue) {
                          onSearchBySellerV2('all');
                        } else {
                          onSearchBySellerV2(newValue.id);
                        }
                      }}
                      options={options}
                      getOptionSelected={(option, value) => {
                        return option.id === value;
                      }}
                      filterOptions={filterOptions}
                      getOptionLabel={(option) => {
                        if (option === 'all') {
                          return 'All';
                        }

                        if (!Number.isNaN(option)) {
                          const result = options.find(
                            (optionItem) => optionItem.id === option
                          );
                          return result?.name || '';
                        }
                        return option.name;
                      }}
                      renderOption={(option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Seller"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                        />
                      )}
                      fullWidth
                    />
                  )}
                </div>
                <div
                  style={{
                    flex: '1',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                >
                  <TextField
                    variant="outlined"
                    label="Items Per Page"
                    margin="normal"
                    name="sort-by"
                    onChange={handleChangePageSize}
                    value={limit}
                    select
                    fullWidth={true}
                  >
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                    <MenuItem value="90">90</MenuItem>
                  </TextField>
                </div>
                <div
                  style={{
                    flex: '1',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                >
                  <TextField
                    variant="outlined"
                    label="View Type"
                    margin="normal"
                    name="sort-by"
                    onChange={handleChangeViewTypeButton}
                    value={queryViewType}
                    select
                    fullWidth={true}
                  >
                    <MenuItem value="list">List</MenuItem>
                    <MenuItem value="grid">Grid</MenuItem>
                  </TextField>
                </div>
              </div>
            </Paper>
            {queryViewType === 'list' && (
              <ProductsTable
                isLoading={isLoading}
                products={mergedProducts || []}
              />
            )}
            {queryViewType === 'grid' && (
              <>
                {emptyProductList ? (
                  <ProductsEmpty
                    searchText={querySearch}
                    queryForm={queryForm}
                  />
                ) : (
                  <ProductsList
                    products={mergedProducts ?? []}
                    loading={isLoading}
                    type={queryCategory as InventoryType}
                  />
                )}
              </>
            )}
            {banner && (
              <AdBanner image={banner?.pictureUrl} url={banner?.link} />
            )}
            <PaginationContainer item md={12} xs={12}>
              {totalPages > 1 && (
                <RxPagination path="products" totalPages={totalPages} />
              )}
            </PaginationContainer>
          </ProductItemContainer>
        </ProductsContainer>
      </Container>
    </TemplateView>
  );
};
