import { useMemo, useState } from 'react';

type WizardRawItem = {
  id: string;
  title: string;
  left?: string;
};

type WizardItem = WizardRawItem & {
  checked: boolean;
};

export type UseWizardHook = {
  currentView: WizardItem | undefined;
  items: WizardItem[];
  onChangeWizard: (id: string) => void;
  isView: (title: string) => boolean;
};

export enum StepperViews {
  VALIDATION = 'validation',
  PERSONAL_INFORMATION = 'personalInfo',
  PHARMACY_INFORMATION = 'pharmacyInfo',
  SHIPPING_INFORMATION = 'shippingInfo',
  FINISH_UP = 'finishUp',
  BUSINESS_INFORMATION = 'businessInfo',
}

const useWizard = (wizardItems: WizardRawItem[]): UseWizardHook => {
  const [items, setWizardItems] = useState<Array<WizardItem>>(() => {
    return wizardItems.map((item, i) => ({
      ...item,
      checked: i === 0,
    }));
  });

  const currentView = useMemo(() => {
    return [...items].reverse().find((w) => w.checked);
  }, [items]);

  const isView = (title: string): boolean => currentView?.title !== title;

  const onChangeWizard = (id: string) => {
    const findIndexItem = items.findIndex((w) => w.id === id);

    const newItems = items.map((w, i) =>
      i > findIndexItem ? { ...w, checked: false } : { ...w, checked: true }
    );

    setWizardItems(newItems);
  };

  return {
    currentView,
    onChangeWizard,
    items,
    isView,
  };
};

export { useWizard };
