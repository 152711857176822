import React, { useEffect, useState } from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { InventoryType, ITableHeader } from 'rx-domain';
import { useGetAdminProducts } from 'rx-api';
import { usePaginationQueries, useSkipPage } from 'rx-hooks';
import { RxPagination } from 'rx-core';

import { CsvUploadModal } from 'pages/general/csv-upload/organims';
import { useAdminProducts, useDownloadProduct } from 'rx-contexts';
import { AdminProductsDataItems } from '../molecules/AdminProductsDataItems';
import { AdminProductsDeleteProduct } from '../molecules/AdminProductsDeleteProduct';
import { AdminProductsTableLoader } from '../atoms/AdminProductsTableLoader';
import { AdminProductsEmpty } from '../atoms/AdminProductsEmpty';
import { AdminProductsEDIModal } from '../molecules/AdminProductsEDIModal';
import AdminDownloadProductsModal from './AdminDownloadProductsModal';

const headerItems: ITableHeader[] = [
  {
    title: '',
    span: 1,
  },
  {
    title: 'NDC',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 3,
  },
  {
    title: 'NAME',
    width: '12%',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 3,
  },
  {
    title: 'DESCRIPTION',
    width: '10%',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 3,
  },
  {
    title: 'EXP DATE',
    width: '10%',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 3,
  },
  {
    title: 'PRICE',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 1,
  },
  {
    title: 'QUANTITY',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    span: 1,
  },
  {
    title: 'Featured',
    span: 1,
  },
  {
    title: '',
    span: 1,
  },
];

interface IAdminProductsTableProps {
  onChangeCount: (count: number) => void;
  setEdiModalOpen: (value: boolean) => void;
  ediModalOpen: boolean;
}

export const AdminProductsTable: React.FC<IAdminProductsTableProps> = (
  props
) => {
  const {
    querySearch,
    querySortBy,
    querySortOrder,
    querySearchType,
    queryCategory,
  } = usePaginationQueries({
    sortByDefault: 'createdAt',
  });

  const { downloadProductsVisible } = useDownloadProduct();

  const { onSetLengthData } = useAdminProducts();
  const [length, setLength] = useState(0);
  const { skip, totalPages } = useSkipPage(length);

  const {
    data: inventory,
    isLoading,
    refetch,
  } = useGetAdminProducts({
    orderBy: querySortBy as 'ndc' | 'shippingName' | 'id' | 'createdAt',
    orderCriteria: querySortOrder,
    id:
      (querySearchType as 'id' | 'ndc' | 'name' | 'shipper') === 'id'
        ? parseInt(querySearch, 10)
        : undefined,
    name:
      (querySearchType as 'id' | 'ndc' | 'name' | 'shipper') === 'name'
        ? querySearch
        : undefined,
    ndc:
      (querySearchType as 'id' | 'ndc' | 'name' | 'shipper') === 'ndc'
        ? querySearch
        : undefined,
    shipper:
      (querySearchType as 'id' | 'ndc' | 'name' | 'shipper') === 'shipper'
        ? querySearch
        : undefined,
    category: queryCategory as InventoryType,
    skip,
    limit: 10,
  });

  useEffect(() => {
    if (inventory?.data && !isLoading) {
      setLength(inventory.data.pagination.total);
      onSetLengthData(inventory.data.pagination.total);
      props.onChangeCount(inventory.data.pagination.total);
    }
  }, [inventory, isLoading, onSetLengthData, props]);

  return (
    <>
      <CsvUploadModal onUploadSuccess={refetch} />
      {downloadProductsVisible && <AdminDownloadProductsModal />}
      <AdminProductsEDIModal
        open={props.ediModalOpen}
        handleOnClose={props.setEdiModalOpen}
      />
      <TableContainer>
        <AdminProductsDeleteProduct onDeleteSuccess={refetch} />
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerItems.map(({ title, span }, index) => (
                <TableCell colSpan={span} key={index}>
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {isLoading && <AdminProductsTableLoader />}

          {!isLoading &&
            inventory?.data.results &&
            inventory?.data.pagination.total === 0 &&
            querySearch && (
              <AdminProductsEmpty>
                <Typography align="center" variant="h6">
                  There is no product matching your criteria.
                </Typography>

                <Typography align="center" variant="h6">
                  Please try another search criteria.
                </Typography>
              </AdminProductsEmpty>
            )}

          {!isLoading &&
            inventory?.data.results &&
            inventory?.data.pagination.total === 0 &&
            !querySearch && (
              <AdminProductsEmpty>
                <Typography align="center" variant="h6">
                  No Products Found
                </Typography>
              </AdminProductsEmpty>
            )}

          {!isLoading &&
            inventory?.data.results &&
            inventory?.data.pagination.total > 0 && (
              <AdminProductsDataItems items={inventory?.data.results ?? []} />
            )}
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <RxPagination
          path="products"
          totalPages={totalPages}
          defaultQueries={{
            sortByDefault: 'createdAt',
          }}
        />
      )}
    </>
  );
};
