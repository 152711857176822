import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import { Form, Formik } from 'formik';

import { GetProductById, GetProducts } from 'rx-domain';
import { useShoppingCartOnline, useToast } from 'rx-hooks';
import { AppDispatch, setCartItem } from 'rx-store';

import { AddProductSchema } from 'utils/validators/pharmacist/add-product.validator';

import { ProductRestrictions } from '../ProductRestrictions';
import { ProductAddShopCart } from '../ProductAddShopCart';
import { ProductDetails } from '../ProductDetails';
import { ProductImage } from '../ProductImage';
import { ProductLink } from '../ProductLink';

import { Container, Title } from './index.styles';
import formatProduct from '../../../../rx-utils/gtmFormatters';

type IProps = {
  product: GetProductById.Response;
};

type IForm = {
  units: number;
};

export const ProductContent: React.FC<IProps> = (props): JSX.Element => {
  const initialValues: IForm = {
    units: 0,
  };

  const dispatch: AppDispatch = useDispatch();

  const { onStoreShoppingCart } = useShoppingCartOnline();
  const { onSuccessToast, onErrorToast } = useToast();

  const onSubmit = async (values: IForm) => {
    try {
      dispatch(
        setCartItem({
          product: { ...props.product, minimumOrderAmount: '' },
          count: values.units,
        })
      );

      await onStoreShoppingCart(props.product.id, values.units);

      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({ ecommerce: null });
      // @ts-ignore
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: 'USD',
          value: 0,
          items: [
            {
              ...formatProduct({
                product: props.product as unknown as GetProducts.ProductItem,
                index: 0,
                quantity: values.units,
              }),
            },
          ],
        },
        user_id: localStorage.getItem('_userid'),
      });

      onSuccessToast(`Added to Cart! 🛒`);
    } catch (err) {
      onErrorToast('Something goes wrong, please retry later');
    }
  };

  return (
    <Container container direction="column">
      <Grid>
        <ProductLink productName={props.product.name} />
        <Title>{props.product.name}</Title>
      </Grid>

      <Grid container item direction="row" spacing={2}>
        <Grid item xs={3}>
          <ProductImage
            productName={props.product.name}
            imgUrl={props.product.pictureUrl}
          />
        </Grid>
        <Grid item xs={9}>
          <Paper variant="outlined" style={{ padding: '1rem' }}>
            <ProductDetails product={props.product} />

            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={AddProductSchema}
            >
              <Form>
                <ProductAddShopCart product={props.product} />
              </Form>
            </Formik>
            <ProductRestrictions product={props.product} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
