import React from 'react';
import styled, { css } from 'styled-components';

import { Grid, Tooltip } from '@material-ui/core';

type IProps = {
  title: string;
  content: string | number;
};

const ColumnTitle = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.sizes.extraSmall};
    font-family: ${theme.fonts.robotoRegular};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

const ColumnContent = styled(ColumnTitle)`
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ProductItemInfo = (props: IProps): JSX.Element => {
  return (
    <Grid item xs={4} md={4}>
      <Grid container item direction="column">
        <Grid container item xs={12} zeroMinWidth>
          <Tooltip title={props.content} arrow placement="bottom-start">
            <ColumnTitle>
              <b>{props.title}</b>
            </ColumnTitle>
          </Tooltip>
        </Grid>
        <Grid container item xs={12}>
          <Tooltip title={props.content} arrow placement="bottom-start">
            <ColumnContent>{props.content}</ColumnContent>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { ProductItemInfo };
