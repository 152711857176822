import React from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Paper, Typography } from '@material-ui/core';

import { http } from 'rx-api';
import { BASE_URL } from 'rx-api/api/constants';
import { useToast, useViewEditMode } from 'rx-hooks';

import {
  SettingsPharmacyLegalDocumentsContainer,
  SettingsPharmacyLegalDocumentsHeader,
} from './index.styles';
import {
  PharmacyLegalDocumentsEditDEAForm,
  PharmacyLegalDocumentsEditStateForm,
  PharmacyLegalDocumentsViewForm,
} from '../../../shared/PharmacyLegalDocuments';

interface IProps {
  data: Array<{
    id: number;
    name: 'dea' | 'state_license';
    url: string;
  }>;
  isLoading: boolean;
  disabled: boolean;
}

export const SettingsPharmacyLegalDocuments: React.FC<IProps> = (props) => {
  const {
    modeDEA,
    modeState,
    setViewModeDEA,
    setEditModeDEA,
    setViewModeState,
    setEditModeState,
  } = useViewEditMode();
  const [deaFile, setDeaFile] = React.useState<File | null>(null);
  const [stateLicenseFile, setStateLicenseFile] = React.useState<File | null>(
    null
  );
  const [saving, setSaving] = React.useState(false);
  const toast = useToast();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();

  const dea = props.data.find((file) => file.name === 'dea');
  const stateLicense = props.data.find((file) => file.name === 'state_license');

  const handleSubmitFiles = async (event: any) => {
    try {
      event.preventDefault();
      setSaving(true);

      const deaForm = new FormData();
      deaForm.append('file', deaFile || '');
      deaForm.append('type', deaFile?.type || '');

      const stateLicenseForm = new FormData();
      stateLicenseForm.append('file', stateLicenseFile || '');
      stateLicenseForm.append('type', stateLicenseFile?.type || '');

      if (modeDEA === 'edit') {
        await http.post(
          `${BASE_URL}/v1/pharmacist/pharmacies/${id}/pharmacy-files/dea`,
          deaForm
        );
      } else {
        await http.post(
          `${BASE_URL}/v1/pharmacist/pharmacies/${id}/pharmacy-files/state_license`,
          stateLicenseForm
        );
      }

      await queryClient.refetchQueries('get-pharmacist-pharmacy-by-id', {
        active: true,
      });
      toast.onSuccessToast('Files updated!');
      setSaving(false);
      setViewModeDEA();
      setViewModeState();
    } catch (err) {
      toast.onErrorToast('Could not updated files!');
      setSaving(false);
    }
  };

  const handleChangeDea = (event: any) => {
    return setDeaFile(event.target.files[0]);
  };

  const handleChangeStateLicense = (event: any) => {
    return setStateLicenseFile(event.target.files[0]);
  };

  return (
    <SettingsPharmacyLegalDocumentsContainer>
      <Paper variant="outlined" style={{ padding: '1rem' }}>
        <SettingsPharmacyLegalDocumentsHeader>
          <Typography variant="h5">Legal Documents</Typography>
        </SettingsPharmacyLegalDocumentsHeader>

        {modeDEA === 'view' && modeState === 'view' && (
          <PharmacyLegalDocumentsViewForm
            deaUrl={dea?.url || ''}
            stateLicenseUrl={stateLicense?.url || ''}
            loading={props.isLoading}
            disabled={props.disabled}
            onClickEditDEA={setEditModeDEA}
            onClickEditState={setEditModeState}
          />
        )}

        {modeDEA === 'edit' && (
          <PharmacyLegalDocumentsEditDEAForm
            onSubmit={handleSubmitFiles}
            onChangeDea={handleChangeDea}
            onChangeStateLicense={handleChangeStateLicense}
            loading={saving}
            onClickCancel={setViewModeDEA}
          />
        )}

        {modeState === 'edit' && (
          <PharmacyLegalDocumentsEditStateForm
            onSubmit={handleSubmitFiles}
            onChangeDea={handleChangeDea}
            onChangeStateLicense={handleChangeStateLicense}
            loading={saving}
            onClickCancel={setViewModeState}
          />
        )}
      </Paper>
    </SettingsPharmacyLegalDocumentsContainer>
  );
};
