import React from 'react';
import { useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import { usePutPharmacistBillingId } from 'rx-api';
import { useToast } from 'rx-hooks';
import { US_STATES_WITH_CODES } from 'rx-utils';

import { PharmacistBillingSchema } from 'utils/validators/admin/pharmacist-billing.validator';
import { Container, Title } from './index.styles';

type IForm = {
  name: string;
  address: string;
  state: string;
  city: string;
  zipCode: string;
};

type IProps = {
  id: string;
  data: IForm;
};

export const PharmacyProfileBillingInformation: React.FC<IProps> = (props) => {
  const queryClient = useQueryClient();

  const toast = useToast();
  const updateBilling = usePutPharmacistBillingId(props.id);

  const formik = useFormik({
    initialValues: {
      name: props.data.name,
      address: props.data.address,
      state: props.data.state || 'AL',
      city: props.data.city,
      zipCode: props.data.zipCode,
    },
    validationSchema: PharmacistBillingSchema,
    enableReinitialize: true,
    onSubmit: async (values: IForm) => {
      try {
        await updateBilling.mutateAsync({
          name: values.name,
          billingAddress: {
            address: values.address,
            state: values.state,
            city: values.city,
            zipCode: values.zipCode,
          },
        });
        await queryClient.refetchQueries('get-admin-pharmacists-id', {
          active: true,
        });
        toast.onSuccessToast('Changes saved succesfully 🚀');
      } catch (err) {
        toast.onErrorToast('Could not save data');
      }
    },
  });

  return (
    <Container item xs={12}>
      <Title>Billing information</Title>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              id="name"
              name="name"
              label="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={!!formik.errors.name}
              helperText={formik.errors.name}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              id="address"
              name="address"
              label="Address"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={!!formik.errors.address}
              helperText={formik.errors.address}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="city"
              name="city"
              label="City"
              value={formik.values.city}
              onChange={formik.handleChange}
              variant="outlined"
              error={!!formik.errors.city}
              helperText={formik.errors.city}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="state-label" style={{ marginLeft: '0.75rem' }}>
                State
              </InputLabel>
              <Select
                labelId="state-label"
                id="state"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                variant="outlined"
                error={!!formik.errors.state}
                fullWidth
                required
              >
                {US_STATES_WITH_CODES.map((state) => (
                  <MenuItem id={state.name} value={state.value}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="zipCode"
              name="zipCode"
              label="Zip Code"
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              error={!!formik.errors.zipCode}
              helperText={formik.errors.zipCode}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting}
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
