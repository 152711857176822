import React from 'react';
import styled from 'styled-components';

import PillThree from '../../../../assets/images/random/pill-three.png';

const Img = styled.img`
  width: 130px;
  height: 90px;

  position: absolute;
  top: -45px;
  right: calc(45vw - 65px);
  display: none;
`;

const FooterPillIconThree = (): JSX.Element => {
  return <Img src={PillThree} />;
};

export { FooterPillIconThree };
