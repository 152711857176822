import React from 'react';
import styled from 'styled-components';

import { SEO } from 'rx-core';
import { LoginFormTemplate } from 'pages/general/login/template';
import { LoginHeader } from 'pages/general/login/molecules';
import { ResetPasswordForm } from '../organims';

const Container = styled.div`
  height: 100vh;
  position: relative;
`;

const ResetPasswordView = (): JSX.Element => {
  return (
    <Container>
      <SEO title="RxRise | Reset Password" />
      <LoginHeader />
      <LoginFormTemplate height="auto">
        <ResetPasswordForm />
      </LoginFormTemplate>
    </Container>
  );
};

export { ResetPasswordView };
