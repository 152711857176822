import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { EdiLogStatus } from './EdiLogStatus';

interface IProps {
  results: {
    id: number;
    status: string;
    externalId: string | null;
    productCount: number;
    createdAt: string;
    updatedAt: string;
  }[];
}

export const EdiTableRows: React.FC<IProps> = ({ results = [] }) => {
  return (
    <>
      {results.map((log) => {
        const utcDate = new Date(log.createdAt);
        const estDateString = utcDate.toLocaleString('en-US', {
          timeZone: 'America/New_York',
        });

        return (
          <TableRow>
            <TableCell>{log.externalId}</TableCell>
            <TableCell>{log.productCount}</TableCell>
            <TableCell>{estDateString}</TableCell>
            <TableCell>
              <EdiLogStatus status={log.status} />
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};
