import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SearchQueryParams } from 'rx-domain';

import { getDateValue, onMakeURL } from 'rx-utils';

import { useDownloadFile } from 'rx-hooks';
import { useDownloadPurchaseOrders } from 'rx-api';
import {
  Container,
  TabContainer,
  TabTitle,
  SecondaryCTAContainer,
} from './index.styles';
import { OrderFilters } from '../OrderFilters';
import { formatPurchaseOrders } from '../../../../utils/csv/csvFormatter';
import { Button, Popover, Checkbox, FormControlLabel } from '@material-ui/core';

type IProps = {
  queries: SearchQueryParams;
};

export const OrderTabs: React.FC<IProps> = ({ queries, children }) => {
  const history = useHistory();
  const { onDownload } = useDownloadFile();
  const downloadOrdersMutation = useDownloadPurchaseOrders();
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkboxes, setCheckboxes] = useState({
    includeOrderItems: false,
  });

  const changeFilter = (
    type: 'pending' | 'fulfilled' | 'shipped' | 'cancelled' | 'all'
  ) => {
    history.push(
      onMakeURL({
        ...queries,
        status: type,
      })
    );
  };

  const handleOnClick = async () => {
    const { data } = await downloadOrdersMutation.mutateAsync();
    const result = formatPurchaseOrders(
      data.results,
      checkboxes.includeOrderItems
    );
    onDownload(
      result,
      `purchase-orders-history-${getDateValue(new Date().toUTCString(), true)}`
    );
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCheckboxChange = (event: any) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Container>
      <TabContainer>
        <TabTitle
          onClick={() => changeFilter('all')}
          active={queries.status === 'all'}
        >
          All orders
        </TabTitle>
        <TabTitle
          onClick={() => changeFilter('cancelled')}
          active={queries.status === 'cancelled'}
        >
          Cancelled
        </TabTitle>
        <TabTitle
          onClick={() => changeFilter('shipped')}
          active={queries.status === 'shipped'}
        >
          Shipped
        </TabTitle>
        <TabTitle
          onClick={() => changeFilter('pending')}
          active={queries.status === 'pending'}
        >
          Pending
        </TabTitle>
      </TabContainer>
      <SecondaryCTAContainer>
        <div>
          <Button
            aria-describedby={id}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Download Report
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div style={{ padding: '20px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkboxes.includeOrderItems}
                    onChange={handleCheckboxChange}
                    name="includeOrderItems"
                  />
                }
                label="Include Order Items"
              />
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: '10px' }}
                onClick={handleOnClick}
              >
                Download
              </Button>
            </div>
          </Popover>
        </div>
        <OrderFilters queries={queries} />
      </SecondaryCTAContainer>
      {children}
    </Container>
  );
};
