import { MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { useGetSellers } from 'rx-api';

const TextFieldStyledLog = styled(TextField)`
  background-color: white;
  width: 200px;
`;

const TextFieldStyledUsername = styled(TextField)`
  background-color: white;
  width: 150px;
  margin-left: 20px;
`;

const TextFieldStyledAction = styled(TextField)`
  background-color: white;
  width: 120px;
  margin-left: 20px;
`;

const FilterContainer = styled.div`
  display: flex;
`;

type LogFiltersProps = {
  filter: string;
  onFilterChange: (
    filterName: string,
    filterValue: 'login' | 'edi-transmit' | 'edi-download' | 'shopping-cart'
  ) => void;
  seller: string;
  onChangeSeller(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void;
};

export const SystemLogFilters: React.FC<LogFiltersProps> = (props) => {
  const [selectedTable, setSelectedTable] = React.useState('login');
  const { data: sellers } = useGetSellers({
    limit: 10000,
  });

  return (
    <FilterContainer>
      <TextFieldStyledLog
        name="log"
        id="log"
        variant="outlined"
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        value={selectedTable}
        onChange={(e: any) => {
          setSelectedTable(e.target.value);
          props.onFilterChange('selectedTable', e.target.value);
        }}
        select
      >
        <MenuItem value="login">Login System Logs</MenuItem>
        <MenuItem value="shopping-cart">Shopping Cart Logs</MenuItem>
        <MenuItem value="edi-download">EDI Download Inventory Logs</MenuItem>
        <MenuItem value="edi-transmit">EDI Transmit Inventory Logs</MenuItem>
      </TextFieldStyledLog>

      {selectedTable === 'login' && (
        <>
          <TextFieldStyledUsername
            name="email"
            id="email"
            variant="outlined"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            value="all"
            onChange={(_e: any) => {}}
            select
          >
            <MenuItem value="all">Email: All</MenuItem>
          </TextFieldStyledUsername>
          <TextFieldStyledAction
            name="action"
            id="action"
            variant="outlined"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            value="all"
            onChange={(e) => {}}
            select
          >
            <MenuItem value="all">Type: All</MenuItem>
            <MenuItem value="added">Pharmacist</MenuItem>
            <MenuItem value="updated">Manufacturer</MenuItem>
            <MenuItem value="removed">Admin</MenuItem>
          </TextFieldStyledAction>
        </>
      )}

      {selectedTable === 'edi' && (
        <>
          <TextFieldStyledAction
            name="seller"
            id="seller"
            variant="outlined"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            value={props.seller}
            onChange={props.onChangeSeller}
            select
          >
            <MenuItem value="all">Seller: All</MenuItem>
            {sellers?.data?.results?.map((seller) => (
              <MenuItem key={seller.id} value={seller.manufacturer.companyName}>
                {seller.manufacturer.companyName}
              </MenuItem>
            ))}
          </TextFieldStyledAction>
        </>
      )}
    </FilterContainer>
  );
};
