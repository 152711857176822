import React from 'react';
import styled from 'styled-components';
import { Footer } from '../../../../rx-core';
import RxHeaderV2 from '../RxHeaderV2/RxHeaderV2';

const Container = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: white;

  display: flex;
  flex-direction: column;
`;

const CustomFooter = styled(Footer)`
  position: relative;
  bottom: 0;
`;

const Content = styled.div``;

type IProps = {
  isHomePage?: boolean;
  children: React.ReactNode[];
};

export const TemplateViewV2 = ({ isHomePage, children }: IProps) => {
  return (
    <Container>
      <RxHeaderV2 isHomePage={isHomePage} />
      <Content>{children}</Content>
      <CustomFooter />
    </Container>
  );
};

export const TemplateContent = styled.section`
  margin: 2rem 0;
`;
