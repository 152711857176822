import styled from 'styled-components';
import { RxTable } from 'rx-core';

export const Container = styled.div`
  width: 80%;
  padding: 1rem;
`;

export const Table = styled(RxTable)`
  tr:nth-child(even) {
    background-color: #e9ecfe;
  }

  background-color: transparent;
`;

export const TableHeader = styled.thead``;

export const TableHeaderRow = styled.th`
  padding: 1rem;
  text-align: left;
`;

export const TableColumn = styled.tr`
  & > td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left-style: solid;
  }

  & > td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right-style: solid;
  }
`;

export const TableRow = styled.td`
  padding: 1rem;

  border: 1px #c7cffa solid;
  border-style: solid none;
`;

export const TableBody = styled.tbody``;

export const TFoot = styled.tfoot`
  tr:nth-child(even) {
    background-color: transparent;
  }

  tr {
    border: none;
  }

  td {
    border: none;
    border-style: inherit;
  }

  td:first-child {
    border-left-style: inherit;
  }

  td:last-child {
    border-right-style: inherit;
  }
`;
