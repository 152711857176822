import { useMutation } from 'react-query';

import { IPostOrdersReceiptDTO, IPostOrdersReceiptResponse } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePostOrdersReceipt = () => {
  const onRequest = async ({ id }: IPostOrdersReceiptDTO) => {
    return http.post<IPostOrdersReceiptResponse>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/orders/${id}/receipt`,
      {}
    );
  };

  return useMutation(onRequest, {});
};

export { usePostOrdersReceipt };
