import { useMutation } from 'react-query';
import { IPutAccountManufacturerDataDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePutAccountManufacturerData = () => {
  return useMutation(
    ['update-manufacturer-data'],
    async (request: IPutAccountManufacturerDataDTO) => {
      return http.put(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/account/manufacturer-data`,
        request
      );
    },
    {}
  );
};
