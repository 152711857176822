import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'ramda';

import { IAddProductForm } from 'rx-domain';
import { useGetProductFromInventory, useUpdateProductById } from 'rx-api';

import { useToast } from 'rx-hooks/utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSellerEditProduct = () => {
  const { onSuccessToast, onErrorToast } = useToast();
  const { id } = useParams<{ id?: string }>();

  const { isLoading, data } = useGetProductFromInventory(
    id ? parseInt(id ?? '0', 10) : 0
  ) as any;

  const updateProduct = useUpdateProductById(id || '0');

  const product = data === undefined ? {} : data.data;

  const initialValues = useMemo(() => {
    if (!isEmpty(data)) {
      return {
        name: product?.name ?? '',
        ndc: product.ndc ?? '',
        productId: product.productId ?? '',
        description: product.description ?? '',
        expirationDate: product.expirationDate ?? '',
        lotNumber: product.lotNumber ?? '',
        manufacturer: product.manufacturer ?? '',
        minimumQuantity: product.minimumQuantity ?? '',
        maximumQuantity: product.maximumQuantity ?? null,
        categoryId: product.categoryId ?? '',
        price: product.price ?? '',
        units: product.units ?? '',
        picture: undefined,
      } as IAddProductForm;
    }

    return {
      ndc: '',
      name: '',
      productId: '',
      description: '',
      expirationDate: '',
      lotNumber: '',
      manufacturer: '',
      minimumQuantity: 0,
      price: 0,
      units: 0,
      picture: undefined,
      categoryId: 4,
    } as IAddProductForm;
  }, [
    data,
    product.category,
    product.description,
    product.expirationDate,
    product.lotNumber,
    product.manufacturer,
    product.minimumQuantity,
    product.name,
    product.ndc,
    product.price,
    product.productId,
    product.units,
  ]);

  const onSubmit = async (newValues: any) => {
    try {
      await updateProduct.mutateAsync(newValues);
      onSuccessToast('Product saved!');
    } catch (err) {
      onErrorToast('Could not save the product!');
    }
  };

  return {
    isLoading,
    initialValues,
    onSubmit,
  };
};
