import React from 'react';
import { useHistory } from 'react-router-dom';

import { useGetSaleOrdePayments } from 'rx-api';
import { useReportPagination, useReportQueryString } from 'rx-hooks';
import { useGetMetaData } from 'rx-api/api/admin/sale-order-payments';

import { ReportsTable } from './PaymentsReportTable';
import { ReportFilters } from './PaymentsReportFilters';

export const PaymentReports: React.FC = () => {
  const {
    seller,
    sellerId,
    buyer,
    buyerId,
    sortBy,
    sortOrder,
    status,
    from,
    to,
    edi,
    setLength,
  } = useReportQueryString();
  const history = useHistory();
  const { data, isLoading, isRefetching } = useGetSaleOrdePayments({ edi });
  const { data: metaData } = useGetMetaData({
    sellerId,
    buyerId,
    to,
    from,
    edi,
  });
  const {
    currentPage,
    setCurrentPage,
    onDataChange,
    nPages,
    currentRecords,
    recordsToSkip,
    handleEditRow,
  } = useReportPagination();

  React.useEffect(() => {
    onDataChange(data, buyer, seller, status, from, to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyer, seller, isLoading, isRefetching, status, from, to]);

  React.useEffect(() => {
    if (data && !isLoading) {
      setLength(data.pagination.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data]);

  const handleClickClearFilters = () => {
    history.push('/reports');
  };

  return (
    <>
      <ReportFilters
        queries={{
          seller: seller.toString(),
          buyer: buyer.toString(),
          s: sortBy,
          o: sortOrder,
          status,
          to,
          from,
          edi,
        }}
        onClickClearFilters={handleClickClearFilters}
      />
      <ReportsTable
        handleEditRow={handleEditRow}
        payments={currentRecords}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        meta={metaData?.meta}
        totalPages={nPages}
        totalItems={data?.pagination.total || 0}
        isLoading={isLoading}
        skip={recordsToSkip}
        queries={{
          seller: seller.toString(),
          buyer: buyer.toString(),
          s: sortBy,
          o: sortOrder,
          status,
          to,
          from,
          edi,
        }}
      />
    </>
  );
};
