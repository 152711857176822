import React from 'react';
import { Grid } from '@material-ui/core';
import { GetAdminProduct } from 'rx-domain';

import { AddProductDropzone } from '../AddProductDropzone';

import { Container, ImageText, Subtitle } from './index.styles';

type IProps = {
  product: GetAdminProduct.Response;
  isEdit: boolean;
};

export const AddProductForm = (props: IProps): JSX.Element => {
  return (
    <Container container direction="column">
      <div
        style={{
          width: '85%',
        }}
      >
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <Subtitle>PRODUCT IMAGE</Subtitle>
            <ImageText>
              Upload an image to showcase in your products, this is optional.
            </ImageText>
            <AddProductDropzone
              url={props.product.pictureUrl}
              idProduct={props.product.id}
              isEdit={props.isEdit}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};
