import React from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { Grid } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import axios from 'axios';

import { IGetAccountUpdatePasswordCSFRResponse } from 'rx-domain';

import { RxButtonSimple, RxInputText } from 'rx-core';

import { usePostUpdatePassword } from 'rx-api';

import { useToast } from 'rx-hooks';

import { ChangePasswordSchema } from 'utils/validators/account-settings/change-password.validator';

type IProps = {
  data: IGetAccountUpdatePasswordCSFRResponse | undefined;
};

const Container = styled(Grid)``;

const RowContainer = styled(Grid)`
  &.MuiGrid-spacing-xs-4 > .MuiGrid-item {
    padding-bottom: 0;
  }
`;

const UpdatePasswordButton = styled(RxButtonSimple)`
  width: 12rem;
`;

type IForm = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const SettingsChangePassForm = (props: IProps): JSX.Element => {
  const initialValues: IForm = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const { onSuccessToast, onErrorToast } = useToast();

  const queryClient = useQueryClient();
  const updatePassword = usePostUpdatePassword();

  const onSubmit = async ({ newPassword, oldPassword }: IForm) => {
    if (!props.data?.csfr) return;

    try {
      await updatePassword.mutateAsync({
        csfr: props.data.csfr,
        currentPassword: oldPassword,
        newPassword,
      });

      await queryClient.refetchQueries('get-account-update-password-csfr', {
        active: true,
      });

      onSuccessToast('Changes saved succesfully 🚀');
    } catch (err) {
      if (axios.isAxiosError(err)) {
        onErrorToast(err.response?.data.message);
      }
    }
  };

  return (
    <Container container direction="column">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={ChangePasswordSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <RowContainer container spacing={4}>
              <Grid item xs={12} md={6}>
                <RxInputText
                  name="oldPassword"
                  label="Current Password *"
                  placeholder="Enter Password"
                  type="password"
                  showPasswordHelper
                  showPassword
                  upperLabel={false}
                />
              </Grid>
            </RowContainer>
            <RowContainer container spacing={4}>
              <Grid item xs={12} md={6}>
                <RxInputText
                  name="newPassword"
                  label="New Password *"
                  placeholder="New Password *"
                  type="password"
                  showPasswordHelper
                  showPassword
                  upperLabel={false}
                />
              </Grid>
            </RowContainer>
            <RowContainer container spacing={4}>
              <Grid item xs={12} md={6}>
                <RxInputText
                  name="confirmNewPassword"
                  label="Verify New Password *"
                  placeholder="Confirm New Password"
                  type="password"
                  showPasswordHelper
                  showPassword
                  upperLabel={false}
                />
              </Grid>
            </RowContainer>
            <RowContainer container spacing={4}>
              <Grid
                container
                item
                xs={12}
                md={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <UpdatePasswordButton
                  loading={isSubmitting}
                  disabled={isSubmitting || !isValid}
                  type="submit"
                >
                  Save new password
                </UpdatePasswordButton>
              </Grid>
            </RowContainer>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export { SettingsChangePassForm };
