import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { RRModalProps, RxInputText } from 'rx-core';
import { GET_SALE_ORDER_KEY, usePostSaleOrdersFulfill } from 'rx-api';

import { MOSFulfillOrderSchema } from 'utils/validators/manage-orders-fulfill.validator';
import { useToast } from 'rx-hooks';
import { PurchaseOrderRequestFullfillSuccess } from '../PurchaseOrderRequestFullfillSuccess';

import {
  Modal,
  ButtonsContainer,
  Container,
  FormRow,
  Title,
  CancelButton,
  AcceptButton,
  SmallInput,
} from './index.styles';

type IProps = RRModalProps;

type IForm = {
  shippingCarrier: string;
  trackingNumber: string;
  trackingUrl: string;
};

export const PurchaseOrderRequestFulfillModal = ({
  onClose,
  ...props
}: IProps): JSX.Element => {
  const initialValues: IForm = {
    shippingCarrier: 'fedex',
    trackingNumber: '',
    trackingUrl: '',
  };

  const [success, setSuccess] = useState(false);

  const { id } = useParams<{ id: string }>();
  const postOrderFulfill = usePostSaleOrdersFulfill({
    id,
  });
  const queryClient = useQueryClient();
  const toast = useToast();

  const onSubmit = async (form: IForm) => {
    try {
      await postOrderFulfill.mutateAsync(form);

      await queryClient.refetchQueries(GET_SALE_ORDER_KEY, {
        active: true,
      });

      setSuccess(true);
      toast.onSuccessToast('Order fulfilled!');
    } catch (err) {
      setSuccess(false);
      toast.onErrorToast('Error: Order fulfillment failed!');
    }
  };

  return (
    <Modal {...props} onClose={onClose}>
      <Container>
        {!success ? (
          <>
            <Title>Fulfill order</Title>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={MOSFulfillOrderSchema}
            >
              {({ isSubmitting }) => (
                <Form>
                  <FormRow>
                    <SmallInput
                      name="shippingCarrier"
                      label="Shipping carrier *"
                      placeholder="Shipping Carrier"
                    />
                    <SmallInput
                      name="trackingNumber"
                      label="TRACKING NUMBER *"
                      placeholder="Tracking number"
                    />
                  </FormRow>
                  <FormRow>
                    <RxInputText
                      name="trackingUrl"
                      label="TRACKING URL *"
                      placeholder="Tracking URL"
                    />
                  </FormRow>
                  <ButtonsContainer>
                    <CancelButton
                      onClick={onClose}
                      type="button"
                      disabled={isSubmitting}
                      variant="outline"
                    >
                      Cancel
                    </CancelButton>
                    <AcceptButton type="submit" loading={isSubmitting}>
                      Fulfill Order
                    </AcceptButton>
                  </ButtonsContainer>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <PurchaseOrderRequestFullfillSuccess onClose={onClose} />
        )}
      </Container>
    </Modal>
  );
};
