import styled, { css } from 'styled-components';

import { Grid } from '@material-ui/core';

export const Container = styled(Grid)``;

export const SettingsList = styled(Grid)``;

export const PageContainer = styled(Grid)``;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h1};
    font-family: ${theme.fonts.roobertRegular};
    font-weight: 400;
    color: white;
  `}
`;
