import { URL_V1 } from 'rx-api/api/constants';
import { useGetRequest } from 'rx-api/utils';

export const usePharmacistGetPharmacyList = (approvalStatus = '') => {
  const url = approvalStatus.length
    ? `${URL_V1}/pharmacist/pharmacies?approvalStatus=${approvalStatus}`
    : `${URL_V1}/pharmacist/pharmacies`;

  return useGetRequest<
    unknown,
    {
      result: Array<{
        id: number;
        pharmacyName: string;
        dbaName: string;
        dea: string;
        type: string;
        stateLicense: string;
        approvalStatus: string;
        billingInformation: {
          name: string;
          address: string;
          city: string;
          state: string;
          zipCode: string;
        } | null;
        shippingAddress: {
          address: string;
          city: string;
          state: string;
          zipCode: string;
        } | null;
      }>;
    }
  >({
    url,
    key: ['get-pharmacist-pharmacy-list'],
  });
};
