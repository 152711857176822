import React from 'react';
import { useQueryClient } from 'react-query';

import { useAdminProducts } from 'rx-contexts';
import { useDeleteProduct } from 'rx-api';
import { useToast } from 'rx-hooks';

import { AdminProductsDeleteModal } from './AdminProductsDeleteModal';

interface IAdminProductsDeleteProductProps {
  onDeleteSuccess: () => void;
}

export const AdminProductsDeleteProduct: React.FC<
  IAdminProductsDeleteProductProps
> = (props) => {
  const { onSuccessToast, onErrorToast } = useToast();
  const queryClient = useQueryClient();
  const { modalDelete, onModalDelete, deleteProduct } = useAdminProducts();

  const adminDeleteProducts = useDeleteProduct();

  const onDeleteProduct = async () => {
    try {
      if (!deleteProduct) return;

      await adminDeleteProducts.mutateAsync({
        id: deleteProduct.id.toString(),
      });

      await queryClient.refetchQueries('get-admin-products', {
        active: true,
      });

      onModalDelete();
      onSuccessToast('Product deleted');
      props.onDeleteSuccess();
    } catch (error) {
      onErrorToast('Could not delete the product ❌');
    }
  };

  return (
    <AdminProductsDeleteModal
      visible={modalDelete}
      onClose={() => onModalDelete()}
      loading={adminDeleteProducts.isLoading}
      onDeleteProduct={onDeleteProduct}
    />
  );
};
