import React from 'react';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid, Paper } from '@material-ui/core';

const Card = styled(Paper)`
  margin-bottom: 1rem;
  padding: 0 1rem;
`;

export const OrderDetailsLoader: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Card variant="outlined">
          <Skeleton height={50} />
        </Card>
        <Card variant="outlined">
          <Skeleton height={80} />
        </Card>
        <Card variant="outlined">
          <Skeleton height={100} />
        </Card>
        <Card variant="outlined">
          <Skeleton height={100} />
        </Card>
        <Card variant="outlined">
          <Skeleton height={50} />
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Card variant="outlined">
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </Card>
      </Grid>
    </Grid>
  );
};
