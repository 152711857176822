import React from 'react';
import styled, { css } from 'styled-components';

export type IProps = {
  query: string;
};

const Container = styled.div``;

const ResultTextContainer = styled.div``;

const ResultText = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoRegular};
    font-weight: 400;
    margin-bottom: 3.75rem;
  `}
`;

const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoRegular};
    color: ${theme.colors.blue[100]};
    font-weight: 400;
    margin-bottom: 1rem;
  `}
`;

const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoRegular};
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmptySearch = (props: IProps): JSX.Element => {
  return (
    <Container>
      <ResultTextContainer>
        <ResultText>Search results for: “{props.query}”</ResultText>
      </ResultTextContainer>
      <Content>
        <Title>Search results for: “{props.query}”</Title>
        <Subtitle>
          We were not able to find any results for “{props.query}”. Try another
          search.
        </Subtitle>
      </Content>
    </Container>
  );
};

export { EmptySearch };
