import styled, { css } from 'styled-components';

import { RRModal, RxButtonSimple } from 'rx-core';

export const Modal = styled(RRModal)`
  width: 32rem;
  height: fit-content;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-weight: 400;
    padding-bottom: 1rem;
    border-bottom: 1px rgba(43, 54, 71, 0.3) solid;
  `}
`;

export const Content = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.small};
    font-weight: 400;
    padding: 1rem 0;
    border-bottom: 1px rgba(43, 54, 71, 0.3) solid;
  `}
`;

export const ActionContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding-top: 1rem;
`;

export const ActionButton = styled(RxButtonSimple)`
  width: 10rem;
  height: 2.5rem;
`;
