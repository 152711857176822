import { Avatar, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Container } from './index.styles';

type IProps = {
  username: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    small: {
      marginRight: '0.45rem',
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
    },
  })
);

export const HeaderSettingsUsername: React.FC<IProps> = ({ username }) => {
  const classes = useStyles();

  return (
    <Container>
      <Avatar className={classes.small}>{username.charAt(0)}</Avatar>
      <Typography>Hi, {username}</Typography>
    </Container>
  );
};
