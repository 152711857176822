import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import {
  MuiPickers,
  Redux,
  ReactQuery,
  Theme,
  GlobalStyle,
  ReactGA,
  Toaster,
  useHotjar,
  StylesProvider,
} from 'plugins';
import { FeatureFlagsProvider } from 'rx-contexts';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from 'styles/theme';

import { RouteProvider } from './router';
import { ShoppingCartProvider } from './rx-contexts/ShoppingCartContext';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKID ?? '');

const App: React.FC = () => {
  const { initHotjar } = useHotjar();

  useEffect(() => {
    initHotjar(parseInt(process.env.REACT_APP_HOTJAR_ID ?? '0', 10), 6);
  }, [initHotjar]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Theme>
        <Toaster />
        <ReactQuery>
          <Redux>
            <ShoppingCartProvider>
              <StylesProvider injectFirst>
                <MuiPickers>
                  <FeatureFlagsProvider>
                    <RouteProvider />
                  </FeatureFlagsProvider>
                </MuiPickers>
              </StylesProvider>
            </ShoppingCartProvider>
          </Redux>
        </ReactQuery>
      </Theme>
    </ThemeProvider>
  );
};

export default hot(module)(App);
