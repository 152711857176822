import * as React from 'react';
import { Container } from '@material-ui/core';
import RxRiseLogo from 'assets/logos/header-logo.svg';
import BlueHeartIcon from 'assets/logos/blue-heart.svg';
import styled from 'styled-components';

const blackTextColor = '#2b3647';
const grayTextColor = '#838ca5';
const primaryBlue = '#5077f2';

const Text = styled.p`
  color: ${grayTextColor};
`;

const Title = styled.h1`
  color: ${blackTextColor};
  font-size: 2rem;
  margin: 0;
`;

const SubTitle = styled.h2`
  color: ${primaryBlue};
  font-size: 2rem;
  margin: 0 0 2rem 0;
`;

const Nav = styled.nav`
  box-sixing: border-box;
  padding: 1rem 0;
`;

const Content = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 4rem auto;
  width: 70%;
`;

const Figure = styled.img`
  width: 40%;
`;

const Caption = styled.div`
  padding: 0 2rem;
`;

const Link = styled.a`
  color: ${primaryBlue};
  display: block;
  margin-top: 1rem;
`;

export const NotFoundView = (): JSX.Element => {
  return (
    <Container>
      <Nav>
        <a href="/">
          <img src={RxRiseLogo} alt="Return to home page" />
        </a>
      </Nav>

      <Content>
        <Figure src={BlueHeartIcon} alt="RxRise Blue Heart Icon" />
        <Caption>
          <Title>404 Error</Title>
          <SubTitle>Page not Found</SubTitle>
          <Text>
            The page you wanted to search probably is broken or has been
            removed.
          </Text>
          <Text>
            <Link href="/">Return to main page</Link>
          </Text>
        </Caption>
      </Content>
    </Container>
  );
};
