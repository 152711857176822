import React, { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { theme, Colors } from 'rx-styles';

export type ButtonBaseProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: Colors;
  secondary?: boolean;
};

const Button = styled.button<{ color?: Colors; secondary?: boolean }>`
  width: 100%;
  height: 52px;
  border-radius: 30.5px;
  border: none;
  cursor: pointer;
  background: ${({ color, secondary }) => (secondary ? 'white' : color)};
  ${({ secondary }) =>
    secondary &&
    css`
      border: 2px solid #5077f2;
    `}
  transition: 1s;
`;

const ButtonBase = (props: ButtonBaseProps): JSX.Element => {
  return <Button {...props} />;
};

ButtonBase.defaultProps = {
  color: theme.colors.blue.gradient,
  secondary: false,
};

export { ButtonBase };
