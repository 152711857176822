import React from 'react';
import {
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { useToast } from 'rx-hooks';
import { useFormik } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { GRAY_LIGHT_COLOR } from 'rx-styles/colors';
import { useRxMutation } from 'rx-api/utils';

import { PreviewPicture } from '../../Ads/components/PreviewPicture';
import { ModalFooter } from '../../Ads/components/ModalFooter';

export const ModalContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
});

export const ModalHeader = styled.header({
  borderBottom: `1px solid ${GRAY_LIGHT_COLOR}`,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0.5rem 0',
});

export const ModalCloseButton = styled.button`
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;

export const ModalContent = styled.div({
  padding: '1.5rem 0',
});

interface ICategoryModalProps {
  isOpen: boolean;
  onClickCancel(): void;
  onClickConfirm(): void;
}

const useAdminPostCategory = () => {
  return useRxMutation<any, unknown>({
    method: 'post',
    url: `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/products/categories`,
  });
};

export const CategoryAddModal: React.FC<ICategoryModalProps> = (props) => {
  const [pictureUrl, setPictureUrl] = React.useState<
    null | string | ArrayBuffer
  >(null);
  const toast = useToast();
  const addCategory = useAdminPostCategory();
  const formik = useFormik({
    initialValues: {
      text: '',
      slug: '',
      file: '',
      picture: new Blob(),
    },
    onSubmit: async (values) => {
      try {
        const data = new FormData();

        data.append('text', values.text);
        data.append('slug', values.slug);
        data.append('picture', values.picture);

        await addCategory.mutateAsync(data);

        toast.onSuccessToast('Category created!');
        formik.resetForm({});
        setPictureUrl(null);
        props.onClickConfirm();
      } catch (e) {
        formik.resetForm({});
        toast.onErrorToast('We could not create the category');
      }
    },
  });

  const handleClose = () => {
    formik.resetForm({});
    setPictureUrl(null);
    props.onClickCancel();
  };
  return (
    <Modal open={props.isOpen}>
      <ModalContainer>
        <Paper variant="outlined" style={{ padding: '1rem', width: '40rem' }}>
          <ModalHeader>
            <Typography variant="h6">Add new category</Typography>
            <ModalCloseButton onClick={props.onClickCancel}>
              <CloseIcon />
            </ModalCloseButton>
          </ModalHeader>
          <form onSubmit={formik.handleSubmit}>
            <ModalContent>
              <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                <Grid item xs={12}>
                  <Typography>
                    This category will be displayed on the marketplace.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        name="text"
                        label="Category ID"
                        placeholder="my_category"
                        variant="outlined"
                        size="small"
                        value={formik.values.text}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                    <Grid item xs={12}>
                      <TextField
                        name="slug"
                        label="Category Name"
                        placeholder="My Category"
                        variant="outlined"
                        size="small"
                        value={formik.values.slug}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                    <Grid item xs={12}>
                      <TextField
                        name="file"
                        type="file"
                        variant="outlined"
                        size="small"
                        value={formik.values.file}
                        onChange={(e: any) => {
                          const reader = new FileReader();

                          reader.onloadend = () => {
                            setPictureUrl(reader?.result);
                          };

                          reader.readAsDataURL(e?.currentTarget?.files[0]);

                          formik.setFieldValue('file', e?.target?.value);
                          formik.setFieldValue(
                            'picture',
                            e?.currentTarget?.files[0]
                          );
                        }}
                        fullWidth
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {pictureUrl !== null && (
                  <Grid item xs={6}>
                    <Typography title="h6">
                      <strong>Preview</strong>
                    </Typography>

                    <PreviewPicture src={pictureUrl.toString()} />
                  </Grid>
                )}
              </Grid>
            </ModalContent>
            <ModalFooter>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                disabled={addCategory.isLoading}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={addCategory.isLoading}
              >
                Add
              </Button>
            </ModalFooter>
          </form>
        </Paper>
      </ModalContainer>
    </Modal>
  );
};
