import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';

import { RxText, Input, RxButtonPrimary, RxButtonSecondary } from 'rx-core';
import { DataState } from 'rx-domain';
import { useForgorPassword } from 'rx-api';
import { ForgotPasswordSchema } from 'utils/validators/forgot-password.validator';
import { useHistory } from 'react-router-dom';

interface IForgotPasswordForm {
  email: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 134px 14px 38px 20px;
  height: 100%;
  width: 100%;
`;

const Title = styled(RxText)`
  margin-bottom: 16px;
`;

const Subtitle = styled(RxText)`
  margin-bottom: 24px;
`;

const TextResend = styled(RxText)``;

const StrongText = styled.span`
  color: ${({ theme }) => theme.colors.blue[300]};
  cursor: pointer;
`;

const MessageText = styled(RxText)`
  margin-top: 18px;
  margin-bottom: 30px;
`;

const Spacer = styled.div`
  height: 66px;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

const ForgotPasswordForm = (): JSX.Element => {
  const initialValues: IForgotPasswordForm = {
    email: '',
  };

  const [stateForm, setStateForm] = useState<DataState>('none');
  const [responseStatus, setResponseStatus] = useState<DataState>('none');

  const mutations = useForgorPassword();
  const history = useHistory();

  const onSubmit = async ({ email }: IForgotPasswordForm) => {
    try {
      await mutations.mutateAsync(email);
      setStateForm('success');
      setResponseStatus('success');
    } catch (err) {
      setStateForm('none');
      setResponseStatus('error');
    }
  };

  return (
    <Container>
      {stateForm === 'none' && (
        <>
          <Title font="Roboto Light" size="h2" color="#2b3647">
            Password reset assistance 🗝
          </Title>
          <Subtitle font="Roboto Light" color="#838ca5" size="medium">
            Please enter the email address associated with your RxRise account
            to reset your password.
          </Subtitle>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={ForgotPasswordSchema}
          >
            <Form>
              <Field
                name="email"
                placeholder="you@email.com"
                label="Company Email Address"
                type="email"
                width="386px"
                state={stateForm}
                component={Input}
              />
              <ButtonContainer>
                <RxButtonSecondary
                  onClick={() => history.push('/login')}
                  type="button"
                  text="Cancel"
                />
                <span style={{ marginLeft: '1rem' }}>
                  <RxButtonPrimary text="Continue" type="submit" />
                </span>
              </ButtonContainer>
            </Form>
          </Formik>
          {responseStatus === 'error' && (
            <MessageText color="#FF6969" size="small" font="Roboto Light">
              We&apos;re sorry. We weren&apos;t able to identify your email. Try
              again.
            </MessageText>
          )}
          {responseStatus === 'none' && <Spacer />}
          <TextResend>
            Didn’t receive email? <StrongText>Resend email.</StrongText>
          </TextResend>
        </>
      )}

      {stateForm === 'success' && (
        <>
          <Spacer />
          <Title font="Roboto Light" size="h2" color="#2b3647">
            Please check your email 📧
          </Title>
          <Subtitle font="Roboto Light" color="#838ca5" size="medium">
            You should have received an email in your inbox. Please follow those
            instructions to reset your password!
          </Subtitle>
        </>
      )}
    </Container>
  );
};

export { ForgotPasswordForm };
