import { URL_V1 } from 'rx-api/api/constants';
import { useGetRequest, makeArrayReactQueryKey } from 'rx-api/utils';

const GET_PRODUCT_FROM_INVENTORY_URL = (id: number | string) =>
  `${URL_V1}/inventory/products/${id}`;

const GET_PRODUCT_FROM_INVENTORY_KEY = 'get-product-from-inventory';

export const useGetProductFromInventory = (productId: number | string) => {
  return useGetRequest<number | string, any>({
    url: GET_PRODUCT_FROM_INVENTORY_URL(productId),
    key: makeArrayReactQueryKey(GET_PRODUCT_FROM_INVENTORY_KEY, {
      productId: `${productId}`,
    }),
  });
};
