import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { useQueryClient } from 'react-query';

import { GetFavorites } from 'rx-domain';

import { useDeleteFavorites, GET_FAVORITES_KEY } from 'rx-api';

import { useToast } from 'rx-hooks';

import { formatMoney, getDateValue } from 'rx-utils';

import {
  TableColumn,
  TableRow,
  LastTableColumn,
  LikedIcon,
} from './index.styles';

type IProps = {
  item: GetFavorites.FavoriteItem;
};

const FavoriteItem = (props: IProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { onSuccessToast } = useToast();

  const deleteFavorite = useDeleteFavorites();

  const removeFavorite = async () => {
    try {
      await deleteFavorite.mutateAsync({ id: props.item.productId });

      await queryClient.refetchQueries(GET_FAVORITES_KEY, { active: true });
      onSuccessToast('removed from favorite list 🚀');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <TableRow>
      <TableColumn>{props.item.product.productId}</TableColumn>
      <TableColumn>{props.item.product.ndc}</TableColumn>
      <TableColumn>{props.item.product.name}</TableColumn>
      <TableColumn>{props.item.product.description}</TableColumn>
      <TableColumn>{props.item.product.manufacturer}</TableColumn>
      <TableColumn>{props.item.product.shippingName}</TableColumn>
      <TableColumn>{formatMoney(props.item.product.price)}</TableColumn>
      <TableColumn>
        {getDateValue(props.item.product.expirationDate)}
      </TableColumn>
      <TableColumn>{props.item.product.lotNumber}</TableColumn>
      <TableColumn>{props.item.product.units}</TableColumn>
      <TableColumn>{props.item.product.minimumQuantity}</TableColumn>
      <TableColumn>
        <Tooltip
          title={props.item.product.shippingConstraints}
          arrow
          placement="bottom-start"
        >
          <span>{props.item.product.shippingConstraints}</span>
        </Tooltip>
      </TableColumn>
      <LastTableColumn>
        <IconButton aria-label="favorite" onClick={() => removeFavorite()}>
          <LikedIcon />
        </IconButton>
      </LastTableColumn>
    </TableRow>
  );
};

export { FavoriteItem };
