import { GetPurchaseOrders } from 'rx-domain';
import { useGetRequest } from 'rx-api/utils';
import { ADMIN_URL_V1 } from '../constants';

const makeParams = (req: Partial<GetPurchaseOrders.Query>) => {
  let query = `?limit=10`;

  if (req.limit) {
    query = `?limit=${req.limit}`;
  }

  if (req.skip) {
    query += `&skip=${req.skip}`;
  }

  if (req.order) {
    query += `&order=${req.order}`;
  }

  if (req.orderBy) {
    query += `&orderBy=${req.orderBy}`;
  }

  return query;
};

const useAdminGetPurchaseOrders = (query: Partial<GetPurchaseOrders.Query>) => {
  return useGetRequest<Partial<any>, any>({
    url: `${ADMIN_URL_V1}/purchase-orders`,
    key: ['ADMIN_GET_PURCHASE_ORDERS', query.skip, query.limit, query.order],
    makeParams,
    params: query,
  });
};

export { useAdminGetPurchaseOrders };
