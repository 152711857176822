import React from 'react';
import { useFormik } from 'formik';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';

import {
  useGetManufacturerBillingInformation,
  usePutManufacturerBillingInformation,
} from 'rx-api';
import { useToast } from 'rx-hooks';
import { STATE_CODES_US } from 'rx-utils';

import { ManufacturerBusinessInfoSchema } from 'utils/validators/account-settings/manufacturer/business-info.validator';

import { Title } from './index.styles';

type IForm = {
  address: string;
  city: string;
  state: string;
  zipCode: string;
  name: string;
};

export const SettingsBillingInfoForm: React.FC = () => {
  const { onErrorToast, onSuccessToast } = useToast();

  const { data: businessDataRes } = useGetManufacturerBillingInformation();
  const updateBusinessInfo = usePutManufacturerBillingInformation();

  const formik = useFormik({
    initialValues: {
      address: businessDataRes?.data?.address || '',
      city: businessDataRes?.data?.city || '',
      state: businessDataRes?.data?.state || '',
      zipCode: businessDataRes?.data?.zipCode || '',
      name: businessDataRes?.data?.name || '',
    },
    validationSchema: ManufacturerBusinessInfoSchema,
    enableReinitialize: true,
    onSubmit: async (values: IForm) => {
      try {
        await updateBusinessInfo.mutateAsync(values);

        onSuccessToast('Changes saved succesfully 🚀');
      } catch (err) {
        onErrorToast('Error: could not update billing information');
      }
    },
  });

  return (
    <Paper variant="outlined" style={{ padding: '1rem' }}>
      <Title>Billing Information</Title>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              name="name"
              label=" Billing Name*"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={!!formik.errors.name}
              helperText={formik.errors.name}
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              name="address"
              label="Address *"
              placeholder="Address"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={!!formik.errors.address}
              helperText={formik.errors.address}
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs>
            <TextField
              name="city"
              label="City *"
              placeholder="City"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={!!formik.errors.city}
              helperText={formik.errors.city}
              variant="outlined"
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel
                style={{ paddingLeft: '1.25rem', marginTop: '-0.25rem' }}
                shrink
              >
                State
              </InputLabel>
              <Select
                name="state"
                placeholder="TX"
                label="State*"
                variant="outlined"
                error={!!formik.errors.state}
                value={formik.values.state}
                onChange={formik.handleChange}
                required
                fullWidth
              >
                {STATE_CODES_US.map((code) => (
                  <MenuItem value={code}>{code}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="zipCode"
              label="Zip Code *"
              placeholder="12345"
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              error={!!formik.errors.zipCode}
              helperText={formik.errors.zipCode}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
