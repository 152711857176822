import * as Yup from 'yup';

const ManufacturerShippingSchema = Yup.object().shape({
  shippingConstraints: Yup.string(),
  groundShipping: Yup.number()
    .typeError('You must specify a number')
    .moreThan(0, 'Must be more than 0')
    .required('Please add a ground shipping'),
  overnightShipping: Yup.number()
    .typeError('You must specify a number')
    .moreThan(0, 'Must be more than 0')
    .required('Please add overnight shipping'),
  minimumOrderAmount: Yup.number()
    .required('Please add a minimum order amount')
    .moreThan(0, 'Must be more than 0')
    .typeError('You must specify a number'),
});

export { ManufacturerShippingSchema };
