import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Grid } from '@material-ui/core';

import { RxButtonSimple } from 'rx-core';

import { useGetTwoFactorConfig } from 'rx-api';

import { TwoFactorModal } from '../../TwoFactorModal';

const Container = styled(Grid)``;

const Title = styled.h1`
  ${({ theme }) => css`
    color: black;
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoLight};
    margin-bottom: 1rem;
  `}
`;

const Content = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoLight};
    font-weight: 400;
    margin-bottom: 1rem;
  `}
`;

const SubmitButton = styled(RxButtonSimple)`
  width: 18rem;
`;

const Settings2FA = (): JSX.Element => {
  const [showModal, setShowModal] = useState(false);

  const { data: has2FActiveRes } = useGetTwoFactorConfig();

  const has2FActive = !!(has2FActiveRes?.data && has2FActiveRes.data.enabled);

  return (
    <Container container direction="column">
      <Grid item xs={12}>
        <Title>Two-Factor Authentification </Title>
        <Content>
          In order to login in to your RxRise account, you'll need your password
          and a verification code. This option will help you to have a extra
          layer of security.
        </Content>
        <SubmitButton
          onClick={() => setShowModal(true)}
          variant={has2FActive ? 'outline' : 'normal'}
          data-cy="security-toggle-2fa"
        >
          {has2FActive
            ? 'Disabled 2FA authentification'
            : 'Activate 2FA authentification'}
        </SubmitButton>
        <TwoFactorModal
          type={has2FActive ? 'OFF' : 'ON'}
          visible={showModal}
          onClose={() => setShowModal(false)}
        />
      </Grid>
    </Container>
  );
};

export { Settings2FA };
