import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

type IRequest = {
  amounts: Array<number>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useVerifyPharmacyPaymentMethodAch = (
  pharmacyId: string | number,
  achId: string | number
) => {
  return useMutation(
    ['verify-pharmacy-payment-method-adch', pharmacyId, achId],
    ({ amounts }: IRequest) => {
      return http.post(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/pharmacist/pharmacies/${pharmacyId}/payment-methods/ach/${achId}/verify`,
        amounts
      );
    },
    { retry: false }
  );
};
