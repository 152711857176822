import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

type Request = {
  url: string;
  method: 'put' | 'post' | 'get';
};

const useRxFormDataMutation = <T extends { [key: string]: any }, R>({
  url,
  method,
}: Request) => {
  const onRequest = (request: T) => {
    const form = new FormData();

    // eslint-disable-next-line no-restricted-syntax
    for (const key in request) {
      if (Object.prototype.hasOwnProperty.call(request, key)) {
        const value = request[key];

        if (value) {
          if (typeof value === 'number') {
            form.append(key, value.toString());
          } else {
            form.append(key, value);
          }
        } else if (typeof value === 'boolean') {
          form.append(key, value);
        }
      }
    }

    return http[method]<R>(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  return useMutation(onRequest);
};

export { useRxFormDataMutation };
