import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import {
  useAdminDownloadConsolidatedOrderInvoice,
  GET_META_DATA_KEY,
} from 'rx-api';
import { RxMenu } from 'rx-core';
import { useDownloadFile, useToast } from 'rx-hooks';
import { useSetPaymentStatus } from 'rx-api/api/admin/sale-order-payments';

type IProps = {
  setPaymentStatus: any;
  saleOrderId: string;
  orderId: number;
  status: string;
};

export const ReportOptions = (props: IProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const saleOrderId = useMemo(() => {
    const arr = props.saleOrderId.split('-');
    return arr[arr.length - 1];
  }, [props.saleOrderId]);

  const queryClient = useQueryClient();
  const toast = useToast();

  const setPaymentStatus = useSetPaymentStatus();
  const { onDownload } = useDownloadFile();
  const downloadInvoice = useAdminDownloadConsolidatedOrderInvoice(
    props.orderId
  );

  const markInvoiceAsPaid = async () => {
    try {
      await setPaymentStatus.mutateAsync({
        saleOrderId,
        status: 'paid',
      });
      props.setPaymentStatus('paid');
      await queryClient.refetchQueries(GET_META_DATA_KEY, {
        active: true,
      });
      toast.onSuccessToast('Order paid 🚀');
    } catch (error) {
      toast.onErrorToast('Error: Could not mark order as paid');
    }
  };

  const markInvoiceAsUnpaid = async () => {
    try {
      await setPaymentStatus.mutateAsync({
        saleOrderId,
        status: 'pending',
      });
      props.setPaymentStatus('pending');
      await queryClient.refetchQueries(GET_META_DATA_KEY, {
        active: true,
      });
      toast.onSuccessToast('Order marked as unpaid 🚀');
    } catch (error) {
      toast.onErrorToast('Error: Could not mark order as unpaid');
    }
  };

  const downloadBuyersInvoice = async () => {
    try {
      const { data } = await downloadInvoice.mutateAsync();
      onDownload(data, `INV-${saleOrderId}`, 'pdf', {
        type: 'application/pdf',
      });
    } catch (error) {
      toast.onErrorToast('Error: could not download invoice');
    }
  };

  const options = [
    {
      type: 'INVOICE_PAID',
      text: 'Mark invoice as Paid',
      onClick: markInvoiceAsPaid,
      disabled: props.status === 'paid',
    },
    {
      type: 'INVOICE_UNPAID',
      text: 'Mark invoice as Unpaid',
      onClick: markInvoiceAsUnpaid,
      disabled: props.status !== 'paid',
    },
    {
      type: 'DOWNLOAD_BUYER_INVOICE',
      text: "Download Buyer's invoice",
      onClick: downloadBuyersInvoice,
    },
  ];

  const onOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <RxMenu
      anchorEl={anchorEl}
      onClose={onClose}
      onOpenMenu={onOpenMenu}
      options={options}
    />
  );
};
