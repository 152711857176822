import styled, { css } from 'styled-components';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const ArrowDownwardIcon = styled(ArrowDownward)`
  margin-right: 0.5rem;
`;

export const Title = styled.h2``;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const RowTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
