export { useGetRequest } from './useGetRequest';
export { useRxMutation } from './useRxMutation';
export { useRxFormDataMutation } from './useRxFormDataMutation';

type ParamType = { key: string; value: string };
type QueryType = { [key: string]: string | number };

function isParamObject(
  param: Array<ParamType> | ParamType
): param is ParamType {
  return (param as ParamType).key !== undefined;
}

export const createUrlWithParams = (
  url: string,
  params: Array<ParamType> | ParamType
): string => {
  if (isParamObject(params)) {
    return url.replace(`{${params.key}}`, params.value);
  }

  if (params.length === 0) {
    return url;
  }

  return params.reduce(
    (newUrl, param) => newUrl.replace(`{${param.key}}`, param.value),
    url
  );
};

export const convertUrlWithQuery = <Q extends QueryType>(
  queries: Q,
  url?: string
): string => {
  const params = Object.entries(queries)
    .map(([key, value]) => (value ? `${key}=${value}` : ''))
    .filter((parameter) => parameter);

  return url ? `${url}?${params.join('&')}` : `?${params.join('&')}`;
};

export const makeArrayReactQueryKey = <Q extends QueryType>(
  keyReactQuery: string,
  queriesAndParams: Q
): Array<string | number> => {
  return [keyReactQuery, ...Object.values(queriesAndParams)];
};
