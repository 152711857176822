import { useQuery } from 'react-query';

import { IGetPharmacistsPaymentTermResponse } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGetPharmacistsPaymentTerm = () => {
  const onRequest = async () => {
    return http.get<IGetPharmacistsPaymentTermResponse>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/pharmacists/payment-term`,
      {
        withCredentials: true,
      }
    );
  };

  return useQuery(['get-pharma-payment-term'], () => onRequest());
};

export { useGetPharmacistsPaymentTerm };
