import { GetFeaturedProducts } from 'rx-domain';
import { useGetRequest } from 'rx-api/utils';
import {
  GET_FEATURED_PRODUCTS_URL,
  GET_FEATURED_PRODUCTS_KEY,
} from './constants';

const makeParams = (req: any) => {
  let query = `?limit=10`;

  if (req.limit) {
    query = `?limit=${req.limit}`;
  }
  return query;
};

const useGetFeaturedProducts = (query: any) => {
  return useGetRequest<
    Partial<GetFeaturedProducts.Query>,
    GetFeaturedProducts.Response
  >({
    url: GET_FEATURED_PRODUCTS_URL,
    key: [GET_FEATURED_PRODUCTS_KEY],
    params: query,
    makeParams,
  });
};

export { useGetFeaturedProducts };
