import { withStyles } from '@material-ui/styles';
import { DialogActions } from '@material-ui/core';

export const RemovePaymentMethodDialogActions = withStyles(() => ({
  root: {
    margin: 0,
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))(DialogActions);
