import React from 'react';
import { Typography } from '@material-ui/core';

import { IGetBillingInformationDTO } from 'rx-domain';

import { CheckoutCard } from '../CheckoutCard/CheckoutCard';
import { CheckoutCardEmpty } from '../CheckoutCardEmpty/CheckoutCardEmpty';

type IProps = {
  billInfoData:
    | {
        id: number;
        pharmacyName: string;
        dbaName: string;
        billingInformation: {
          name: string;
          address: string;
          city: string;
          state: string;
          zipCode: string;
        } | null;
        shippingAddress: {
          address: string;
          city: string;
          state: string;
          zipCode: string;
        } | null;
      }
    | undefined;
  onAddBillInfo: () => void;
};

const CheckoutBillInfo: React.FC<IProps> = ({
  billInfoData,
  onAddBillInfo,
}) => {
  return (
    <CheckoutCard isFullHeight={true}>
      {billInfoData && billInfoData?.billingInformation ? (
        <>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Verify Billing Address
          </Typography>
          <Typography>{billInfoData.pharmacyName}</Typography>
          <Typography>{`${billInfoData?.billingInformation?.address} ${billInfoData?.billingInformation?.city}, ${billInfoData?.billingInformation?.state} ${billInfoData?.billingInformation?.zipCode} United States`}</Typography>
        </>
      ) : (
        <CheckoutCardEmpty
          data-testid="checkout-billing-empty-info"
          subtitle="You have no billing information available."
          action={{
            text: 'Add Billing Information',
            action: onAddBillInfo,
          }}
        />
      )}
    </CheckoutCard>
  );
};

export { CheckoutBillInfo };
