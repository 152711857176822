import React from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BarChartIcon from '@material-ui/icons/BarChart';
import BusinessIcon from '@material-ui/icons/Business';
import CategoryIcon from '@material-ui/icons/Category';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import GradeIcon from '@material-ui/icons/Grade';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import PersonIcon from '@material-ui/icons/Person';
import SubjectIcon from '@material-ui/icons/Subject';

import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { HeaderLink } from '../HeaderLink';
import { HeaderSettings } from '../HeaderSettings';

import { Container, Spacer } from './index.styles';

export const HeaderAdmin: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <Spacer />

      <Button onClick={toggleDrawer}>Menu</Button>
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer}>
        <List>
          <Link
            to="/transactions"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <ListItem button>
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>

              <ListItemText>Transactions</ListItemText>
            </ListItem>
          </Link>

          <Link
            to="/accounts"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <ListItem button>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>

              <ListItemText>Accounts</ListItemText>
            </ListItem>
          </Link>

          <Link
            to="/pharmacies"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <ListItem button>
              <ListItemIcon>
                <LocalPharmacyIcon />
              </ListItemIcon>

              <ListItemText>Pharmacies</ListItemText>
            </ListItem>
          </Link>

          <Link
            to="/manufacturers"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <ListItem button>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>

              <ListItemText>Manufacturers</ListItemText>
            </ListItem>
          </Link>

          <Link
            to="/purchase-orders"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <ListItem button>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>

              <ListItemText>Orders</ListItemText>
            </ListItem>
          </Link>

          <Link
            to="/products"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <ListItem button>
              <ListItemIcon>
                <GradeIcon />
              </ListItemIcon>

              <ListItemText>Products</ListItemText>
            </ListItem>
          </Link>

          <Link
            to="/reports"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <ListItem button>
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>

              <ListItemText>Reports</ListItemText>
            </ListItem>
          </Link>

          <Link to="/ads" style={{ textDecoration: 'none', color: 'black' }}>
            <ListItem button>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>

              <ListItemText>Ads</ListItemText>
            </ListItem>
          </Link>

          <Link
            to="/categories"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <ListItem button>
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>

              <ListItemText>Categories</ListItemText>
            </ListItem>
          </Link>

          <Link
            to="/system-logs"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <ListItem button>
              <ListItemIcon>
                <SubjectIcon />
              </ListItemIcon>

              <ListItemText>System Logs</ListItemText>
            </ListItem>
          </Link>
        </List>
      </Drawer>
      <HeaderSettings />
    </Container>
  );
};
