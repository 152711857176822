import React from 'react';
import styled from 'styled-components';
import { TableBody } from '@material-ui/core';

import { GetAdminProducts } from 'rx-domain';

import { AdminProductsDataItem } from './AdminProductsDataItem';

type IProps = {
  items: GetAdminProducts.ProductItem[];
};

const Spacer = styled.tr`
  height: 0.5rem;
`;

export const AdminProductsDataItems: React.FC<IProps> = (props) => {
  return (
    <TableBody>
      {props.items.map((item, index) => (
        <React.Fragment key={index}>
          <Spacer />
          <AdminProductsDataItem {...item} index={index} key={index} />
        </React.Fragment>
      ))}
    </TableBody>
  );
};
