import { IGetTransactionsResponse } from 'rx-domain';

import { useGetRequest } from 'rx-api/utils';

import { GET_TRANSACTIONS_URL } from './constants';

type IRequest = {
  compoundOrderId: string;
  seller?: string;
  buyer?: string;
  skip: number;
  from?: string;
  to?: string;
};

const makeParams = (req: IRequest) => {
  let params = `?skip=${req.skip}&limit=10`;

  if (req.compoundOrderId) {
    params = `${params}&compoundOrderId=${req.compoundOrderId}`;
  }

  if (req.buyer) {
    params = `${params}&buyer=${req.buyer}`;
  }

  if (req.seller) {
    params = `${params}&seller=${req.seller}`;
  }

  if (req.from) {
    params = `${params}&from=${req.from}`;
  }

  if (req.to) {
    params = `${params}&to=${req.to}`;
  }

  return params;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGetTransactions = (request: IRequest) => {
  return useGetRequest<IRequest, IGetTransactionsResponse>({
    url: GET_TRANSACTIONS_URL,
    key: [
      'get-transactions',
      request.compoundOrderId,
      request.buyer,
      request.seller,
      request.skip,
      request.from,
      request.to,
    ],
    params: request,
    makeParams,
  });
};

export { useGetTransactions };
