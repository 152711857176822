import { useGetRequest } from 'rx-api/utils';

type IRequest = {
  id: number;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGetAdminManufacturerIdApiKey = (request: IRequest) => {
  return useGetRequest<IRequest, { apiKey: string }>({
    url: `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/manufacturers/${request.id}/api-key`,
    key: ['get-admin-manufacturers-id-api-key', request.id],
    params: request,
  });
};

export { useGetAdminManufacturerIdApiKey };
