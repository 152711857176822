import React from 'react';
import styled from 'styled-components';

import PillTwo from '../../../../assets/images/random/pill-two.png';

const Img = styled.img`
  width: 130px;
  height: 100px;

  position: absolute;
  bottom: 0;
  left: calc(35vw - 65px);
  display: none;
`;

const FooterPillIconTwo = (): JSX.Element => {
  return <Img src={PillTwo} />;
};

export { FooterPillIconTwo };
