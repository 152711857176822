import React from 'react';
import { Grid, TextField, Button, MenuItem, Select } from '@material-ui/core';

interface IAddPricingTierForm {
  values: {
    minAmount: number | null;
    maxAmount: number | null;
    price: string;
    logicalOperator: string;
  };
  errors: { minAmount?: string; maxAmount?: string; price?: string };
  isDisabled: boolean;
  onSubmit: (e: any) => void;
  onChange: (e: any) => void;
}

export const AddPricingTierForm: React.FC<IAddPricingTierForm> = (props) => {
  const columnSize = props.values.logicalOperator === 'between' ? 3 : 4;

  return (
    <form onSubmit={props.onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={columnSize}>
          <Select
            id="logicalOperator"
            name="logicalOperator"
            value={props.values.logicalOperator}
            onChange={props.onChange}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="less_than">Less Than</MenuItem>
            <MenuItem value="between">Between</MenuItem>
            <MenuItem value="more_than">More Than</MenuItem>
          </Select>
        </Grid>

        {props.values.logicalOperator !== 'less_than' && (
          <Grid item xs={columnSize}>
            <TextField
              placeholder="1"
              label={
                props.values.logicalOperator === 'between'
                  ? 'Minimum Amount'
                  : 'Amount'
              }
              variant="outlined"
              name="minAmount"
              id="minAmount"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
              value={props.values.minAmount}
              onChange={props.onChange}
            />
          </Grid>
        )}

        {props.values.logicalOperator !== 'more_than' && (
          <Grid item xs={columnSize}>
            <TextField
              placeholder="2"
              label={
                props.values.logicalOperator === 'between'
                  ? 'Maximum Amount'
                  : 'Amount'
              }
              variant="outlined"
              name="maxAmount"
              id="maxAmount"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
              value={props.values.maxAmount}
              onChange={props.onChange}
            />
          </Grid>
        )}

        <Grid item xs={columnSize}>
          <TextField
            placeholder="10"
            label="Price"
            variant="outlined"
            name="price"
            id="price"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            required
            fullWidth
            value={props.values.price}
            onChange={props.onChange}
          />
        </Grid>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '1rem' }}
        type="submit"
        disabled={props.isDisabled}
      >
        Add
      </Button>
    </form>
  );
};
