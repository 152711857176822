import * as Yup from 'yup';

import debounce from 'lodash.debounce';
import { http } from 'rx-api';
import axios from 'axios';

import {
  address,
  state,
  city,
  zipCode,
  phoneNumber,
  password,
  firstName,
  lastName,
} from '../common.validator';

const FILE_SIZE = 20 * 1024 * 1024;
const ASYNC_VALIDATION_TIMEOUT_IN_MS = 1000;
const SUPPORTED_FORMATS = ['application/pdf', 'image/png', 'image/jpeg'];
let previousEmail: string | undefined;
let previousEmailState = false;

const validationFunction = async (
  value: string | undefined,
  resolve: (arg0: boolean) => void
) => {
  try {
    await http.post(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/signin/verify-email`,
      { email: value }
    );
    resolve(true);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (
        error.response &&
        error.response.data.message === 'Email Already In Use'
      ) {
        resolve(false);
      }
    }
    resolve(true);
  }
};

const validationDebounced = debounce(
  validationFunction,
  ASYNC_VALIDATION_TIMEOUT_IN_MS
);

const SignupBuyerSchema = Yup.object().shape({
  pharmacy: Yup.object().shape({
    deaNumber: Yup.string().required('DEA Number is required'),
    dea: Yup.mixed()
      .required('Please upload your DEA PDF file')
      .test('fileSize', 'PDF file is too large', (value) => {
        return value && value.size <= FILE_SIZE;
      })
      .test(
        'fileFormat',
        'Please upload your DEA File using one of these formats: PDF, PNG, JPEG',
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    stateLicense: Yup.mixed()
      .required('Please upload your State License PDF file')
      .test('fileSize', 'PDF file is too large', (value) => {
        return value && value.size <= FILE_SIZE;
      })
      .test(
        'fileFormat',
        'Please upload your State License File using one of these formats: PDF, PNG, JPEG',
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    dbaName: Yup.string().required('Please enter the Doing Business As name'),
    email: Yup.string()
      .email('Please enter a valid email')
      .required('Please enter your email'),
    faxNumber: Yup.string().notRequired(),
    name: Yup.string().required('Please enter your pharmacy name'),
    npi: Yup.string().required('Please enter your NPI'),
    phoneNumber,
    type: Yup.string().required('Please select a option'),
    yearsBusiness: Yup.number()
      .min(1, 'At least one year')
      .required('Please enter your years in business'),
  }),
  shippingInfo: Yup.object().shape({
    address,
    state,
    city,
    zipCode,
  }),
  billingInfo: Yup.object().shape({
    address,
    state,
    city,
    zipCode,
  }),
  tradeReference: Yup.object().shape({
    accountOne: Yup.string().required('Please enter required field'),
    accountTwo: Yup.string().required('Please enter required field'),
    primaryWholesaler: Yup.string().required('Please enter required field'),
    secondaryWholesaler: Yup.string().required('Please enter required field'),
  }),
  passwordSetup: Yup.object().shape({
    password,
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Please, verify your password')
      .required('Required'),
  }),
  others: Yup.object().shape({
    subscribeToMailingList: Yup.bool(),
    useBillingAddress: Yup.bool(),
    acceptTOS: Yup.bool().oneOf(
      [true],
      'You must agree with the terms and conditions'
    ),
    howDidYouHearAboutUs: Yup.string().required('Please select an option'),
    saleRep: Yup.string().when('howDidYouHearAboutUs', {
      is: 'SaleRep',
      then: Yup.string().required('Please enter required field'),
    }),
  }),
  additionalInfo: Yup.object().shape({
    firstName,
    lastName,
    phoneNumber,
    email: Yup.string()
      .email('Please enter a valid email')
      .required("Please add your pharmacy's email")
      .test('checkEmailIsUnique', 'EMAIL_ALREADY_USE', async (value) => {
        if (value !== previousEmail) {
          const promise = new Promise((resolve) =>
            validationDebounced(value, resolve)
          );
          const result = (await promise) as boolean;
          previousEmailState = result;
          previousEmail = value;
        }
        return previousEmailState;
      }),
  }),
});

export { SignupBuyerSchema };
