import React from 'react';
import styled, { css } from 'styled-components';

import { Grid } from '@material-ui/core';

import { IBuyerSearchFilterForm } from 'rx-domain';

type IProps = {
  searchText: string;
  queryForm: IBuyerSearchFilterForm;
};

const Container = styled(Grid)`
  ${({ theme }) => css`
    ${theme.lessThan('ipadPro')} {
      padding: 1rem;
    }
  `}
`;

const DrugsTitle = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoLight};
    font-size: 18px;
  `}
`;

const SearchContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    ${theme.lessThan('ipadPro')} {
      margin-top: 50px;
    }
  `}
`;

const SearchTitle = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoLight};
    color: ${theme.colors.blue[100]};
    font-size: 1.5rem;
    margin-bottom: 1rem;
  `}
`;

const SearchContent = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoLight};
    font-size: 18px;
    text-align: center;
  `}
`;

const ProductsEmpty = (props: IProps): JSX.Element => {
  const searchNotData = props.searchText ? props.searchText : props.queryForm;

  return (
    <Container container item direction="column">
      <DrugsTitle>Drugs / Search results for: “{searchNotData}”</DrugsTitle>
      <SearchContainer>
        <SearchTitle data-cy="products-search-not-found-product">
          Search results for: {searchNotData}
        </SearchTitle>
        <SearchContent>
          We were not able to find any results for “{searchNotData}”.
          <br />
          Try another search.
        </SearchContent>
      </SearchContainer>
    </Container>
  );
};

export { ProductsEmpty };
