import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useTransactions } from 'rx-contexts';

import { capitalize } from 'rx-utils';

import { useTransactionGenerateDocs } from 'rx-hooks';

import { RxButton } from 'rx-core';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const Title = styled.h2`
  font-size: ${({ theme: { sizes } }) => `${sizes.h2}`};
  font-family: ${({ theme: { fonts } }) => `${fonts.robotoLight}`};
  padding-bottom: 16px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 12px;
`;

const ReturnButton = styled(RxButton)`
  margin-right: 24px;
`;

const TransactionGenerateFailed = (): JSX.Element => {
  const { onSetModalGenerate, onInit, typeDoc } = useTransactions();
  const { onGenerateInvoice, onGenerateReceipt } = useTransactionGenerateDocs(
    {}
  );

  const getTitle = useMemo(() => {
    switch (typeDoc) {
      case 'CONSOLIDATED-INVOICE':
        return 'invoice';
      case 'CONSOLIDATED-RECEIPT':
        return 'receipt';
      default:
        return 'invoice';
    }
  }, [typeDoc]);

  const onReturnTransactions = () => {
    onInit();
    onSetModalGenerate(false);
  };

  const onRetryGeneration = async () => {
    onInit();

    switch (typeDoc) {
      case 'CONSOLIDATED-INVOICE':
        await onGenerateInvoice();
        break;
      case 'CONSOLIDATED-RECEIPT':
        await onGenerateReceipt();
        break;
      default:
        await onGenerateInvoice();
    }
  };

  return (
    <Container>
      <Title>
        {capitalize(getTitle)} generation failed, would you like to try again?
      </Title>
      <ButtonContainer>
        <ReturnButton
          text="Return to Transactions"
          width="200px"
          fontSize="16px"
          secondary
          onClick={onReturnTransactions}
        />
        <RxButton
          text="Try Again"
          width="180px"
          fontSize="16px"
          onClick={onRetryGeneration}
        />
      </ButtonContainer>
    </Container>
  );
};

export { TransactionGenerateFailed };
