import React from 'react';

import styled, { css } from 'styled-components';

export type RxProgressBarProps = {
  percent: number;
};

const Container = styled.div``;

const LineBar = styled.div<{ percent: number }>`
  ${({ theme: { colors }, percent }) => css`
    background: ${colors.blue.gradient};
    width: ${percent}%;
    height: 4px;
    border-radius: 30px;
    position: relative;
  `}
`;

const Circle = styled.div`
  ${({ theme: { colors } }) => css`
    background: ${colors.blue.gradient};
    height: 8px;
    width: 8px;
    border-radius: 100%;
    position: absolute;
    top: -2px;
    right: 0;
  `}
`;

const RxProgressBar = ({
  percent,
  ...props
}: RxProgressBarProps): JSX.Element => {
  return (
    <Container {...props}>
      <LineBar percent={percent}>{percent > 0 && <Circle />}</LineBar>
    </Container>
  );
};

RxProgressBar.defaultProps = {
  percent: 0,
};

export { RxProgressBar };
