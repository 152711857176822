import React from 'react';

import { Loading, Button } from './index.styles';

type VariantButton = 'outline' | 'normal' | 'ghost';

export type RxButtonSimpleProps =
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    loading?: boolean;
    variant?: VariantButton;
    dataCy?: string;
  };

const RxButtonSimple: React.FC<RxButtonSimpleProps> = ({
  loading,
  ...props
}: RxButtonSimpleProps) => {
  return (
    <Button {...props} $loading={loading}>
      {loading ? (
        <Loading size={20} thickness={6} type={props.variant} />
      ) : (
        props.children
      )}
    </Button>
  );
};

RxButtonSimple.defaultProps = {
  loading: false,
  variant: 'normal',
};

export { RxButtonSimple };
