import { GENERAL_URL_V1 } from '../constants';

export const GET_ACCOUNT_2FA_URL = `${GENERAL_URL_V1}/account/2fa`;
export const PUT_ACCOUNT_2FA_URL = GET_ACCOUNT_2FA_URL;

export const GET_ACCOUNT_CONTACT_INFO_URL = `${GENERAL_URL_V1}/account/contact-information`;
export const PUT_ACCOUNT_CONTACT_INFO_URL = GET_ACCOUNT_CONTACT_INFO_URL;

export const GET_ACCOUNT_2FA_KEY = 'get-account-2FA';
export const GET_ACCOUNT_CONTACT_INFO_KEY = 'get-account-contact-information';
