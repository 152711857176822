/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { sum } from 'ramda';

import {
  CheckoutTypeError,
  CheckoutErrorMinPOAmount,
  MinPoAmountErrorItem,
  PaymentMethodACH,
  PaymentMethodCard,
  CheckoutErrorEnum,
  IAddress,
  GetProducts,
} from 'rx-domain';

import {
  AppDispatch,
  getHasOTCProduct,
  getSavingMoney,
  getMinAmountValidation,
  setCheckoutSummary,
  setCheckoutSummaryPaymentMethod,
  getShopItemsBySeller,
  getWACSubtotal,
} from 'rx-store';

import {
  CheckoutResponse,
  useGetPharmacistsPaymentTerm,
  useOrdersCheckout,
  useGetPharmacyPaymentMethodsAch,
  useGetPharmacyPaymentMethodsCards,
} from 'rx-api';

import { getPaymentMethodName } from 'rx-utils';
import {
  useAuth,
  useBankingAccountData,
  useSyncShoppingCart,
} from 'rx-hooks/utils';
import formatProduct from '../../../rx-utils/gtmFormatters';

type ACH = PaymentMethodACH & {
  type: 'ach';
};

type Cards = PaymentMethodCard & {
  type: 'card';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useCheckoutProccess = () => {
  const [addShipAddressModal, setAddShipAddressModal] = useState(false);
  const [addBillInfoModal, setAddBillInfoModal] = useState(false);
  const [checkoutError, setCheckoutError] = useState<{
    type: CheckoutTypeError;
    reason?: string;
    meta?: {
      items: Array<MinPoAmountErrorItem>;
    };
  } | null>(null);
  const [paymentType, setPaymentType] = useState<{
    type: 'ach' | 'card' | 'paymentTerms';
    value: string;
  } | null>(null);

  const savingMoney = useSelector(getSavingMoney);
  const hasOTCProducts = useSelector(getHasOTCProduct);
  const fullTotalOrder = useSelector(getWACSubtotal);
  const validOrder = useSelector(getMinAmountValidation);
  const productsBySellers = useSelector(getShopItemsBySeller);

  const history = useHistory();
  const authData = useAuth();
  const {
    pharmacyResponse,
    billingInfoResponse,
    shippingAddressResponse,
    pharmaciesListResponse,
    ...bankingResponses
  } = useBankingAccountData();
  const dispatch: AppDispatch = useDispatch();

  const [selectedPharmacy, setSelectedPharmacy] = useState(
    pharmaciesListResponse?.data?.result[0]
  );

  const { data: cardsResponse, ...OptsCards } =
    useGetPharmacyPaymentMethodsCards(selectedPharmacy?.id || 0);
  const { data: ACHCardsResponse, ...OptsAchs } =
    useGetPharmacyPaymentMethodsAch(selectedPharmacy?.id || 0);

  useEffect(() => {
    setSelectedPharmacy(pharmaciesListResponse?.data?.result[0]);
  }, [pharmaciesListResponse]);

  const { isLoading: isLoadingShopCart } = useSyncShoppingCart();
  const checkoutMutation = useOrdersCheckout();
  const { data: paymentTerm, refetch: refetchPaymentTerm } =
    useGetPharmacistsPaymentTerm();

  const minimumPaymentTerm = useMemo(() => {
    return paymentTerm?.data.minimumAmount ?? 5000;
  }, [paymentTerm]);

  const paymentTermPermission = useMemo(() => {
    return !!paymentTerm?.data.id;
  }, [paymentTerm?.data]);

  const hasPaymentTermsPermission = useMemo(() => {
    return !hasOTCProducts && paymentTermPermission;
  }, [hasOTCProducts, paymentTermPermission]);

  const isPaymentTermsEnabled = savingMoney >= minimumPaymentTerm;

  const shoppingCartRequiresEDI = Object.entries(productsBySellers).some(
    ([key, products]) =>
      products.some((item) => item.product.externalId !== null)
  );

  // const requiresDEA = !selectedPharmacy?.dea && shoppingCartRequiresEDI;

  const canCheckout = useMemo(
    () =>
      !!paymentType &&
      !!shippingAddressResponse?.data.address &&
      !!billingInfoResponse?.data &&
      !checkoutMutation.isLoading &&
      validOrder,
    [
      paymentType,
      shippingAddressResponse,
      billingInfoResponse,
      checkoutMutation.isLoading,
      validOrder,
    ]
  );

  const handleChangePaymentMethod = (
    type: 'ach' | 'card' | 'paymentTerms',
    value: string
  ) => {
    setPaymentType({
      type,
      value,
    });
  };

  const setDefaultPaymentMethod = (payment: ACH | Cards) => {
    dispatch(setCheckoutSummaryPaymentMethod(getPaymentMethodName(payment)));
  };

  const getSubtotal = (checkoutRes: CheckoutResponse) => {
    return sum(checkoutRes.purchaseOrders.map((po) => parseFloat(po.subtotal)));
  };

  const checkErrors = useCallback(
    async (
      error: AxiosError<{
        message?: CheckoutTypeError;
        meta?: {
          items: any[];
        };
      }>
    ) => {
      if (!error.response) {
        return;
      }

      if (!error.response.data.message) {
        setCheckoutError({
          type: CheckoutErrorEnum.BROKEN,
        });
        return;
      }

      if (
        error.response.data.message?.includes(
          'Your account currently does not support bank account payment amounts greater than'
        )
      ) {
        setCheckoutError({
          type: CheckoutErrorEnum.ACH_LIMIT_EXCEEDED,
          reason: error.response.data.message,
        });
        return;
      }

      const type = error.response.data.message.includes(
        'Minimum Order with payment terms must be'
      )
        ? 'payment_term_minimum_amount'
        : error.response.data.message;
      const reason = error.response.data.message;

      switch (type) {
        case 'Unverified ACH Account':
        case 'No billing address':
        case 'No shipping address':
        case 'Minimum Order Total of $150 USD':
        case 'Insufficient funds':
        case 'Your card was declined':
        case 'Missing DEA and/or State License':
        case 'Pharmacy is not approved':
        case 'Empty Shopping Cart': {
          setCheckoutError({ type });
          break;
        }

        case 'Payment Term Forbidden': {
          const { isAuth, setUserData, ...userData } = authData;

          await refetchPaymentTerm();

          // Removed Payment Term permission
          setUserData({
            ...userData,
            permissions: authData.permissions?.filter(
              (p) => p !== 'payment_term'
            ),
          });
          setCheckoutError({
            type: 'Payment Term Forbidden',
          });
          break;
        }

        case 'Products cannot be shipped to restricted states':
        case 'Grace Period Forbidden':
        case 'Not Enough Inventory': {
          setCheckoutError({
            type,
            meta: error.response?.data?.meta,
          });
          break;
        }

        case 'payment_term_minimum_amount': {
          setCheckoutError({
            type,
            reason,
          });
          break;
        }
        case 'Minimum Purchase Orders Amount': {
          const minPoAmountRes = error.response
            .data as CheckoutErrorMinPOAmount;

          setCheckoutError({
            type,
            meta: minPoAmountRes.meta,
          });
          break;
        }
        default:
          setCheckoutError({
            type: CheckoutErrorEnum.BROKEN,
          });
          break;
      }
    },
    [authData, refetchPaymentTerm]
  );

  const onPlaceOrder = useCallback(async () => {
    try {
      setCheckoutError(null);

      if (!paymentType) return;
      if (!paymentType.value) return;

      const productsFormatted: any[] = [];
      Object.entries(productsBySellers).forEach(([key, products]) => {
        products.forEach((item, index) =>
          productsFormatted.push({ ...item.product, count: item.count })
        );
      });

      if (paymentType.type === 'paymentTerms') {
        let paymentTermValue = 15;

        if (paymentType.value === 'paymentFifteenDays') paymentTermValue = 15;
        if (paymentType.value === 'paymentThirtyDays') paymentTermValue = 30;

        const response = await checkoutMutation.mutateAsync({
          gracePeriod: paymentTermValue,
          pharmacyId: selectedPharmacy?.id || 0,
        });
        dispatch(
          setCheckoutSummary({
            delivered: {
              pharmacyData: {
                address: selectedPharmacy?.shippingAddress as IAddress,
                type: selectedPharmacy?.type || '',
                dea: selectedPharmacy?.dea || '',
                pharmacyName: selectedPharmacy?.pharmacyName || '',
                stateLicense: selectedPharmacy?.stateLicense || '',
                approvalStatus: '',
              },
              shipAddressData: selectedPharmacy?.shippingAddress as IAddress,
            },
            subtotal: getSubtotal(response.data),
            paymentMethod: '',
            orderNumber: `ORD-${response.data.pharmacistId}-${response.data.id}`,
          })
        );

        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push({ ecommerce: null });
        // @ts-ignore
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: response.data.id,
            currency: 'USD',
            value: 0,
            items: productsFormatted.map((product, index) => {
              return formatProduct({
                product: product as unknown as GetProducts.ProductItem,
                index: index,
                quantity: product.count,
              });
            }),
          },
          user_id: localStorage.getItem('_userid'),
        });

        history.push(`/checkout/summary/${response.data.id}`);

        return;
      }

      if (paymentType.type === 'ach' || paymentType.type === 'card') {
        const response = await checkoutMutation.mutateAsync({
          paymentMethodId: parseInt(paymentType.value, 10),
          pharmacyId: selectedPharmacy?.id || 0,
        });

        dispatch(
          setCheckoutSummary({
            delivered: {
              pharmacyData: pharmacyResponse?.data,
              shipAddressData: shippingAddressResponse?.data.address,
            },
            subtotal: getSubtotal(response.data),
            paymentMethod: '',
            orderNumber: `ORD-${response.data.pharmacistId}-${response.data.id}`,
          })
        );

        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push({ ecommerce: null });
        // @ts-ignore
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: response.data.id,
            currency: 'USD',
            value: 0,
            items: productsFormatted.map((product, index) => {
              return formatProduct({
                product: product as unknown as GetProducts.ProductItem,
                index: index,
                quantity: product.count,
              });
            }),
          },
          user_id: localStorage.getItem('_userid'),
        });

        history.push(`/checkout/summary/${response.data.id}`);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) await checkErrors(error);
    }
  }, [
    checkErrors,
    checkoutMutation,
    dispatch,
    history,
    paymentType,
    pharmacyResponse?.data,
    selectedPharmacy?.dea,
    selectedPharmacy?.id,
    selectedPharmacy?.pharmacyName,
    selectedPharmacy?.shippingAddress,
    selectedPharmacy?.stateLicense,
    selectedPharmacy?.type,
    shippingAddressResponse?.data?.address,
  ]);

  return {
    canCheckout,
    hasPaymentTermsPermission,
    isPaymentTermsEnabled,
    refetchPaymentTerm,
    paymentType,
    handleChangePaymentMethod,
    onPlaceOrder,
    setDefaultPaymentMethod,
    checkoutError,
    selectedPharmacy,
    setSelectedPharmacy,
    addShipAddressModal,
    setAddShipAddressModal,
    addBillInfoModal,
    setAddBillInfoModal,
    pharmacyResponse,
    pharmaciesListResponse,
    billingInfoResponse,
    shippingAddressResponse,
    cardsResponse,
    // requiresDEA,
    ACHCardsResponse,
    setCheckoutError,
    cardsResponseLoading: OptsCards.isLoading,
    ACHCardsResponseLoading: OptsAchs.isLoading,
    checkoutLoading: checkoutMutation.isLoading,
    checkoutSuccess: checkoutMutation.isSuccess,
    checkoutResponse: checkoutMutation,
    isLoadingShopCart,

    ...bankingResponses,
  };
};

export { useCheckoutProccess };
