import { useQuery } from 'react-query';
import { IGetPaymentMethodACHDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// TODO: Only works in manufacturer users
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetPharmacyPaymentMethodsAch = (
  pharmacyId: number | string
) => {
  return useQuery(['get-pharmacy-payment-methods-ach', pharmacyId], () => {
    return http.get<IGetPaymentMethodACHDTO>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/pharmacist/pharmacies/${pharmacyId}/payment-methods/ach`,
      { withCredentials: true }
    );
  });
};
