import * as Yup from 'yup';
import {
  firstName,
  lastName,
  titleOrPosition,
  phoneNumber,
  email,
} from '../common.validator';

const ManufacturerAccountSchema = Yup.object().shape({
  firstName,
  lastName,
  titleOrPosition,
  phoneNumber,
  email: email.required(),
  secondaryEmail: Yup.string().nullable().email('Invalid email'),
});

export { ManufacturerAccountSchema };
