import React from 'react';
import { SEO, TemplateView } from 'rx-core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { AddBannedProductValidator } from 'validations/AddProductValidator';
import { useAdminGetBannedProduct } from 'rx-hooks/roles/admin/useAdminGetBannedProduct';
import { AdminAddBannedProductForm } from '../organims/AdminAddBannedProductForm';
import { AddProductActions } from '../../../general/add-product/AddProductActions';

const AdminEditBannedProductView: React.FC = () => {
  const history = useHistory();

  const { initialValues, onSubmit } = useAdminGetBannedProduct();

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: AddBannedProductValidator,
    enableReinitialize: true,
  });

  return (
    <TemplateView>
      <SEO title="RxRise | Edit Banned Product" />
      <form onSubmit={formik.handleSubmit}>
        <AddProductActions isEdit={true} productName={initialValues.name} />
        <AdminAddBannedProductForm
          values={formik.values}
          onChange={formik.handleChange}
          errors={formik.errors}
          onClickCancel={() => history.push('/products')}
        />
      </form>
    </TemplateView>
  );
};

export { AdminEditBannedProductView };
