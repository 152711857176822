import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import {
  AppDispatch,
  getCheckoutSummary,
  setCleanShoppingCart,
} from 'rx-store';
import { useGetOrderById } from 'rx-api';
import { TemplateView, SEO } from 'rx-core';

import { useQueryClient } from 'react-query';
import { GET_SHOPPING_CART_ITEMS_KEY } from 'rx-api/api/pharmacist/shopping-cart/constants';
import {
  MainContainer,
  Container,
  OrderNumberText,
  Title,
  Subtitle,
  ShipAddressContainer,
  SubtotalContainer,
  CheckoutSaleOrder,
} from './CheckoutSummaryView.styles';
import { CheckoutSummaryLoading } from './CheckoutSummaryLoading';

export const CheckoutSummaryView: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const queryClient = useQueryClient();
  const summaryData = useSelector(getCheckoutSummary);
  const { orderId } = useParams<{ orderId: string | undefined }>();
  const {
    data: response,
    isLoading,
    error,
  } = useGetOrderById({
    id: orderId || '0',
  });
  const isNotFound = (error as any)?.response.status === 404;

  useEffect(() => {
    queryClient.removeQueries({
      queryKey: GET_SHOPPING_CART_ITEMS_KEY,
      exact: true,
    });

    dispatch(setCleanShoppingCart());
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [dispatch, queryClient]);

  return (
    <TemplateView>
      <SEO title="RxRise | Checkout Summary" />
      <MainContainer container justifyContent="center">
        <Container data-cy="order-summary" item md={8} spacing={4}>
          {isLoading && <CheckoutSummaryLoading />}

          {!isLoading && isNotFound && (
            <>
              <Title>There's been a mistake</Title>

              <Typography variant="h6">
                The order you are looking for was not found, or it doesn't
                exist.
              </Typography>

              <Typography>
                Dont’t worry you can do one of the options below:
              </Typography>

              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#9EA6BB',
                  margin: '1rem 0',
                }}
              />

              <Grid
                item
                container
                justifyContent="center"
                direction="column"
                alignItems="center"
              >
                <Typography>
                  <Link to="/orders">Go to "My Orders"</Link>
                  <span>{' or '}</span>
                  <Link to="/">Return to Marketplace</Link>
                </Typography>
              </Grid>
            </>
          )}

          {!isLoading && !isNotFound && (
            <>
              <Title>Thank you for your purchase!</Title>

              <Subtitle>
                Your order has been placed. You will receive an e-mail
                confirmation with your order details.
              </Subtitle>
              <hr />

              <ShipAddressContainer>
                <Subtitle>Delivered to:</Subtitle>
                <div>
                  <Subtitle>
                    <b>{response?.data.shippingAddress.pharmacy}</b>
                    <br />
                    {response?.data.shippingAddress.address}
                    <br />
                    {response?.data.shippingAddress.city},{' '}
                    {response?.data.shippingAddress.state},{' '}
                    {response?.data.shippingAddress.zipCode}
                    <br />
                    Unites States
                  </Subtitle>
                </div>
              </ShipAddressContainer>

              <Subtitle data-cy="checkout-summary-payments">
                Payment details: {summaryData.paymentMethod}.
              </Subtitle>
              <hr />

              {response?.data.purchaseOrders.map((purchaseOrder) => (
                <CheckoutSaleOrder>
                  <OrderNumberText>
                    Purchase Order: PO-{purchaseOrder.id}
                  </OrderNumberText>
                  <Typography variant="subtitle1">
                    Products from: {purchaseOrder.seller}
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Line</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Sub Total</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {purchaseOrder.orderItems.map((orderItem) => {
                        const subtotal = parseFloat(
                          `${parseFloat(orderItem.price) * orderItem.quantity}`
                        ).toFixed(2);

                        return (
                          <TableRow>
                            <TableCell>{orderItem.line}</TableCell>
                            <TableCell>${orderItem.price}</TableCell>
                            <TableCell>{orderItem.quantity}</TableCell>
                            <TableCell>${subtotal}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </CheckoutSaleOrder>
              ))}

              <SubtotalContainer />

              <Grid
                item
                container
                justifyContent="center"
                direction="column"
                alignItems="center"
              >
                <Grid item>
                  <Link data-cy="checkout-summary-go-back" to="/">
                    Return to Marketplace
                  </Link>
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </MainContainer>
    </TemplateView>
  );
};
