import React from 'react';
import styled, { css } from 'styled-components';
import { Form, Formik } from 'formik';

import { GetProducts } from 'rx-domain';
import { useDispatch } from 'react-redux';
import { AppDispatch, setCartItem } from 'rx-store';
import { useShoppingCartOnline, useToast } from 'rx-hooks';
import { AddProductSchema } from 'utils/validators/pharmacist/add-product.validator';

import { ProductItemAddCart } from '../atoms/ProductItemAddCart';
import { ProductItemAmount } from '../atoms/ProductItemAmount';
import { ProductItemAddFavorite } from '../atoms/ProductItemAddFavorite';

type IProps = GetProducts.ProductItem;

const Container = styled.div`
  margin-top: 0.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.span`
  ${({ theme }) => css`
    padding-right: 0.5rem;

    ${theme.lessThan('ipadPro')} {
      display: none;
    }
  `}
`;

type IForm = {
  units: number;
};

export const ProductItemActions: React.FC<IProps> = (props): JSX.Element => {
  const initialValues: IForm = {
    units: 0,
  };

  const dispatch: AppDispatch = useDispatch();

  const { onStoreShoppingCart } = useShoppingCartOnline();
  const { onSuccessToast, onErrorToast } = useToast();

  const onAddShoppingCartItem = async (values: IForm) => {
    try {
      const shopCartId = await onStoreShoppingCart(props.id, values.units);

      if (!shopCartId) return;

      dispatch(
        setCartItem({
          count: values.units,
          product: { ...props, minimumOrderAmount: '' },
          id: shopCartId,
        })
      );

      onSuccessToast(`Added to Cart! 🛒`);
    } catch (err) {
      onErrorToast('Something goes wrong, please retry later');
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onAddShoppingCartItem}
      validationSchema={AddProductSchema}
    >
      <Form>
        <Container>
          <ButtonContainer>
            {props.units > 0 ? (
              <>
                <Text>Quantity:</Text>
                <ProductItemAmount {...props} />
                <ProductItemAddCart {...props} />
              </>
            ) : (
              'Out of Stock'
            )}
          </ButtonContainer>
        </Container>
      </Form>
    </Formik>
  );
};
