import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useAdminGetPharmacies } from 'rx-api';

import { ApprovePharmacyCard } from '../../molecules/ApprovePharmacyCard';

export const ApprovePharmacy: React.FC = () => {
  const { data } = useAdminGetPharmacies();
  const totalPharmacies = data?.data?.results?.length || 0;
  return (
    <div style={{ marginBottom: '2rem' }}>
      <header style={{ marginBottom: '0.2rem' }}>
        <Typography variant="h5">Additional Pharmacies Request</Typography>
        <Typography variant="h6">
          You have {totalPharmacies} requests to approve:
        </Typography>
      </header>

      <Grid spacing={4} container>
        {data?.data &&
          data.data.results.map((buyer: any) => (
            <Grid xs={4} key={buyer.id} item>
              <ApprovePharmacyCard
                title="Pharmacy"
                data={{
                  id: buyer.id,
                  dea: buyer.dea,
                  stateLicense: buyer.stateLicense,
                  pharmacyName: buyer.pharmacyName,
                  legalDocuments: buyer.legalDocuments,
                  firstName: buyer.account.firstName,
                  lastName: buyer.account.lastName,
                  phoneNumber: buyer.account.phoneNumber,
                  titleOrPosition: buyer.account.titleOrPosition,
                  address: buyer.billingAddress,
                }}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};
