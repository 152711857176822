import { useSelector } from 'react-redux';
import { getCartItems } from 'rx-store';
import {
  usePutShoppingCart,
  usePostShoppingCart,
  useDeleteShoppingCart,
} from 'rx-api';
import { useQueryClient } from 'react-query';
import { GET_SHOPPING_CART_ITEMS_KEY } from 'rx-api/api/pharmacist/shopping-cart/constants';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useShoppingCartOnline = () => {
  const updateShoppingCart = usePutShoppingCart();
  const postShoppingCart = usePostShoppingCart();
  const deleteShoppingCart = useDeleteShoppingCart();
  const queryClient = useQueryClient();

  const cartItems = useSelector(getCartItems);

  const onUpdateShoppingCart = async (
    id: number | undefined,
    quantity: number
  ): Promise<number | null> => {
    if (!id) {
      return null;
    }

    const { data } = await updateShoppingCart.mutateAsync({
      id,
      quantity,
    });

    return data.id;
  };

  const onStoreShoppingCart = async (
    productId: number,
    quantity: number
  ): Promise<number | null> => {
    try {
      if (!productId) {
        return null;
      }

      const cartItem = cartItems.find((ci) => ci.product.id === productId);

      if (cartItem) {
        return await onUpdateShoppingCart(cartItem.id, quantity);
      }

      const { data } = await postShoppingCart.mutateAsync({
        productId,
        quantity,
      });

      return data.id;
    } catch (e) {
      return null;
    }
  };

  const onDeleteProduct = async (id: number | undefined): Promise<void> => {
    if (!id) {
      return;
    }

    await deleteShoppingCart.mutateAsync({
      id,
    });

    await queryClient.removeQueries({
      queryKey: GET_SHOPPING_CART_ITEMS_KEY,
      exact: true,
    });
  };

  return {
    cartItems,
    onUpdateShoppingCart,
    onStoreShoppingCart,
    onDeleteProduct,
    isLoadingUpdate: updateShoppingCart.isLoading,
    isLoadingStore: postShoppingCart.isLoading,
    isLoadingDelete: deleteShoppingCart.isLoading,
  };
};
