import { useMutation } from 'react-query';

import { http } from 'rx-api/http/repository';

type IRequest = {
  id: number;
  emails: Array<string>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAdminManufacturersIdApiKeyEmail = () => {
  const onRequest = async ({ id, ...request }: IRequest) => {
    return http.post(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/manufacturers/${id}/api-key/email`,
      request.emails
    );
  };

  return useMutation(onRequest, {});
};
