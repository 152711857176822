import { GetOrders } from 'rx-domain';
import { GET_BUYER_ORDERS_URL, GET_BUYER_ORDERS_KEY } from './constants';
import { useGetRequest } from 'rx-api/utils';

const makeParams = (req: Partial<GetOrders.Query>) => {
  let query = `?limit=10`;

  if (req.limit) {
    query = `?limit=${req.limit}`;
  }

  if (req.id) {
    query += `&id=${req.id}`;
  }

  if (req.status) {
    query += `&status=${req.status}`;
  }

  if (req.skip) {
    query += `&skip=${req.skip}`;
  }

  if (req.from) {
    query += `&from=${req.from}`;
  }

  if (req.to) {
    query += `&to=${req.to}`;
  }

  return query;
};

const useGetOrders = (query: Partial<GetOrders.Query>) => {
  return useGetRequest<Partial<GetOrders.Query>, GetOrders.Response>({
    url: GET_BUYER_ORDERS_URL,
    key: [
      GET_BUYER_ORDERS_KEY,
      query.id,
      query.to,
      query.from,
      query.skip,
      query.limit,
      query.status,
    ],
    params: query,
    makeParams,
  });
};

export { useGetOrders };
