import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Typography } from '@material-ui/core';

import { getWACSubtotal, getMinAmountValidation } from 'rx-store';

import { formatMoney } from 'rx-utils';

import { Container } from './index.styles';

export const ShopCartCheckout: React.FC = () => {
  const fullTotalOrder = useSelector(getWACSubtotal);
  const validOrder = useSelector(getMinAmountValidation);

  const history = useHistory();

  const nextToCheckout = () => {
    history.push('/checkout');
  };

  return (
    <Container>
      <Typography style={{ marginRight: '1rem' }}>
        Order Total: <b>{formatMoney(fullTotalOrder)}</b>
      </Typography>
      <Button
        data-cy="shopping-cart-checkout"
        disabled={!validOrder}
        onClick={nextToCheckout}
        variant="contained"
        color="primary"
      >
        Proceed to Checkout
      </Button>
    </Container>
  );
};
