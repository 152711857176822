import React from 'react';
import styled from 'styled-components';

type LoginTitleProps = {
  text: string;
  className?: string;
};

const Text = styled.h2`
  font-family: ${({ theme }) => theme.fonts.robotoLight};
  font-size: 24px;
  line-height: 28px;

  color: ${({ theme }) => theme.colors.black[100]};
`;

const LoginTitle = ({ text, ...props }: LoginTitleProps): JSX.Element => {
  return <Text {...props}>{text}</Text>;
};

LoginTitle.defaultProps = {
  className: '',
};

export { LoginTitle };
