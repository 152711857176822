import { GetProducts } from 'rx-domain';
import { GET_PRODUCTS_KEY, GET_PRODUCTS_URL } from './constants';
import { useGetRequest } from 'rx-api/utils';

const makeParams = (req: Partial<GetProducts.Query>) => {
  let query = `?limit=10`;

  if (req.limit) {
    query = `?limit=${req.limit}`;
  }

  if (req.skip) {
    query += `&skip=${req.skip}`;
  }

  if (req.category) {
    query += `&category=${req.category}`;
  }

  if (req.description) {
    query += `&description=${req.description}`;
  }

  if (req.form) {
    query += `&form=${req.form}`;
  }

  if (req.id) {
    query += `&id=${req.id}`;
  }

  if (req.maxPrice) {
    query += `&maxPrice=${req.maxPrice}`;
  }

  if (req.minPrice) {
    query += `&minPrice=${req.minPrice}`;
  }

  if (req.name) {
    query += `&name=${req.name}`;
  }

  if (req.ndc) {
    query += `&ndc=${req.ndc}`;
  }

  if (req.shippingName) {
    query += `&shippingName=${req.shippingName}`;
  }

  if (req.sortBy) {
    query += `&sortBy=${req.sortBy}`;
  }

  if (req.sortOrder) {
    query += `&sortOrder=${req.sortOrder}`;
  }

  if (req.sellerId) {
    query += `&sellerId=${req.sellerId}`;
  }

  if (req.strengthMin) {
    query += `&strengthMin=${req.strengthMin}`;
  }

  if (req.strengthMax) {
    query += `&strengthMax=${req.strengthMax}`;
  }

  if (req.strengthUnit) {
    query += `&strengthUnit=${req.strengthUnit}`;
  }

  return query;
};

const useGetBuyerProducts = (query: Partial<GetProducts.Query>) => {
  return useGetRequest<Partial<GetProducts.Query>, GetProducts.Response>({
    url: GET_PRODUCTS_URL,
    key: [
      GET_PRODUCTS_KEY,
      query.category,
      query.description,
      query.form,
      query.id,
      query.limit,
      query.maxPrice,
      query.minPrice,
      query.name,
      query.ndc,
      query.shippingName,
      query.skip,
      query.sortBy,
      query.sortOrder,
      query.viewType,
      query.sellerId,
      query.strengthMin,
      query.strengthMax,
      query.strengthUnit,
    ],
    params: query,
    makeParams,
  });
};

export { useGetBuyerProducts };
