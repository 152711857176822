import React from 'react';

export const OrderAmountReportsLegend = ({ payload }: any) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        padding: '10px',
      }}
    >
      {payload.map((entry: any, index: number) => {
        const label =
          entry.value === 'rxriseSplit'
            ? 'RxRise Split'
            : entry.value === 'sellerSplit'
            ? 'Seller Split'
            : 'Total Orders Amount';

        return (
          <div
            key={`item-${index}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '0.75rem',
            }}
          >
            <div
              style={{
                backgroundColor: entry.color,
                width: '10px',
                height: '10px',
                marginRight: '5px',
              }}
            ></div>
            <span>{label}</span>
          </div>
        );
      })}
    </div>
  );
};
