import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  padding: 0.5rem;
  width: 100%;
`;

export const PaginationContainer = styled.div`
  padding-top: 1rem;
`;
