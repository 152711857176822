import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { useAuth } from 'rx-hooks';

type GuardedRouteProps = RouteProps & {
  component: React.ComponentType;
};

const GuardedRoute = ({
  component: Component,
  ...rest
}: GuardedRouteProps): JSX.Element => {
  const { isAuth } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth === true ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export { GuardedRoute };
