import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { useFormikContext } from 'formik';

import { SignupSellerForm } from 'rx-domain';

import { Grid } from '@material-ui/core';
import { RxButton, RxInputText, RRSelect as RxSelect } from 'rx-core';

import { useSignupManufacturer } from 'rx-contexts';

import { useMediaQuery } from 'rx-hooks';
import { StepperViews } from 'rx-hooks/utils/useWizard';

const Container = styled.div`
  width: 100%;
`;

const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h1};
    font-family: ${theme.fonts.roobertLight};
    font-weight: normal;
    text-align: center;
    margin-top: 5rem;

    ${theme.lessThan('ipad')} {
      font-size: ${theme.sizes.h2};
      margin-top: 2.5rem;
    }
  `}
`;

const TextInformation = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoLight};
    font-weight: 400;
    text-align: center;
    color: ${theme.colors.gray[500]};
    line-height: 28px;
    margin-top: 1rem;
    margin-bottom: 2.5rem;

    ${theme.lessThan('ipad')} {
      font-size: ${theme.sizes.small};
      padding: 0 1.5rem;
    }
  `}
`;

const GridResponsive = styled(Grid)`
  ${({ theme }) => css`
    ${theme.lessThan('ipad')} {
      &.MuiGrid-spacing-xs-2 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }

      &.MuiGrid-spacing-xs-4 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }
    }
  `}
`;

const ButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    ${theme.lessThan('ipad')} {
      padding: 0 1.5rem;
    }
  `}
`;

const NextButton = styled(RxButton)`
  margin-top: 12px;
  margin-bottom: 40px;
  max-width: 450px;
`;

const PASSWORD_MESSAGE =
  "It's important to use a secure password. You can create this with any combination of 8 or more mixed case letters, numbers or special characters (*? etc).";

const SignupSellerPersonalForm = (): JSX.Element => {
  const isDesktop = useMediaQuery('(min-width: 968px)');

  const { setTouched, errors, setErrors } =
    useFormikContext<SignupSellerForm>();
  const { onChangeWizard, formError } = useSignupManufacturer();

  const validForm =
    !!errors.email === false &&
    !!errors.firstName === false &&
    !!errors.lastName === false &&
    !!errors.titleOrPosition === false &&
    !!errors.password === false &&
    !!errors.confirmPassword === false &&
    !!errors.phoneNumber === false &&
    !!errors.howDidYouHearAboutUs === false;

  const onValidateForm = () => {
    setTouched({
      email: true,
      firstName: true,
      lastName: true,
      titleOrPosition: true,
      password: true,
      confirmPassword: true,
      phoneNumber: true,
      howDidYouHearAboutUs: true,
    });
  };

  const onNextForm = () => {
    onValidateForm();

    if (!validForm) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return;
    }

    onChangeWizard(StepperViews.BUSINESS_INFORMATION);
  };

  useEffect(() => {
    if (formError) {
      if (formError === 'EMAIL_ALREADY_USE') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [formError]);

  useEffect(() => {
    if (formError && formError === 'EMAIL_ALREADY_USE') {
      setErrors({
        email: 'EMAIL_ALREADY_USE',
      });
    }
  }, [formError, setErrors]);

  return (
    <Container>
      <Title>Welcome!</Title>
      <TextInformation>Let’s continue with some basic info</TextInformation>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="email"
            label="Email *"
            placeholder="yourname@pharmacyname.com"
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="firstName"
            label="First Name *"
            placeholder="First Name"
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="lastName"
            label="Last Name *"
            placeholder="Last Namer"
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="titleOrPosition"
            label="Title / Position at Company *"
            placeholder="Title / Position at Company"
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="phoneNumber"
            label="Phone Number *"
            placeholder="Phone number"
            withMask
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="password"
            label="Password *"
            placeholder="Password"
            type="password"
            showPassword={true}
            message={isDesktop ? PASSWORD_MESSAGE : ''}
            showPasswordHelper
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="confirmPassword"
            label="Verify Password *"
            placeholder="Verify Password"
            type="password"
            showPassword={true}
            showPasswordHelper
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxSelect
            name="howDidYouHearAboutUs"
            label="How did you hear about us? *"
            items={[
              {
                value: '',
                name: 'Please Select',
              },
              {
                value: 'Linkedin',
                name: 'Linkedin',
              },
              {
                value: 'Twitter',
                name: 'Twitter',
              },
              {
                value: 'Facebook',
                name: 'Facebook',
              },
              {
                value: 'Instagram',
                name: 'Instagram',
              },
              {
                value: 'SaleRep',
                name: 'Sales Representative',
              },
              {
                value: 'Other',
                name: 'Other',
              },
            ]}
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
      <ButtonContainer>
        <NextButton
          type="button"
          text="Next"
          data-testid="next-button"
          onClick={onNextForm}
        />
      </ButtonContainer>
    </Container>
  );
};

export { SignupSellerPersonalForm };
