import React from 'react';
import { useFormik } from 'formik';
import { useToast } from 'rx-hooks';
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useRxMutation } from 'rx-api/utils';
import { ModalContainer } from './ModalContainer';
import { ModalContent } from './ModalContent';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
import { ModalCloseButton } from './ModalCloseButton';
import { PreviewPicture } from './PreviewPicture';

interface IAdListAddItemModalProps {
  isOpen: boolean;
  onClickCancel(): void;
  onClickConfirm(): void;
}

const useAdminPostAdCampaignItem = () => {
  return useRxMutation<any, unknown>({
    method: 'post',
    url: `${process.env.REACT_APP_WEBSITE_URL}/v3/admin/ad-campaigns`,
  });
};

export const AdListAddItemModal: React.FC<IAdListAddItemModalProps> = (
  props
) => {
  const [pictureUrl, setPictureUrl] = React.useState<
    null | string | ArrayBuffer
  >(null);
  const addCampaignItem = useAdminPostAdCampaignItem();
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      name: '',
      type: 'popup',
      link: '',
      file: '',
      picture: new Blob(),
    },
    onSubmit: async (values) => {
      try {
        const data = new FormData();

        data.append('type', values.type);
        data.append('name', values.name);
        data.append('link', values.link);
        data.append('file', values.picture);

        await addCampaignItem.mutateAsync(data);

        toast.onSuccessToast('Ad created!');
        formik.resetForm({});
        setPictureUrl(null);
        props.onClickConfirm();
      } catch (err) {
        formik.resetForm({});
        toast.onErrorToast('We could not create the Ad');
      }
    },
  });

  const handleClose = () => {
    formik.resetForm({});
    setPictureUrl(null);
    props.onClickCancel();
  };

  return (
    <Modal open={props.isOpen} onClose={handleClose}>
      <ModalContainer>
        <Paper variant="outlined" style={{ padding: '1rem', width: '40rem' }}>
          <form onSubmit={formik.handleSubmit}>
            <ModalHeader>
              <Typography variant="h6">Create Ad</Typography>
              <ModalCloseButton onClick={handleClose}>
                <CloseIcon />
              </ModalCloseButton>
            </ModalHeader>

            <ModalContent>
              <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                <Grid item xs={12}>
                  <Typography>
                    This ad will be displayed on the marketplace. You can a dd a
                    redirect link if needed.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        name="file"
                        type="file"
                        variant="outlined"
                        size="small"
                        value={formik.values.file}
                        onChange={(e: any) => {
                          const reader = new FileReader();

                          reader.onloadend = () => {
                            setPictureUrl(reader?.result);
                          };

                          reader.readAsDataURL(e?.currentTarget?.files[0]);

                          formik.setFieldValue('file', e?.target?.value);
                          formik.setFieldValue(
                            'picture',
                            e?.currentTarget?.files[0]
                          );
                        }}
                        fullWidth
                        required
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                    <Grid item xs={12}>
                      <TextField
                        name="name"
                        label="Ad Name"
                        placeholder="Name"
                        variant="outlined"
                        size="small"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        required
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                    <Grid item xs={12}>
                      <TextField
                        name="link"
                        label="Ad URL Link"
                        placeholder="https://rxrise.com"
                        variant="outlined"
                        size="small"
                        value={formik.values.link}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="url"
                        fullWidth
                        required
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                    <Grid item xs={12}>
                      <FormControl fullWidth size="small">
                        <Select
                          placeholder="Ad Type"
                          name="type"
                          id="ad-type-select"
                          label-id="ad-type-label"
                          variant="outlined"
                          value={formik.values.type}
                          onChange={formik.handleChange}
                          fullWidth
                          required
                        >
                          <MenuItem value="banner">Banner</MenuItem>
                          <MenuItem value="popup">Popup</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                {pictureUrl !== null && (
                  <Grid item xs={6}>
                    <Typography title="h6">
                      <strong>Preview</strong>
                    </Typography>

                    <PreviewPicture src={pictureUrl.toString()} />
                  </Grid>
                )}
              </Grid>
            </ModalContent>

            <ModalFooter>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                disabled={addCampaignItem.isLoading}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={addCampaignItem.isLoading}
              >
                Add
              </Button>
            </ModalFooter>
          </form>
        </Paper>
      </ModalContainer>
    </Modal>
  );
};
