import React from 'react';
import styled from 'styled-components';

import InfoIcon from '../../../assets/icons/info-icon.svg';

const Icon = styled.img``;

const PopperInfoIcon = (): JSX.Element => {
  return <Icon src={InfoIcon} />;
};

export { PopperInfoIcon };
