import { useMutation } from 'react-query';

import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePostAdminPharmacyLegalDocuments = (id: string, name: string) => {
  const onRequest = async (form: any) => {
    return http.post(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/pharmacies/${id}/pharmacy-files/${name}`,
      form
    );
  };

  return useMutation(onRequest, {});
};

export { usePostAdminPharmacyLegalDocuments };
