import React, { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

import { DataState } from 'rx-domain';
import CheckErrorIcon from '../../../assets/icons/check-error-icon.svg';
import CheckInfoIcon from '../../../assets/icons/check-info-icon.svg';
import CheckSuccessIcon from '../../../assets/icons/check-success-icon.svg';

export type StatusCheckIconProps = ImgHTMLAttributes<HTMLImageElement> & {
  type?: DataState;
  className?: string;
};

const getIcon = (type: DataState): string => {
  switch (type) {
    case 'success':
      return CheckSuccessIcon;
    case 'info':
      return CheckInfoIcon;
    case 'error':
      return CheckErrorIcon;
    default:
      return CheckInfoIcon;
  }
};

const Icon = styled.img`
  background-color: white;
`;

const StatusCheckIcon = ({
  type,
  className,
}: StatusCheckIconProps): JSX.Element => {
  return <Icon src={getIcon(type ?? 'none')} className={className} />;
};

StatusCheckIcon.defaultProps = {
  className: '',
  type: 'none',
};

export { StatusCheckIcon };
