import React from 'react';
import { TableRow, TableCell, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export const AdminProductsTableLoader: React.FC = () => (
  <>
    <TableRow>
      <TableCell colSpan={20}>
        <Paper style={{ padding: '0 1rem', width: '100%' }}>
          <Skeleton height={75} />
        </Paper>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell colSpan={20}>
        <Paper style={{ padding: '0 1rem', width: '100%' }}>
          <Skeleton height={75} />
        </Paper>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell colSpan={20}>
        <Paper style={{ padding: '0 1rem', width: '100%' }}>
          <Skeleton height={75} />
        </Paper>
      </TableCell>
    </TableRow>
  </>
);
