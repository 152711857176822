import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import { IAuthData } from 'rx-domain';

const getUserSettings = () => {
  const item = window.localStorage.getItem('_user');
  return item ? (JSON.parse(item) as IAuthData) : null;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnWindowFocus: false,
      onError: (error) => {
        Sentry.captureException(error);

        if (!axios.isAxiosError(error)) return;

        if (!error.response) {
          Sentry.captureMessage(error.message);
          return;
        }

        Sentry.captureMessage(error?.config?.url ?? error.message, (scope) => {
          scope.clear();

          scope.setTag('endpoint_status', error.response?.status);

          const userSettings = getUserSettings();

          if (!userSettings) return scope;

          scope.setTag('user_email', userSettings.email);
          scope.setTag(
            'user_full_name',
            `${userSettings.firstName} ${userSettings.lastName}`
          );
          scope.setTag('user_type', userSettings.type);
          scope.setTag('user_company_name', userSettings.companyName);

          scope.setUser({
            email: userSettings.email,
          });

          return scope;
        });
      },
    },
    mutations: {
      onError: (error) => {
        Sentry.captureException(error);

        if (!axios.isAxiosError(error) || !error.response) return;

        Sentry.captureMessage(error?.config?.url ?? error.message, (scope) => {
          scope.clear();

          scope.setTag('endpoint_status', error.response?.status);

          const userSettings = getUserSettings();

          if (!userSettings) return scope;

          scope.setTag('user_email', userSettings.email);
          scope.setTag(
            'user_full_name',
            `${userSettings.firstName} ${userSettings.lastName}`
          );
          scope.setTag('user_type', userSettings.type);
          scope.setTag('user_company_name', userSettings.companyName);

          scope.setUser({
            email: userSettings.email,
          });

          return scope;
        });
      },
    },
  },
});

const ReactQuery: React.FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ReactQuery;
