import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useToast } from 'rx-hooks';

type IProps = {
  apiKey: string;
};

const Container = styled.div`
  width: 46rem;
  position: relative;
  height: 3.125rem;
`;

const Input = styled.input`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    padding-left: 3rem;
    border-radius: 10px;
    border: 1px solid #9fa6b9;
    background: #ffffff;
    font-size: ${theme.sizes.medium};
  `}
`;

const ShowIcon = styled(VisibilityIcon)`
  ${({ theme }) => css`
    cursor: pointer;
    position: absolute;
    left: 0.75rem;
    top: 12.5px;
    color: ${theme.colors.blue[100]};
  `}
`;

const HideIcon = styled(VisibilityOffIcon)`
  ${({ theme }) => css`
    cursor: pointer;
    position: absolute;
    left: 0.75rem;
    top: 12.5px;
    color: ${theme.colors.blue[100]};
  `}
`;

const CopyIcon = styled(FileCopyIcon)`
  cursor: pointer;
  position: absolute;
  right: 0.75rem;
  top: 12.5px;
`;

const ManufacturerApiKeyInput = (props: IProps): JSX.Element => {
  const [showApiKey, setShowApiKey] = useState(false);

  const { onSuccessToast, onErrorToast } = useToast();

  const onCopyClipboard = async () => {
    if (!navigator.clipboard) {
      onErrorToast('Browser no supported');
      return;
    }

    try {
      await navigator.clipboard.writeText(props.apiKey);
      onSuccessToast('Copy to clipboard 📋');
    } catch (err) {
      onErrorToast('Browser no supported');
    }
  };

  return (
    <Container>
      {!showApiKey ? (
        <ShowIcon onClick={() => setShowApiKey((prev) => !prev)} />
      ) : (
        <HideIcon onClick={() => setShowApiKey((prev) => !prev)} />
      )}
      <Input
        value={props.apiKey}
        disabled
        type={showApiKey ? 'text' : 'password'}
      />
      <CopyIcon onClick={onCopyClipboard} />
    </Container>
  );
};

export { ManufacturerApiKeyInput };
