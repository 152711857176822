import React from 'react';
import styled, { css } from 'styled-components';

import { Grid } from '@material-ui/core';
import { RxInputText } from 'rx-core';

const Container = styled.div``;

const PharmacyTitleSection = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoRegular};
    font-weight: 400;
    margin-bottom: 1.5rem;

    ${theme.lessThan('ipad')} {
      padding: 0 1.5rem;
    }
  `}
`;

const GridResponsive = styled(Grid)`
  ${({ theme }) => css`
    ${theme.lessThan('ipad')} {
      &.MuiGrid-spacing-xs-2 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }

      &.MuiGrid-spacing-xs-4 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }
    }
  `}
`;

const SignupBuyerTradeReferenceForm = (): JSX.Element => {
  return (
    <Container>
      <PharmacyTitleSection>Trade reference</PharmacyTitleSection>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="tradeReference.primaryWholesaler"
            label="Primary Wholesaler *"
            placeholder="Primary Wholesaler"
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="tradeReference.accountOne"
            label="Account #1 *"
            placeholder="Account #1"
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="tradeReference.secondaryWholesaler"
            label="Secondary Wholesaler *"
            placeholder="Secondary Wholesaler"
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="tradeReference.accountTwo"
            label="Account #2 *"
            placeholder="Account #2"
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
    </Container>
  );
};

export { SignupBuyerTradeReferenceForm };
