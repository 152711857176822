import React from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Button, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useToast } from 'rx-hooks';
import {
  useDeletePharmacyPaymentMethod,
  useGetPharmacyPaymentMethodsAch,
  useGetPharmacyPaymentMethodsCards,
} from 'rx-api';
import { AddPaymentMethodModal } from './AddPaymentMethodModal';
import { VerifyPharmacyAchModal } from './VerifyPharmacyAchModal';
import { RemovePharmacyPaymentMethodModal } from './RemovePharmacyPaymentMethodModal';

interface IProps {
  disabled: boolean;
}

export const PharmacyPaymentMethods: React.FC<IProps> = (props) => {
  const [isAddPaymentMethodModalOpen, setIsAddPaymentMethodMoadlOpenOpen] =
    React.useState(false);
  const [isVerifyAchModalOpen, setIsVerifyAchModalOpen] = React.useState(false);
  const [isDeletePaymentMethodModalOpen, setIsDeletePaymentMethodModalOpen] =
    React.useState(false);
  const [selectedAchId, setSelectedAchId] = React.useState(0);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] =
    React.useState(0);
  const [selectedPaymentType, setSelectedPaymentType] = React.useState('');
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { data: cardsResponse, isLoading: isLoadingCards } =
    useGetPharmacyPaymentMethodsCards(id);
  const { data: ACHAccountsResponse, isLoading: isLoadingAch } =
    useGetPharmacyPaymentMethodsAch(id);
  const deletePaymentMethod = useDeletePharmacyPaymentMethod(
    parseInt(`${id}`, 10),
    selectedPaymentMethodId,
    selectedPaymentType
  );

  const isLoading = isLoadingCards || isLoadingAch;

  const handleClickAddPaymentMethod = () => {
    setIsAddPaymentMethodMoadlOpenOpen(true);
  };

  const handleCloseAddModal = () => {
    setIsAddPaymentMethodMoadlOpenOpen(false);
  };

  const handleCloseVerifyModal = () => {
    setIsVerifyAchModalOpen(false);
  };

  const handleClickVerify = (achId: number) => () => {
    setSelectedAchId(achId);
    setIsVerifyAchModalOpen(true);
  };

  const handleSuccessVerify = () => {
    setSelectedAchId(0);
    setIsVerifyAchModalOpen(false);
  };

  const handleClickDeletePaymentMethod =
    (paymentMethodId: number, paymentType: 'card' | 'ach') => () => {
      setSelectedPaymentMethodId(paymentMethodId);
      setSelectedPaymentType(paymentType);
      setIsDeletePaymentMethodModalOpen(true);
    };

  const handleCloseDeleteModal = () => {
    setSelectedPaymentMethodId(0);
    setSelectedPaymentType('');
    setIsDeletePaymentMethodModalOpen(false);
  };

  const handleClickConfirmDelete = async () => {
    try {
      await deletePaymentMethod.mutateAsync();
      toast.onSuccessToast('Payment Method Removed');
      await queryClient.refetchQueries('get-pharmacy-payment-methods-cards', {
        active: true,
      });
      await queryClient.refetchQueries('get-pharmacy-payment-methods-ach', {
        active: true,
      });
      setSelectedPaymentMethodId(0);
      setSelectedPaymentType('');
      setIsDeletePaymentMethodModalOpen(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.onErrorToast(`Error:  ${error.response?.data.message}`);
      } else {
        toast.onErrorToast('Could not add card!');
      }
    }
  };

  return (
    <Paper variant="outlined" style={{ padding: '1rem' }}>
      <Typography variant="h6" style={{ marginBottom: '0.5rem' }}>
        Payment Methods
      </Typography>

      {isLoading && (
        <>
          <Skeleton height={60} />
          <Skeleton height={60} />
          <Skeleton height={60} />
        </>
      )}

      {!isLoading &&
        cardsResponse?.data.results.length === 0 &&
        ACHAccountsResponse?.data.results.length === 0 && (
          <Typography>
            This pharmacy doesn't have any payment method associated with it
          </Typography>
        )}

      {!isLoading &&
        cardsResponse?.data.results.map((card) => {
          return (
            <Paper
              key={card.id}
              variant="outlined"
              style={{
                padding: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography>
                {card.brand.toUpperCase()} {card.expMonth}/{card.expYear}
              </Typography>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ marginRight: '1rem' }}>
                  **** **** **** {card.last4}
                </Typography>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleClickDeletePaymentMethod(
                    parseInt(`${card.id}`, 10),
                    'card'
                  )}
                >
                  <DeleteOutlineIcon /> Remove
                </Button>
              </div>
            </Paper>
          );
        })}

      {ACHAccountsResponse?.data.results.map((ach) => {
        return (
          <Paper
            key={ach.id}
            variant="outlined"
            style={{
              padding: '1rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              alignContent: 'flex-start',
            }}
          >
            <div>
              <Typography>
                {ach.bankName.toUpperCase()} {ach.routingNumber}
              </Typography>
              {!ach.isVerified && (
                <Typography color="secondary" variant="subtitle1">
                  Unverified ACH account
                </Typography>
              )}
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography>**** **** **** {ach.last4}</Typography>
              {!ach.isVerified && (
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: '1rem' }}
                  onClick={handleClickVerify(parseInt(`${ach.id}`, 10))}
                >
                  <CheckCircleIcon style={{ marginRight: '0.5rem' }} /> Verify
                </Button>
              )}
              <Button
                color="secondary"
                variant="outlined"
                style={{ marginLeft: '1rem' }}
                onClick={handleClickDeletePaymentMethod(
                  parseInt(`${ach.id}`, 10),
                  'ach'
                )}
              >
                <DeleteOutlineIcon style={{ marginRight: '0.5rem' }} /> Remove
              </Button>
            </div>
          </Paper>
        );
      })}

      {!isLoading && (
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '1rem' }}
          onClick={handleClickAddPaymentMethod}
          disabled={props.disabled}
        >
          Add Payment Method
        </Button>
      )}

      <AddPaymentMethodModal
        pharmacyId={parseInt(`${id}`, 10)}
        open={isAddPaymentMethodModalOpen}
        onCloseModal={handleCloseAddModal}
      />

      <VerifyPharmacyAchModal
        open={isVerifyAchModalOpen}
        achId={selectedAchId}
        pharmacyId={parseInt(`${id}`, 10)}
        onCloseModal={handleCloseVerifyModal}
        onSuccess={handleSuccessVerify}
      />

      <RemovePharmacyPaymentMethodModal
        open={isDeletePaymentMethodModalOpen}
        id={selectedPaymentMethodId}
        pharmacyId={parseInt(`${id}`, 10)}
        onCloseModal={handleCloseDeleteModal}
        onClickConfirm={handleClickConfirmDelete}
        type={selectedPaymentType}
        disabled={deletePaymentMethod.isLoading}
      />
    </Paper>
  );
};
