import { useMutation } from 'react-query';

import { IPostAdminPermissionsIdGrantDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePostAdminPermissionsIdGrant = () => {
  const onRequest = async ({ id, ...req }: IPostAdminPermissionsIdGrantDTO) => {
    return http.post(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/permissions/${id}/grant`,
      req
    );
  };

  return useMutation(onRequest, {});
};

export { usePostAdminPermissionsIdGrant };
