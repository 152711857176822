import { makeStyles, createStyles } from '@material-ui/core/styles';

type StyleProps = {
  withValue: boolean;
};
export const useStyles = makeStyles(() =>
  createStyles({
    option: {
      '&:hover': {},
    },
    input: (props: StyleProps) =>
      props.withValue
        ? {
            borderRadius: '10px',
          }
        : {
            borderRadius: '10px',
          },
    root: (props: StyleProps) =>
      props.withValue
        ? {
            borderRadius: '10px',
            marginLeft: '1rem',
          }
        : {
            borderRadius: '10px',
            marginLeft: '1rem',
          },
    clearIndicator: (props: StyleProps) => (props.withValue ? {} : {}),
    popupIndicator: (props: StyleProps) => (props.withValue ? {} : {}),
  })
);
