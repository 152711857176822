import { GetLoginLogs } from 'rx-domain';
import { useGetRequest } from 'rx-api/utils';
import {
  GET_LOGS_ACCOUNT_LOGIN_URL,
  GET_LOGS_ACCOUNT_LOGIN_KEY,
  GET_LOGS_LOGIN_EMAIL_LIST_KEY,
  GET_LOGS_LOGIN_EMAIL_LIST_URL,
} from 'rx-api/api/admin/logs/constants';

const makeParams = (req: GetLoginLogs.QUERY) => {
  let url = `?limit=10`;

  if (req.skip > 0) {
    url += `&skip=${req.skip}`;
  }

  if (req.sortBy && req.sortOrder) {
    url += `&sortBy=${req.sortBy}&sortOrder=${req.sortOrder}`;
  }

  if (req.accountType && req.accountType !== 'All') {
    url += `&accountType=${req.accountType}`;
  }

  if (req.email) {
    url += `&email=${req.email}`;
  }

  return url;
};

const useGetLoginLogs = (query: GetLoginLogs.QUERY) => {
  return useGetRequest<GetLoginLogs.QUERY, GetLoginLogs.Response>({
    url: GET_LOGS_ACCOUNT_LOGIN_URL,
    key: [
      GET_LOGS_ACCOUNT_LOGIN_KEY,
      query.limit,
      query.skip,
      query.sortBy,
      query.sortOrder,
      query.accountType,
      encodeURI(query.email),
    ],
    params: query,
    makeParams,
  });
};

const useGetEmailList = () => {
  return useGetRequest<
    unknown,
    Array<{ firstName: string; lastName: string; email: string }>
  >({
    url: GET_LOGS_LOGIN_EMAIL_LIST_URL,
    key: [GET_LOGS_LOGIN_EMAIL_LIST_KEY],
  });
};

export { useGetLoginLogs, useGetEmailList };
