import { UpdateContactInfo } from 'rx-domain';
import { useRxMutation } from 'rx-api/utils';
import { PUT_ACCOUNT_CONTACT_INFO_URL } from './constanst';

export const useUpdateContactInfo = () => {
  return useRxMutation<Partial<UpdateContactInfo.DTO>, unknown>({
    method: 'put',
    url: PUT_ACCOUNT_CONTACT_INFO_URL,
  });
};
