import React from 'react';
import { SEO, TemplateView } from 'rx-core';
import { useFormik } from 'formik';
import { useToast } from 'rx-hooks';
import { useHistory } from 'react-router-dom';
import { usePostAdminBannedProduct } from 'rx-api/api/admin';
import { AddBannedProductValidator } from 'validations/AddProductValidator';
import { AdminAddBannedProductForm } from '../organims/AdminAddBannedProductForm';
import { AddProductActions } from '../../../general/add-product/AddProductActions';

const AdminAddBannedProductView: React.FC = () => {
  const toast = useToast();
  const history = useHistory();
  const addBannedProduct = usePostAdminBannedProduct();

  type IBannedProduct = {
    ndc: string;
    name: string;
    description: string;
  };

  const handleSubmit = async (values: IBannedProduct) => {
    try {
      await addBannedProduct.mutateAsync(values);
      toast.onSuccessToast('Product added!');
      history.push('/products?category=black_list');
      return;
    } catch (err) {
      toast.onErrorToast('Could not add banned product');
    }
  };

  const formik = useFormik({
    initialValues: {
      ndc: '',
      name: '',
      description: '',
    },
    onSubmit: handleSubmit,
    validationSchema: AddBannedProductValidator,
    enableReinitialize: true,
  });

  return (
    <TemplateView>
      <SEO title="RxRise | Add New Banned Product" />
      <form onSubmit={formik.handleSubmit}>
        <AddProductActions isEdit={false} productName={''} />
        <AdminAddBannedProductForm
          values={formik.values}
          onChange={formik.handleChange}
          errors={formik.errors}
          onClickCancel={() => history.push('/products')}
        />
      </form>
    </TemplateView>
  );
};

export { AdminAddBannedProductView };
