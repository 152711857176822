import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Button } from '@material-ui/core';

import { http, useGetAdminEDIInventory } from 'rx-api';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { useDownloadProduct, useManageItemsUpload } from 'rx-contexts';
import { useToast } from 'rx-hooks';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

type IProps = {
  view: string | 'black_list';
  setEdiModalOpen?: (value: boolean) => void;
};

const Title = styled.h2``;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrowDownwardIcon = styled(ArrowDownward)`
  margin-right: 0.5rem;
`;

const ArrowUpwardIcon = styled(ArrowUpward)`
  margin-right: 0.5rem;
`;

const usePublishInventoryToEdi = () => {
  return useMutation(
    'admin-publis-inventory-to-edi',
    async () => {
      return http.post<unknown>(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/edi/inventory/publish`
      );
    },
    {}
  );
};

export const AdminProductsActions: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const toast = useToast();
  const [isLoading] = useState(false);
  const { onSetUploadModalVisible, onStepUpload } = useManageItemsUpload();
  const { onSetDownloadProductsVisible } = useDownloadProduct();
  const pullEDI = useGetAdminEDIInventory();
  const publishInventory = usePublishInventoryToEdi();

  const onOpenUploadModal = () => {
    onStepUpload('CHOOSE-MANUFACTURER');
    onSetUploadModalVisible(true);
  };

  const onClickPublishInventory = async () => {
    try {
      await publishInventory.mutateAsync();
      toast.onSuccessToast('Inventory published to EDI');
    } catch (err) {
      toast.onErrorToast('Could not publish inventory to EDI');
    }
  };

  const onDownloadProducts = async () => {
    onSetDownloadProductsVisible(true);
  };

  const onAddBlackListItem = () => {
    history.push(`/products/banned/add`);
  };

  const onAddIndividualProduct = () => {
    history.push('/products/add');
  };

  const onDownloadEDIInventory = async () => {
    await pullEDI.mutateAsync();
    toast.onSuccessToast(
      'Manual EDI download has been initiated. Process could take a few minutes to finish.',
      8000
    );
  };

  if (isLoading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <section>
      <Title style={{ marginBottom: '1rem' }}>Products</Title>
      <BtnContainer>
        {props.view !== 'black_list' && (
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="outlined" onClick={onDownloadEDIInventory}>
                Pull EDI Inventory
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={onClickPublishInventory}
                disabled={publishInventory.isLoading}
              >
                Publish EDI Inventory
              </Button>
            </Grid>

            <Grid item>
              <Button variant="outlined" onClick={() => onOpenUploadModal()}>
                Upload Multiple Products
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={onAddIndividualProduct}
              >
                Add Individual Product
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => onDownloadProducts()}
              >
                <ArrowDownwardIcon />
                Download Products
              </Button>
            </Grid>
          </Grid>
        )}
        {props.view === 'black_list' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => onAddBlackListItem()}
          >
            <ArrowUpwardIcon />
            Add Black list item
          </Button>
        )}
      </BtnContainer>
    </section>
  );
};
