import React from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useQueryClient } from 'react-query';
import { Button, Grid } from '@material-ui/core';

import {
  usePostAdminPermissionsIdGrant,
  usePostAdminPermissionsIdRevoke,
  useUpdatePharmacyPaymentTerm,
} from 'rx-api';
import { RxInputText } from 'rx-core';
import { useToast } from 'rx-hooks';

import { PaymentTermsSchema } from 'utils/validators/admin/payment-terms.validator';
import { Title, Subtitle } from '../styles/index.styles';
import { PTSwitch, PTSwitchContainer } from './index.styles';

type IProps = {
  permissions: string[];
  paymentTermsData: {
    id: number;
    minimumAmount: number;
    gracePeriods: Array<number>;
    ownerId: number;
    createdAt: string;
    updatedAt: string;
  };
  onPaymentTermAccessChange(): void;
};

type IForm = {
  minimumAmount: number;
  paymentTermPermission: boolean;
};

export const PharmacyProfilePaymentTerms: React.FC<IProps> = (props) => {
  const initialData: IForm = {
    minimumAmount: props.paymentTermsData.minimumAmount,
    paymentTermPermission: false,
  };

  const hasPermission = !!props.permissions.find((p) => p === 'payment_term');

  const grantPermission = usePostAdminPermissionsIdGrant();
  const revokePermission = usePostAdminPermissionsIdRevoke();
  const updatePaymentTerm = useUpdatePharmacyPaymentTerm();

  const { onSuccessToast, onErrorToast } = useToast();

  const query = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const onSubmit = async (values: IForm) => {
    try {
      await updatePaymentTerm.mutateAsync({
        gracePeriods: [15, 30],
        id: query.id,
        minimumAmount: values.minimumAmount,
      });

      onSuccessToast('Updated payment term');
    } catch (err) {
      onErrorToast('Could not save data');
    }
  };

  const onChangePaymentTerm = async (_: unknown, checked: boolean) => {
    try {
      if (checked) {
        await grantPermission.mutateAsync({
          accountId: `${props.paymentTermsData.ownerId}`,
          id: 1,
        });

        await queryClient.refetchQueries('get-admin-accounts-id-permissions', {
          active: true,
        });

        onSuccessToast('Payment Terms: Access Granted');
        props.onPaymentTermAccessChange();
      } else {
        await revokePermission.mutateAsync({
          accountId: `${props.paymentTermsData.ownerId}`,
          id: 1,
        });

        await queryClient.refetchQueries('get-admin-accounts-id-permissions', {
          active: true,
        });

        onSuccessToast('Payment Terms: Access Revoked');
        props.onPaymentTermAccessChange();
      }
    } catch (err) {
      onErrorToast('Could not save data');
    }
  };

  return (
    <>
      <Title>Payment terms</Title>
      <Subtitle>
        Turn on this option if you want to grant buyers the option to pay within
        a range of 15 or 30 days.
      </Subtitle>
      <Formik
        initialValues={initialData}
        onSubmit={onSubmit}
        validationSchema={PaymentTermsSchema}
      >
        {({ isSubmitting, isValid, submitForm }) => (
          <Form>
            <PTSwitchContainer
              control={<PTSwitch name="paymentTermPermission" />}
              label="Payment Terms enabled"
              onChange={onChangePaymentTerm}
              checked={hasPermission}
            />

            {hasPermission && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <RxInputText
                      name="minimumAmount"
                      label="Minimum Amount Required"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      disabled={!isValid || isSubmitting}
                      onClick={submitForm}
                    >
                      SAVE
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
