import React from 'react';

import { SearchQueryParams } from 'rx-domain';

import { useDownloadPharmacies } from 'rx-api';

import { useDownloadFile, useToast } from 'rx-hooks';

import { Button, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { SellerStatusFilter } from '../SellerStatusFilter';
import { SellerSearchFilter } from '../SellerSearchFilter';

import {
  Container,
  ArrowDownwardIcon,
  Title,
  RowTitle,
} from './SellerFilters.styles';

type IProps = {
  queries: SearchQueryParams;
  loading: boolean;
};

export const SellerFilters: React.FC<IProps> = (props) => {
  const csvDownload = useDownloadPharmacies();
  const { onDownload } = useDownloadFile();
  const toast = useToast();

  const onDownloadCSV = async () => {
    try {
      if (!props.queries.approvalStatus) {
        return;
      }

      const { data } = await csvDownload.mutateAsync({
        approvalStatus: props.queries.approvalStatus as
          | 'approved'
          | 'pending'
          | 'rejected',
      });

      onDownload(data, props.queries.approvalStatus);
    } catch (err) {
      toast.onErrorToast('Error: could not download CSV file');
    }
  };

  return (
    <Container>
      <RowTitle>
        <Title>Manufacturers</Title>

        <div>
          <Link to="/manufacturers/new">
            <Button variant="outlined" style={{ marginRight: '1rem' }}>
              New Manufacturer
            </Button>
          </Link>
          <Button variant="contained" color="primary" onClick={onDownloadCSV}>
            <ArrowDownwardIcon />
            Download CSV
          </Button>
        </div>
      </RowTitle>

      <Paper variant="outlined" style={{ padding: '1rem', display: 'flex' }}>
        <SellerSearchFilter loading={props.loading} />
        <SellerStatusFilter queries={props.queries} />
      </Paper>
    </Container>
  );
};
