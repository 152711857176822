import { useResponsive } from 'rx-hooks';
import React from 'react';
import styled, { css } from 'styled-components';
import { typography, TypographyProps } from 'styled-system';

import { IMediaQuery } from 'rx-domain';

type TermsAndConditionsTitleProps = TypographyProps & {
  children: React.ReactNode;
  className?: string;
};

const Text = styled.h1<IMediaQuery>`
  font-family: Roobert Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 65px;
  line-height: 105%;

  color: #2b3647;
  ${typography};

  ${({ isIpadPro }) =>
    isIpadPro &&
    css`
      font-size: 25px;
      margin-top: 158px;
    `}
`;

const TermsAndConditionsTitle = ({
  children,
  ...props
}: TermsAndConditionsTitleProps): JSX.Element => {
  const { getMediaQueryProps } = useResponsive();

  return (
    <Text {...props} {...getMediaQueryProps()}>
      {children}
    </Text>
  );
};

export { TermsAndConditionsTitle };
