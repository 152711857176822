import { GetShoppingCartLogs } from 'rx-domain';
import { useGetRequest } from 'rx-api/utils';
import {
  GET_LOGS_SHOPPING_CART_URL,
  GET_LOGS_SHOPPING_CART_KEY,
} from 'rx-api/api/admin/logs/constants';

const makeParams = (req: GetShoppingCartLogs.QUERY) => {
  let url = `?limit=10`;

  if (req.limit) {
    url = `?limit=${req.limit}`;
  }

  if (req.skip > 0) {
    url += `&skip=${req.skip}`;
  }

  return url;
};

export const useGetShoppingCartLogs = (query: GetShoppingCartLogs.QUERY) => {
  return useGetRequest<GetShoppingCartLogs.QUERY, GetShoppingCartLogs.Response>(
    {
      url: GET_LOGS_SHOPPING_CART_URL,
      key: [GET_LOGS_SHOPPING_CART_KEY, query.limit, query.skip],
      params: query,
      makeParams,
    }
  );
};
