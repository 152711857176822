import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { TableCell, TableRow } from '@material-ui/core';

import { ShopCartItem } from 'rx-domain';
import {
  AppDispatch,
  removeCartItem,
  setUnitsItemShoppingCart,
} from 'rx-store';
import { useShoppingCartOnline } from 'rx-hooks';
import { formatMoney, getDateValue } from 'rx-utils';

import { DeleteIcon } from './index.styles';
import { ShopCartInput } from '../ShopCartInput';

type IProps = {
  shopCartItem: ShopCartItem;
  mode?: 'summary' | 'shop-cart';
};

const getPriceStyle = (oldPrice: string, newPrice?: string) => {
  if (!newPrice) {
    return {};
  }

  if (oldPrice === newPrice) {
    return {};
  }

  return { textDecoration: 'line-through' };
};

const getNewPrice = (oldPrice: string, newPrice?: string): string => {
  if (!newPrice) {
    return '-';
  }

  if (oldPrice === newPrice) {
    return '-';
  }

  return formatMoney(newPrice);
};

export const ShopCartDrugItem = (props: IProps): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();

  const { onDeleteProduct, onUpdateShoppingCart } = useShoppingCartOnline();

  const shopCartAmount = useMemo(() => {
    return parseInt(props.shopCartItem.count?.toString() ?? '0', 10);
  }, [props.shopCartItem.count]);

  const subtotal = useMemo(() => {
    if (!props.shopCartItem.count) return 0;

    return (
      props.shopCartItem.count *
      parseFloat(
        props.shopCartItem.product.newPrice || props.shopCartItem.product.price
      )
    );
  }, [
    props.shopCartItem.count,
    props.shopCartItem.product.newPrice,
    props.shopCartItem.product.price,
  ]);

  const validAmount = useMemo(() => {
    const isValidMaximumQuantity = props.shopCartItem.product?.maximumQuantity
      ? shopCartAmount <= props.shopCartItem.product.maximumQuantity
      : true;
    return (
      shopCartAmount >= props.shopCartItem.product.minimumQuantity &&
      isValidMaximumQuantity &&
      shopCartAmount <= props.shopCartItem.product.units
    );
  }, [
    props.shopCartItem.product.minimumQuantity,
    props.shopCartItem.product.maximumQuantity,
    props.shopCartItem.product.units,
    shopCartAmount,
  ]);

  const updateShopCart = useCallback(
    debounce(async (amount) => {
      await onUpdateShoppingCart(props.shopCartItem.id, amount);
    }, 750),
    [props.shopCartItem.id]
  );

  const removeProduct = async () => {
    if (props.shopCartItem.id) {
      await onDeleteProduct(props.shopCartItem.id);
      dispatch(removeCartItem(props.shopCartItem.product.id));
    }
  };

  const validateNewAmount = useCallback(
    async (value: string) => {
      if (!value) {
        await onUpdateShoppingCart(props.shopCartItem.id, 0);
        dispatch(
          setUnitsItemShoppingCart({
            id: props.shopCartItem.product.id,
            amount: 0,
          })
        );
        return;
      }

      const amount = parseInt(value, 10);

      if (amount < 0) return;

      dispatch(
        setUnitsItemShoppingCart({
          id: props.shopCartItem.product.id,
          amount,
        })
      );

      await updateShopCart(amount);
    },
    [dispatch, props.shopCartItem, updateShopCart, onUpdateShoppingCart]
  );

  const updateShopCartItem = async (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    await validateNewAmount(target.value);
  };

  const addOneAmount = useCallback(async () => {
    const newAmount = shopCartAmount + 1;

    await validateNewAmount(newAmount.toString());
  }, [shopCartAmount, validateNewAmount]);

  const substractOneAmount = useCallback(async () => {
    const newAmount = shopCartAmount - 1;
    await validateNewAmount(newAmount.toString());
  }, [shopCartAmount, validateNewAmount]);

  return (
    <TableRow key={props.shopCartItem.id}>
      <TableCell>{props.shopCartItem.product.ndc}</TableCell>
      <TableCell>{props.shopCartItem.product.name}</TableCell>
      <TableCell
        style={getPriceStyle(
          props.shopCartItem.product.price,
          props.shopCartItem.product.newPrice
        )}
      >
        {formatMoney(props.shopCartItem.product.price)}
      </TableCell>
      <TableCell>
        {getDateValue(props.shopCartItem.product.expirationDate)}
      </TableCell>
      <TableCell>
        {props.mode === 'shop-cart' ? (
          <ShopCartInput
            value={props.shopCartItem.count ?? 0}
            onChange={updateShopCartItem}
            onAddOne={addOneAmount}
            onMinusOne={substractOneAmount}
            error={!validAmount}
          />
        ) : (
          props.shopCartItem.count ?? 0
        )}
      </TableCell>
      <TableCell>{formatMoney(subtotal)}</TableCell>
      <TableCell>
        {props.mode === 'shop-cart' && (
          <DeleteIcon data-cy="shopping-cart-remove" onClick={removeProduct} />
        )}
      </TableCell>
    </TableRow>
  );
};

ShopCartDrugItem.defaultProps = {
  mode: 'shop-cart',
};
