import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormBackgroundImg from 'assets/images/backgrounds/rxrise-background.png';
import rxriseLogo from 'assets/logos/rxrise-logo.png';
import { NewMainSearch } from '../../../../rx-core/components/home/search/molecules/NewMainSearch';

const useStyles = makeStyles((theme) => ({
  banner: {
    height: '500px',
    backgroundColor: 'white',
    backgroundImage: `url(${FormBackgroundImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
}));

export default function MainSearch() {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.banner}>
        <div style={{ maxWidth: '450px' }}>
          <img src={rxriseLogo} style={{ maxWidth: '100%' }} />
        </div>
        <NewMainSearch />
      </Grid>
    </Grid>
  );
}
