import React, { ChangeEvent } from 'react';

import { Grid, InputAdornment, TextField } from '@material-ui/core';
import { Container } from './index.styles';

type IProps = {
  error: string | undefined;
  value: string | number;
  handleChange(e: ChangeEvent<any>): void;
};

const SellerShipInfoMinOrderForm = (props: IProps): JSX.Element => {
  return (
    <Container>
      <Grid item xs={12} md={4}>
        <TextField
          error={!!props.error}
          name="minimumOrderAmount"
          label="Minimum Order Amount *"
          variant="outlined"
          value={props.value}
          onChange={props.handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          helperText={props.error}
          fullWidth
        />
      </Grid>
    </Container>
  );
};

export { SellerShipInfoMinOrderForm };
