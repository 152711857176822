import { GetTwoFactorConfig } from 'rx-domain';
import { useGetRequest } from 'rx-api/utils';
import { GET_ACCOUNT_2FA_KEY, GET_ACCOUNT_2FA_URL } from './constanst';

export const useGetTwoFactorConfig = () => {
  return useGetRequest<unknown, GetTwoFactorConfig.Response>({
    url: GET_ACCOUNT_2FA_URL,
    key: [GET_ACCOUNT_2FA_KEY],
  });
};
