import { CS_SET_SUMMARY, CS_SET_PAYMENT_METHOD } from './actionTypes';

import { CheckoutSummaryAction, CheckoutSummaryState } from './reducer';

const setCheckoutSummary = (
  summary: CheckoutSummaryState
): CheckoutSummaryAction => {
  return {
    type: CS_SET_SUMMARY,
    summary,
  };
};

const setCheckoutSummaryPaymentMethod = (
  paymentMethod: string
): CheckoutSummaryAction => {
  return {
    type: CS_SET_PAYMENT_METHOD,
    summary: {
      delivered: {},
      paymentMethod,
      subtotal: 0,
      orderNumber: '',
    },
  };
};

export { setCheckoutSummary, setCheckoutSummaryPaymentMethod };
