import { useQuery } from 'react-query';

import { IGetPharmacistPaymentMethodsResponse } from 'rx-domain';
import { http } from 'rx-api/http/repository';

type IRequest = {
  id: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetPharmacyPaymentMethods = ({ id }: IRequest) => {
  const onRequest = async (req: IRequest) => {
    return http.get<IGetPharmacistPaymentMethodsResponse>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/pharmacies/${req.id}/payment-methods`,
      {
        withCredentials: true,
      }
    );
  };

  return useQuery(['get-admin-pharmacists-payment-methods', id], () =>
    onRequest({ id })
  );
};
