import { useParams } from 'react-router-dom';
import { useGetAdminBannedProduct, usePutAdminBannedProductsId } from 'rx-api';
import { useMemo } from 'react';
import { isEmpty } from 'ramda';
import { IAddBannedProductForm } from 'rx-domain';
import { useToast } from 'rx-hooks/utils';

export const useAdminGetBannedProduct = () => {
  const { onSuccessToast, onErrorToast } = useToast();
  const { id } = useParams<{ id?: string }>();

  const { isLoading, data } = useGetAdminBannedProduct(
    id ? parseInt(id ?? '0', 10) : 0
  ) as any;

  const updateProduct = usePutAdminBannedProductsId();

  const product = data === undefined ? {} : data.data;

  const initialValues = useMemo(() => {
    if (!isEmpty(data)) {
      return {
        name: product?.name ?? '',
        ndc: product.ndc ?? '',
        id: product.id ?? 0,
        description: product.description ?? '',
      } as IAddBannedProductForm;
    }

    return {
      ndc: '',
      name: '',
      id: 0,
      description: '',
    } as IAddBannedProductForm;
  }, [data, product.description, product.name, product.ndc, product.id]);

  const onSubmit = async (newValues: any) => {
    try {
      await updateProduct.mutateAsync(newValues);
      onSuccessToast('Product saved!');
    } catch (err) {
      onErrorToast('Could not save the product!');
    }
  };

  return {
    initialValues,
    isLoading,
    onSubmit,
  };
};
