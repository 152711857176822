import { useRxMutation } from 'rx-api/utils';
import { ADMIN_URL_V1 } from '../constants';

type IPostProductPricingRequestPayloadDto = {
  minAmount: number;
  maxAmount: number;
  price: string;
  logicalOperator: string;
};

const POST_PRODUCT_PRICING_TIER_ADMIN_URL = (id: number | string) =>
  `${ADMIN_URL_V1}/products/${id}/pricing-tiers`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePostProductPricingTierAdmin = (id: number | string) => {
  return useRxMutation<IPostProductPricingRequestPayloadDto, unknown>({
    method: 'post',
    url: POST_PRODUCT_PRICING_TIER_ADMIN_URL(id),
  });
};
