import { useGetRequest } from 'rx-api/utils';
import {
  GET_SELLER_SHIPPING_CONFIGURATION_URL,
  GET_SELLER_SHIPPING_CONFIGURATION_KEY,
} from './constants';

export interface IGetManufacturerShippingConfigurationResponseDto {
  overnightShipping: number;
  groundShipping: number;
  minimumOrderAmount: number;
  shippingConstraints: string;
}

export const useGetManufacturerShippingPreferences = () => {
  return useGetRequest<
    unknown,
    IGetManufacturerShippingConfigurationResponseDto
  >({
    url: GET_SELLER_SHIPPING_CONFIGURATION_URL,
    key: GET_SELLER_SHIPPING_CONFIGURATION_KEY,
  });
};
