import {
  GetInventory,
  PostInventoryBulk,
  PutInventoryItem,
  PostSingleProduct,
} from 'rx-domain';
import {
  convertUrlWithQuery,
  createUrlWithParams,
  makeArrayReactQueryKey,
  useGetRequest,
  useRxMutation,
  useRxFormDataMutation,
} from 'rx-api/utils';
import {
  SELLER_URL_V2,
  SELLER_URL_V3,
} from 'rx-api/api/manufacturer/constants';

export const GET_INVENTORY_PRODUCTS_URL = `${SELLER_URL_V2}/inventory/products`;
export const GET_INVENTORY_PRODUCTS_BULK_URL = `${SELLER_URL_V3}/inventory/products/bulk`;
export const POST_SINGLE_PRODUCT_URL = `${SELLER_URL_V2}/inventory/products`;
export const PUT_INVENTORY_PRODUCT_URL = `${SELLER_URL_V2}/inventory/products/{id}`;

export const GET_INVENTORY_PRODUCTS_KEY = 'GET_INVENTORY_PRODUCTS_KEY';

export const useGetInventory = (query: Partial<GetInventory.Query>) => {
  return useGetRequest<Partial<GetInventory.Query>, GetInventory.Response>({
    url: convertUrlWithQuery(
      { ...query, limit: 10 },
      GET_INVENTORY_PRODUCTS_URL
    ),
    key: makeArrayReactQueryKey(GET_INVENTORY_PRODUCTS_KEY, query),
  });
};

export const usePostInventoryBulk = () => {
  return useRxMutation<PostInventoryBulk.DTO, unknown>({
    method: 'post',
    url: GET_INVENTORY_PRODUCTS_BULK_URL,
  });
};

export const usePutInventoryItem = (params: PutInventoryItem.Params) => {
  return useRxMutation<PutInventoryItem.DTO, unknown>({
    method: 'put',
    url: createUrlWithParams(PUT_INVENTORY_PRODUCT_URL, {
      key: 'id',
      value: params.id.toString(),
    }),
  });
};

export const usePostSingleProduct = () => {
  return useRxFormDataMutation<PostSingleProduct.DTO, unknown>({
    method: 'post',
    url: POST_SINGLE_PRODUCT_URL,
  });
};

export { useAddInventoryProductPricingTier } from './useAddInventoryProductPricingTier';
export { useDeleteInventoryProductPricingTier } from './useDeleteInventoryProductPricingTier';
export { useDeleteProductFromInventory } from './useDeleteProductFromInventory';
export { useGetInventoryProductPricingTiers } from './useGetInventoryProductPricingTiers';
export { useGetProductFromInventory } from './useGetProductFromInventory';
export { useUpdateProductById } from './useUpdateProductById';
