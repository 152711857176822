import React from 'react';
import styled from 'styled-components';

import { FilterProvider } from 'rx-contexts';

import { RxTableTabs, SEO, TemplateView } from 'rx-core';

import { MOTab } from './MOTab';
import { MOFilter } from './MOFilter';
import { MOActions } from './MOActions';

const Container = styled.div`
  padding: 1.5rem;
`;

const ManageOrdersView = (): JSX.Element => {
  return (
    <TemplateView>
      <SEO title="RxRise | Manage Orders" />
      <FilterProvider>
        <Container>
          <MOActions />
          <RxTableTabs
            items={[
              {
                active: true,
                title: 'All orders',
                onSetView: () => {},
                value: 'ALL_MO',
              },
            ]}
            filterComp={<MOFilter />}
          >
            <MOTab />
          </RxTableTabs>
        </Container>
      </FilterProvider>
    </TemplateView>
  );
};

export { ManageOrdersView };
