import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const DownloadButton = styled.button`
  ${({ theme }) => css`
    height: 3rem;
    padding: 0 1.5rem;
    color: ${theme.colors.white[100]};
    font-size: ${theme.sizes.medium};
    border-radius: 10px;
    border: none;
    background-color: ${theme.colors.blue[300]};
    margin-right: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoRegular};
    padding-left: 1rem;
  `}
`;
