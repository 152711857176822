import React from 'react';

import { GetOrders } from 'rx-domain';

import { formatMoney } from 'rx-utils';
import {
  Container,
  RowContainer,
  RowItemContainer,
  HeaderContainer,
} from './index.styles';

type IProps = {
  items: GetOrders.OrderItem[];
};

const OrderItemContent = (props: IProps): JSX.Element => {
  return (
    <Container>
      <HeaderContainer>
        <RowItemContainer width={9 / 12} header>
          ITEM
        </RowItemContainer>
        <RowItemContainer width={1 / 12} header>
          PRICE
        </RowItemContainer>
        <RowItemContainer width={2 / 12} header>
          AMOUNT ORDERED
        </RowItemContainer>
        <RowItemContainer width={1 / 12} header>
          TOTAL
        </RowItemContainer>
      </HeaderContainer>
      {props.items.map((item) => (
        <RowContainer key={item.id}>
          <RowItemContainer width={9 / 12}>{item.line}</RowItemContainer>
          <RowItemContainer width={1 / 12}>
            {formatMoney(item.price)}
          </RowItemContainer>
          <RowItemContainer width={2 / 12}>{item.quantity}</RowItemContainer>
          <RowItemContainer width={1 / 12}>
            {formatMoney(item.price * item.quantity)}
          </RowItemContainer>
        </RowContainer>
      ))}
    </Container>
  );
};

export { OrderItemContent };
