import React from 'react';

import {
  ButtonContainer,
  Container,
  Content,
  Title,
  CancelButton,
} from './index.styles';

type IProps = {
  onClose: () => void;
};

export const PurchaseOrderRequestFullfillSuccess: React.FC<IProps> = ({
  onClose,
}) => {
  return (
    <Container>
      <Title>Order fulfilled!</Title>
      <Content>Order has been fulfilled!</Content>
      <br />
      <Content>
        Your client will receive and email with the tracking code so they can
        track your products.
      </Content>
      <ButtonContainer>
        <CancelButton onClick={onClose} variant="outline">
          Close
        </CancelButton>
      </ButtonContainer>
    </Container>
  );
};
