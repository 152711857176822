import React from 'react';
import styled, { css } from 'styled-components';

import { FieldArrayRenderProps, FormikProps } from 'formik';

import { IProductForm, IProductsForm } from 'rx-domain';

import { ProductArrayItemForm } from '../ProductArrayItemForm';

export type ProductArrayFormProps = FieldArrayRenderProps & {
  form: FormikProps<IProductsForm>;
  initialValues: IProductsForm;
  onCancel: () => void;
  type: 'edit' | 'create';
  mode: 'edition' | 'normal';
  onDeleteDB: (index: number) => void;
};

const Container = styled.tbody``;

const TableFooter = styled.tfoot`
  border: none;
  width: 100%;
  text-align: end;
`;

const AddItemButton = styled.button`
  ${({ theme }) => css`
    background: transparent;
    border: none;
    font-size: 16px;
    color: ${theme.colors.blue[100]};
    cursor: pointer;
  `}
`;

const Spacer = styled.tr`
  height: 8px;
`;

const FooterSpacer = styled.tr`
  height: 16px;
`;

const ProductArrayForm = ({
  initialValues,
  form: { values },
  onDeleteDB,
  remove,
  push,
  type,
  mode,
}: ProductArrayFormProps): JSX.Element => {
  return (
    <>
      <Container>
        {values.products.length > 0 &&
          values.products.map((product: IProductForm, index: number) => (
            <React.Fragment key={index}>
              <ProductArrayItemForm
                key={index}
                index={index}
                item={product}
                remove={remove}
                type={type}
                mode={mode}
                onDeleteDB={onDeleteDB}
              />
              <Spacer />
            </React.Fragment>
          ))}
      </Container>
      <TableFooter>
        <FooterSpacer />
        {type === 'create' && (
          <tr>
            <td colSpan={14}>
              <AddItemButton
                type="button"
                onClick={() => push({ ...initialValues.products[0] })}
              >
                + Add item
              </AddItemButton>
            </td>
          </tr>
        )}
      </TableFooter>
    </>
  );
};

ProductArrayForm.defaultProps = {
  onDeleteDB: () => {},
  mode: 'normal',
};

export { ProductArrayForm };
