import { PutAdminProductData } from 'rx-domain';
import { PUT_ADMIN_PRODUCT_DATA_URL } from 'rx-api/api/admin/products/constants';
import { useRxMutation } from 'rx-api/utils';

export const usePutAdminProductData = (params: PutAdminProductData.Params) => {
  return useRxMutation<PutAdminProductData.DTO, unknown>({
    method: 'put',
    url: PUT_ADMIN_PRODUCT_DATA_URL.replace('{id}', params.id.toString()),
  });
};
