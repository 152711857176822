/* eslint-disable no-nested-ternary */
import React, { memo, useMemo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import checkIcon from 'assets/icons/check-icon.svg';
import RxRiseLogo from 'assets/logos/rxrise-gray-logo.svg';
import { GetAdminProducts } from 'rx-domain';
import { formatDate, formatMoney, formatQuantity } from 'rx-utils';
import { useAdminProducts } from 'rx-contexts';
import { usePostFeaturedProduct, GET_ADMIN_PRODUCTS_KEY } from 'rx-api';
import { useQuery, useToast } from 'rx-hooks';
import { TransactionsOrderMenu } from '../../transactions-legacy/molecules/TransactionsOrderMenu';

type IProps = GetAdminProducts.ProductItem & {
  index: number;
};

export const AdminProductsDataItem = memo((props: IProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const history = useHistory();
  const { onSuccessToast } = useToast();
  const queryClient = useQueryClient();
  const { isSelected, onModalDelete, onSetDeleteProduct } = useAdminProducts();
  const postFeaturedProduct = usePostFeaturedProduct(props.id);
  const query = useQuery();
  const category = query.get('category') || 'drug';
  const isDrug = category === 'drug';

  const checkedId = `products.${props.id}.selected`;

  const onOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onEditMode = useCallback(() => {
    history.push(`/products/${props.id}`);
  }, [history, props]);

  const onEditPicture = useCallback(() => {
    history.push(`/products/picture/${props.id}`);
  }, [history, props]);

  const onClickFeatureProduct = useCallback(async () => {
    try {
      await postFeaturedProduct.mutateAsync({}, {});
      await queryClient.refetchQueries(GET_ADMIN_PRODUCTS_KEY, {
        active: true,
      });

      onSuccessToast('Changes saved succesfully 🚀');
    } catch (err) {
      window.console.log(err);
    }
  }, [onSuccessToast, postFeaturedProduct, queryClient]);

  const onDeleteProduct = useCallback(() => {
    onSetDeleteProduct(props);
    onModalDelete();
  }, [onModalDelete, onSetDeleteProduct, props]);

  const viewModeMenuOptions = useMemo(() => {
    return [
      {
        onClick: onEditMode,
        text: 'Edit product',
        type: 'EDIT',
      },
      {
        onClick: onEditPicture,
        text: 'Replace product image',
        type: 'EDIT_IMAGE',
      },
      {
        onClick: onClickFeatureProduct,
        text: !props.isFeatured
          ? 'Set as "featured"'
          : 'Remove from "featured"',
        type: 'FEATURE_PRODUCT',
      },
      {
        onClick: onDeleteProduct,
        text: 'Delete product',
        type: 'DELETE',
      },
    ];
  }, [
    onClickFeatureProduct,
    onDeleteProduct,
    onEditMode,
    onEditPicture,
    props.isFeatured,
  ]);

  return (
    <>
      <TableRow
        style={{
          backgroundColor: props.isFeatured
            ? '#f4eed4'
            : isSelected(checkedId)
            ? '#eff3ff'
            : '#fff',
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell colSpan={3}>{props.ndc}</TableCell>
        <TableCell colSpan={3}>{props.name}</TableCell>
        <TableCell colSpan={3}>{props.description}</TableCell>
        <TableCell colSpan={3}>{formatDate(props.expirationDate)}</TableCell>
        <TableCell>{formatMoney(props.price)}</TableCell>
        <TableCell>{formatQuantity(props.units)}</TableCell>
        <TableCell>
          {props.isFeatured ? <img src={checkIcon} alt="" /> : ''}
        </TableCell>

        <TableCell>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={onOpenMenu}
          >
            <MoreVertIcon />
          </IconButton>
          <TransactionsOrderMenu
            anchorEl={anchorEl}
            onClose={onClose}
            options={viewModeMenuOptions}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={17}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="subtitle1">Other Information</Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Lot Number</TableCell>
                    <TableCell>Manufacturer</TableCell>
                    <TableCell>Minimum Quantity</TableCell>
                    {isDrug && <TableCell>Is VAWD</TableCell>}
                    <TableCell>Ground Shipping</TableCell>
                    <TableCell>Overnight Shipping</TableCell>
                    <TableCell>Shipping Constraints</TableCell>
                    <TableCell>Shipping Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{props.lotNumber}</TableCell>
                    <TableCell>{props.manufacturer}</TableCell>
                    <TableCell>{props.minimumQuantity}</TableCell>
                    {isDrug && (
                      <TableCell>{props.isVawd ? 'Yes' : 'No'}</TableCell>
                    )}
                    <TableCell>{formatMoney(props.groundShipping)}</TableCell>
                    <TableCell>
                      {formatMoney(props.overnightShipping)}
                    </TableCell>
                    <TableCell>{props.shippingConstraints}</TableCell>
                    <TableCell>{props.shippingName}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
});
