import { useMutation } from 'react-query';
import { GetSaleOrderPayments } from 'rx-domain';
import { GET_SALE_ORDER_PAYMENTS_URL } from 'rx-api/api/admin/sale-order-payments/constants';
import { http } from 'rx-api/http/repository';

const makeParams = (req: Partial<GetSaleOrderPayments.Query>) => {
  let query = `?limit=10`;

  if (req.limit) {
    query = `?limit=${req.limit}`;
  }

  if (req.skip) {
    query += `&skip=${req.skip}`;
  }

  if (req.sellerId) {
    query += `&sellerId=${req.sellerId}`;
  }

  if (req.sortBy) {
    query += `&sortBy=${req.sortBy}`;
  }

  if (req.sortOrder) {
    query += `&sortOrder=${req.sortOrder}`;
  }

  if (req.status) {
    query += `&status=${req.status}`;
  }

  return query;
};

export const useDownloadSaleOrderPayments = () => {
  const onRequest = (query: Partial<GetSaleOrderPayments.Query>) => {
    return http.get(`${GET_SALE_ORDER_PAYMENTS_URL}${makeParams(query)}`, {
      withCredentials: true,
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
      },
    });
  };

  return useMutation(onRequest, {});
};
