import React, { useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Paper, TableBody } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { upperFirst } from 'lodash';
import { useGetShoppingCartLogs } from 'rx-api/api/admin/logs/useGetShoppingCartLogs';

import { ShoppingCartTableLoader } from './ShoppingCartTableLoader';
import { ShoppingCartTableEmpty } from './ShoppingCartTableEmpty';
import { useStyles } from './useStyles';

interface Data {
  id: string;
  name: string;
  description: string;
  email: string;
  createdOn: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  sortable: boolean;
}

const EDICells: HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
    sortable: false,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    sortable: false,
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    sortable: false,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    sortable: false,
  },
  {
    id: 'createdOn',
    numeric: false,
    disablePadding: false,
    label: 'Created on',
    sortable: false,
  },
];

export const ShoppingCartTableContainer: React.FC = () => {
  const [skip, setSkip] = useState<number>(0);
  const [currentPage, setCurrentPAge] = useState<number>(0);
  const [limit] = useState<number>(20);

  const classes = useStyles();

  const { data: logsData, isLoading } = useGetShoppingCartLogs({
    limit,
    skip,
  });

  return (
    <Paper>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHead className={classes.tableHead}>
            <TableRow>
              {EDICells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                >
                  <>{headCell.label}</>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <ShoppingCartTableLoader />}

            {!isLoading && !logsData?.data?.results.length && (
              <ShoppingCartTableEmpty />
            )}

            {!isLoading &&
              logsData?.data?.results &&
              logsData?.data?.results.map((log) => {
                return (
                  <TableRow key={log.id}>
                    <TableCell>{log.id}</TableCell>
                    <TableCell>
                      {upperFirst(log.name.split('_').join(' '))}
                    </TableCell>
                    <TableCell>{log.description}</TableCell>
                    <TableCell>{log.email}</TableCell>
                    <TableCell>
                      {new Date(log.createdAt).toUTCString()}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[15]}
        count={logsData?.data?.pagination?.total || 0}
        rowsPerPage={limit}
        page={currentPage}
        onPageChange={(event, page) => {
          setCurrentPAge(page);
          setSkip(page * limit);
        }}
        onRowsPerPageChange={() => {}}
      />
    </Paper>
  );
};
