import {
  Modal,
  Backdrop,
  Fade,
  FormControl,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useGetSellers } from 'rx-api';
import { IGetManufacturer } from 'rx-domain';

type AdminProductsEDIModalProps = {
  open?: boolean;
  handleOnClose?: (value: boolean) => void | null;
};

const Title = styled.h2`
  font-size: ${({ theme: { sizes } }) => `${sizes.h2}`};
  font-family: ${({ theme: { fonts } }) => `${fonts.robotoLight}`};

  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgb(43, 54, 71, 0.35);
  width: 100%;
`;

const SelectStyled = styled(Select)`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50%;
`;

export const AdminProductsEDIModal = ({
  open = true,
  handleOnClose,
}: AdminProductsEDIModalProps) => {
  const [options, setOptions] = useState<IGetManufacturer[]>([]);
  const [value, setValue] = useState('');

  const { data: adminManufacturers, isLoading } = useGetSellers({
    limit: 1000,
    approvalStatus: 'approved',
    skip: 0,
  });
  useEffect(() => {
    if (adminManufacturers?.data) {
      setOptions(
        adminManufacturers.data.results.filter(
          (item) => item?.ediSettings?.ediPartnerId
        )
      );
    }
  }, [adminManufacturers]);

  return (
    <Modal
      open={open}
      onClose={() => handleOnClose && handleOnClose(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: '',
            backgroundColor: 'white',
            margin: 'auto',
            padding: 20,
            outline: 'none',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: '57.5rem',
            maxHeight: '20rem',
            height: 'fit-content',
          }}
        >
          <Title>Select the inventory to be pulled from EDI</Title>
          <FormControl variant="outlined">
            <SelectStyled
              value={value}
              onChange={(e) => {
                setValue(e.target.value as string);
              }}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="" disabled>
                Select a manufacturer
              </MenuItem>
              {options.map((option) => (
                <MenuItem value={option.id}>
                  {option.manufacturer.companyName}
                </MenuItem>
              ))}
            </SelectStyled>
          </FormControl>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: '0.5rem',
              borderTop: '1px solid rgb(43, 54, 71, 0.35)',
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleOnClose && handleOnClose(false)}
            >
              Cancel
            </Button>
            <Button
              style={{
                marginLeft: '10px',
              }}
              variant="contained"
              color="primary"
              onClick={() => handleOnClose && handleOnClose(false)}
              disabled={value === ''}
            >
              Pull Inventory
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
