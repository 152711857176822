import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import { Paper } from '@material-ui/core';
import { Content } from '../index.styles';

export const PendingTaskListLoader: React.FC = () => {
  return (
    <>
      <Paper elevation={1} variant="outlined">
        <Content>
          <Skeleton variant="rect" height={20} />
          <div style={{ marginTop: '1rem' }} />
          <Skeleton variant="rect" height={40} />
        </Content>
      </Paper>

      <Paper style={{ marginTop: '1rem' }} elevation={1} variant="outlined">
        <Content>
          <Skeleton variant="rect" height={20} />
          <div style={{ marginTop: '1rem' }} />
          <Skeleton variant="rect" height={40} />
        </Content>
      </Paper>
    </>
  );
};
