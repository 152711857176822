import { Button, Grid, Paper, TextField } from '@material-ui/core';
import React from 'react';

type IAddBannedProductFormProps = {
  onChange(e: React.ChangeEvent<any>): void;
  onClickCancel(e: React.ChangeEvent<any>): void;
  errors: {
    ndc?: string;
    name?: string;
    description?: string;
  };
  values: {
    ndc?: string;
    name?: string;
    description?: string;
  };
};

const AdminAddBannedProductForm: React.FC<IAddBannedProductFormProps> = ({
  onChange,
  values,
  errors,
  onClickCancel,
}) => {
  return (
    <Paper variant="outlined" style={{ padding: '1rem', marginBottom: '1rem' }}>
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <TextField
            name="ndc"
            id="ndc"
            label="NDC *"
            variant="outlined"
            onChange={onChange}
            value={values.ndc}
            fullWidth={true}
            error={(errors?.ndc?.length || 0) > 0}
            helperText={errors.ndc}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="name"
            id="name"
            label="NAME *"
            variant="outlined"
            onChange={onChange}
            value={values.name}
            error={(errors?.name?.length || 0) > 0}
            helperText={errors.name}
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <TextField
            name="description"
            id="description"
            label="DESCRIPTION"
            variant="outlined"
            onChange={onChange}
            value={values.description}
            error={(errors?.description?.length || 0) > 0}
            helperText={errors.description}
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        style={{ marginTop: '1rem' }}
      >
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: '1rem' }}
            onClick={onClickCancel}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            disabled={false}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export { AdminAddBannedProductForm };
