import { useMutation } from 'react-query';
import {
  IPostAuthLogin,
  IPostAuthLoginDTO,
  IPostAuthLogin2FA,
} from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePostAuthLogin = () => {
  return useMutation(async (request: IPostAuthLoginDTO) => {
    return http.post<IPostAuthLogin & IPostAuthLogin2FA>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/auth/login`,
      request
    );
  }, {});
};
