import { useQuery } from 'react-query';
import { IGetPharmacistsDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAdminGetPharmacists = ({ skip }: { skip: number }) => {
  const onRequest = async (req: { skip: number }) => {
    return http.get<IGetPharmacistsDTO>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/pharmacists?approvalStatus=pending&isRequest=true&skip=${req.skip}`,
      {
        withCredentials: true,
      }
    );
  };

  return useQuery(['get-pharmacies', skip], () => onRequest({ skip }));
};
