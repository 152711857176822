import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Paper, Typography } from '@material-ui/core';

import { getWACSubtotal, getValidateItems } from 'rx-store';

import { formatMoney } from 'rx-utils';

import { Container } from './index.styles';

type ICheckoutPlaceOrderProps = {
  onPlaceOrder: () => void;
  validate: boolean;
  loading: boolean;
};

export const CheckoutPlaceOrder: React.FC<ICheckoutPlaceOrderProps> = (
  props
) => {
  const fullTotalOrder = useSelector(getWACSubtotal);
  const validShopItems = useSelector(getValidateItems);

  return (
    <Paper variant="elevation" style={{ marginTop: '1rem' }}>
      <Container>
        <Typography style={{ marginRight: '1rem' }}>
          Total of order: <b>{formatMoney(fullTotalOrder)}</b>
        </Typography>
        <Link to="/shopping-cart">
          <Button color="primary" variant="outlined" data-cy="checkout-btn">
            Edit Cart
          </Button>
        </Link>
        <Button
          disabled={!validShopItems || !props.validate || props.loading}
          style={{ marginLeft: '20px' }}
          onClick={() => props.onPlaceOrder()}
          color="primary"
          variant="contained"
          data-cy="checkout-btn"
        >
          Place order
        </Button>
      </Container>
    </Paper>
  );
};
