import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { ButtonBase } from '../ButtonBase';
import { ButtonIcon } from '../ButtonIcon';
import { ButtonText } from '../ButtonText';
import { ButtonShape } from '../ButtonShape';

export type ButtonPrimaryProps = HTMLAttributes<HTMLDivElement> & {
  text: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 191px;
  cursor: pointer;
`;

export const InnerButtonContainer = styled.div`
  width: 132px;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ButtonPrimary = ({
  text,
  onClick,
  ...props
}: ButtonPrimaryProps): JSX.Element => {
  return (
    <Container onClick={onClick} {...props}>
      <ButtonBase type="submit">
        <InnerButtonContainer>
          <ButtonText>{text}</ButtonText>
        </InnerButtonContainer>
      </ButtonBase>
      <ButtonIcon />
      <ButtonShape up />
      <ButtonShape />
    </Container>
  );
};

export { ButtonPrimary };
