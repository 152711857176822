import React, { ImgHTMLAttributes, forwardRef } from 'react';
import styled from 'styled-components';

type HeaderNormalActionProps = ImgHTMLAttributes<HTMLImageElement> & {
  numberNotifications?: number;
  className?: string;
};

const Icon = styled.img`
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
`;

const Notification = styled.div`
  height: 14px;
  width: 14px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: absolute;
  background-color: red;
  border-radius: 100%;

  top: 0;
  right: 40%;
`;

const NotificationFont = styled.div`
  font-size: 10px;
  color: white;
`;

const HeaderNormalAction = forwardRef<HTMLDivElement, HeaderNormalActionProps>(
  (
    { numberNotifications, ...props }: HeaderNormalActionProps,
    ref
  ): JSX.Element => {
    return (
      <Container ref={ref}>
        <Icon {...props} />
        {numberNotifications && numberNotifications > 0 ? (
          <Notification>
            <NotificationFont>{numberNotifications}</NotificationFont>
          </Notification>
        ) : null}
      </Container>
    );
  }
);

HeaderNormalAction.defaultProps = {
  numberNotifications: 0,
  className: '',
};

export { HeaderNormalAction };
