import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

const SELLER_DELETE_PRODUCT_FROM_INVENTORY_URL = (id: string) =>
  `${process.env.REACT_APP_WEBSITE_URL}/v1/inventory/products/${id}`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDeleteProductFromInventory = () => {
  const onRequest = (id: string) => {
    return http.delete(SELLER_DELETE_PRODUCT_FROM_INVENTORY_URL(id));
  };

  return useMutation(onRequest);
};
