import * as Yup from 'yup';

const PaymentTermsSchema = Yup.object().shape({
  minimumAmount: Yup.number()
    .transform((_: string, originalValue: string) => {
      const value = parseFloat(
        originalValue.toString().replace(/,+/g, '').replace('$', '').trim()
      );

      return Number.isNaN(value) ? 0 : value;
    })
    .min(1)
    .required('Please enter the minimum amount'),
});

export { PaymentTermsSchema };
