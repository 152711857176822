import React from 'react';
import axios from 'axios';
import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { GetOrders } from 'rx-domain';
import {
  useDownloadPurchaseOrderInvoice,
  useDownloadPurchaseOrderReceipt,
} from 'rx-api';
import { useDownloadFile, useToast } from 'rx-hooks';
import { formatMoney, getDateValue } from 'rx-utils';

import { TransactionPaymentStatus } from 'pages/admin/transactions/TransactionPaymentStatus';
import { TransactionShippingStatus } from 'pages/admin/transactions/TransactionShippingStatus';
import { Container, Column, TagContainer } from './index.styles';

type IProps = {
  item: GetOrders.Order;
};

export const OrderItemHeader: React.FC<IProps> = (props) => {
  const toast = useToast();
  const { onDownload } = useDownloadFile();

  const downloadInvoice = useDownloadPurchaseOrderInvoice(props.item.id);
  const downloadReceipt = useDownloadPurchaseOrderReceipt(props.item.id);

  const onDownloadInvoice = async () => {
    try {
      const { data } = await downloadInvoice.mutateAsync();
      onDownload(
        data,
        props.item.isRequest
          ? `PO-${props.item.id}-invoice`
          : `PO-${props.item.orderId}-${props.item.id}-invoice`,
        'pdf',
        {
          type: 'application/pdf',
        }
      );
    } catch (error) {
      toast.onErrorToast('Could not download invoice');
    }
  };

  const onDownloadReceipt = async () => {
    try {
      const { data } = await downloadReceipt.mutateAsync();
      onDownload(
        data,
        props.item.isRequest
          ? `PO-${props.item.id}-receipt`
          : `PO-${props.item.orderId}-${props.item.id}-receipt`,
        'pdf',
        { type: 'application/pdf' }
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const resp = new TextDecoder().decode(error?.response?.data);
        if (JSON.parse(resp)?.message === 'Order has not been paid') {
          toast.onErrorToast(
            'Cannot download receipt. Order has not been paid yet.'
          );
          return;
        }
      }

      toast.onErrorToast('Could not download receipt');
    }
  };

  return (
    <>
      <Container>
        <Column width={4 / 12}>
          <Typography>
            Purchase Order:{' '}
            <strong>
              {props.item.poPrefix}-{props.item.id}
            </strong>
          </Typography>
          <div className="order-download-links">
            <span onClick={onDownloadInvoice}>Download Invoice</span> |{' '}
            <span onClick={onDownloadReceipt}>Download Receipt</span>
          </div>
        </Column>
        <Column width={2 / 12}>
          <Typography>
            <strong>Order made in</strong>
          </Typography>
          <p>{getDateValue(props.item.createdAt)}</p>
        </Column>
        <Column width={2 / 12}>
          <Typography>
            <strong>Order total</strong>
          </Typography>
          <p>{formatMoney(props.item.subtotal)}</p>
        </Column>
        <Column width={2 / 12}>
          <Typography>
            <strong>Shipped To:</strong>
          </Typography>
          <Typography>{props.item.pharmacyName}</Typography>
        </Column>

        <Column width={3 / 12}>
          <Link
            to={`/orders/${props.item.id}`}
            style={{ textDecoration: 'none' }}
          >
            <Button variant="contained" color="primary">
              View order details
            </Button>
          </Link>
        </Column>
      </Container>

      <TagContainer>
        <Column width={2 / 12}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '0.5rem' }}>
              <TransactionPaymentStatus status={props.item.paymentStatus} />
            </div>
            <TransactionShippingStatus status={props.item.status} />
          </div>
        </Column>
      </TagContainer>
    </>
  );
};
