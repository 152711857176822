import React, { useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Paper, TableBody } from '@material-ui/core';
import { capitalize, getDateValue } from 'rx-utils';
import TablePagination from '@material-ui/core/TablePagination';

import { useGetLoginLogs } from '../../../../rx-api';
import { useStyles } from './useStyles';

interface Data {
  username: string;
  date: string;
  accountType: string;
  location: string;
  createdOn: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  sortable: boolean;
}

const LoginCells: HeadCell[] = [
  {
    id: 'username',
    numeric: false,
    disablePadding: false,
    label: 'Email/Company',
    sortable: false,
  },
  {
    id: 'accountType',
    numeric: false,
    disablePadding: false,
    label: 'Account Type',
    sortable: false,
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    label: 'Location',
    sortable: false,
  },
  {
    id: 'createdOn',
    numeric: false,
    disablePadding: false,
    label: 'Created on',
    sortable: false,
  },
];

export const UserLoginLogs: React.FC = () => {
  const [skip, setSkip] = useState<number>(0);
  const [sortOrder] = useState('desc');
  const [searchFilterByType] = useState('');
  const [searchFilterByEmail] = useState('');
  const [currentPage, setCurrentPAge] = useState<number>(0);
  const [limit] = useState<number>(20);

  const classes = useStyles();

  const { data: logsData } = useGetLoginLogs({
    skip,
    sortBy: 'createdAt',
    sortOrder,
    accountType: searchFilterByType,
    email: searchFilterByEmail,
  });

  return (
    <Paper>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHead className={classes.tableHead}>
            <TableRow>
              {LoginCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                >
                  <>{headCell.label}</>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {logsData?.data?.results &&
              logsData?.data?.results.map((log) => {
                return (
                  <TableRow key={log.id}>
                    <TableCell>{log.email}</TableCell>
                    <TableCell>{capitalize(log.accountType)}</TableCell>
                    <TableCell>{log.location}</TableCell>
                    <TableCell>{getDateValue(log.createdAt)}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[15]}
        count={logsData?.data?.pagination?.total || 0}
        rowsPerPage={limit}
        page={currentPage}
        onPageChange={(event, page) => {
          setCurrentPAge(page);
          setSkip(page * limit);
        }}
        onRowsPerPageChange={() => {}}
      />
    </Paper>
  );
};
