import React, { HTMLAttributes } from 'react';
import { LayoutProps } from 'styled-system';
import { AnimatePresence } from 'framer-motion';

import { theme, Colors } from 'rx-styles';

import { ButtonLoadingIcon } from '../ButtonLoadingIcon/ButtonLoadingIcon';

import { Container, Button, LoadingContainer, Text } from './indext.styles';

export type RxButtonProps = HTMLAttributes<HTMLDivElement> &
  LayoutProps & {
    text: string;
    color?: Colors;
    textColor?: Colors;
    fontSize?: string;
    secondary?: boolean;
    loading: boolean;
    type: 'submit' | 'reset' | 'button' | undefined;
    disabled: boolean | undefined;
  };

const RxButton = ({
  type,
  text,
  color,
  loading,
  onClick,
  disabled,
  fontSize,
  textColor,
  secondary,
  ...props
}: RxButtonProps): JSX.Element => {
  return (
    <Container onClick={disabled || loading ? () => {} : onClick} {...props}>
      <Button
        type={type}
        color={disabled || loading ? '#9FA6B9' : color}
        disabled={disabled || loading}
        secondary={secondary || false}
      >
        {!loading && (
          <Text
            textColor={secondary ? '#5077F2' : textColor}
            fontSize={fontSize}
          >
            {text}
          </Text>
        )}
        <AnimatePresence>
          {loading && (
            <LoadingContainer
              animate={{ rotate: 360 }}
              transition={{ duration: 2, repeat: Infinity }}
            >
              <ButtonLoadingIcon />
            </LoadingContainer>
          )}
        </AnimatePresence>
      </Button>
    </Container>
  );
};

RxButton.defaultProps = {
  color: theme.colors.blue.gradient,
  textColor: theme.colors.white[100],
  fontSize: '18px',
  secondary: false,
  type: 'submit',
  disabled: undefined,
  loading: false,
};

export { RxButton };
