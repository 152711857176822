import React from 'react';
import { Button, Grid, TextField } from '@material-ui/core';

export interface IPharmacyLegalDocumentsEditFormProps {
  loading: boolean;
  onSubmit: (e: any) => void;
  onChangeDea: (e: any) => void;
  onChangeStateLicense: (e: any) => void;
  onClickCancel: (e: any) => void;
}

export const PharmacyLegalDocumentsEditStateForm = (
  props: IPharmacyLegalDocumentsEditFormProps
): JSX.Element => {
  return (
    <form onSubmit={props.onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="State License File(PDF/JPG/PNG)"
            id="stateLicense"
            name="stateLicense"
            type="file"
            required
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={props.onChangeStateLicense}
          />
        </Grid>

        <Grid item>
          <Button
            style={{ marginTop: 16 }}
            variant="outlined"
            color="primary"
            disabled={props.loading}
            onClick={props.onClickCancel}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <Button
            style={{ marginTop: 16 }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={props.loading}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
