import { createSelector } from 'reselect';
import { IProductData } from 'rx-domain';
import { RootState } from 'rx-store/store';
import { getCheckoutItems } from '../shopping-cart/getters';

const getSelectedProduct = (
  state: RootState
): IProductData | null | undefined => state.currentInv.selectedProduct;

const getChooseAmountModalVisibility = (state: RootState): boolean =>
  state.currentInv.chooseAmountModalVisible;

const getProductQty = createSelector(
  [getSelectedProduct, getCheckoutItems],
  (product, products) => {
    const idProduct = product?.id ? parseInt(product.id, 10) : 0;

    return products.find((p) => p.id === idProduct)?.quantity;
  }
);

export { getSelectedProduct, getChooseAmountModalVisibility, getProductQty };
