import React from 'react';
import { Button, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { SearchQueryParams } from 'rx-domain';
import { useDownloadPharmacies } from 'rx-api';
import { useDownloadFile } from 'rx-hooks';

import { BuyerSearchFilter } from '../BuyerSearchFilter';

import { Container, ArrowDownwardIcon, Title, RowTitle } from './index.styles';

type IBuyerFiltersProps = {
  queries: SearchQueryParams;
  loading: boolean;
};

export const BuyerFilters: React.FC<IBuyerFiltersProps> = (props) => {
  const csvDownload = useDownloadPharmacies();
  const { onDownload } = useDownloadFile();

  const onDownloadCSV = async () => {
    try {
      const approvalStatus = props.queries.approvalStatus
        ? (props.queries.approvalStatus as 'approved' | 'pending' | 'rejected')
        : 'any';

      const { data } = await csvDownload.mutateAsync({
        approvalStatus,
      });

      onDownload(data, approvalStatus);
    } catch (err) {
      // do nothing
    }
  };

  return (
    <Container>
      <RowTitle>
        <Title>Pharmacies</Title>
        <div>
          <Link to="/pharmacies/new" style={{ textDecoration: 'none' }}>
            <Button variant="outlined" style={{ marginRight: '1rem' }}>
              Add pharmacist
            </Button>
          </Link>

          <Button variant="contained" color="primary" onClick={onDownloadCSV}>
            <ArrowDownwardIcon />
            Download CSV
          </Button>
        </div>
      </RowTitle>
      <Paper variant="outlined" style={{ padding: '1rem' }}>
        <BuyerSearchFilter loading={props.loading} />
      </Paper>
    </Container>
  );
};
