import styled from 'styled-components';

export const InfoTitle = styled.p`
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

export const InfoContent = styled.div``;

export const InfoContainer = styled.div`
  margin-bottom: 1rem;
`;
