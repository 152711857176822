import { useQuery } from 'react-query';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAdminGetPharmacies = () => {
  const onRequest = async () => {
    return http.get<any>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/pharmacies?approvalStatus=pending&isRequest=true`,
      { withCredentials: true }
    );
  };

  return useQuery(['get-pharmacies'], () => onRequest());
};
