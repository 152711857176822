import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { SearchQueryParams } from 'rx-domain';

import { useReportFilterBuyer } from 'rx-hooks';

import { onMakeURL } from 'rx-utils';

import { useStyles } from './ReportFilterBuyer.styles';

type BuyerPick = { title: string; value: number };

type IProps = {
  queries: SearchQueryParams;
  placeholder?: string | undefined;
  defaultValue?: BuyerPick;
};

export const ReportFilterBuyer: React.FC<IProps> = ({
  placeholder,
  defaultValue,
  queries,
}) => {
  const {
    value,
    open,
    options,
    isLoading,
    setOpen,
    setOptions,
    setSearch,
    setValue,
  } = useReportFilterBuyer();
  const classes = useStyles({ withValue: !!value });
  const history = useHistory();

  const allOptions = useMemo(() => {
    if (!defaultValue) return options;

    return [defaultValue, ...options];
  }, [defaultValue, options]);

  const onGetBuyer = (buyerPick: BuyerPick | null) => {
    if (!buyerPick) {
      onMakeURL({
        ...queries,
        buyer: '',
      });

      return;
    }

    const newUrl = onMakeURL({
      ...queries,
      buyer: buyerPick.title,
      buyerId: buyerPick.value,
    });

    history.push(newUrl);
  };

  return (
    <Autocomplete
      id="buyer-search"
      size="small"
      defaultValue={defaultValue}
      classes={classes}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, autocompleteValue) =>
        option.value === autocompleteValue.value
      }
      getOptionLabel={(option) => option.title}
      options={allOptions}
      loading={isLoading}
      value={value}
      filterOptions={(x) => x}
      onChange={(event: unknown, newValue: BuyerPick | null) => {
        setOptions(newValue ? [newValue, ...allOptions] : allOptions);
        setValue(newValue);
        onGetBuyer(newValue);
      }}
      onInputChange={(event, newInputValue) => setSearch(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          focused={false}
          style={{
            width: '200px',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
