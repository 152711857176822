import React from 'react';
import { useParams } from 'react-router-dom';

import { SEO, TemplateView } from 'rx-core';
import { useGetProductById } from 'rx-api';

import { ProductContent } from './ProductContent';
import { Container } from './index.styles';
import { ProductDetailLodaer } from './ProductDetailLoader';

export const ProductView: React.FC = () => {
  const params = useParams<{ id: string }>();

  const { data: productResponse, isLoading } = useGetProductById({
    id: params.id,
  });

  return (
    <TemplateView>
      <SEO title="RxRise | Product Details" />
      <Container>
        {isLoading && <ProductDetailLodaer />}

        {!isLoading && productResponse?.data && (
          <ProductContent product={productResponse.data} />
        )}
      </Container>
    </TemplateView>
  );
};
