import { SEO } from 'rx-core';
import React from 'react';
import Container from '@material-ui/core/Container';
import { Grid, Typography } from '@material-ui/core';
import { TemplateViewV2 } from './TemplateViewV2/TemplateViewV2';
import MainSearch from './MainSearch/MainSearch';
import FeaturedSection from './FeaturedSection/FeaturedSection';
import Categories from './Categories/Categories';
// import blogTest from '../../../../assets/images/backgrounds/blog-test.png';

const HomeViewV2 = () => {
  return (
    <TemplateViewV2 isHomePage={true}>
      <SEO title="RxRise | Home" />
      <MainSearch />
      <Categories />
      <FeaturedSection />
      {/* <img src={blogTest} /> */}
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12}>
            <div>
              <Typography variant="h5" style={{ marginTop: '30px' }}>
                Purchase Again
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </TemplateViewV2>
  );
};

export default HomeViewV2;
