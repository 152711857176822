import React from 'react';
import { MenuItem, Typography } from '@material-ui/core';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

type IProps = {
  logout: () => Promise<void>;
};

export const HeaderSettingsAdmin: React.FC<IProps> = (props) => {
  return (
    <MenuItem onClick={props.logout}>
      <ExitToAppOutlinedIcon />
      <Typography style={{ marginRight: '0.5rem' }}>Log out</Typography>
    </MenuItem>
  );
};
