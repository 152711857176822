import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

export const useDeleteAccountEmailRecipient = () => {
  return useMutation(['delete-account-email-recipient'], (id: number) => {
    return http.delete(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/account/email-recipients/${id}`
    );
  });
};
