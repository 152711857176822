import React from 'react';

import { formatMoney } from 'rx-utils';
import { TableCell, TableFooter, TableRow } from '@material-ui/core';

type IProps = {
  grantTotal: number;
};

export const ShopCartCardTotal: React.FC<IProps> = (props) => {
  return (
    <TableFooter>
      <TableRow>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell>
          GRAND TOTAL <br />
          {formatMoney(props.grantTotal)}
        </TableCell>
        <TableCell />
      </TableRow>
    </TableFooter>
  );
};
