export const STATE_CODES_US = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const RXRISE_FEE_PERCENT = 8;
export const STRIPE_FEE_PERCENT = 2.9;
export const STRIPE_FEE_CENT = 0.3;
export const RXRISE_FEE_TOTAL = RXRISE_FEE_PERCENT + STRIPE_FEE_PERCENT;

export const NDC_REGEX =
  /\d{4}-\d{4}-\d{2}|\d{5}-\d{3}-\d{2}|\d{5}-\d{4}-\d{1,2}|\d{5}-\*\d{3}-\d{2}/g;

export const DATE_REGEX =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/g;

export const productFormList = [
  'Aerosol',
  'Capsule',
  'Cream',
  'Crystal',
  'Foam',
  'Gel',
  'Lotion',
  'Oil',
  'Ointment',
  'Pill',
  'Tablet',
];

export const strengthList = ['0.5mg', '5mg', '10mg', '20mg', '30mg'];

export const filtersList = new Map([['Type', productFormList]]);

export const pharmacyTypeList = [
  {
    value: '1',
    name: 'Retail Pharmacy',
  },
  {
    value: '2',
    name: 'Dr./Physician Office',
  },
  {
    value: '3',
    name: 'Clinic',
  },
  {
    value: '4',
    name: 'Hospital',
  },
  {
    value: '5',
    name: 'Long Term Care Facility',
  },
  {
    value: '6',
    name: 'Chain Pharmacy',
  },
];

export const US_STATES_WITH_CODES = [
  {
    name: 'Alaska',
    value: 'AK',
  },
  {
    name: 'Alabama',
    value: 'AL',
  },
  {
    name: 'Arizona',
    value: 'AZ',
  },
  {
    name: 'Arkansas',
    value: 'AR',
  },
  {
    name: 'California',
    value: 'CA',
  },
  {
    name: 'Colorado',
    value: 'CO',
  },
  {
    name: 'Connecticut',
    value: 'CT',
  },
  {
    name: 'Delaware',
    value: 'DE',
  },
  {
    name: 'Washington DC',
    value: 'DC',
  },
  {
    name: 'Florida',
    value: 'FL',
  },
  {
    name: 'Georgia',
    value: 'GA',
  },
  {
    name: 'Hawaii',
    value: 'HI',
  },
  {
    name: 'Idaho',
    value: 'ID',
  },
  {
    name: 'Illinois',
    value: 'IL',
  },
  {
    name: 'Indiana',
    value: 'IN',
  },
  {
    name: 'Iowa',
    value: 'IA',
  },
  {
    name: 'Kansas',
    value: 'KS',
  },
  {
    name: 'Kentucky',
    value: 'KY',
  },
  {
    name: 'Louisiana',
    value: 'LA',
  },
  {
    name: 'Maine',
    value: 'ME',
  },
  {
    name: 'Maryland',
    value: 'MD',
  },
  {
    name: 'Massachusetts',
    value: 'MA',
  },
  {
    name: 'Michigan',
    value: 'MI',
  },
  {
    name: 'Mississippi',
    value: 'MS',
  },
  {
    name: 'Minnesota',
    value: 'MN',
  },
  {
    name: 'Missouri',
    value: 'MO',
  },
  {
    name: 'Nebraska',
    value: 'NE',
  },
  {
    name: 'Montana',
    value: 'MT',
  },
  {
    name: 'Nevada',
    value: 'NV',
  },
  {
    name: 'New Hampshire',
    value: 'NH',
  },
  {
    name: 'New Mexico',
    value: 'NM',
  },
  {
    name: 'New York',
    value: 'NY',
  },
  {
    name: 'North Carolina',
    value: 'NC',
  },
  {
    name: 'North Dakota',
    value: 'ND',
  },
  {
    name: 'Ohio',
    value: 'OH',
  },
  {
    name: 'Oklahoma',
    value: 'OK',
  },
  {
    name: 'Oregon',
    value: 'OR',
  },
  {
    name: 'Pennsylvania',
    value: 'PA',
  },
  {
    name: 'Puerto Rico',
    value: 'PR',
  },
  {
    name: 'Rhode Island',
    value: 'RI',
  },
  {
    name: 'South Carolina',
    value: 'SC',
  },
  {
    name: 'Tennessee',
    value: 'TN',
  },
  {
    name: 'Texas',
    value: 'TX',
  },
  {
    name: 'Utah',
    value: 'UT',
  },
  {
    name: 'Vermont',
    value: 'VT',
  },
  {
    name: 'Virginia',
    value: 'VA',
  },
  {
    name: 'South Dakota',
    value: 'SD',
  },
  {
    name: 'Washington',
    value: 'WA',
  },
  {
    name: 'West Virginia',
    value: 'WV',
  },
  {
    name: 'Wisconsin',
    value: 'WI',
  },
  {
    name: 'Wyoming',
    value: 'WY',
  },
];

export const HARDCODED_CATEGORIES = {
  1: 'drug',
  2: 'otc',
  3: 'medical_supply',
  4: 'diagnostic_test',
};
