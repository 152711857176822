import { MenuItem, Paper, Select, TextField } from '@material-ui/core';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useGetOrderCountMetrics, useGetOrderAmountsMetrics } from 'rx-api';
import { startOfWeek, format } from 'date-fns/fp';
import { OrderAmountReportsTooltip } from './OrderAmountReportsTooltip';
import { OrderCountReportsTooltip } from './OrderCountReportsTooltip';
import { OrderCountReportsLegend } from './OrderCountReportsLegend';
import { OrderAmountReportsLegend } from './OrderAmountReportsLegend';

export const OrderReports: React.FC = () => {
  const [graphType, setGraphType] = React.useState<string>('count');
  const [filterFormat, setFilterFormat] = React.useState<string>('weekly');
  const [filterOrderType, setFilterOrderType] = React.useState<string>('all');
  const [startDate, setStartDate] = React.useState(
    format('yyyy-MM-dd', startOfWeek(new Date()))
  );
  const { data: amountData } = useGetOrderAmountsMetrics(
    filterFormat,
    startDate,
    filterOrderType
  );
  const { data: countData } = useGetOrderCountMetrics(
    filterFormat,
    startDate,
    filterOrderType
  );
  const maxAmount = Math.max(
    ...(amountData?.data?.results?.map(
      (metric: { amount: number }) => metric.amount
    ) || [])
  );
  const maxCount = Math.max(
    ...(countData?.data?.results?.map(
      (metric: { count: number }) => metric.count
    ) || [])
  );

  return (
    <>
      <Paper
        style={{ padding: '1rem', marginBottom: '1rem' }}
        variant="outlined"
      >
        <div style={{ display: 'flex' }}>
          <Select
            defaultValue="count"
            value={graphType}
            style={{ marginRight: '1rem' }}
            onChange={(event) => setGraphType(event.target.value as string)}
          >
            <MenuItem value="count">Total Count</MenuItem>
            <MenuItem value="amount">Total Amount</MenuItem>
          </Select>

          <Select
            defaultValue="all"
            value={filterOrderType}
            style={{ marginRight: '1rem' }}
            onChange={(event) =>
              setFilterOrderType(event.target.value as string)
            }
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="edi">EDI</MenuItem>
            <MenuItem value="non-edi">Non-EDI</MenuItem>
          </Select>

          <Select
            defaultValue="weekly"
            value={filterFormat}
            style={{ marginRight: '1rem' }}
            onChange={(event) => setFilterFormat(event.target.value as string)}
          >
            <MenuItem value="weekly">7 Days</MenuItem>
            <MenuItem value="monthly">4 Weeks</MenuItem>
            <MenuItem value="yearly">12 Months</MenuItem>
          </Select>

          <TextField
            id="date"
            type="date"
            label="Filter by date"
            defaultValue={startDate}
            value={startDate}
            onChange={(event) => {
              setStartDate(event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </Paper>

      <Paper style={{ padding: '1rem' }} variant="outlined">
        {graphType === 'amount' && (
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              data={amountData?.data?.results}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis domain={[0, maxAmount + 100]} />
              <Tooltip content={<OrderAmountReportsTooltip />} />
              <Legend content={<OrderAmountReportsLegend />} />
              <Bar dataKey="rxriseSplit" stackId="a" fill="#008080" />
              <Bar dataKey="sellerSplit" stackId="a" fill="#FF7F50" />
            </BarChart>
          </ResponsiveContainer>
        )}

        {graphType === 'count' && (
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              data={countData?.data?.results}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis domain={[0, maxCount + 10]} />
              <Tooltip content={<OrderCountReportsTooltip />} />
              <Legend content={<OrderCountReportsLegend />} />
              <Bar dataKey="nonEdiCount" stackId="a" fill="#008080" />
              <Bar dataKey="ediCount" stackId="a" fill="#FF7F50" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Paper>
    </>
  );
};
