import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import CloseIcon from '../../../assets/icons/close-icon.svg';

import { RROverlay } from '../RROverlay/RROverlay';

export type RRModalProps = {
  children?: React.ReactNode;
  onClose: () => void;
  visible: boolean;
  closeButton?: boolean;
  setCheckoutError?: React.Dispatch<React.SetStateAction<any>>;
  selectedPharmacy?: {
    id: number;
    pharmacyName: string;
    dbaName: string;
    billingInformation: {
      name: string;
      address: string;
      city: string;
      state: string;
      zipCode: string;
    } | null;
    shippingAddress: {
      address: string;
      city: string;
      state: string;
      zipCode: string;
    } | null;
  };
};

export const RRModalContainer = styled(motion.div)`
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  position: absolute;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const CloseImg = styled.img`
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
  z-index: 12;
`;

const MODAL_VARIANTS = {
  initial: { top: '-50%', transition: { type: 'spring' } },
  visible: { top: '50%' },
  exit: { top: '-50%' },
};

const RRModal = ({
  onClose,
  visible,
  children,
  closeButton,
  ...props
}: RRModalProps): JSX.Element => {
  return (
    <RROverlay onClose={() => {}} visible={visible}>
      <RRModalContainer
        initial="initial"
        animate="visible"
        exit="exit"
        variants={MODAL_VARIANTS}
        {...props}
      >
        {closeButton && <CloseImg src={CloseIcon} onClick={onClose} />}
        {children}
      </RRModalContainer>
    </RROverlay>
  );
};

RRModal.defaultProps = {
  closeButton: true,
};

export { RRModal };
