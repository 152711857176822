import { useMutation } from 'react-query';

import { http } from 'rx-api/http/repository';

type IRequest = {
  ndc: string;
  description: string;
  name: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePostAdminBannedProduct = () => {
  const onRequest = async (request: IRequest) => {
    return http.post(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/banned-products`,
      request
    );
  };

  return useMutation(onRequest, {});
};
