import { Box, TableCell, TableRow } from '@material-ui/core';
import React from 'react';

export const AdminBannedProductsEmpty: React.FC<
  React.PropsWithChildren<any>
> = ({ children }) => (
  <TableRow>
    <TableCell colSpan={15}>
      <Box style={{ padding: '2rem', width: '100%' }}>{children}</Box>
    </TableCell>
  </TableRow>
);
