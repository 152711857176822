import { map, reduce, toLower, keys, groupBy } from 'ramda';

import {
  ICheckoutItem,
  ShopCartItem,
  ShoppingCartState,
  ShippingConfig,
} from 'rx-domain';

import { createSelector } from 'reselect';

import { RootState } from 'rx-store/store';

const getShipperNames = (state: RootState): string[] => {
  return map(
    toLower,
    keys(
      groupBy(
        (ci) => ci.product.shippingName ?? '',
        state.shoppingCart.cartItems
      )
    )
  );
};

const getTotalCartItems = (state: RootState): number => {
  return state.shoppingCart.cartItems.length;
};

const getShoppingCartState = (state: RootState): ShoppingCartState => {
  return state.shoppingCart.shoppingCartState;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getSearchItemCount = () => {
  return createSelector(
    (state: RootState) => state.shoppingCart.cartItems,
    (_: RootState, id: number) => id,
    (cartItems, id) => {
      return (
        cartItems.find((item) => item.id?.toString() === id.toString())
          ?.count || 0
      );
    }
  );
};

const getCartItems = (state: RootState): ShopCartItem[] =>
  state.shoppingCart.cartItems;

const getSubtotal = (state: RootState): number => {
  const rawSubtotal = state.shoppingCart.cartItems.reduce((prev, { count }) => {
    return prev + (count ?? 0);
  }, 0);

  return rawSubtotal;
};

const getWACSubtotal = (state: RootState): number => {
  const rawSubtotal = state.shoppingCart.cartItems.reduce(
    (prev, { count, product }) => {
      return prev + (count || 0) * parseFloat(product.price ?? '0');
    },
    0
  );

  return rawSubtotal;
};

const getSavingMoney = createSelector(
  [getSubtotal, getWACSubtotal],
  (_, wacSubtotal) => {
    return wacSubtotal;
  }
);

const getCheckoutItems = (state: RootState): ICheckoutItem[] => {
  return state.shoppingCart.cartItems.map((item) => ({
    id: item.id ? item.id : 0,
    quantity: item.count ?? 0,
    category: item.product.category,
  }));
};

const getHasOTCProduct = (state: RootState): boolean => {
  return !!state.shoppingCart.cartItems.find(
    (item) => item.product.category === 'otc'
  );
};

const getValidateItems = (state: RootState): boolean => {
  return (
    state.shoppingCart.cartItems.every((item) => {
      const maxOrderIsExceeded = Boolean(
        item.product.maximumQuantity &&
          item.count > item.product.maximumQuantity
      );
      return (
        item.count &&
        item.count >= item.product.minimumQuantity &&
        item.count <= item.product.units &&
        !maxOrderIsExceeded
      );
    }) && state.shoppingCart.cartItems.length > 0
  );
};

const getEmptyShoppingCart = (state: RootState): boolean => {
  return state.shoppingCart.cartItems.length === 0;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getShopItemsBySeller = (state: RootState) => {
  return groupBy(
    (ci) => ci.product.shippingName ?? '',
    state.shoppingCart.cartItems
  );
};

const getShippingConfigBySeller = createSelector(
  [getShopItemsBySeller],
  (itemsBySeller) => {
    return reduce(
      (prev, sellerName) => {
        const items = itemsBySeller[sellerName];

        if (items.length === 0) {
          return {
            ...prev,
            [sellerName]: {
              groundShipping: 0,
              overnightShipping: 0,
              minimumAmount: 0,
              shippingConstraints: null,
              grantTotal: 0,
            },
          };
        }

        const [firstProduct] = items;

        const grantTotal = items.reduce((acc, item) => {
          return item.count
            ? acc +
                item.count *
                  parseFloat(item.product.newPrice ?? item.product.price ?? '0')
            : acc;
        }, 0);

        return {
          ...prev,
          [sellerName]: {
            groundShipping: parseFloat(
              firstProduct.product.groundShipping ?? '0'
            ),
            overnightShipping: parseFloat(
              firstProduct.product.overnightShipping ?? '0'
            ),
            minimumAmount:
              firstProduct.product.minimumOrderAmount === '0' ||
              !!firstProduct.product.minimumOrderAmount === false
                ? 150
                : parseFloat(firstProduct.product.minimumOrderAmount ?? '0'),
            shippingConstraints: firstProduct.product.shippingConstraints,
            grantTotal,
          },
        };
      },
      {},
      Object.keys(itemsBySeller)
    ) as Record<string, ShippingConfig>;
  }
);

const getMinAmountValidation = createSelector(
  [getValidateItems, getShippingConfigBySeller],
  (itemsValidation, sellerConfig) => {
    return (
      itemsValidation &&
      Object.keys(sellerConfig).every((sellerName) => {
        return (
          sellerConfig[sellerName].grantTotal >=
          sellerConfig[sellerName].minimumAmount
        );
      })
    );
  }
);

export {
  getTotalCartItems,
  getCartItems,
  getSubtotal,
  getSavingMoney,
  getShoppingCartState,
  getSearchItemCount,
  getCheckoutItems,
  getValidateItems,
  getEmptyShoppingCart,
  getShipperNames,
  getHasOTCProduct,
  getShopItemsBySeller,
  getWACSubtotal,
  getShippingConfigBySeller,
  getMinAmountValidation,
};
