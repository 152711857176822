import styled, { css } from 'styled-components';
import {
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
} from '@material-ui/icons';

export const TableRow = styled.tr`
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;

  background: rgb(255, 255, 255);
  box-shadow: 5px 5px 25px rgba(51, 60, 68, 0.08);
  border-radius: 10px;
  height: 76px;
`;

export const TableColumn = styled.td`
  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  color: black;

  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-all;
`;

export const LastTableColumn = styled(TableColumn)`
  text-align: right;
`;

export const LikedIcon = styled(FavoriteIcon)`
  ${({ theme }) => css`
    cursor: pointer;

    &.MuiSvgIcon-root {
      fill: ${theme.colors.red[100]};
    }
  `}
`;

export const NonLikedIcon = styled(FavoriteBorderIcon)`
  cursor: pointer;
`;
