import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { reducers } from './reducers';

const composedEnhancers = composeWithDevTools();

const store = createStore(reducers, {}, composedEnhancers);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, reducers };
