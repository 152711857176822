import * as Yup from 'yup';

const FILE_SIZE = 2 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];

const EditPictureProductValidator = Yup.object().shape({
  picture: Yup.mixed()
    .test('fileSize', 'File too large', (value) => {
      return (value && value?.size <= FILE_SIZE) || value === null;
    })
    .test(
      'fileFormat',
      'Unsupported Format',
      (value) =>
        (value && SUPPORTED_FORMATS.includes(value.type)) || value === null
    ),
});

export { EditPictureProductValidator };
