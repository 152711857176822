import { useAuth } from 'rx-hooks';
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

type RedictectDashboardProps = RouteProps & {
  component: React.ComponentType;
};

const RedictectDashboard = ({
  component: Component,
  ...rest
}: RedictectDashboardProps): JSX.Element => {
  const { isAuth } = useAuth();

  if (!isAuth) {
    localStorage.removeItem('ad_status');
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth === false ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export { RedictectDashboard };
