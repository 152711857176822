import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar } from '@material-ui/core';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  FavoriteBorder as FavoriteBorderIcon,
  LocalMallOutlined,
} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getTotalCartItems } from '../../../../rx-store';
import { HeaderLogo } from '../../../../rx-core/components/home/RxHeader/HeaderLogo';
import { HeaderSettings } from '../../../../rx-core/components/home/RxHeader/HeaderSettings';
import { SearchHeader } from '../../../../rx-core';

const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    color: 'white',
    backgroundColor: '#5077F2',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(
        ['background-color', 'color', 'display'],
        {
          duration: theme.transitions.duration.standard,
        }
      ),
    },
    appBarTransparent: {
      backgroundColor: 'transparent !important', // Uso de !important para asegurar que la transparencia tenga prioridad inicialmente
      color: 'black',
    },
    appBarSolid: {
      backgroundColor: 'white !important', // Puedes cambiar esto por cualquier color que desees. Uso de !important para asegurar que el cambio de color se aplique.
      color: 'white',
    },
    toolbar: {
      justifyContent: 'space-between',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    logo: {
      transition: 'opacity 0.5s ease-in-out', // Ajusta la duración de la transición según prefieras
      opacity: 0, // Inicia con el logo invisible
    },
    logoVisible: {
      opacity: 1, // Hace el logo completamente visible
    },
    inputRoot: {
      color: 'inherit',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      color: '#000000',
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
);

export default function RxHeaderV2({
  isHomePage = false,
}: {
  isHomePage?: boolean;
}) {
  const totalCartItems = useSelector(getTotalCartItems);

  const classes: any = useStyles();
  const [navBackground, setNavBackground] = useState<any>('appBarTransparent');
  const [showLogo, setShowLogo] = useState<boolean>(false);

  const handleScroll = () => {
    const show = window.scrollY > 300;
    setShowLogo(show);
    if (show) {
      setNavBackground('appBarSolid');
    } else {
      setNavBackground('appBarTransparent');
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Link to="/favorites">
          <IconButton aria-label="favorites">
            <FavoriteBorderIcon fontSize="medium" color="inherit" />
          </IconButton>
          Favorites
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/shopping-cart" data-cy="shopping-cart-icon">
          <IconButton aria-label="cart">
            <StyledBadge badgeContent={totalCartItems}>
              <LocalMallOutlined />
            </StyledBadge>
          </IconButton>
          Shopping Cart
        </Link>
      </MenuItem>
      <MenuItem>
        <HeaderSettings />
        Profile
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <AppBar
        position="fixed"
        className={`${classes.appBar} ${classes[navBackground]}`}
      >
        <Toolbar className={classes.toolbar}>
          <div
            className={`${classes.logo} ${showLogo ? classes.logoVisible : ''}`}
          >
            <HeaderLogo />
          </div>
          <div
            className={`${classes.logo} ${showLogo ? classes.logoVisible : ''}`}
          >
            <SearchHeader isHomePage={false} />
          </div>
          <div className={classes.sectionDesktop}>
            <Link to="/favorites">
              <IconButton aria-label="favorites">
                <FavoriteBorderIcon fontSize="medium" color="inherit" />
              </IconButton>
            </Link>
            <Link to="/shopping-cart" data-cy="shopping-cart-icon">
              <IconButton aria-label="cart">
                <StyledBadge badgeContent={totalCartItems}>
                  <LocalMallOutlined />
                </StyledBadge>
              </IconButton>
            </Link>
            <HeaderSettings />
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}
