import { useGetRequest } from 'rx-api/utils';
import { URL_V1 } from 'rx-api/api/constants';

export const GET_PHARMACY_DATA_URL = `${URL_V1}/catalog/sellers`;

type IGetPharmacistSellerListResponse = Array<{
  id: number;
  name: string;
}>;

const makeParams = (req: any) => {
  const url = `?status=approved`;

  return url;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetCatalogSellerList = () => {
  return useGetRequest<unknown, IGetPharmacistSellerListResponse>({
    url: GET_PHARMACY_DATA_URL,
    key: ['pharmacist-get-seller-list'],
    params: { status: 'approved' },
    makeParams,
  });
};
