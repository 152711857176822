import React from 'react';
import { Grid } from '@material-ui/core';

import { BannerTitle, SEO, TemplateView } from 'rx-core';

import { SettingsEmailRecipients } from 'pages/buyer/settingsV2/SettingsProfile/SettingsEmailRecipients';
import { SettingsNav } from '../../shared/SettingsNav';
import { SettingsPersonalInfoForm } from './SettingsPersonalInfoForm';
import { SettingsBillingInfoForm } from './SettingsBillingInfoForm';
import { Separator, Title } from './index.styles';
import { SettingsBizInfoForm } from './SettingsBizInfoForm';
import { SettingsEdi } from './SettingsEdi';

export const SettingsProfileSellerView: React.FC = () => {
  return (
    <TemplateView data-cy="account-settings">
      <SEO title="RxRise | Settings" />
      <BannerTitle>
        <Title>Account Settings</Title>
      </BannerTitle>
      <div style={{ padding: '16px 0', flexGrow: 1 }}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs={3}>
            <Grid item xs={12}>
              <SettingsNav
                links={[
                  {
                    text: 'My Profile',
                    to: '/settings/profile',
                    dataCy: 'account-settings-profile',
                  },
                  {
                    text: 'Shipping',
                    to: '/settings/shipping',
                    dataCy: 'account-settings-shipping',
                  },
                  {
                    text: 'Security',
                    to: '/settings/security',
                    dataCy: 'account-settings-security',
                  },
                ]}
              />
            </Grid>
          </Grid>

          <Grid item xs={9}>
            <SettingsPersonalInfoForm />

            <Separator />

            <SettingsBizInfoForm />

            <Separator />

            <SettingsEdi />

            <Separator />

            <SettingsBillingInfoForm />

            <Separator />

            <SettingsEmailRecipients />
          </Grid>
        </Grid>
      </div>
    </TemplateView>
  );
};
