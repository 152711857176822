import { IPostPaymentMethodsCardsDTO } from 'rx-domain';
import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

// TODO: Only works in pharmacy users
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAddPharmacyPaymentMethodCard = (
  pharmacyId: string | number
) => {
  return useMutation(
    'add-pharmacy-payment-method-card',
    (payloadDto: IPostPaymentMethodsCardsDTO) => {
      return http.post(
        `${process.env.REACT_APP_WEBSITE_URL}/v2/pharmacist/pharmacies/${pharmacyId}/payment-methods/cards`,
        payloadDto
      );
    },
    { retry: false }
  );
};
