import styled, { css } from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';
import RxRiseLogo from '../../../../assets/logos/rxrise-gray-logo.svg';

type IProps = {
  text: string;
  background: string;
  path: string;
};

const CategoryContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
`;

const ImageStyled = styled.img`
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-bottom: auto;
  flex: 1;
  object-fit: fill;
  padding: 0;
  display: block;
  min-height: 230px;
`;

const TextStyled = styled.div`
  border-top: 1px #b9b9b9 solid;
  color: black;
  text-align: center;
  padding: 7px;
  margin-top: auto;
  width: 100%;
  box-sizing: border-box;
`;

const LinkStyled = styled(LinkRouter)`
  text-decoration: none;
`;

const CategoryCardContainer = styled.div`
  ${({ theme }) => css`
    box-sizing: border-box;
    width: calc(25% - 20px);
    margin: 10px;
    min-height: 250px;
    border: 1px #b9b9b9 solid;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.02);
    }
    @media (max-width: 992px) {
      width: calc(33.3333% - 20px);
    }

    @media (max-width: 768px) {
      width: calc(50% - 20px);
    }

    @media (max-width: 576px) {
      width: calc(100% - 20px);
    }
  `}
`;

const CategoryCard = (props: IProps): JSX.Element => {
  return (
    <CategoryCardContainer>
      <LinkStyled to={props.path}>
        <CategoryContent>
          <ImageStyled src={props.background || RxRiseLogo} />
          <TextStyled>
            <p>{props.text}</p>
          </TextStyled>
        </CategoryContent>
      </LinkStyled>
    </CategoryCardContainer>
  );
};

export { CategoryCard };
