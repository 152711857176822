import React from 'react';
import styled, { css } from 'styled-components';

import { useAddPayment } from 'rx-contexts';
import { RRModal } from 'rx-core';
import {
  AddPaymentMethodActions,
  AddPaymentMethodContent,
  AddPaymentMethodTabs,
} from '../molecules';

const Modal = styled(RRModal)`
  ${({ theme }) => css`
    min-height: 25rem;
    width: 36rem;
    border-radius: 0.5rem;

    ${theme.lessThan('desktop')} {
      min-height: 95%;
      width: 95%;
    }
  `}
`;

const Container = styled.div`
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const AddPaymentMethodModal = (): JSX.Element => {
  const { openModal, onOpenModal } = useAddPayment();

  return (
    <Modal
      data-cy="add-payment-method-modal"
      visible={openModal}
      onClose={() => onOpenModal(false)}
      closeButton={false}
    >
      <Container>
        <AddPaymentMethodContent />
        <AddPaymentMethodActions onCancel={() => onOpenModal(false)} />
      </Container>
    </Modal>
  );
};

export { AddPaymentMethodModal };
