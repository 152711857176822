import styled from 'styled-components';

export const TableRow = styled.tr`
  & > td:first-child {
    padding-left: 2.5rem;
  }

  & > td:last-child {
    padding-right: 2.5rem;
  }
`;

export const TableColumn = styled.td`
  padding: 0.25rem;
  padding-left: 0;
`;

export const TableAmount = styled(TableColumn)`
  padding-bottom: 2.5rem;
`;

export const TableColumnTitle = styled(TableColumn)`
  font-weight: bold;
  border-top: 1px solid #d6d6d6;
  padding: 1rem 0;
`;
