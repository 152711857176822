import { useMutation } from 'react-query';

import { IDeleteFavoritesDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDeleteFavorites = () => {
  const onRequest = async (request: IDeleteFavoritesDTO) => {
    return http.delete(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/favorites/${request.id}`
    );
  };

  return useMutation(onRequest, {});
};

export { useDeleteFavorites };
