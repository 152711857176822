import React, { createContext, FC, useContext, useState } from 'react';

type ContextProps = {
  openModal: boolean;
  openVerifyModal: boolean;
  view: 'debit-credit-card' | 'ach' | 'payment-terms';
  loading: boolean;
  finish: boolean;
  savePayment: boolean;
  error: string | null;
  paymentId: number | null;
  pharmacyId: number | null;
  onSetLoading: (value: boolean) => void;
  onSetFinish: (value: boolean) => void;
  onSetError: (value: string) => void;
  onSavePayment: (value: boolean) => void;
  onSetView: (value: 'debit-credit-card' | 'ach' | 'payment-terms') => void;
  onOpenModal: (value: boolean) => void;
  onOpenVerifyModal: (value: boolean) => void;
  onSetPaymentId: (value: number | null) => void;
  onSetPharmacyId: (value: number) => void;
};

const AddPaymentContext = createContext<ContextProps | undefined>(undefined);

const AddPaymentProvider: FC = ({ children }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openVerifyModal, setOpenVerifyModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [finish, setFinish] = useState<boolean>(false);
  const [savePayment, setSavePayment] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [paymentId, setPaymentId] = useState<number | null>(null);
  const [pharmacyId, setPharmacyId] = useState<number | null>(null);
  const [view, setView] = useState<
    'debit-credit-card' | 'ach' | 'payment-terms'
  >('debit-credit-card');

  const onSetPharmacyId = (value: number) => {
    setPharmacyId(value);
  };

  const onOpenModal = (value: boolean) => {
    setOpenModal(value);
  };

  const onOpenVerifyModal = (value: boolean) => {
    setOpenVerifyModal(value);
  };

  const onSetLoading = (value: boolean) => {
    setLoading(value);
  };

  const onSetFinish = (value: boolean) => {
    setFinish(value);
  };

  const onSetError = (value: string | null) => {
    setError(value);
  };

  const onSavePayment = (value: boolean) => {
    setSavePayment(value);
  };

  const onSetPaymentId = (value: number | null) => {
    setPaymentId(value);
  };

  const onSetView = (value: 'debit-credit-card' | 'ach' | 'payment-terms') => {
    setView(value);
    setSavePayment(false);
  };

  return (
    <AddPaymentContext.Provider
      value={{
        loading,
        finish,
        error,
        savePayment,
        view,
        openModal,
        openVerifyModal,
        paymentId,
        pharmacyId,
        onSetLoading,
        onSetFinish,
        onSetError,
        onSavePayment,
        onSetView,
        onOpenModal,
        onOpenVerifyModal,
        onSetPaymentId,
        onSetPharmacyId,
      }}
    >
      {children}
    </AddPaymentContext.Provider>
  );
};

const useAddPayment = (): ContextProps => {
  const context = useContext(AddPaymentContext);

  if (context === undefined) {
    throw new Error('useAddPayment must be used within a AddPaymentProvider');
  }

  return context;
};

export { useAddPayment, AddPaymentProvider };
