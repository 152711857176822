import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = styled(Grid)``;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-weight: 400;
    margin-bottom: 1rem;
  `}
`;
