import React from 'react';
import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

export type InputSelectProps = React.SelectHTMLAttributes<HTMLSelectElement> &
  LayoutProps & {
    error: boolean;
    children: React.ReactNode;
  };

const Select = styled.select<InputSelectProps>`
  ${({ theme, error }) => css`
    width: 303px;
    height: 50px;
    border: 1px solid ${theme.colors.gray[300]};
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    width: 100%;
    font-family: ${theme.fonts.robotoRegular};
    font-size: ${theme.sizes.small};
    line-height: 25px;
    padding: 0 24px;
    ${() =>
      error &&
      css`
        border-color: ${theme.colors.red[100]};
        color: ${theme.colors.red[100]};
      `};
    ${layout};

    &:disabled {
      color: ${theme.colors.gray[500]};
      border: 1px solid ${theme.colors.gray[400]};
      cursor: not-allowed;
    }

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${theme.colors.gray[500]};
      font-family: ${theme.fonts.robotoRegular};
      font-style: normal;
      font-weight: normal;
      font-size: ${theme.sizes.small};
    }
  `}
`;

const InputSelect = ({ children, ...props }: InputSelectProps): JSX.Element => {
  return <Select {...props}>{children}</Select>;
};

export { InputSelect };
