import * as Yup from 'yup';

import { firstName, address, city, state, zipCode } from '../common.validator';

export const BillingInformationSchema = Yup.object().shape({
  name: firstName,
  address,
  city,
  state,
  zipCode,
});
