import React from 'react';
import { Typography } from '@material-ui/core';
import { ReportProblem } from '@material-ui/icons';

export const SuspendedAccountBanner: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: 'rgb(255, 244, 229)',
        color: 'rgb(102, 60, 0)',
        display: 'flex',
        marginTop: '1rem',
        padding: '0.75rem 0.5rem',
      }}
    >
      <ReportProblem style={{ marginTop: '0.25rem' }} htmlColor="#ff9800" />
      <div style={{ marginLeft: '0.5rem' }}>
        <Typography variant="h6">This account is suspended</Typography>
        <Typography>
          Please review the "Danger Zone" if you want to reactivate the account
          again
        </Typography>
      </div>
    </div>
  );
};
