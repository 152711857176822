import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const Text = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.blue[100]};
    cursor: pointer;
    font-size: ${theme.sizes.small};
    text-decoration: none;
  `}
`;

const LoginForgot = (): JSX.Element => {
  return <Text to="/forgot-password">Forgot my password</Text>;
};

export { LoginForgot };
