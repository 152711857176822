import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { InventoryType } from 'rx-domain';
import { onMakeURL } from 'rx-utils';

import { AdminProductsTemplate } from '../templates/AdminProductsTemplate';
import { AdminProductsActions } from '../molecules/AdminProductsActions';
import { AdminProductsTabs } from '../molecules/AdminProductsTabs';
import { AdminProductsTable } from '../organims/AdminProductsTable';
import { AdminBlackListTable } from '../organims/AdminBlackListTable';

const Container = styled.div`
  padding: 1.5rem;
`;

export const AdminProducts: React.FC = () => {
  const location = useLocation();
  const categoryParam =
    new URLSearchParams(location.search).get('category') || 'drug';
  const [view, setView] = useState<string | 'black_list'>(
    categoryParam as InventoryType
  );
  const [count, setCount] = React.useState(0);
  const [ediModalOpen, setEdiModalOpen] = React.useState(false);

  const history = useHistory();

  const changeQueryView = (category: string | 'black_list') => {
    history.push(
      onMakeURL({
        category,
      })
    );
  };

  return (
    <AdminProductsTemplate>
      <Container>
        <AdminProductsActions setEdiModalOpen={setEdiModalOpen} view={view} />
        <AdminProductsTabs
          view={view}
          count={count}
          onSetView={(value) => {
            setView(value);
            changeQueryView(value);
          }}
        >
          {view === 'black_list' ? (
            <AdminBlackListTable
              onChangeCount={(newCount: number) => setCount(newCount)}
            />
          ) : (
            <AdminProductsTable
              ediModalOpen={ediModalOpen}
              setEdiModalOpen={setEdiModalOpen}
              onChangeCount={(newCount: number) => setCount(newCount)}
            />
          )}
        </AdminProductsTabs>
      </Container>
    </AdminProductsTemplate>
  );
};
