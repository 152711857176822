import { repeat } from 'ramda';

import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const pipeInputDate = createAutoCorrectedDatePipe('mm/dd/yy');

export const pipeInputFullDate = createAutoCorrectedDatePipe('mm/dd/yyyy');

export const maskInputDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/];

export const maskInputFullDate = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const MASK_PHONE_NUMBER = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const MASK_ZIP_CODE = [/\d/, /\d/, /\d/, /\d/, /\d/];

export const buildNumberMask = (value: number): RegExp[] => {
  return repeat(/\d/, value.toString().length);
};

export const MASK_CREDIT_CARD = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
];

export const percentageMask = createNumberMask({
  prefix: '',
  suffix: ' %',
  allowDecimal: true,
});

export const decimalMask = createNumberMask({
  prefix: '$ ',
  suffix: '',
  allowDecimal: true,
  integerLimit: 1,
});

export const decimalUnlimitedMask = createNumberMask({
  prefix: '$ ',
  suffix: '',
  allowDecimal: true,
});
