import React from 'react';
import { Grid } from '@material-ui/core';

import { useGetAccountUpdatePasswordCSFR } from 'rx-api';

import { SettingsChangePassForm } from '../SettingsChangePassForm';
import { Container, Content, Subtitle, Title } from './index.styles';

const SettingsChangePass = (): JSX.Element => {
  const { data: csfrRes } = useGetAccountUpdatePasswordCSFR();

  return (
    <Container container direction="column">
      <Grid item xs={12}>
        <Title>Signing in to RxRise</Title>
        <Subtitle>Change Password</Subtitle>
        <Content>
          It's important to use a secure password. You can create this with any
          combination of 8 or more mixed case letters, numbers or special
          characters (!#*? etc).
        </Content>
        <SettingsChangePassForm data={csfrRes?.data} />
      </Grid>
    </Container>
  );
};

export { SettingsChangePass };
