import { Skeleton } from '@material-ui/lab';
import React from 'react';

export const PharmacyMemberListLoader: React.FC = () => {
  return (
    <>
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
    </>
  );
};
