import React from 'react';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

type FooterNewsletterTitleProps = LayoutProps & {
  children: React.ReactNode;
};

const Text = styled.div`
  width: 40%;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: white;
  margin-bottom: 26px;
  ${layout}
`;

const FooterNewsletterTitle = ({
  children,
  ...props
}: FooterNewsletterTitleProps): JSX.Element => {
  return <Text {...props}>{children}</Text>;
};

export { FooterNewsletterTitle };
