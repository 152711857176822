import { IProductData } from 'rx-domain';

import {
  CI_CLOSE_CHOOSE_AMOUNT_MANUAL,
  CI_OPEN_CHOOSE_AMOUNT_MANUAL,
} from './actionTypes';

export interface ICurrentInvState {
  chooseAmountModalVisible: boolean;
  selectedProduct?: IProductData | null;
}

export interface ICurrentInvAction {
  type: string;
  currentInv: ICurrentInvState;
}

const initialState: ICurrentInvState = {
  chooseAmountModalVisible: false,
  selectedProduct: null,
};

const currentInvReducer = (
  state: ICurrentInvState = initialState,
  action: ICurrentInvAction
): ICurrentInvState => {
  switch (action.type) {
    case CI_OPEN_CHOOSE_AMOUNT_MANUAL: {
      return {
        ...state,
        ...action.currentInv,
      };
    }
    case CI_CLOSE_CHOOSE_AMOUNT_MANUAL: {
      return {
        ...state,
        ...action.currentInv,
        selectedProduct: null,
      };
    }
    default:
      return state;
  }
};

export { initialState, currentInvReducer };
