import { useMutation } from 'react-query';

import { IDeletePaymentMethodsCardsDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDeletePaymentMethodsCards = () => {
  const onRequest = (req: IDeletePaymentMethodsCardsDTO) => {
    return http.delete(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/pharmacist/payment-methods/cards/${req.id}`
    );
  };

  return useMutation(onRequest);
};

export { useDeletePaymentMethodsCards };
