import { createGlobalStyle } from 'styled-components';

import RobotoLight from './assets/fonts/Roboto-Light.ttf';
import RobotoRegular from './assets/fonts/Roboto-Regular.ttf';
import RobotoMedium from './assets/fonts/Roboto-Medium.ttf';

import RoobertLight from './assets/fonts/Roobert-Light.ttf';
import RooberRegular from './assets/fonts/Roobert-Regular.ttf';
import RooberBold from './assets/fonts/Roobert-Bold.ttf';
import RooberMedium from './assets/fonts/Roobert-Medium.ttf';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Roboto Regular";
    src: url(${RobotoRegular});
  }

  @font-face {
    font-family: "Roboto Light";
    src: url(${RobotoLight});
  }

  @font-face {
    font-family: "Roboto Medium";
    src: url(${RobotoMedium});
  }

  @font-face {
    font-family: "Roobert Light";
    src: url(${RoobertLight});
  }

  @font-face {
    font-family: "Roobert Regular";
    src: url(${RooberRegular});
  }

  @font-face {
    font-family: "Roobert Medium";
    src: url(${RooberBold});
  }

  @font-face {
    font-family: "Roobert Bold";
    src: url(${RooberMedium});
  }

  body {
    font-family: 'Roboto Regular', sans-serif;
  }

  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

export { GlobalStyle };
