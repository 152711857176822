import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';
import { ADMIN_URL_V1 } from '../constants';

const getUrl = (id: number | string): string =>
  `${ADMIN_URL_V1}/purchase-orders/${id}/receipt`;

export const useAdminDownloadPurchaseOrderReceipt = (params: {
  id: number | string;
}) => {
  return useMutation(
    ['admin-download-buyer-invoice', params.id],
    () => {
      const url = getUrl(params.id);
      return http.get(url, { responseType: 'arraybuffer' });
    },
    { retry: false }
  );
};
