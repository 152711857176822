import React, { createContext, FC, useContext, useState } from 'react';

import {
  UploadCSV,
  IGetManufacturer,
  StepUploadType,
  InventoryType,
} from 'rx-domain';

type ContextProps = {
  stepUpload: StepUploadType;
  bulkUploadType: InventoryType;
  csvFile: ArrayBuffer | null;
  csvData: UploadCSV[];
  overrideInventory: boolean;
  manufacturer: IGetManufacturer | null;
  loading: boolean;
  confirmUpload: boolean;
  onStepUpload: (step: StepUploadType) => void;
  onSetBulkUploadType: (type: InventoryType) => void;
  onSetCsvData: (data: UploadCSV[]) => void;
  onSetRawCsvData: (data: ArrayBuffer | null) => void;
  uploadModalVisible: boolean;
  onSetUploadModalVisible: (visible: boolean) => void;
  onSetOverrideInventory: (on: boolean) => void;
  onSetManufacturer: (manufacturer: IGetManufacturer | null) => void;
  onSetLoading: (loading: boolean) => void;
  onSetConfirmUpload: (value: boolean) => void;
};

const ManageItemsUploadContext = createContext<ContextProps | undefined>(
  undefined
);

const ManageItemsUploadProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [bulkUploadType, setBulkUploadType] = useState<InventoryType>('drug');
  const [confirmUpload, setConfirmUpload] = useState(false);
  const [csvData, setCsvData] = useState<UploadCSV[]>([]);
  const [file, setFile] = useState<ArrayBuffer | null>(null);
  const [stepUpload, setStepUpload] = useState<StepUploadType>('CHOOSE');
  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
  const [overrideInventory, setOverrideInventory] = useState<boolean>(true);
  const [manufacturer, setManufacturer] = useState<IGetManufacturer | null>(
    null
  );

  const onStepUpload = (value: StepUploadType) => {
    setStepUpload(value);
  };

  const onSetCsvData = (data: UploadCSV[]) => {
    setCsvData(data);
  };

  const onSetRawCsvData = (data: ArrayBuffer | null) => {
    setFile(data);
  };

  const onSetUploadModalVisible = (visible: boolean) => {
    setUploadModalVisible(visible);
  };

  const onSetOverrideInventory = (on: boolean) => {
    setOverrideInventory(on);
  };

  const onSetManufacturer = (value: IGetManufacturer | null) => {
    setManufacturer(value);
  };

  const onSetLoading = (value: boolean) => {
    setLoading(value);
  };

  const onSetConfirmUpload = (value: boolean) => {
    setConfirmUpload(value);
  };

  const onSetBulkUploadType = (type: InventoryType) => {
    setBulkUploadType(type);
  };

  return (
    <ManageItemsUploadContext.Provider
      value={{
        loading,
        csvData,
        stepUpload,
        manufacturer,
        csvFile: file,
        overrideInventory,
        uploadModalVisible,
        confirmUpload,
        bulkUploadType,
        onSetLoading,
        onStepUpload,
        onSetCsvData,
        onSetRawCsvData,
        onSetManufacturer,
        onSetUploadModalVisible,
        onSetOverrideInventory,
        onSetConfirmUpload,
        onSetBulkUploadType,
      }}
    >
      {children}
    </ManageItemsUploadContext.Provider>
  );
};

const useManageItemsUpload = (): ContextProps => {
  const context = useContext(ManageItemsUploadContext);

  if (context === undefined) {
    throw new Error(
      'useManageItemsUpload must be used within a ManageItemsUploadProvider'
    );
  }

  return context;
};

export { useManageItemsUpload, ManageItemsUploadProvider };
