import React, { InputHTMLAttributes, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { CircularProgress } from '@material-ui/core';

import SearchIcon from '../../../../assets/icons/search-icon.svg';
import CloseIcon from '../../../../assets/icons/close-icon.svg';

type IProps = InputHTMLAttributes<HTMLInputElement> & {
  withData: boolean;
  withItems: boolean;
  loading: boolean;
  searchText: string;
  onCleanInput: () => void;
};

const Container = styled.div`
  position: relative;
`;

const Icon = styled.img`
  position: absolute;
  left: 20px;
  top: 15px;
`;

const CloseImg = styled.img`
  position: absolute;
  right: 16px;
  top: 18px;
  cursor: pointer;
`;

const LoadingIcon = styled(CircularProgress)`
  position: absolute;
  left: 16px;
  top: 11px;

  ${({ theme }) => css`
    color: ${theme.colors.blue[100]} !important;
  `}
`;

const Input = styled.input<{
  resultState: boolean;
}>`
  border-radius: 50px;
  height: 50px;
  width: 334px;
  outline: none;

  padding: 0 18px 0 64px;

  ${({ theme: { colors, fonts, sizes }, resultState }) => css`
    border: none;
    font-size: ${sizes.medium};
    font-family: ${fonts.robotoLight};
    background-color: ${colors.white[100]};
    padding-right: 48px;

    &::placeholder {
      color: ${colors.gray[500]};
      font-size: ${sizes.medium};
      font-family: ${fonts.robotoLight};
    }

    ${resultState &&
    css`
      background-color: white;
      border-radius: 8px 8px 0 0;
      border-bottom: none;
    `};
  `}
`;

const HeaderSearchInput = forwardRef<HTMLInputElement, IProps>(
  ({ className, onCleanInput, loading, ...props }, ref): JSX.Element => {
    const resultState =
      (props.withData || props.withItems) &&
      props.searchText.length >= 3 &&
      !loading;

    return (
      <Container className={className}>
        {loading ? (
          <LoadingIcon size={28} thickness={5} />
        ) : (
          <Icon src={SearchIcon} />
        )}
        {props.searchText && (
          <CloseImg src={CloseIcon} onClick={onCleanInput} />
        )}
        <Input
          placeholder="Search for products"
          resultState={resultState}
          ref={ref}
          {...props}
        />
      </Container>
    );
  }
);

export { HeaderSearchInput };
