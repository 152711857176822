import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

export const Container = styled(Grid)`
  background-color: white;
  padding: 18px 22px;
  box-shadow: 5px 5px 25px rgb(51 60 68 / 8%);
  border-radius: 10px;
  border: 1px solid #cfd3de;
  margin-bottom: 0.5rem;
`;

export const TitleColumn = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.extraSmall};
  `}
`;

export const TitleColumnContent = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.small};
  `}
`;

export const Loading = styled(CircularProgress)`
  ${({ theme }) => css`
    color: ${theme.colors.blue[100]} !important;
  `}
`;
