import React, { createContext, useContext, useState } from 'react';

// Paso 1: Crear el contexto
const ShoppingCartContext = createContext<any>(null);

// Paso 2: Crear el proveedor del contexto
export const ShoppingCartProvider = ({ children }: { children: any }) => {
  // Paso 2.1: Usar useState para manejar el estado del carrito
  const [itemsCount, setItemsCount] = useState(0);

  // Paso 3: Crear una función para actualizar el número de ítems
  const updateItemCount = (newCount: number) => {
    setItemsCount(newCount);
  };

  // Paso 4: Pasar el estado y la función a través del contexto
  return (
    <ShoppingCartContext.Provider value={{ itemsCount, updateItemCount }}>
      {children}
    </ShoppingCartContext.Provider>
  );
};

// Hook personalizado para usar el contexto del carrito de compras
export const useShoppingCart = () => useContext(ShoppingCartContext);
