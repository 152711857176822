import * as Yup from 'yup';
import { DATE_REGEX, parseMoneyNumber } from 'rx-utils';

const email = Yup.string().email('Required field');

const city = Yup.string().required('Please enter a city');

const state = Yup.string().required('Please select a state');

const address = Yup.string().required('Please enter an address');

const lastName = Yup.string().required('Please enter your first');

const firstName = Yup.string().required('Please enter your last name');

const phoneNumber = Yup.string()
  .min(2, 'Please add at least 2 numbers')
  .required('Required field');

const titleOrPosition = Yup.string().required(
  'Please enter your title or position'
);

const productSchema = Yup.object().shape({
  productId: Yup.string().min(1).max(255).notRequired(),
  ndc: Yup.string().min(9).max(20).notRequired(),
  name: Yup.string()
    .min(1)
    .max(255)
    .transform((_, originalValue: string) => {
      return originalValue.replaceAll(DATE_REGEX, '').trim();
    })
    .required('Name is Required'),
  description: Yup.string().min(1).max(255).required('Description is Required'),
  manufacturer: Yup.string()
    .min(1)
    .max(255)
    .required('Manufacturer is Required'),
  expirationDate: Yup.string().required('Expiration Date is Required'),
  price: Yup.number()
    .transform(parseMoneyNumber)
    .positive()
    .required('Price Required field'),
  units: Yup.number().positive().required('Units is Required'),
  lotNumber: Yup.string().max(255).required('Lot Number is Required'),
  minimumQuantity: Yup.number().min(0).required('Minimum Quantity is Required'),
});

const zipCode = Yup.string()
  .matches(/^[0-9]+$/, 'Must be only digits')
  .min(5, 'Must be minimum 5 digits')
  .max(6, 'Must be maximum 6 digits')
  .required('Please enter a zip code');

const password = Yup.string()
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
    'Password has not the correct format.'
  )
  .required('Please enter your password');

export {
  zipCode,
  email,
  password,
  firstName,
  lastName,
  address,
  city,
  state,
  phoneNumber,
  titleOrPosition,
  productSchema,
};
