import React from 'react';

import { Typography } from '@material-ui/core';
import { EmptyText, LinkText } from './index.styles';

type IProps = {
  noResults: boolean;
  noSearchResults: boolean;
};

export const OrdersEmpty: React.FC<IProps> = (props) => {
  return (
    <div>
      {props.noResults && (
        <EmptyText>
          <Typography variant="h5">
            You have no orders in your history. You can start buyin by{' '}
            <LinkText to="/">clicking here.</LinkText>
          </Typography>
        </EmptyText>
      )}
      {props.noSearchResults && (
        <EmptyText>
          <Typography variant="h5">
            <span>There is no orders matching your criteria</span>
            <br />
            <span>Please try another filter selection.</span>
          </Typography>
        </EmptyText>
      )}
    </div>
  );
};
