import React, { useState } from 'react';

import { Button } from '@material-ui/core';

import { GetSaleOrder } from 'rx-domain';

import { useHistory, useParams } from 'react-router-dom';
import { PurchaseOrderRequestFulfillModal } from '../PurchaseOrderRequestFulfillModal';
import { PurchaseOrderRequestBackPage } from '../PurchaseOrderRequestBackPage';

import { Container, Title, FilterContainer } from './index.styles';

type IProps = GetSaleOrder.Response;

export const PurchaseOrderRequestActions: React.FC<IProps> = (props) => {
  const [modal, setModal] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  return (
    <Container>
      <PurchaseOrderRequestBackPage />
      <FilterContainer>
        <Title>
          Purchase Order Request: {props.soPrefix}-{props.id}
        </Title>
        <div>
          {process.env.NODE_ENV !== 'production' && (
            <Button
              disabled={props.status !== 'pending'}
              style={{ marginRight: '10px' }}
              variant="contained"
              color="primary"
              onClick={() => history.push(`/order-selected/${id}/edit`)}
            >
              Edit Order
            </Button>
          )}

          {props.status === 'pending' && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModal(true)}
            >
              Fullfill Order
            </Button>
          )}
        </div>

        <PurchaseOrderRequestFulfillModal
          visible={modal}
          onClose={() => setModal(false)}
        />
      </FilterContainer>
    </Container>
  );
};
