import React from 'react';

import { CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTransactionFilterBuyer } from 'rx-hooks';

import { useStyles } from './index.styles';

type IProps = {
  onGetBuyer: (
    value: {
      id: number;
      pharmacyName: string;
    } | null
  ) => void;
};

const TransactionFilterBuyer = ({ onGetBuyer }: IProps): JSX.Element => {
  const {
    value,
    open,
    options,
    isLoading,
    setOpen,
    setOptions,
    setSearch,
    setValue,
  } = useTransactionFilterBuyer();
  const classes = useStyles({ withValue: !!value });

  return (
    <Autocomplete
      id="buyer-search"
      size="small"
      classes={classes}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionSelected={(option, autocompleteValue) =>
        option.id === autocompleteValue.id
      }
      getOptionLabel={(option) => option?.pharmacyName}
      options={options}
      loading={isLoading}
      value={value}
      filterOptions={(x) => x}
      onChange={(
        event: unknown,
        newValue: {
          id: number;
          pharmacyName: string;
        } | null
      ) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onGetBuyer(newValue);
      }}
      onInputChange={(event, newInputValue) => setSearch(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Buyer"
          variant="outlined"
          focused={false}
          style={{
            width: '200px',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export { TransactionFilterBuyer };
