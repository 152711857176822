import React from 'react';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

export const DashboardTopProductsLoader: React.FC = () => (
  <Paper style={{ padding: '1rem' }}>
    <Skeleton variant="rect" style={{ marginBottom: '1rem' }} height={40} />
    <Skeleton variant="rect" style={{ marginBottom: '1rem' }} height={40} />
    <Skeleton variant="rect" height={40} />
  </Paper>
);
