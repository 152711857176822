import { useMutation } from 'react-query';
import { IPutAddressShippingDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePutAddressShippingId = () => {
  const onRequest = async ({ ...request }: IPutAddressShippingDTO) => {
    return http.put(
      `${
        process.env.REACT_APP_WEBSITE_URL || process.env.API_SERVER
      }/v1/address/shipping`,
      request
    );
  };

  return useMutation(onRequest, {});
};
