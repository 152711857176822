import { useRxMutation } from 'rx-api/utils';
import { ADMIN_URL_V1 } from '../constants';

const DELETE_PRODUCT_PRICING_TIER_ADMIN_URL = (
  productId: number | string,
  pricingTierId: number | string
) => `${ADMIN_URL_V1}/products/${productId}/pricing-tiers/${pricingTierId}`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDeleteProductPricingTierAdmin = (
  id: number | string,
  productId: number | string
) => {
  return useRxMutation<unknown, unknown>({
    method: 'delete',
    url: DELETE_PRODUCT_PRICING_TIER_ADMIN_URL(id, productId),
  });
};
