import { useMutation } from 'react-query';
import { IPostPurchaseOrderCancelDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePostPurchaseOrderCancel = () => {
  const onRequest = async ({ id, ...req }: IPostPurchaseOrderCancelDTO) => {
    return http.post(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/purchase-orders/${id}/cancel`,
      req
    );
  };

  return useMutation(onRequest, {});
};

export { usePostPurchaseOrderCancel };
