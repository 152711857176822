import {
  GET_ADMIN_METRICS_PRODUCTS_MOST_SOLD_URL,
  GET_ADMIN_METRICS_PRODUCTS_MOST_SOLD_KEY,
} from 'rx-api/api/admin/metrics/constants';
import {
  useGetRequest,
  convertUrlWithQuery,
  makeArrayReactQueryKey,
} from 'rx-api/utils';

export namespace GetAdminMetricsTopProducts {
  export type GetTopProductsResponsePayload = Array<{
    name: string;
    totalAmountSold: number;
    totalUnitsSold: number;
    averageSellingPrice: string;
    city: string;
    state: string;
  }>;

  export type RequestQuery = {
    limit: number;
    from: string;
    to: string;
  };
}

const useGetAdminMetricsTopProducts = (
  query: GetAdminMetricsTopProducts.RequestQuery,
  config = {}
) => {
  return useGetRequest<
    unknown,
    GetAdminMetricsTopProducts.GetTopProductsResponsePayload
  >({
    ...config,
    url: convertUrlWithQuery(query, GET_ADMIN_METRICS_PRODUCTS_MOST_SOLD_URL),
    key: makeArrayReactQueryKey(
      GET_ADMIN_METRICS_PRODUCTS_MOST_SOLD_KEY,
      query
    ),
  });
};

export { useGetAdminMetricsTopProducts };
