import React from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';

interface IProps {
  values: {
    newPassword: string;
    confirmPassword: string;
  };
  errors: {
    newPassword?: string;
    confirmPassword?: string;
  };
  onChange(value: any): void;
  onSubmit(value: any): void;
  disabled: boolean;
}

export const AccountPasswordForm: React.FC<IProps> = (props) => {
  return (
    <>
      <Typography variant="h6" style={{ marginBottom: '1rem' }}>
        Change Password
      </Typography>

      <form onSubmit={props.onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="newPassword"
              type="password"
              variant="outlined"
              label="New Password"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={props.onChange}
              value={props.values.newPassword}
              error={!!props.errors.newPassword}
              helperText={props.errors.newPassword}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={6}></Grid>

          <Grid item xs={6}>
            <TextField
              label="Confirm New Password"
              name="confirmPassword"
              type="password"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={props.onChange}
              value={props.values.confirmPassword}
              error={!!props.errors.confirmPassword}
              helperText={props.errors.confirmPassword}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={props.disabled}
            >
              Change Password
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
