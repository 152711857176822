import { PostAdminAddCard } from 'rx-domain';
import { POST_ADMIN_ADD_CARD } from 'rx-api/api/admin/pharmacies/constants';
import { createUrlWithParams, useRxMutation } from 'rx-api/utils';

export const usePostAdminAddCard = (params: PostAdminAddCard.Params) => {
  return useRxMutation<PostAdminAddCard.DTO, unknown>({
    method: 'post',
    url: createUrlWithParams(POST_ADMIN_ADD_CARD, [
      {
        key: 'id',
        value: params.id,
      },
    ]),
  });
};
