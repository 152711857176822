import React, { useEffect } from 'react';

import { TemplateView, SEO, RxPagination } from 'rx-core';

import { useGetSellers } from 'rx-api';

import { useSellers } from 'rx-hooks';

import { Card, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { SellerFilters } from './SellerFilters/SellerFilters';

import { SellerList } from './SellerList';

import { Container } from './SellersView.styles';

export const SellerListView = (): JSX.Element => {
  const { totalPages, querySeller, queryStatus, skip, setLength } =
    useSellers();

  const { data: sellersRes, isLoading } = useGetSellers({
    skip,
    approvalStatus: queryStatus,
    shipper: querySeller,
  });

  useEffect(() => {
    if (sellersRes?.data && !isLoading) {
      setLength(sellersRes.data.pagination.total);
    }
  }, [sellersRes, isLoading, setLength]);

  return (
    <TemplateView>
      <SEO title="RxRise | Manufacturers" />
      <Container>
        <SellerFilters
          loading={isLoading}
          queries={{
            approvalStatus: queryStatus,
            pharmacy: querySeller,
          }}
        />

        {!isLoading && (sellersRes?.data?.results?.length ?? 0) > 0 && (
          <SellerList items={sellersRes?.data?.results ?? []} />
        )}

        {!isLoading && (sellersRes?.data?.results?.length ?? 0) === 0 && (
          <Card variant="outlined" style={{ padding: '1rem' }}>
            <Typography>
              No manufacturers found with that search criteria.
            </Typography>
          </Card>
        )}

        {isLoading && (
          <Card variant="outlined" style={{ padding: '1rem' }}>
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
          </Card>
        )}

        {totalPages > 1 && (
          <RxPagination totalPages={totalPages} path="manufacturers" />
        )}
      </Container>
    </TemplateView>
  );
};
