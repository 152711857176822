import React, { useEffect } from 'react';

import { TemplateView, SEO, RxPagination } from 'rx-core';

import { useGetPharmacyList } from 'rx-api';
import { useBuyers } from 'rx-hooks';

import { BuyerList } from './BuyerList';
import { BuyerFilters } from './BuyerFilters';

import { Container } from './index.styles';

export const BuyerListView: React.FC = () => {
  const { totalPages, queryPharmacy, skip, setLength } = useBuyers();

  const { data: buyersResponse, isLoading } = useGetPharmacyList({
    search: queryPharmacy,
    skip,
  });

  useEffect(() => {
    if (buyersResponse?.data && !isLoading) {
      setLength(buyersResponse.data.pagination.total);
    }
  }, [buyersResponse, isLoading, setLength]);

  return (
    <TemplateView>
      <SEO title="RxRise | Pharmacists" />
      <Container>
        <BuyerFilters
          loading={isLoading}
          queries={{ pharmacy: queryPharmacy }}
        />

        {buyersResponse?.data && (
          <BuyerList items={buyersResponse?.data.results} />
        )}

        {totalPages > 1 && (
          <RxPagination totalPages={totalPages} path="pharmacies" />
        )}
      </Container>
    </TemplateView>
  );
};
