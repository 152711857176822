import { filter, take, map, chain } from 'ramda';

import { IProductData, IDrug, SearchQueryParams } from 'rx-domain';

import { capitalize } from './formatters';

const filterProductsByNDC = (
  nameOrNdc: string,
  results: IProductData[]
): IProductData[] => {
  return filter((drug) => drug.ndc === nameOrNdc, results);
};

const filterSearchResulsByNDC = (
  nameOrNdc: string,
  results: IDrug[]
): IDrug[] => {
  return filter((drug) => drug.ndc === nameOrNdc, results);
};

const filterSearchResults = (results: IProductData[]): IDrug[] => {
  return chain<IDrug[], IDrug[], IProductData[]>(
    take(5),
    map((drug: IProductData) => ({
      id: drug.id ? parseInt(drug.id, 10) : 0,
      ndc: drug.ndc,
      name: capitalize(drug.name),
      searchName: drug.name,
    }))
  )(results);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const onMakeURL = (values: SearchQueryParams, initialParams = '') => {
  const params = Object.entries(values)
    .map(([key, value]) => (value ? `${key}=${value}` : ''))
    .filter((parameter) => parameter);

  return `?${initialParams}${params.join('&')}`;
};

export {
  filterProductsByNDC,
  filterSearchResulsByNDC,
  filterSearchResults,
  onMakeURL,
};
