import { GetProducts } from 'rx-domain';
import styled, { css } from 'styled-components';
import { Grid, Paper, Tooltip } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { formatMoney, getDateValue } from 'rx-utils';

import RxRiseRecommendedIcon from '../../../../assets/icons/rxrise-icon.svg';
import RxRiseLogo from '../../../../assets/logos/rxrise-gray-logo.svg';
import { ProductItemActions } from '../../products/molecules/ProductItemActions';
import { FeaturedProductItemInfo } from '../FeaturedProductItemInfo/FeaturedProductItemInfo';

type IProductItemProps = GetProducts.ProductItem;

const Container = styled(Paper)`
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  min-height: 20.25rem;
  padding: 1rem 1rem 0.5rem 1rem;
  position: relative;
`;

const Spacer = styled.div`
  flex: 1;
`;

const Section = styled(Grid)`
  margin-bottom: 0.5rem;
`;

const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.blue[300]};
    font-size: ${theme.sizes.small};
    font-family: ${theme.fonts.robotoRegular};
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

const Text = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.small};
    font-family: ${theme.fonts.robotoLight};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

const TextRegular = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.sizes.small};
    font-family: ${theme.fonts.robotoRegular};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

const RegisterAText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.blue[300]};
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoRegular};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

const ImageProduct = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ImageProductContainer = styled.figure`
  width: 100%;
  height: 10rem;
  margin-top: 1.5rem;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
`;

const ImagePlaceholder = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Featured = styled.div`
  background-color: #ffeb94;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 100%;
  left: 0;
  text-align: center;
  padding: 0.3rem 1rem 0.3rem 0.75rem;
  position: absolute;
  top: 0;
`;

export const FeaturedIcon = styled.img`
  display: inline-block;
  vertical-align: middle;
`;

export const FeaturedLabel = styled.p`
  display: inline-block;
  vertical-align: middle;
`;

const FeaturedProduct: React.FC<IProductItemProps> = (props) => {
  const history = useHistory();

  const onDisplayItem = () => {
    history.push(`/product/${props.id}`);
  };

  return (
    <Container elevation={3} data-cy="product-card">
      {props.isFeatured && (
        <Featured>
          <FeaturedIcon src={RxRiseRecommendedIcon} alt="" />
          <FeaturedLabel>recommends</FeaturedLabel>
        </Featured>
      )}
      <Section>
        {props.pictureUrl && (
          <ImageProductContainer>
            <ImageProduct
              src={props.pictureUrl ? props.pictureUrl : 'other'}
              alt={props.name}
            />
          </ImageProductContainer>
        )}
        {!props.pictureUrl && (
          <ImagePlaceholder>
            <img src={RxRiseLogo} alt="" />
            <p>Product Image Not Available</p>
          </ImagePlaceholder>
        )}
      </Section>
      <Section container justifyContent="space-between" wrap="nowrap">
        <Grid item xs={7} md={9} zeroMinWidth>
          <Tooltip title={props.name} arrow placement="bottom-start">
            <Title
              data-testid={`products-list-item-${props.id}`}
              onClick={onDisplayItem}
            >
              {props.name}
            </Title>
          </Tooltip>
        </Grid>
        <Grid container item xs={5} md={3} justifyContent="flex-end">
          <Text>{formatMoney(props.price)}</Text>
        </Grid>
      </Section>
      {props.productId && (
        <Section container>
          <Grid item xs={12}>
            <Text>
              <TextRegular>Product ID:</TextRegular> {props.productId}
            </Text>
          </Grid>
        </Section>
      )}
      <Section container wrap="nowrap">
        <Grid item xs={12} zeroMinWidth>
          <Tooltip title={props.description} arrow placement="bottom-start">
            <Text>{props.description !== 'N/A' && props.description}</Text>
          </Tooltip>
        </Grid>
      </Section>
      <Section container wrap="nowrap">
        <FeaturedProductItemInfo
          title="EXP. DATE"
          content={getDateValue(props.expirationDate)}
        />
        <FeaturedProductItemInfo title="LOT NUMBER" content={props.lotNumber} />
        <FeaturedProductItemInfo
          title="MIN. ORDER"
          content={props.minimumQuantity}
        />
        <FeaturedProductItemInfo title="AVAILABLE" content={props.units} />
      </Section>
      <Spacer />
      <hr />
      <ProductItemActions isHomePageFeatured={true} {...props} />
    </Container>
  );
};

export { FeaturedProduct };
