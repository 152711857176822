import React from 'react';
import styled, { css } from 'styled-components';

type IProps = {
  percentage: number;
  type: 'flat' | 'compound';
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.75rem;
  width: fit-content;
  margin-bottom: 1rem;
`;

const Title = styled.div`
  ${({ theme }) => css`
    text-transform: uppercase;
    font-size: ${theme.sizes.extraSmall};
    font-family: ${theme.fonts.robotoRegular};
    line-height: 14px;

    color: ${theme.colors.black[100]}};
    font-weight: 400;
    margin-bottom: 0.5rem;
  `}
`;

const Table = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  border: 1px #2b3647 solid;
  padding: 0.5rem;
`;

const TableItem = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.sizes.small};
    font-weight: 400;
    font-family: ${theme.fonts.robotoRegular};
    line-height: 14px;
    margin-bottom: 0.5rem;
  `}
`;

export const ManufacturerFeesTable: React.FC<IProps> = ({
  type,
  percentage,
}) => {
  const percentageValid = Number.isNaN(percentage) ? 0 : percentage;

  const cardFeeValue =
    type === 'flat' ? percentageValid : `${percentageValid + 2.9}% + .30`;
  const achFeeValue = type === 'flat' ? percentageValid : percentageValid + 0.8;
  const paymentTermsValue = percentageValid;

  return (
    <Container>
      <Title>Fees table:</Title>
      <Table>
        <TableItem>With Card Fee = {cardFeeValue}</TableItem>
        <TableItem>With ACH Fee = {achFeeValue}%</TableItem>
        <TableItem>With Paymet Terms Fee = {paymentTermsValue}%</TableItem>
      </Table>
    </Container>
  );
};
