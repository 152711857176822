import * as Yup from 'yup';
import { BulkProductSchema } from './BulkProductsValidator';
import { validateName, validateNDC } from './common';

const FILE_SIZE = 2 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];

const AddProductValidator = BulkProductSchema.shape({
  category: Yup.string().required(),
  picture: Yup.mixed()
    .test('fileSize', 'File too large', (value) => {
      return value && value.size <= FILE_SIZE;
    })
    .test(
      'fileFormat',
      'Unsupported Format',
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
});

const AddBannedProductValidator = Yup.object().shape({
  ndc: Yup.string().min(10).max(20).required('NDC is required'),
  name: validateName,
  description: Yup.string().nullable(true).default('N/A').min(1).max(255),
});

export { AddProductValidator, AddBannedProductValidator };
