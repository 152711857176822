import React from 'react';

import { Container, LoadingIcon } from './index.styles';

const OrdersLoading = (): JSX.Element => {
  return (
    <Container>
      <LoadingIcon size={26} thickness={6} />
    </Container>
  );
};

export { OrdersLoading };
