import React, { useState, useEffect } from 'react';

import {
  GetSaleOrdersStatus,
  ITableHeader,
  UserApprovalStatus,
} from 'rx-domain';

import { RxPagination, RxTable, RxTableHeader } from 'rx-core';

import { usePaginationQueries, useSkipPage } from 'rx-hooks';

import { useGetSaleOrders } from 'rx-api';

import { useFilter } from 'rx-contexts';

import { MOItems } from '../MOItems';
import { ProductsEmptyState } from '../../../admin/productsV2/molecules/ProductsEmptyState';

import { Container, PaginationContainer } from './index.styles';

const MO_HEADER: Array<ITableHeader> = [
  { title: 'PURCHASE ORDER NUMBER', fontWeight: 'bold', span: 1 },
  { title: 'ORDER DATE', fontWeight: 'bold', span: 1 },
  { title: 'STATUS', fontWeight: 'bold', span: 1 },
  { title: 'ITEMS', fontWeight: 'bold', span: 1 },
  { title: 'TOTAL', fontWeight: 'bold', span: 1 },
];

const MOTab = (): JSX.Element => {
  const [length, setLength] = useState(0);

  const { skip, totalPages } = useSkipPage(length);
  const { onSetLoading, search } = useFilter<UserApprovalStatus>();
  const { queryMOStatus } = usePaginationQueries();

  const { data, isLoading } = useGetSaleOrders({
    status: queryMOStatus as GetSaleOrdersStatus,
    skip,
    id: search,
  });

  useEffect(() => {
    if (data?.data && !isLoading) {
      setLength(data.data.pagination.total);
    }
  }, [data, isLoading]);

  useEffect(() => {
    onSetLoading(isLoading);
  }, [isLoading, onSetLoading]);

  return (
    <Container>
      <RxTable>
        <RxTableHeader items={MO_HEADER} />
        {data?.data.results && data.data.results.length === 0 && search && (
          <ProductsEmptyState
            message={
              <>
                <span>"There is no orders matching your criteria"</span>
                <br />
                <span>Please try another seach."</span>
              </>
            }
            colSpan={5}
          />
        )}
        {data?.data.results && data.data.results.length === 0 && (
          <ProductsEmptyState
            message={
              <>
                <span>You don't have any orders yet</span>
              </>
            }
            colSpan={5}
          />
        )}
        <MOItems items={data?.data.results ?? []} />
      </RxTable>

      {totalPages > 1 && (
        <PaginationContainer>
          <RxPagination path="manage-orders" totalPages={totalPages} />
        </PaginationContainer>
      )}
    </Container>
  );
};

export { MOTab };
