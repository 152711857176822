import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';
import { FieldProps } from 'formik';

import { DataState } from 'rx-domain';

import { RxText } from 'rx-core/components/texts/RxText';
import { StatusCheckIcon } from '../../icons';

import { InputTitle } from '../InputTitle/InputTitle';
import { InputText } from '../InputText/InputText';
import { RxInputErrorMessage } from '../RxInputErrorMessage/RxInputErrorMessage';

export type InputProps = FieldProps &
  InputHTMLAttributes<HTMLInputElement> & {
    label: string;
    state?: DataState;
    className?: string;
    inputError: boolean;
    message?: string;
  };

const Container = styled.div<LayoutProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${layout}
`;

const CheckIcon = styled(StatusCheckIcon)`
  height: 28px;
  width: 28px;
  position: absolute;
  bottom: 11px;
  right: -42px;
`;

const InputContainer = styled.div<LayoutProps>`
  position: relative;
  ${layout};
`;

const CustomInputTitle = styled(InputTitle)`
  margin-bottom: 10px;
`;

const Input: React.FC<InputProps> = ({
  label,
  className,
  state,
  width,
  inputError,
  message,
  field: { name, ...fieldProps },
  form: { errors, touched },
  ...props
}: InputProps & LayoutProps) => {
  return (
    <Container className={className}>
      {label && (
        <CustomInputTitle
          title={label}
          htmlFor={name}
          error={state === 'error' && inputError}
        />
      )}
      <InputContainer width={width}>
        <InputText
          id={name}
          name={name}
          {...fieldProps}
          {...props}
          error={state === 'error' && inputError}
        />
        {state !== 'none' && !inputError && <CheckIcon type={state} />}
      </InputContainer>
      <RxInputErrorMessage>
        {touched[name] && errors[name] && errors[name]}
      </RxInputErrorMessage>
      {message && (
        <RxText font="Roboto Light" size="small" mb={12} color="#838ca5">
          {message}
        </RxText>
      )}
    </Container>
  );
};

Input.defaultProps = {
  className: '',
  message: '',
  state: 'none',
};

export { Input };
