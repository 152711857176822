import React from 'react';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

import ArrowRightIcon from '../../../assets/icons/arrow-right.png';

export type FooterInputProps = LayoutProps & {
  onChange?: (value: string) => void;
  onKeyDown?: () => void;
  onClick?: () => void;
  onClickArrow?: () => void;
  value?: string;
  placeholder?: string;
};

const Input = styled.input`
  width: 100%;
  height: 60px;
  background: transparent;
  border: 0px;
  outline: none;
  color: white;
  border-bottom: 1px solid #838ca5;

  font-family: Roboto Regular;
  font-size: 18px;
  line-height: 25px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #838ca5;
    font-family: Roboto Regular;
    font-style: normal;
    font-weight: normal;
  }
`;

const ArrowRightImg = styled.img`
  height: 13px;
  width: 15px;
  cursor: pointer;
`;

const ArrowRightContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 8px;
`;

const Container = styled.div`
  position: relative;
  ${layout}
`;

const FooterInput = ({
  onChange,
  value,
  placeholder,
  onKeyDown,
  onClickArrow,
  ...props
}: FooterInputProps): JSX.Element => {
  return (
    <Container {...props}>
      <Input
        onChange={({ target: { value: val } }) => {
          if (onChange) onChange(val);
        }}
        value={value}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
      />
      <ArrowRightContainer>
        <ArrowRightImg src={ArrowRightIcon} onClick={onClickArrow} />
      </ArrowRightContainer>
    </Container>
  );
};

FooterInput.defaultProps = {
  onChange: () => {},
  onKeyDown: () => {},
  onClick: () => {},
  onClickArrow: () => {},
  value: '',
  placeholder: '',
};

export { FooterInput };
