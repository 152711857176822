import React from 'react';
import styled, { css } from 'styled-components';

import { NewSearchFilters } from './NewSearchFilters';
import NewSearchAutoCompleteInput from './NewSearchAutoCompleteInput';
import { NewSearchFindButton } from './NewSearchFindButton';

type IProps = {
  isHomePage?: boolean;
};

const Container = styled.div`
  ${({ theme }) => css`
    min-width: 38.875rem;
    height: 3.5rem;

    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 2.25rem;

    display: flex;
    align-items: center;

    ${theme.lessThan('ipadPro')} {
      display: none;
    }

    ${theme.lessThan('desktop')} {
      width: 31.875rem;
      min-width: 31.875rem;
    }
  `}
`;

const SearchHeader = (props: IProps): JSX.Element => {
  return (
    <Container {...props}>
      {process.env.REACT_APP_IS_AI_ENABLED !== 'true' && (
        <>
          <NewSearchFilters />
          <NewSearchAutoCompleteInput />
          <NewSearchFindButton />
        </>
      )}
      {process.env.REACT_APP_IS_AI_ENABLED === 'true' && !props.isHomePage && (
        <>
          <NewSearchFilters />
          <NewSearchAutoCompleteInput />
          <NewSearchFindButton />
        </>
      )}
    </Container>
  );
};

export { SearchHeader };
