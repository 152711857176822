import { Button, Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';

interface IProps {
  pharmacyName: string;
  dea: string;
  stateLicense: string;
  billingAddress: string;
  billingAddressName: string;
  shippingAddress: string;
}

export const NewPurchaseOrderSelectedPharmacy: React.FC<IProps> = (props) => {
  return (
    <section>
      <Typography variant="h6" style={{ marginBottom: '0.5rem' }}>
        Pharmacy Information
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography>
            <strong>Name</strong>: {props.pharmacyName}
          </Typography>
          <Typography>
            <strong>DEA</strong>: {props.dea}
          </Typography>
          <Typography>
            <strong>State License</strong>: {props.stateLicense}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography>
            <strong>Shipping Address</strong>
          </Typography>
          <Typography>{props.shippingAddress}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography>
            <strong>Billing Address</strong>
          </Typography>
          <Typography>{props.billingAddressName}</Typography>
          <Typography>
            <Typography>{props.billingAddress}</Typography>
          </Typography>
        </Grid>
      </Grid>
    </section>
  );
};
