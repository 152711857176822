type SettingsTab = {
  text: string;
  to: string;
  dataCy: string;
};

export const settingsBuyerTabs = (): SettingsTab[] => [
  {
    text: 'My Profile',
    to: '/settings/profile',
    dataCy: 'account-settings-profile',
  },
  {
    text: 'My Pharmacies',
    to: '/settings/pharmacies',
    dataCy: 'account-settings-pharmacy',
  },
  {
    text: 'Security',
    to: '/settings/security',
    dataCy: 'account-settings-security',
  },
];
