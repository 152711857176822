import React from 'react';
import { useQueryClient } from 'react-query';
import {
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { ITransaction } from 'rx-domain';
import { capitalize, formatMoney, getDateValue } from 'rx-utils';
import {
  useAdminDeleteTransaction,
  useToast,
  useTransactionOrder,
  useTransactionOrderActions,
} from 'rx-hooks';
import { RxMenu } from 'rx-core';

import { TransactionPaymentStatus } from '../TransactionPaymentStatus';

type IProps = ITransaction;

const formatPaymentType = (paymentType: string) => {
  return paymentType.split('_').map(capitalize).join(' ');
};

const StyledTableCell = withStyles((theme) => {
  return createStyles({
    body: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
  });
})(TableCell);

export const TransactionOrder: React.FC<IProps> = (props) => {
  const [isDeleteTransactionModalOpen, setIsDeleteTransactionModalOpen] =
    React.useState(false);
  const { transactionOrderId, grossTotalAmount, netTotalAmount } =
    useTransactionOrder(props);
  const deleteTransaction = useAdminDeleteTransaction();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { anchorEl, onClose, onOpenMenu, downloadOptions } =
    useTransactionOrderActions(props);

  const onClickDeleteTransaction = () => {
    setIsDeleteTransactionModalOpen(true);
  };

  const handleCloseDeleteTransactionModal = () => {
    setIsDeleteTransactionModalOpen(false);
  };

  const handleClickConfirmDeleteTransaction = async () => {
    try {
      await deleteTransaction.mutateAsync(props.id);
      await queryClient.refetchQueries('get-transactions', {
        active: true,
      });
      toast.onSuccessToast('Transaction Deleted');
    } catch (err) {
      toast.onErrorToast('Error: could not delete transaction');
    }
  };

  return (
    <>
      <Dialog
        open={isDeleteTransactionModalOpen}
        onClose={handleCloseDeleteTransactionModal}
        aria-labelledby="delete-transaction-dialog-title"
        aria-describedby="delete-transaction-dialog-description"
      >
        <DialogTitle id="delete-transaction-dialog-title">
          Are you sure you want to delete the entire transaction?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action will remove all the order, order items, invoices and
            records associated with this transaction.
          </DialogContentText>

          <DialogContentText>
            This action is permantent and cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
          }}
        >
          <Button
            onClick={handleCloseDeleteTransactionModal}
            color="primary"
            variant="contained"
            disabled={deleteTransaction.isLoading}
            autoFocus
          >
            No, Cancel
          </Button>
          <Button
            onClick={handleClickConfirmDeleteTransaction}
            variant="outlined"
            color="secondary"
            disabled={deleteTransaction.isLoading}
          >
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow>
        <StyledTableCell>{transactionOrderId}</StyledTableCell>
        <StyledTableCell>{getDateValue(props.createdAt)}</StyledTableCell>
        <StyledTableCell>
          {formatPaymentType(props.paymentType)}
        </StyledTableCell>
        <StyledTableCell>{getDateValue(props.order.dueDate)}</StyledTableCell>
        <StyledTableCell>
          {props.order.payoutDate
            ? getDateValue(props.order.payoutDate)
            : 'N/A'}
        </StyledTableCell>
        <StyledTableCell>{formatMoney(grossTotalAmount)}</StyledTableCell>
        <StyledTableCell>{formatMoney(netTotalAmount)}</StyledTableCell>
        <StyledTableCell>
          <TransactionPaymentStatus status={props.order.status} />
        </StyledTableCell>
        <StyledTableCell>
          <RxMenu
            anchorEl={anchorEl}
            onClose={onClose}
            onOpenMenu={onOpenMenu}
            options={[
              ...downloadOptions,
              {
                type: 'DELETE_TRANSACTION',
                text: 'Delete Entire Transaction',
                onClick: onClickDeleteTransaction,
              },
            ]}
          />
        </StyledTableCell>
      </TableRow>
    </>
  );
};
