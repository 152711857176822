import * as React from 'react';

import { Text } from './index.styles';

type IProps = {
  bold?: boolean;
};

const AdminSellerCardSubTitle: React.FC<IProps> = ({
  children,
  ...props
}): JSX.Element => {
  return <Text {...props}>{children}</Text>;
};

export { AdminSellerCardSubTitle };
