import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export const ProductsTableLoader: React.FC = () => {
  return (
    <>
      <TableRow>
        <TableCell colSpan={11}>
          <Skeleton height={50} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={11}>
          <Skeleton height={50} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={11}>
          <Skeleton height={50} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={11}>
          <Skeleton height={50} />
        </TableCell>
      </TableRow>
    </>
  );
};
