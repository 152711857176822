import React from 'react';
import styled, { css } from 'styled-components';

import { useAddPayment } from 'rx-contexts';

import { AddPaymentMethodDebitCreditForm } from './AddPaymentMethodDebitCreditForm';
import { AddPaymentMethodACHForm } from './AddPaymentMethodACHForm';

const Container = styled.div`
  ${({ theme }) => css`
    flex: 1;
    background-color: white;

    ${theme.lessThan('desktop')} {
      overflow: auto;
    }
  `}
`;

const AddPaymentMethodContent = (): JSX.Element => {
  const { view } = useAddPayment();

  return (
    <Container>
      {view === 'ach' && <AddPaymentMethodACHForm />}
      {view === 'debit-credit-card' && <AddPaymentMethodDebitCreditForm />}
    </Container>
  );
};

export { AddPaymentMethodContent };
