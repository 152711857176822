import React from 'react';
import { Paper, Tab, Tabs } from '@material-ui/core';

import { TemplateView, SEO } from 'rx-core';

import { Container } from './index.styles';
import { PaymentReports } from './PaymentsReports';
import { OrderReports } from './OrderReports';

export const ReportsView: React.FC = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <TemplateView>
      <SEO title="RxRise | Reports" />
      <Container>
        <Paper variant="outlined" style={{ marginBottom: '1rem' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            variant="fullWidth"
            defaultValue={0}
          >
            <Tab label="Payments" />
            <Tab label="Orders" />
          </Tabs>
        </Paper>

        {value === 0 && <PaymentReports />}
        {value === 1 && <OrderReports />}
      </Container>
    </TemplateView>
  );
};
