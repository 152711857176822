import { IGetPharmacistPharmacyResponse } from 'rx-domain';

import { useGetRequest } from 'rx-api/utils';

import { GET_PHARMACIST_PHARMACY_BY_ID_URL } from './constants';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetPharmacistPharmacyById = (id: number | string) => {
  return useGetRequest<unknown, IGetPharmacistPharmacyResponse>({
    url: GET_PHARMACIST_PHARMACY_BY_ID_URL(id),
    key: ['get-pharmacist-pharmacy-by-id', id],
  });
};
