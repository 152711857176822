import * as Yup from 'yup';

const BuyerTradeRefSchema = Yup.object().shape({
  account1: Yup.string().notRequired(),
  account2: Yup.string().notRequired(),
  primaryWholesaler: Yup.string().notRequired(),
  secondaryWholesaler: Yup.string().notRequired(),
});

export { BuyerTradeRefSchema };
