import { GetAdminProducts } from 'rx-domain';
import { useGetRequest } from 'rx-api/utils';
import {
  GET_ADMIN_PRODUCTS_URL,
  GET_ADMIN_PRODUCTS_KEY,
} from 'rx-api/api/admin/products/constants';

const makeParams = (req: Partial<GetAdminProducts.Query>) => {
  let query = `?limit=10`;

  if (req.limit) {
    query = `?limit=${req.limit}`;
  }

  if (req.skip) {
    query += `&skip=${req.skip}`;
  }

  if (!req.category) {
    query += `&category=drug`;
  } else if (req.category === 'all') {
    query += ``;
  } else {
    query += `&category=${req.category}`;
  }

  if (req.id) {
    query += `&id=${req.id}`;
  }

  if (req.name) {
    query += `&name=${req.name}`;
  }

  if (req.ndc) {
    query += `&ndc=${req.ndc}`;
  }

  if (req.orderBy) {
    query += `&orderBy=${req.orderBy}`;
  }

  if (req.orderCriteria) {
    query += `&orderCriteria=${req.orderCriteria}`;
  }

  if (req.shipper) {
    query += `&shipper=${req.shipper}`;
  }

  return query;
};

const useGetAdminProducts = (query: Partial<GetAdminProducts.Query> = {}) => {
  return useGetRequest<
    Partial<GetAdminProducts.Query>,
    GetAdminProducts.Response
  >({
    url: GET_ADMIN_PRODUCTS_URL,
    key: [
      GET_ADMIN_PRODUCTS_KEY,
      query.limit,
      query.skip,
      query.id,
      query.ndc,
      query.name,
      query.shipper,
      query.category,
      query.orderBy,
      query.orderCriteria,
    ],
    makeParams,
    params: query,
  });
};

export { useGetAdminProducts };
