import React, { InputHTMLAttributes } from 'react';

import { Container, RadioInput } from './index.styles';

type ICheckoutPaymentCardACHInputProps =
  InputHTMLAttributes<HTMLInputElement> & {
    text: string;
  };

export const CheckoutPaymentCardACHInput: React.FC<
  ICheckoutPaymentCardACHInputProps
> = (props) => {
  return (
    <Container>
      <RadioInput name="" {...props} />
    </Container>
  );
};
