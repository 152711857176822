import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useV3PostSigninPharmacy = () => {
  return useMutation(
    ['register-pharmacist'],
    async (request: FormData) => {
      return http.post(
        `${process.env.REACT_APP_WEBSITE_URL}/v3/signin/pharmacy`,
        request,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    },
    {}
  );
};
