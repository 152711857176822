import { IGetSellerEmailRecipients } from 'rx-domain';
import { useGetRequest, createUrlWithParams } from 'rx-api/utils';
import {
  GET_ADMIN_SELLER_EMAIL_RECIPIENTS,
  GET_ADMIN_SELLER_EMAIL_RECIPIENTS_KEY,
} from 'rx-api/api/admin/manufacturers/constants';

const useGetSellerEmailRecipients = (
  params: IGetSellerEmailRecipients.Params
) => {
  return useGetRequest<
    IGetSellerEmailRecipients.Params,
    IGetSellerEmailRecipients.Response
  >({
    url: createUrlWithParams(GET_ADMIN_SELLER_EMAIL_RECIPIENTS, {
      key: 'id',
      value: params.id,
    }),
    key: [GET_ADMIN_SELLER_EMAIL_RECIPIENTS_KEY, params.id],
  });
};

export { useGetSellerEmailRecipients };
