import { GetContactInfo } from 'rx-domain';
import { useGetRequest } from 'rx-api/utils';
import {
  GET_ACCOUNT_CONTACT_INFO_KEY,
  GET_ACCOUNT_CONTACT_INFO_URL,
} from './constanst';

export const useGetContactInfo = () => {
  return useGetRequest<unknown, GetContactInfo.Response>({
    url: GET_ACCOUNT_CONTACT_INFO_URL,
    key: [GET_ACCOUNT_CONTACT_INFO_KEY],
  });
};
