import { DeleteAdminPaymentMethod } from 'rx-domain';
import { DELETE_ADMIN_ACH } from 'rx-api/api/admin/pharmacies/constants';
import { createUrlWithParams, useRxMutation } from 'rx-api/utils';

export const useDeleteAdminACH = (params: DeleteAdminPaymentMethod.Params) => {
  return useRxMutation<unknown, unknown>({
    url: createUrlWithParams(DELETE_ADMIN_ACH, [
      {
        key: 'id',
        value: params.id,
      },
      {
        key: 'achId',
        value: params.paymentId,
      },
    ]),
    method: 'delete',
  });
};
