import React from 'react';

import { HeaderLink } from '../HeaderLink';
import { HeaderSettings } from '../HeaderSettings';

import { Container, Spacer } from './index.styles';

const HeaderSeller = (): JSX.Element => {
  return (
    <Container>
      <HeaderLink to="/" exact text="Inventory" />
      <HeaderLink to="/manage-orders" text="Manage orders" />
      <Spacer />
      <HeaderSettings />
    </Container>
  );
};

export { HeaderSeller };
