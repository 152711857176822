import * as Yup from 'yup';

import { email, password } from './common.validator';

const ResetPasswordSchema = Yup.object().shape({
  email,
  password,
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  token: Yup.string().required(),
});

export { ResetPasswordSchema };
