import React, { useCallback } from 'react';
import debounce from 'lodash.debounce';
import { useHistory } from 'react-router-dom';

import { UserApprovalStatus } from 'rx-domain';

import { useFilter } from 'rx-contexts';

import { usePaginationQueries } from 'rx-hooks';

import { RxTableSearch } from 'rx-core';

const MOSearch = (): JSX.Element => {
  const history = useHistory();

  const { onSetLazyLoading, onSearch, loading, lazyLoading } =
    useFilter<UserApprovalStatus>();
  const { onMakeURL, queryMOStatus } = usePaginationQueries();

  const onWaitSearchText = useCallback(
    debounce((value) => {
      onSearch(value.trim());
      onSetLazyLoading(false);

      const fullURL = onMakeURL({
        search: value.trim(),
        mo: queryMOStatus,
      });

      history.push(fullURL);
    }, 750),
    []
  );

  const onSearchByOrderId = (event: React.SyntheticEvent) => {
    onSetLazyLoading(true);

    const target = event.target as HTMLInputElement;

    if (!target.value) {
      onSearch('');
      onSetLazyLoading(false);

      const fullURL = onMakeURL({
        search: '',
        mo: queryMOStatus,
      });

      history.push(fullURL);
      return;
    }

    onWaitSearchText(target.value);
  };

  return (
    <RxTableSearch
      loading={loading || lazyLoading}
      onSearchInput={onSearchByOrderId}
      placeholder="Search by order number"
    />
  );
};

export { MOSearch };
