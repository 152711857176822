import { GetPharmacyList } from 'rx-domain';
import {
  GET_PHARMACY_LIST_KEY,
  GET_PHARMACY_LIST_URL,
} from 'rx-api/api/admin/pharmacies/constants';
import { useGetRequest } from 'rx-api/utils';

const makeParams = (req: Partial<GetPharmacyList.RequestQuerySearch>) => {
  let query = `?limit=10`;

  if (req.limit) {
    query = `?limit=${req.limit}`;
  }

  if (req.skip) {
    query += `&skip=${req.skip}`;
  }

  if (req.search) {
    query += `&search=${req.search}`;
  }

  if (req.isRequest) {
    query += `&isRequest=true`;
  }

  return query;
};

export const useGetPharmacyList = (
  query: Partial<GetPharmacyList.RequestQuerySearch>
) => {
  return useGetRequest<
    Partial<GetPharmacyList.RequestQuerySearch>,
    GetPharmacyList.ResponseBodyPayload
  >({
    url: GET_PHARMACY_LIST_URL,
    key: [GET_PHARMACY_LIST_KEY, query.limit, query.skip, query.search],
    makeParams,
    params: query,
  });
};
