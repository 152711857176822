import React from 'react';
import styled from 'styled-components';

import { Tooltip } from '@material-ui/core';

import { RxInputTable } from 'rx-core';

type IProps = {
  text: string | JSX.Element;
  name: string;
  mode: 'VIEW' | 'EDIT';
};

const TableCell = styled.td`
  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  color: black;

  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-all;
`;

const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AdminProductDateItemCell = ({
  text,
  mode,
  name,
}: IProps): JSX.Element => {
  return (
    <TableCell>
      {mode === 'VIEW' ? (
        <Tooltip title={text} placement="bottom">
          <Text>{text}</Text>
        </Tooltip>
      ) : (
        <RxInputTable name={name} />
      )}
    </TableCell>
  );
};

export { AdminProductDateItemCell };
