import React, { createContext, FC, useContext, useState } from 'react';
import { includes, filter, uniq, without } from 'ramda';
import { GetAdminProducts, IBlackListItem } from 'rx-domain';

type ContextProps = {
  selectAll: boolean;
  modalDelete: boolean;
  modalCancel: boolean;
  productsMode: 'VIEW' | 'EDIT';
  blackListMode: 'VIEW' | 'EDIT';
  deleteProduct: GetAdminProducts.ProductItem | undefined;
  deleteBlackItem: IBlackListItem | undefined;
  lengthData: number;
  lengthBlackListData: number;
  onSelectAll: () => void;
  isSelected: (id: string) => boolean;
  setSelectedProduct: (id: string) => void;
  setAllSelected: (productsId: Array<string>) => void;
  setOffCurrentSelected: (productsId: Array<string>) => void;
  onModalCancel: () => void;
  onModalDelete: () => void;
  onSetProductsMode: (mode: 'VIEW' | 'EDIT') => void;
  onSetBlackListMode: (mode: 'VIEW' | 'EDIT') => void;
  onSetDeleteProduct: (product: GetAdminProducts.ProductItem) => void;
  onSetDeleteBlackItem: (blackItem: IBlackListItem) => void;
  onSetLengthData: (length: number) => void;
  onSetLengthBlackListData: (length: number) => void;
};

const AdminProductsContext = createContext<ContextProps | undefined>(undefined);

const AdminProductsProvider: FC = ({ children }) => {
  const [lengthData, setLengthData] = useState<number>(0);
  const [lengthBlackListData, setLengthBlackListData] = useState<number>(0);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [deleteProduct, setDeleteProduct] = useState<
    GetAdminProducts.ProductItem | undefined
  >(undefined);
  const [deleteBlackItem, setDeleteBlackItem] = useState<
    IBlackListItem | undefined
  >(undefined);
  const [productsMode, setProductsMode] = useState<'VIEW' | 'EDIT'>('VIEW');
  const [blackListMode, setBlackListMode] = useState<'VIEW' | 'EDIT'>('VIEW');
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [modalCancel, setModalCancel] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<Array<string>>([]);

  const onSelectAll = () => setSelectAll((prev) => !prev);

  const isSelected = (id: string) => {
    return !!selectedProducts.find((product) => product === id);
  };

  const setSelectedProduct = (id: string) => {
    setSelectedProducts((prev) =>
      includes(id, prev)
        ? filter((productId) => productId !== id, prev)
        : [...prev, id]
    );
  };

  const setAllSelected = (productsId: Array<string>) => {
    setSelectedProducts((prev) => uniq([...prev, ...productsId]));
  };

  const setOffCurrentSelected = (productsId: Array<string>) => {
    setSelectedProducts((prev) => without(productsId, prev));
  };

  const onModalCancel = () => setModalCancel((prev) => !prev);

  const onModalDelete = () => setModalDelete((prev) => !prev);

  const onSetProductsMode = (mode: 'VIEW' | 'EDIT') => {
    setProductsMode(mode);
  };

  const onSetDeleteProduct = (product: GetAdminProducts.ProductItem) => {
    setDeleteProduct(product);
  };

  const onSetLengthData = (lenght: number) => {
    setLengthData(lenght);
  };

  const onSetBlackListMode = (mode: 'VIEW' | 'EDIT') => {
    setBlackListMode(mode);
  };

  const onSetDeleteBlackItem = (value: IBlackListItem) => {
    setDeleteBlackItem(value);
  };

  const onSetLengthBlackListData = (lenght: number) => {
    setLengthBlackListData(lenght);
  };

  return (
    <AdminProductsContext.Provider
      value={{
        selectAll,
        modalDelete,
        modalCancel,
        productsMode,
        deleteProduct,
        lengthData,
        lengthBlackListData,
        deleteBlackItem,
        blackListMode,
        onSelectAll,
        isSelected,
        setSelectedProduct,
        setAllSelected,
        setOffCurrentSelected,
        onModalCancel,
        onModalDelete,
        onSetProductsMode,
        onSetDeleteProduct,
        onSetLengthData,
        onSetBlackListMode,
        onSetDeleteBlackItem,
        onSetLengthBlackListData,
      }}
    >
      {children}
    </AdminProductsContext.Provider>
  );
};

const useAdminProducts = (): ContextProps => {
  const context = useContext(AdminProductsContext);

  if (context === undefined) {
    throw new Error(
      'useAdminProducts must be used within a AdminProductsProvider'
    );
  }

  return context;
};

export { useAdminProducts, AdminProductsProvider };
