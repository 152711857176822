import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';
import { useGetRequest } from 'rx-api/utils';

const url = (id: number | string) =>
  `${process.env.REACT_APP_WEBSITE_URL}/v3/admin/ad-campaigns/${id}`;

export const useDeleteAdminAdCampaignItem = () => {
  const onRequest = (id: string | number) => {
    return http.delete(url(id));
  };

  return useMutation(onRequest);
};

type AdCampaignItemDto = {
  id: number;
  link: string;
  name: string;
  pictureUrl: string;
  type: 'popup' | 'banner';
  createdAt: string;
  updatedAt: string;
};

export const useGetAdminAdItemList = () => {
  const { isLoading, data, ...rest } = useGetRequest<
    unknown,
    {
      banner: AdCampaignItemDto | null;
      popup: AdCampaignItemDto | null;
    }
  >({
    key: 'get-ad-campaign-item-list',
    url: `${process.env.REACT_APP_WEBSITE_URL}/v3/admin/ad-campaigns`,
  });

  if (isLoading) {
    return { ...rest, data: { banner: null, popup: null }, isLoading };
  }

  return {
    ...rest,
    isLoading,
    data: data?.data ?? { banner: null, popup: null },
  };
};
