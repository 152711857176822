import { useRxMutation } from 'rx-api/utils';
import { URL_V1 } from 'rx-api/api/constants';

type UpdateAccountRequestPayloadDto = {
  firstName: string;
  lastName: string;
  email: string;
  secondaryEmail: string;
  phoneNumber: string;
  titleOrPosition: string;
};

export const useUpdateAccount = (id: string) => {
  return useRxMutation<UpdateAccountRequestPayloadDto, unknown>({
    method: 'put',
    url: `${URL_V1}/admin/accounts/${id}`,
  });
};
