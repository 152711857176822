import React from 'react';
import styled, { css } from 'styled-components';

const Text = styled.span`
  height: 30px;
  margin-left: 12px;
  padding-top: 4px;

  ${({ theme: { sizes, colors, fonts } }) => css`
    font-family: ${fonts.robotoLight};
    font-size: ${sizes.extraSmall};
    color: ${colors.error};
  `}
`;

const RxInputErrorMessage: React.FC = ({ children, ...props }): JSX.Element => {
  return <Text {...props}>{children}</Text>;
};

export { RxInputErrorMessage };
