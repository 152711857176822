import { createContext, FC, useContext, useState } from 'react';

type ContextProps = {
  downloadProductsVisible: boolean;
  onSetDownloadProductsVisible: (visible: boolean) => void;
};

const DownloadProductsContext = createContext<ContextProps | undefined>(
  undefined
);

const DownloadProductsProvider: FC = ({ children }) => {
  const [downloadProductsVisible, setDownloadProductsVisible] =
    useState<boolean>(false);

  const onSetDownloadProductsVisible = (visible: boolean) => {
    setDownloadProductsVisible(visible);
  };

  return (
    <DownloadProductsContext.Provider
      value={{
        downloadProductsVisible,
        onSetDownloadProductsVisible,
      }}
    >
      {children}
    </DownloadProductsContext.Provider>
  );
};

const useDownloadProduct = (): ContextProps => {
  const context = useContext(DownloadProductsContext);

  if (context === undefined) {
    throw new Error(
      'useDownloadProducts must be used within a DownloadProductsProvider'
    );
  }

  return context;
};

export { DownloadProductsProvider, useDownloadProduct };
