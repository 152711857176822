import React from 'react';
import { Button, Grid } from '@material-ui/core';

import { PaymentMethodACH, PaymentMethodCard } from 'rx-domain';

import { useAddPayment } from 'rx-contexts';

import { PaymentListItem } from '../PaymentListItem';

import { Container, EmptyText } from './index.styles';

interface IPaymentListProps {
  cards: PaymentMethodCard[];
  achs: PaymentMethodACH[];
}

const PaymentList: React.FC<IPaymentListProps> = (props) => {
  const { onOpenModal } = useAddPayment();

  const noPaymentMethods = props.cards.length === 0 && props.achs.length === 0;

  return (
    <Container container direction="column">
      {props.cards.map((card) => (
        <PaymentListItem key={card.id} type="card" {...card} />
      ))}
      {props.achs.map((ach) => (
        <PaymentListItem key={ach.id} type="ach" {...ach} />
      ))}
      {noPaymentMethods && (
        <EmptyText data-cy="payment-method-list-empty">
          You don’t have any banking information registered.
        </EmptyText>
      )}

      <Grid xs={4}>
        <Button
          style={{ marginTop: '1rem' }}
          variant="contained"
          color="primary"
          onClick={() => onOpenModal(true)}
        >
          Add new payment
        </Button>
      </Grid>
    </Container>
  );
};

export { PaymentList };
