import styled, { css } from 'styled-components';
import { RRModal, RxRadioButton, RxButtonSimple } from 'rx-core';

export const Modal = styled(RRModal)`
  height: fit-content;
`;

export const Container = styled.div`
  padding: 1rem;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    padding-bottom: 1rem;
    border-bottom: 1px rgba(43, 54, 71, 0.35) solid;
  `}
`;

export const Subtitle = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-weight: 400;
    padding: 1rem 0;
  `}
`;

export const RadioInput = styled(RxRadioButton)`
  margin-bottom: 1rem;

  & > input {
    cursor: pointer;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  border-top: 1px rgba(43, 54, 71, 0.35) solid;
`;

export const CancelBtn = styled(RxButtonSimple)`
  margin-right: 1rem;
`;

export const AcceptBtn = styled(RxButtonSimple)`
  width: 12rem;
`;
