import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem;
`;

export const TabContainer = styled.div`
  width: 100%;
  background-color: white;
  margin-bottom: 1rem;
`;
