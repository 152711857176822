import * as Yup from 'yup';

import {
  firstName,
  lastName,
  phoneNumber,
  email,
} from '../../common.validator';

const PharmacyPersonalInfoSchema = Yup.object().shape({
  firstName,
  lastName,
  phoneNumber,
  email,
  secondaryEmail: Yup.string()
    .email('Please provide a correct format')
    .notRequired(),
});

export { PharmacyPersonalInfoSchema };
