import React, { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

export type InputTableProps = InputHTMLAttributes<HTMLInputElement> &
  LayoutProps & {
    error: boolean;
  };

export const InputTableContainer = styled.input<{ error: boolean }>`
  ${({ theme: { colors, fonts } }) => css`
    background: ${colors.white[100]};
    font-family: ${fonts.robotoLight};
    border-radius: 50px;
    border: none;
    font-size: 14px;
    height: 36px;
    padding-left: 6px;
    padding-right: 6px;
    outline: none;
    ${layout};

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${colors.gray[500]};
      font-family: ${fonts.robotoRegular};
      font-style: normal;
      font-weight: normal;
    }
  `}

  ${({ error, theme: { colors } }) =>
    error &&
    css`
      border: 1px ${colors.red[100]} solid;
    `};
`;

const InputTable = ({ ...props }: InputTableProps): JSX.Element => {
  return <InputTableContainer {...props} />;
};

InputTable.defaultProps = {
  error: false,
};

export { InputTable };
