import styled, { css } from 'styled-components';

import { RRModal, RxButtonSimple } from 'rx-core';
import { Grid } from '@material-ui/core';

export const Modal = styled(RRModal)`
  height: fit-content;
  width: 31.5rem;
`;

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoRegular};
    margin-top: 1rem;
    font-size: ${theme.sizes.h2};
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(43, 54, 71, 0.35);
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoRegular};
    font-size: ${theme.sizes.medium};
    margin-top: 1rem;
    margin-bottom: 1rem;
  `}
`;

export const CancelButton = styled(RxButtonSimple)`
  width: 12rem;
`;

export const Spacer = styled.div`
  flex: 1 1;
`;

export const Actions = styled(Grid)`
  display: flex;
`;

export const SelectContainer = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgb(43, 54, 71, 0.35);
`;

export const SelectType = styled.button`
  ${({ theme }) => css`
    background-color: white;
    height: 2.5rem;
    border-radius: 10px;
    border: 1px solid #9fa6b9;
    font-size: ${theme.sizes.medium};
    padding: 0.5rem;
    outline: none;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `}
`;

export const SelectTypeText = styled.span`
  margin-right: 0.25rem;
`;

export const OtherContainer = styled.div`
  margin-top: 1rem;
`;

export const TextArea = styled.textarea`
  ${({ theme }) => css`
    outline: none;
    width: 100%;
    resize: none;
    border: 1px solid #9fa6b9;
    border-radius: 0.5rem;
    font-size: ${theme.sizes.medium};
    max-height: 4rem;
  `}
`;
