import React from 'react';

import { useAuth } from 'rx-hooks';

import { HeaderLogo } from './HeaderLogo';
import { HeaderAdmin } from './HeaderAdmin';
import { HeaderBuyer } from './HeaderBuyer';
import { HeaderSeller } from './HeaderSeller';

import { Container, UserContainer, Content } from './index.styles';

const RxHeader = ({ isHomePage = false }): JSX.Element => {
  const { type } = useAuth();

  return (
    <Container>
      <Content>
        <HeaderLogo />
        <UserContainer>
          {type === 'admin' && <HeaderAdmin />}
          {type === 'manufacturer' && <HeaderSeller />}
          {type === 'pharmacist' && <HeaderBuyer isHomePage={isHomePage} />}
        </UserContainer>
      </Content>
    </Container>
  );
};

export { RxHeader };
