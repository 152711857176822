import React, { useEffect } from 'react';
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { GetProductById, GetProducts } from 'rx-domain';
import { formatMoney, getDateValue } from 'rx-utils';
import formatProduct from 'rx-utils/gtmFormatters';

type IProductDetailsProps = {
  product: GetProductById.Response;
};

const getLogicalOperatorText = (logicalOperator: string) => {
  if (logicalOperator === 'less_than') {
    return 'Less Than';
  }

  if (logicalOperator === 'more_than') {
    return 'More Than';
  }

  return 'Between';
};

const getAmount = (min: number | null, max: number | null) => {
  if (min === null) {
    return `${max} units`;
  }

  if (max === null) {
    return `${min} units`;
  }

  return `${min} - ${max} units`;
};

export const ProductDetails: React.FC<IProductDetailsProps> = (props) => {
  useEffect(() => {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'view_item',
      ecommerce: {
        currency: 'USD',
        value: 0,
        items: [
          {
            ...formatProduct({
              product: props.product as unknown as GetProducts.ProductItem,
              index: 0,
            }),
          },
        ],
      },
      user_id: localStorage.getItem('_userid'),
    });
  }, []);

  return (
    <div>
      {props.product.productId !== '' && props.product.productId !== null && (
        <Typography>
          <b>Product ID:</b> {props.product.productId}
        </Typography>
      )}

      {props.product.ndc !== '' && (
        <Typography>
          <b>NDC:</b> {props.product.ndc}
        </Typography>
      )}

      <Typography>
        <b>Description:</b> {props.product.description}
      </Typography>
      <Typography>
        <b>Manufacturer:</b> {props.product.manufacturer}
      </Typography>
      <Typography>
        <b>Shipper:</b> {props.product.shippingName}
      </Typography>
      <Typography>
        <b>Expiration Date:</b> {getDateValue(props.product.expirationDate)}
      </Typography>
      <Typography>
        <b>Lot Number:</b> {props.product.lotNumber}
      </Typography>
      <Typography>
        <b>Minimum Order:</b> {props.product.minimumQuantity}
      </Typography>
      {props.product.maximumQuantity && (
        <Typography>
          <b>Maximum Order:</b> {props.product.maximumQuantity}
        </Typography>
      )}
      <Typography>
        <b>Stock Available:</b> {props.product.units}
      </Typography>

      <Divider style={{ margin: '1rem 0' }} />

      {props.product.pricingTiers.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="subtitle2">
              <strong>Pricing Options</strong>

              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Units</TableCell>
                    <TableCell>New Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.product.pricingTiers.map((tier) => {
                    const logicalOperator = getLogicalOperatorText(
                      tier.logicalOperator
                    );
                    const amount = getAmount(tier.minAmount, tier.maxAmount);

                    return (
                      <TableRow key={tier.id}>
                        <TableCell>{logicalOperator}</TableCell>
                        <TableCell>{amount}</TableCell>
                        <TableCell>{formatMoney(tier.price)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
