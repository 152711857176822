import styled, { css } from 'styled-components';

import { RxRadioButtonSimple } from 'rx-core';

export const Container = styled.div``;

export const RadioInput = styled(RxRadioButtonSimple)`
  ${({ theme }) => css`
    margin-bottom: 0;

    input {
      cursor: pointer;
    }

    span {
      font-weight: 400;
      font-size: ${theme.sizes.small};
      font-family: ${theme.fonts.robotoLight};
    }
  `}
`;
