import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash.debounce';

import { RxTableSearch } from 'rx-core';

import { onMakeURL } from 'rx-utils';

type IBuyerSearchFilterProps = {
  loading: boolean;
};

export const BuyerSearchFilter: React.FC<IBuyerSearchFilterProps> = (props) => {
  const [localLoading, setLocalLoading] = useState(false);

  const history = useHistory();

  const findByText = useCallback(
    debounce((value) => {
      setLocalLoading(false);

      const fullURL = onMakeURL({
        search: value.trim(),
      });

      history.push(fullURL);
    }, 750),
    []
  );

  const searchByText = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;

    setLocalLoading(true);

    if (!target.value) {
      setLocalLoading(false);

      const fullURL = onMakeURL({
        search: '',
      });

      history.push(fullURL);
      return;
    }

    findByText(target.value);
  };

  return (
    <RxTableSearch
      loading={localLoading || props.loading}
      onSearchInput={searchByText}
      placeholder="Search by pharmacy name or DBA"
    />
  );
};
