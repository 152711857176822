import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { empty, isEmpty } from 'ramda';
import axios from 'axios';
import { Button, CircularProgress, Typography } from '@material-ui/core';

import { ErrorCSV, UploadCSV } from 'rx-domain';

import { useManageItemsUpload } from 'rx-contexts';

// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!./../../../../workers/upload-csv.worker.ts';

import { useToast } from 'rx-hooks';

import { useAdminUploadDrugsBulk, usePostInventoryBulk } from 'rx-api';
import { useQueryClient } from 'react-query';

import { CsvUploadSummary } from './CsvUploadSummary';
import { CsvUploadProgress } from './CsvUploadProgress';
import { CsvUploadError } from './CsvUploadError';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const FooterActions = styled.div`
  display: flex;
  margin-top: 38px;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;

const LoadingEffect = styled(CircularProgress)``;

interface ICsvUploadReviewProps {
  onUploadSuccess: () => void;
}

export const CsvUploadReview: React.FC<ICsvUploadReviewProps> = (props) => {
  const uploadCSVWorker = useRef<Worker | null>(null);
  const [items, setItems] = useState<UploadCSV[]>([]);
  const [isError, setErrorUpload] = useState(false);
  const [finishUpload, setFinishUpload] = useState(false);
  const [workerLoading, setWorkerLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<{ [key: string]: ErrorCSV[] }>({});
  const [missingColumns, setMissingColumns] = useState<string[]>([]);

  const toast = useToast();

  const hasError = items.length === 0 && !workerLoading;

  const {
    csvFile,
    manufacturer,
    overrideInventory,
    csvData,
    loading,
    confirmUpload,
    onSetLoading,
    onSetCsvData,
    onStepUpload,
    onSetRawCsvData,
    onSetManufacturer,
    onSetUploadModalVisible,
    onSetConfirmUpload,
    bulkUploadType,
    onSetBulkUploadType,
  } = useManageItemsUpload();

  const bulkInventory = usePostInventoryBulk();
  const adminBulkInventory = useAdminUploadDrugsBulk(
    manufacturer?.id ? manufacturer.id : 0
  );

  const queryClient = useQueryClient();

  const onCancel = () => {
    onStepUpload('CHOOSE');
    onSetBulkUploadType('drug');
    onSetRawCsvData(null);
  };

  const onClose = async () => {
    await queryClient.refetchQueries('get-inventory-products', {
      active: true,
    });

    onStepUpload('CHOOSE');
    onSetBulkUploadType('drug');
    onSetCsvData([]);
    onSetRawCsvData(null);
    onSetUploadModalVisible(false);
    onSetManufacturer(null);
    onSetConfirmUpload(false);
    onSetLoading(false);
  };

  const onUploadFile = async () => {
    onSetLoading(true);
    onSetConfirmUpload(true);

    try {
      if (manufacturer) {
        await adminBulkInventory.mutateAsync({
          type: bulkUploadType,
          overrideInventory,
          data: csvData,
        });

        onSetLoading(false);
        setFinishUpload(true);
        props.onUploadSuccess();

        return;
      }

      await bulkInventory.mutateAsync({
        overrideInventory,
        type: bulkUploadType,
        data: csvData,
      });

      onSetLoading(false);
      setFinishUpload(true);
      props.onUploadSuccess();
    } catch (err) {
      setErrorUpload(true);
      onSetLoading(false);

      if (axios.isAxiosError(err)) {
        toast.onErrorToast(err.response?.data.message);
      } else {
        toast.onErrorToast('An error ocurred while saving. Try again.');
      }
    }
  };

  useEffect(() => {
    uploadCSVWorker.current = new Worker();
  }, []);

  useEffect(() => {
    if (uploadCSVWorker.current) {
      uploadCSVWorker.current.postMessage({ csv: csvFile });

      uploadCSVWorker.current.addEventListener('message', (event) => {
        setItems(event.data.transformedData);
        setErrors(event.data.errors);
        setMissingColumns(event.data.missingColumns);

        setWorkerLoading(false);

        if (empty(event.data.errors)) {
          uploadCSVWorker.current = null;
          onSetCsvData(event.data.transformedData);
        }
      });
    }
  }, [csvFile, onSetCsvData, bulkUploadType]);

  return (
    <Container>
      {hasError || isError ? (
        <div style={{ height: '100%', width: '100%' }}>
          <CsvUploadError />
        </div>
      ) : (
        <>
          {!confirmUpload ? (
            <>
              <CsvUploadSummary
                totalItems={items.length}
                loading={workerLoading}
                missingColumns={missingColumns}
                errors={errors}
              />
            </>
          ) : (
            <div style={{ height: '100%', width: '100%' }}>
              <CsvUploadProgress loading={loading} success={finishUpload} />
            </div>
          )}
          {!workerLoading ? (
            <FooterActions>
              <Button
                color="primary"
                variant={finishUpload ? 'contained' : 'outlined'}
                onClick={!confirmUpload ? onCancel : onClose}
                style={{ marginRight: '1rem' }}
              >
                {finishUpload ? 'Close' : 'Cancel'}
              </Button>
              {!finishUpload && (
                <Button
                  disabled={items.length === 0 || workerLoading || loading}
                  color="primary"
                  variant="contained"
                  onClick={onUploadFile}
                >
                  {loading ? (
                    <>
                      <LoadingEffect
                        size={24}
                        thickness={2}
                        style={{ marginRight: '1rem' }}
                      />
                      Uploading
                    </>
                  ) : (
                    'Continue Upload'
                  )}
                </Button>
              )}
            </FooterActions>
          ) : (
            <div style={{ height: '100%', width: '100%' }}>
              <Typography variant="h5">Processing CSV File</Typography>
              <Typography variant="subtitle1">
                This could take a minute...
              </Typography>
              <div
                style={{
                  marginTop: '1.5rem',
                  padding: '1rem 0 2rem 0',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <LoadingEffect size={48} thickness={5} />
              </div>
            </div>
          )}
        </>
      )}
    </Container>
  );
};
