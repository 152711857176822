import { useQuery } from 'rx-hooks/utils/useQuery';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSkipPage = (totalItems: number, pageSize = 10) => {
  const query = useQuery();

  const page = parseInt(query.get('p') ?? '1', 10);

  return {
    skip: page >= 0 ? page * pageSize - pageSize : 0,
    totalPages: Math.ceil(totalItems / pageSize),
  };
};
