import React from 'react';
import styled from 'styled-components';

import SearchResultFilterBackground from '../../../assets/others/search-result-filters-background.png';

export type BannerTitleProps = {
  children?: React.ReactNode;
};

const Container = styled.div`
  background: url(${SearchResultFilterBackground}) #5077f2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BannerTitle = ({ children, ...props }: BannerTitleProps): JSX.Element => {
  return <Container {...props}>{children}</Container>;
};

BannerTitle.defaultProps = {
  children: <></>,
};

export { BannerTitle };
