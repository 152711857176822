import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = styled(Grid)``;

export const EmptyText = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-weight: 400;
  `}
`;
