import React from 'react';
import { useSelector } from 'react-redux';

import { getShopItemsBySeller, getShippingConfigBySeller } from 'rx-store';

import { SEO, TemplateView } from 'rx-core';

import { useSyncShoppingCart } from 'rx-hooks';

import { ShopCartTitle } from './ShopCartTitle';
import { ShopCartEmpty } from './ShopCartEmpty';
import { ShopCartLoading } from './ShopCartLoading';
import { ShopCartProductsBySeller } from './ShopCartProductsBySeller';

import { Container } from './index.styles';

export const ShopCartView: React.FC = () => {
  const productsBySellers = useSelector(getShopItemsBySeller);
  const shippingConfig = useSelector(getShippingConfigBySeller);

  const sellerWithProducts = Object.keys(productsBySellers);

  const { isLoading: isLoadingShopCart } = useSyncShoppingCart();

  return (
    <TemplateView>
      <SEO title="RxRise | Shopping Cart" />
      <Container>
        <ShopCartTitle />
        {isLoadingShopCart && <ShopCartLoading />}

        {!isLoadingShopCart && (
          <ShopCartProductsBySeller
            productsBySellers={productsBySellers}
            sellerWithProducts={sellerWithProducts}
            shippingConfig={shippingConfig}
          />
        )}

        {sellerWithProducts.length === 0 && !isLoadingShopCart && (
          <ShopCartEmpty />
        )}
      </Container>
    </TemplateView>
  );
};
