import React from 'react';
import moment from 'moment';

import { useAdminGetMetrics } from 'rx-api';
import { useQuery } from 'rx-hooks';

import { DashboardMetricsFilter } from './DashboardMetricsFilter/DashboardMetricsFilter';

import {
  MetricsContainer,
  MetricsTitle,
  MetricsCard,
  MetricsSection,
  MetricsCount,
} from './index.styles';

const DashboardMetrics = (): JSX.Element => {
  const query = useQuery();

  const queryTime = (query.get('time') ?? '') as
    | ''
    | 'quarter'
    | 'month'
    | 'week'
    | 'day';

  const getRangeDates = (time: '' | 'quarter' | 'month' | 'week' | 'day') => {
    if (!time) {
      return {
        from: '1970-01-01',
      };
    }

    return {
      to: moment().startOf(time).toISOString(),
      from: moment().endOf(time).toISOString(),
    };
  };

  const { isLoading, data: response } = useAdminGetMetrics({
    ...getRangeDates(queryTime),
  });

  return (
    <MetricsContainer>
      <MetricsTitle>Marketplace Hightlights</MetricsTitle>
      <DashboardMetricsFilter
        queries={{
          time: queryTime,
        }}
      />
      <MetricsCard>
        <MetricsSection>
          <MetricsCount>
            {isLoading ? '-' : response?.data.products}
          </MetricsCount>
          <p>Products on Marketplace</p>
        </MetricsSection>

        <MetricsSection>
          <MetricsCount>
            {isLoading ? '-' : response?.data?.ediProducts ?? 0}
          </MetricsCount>
          <p>EDI Products on Marketplace</p>
        </MetricsSection>

        <MetricsSection>
          <MetricsCount>{isLoading ? '-' : response?.data.buyers}</MetricsCount>
          <p>Pharmacists (Buyers)</p>
        </MetricsSection>

        <MetricsSection>
          <MetricsCount>
            {isLoading ? '-' : response?.data.sellers}
          </MetricsCount>
          <p>Manufacturers (Sellers)</p>
        </MetricsSection>

        <MetricsSection>
          <MetricsCount>
            {isLoading ? '-' : response?.data.transactions}
          </MetricsCount>
          <p>Transactions Completed</p>
        </MetricsSection>
      </MetricsCard>
    </MetricsContainer>
  );
};

export { DashboardMetrics };
