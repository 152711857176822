import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

import { useManageItemsUpload } from 'rx-contexts';

import { MISearchManufacturer } from 'pages/seller/manage-items/atoms/MISearchManufacturer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.h2`
  font-size: ${({ theme: { sizes } }) => `${sizes.h2}`};
  font-family: ${({ theme: { fonts } }) => `${fonts.robotoLight}`};

  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgb(43, 54, 71, 0.35);
`;

const LabelTitle = styled.p`
  font-size: ${({ theme: { sizes } }) => `${sizes.small}`};
  font-family: ${({ theme: { fonts } }) => `${fonts.robotoLight}`};
  margin-top: 1rem;
  margin-bottom: 6px;
`;

const InfoTitle = styled(LabelTitle)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const LinkTitle = styled.span`
  color: ${({ theme: { colors } }) => `${colors.blue[100]}`};
  font-family: ${({ theme: { fonts } }) => `${fonts.robotoLight}`};
  cursor: pointer;
`;

const FooterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const FooterSpacer = styled.div`
  flex: 1;
  border-bottom: 1px solid rgb(43, 54, 71, 0.35);
`;

export const CsvUploadPickManufacturer: React.FC = () => {
  const {
    onSetRawCsvData,
    onSetCsvData,
    onSetUploadModalVisible,
    onSetManufacturer,
    manufacturer,
    onStepUpload,
  } = useManageItemsUpload();

  const onCancel = () => {
    onSetCsvData([]);
    onSetRawCsvData(null);
    onSetUploadModalVisible(false);
    onSetManufacturer(null);
  };

  return (
    <Container>
      <Title>On behalf of who are you uploading the products?</Title>
      <LabelTitle>Please Select RxRise Seller</LabelTitle>
      <MISearchManufacturer onGetManufacturer={onSetManufacturer} />
      <InfoTitle>
        If seller is not on the list, you can{' '}
        <LinkTitle>Manage your sellers.</LinkTitle>
      </InfoTitle>
      <FooterSpacer />
      <FooterActions>
        <Button
          variant="outlined"
          onClick={onCancel}
          color="primary"
          style={{ marginRight: '1rem' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!!manufacturer === false}
          onClick={() => onStepUpload('CHOOSE-CSV-TYPE')}
          color="primary"
        >
          Next
        </Button>
      </FooterActions>
    </Container>
  );
};
