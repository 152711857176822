import React from 'react';

import {
  FilterProvider,
  AdminProductsProvider,
  ManageItemsUploadProvider,
  DownloadProductsProvider,
} from 'rx-contexts';

import { SEO, TemplateView } from 'rx-core';

const AdminProductsTemplate: React.FC = ({ children }): JSX.Element => {
  return (
    <TemplateView>
      <SEO title="RxRise | Manage Products" />
      <FilterProvider>
        <AdminProductsProvider>
          <ManageItemsUploadProvider>
            <DownloadProductsProvider>{children}</DownloadProductsProvider>
          </ManageItemsUploadProvider>
        </AdminProductsProvider>
      </FilterProvider>
    </TemplateView>
  );
};

export { AdminProductsTemplate };
