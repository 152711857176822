import React from 'react';
import { Link } from 'react-router-dom';
import { Paper, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Content, PendingTaskHeader } from '../index.styles';

export interface IPendingTaskProps {
  url: string;
  pendingTask: { title: string; description: string; date: string };
}

export const PendingTask = (props: IPendingTaskProps): JSX.Element => {
  return (
    <Link
      to={props.url}
      style={{
        display: 'block',
        marginBottom: '1rem',
        textDecoration: 'none',
      }}
    >
      <Paper elevation={0} variant="outlined">
        <Content>
          <PendingTaskHeader>
            <Typography variant="subtitle1" color="textSecondary">
              {props.pendingTask.title}
            </Typography>
            <InfoIcon color="error" />
          </PendingTaskHeader>
          <section>
            <Typography variant="h6">
              {props.pendingTask.description}
            </Typography>
          </section>
          <footer>
            <Typography variant="subtitle1" color="error">
              Request Arrived: {props.pendingTask.date}
            </Typography>
          </footer>
        </Content>
      </Paper>
    </Link>
  );
};
