import { PUT_ADMIN_EDIT_PURCHASE_ORDER_ITEMS_URL } from 'rx-api/api/admin/products';
import { useRxMutation } from 'rx-api/utils';

export const useAdminPutEditPurchaseOrderItems = (params: any) => {
  return useRxMutation<any, unknown>({
    method: 'put',
    url: PUT_ADMIN_EDIT_PURCHASE_ORDER_ITEMS_URL.replace(
      '{id}',
      params.id.toString()
    ),
  });
};
