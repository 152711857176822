import styled, { css } from 'styled-components';

export const TotalReports = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.sizes.small};
    color: #a3a3a3;
  `}
`;

export const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ActionContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;
