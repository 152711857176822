import styled, { css } from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    margin-bottom: 1rem;
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoLight};
    margin-bottom: 0.4rem;
  `}
`;

export const Container = styled.div`
  max-width: 600px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  //-webkit-box-shadow: 7px 6px 5px -4px rgb(177 177 177 / 75%);
  //-moz-box-shadow: 7px 6px 5px -4px rgb(177 177 177 / 75%);
  //box-shadow: 7px 6px 5px -4px rgb(177 177 177 / 75%);
`;

export const LinkStyled = styled(LinkRouter)`
  text-decoration: none;
`;
