import React from 'react';
import styled from 'styled-components';

import { Field } from 'formik';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { IProductForm } from 'rx-domain';
import DeleteIcon from '../../../assets/icons/delete-icon.svg';

import { InputTableFormik } from '../../inputs/InputTableFormik';
import { InputTableMaskFormik } from '../../inputs/InputTableMaskFormik';
import { PopperTable } from '../../popper/PopperTable';
import { RxDatePicker } from '../../inputs/RxDatePicker';

export type ProductArrayItemFormProps = {
  index: number;
  item: IProductForm;
  remove: <T>(index: number) => T | undefined;
  type: 'edit' | 'create';
  mode: 'edition' | 'normal';
  onDeleteDB: (index: number) => void;
};

const numberMask = createNumberMask({
  prefix: '$ ',
  suffix: '',
  decimalLimit: 2,
  allowDecimal: true,
});

const Container = styled.tr`
  background: #ffffff;
  box-shadow: 5px 5px 25px rgba(51, 60, 68, 0.08);
  border-radius: 10px;
  height: 76px;
  border: 16px transparent solid;
`;

const Column = styled.td`
  padding-right: 16px;
`;

const LastColumn = styled(Column)`
  text-align: end;
`;

const Icon = styled.img`
  color: red;
  cursor: pointer;
`;

const ProductArrayItemForm = ({
  item,
  index,
  remove,
  onDeleteDB,
  type,
  mode,
}: ProductArrayItemFormProps): JSX.Element => {
  const onRemoveItem = () => {
    if (type === 'create') {
      remove(index);
    } else {
      onDeleteDB(index);
    }
  };

  return (
    <Container>
      <Column>
        {mode === 'normal' ? (
          <PopperTable>{item.productId}</PopperTable>
        ) : (
          <Field
            name={`products.${index}.productId`}
            type="text"
            width="100%"
            component={InputTableFormik}
          />
        )}
      </Column>
      <Column>
        {mode === 'normal' ? (
          <PopperTable>{item.ndc}</PopperTable>
        ) : (
          <Field
            name={`products.${index}.ndc`}
            type="text"
            width="100%"
            component={InputTableFormik}
          />
        )}
      </Column>
      <Column>
        {mode === 'normal' ? (
          <PopperTable>{item.name}</PopperTable>
        ) : (
          <Field
            name={`products.${index}.name`}
            type="text"
            width="100%"
            inputType="textarea"
            component={InputTableFormik}
          />
        )}
      </Column>
      <Column>
        {mode === 'normal' ? (
          <PopperTable>{item.description}</PopperTable>
        ) : (
          <Field
            name={`products.${index}.description`}
            type="text"
            width="100%"
            inputType="textarea"
            component={InputTableFormik}
          />
        )}
      </Column>
      <Column>
        {mode === 'normal' ? (
          <PopperTable>{item.manufacturer}</PopperTable>
        ) : (
          <Field
            name={`products.${index}.manufacturer`}
            type="text"
            width="100%"
            component={InputTableFormik}
          />
        )}
      </Column>
      <Column>
        {mode === 'normal' ? (
          item.price
        ) : (
          <Field
            name={`products.${index}.price`}
            type="text"
            width="100%"
            mask={numberMask}
            component={InputTableMaskFormik}
          />
        )}
      </Column>
      <Column>
        {mode === 'normal' ? (
          item.expirationDate
        ) : (
          <RxDatePicker
            name={`products[${index}].expirationDate`}
            type="text"
            width="100%"
          />
        )}
      </Column>
      <Column>
        {mode === 'normal' ? (
          item.lotNumber
        ) : (
          <Field
            name={`products.${index}.lotNumber`}
            type="text"
            width="100%"
            component={InputTableFormik}
          />
        )}
      </Column>
      <Column>
        {mode === 'normal' ? (
          item.units
        ) : (
          <Field
            name={`products.${index}.units`}
            type="text"
            width="100%"
            component={InputTableFormik}
          />
        )}
      </Column>
      <Column>
        {mode === 'normal' ? (
          item.minimumQuantity
        ) : (
          <Field
            name={`products.${index}.minimumQuantity`}
            type="text"
            width="100%"
            component={InputTableFormik}
          />
        )}
      </Column>
      <LastColumn colSpan={4}>
        {type === 'create' ? (
          <Icon src={DeleteIcon} alt="" onClick={onRemoveItem} />
        ) : null}
        {type === 'edit' && mode === 'edition' && (
          <Icon src={DeleteIcon} alt="" onClick={onRemoveItem} />
        )}
      </LastColumn>
    </Container>
  );
};

export { ProductArrayItemForm };
