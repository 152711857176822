import { useSelector } from 'react-redux';
import { getShopItemsBySeller, getWACSubtotal } from 'rx-store';
import React, { useEffect } from 'react';

import { useSyncShoppingCart } from 'rx-hooks';
import { CheckoutCard } from '../CheckoutCard/CheckoutCard';
import { ProductCardList } from '../CheckoutProductCardList';
import { ProductContainer } from './index.styles';
import { ShopCartLoading } from '../../shopping-cart/ShopCartLoading';
import { GetProducts, GetShoppingCart } from '../../../../rx-domain';
import formatProduct from '../../../../rx-utils/gtmFormatters';

interface IProps {
  leftContainerValue: number;
  shippingState: string;
}

export const CheckoutProductsList: React.FC<IProps> = (props) => {
  const fullTotalOrder = useSelector(getWACSubtotal);

  const productsBySellers = useSelector(getShopItemsBySeller);

  const sellerWithProducts = Object.keys(productsBySellers);

  useEffect(() => {
    if (fullTotalOrder !== 0) {
      const products: GetShoppingCart.ShoppingCartProductItemGtm[] = [];
      for (const orderKey of sellerWithProducts) {
        for (const product of productsBySellers[orderKey]) {
          products.push({ ...product.product, count: product.count });
        }
      }

      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({ ecommerce: null });
      // @ts-ignore
      window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          currency: 'USD',
          value: 0,
          items: products.map((product, index) => {
            return formatProduct({
              product: product as unknown as GetProducts.ProductItem,
              index: index,
              quantity: product.count,
            });
          }),
        },
        user_id: localStorage.getItem('_userid'),
      });
    }
  }, [fullTotalOrder]);

  return (
    <CheckoutCard isFullHeight={false}>
      <ProductContainer style={{ height: `${props.leftContainerValue + 4}px` }}>
        {sellerWithProducts.map((orderKey) => (
          <ProductCardList
            key={orderKey}
            title={orderKey}
            products={productsBySellers[orderKey]}
            shippingState={props.shippingState}
          />
        ))}
      </ProductContainer>
    </CheckoutCard>
  );
};
