import React, { useState, useEffect } from 'react';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import { TableCell, TableRow } from '@material-ui/core';

import { useGetEmailList, useGetLoginLogs } from 'rx-api';
import { useSkipPage } from 'rx-hooks';
import { RxButtonSimple, RxPagination } from 'rx-core';
import { capitalize, getDateValue } from 'rx-utils';
import {
  Container,
  TableColumnHeader,
  TableHeader,
  TableBody,
  Table,
  Spacer,
  SortHeader,
  FiltersContainer,
} from './index.styles';
import { DashboardLogsAutocomplete } from './DashboardLogsAutocomplete';
import { DashboardLogsAutocompleteContainer } from './DashboardLogsAutocompleteContainer';
import { DashboardLoginLogsLoader } from './DashboardLoginLogsLoader';

const DashboardLoginLogs = (): JSX.Element => {
  const [length, setLength] = useState(0);
  const [sortOrder, setSortOrder] = useState('desc');
  const { skip, totalPages } = useSkipPage(length);
  const [isFilterByTypeOpen, setIsFilterByTypeOpen] = useState(false);
  const filterByTypeOptions = ['All', 'pharmacist', 'admin', 'manufacturer'];
  const [searchFilterByType, setSearchFilterByType] = useState('');
  const [isFilterByEmailOpen, setIsFilterByEmailOpen] = useState(false);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [searchFilterByEmail, setSearchFilterByEmail] = useState('');

  const { data: logsData, isLoading } = useGetLoginLogs({
    skip,
    sortBy: 'createdAt',
    sortOrder,
    accountType: searchFilterByType,
    email: searchFilterByEmail,
  });

  const { data: emailListData, isLoading: isLoadingEmailList } =
    useGetEmailList();

  useEffect(() => {
    if (logsData?.data && !isLoading) {
      setLength(logsData.data.pagination.total);
    }
  }, [isLoading, logsData]);

  useEffect(() => {
    if (emailListData?.data && !isLoadingEmailList) {
      setEmailList(emailListData.data.map((user) => user.email));
    }
  }, [isLoadingEmailList, emailListData]);

  const handleClickSortDate = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleClickClearFilters = () => {
    setSearchFilterByEmail('');
    setSearchFilterByType('');
  };

  return (
    <Container>
      <FiltersContainer>
        <DashboardLogsAutocompleteContainer>
          <DashboardLogsAutocomplete
            isOpen={isFilterByEmailOpen}
            onOpen={() => setIsFilterByEmailOpen(true)}
            onClose={() => setIsFilterByEmailOpen(false)}
            placeholder="Filter by email"
            options={emailList}
            searchValue={searchFilterByEmail}
            isLoading={isLoadingEmailList}
            onChange={(_event, newInputValue) =>
              setSearchFilterByEmail(newInputValue)
            }
          />
        </DashboardLogsAutocompleteContainer>

        <DashboardLogsAutocomplete
          isLoading={false}
          isOpen={isFilterByTypeOpen}
          onOpen={() => setIsFilterByTypeOpen(true)}
          onClose={() => setIsFilterByTypeOpen(false)}
          placeholder="Filter by type"
          options={filterByTypeOptions}
          searchValue={searchFilterByType}
          onChange={(_event, newInputValue) =>
            setSearchFilterByType(newInputValue)
          }
        />

        <RxButtonSimple variant="ghost" onClick={handleClickClearFilters}>
          Clear Filters
        </RxButtonSimple>
      </FiltersContainer>
      <Table>
        <TableHeader>
          <tr>
            <TableColumnHeader>EMAIL</TableColumnHeader>
            <TableColumnHeader>ACCOUNT TYPE</TableColumnHeader>
            <TableColumnHeader>LOCATION</TableColumnHeader>
            <TableColumnHeader>
              <SortHeader onClick={handleClickSortDate}>
                LOGIN DATE
                <SwapVertIcon />
              </SortHeader>
            </TableColumnHeader>
          </tr>
        </TableHeader>
        <TableBody>
          {isLoading && <DashboardLoginLogsLoader />}

          {!isLoading &&
            logsData?.data.results.map((log) => (
              <React.Fragment key={log.id}>
                <TableRow>
                  <TableCell>{log.email}</TableCell>
                  <TableCell>{capitalize(log.accountType)}</TableCell>
                  <TableCell>{log.location}</TableCell>
                  <TableCell>{getDateValue(log.createdAt)}</TableCell>
                </TableRow>
                <Spacer />
              </React.Fragment>
            ))}
        </TableBody>
      </Table>

      {totalPages > 1 && <RxPagination path="" totalPages={totalPages} />}
    </Container>
  );
};

export { DashboardLoginLogs };
