import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const Text = styled.span`
  ${({ theme: { colors, sizes } }) => css`
    color: ${colors.black[100]};
    font-size: ${sizes.small};
    line-height: 19px;
  `}
`;

const TextStrong = styled(Link)`
  color: ${(props) => `${props.theme.colors.blue[100]}`};
  cursor: pointer;
  text-decoration: none;
`;

const LoginCreateAccount = (): JSX.Element => {
  return (
    <Text>
      New to RxRise?{' '}
      <TextStrong to="/signup/manufacturer">Sign up to sell </TextStrong>
      or <TextStrong to="/signup">Sign up to Buy</TextStrong>
    </Text>
  );
};

export { LoginCreateAccount };
