import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import { ProductFormLoader } from './ProductFormLoader';
import { useGetCategoriesList } from 'rx-api';

type IAddProductFormProps = {
  isLoading: boolean;
  values: {
    ndc?: string;
    name?: string;
    description?: string;
    manufacturer?: string;
    price?: number;
    expirationDate?: string;
    lotNumber?: string;
    units?: number;
    minimumQuantity?: number;
    productId?: string;
    category?: string;
    categoryId?: number;
    strengthValue?: number | null;
    strengthUnit?: string;
    maximumQuantity?: number | string | null;
  };
  errors: {
    ndc?: string;
    name?: string;
    description?: string;
    manufacturer?: string;
    price?: string;
    expirationDate?: string;
    lotNumber?: string;
    units?: string;
    minimumQuantity?: string;
    maximumQuantity?: string;
    productId?: string;
  };
  isDisabled: boolean;
  onChange(e: React.ChangeEvent<any>): void;
  onClickCancel(e: React.ChangeEvent<any>): void;
  setFieldValue?: any;
};

export const AddProductForm: React.FC<IAddProductFormProps> = ({
  errors,
  values,
  isLoading,
  isDisabled,
  onClickCancel,
  onChange,
  setFieldValue,
}) => {
  const { data, refetch } = useGetCategoriesList();

  useEffect(() => {
    if (setFieldValue && values.category) {
      setFieldValue(
        'categoryId',
        data?.find((category) => {
          if (category.text === values.category) {
            return category;
          }
        })?.id
      );
    }
  }, [values.category, data]);

  if (isLoading) {
    return <ProductFormLoader />;
  }

  return (
    <Paper variant="outlined" style={{ padding: '1rem', marginBottom: '1rem' }}>
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <TextField
            name="ndc"
            id="ndc"
            label="NDC"
            variant="outlined"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            error={(errors?.ndc?.length || 0) > 0}
            helperText={errors.ndc}
            value={values.ndc}
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="name"
            id="name"
            label="NAME"
            variant="outlined"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            required
            error={(errors?.name?.length || 0) > 0}
            helperText={errors.name}
            value={values.name}
            onChange={onChange}
          />
        </Grid>
      </Grid>

      <Grid container item spacing={2}>
        <Grid item xs>
          <TextField
            name="description"
            id="description"
            label="DESCRIPTION"
            variant="outlined"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            required
            margin="normal"
            error={(errors?.description?.length || 0) > 0}
            helperText={errors.description}
            value={values.description}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs>
          <TextField
            name="manufacturer"
            id="manufacturer"
            label="MANUFACTURER"
            variant="outlined"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            required
            margin="normal"
            error={(errors?.manufacturer?.length || 0) > 0}
            helperText={errors.manufacturer}
            value={values.manufacturer}
            onChange={onChange}
          />
        </Grid>
      </Grid>

      <Grid container item spacing={2}>
        <Grid item xs>
          <TextField
            name="price"
            id="price"
            label="PRICE"
            variant="outlined"
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            margin="normal"
            error={(errors?.price?.length || 0) > 0}
            helperText={errors.price}
            value={values.price}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs>
          <TextField
            name="expirationDate"
            id="expirationDate"
            label="EXP. DATE"
            variant="outlined"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            required
            margin="normal"
            error={(errors?.expirationDate?.length || 0) > 0}
            helperText={errors.expirationDate}
            value={values.expirationDate}
            onChange={onChange}
          />
        </Grid>
      </Grid>

      <Grid container item spacing={2}>
        <Grid item xs>
          <TextField
            name="lotNumber"
            id="lotNumber"
            label="LOT NUMBER"
            variant="outlined"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            error={(errors?.lotNumber?.length || 0) > 0}
            helperText={errors.lotNumber}
            value={values.lotNumber}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs>
          <TextField
            name="units"
            id="units"
            label="UNITS"
            variant="outlined"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            required
            margin="normal"
            error={(errors?.units?.length || 0) > 0}
            helperText={errors.units}
            value={values.units}
            onChange={onChange}
          />
        </Grid>
      </Grid>

      <Grid container item spacing={2}>
        <Grid item xs>
          <TextField
            name="minimumQuantity"
            id="minimumQuantity"
            label="MINIMUM QUANTITY"
            variant="outlined"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            error={(errors?.minimumQuantity?.length || 0) > 0}
            helperText={errors.minimumQuantity}
            value={values.minimumQuantity}
            onChange={onChange}
          />
        </Grid>

        <Grid item xs>
          <TextField
            name="productId"
            id="productId"
            label="PRODUCT ID"
            variant="outlined"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            error={(errors?.productId?.length || 0) > 0}
            helperText={errors.productId}
            value={values.productId}
            onChange={onChange}
          />
        </Grid>
      </Grid>

      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <TextField
            name="strengthValue"
            id="strengthValue"
            label="STRENGTH VALUE"
            variant="outlined"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            value={values.strengthValue}
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="strengthUnit"
            id="strengthUnit"
            label="STRENGTH UNIT"
            variant="outlined"
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            value={values.strengthUnit}
            onChange={onChange}
            select
          >
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="mg">mg</MenuItem>
            <MenuItem value="g">g</MenuItem>
            <MenuItem value="mcg">mcg</MenuItem>
            <MenuItem value="μg">μg</MenuItem>
            <MenuItem value="%">%</MenuItem>
          </TextField>
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth style={{ marginTop: '1rem' }}>
              <Select
                displayEmpty
                fullWidth
                labelId="category"
                id="category"
                name="category"
                variant="outlined"
                value={data ? values.category : ''}
                onChange={onChange}
              >
                {data?.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.text}>
                      {item.slug}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="maximumQuantity"
              id="maximumQuantity"
              label="MAXIMUM QUANTITY"
              variant="outlined"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              value={values.maximumQuantity}
              error={(errors?.maximumQuantity?.length || 0) > 0}
              helperText={errors.maximumQuantity}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        style={{ marginTop: '1rem' }}
      >
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: '1rem' }}
            onClick={onClickCancel}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            disabled={isDisabled}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
