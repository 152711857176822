const EXTRA_SMALL_SIZE = 'extra-small';
const SMALL_SIZE = 'small';
const MEDIUM_SIZE = 'medium';
const H1_SIZE = 'h1';
const H2_SIZE = 'h2';
const H2_BIG_SIZE = 'h2';

export type Sizes = 'extra-small' | 'small' | 'medium' | 'h1' | 'h2' | 'h2-big';

export const getSizeValue = (size: Sizes): string => {
  switch (size) {
    case 'extra-small':
      return '12px';
    case 'small':
      return '16px';
    case 'medium':
      return '18px';
    case 'h2':
      return '24px';
    case 'h2-big':
      return '36px';
    case 'h1':
      return '40px';
    default:
      return '16px';
  }
};

export {
  EXTRA_SMALL_SIZE,
  SMALL_SIZE,
  MEDIUM_SIZE,
  H1_SIZE,
  H2_SIZE,
  H2_BIG_SIZE,
};
