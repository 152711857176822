import { useState } from 'react';
import { GetSaleOrderPayments } from 'rx-domain';
import moment from 'moment';

export const useReportPagination = () => {
  const [results, setResults] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const handleEditRow = (orderId: number, status: string) => {
    const updatedRows = results.map((row: any) =>
      orderId === row.orderId
        ? {
            ...row,
            payedOn: status === 'paid' ? moment().format('MM/DD/YYYY') : null,
            status: status,
          }
        : row
    );
    setResults(updatedRows);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const recordsToSkip = indexOfLastRecord - recordsPerPage;
  const currentRecords = results
    ? results.slice(recordsToSkip, indexOfLastRecord)
    : [];
  const nPages = Math.ceil(results?.length / recordsPerPage);

  const filterByStatus = (
    data: Array<GetSaleOrderPayments.SaleOrderPayment> = [],
    status: string
  ) => {
    if (status === '') return data;
    return data?.filter((record: any) => {
      if (status === 'paid' || status === 'pending') {
        return record.status === status;
      }
      return true;
    });
  };

  const filterBySeller = (
    data: Array<GetSaleOrderPayments.SaleOrderPayment> = [],
    seller: string
  ) => {
    if (seller === 'All Sellers' || seller === '') return data;
    return data?.filter((record) => record.manufacturer === seller);
  };

  const filterByBuyer = (
    data: Array<GetSaleOrderPayments.SaleOrderPayment> = [],
    buyer: string
  ) => {
    if (buyer === 'All Buyers' || buyer === '') return data;
    return data?.filter((record) => record.buyer === buyer);
  };

  const filterByDate = (
    data: Array<GetSaleOrderPayments.SaleOrderPayment> = [],
    from: string,
    to: string
  ) => {
    if (from === '' || to === '') return data;
    return data?.filter((record) => {
      const date = new Date(record.dateSold);
      const start = new Date(from);
      const end = new Date(to);

      return date > start && date < end;
    });
  };

  const onDataChange = (
    data: GetSaleOrderPayments.Response | undefined,
    buyer: string,
    seller: string,
    status: string,
    from: string,
    to: string
  ) => {
    const statusFiltered = filterByStatus(data?.results, status);
    const sellerFiltered = filterBySeller(statusFiltered, seller);
    const buyerFiltered = filterByBuyer(sellerFiltered, buyer);
    const filteredByDate = filterByDate(buyerFiltered, from, to);
    setResults(filteredByDate);
    setCurrentPage(1);
  };

  return {
    results,
    recordsToSkip,
    currentPage,
    setResults,
    setCurrentPage,
    recordsPerPage,
    onDataChange,
    currentRecords,
    nPages,
    handleEditRow,
  };
};
