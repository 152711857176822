import React from 'react';

import { PaymentMethodCard, PaymentMethodACH } from 'rx-domain';

import { CheckoutPaymentACH } from '../CheckoutPaymentACH/CheckoutPaymentACH';
import {
  Container,
  Table,
  TableColumn,
  TableRow,
  Title,
} from './CheckoutPaymentMethods.styles';
import { CheckoutPaymentCard } from '../CheckoutPaymentCard/CheckoutPaymentCard';

type ICheckoutPaymentsACHProps = {
  cards: PaymentMethodCard[];
  achs: PaymentMethodACH[];
  selectedPaymentMethod: {
    type: 'ach' | 'card' | 'paymentTerms';
    value: string;
  } | null;
  onChange: (type: 'ach' | 'card', value: string) => void;
  onVerify: (id: number) => void;
};

export const CheckoutPaymentMethods: React.FC<ICheckoutPaymentsACHProps> = (
  props
) => {
  return (
    <Container>
      <Title>Your available payment methods:</Title>
      <Table>
        <thead>
          <TableRow>
            <TableColumn>CARD</TableColumn>
            <TableColumn>EXPIRES ON</TableColumn>
            <TableColumn>STATUS</TableColumn>
          </TableRow>
        </thead>
        <tbody>
          {props.cards.map((card) => (
            <CheckoutPaymentCard
              key={card.id}
              paymentMethod={{ ...card, type: 'card' }}
              selectedPaymentMethod={props.selectedPaymentMethod}
              onChange={props.onChange}
            />
          ))}
          {props.achs.map((ach) => (
            <CheckoutPaymentACH
              key={ach.id}
              selectedPaymentMethod={props.selectedPaymentMethod}
              paymentMethod={{
                ...ach,
                type: 'ach',
                onVerify: () => {
                  props.onVerify(parseInt(ach.id, 10));
                },
              }}
              onChange={props.onChange}
            />
          ))}
        </tbody>
      </Table>
    </Container>
  );
};
