import { http } from 'rx-api';
import { useMutation } from 'react-query';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAdminDeleteTransaction = () => {
  return useMutation(['delete-transaction'], (id: number | string) => {
    return http.delete(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/transactions/${id}`
    );
  });
};
