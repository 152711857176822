import React, { useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';

import { GetSaleOrderPayments } from 'rx-domain';
import { capitalize, formatMoney, getDateValue } from 'rx-utils';

import { ReportOptions } from '../PaymentsReportOptions';
import { TransactionPaymentStatus } from '../../../transactions/TransactionPaymentStatus';

type IProps = {
  payment: GetSaleOrderPayments.SaleOrderPayment;
  handleEditRow: any;
  index: number;
};

export const ReportItem: React.FC<IProps> = (props) => {
  const [paymentStatus, setPaymentStatus] = useState(props.payment.status);
  return (
    <TableRow
      style={{
        backgroundColor: props.payment.isEdiOrder ? '#fff9c4' : 'transparent',
      }}
    >
      <TableCell>{props.payment.saleOrderId}</TableCell>
      <TableCell>{props.payment.manufacturer}</TableCell>
      <TableCell>{props.payment.buyer}</TableCell>
      <TableCell>
        <TransactionPaymentStatus status={paymentStatus} />
      </TableCell>
      <TableCell>{formatMoney(props.payment.amount)}</TableCell>
      <TableCell>{formatMoney(props.payment.rxriseSplit)}</TableCell>
      <TableCell>{formatMoney(props.payment.stripeSplit)}</TableCell>
      <TableCell>{formatMoney(props.payment.sellerSplit)}</TableCell>
      <TableCell>{getDateValue(props.payment.dateSold)}</TableCell>
      <TableCell>
        {props.payment.payedOn
          ? getDateValue(props.payment.payedOn)
          : 'Waiting for paid'}
      </TableCell>
      <TableCell>{capitalize(props.payment.paymentType)}</TableCell>
      <TableCell>
        <ReportOptions
          setPaymentStatus={(status: string) => {
            props.handleEditRow(props.payment.orderId, status);
            setPaymentStatus(status);
          }}
          saleOrderId={props.payment.saleOrderId}
          status={props.payment.status}
          orderId={props.payment.orderId}
        />
      </TableCell>
    </TableRow>
  );
};
