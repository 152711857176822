import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

type PutShoppingCartDTO = {
  quantity: number;
  id: number;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePutShoppingCart = () => {
  const onRequest = async ({ id, ...req }: PutShoppingCartDTO) => {
    return http.put(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/pharmacists/shopping-cart/${id}`,
      req
    );
  };

  return useMutation(onRequest, {});
};

export { usePutShoppingCart };
