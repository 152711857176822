import React from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';

import { usePharmacistUpdateBillingInformation } from 'rx-api';
import { useToast } from 'rx-hooks';
import { STATE_CODES_US } from 'rx-utils';

import { BillingInformationSchema } from 'utils/validators/account-settings/billing-information.validator';

import { Title } from './index.styles';

type IProps = {
  data: {
    name: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
  } | null;
  disabled: boolean;
};

type IForm = {
  name: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
};

export const SettingsBillingInfo: React.FC<IProps> = (props) => {
  const { onSuccessToast, onErrorToast } = useToast();
  const params = useParams<{ id: string }>();
  const updateBillingInformation = usePharmacistUpdateBillingInformation(
    Number(params.id)
  );

  const formik = useFormik({
    initialValues: {
      name: props.data?.name ?? '',
      address: props.data?.address ?? '',
      city: props.data?.city ?? '',
      state: props.data?.state ?? 'AL',
      zipCode: props.data?.zipCode ?? '',
    },
    enableReinitialize: true,
    validationSchema: BillingInformationSchema,
    onSubmit: async (values: IForm) => {
      try {
        await updateBillingInformation.mutateAsync(values);
        onSuccessToast('Changes saved succesfully 🚀');
      } catch (err) {
        onErrorToast('Error: could not save billing information');
      }
    },
  });

  return (
    <Paper variant="outlined" style={{ padding: '1rem' }}>
      <Title>Billing Infomation</Title>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={12} md={6}>
            <TextField
              name="name"
              label="Name *"
              placeholder="Name"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={!!formik.errors.name}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={12} md={6}>
            <TextField
              name="address"
              label="Address *"
              placeholder="Address"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.address}
              onChange={formik.handleChange}
              error={!!formik.errors.address}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              name="city"
              label="City *"
              placeholder="City"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.city}
              onChange={formik.handleChange}
              error={!!formik.errors.city}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={12} md={6}>
            <Select
              name="state"
              label="State *"
              placeholder="State"
              variant="outlined"
              value={formik.values.state}
              onChange={formik.handleChange}
              error={!!formik.errors.state}
              disabled={props.disabled}
              fullWidth
            >
              {STATE_CODES_US.map((state) => (
                <MenuItem value={state} key={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="zipCode"
              label="Zip Code *"
              placeholder="Zip code"
              variant="outlined"
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              error={!!formik.errors.zipCode}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              color="primary"
              disabled={formik.isSubmitting || props.disabled}
              type="submit"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
