import { TableCell, TableRow } from '@material-ui/core';

interface IDashboardTopProductsRow {
  name: string;
  totalUnitsSold: string;
  totalAmountSold: string;
  averageSellingPrice: string;
}

export const DashboardTopProductsRow: React.FC<IDashboardTopProductsRow> = (
  props
) => {
  return (
    <TableRow>
      <TableCell>{props.name}</TableCell>
      <TableCell align="right">{props.totalUnitsSold}</TableCell>
      <TableCell align="right">{props.totalAmountSold}</TableCell>
      <TableCell align="right">{props.averageSellingPrice}</TableCell>
    </TableRow>
  );
};
