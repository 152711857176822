import {
  SET_SEARCH_CLEAN,
  SET_SEARCH_TYPE,
  SET_SEARCH_LOADING,
  SET_SEARCH_TEXT,
  SET_SEARCH_ORDER_TYPE,
  SET_SEARCH_SORT_BY_TYPE,
} from './actionTypes';

export type SearchBuyerFilterType =
  | 'name'
  | 'ndc'
  | 'shippingName'
  | 'description';

export type SearchBuyerSortByType =
  | 'id'
  | 'expirationDate'
  | 'price'
  | 'name'
  | 'shippingName'
  | 'description';

export type SearchBuyerSortOrderType = 'asc' | 'desc';

export type SearchBuyerState = Partial<{
  text: string;
  loading: boolean;
  searchType: SearchBuyerFilterType;
  sortBy: SearchBuyerSortByType;
  sortOrder: SearchBuyerSortOrderType;
}>;

export type SearchBuyerAction = {
  type:
    | typeof SET_SEARCH_CLEAN
    | typeof SET_SEARCH_TYPE
    | typeof SET_SEARCH_LOADING
    | typeof SET_SEARCH_ORDER_TYPE
    | typeof SET_SEARCH_SORT_BY_TYPE
    | typeof SET_SEARCH_TEXT;
  search: Partial<SearchBuyerState>;
};

export const initialState: SearchBuyerState = {
  text: '',
  searchType: 'name',
  sortBy: 'name',
  sortOrder: 'asc',
  loading: false,
};

const searchBuyerReducer = (
  state: SearchBuyerState = initialState,
  action: SearchBuyerAction
): SearchBuyerState => {
  switch (action.type) {
    case 'SET_SEARCH_TEXT':
      return {
        ...state,
        text: action.search.text,
      };
    case 'SET_SEARCH_TYPE':
      return {
        ...state,
        searchType: action.search.searchType,
      };
    case 'SET_SEARCH_SORT_BY_TYPE':
      return {
        ...state,
        sortBy: action.search.sortBy,
      };
    case 'SET_SEARCH_ORDER_TYPE':
      return {
        ...state,
        sortOrder: action.search.sortOrder,
      };
    case 'SET_SEARCH_LOADING':
      return {
        ...state,
        loading: action.search.loading,
      };
    case 'SET_SEARCH_CLEAN':
      return {
        ...state,
        text: '',
        loading: false,
      };
    default:
      return initialState;
  }
};

export { searchBuyerReducer };
