import styled, { css } from 'styled-components';

export const Title = styled.h1`
  ${({ theme }) => css`
    color: black;
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoLight};
    margin-bottom: 1rem;
  `}
`;
