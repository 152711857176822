import styled, { css } from 'styled-components';

import SearchIcon from '@material-ui/icons/Search';
import { CircularProgress } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const SearchInputIcon = styled(SearchIcon)`
  ${({ theme }) => css`
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    color: ${theme.colors.gray[500]};
  `}
`;

export const LoadingIcon = styled(CircularProgress)`
  ${({ theme }) => css`
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    color: ${theme.colors.blue[100]} !important;
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    background-color: ${theme.colors.gray[100]};
    width: 34rem;
    height: 2.5rem;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 0 3.25rem;
    font-size: ${theme.sizes.medium};

    &::placeholder {
      font-size: 16px;
      line-height: 22px;
      color: ${theme.colors.gray[500]};
    }
  `}
`;
