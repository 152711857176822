import React from 'react';
import { Button, Divider, Paper, Typography } from '@material-ui/core';

interface IProps {
  subtotal: number;
  onClickCheckout(): void;
  isDisabled: boolean;
}

export const NewPurchaseOrderCheckout: React.FC<IProps> = (props) => {
  return (
    <>
      <Divider style={{ margin: '1rem 0' }} />

      <Paper
        variant="outlined"
        style={{
          alignItems: 'center',
          padding: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography>
          <strong>Subtotal</strong>: ${props.subtotal} USD
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={props.onClickCheckout}
          disabled={props.isDisabled}
        >
          Place Order
        </Button>
      </Paper>
    </>
  );
};
