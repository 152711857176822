import React from 'react';
import { useDispatch } from 'react-redux';

import { PaymentMethodACH } from 'rx-domain';

import { AppDispatch, setCheckoutSummaryPaymentMethod } from 'rx-store';

import { getPaymentMethodName } from 'rx-utils';

import { CheckoutPaymentCardACHInput } from '../CheckoutPaymentCardACHInput';

import { Container, VerifyLink } from './CheckoutPaymentACH.styles';

type ACH = PaymentMethodACH & {
  type: 'ach';
  onVerify: () => void;
};

type ICheckoutPaymentCardACHProps = {
  selectedPaymentMethod: {
    type: 'ach' | 'card' | 'paymentTerms';
    value: string;
  } | null;
  paymentMethod: ACH;
  onChange: (type: 'ach', value: string) => void;
};

export const CheckoutPaymentACH: React.FC<ICheckoutPaymentCardACHProps> = ({
  paymentMethod,
  selectedPaymentMethod,
  onChange,
}) => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <Container>
      <td>
        <CheckoutPaymentCardACHInput
          text={getPaymentMethodName(paymentMethod)}
          value={paymentMethod.id}
          onChange={() => {
            onChange('ach', paymentMethod.id);
            dispatch(
              setCheckoutSummaryPaymentMethod(
                getPaymentMethodName(paymentMethod)
              )
            );
          }}
          name="checkout-payment"
          checked={`${selectedPaymentMethod?.value}` === `${paymentMethod.id}`}
        />
      </td>
      <td></td>
      <td data-cy="ach-verify-status">
        {paymentMethod.isVerified ? `Verified ✅` : `Not Verified ❌`}{' '}
        {!paymentMethod.isVerified && (
          <VerifyLink
            data-cy="ach-verify-link"
            onClick={paymentMethod.onVerify}
          >
            Verify
          </VerifyLink>
        )}
      </td>
    </Container>
  );
};
