import { combineReducers } from 'redux';
import { shoppingCartReducer } from 'rx-store/modules/shopping-cart/reducer';
import { currentInvReducer } from 'rx-store/modules/current-inventory/reducer';
import { searchBuyerReducer } from 'rx-store/modules/search-buyer/reducer';
import { checkoutSummaryReducer } from 'rx-store/modules/checkout-summary/reducer';

const rootReducer = {
  shoppingCart: shoppingCartReducer,
  currentInv: currentInvReducer,
  searchBuyer: searchBuyerReducer,
  checkoutSummary: checkoutSummaryReducer,
};

const reducers = combineReducers(rootReducer);

export { reducers };
