import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const RemovePricingTiersDialogActions = withStyles(() => ({
  root: {
    margin: 0,
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))(DialogActions);

interface IProductPricingTierDeleteModalProps {
  open: boolean;
  onClose(): void;
  onClickConfirm(): void;
}

export const ProductPricingTierDeleteModal: React.FC<
  IProductPricingTierDeleteModalProps
> = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        Are you sure you want to remove this pricing tier?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          This action is permanent and cannot be undone
        </DialogContentText>
      </DialogContent>
      <RemovePricingTiersDialogActions>
        <Button onClick={props.onClose} color="primary" variant="outlined">
          No, cancel
        </Button>
        <Button
          onClick={props.onClickConfirm}
          color="secondary"
          variant="outlined"
        >
          Yes, remove
        </Button>
      </RemovePricingTiersDialogActions>
    </Dialog>
  );
};
