import React from 'react';
import styled, { css } from 'styled-components';
import { space, layout, SpaceProps, LayoutProps } from 'styled-system';

import { ITableHeader } from 'rx-domain';

export type TableHeaderProps = {
  items: Array<ITableHeader>;
};

const Container = styled.thead``;

const Column = styled.th<SpaceProps & LayoutProps>`
  ${({ theme }) => css`
    text-transform: uppercase;
    font-family: ${theme.fonts.robotoRegular};
    font-size: 12px;
    text-align: left;
    height: 48px;

    ${space};
    ${layout};
  `}
`;

const TableHeader = ({ items, ...props }: TableHeaderProps): JSX.Element => {
  return (
    <Container {...props}>
      <tr>
        {items.map(({ title, ...itemProps }) => (
          <Column {...itemProps} key={title}>
            {title}
          </Column>
        ))}
      </tr>
    </Container>
  );
};

export { TableHeader };
