import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { useHistory, useLocation } from 'react-router-dom';
import { useFilter } from 'rx-contexts';
import { useQuery } from 'rx-hooks';

import { InputSearch } from '../../../inputs/InputSearch';

const Container = styled.div`
  position: relative;
  margin-left: 34px;
`;

const HeaderSearchPharmacist = (): JSX.Element => {
  const timerRef = useRef<number | null>(null);

  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const search = query.get('search') ?? '';

  const { onSetLazyLoading, onSearch, loading, lazyLoading } = useFilter();

  const onSearchInput = async (event: React.SyntheticEvent) => {
    onSetLazyLoading(true);
    if (timerRef.current) clearTimeout(timerRef.current);

    const target = event.target as HTMLInputElement;

    if (!target.value) {
      onSearch('');
      history.push(`${location.pathname}`);
      onSetLazyLoading(false);
      return;
    }

    timerRef.current = window.setTimeout(() => {
      onSearch(target.value.trim());

      history.push(`${location.pathname}?search=${target.value.trim()}`);
      onSetLazyLoading(false);
    }, 750);
  };

  useEffect(() => {
    onSearch(search);
  }, [onSearch, search]);

  return (
    <Container>
      <InputSearch
        onKeyUp={onSearchInput}
        placeholder="Search for products"
        loading={loading || lazyLoading}
      />
    </Container>
  );
};

export { HeaderSearchPharmacist };
