import React from 'react';
import { Divider } from '@material-ui/core';

import { SettingsTemplate } from '../SettingsTemplate';
import { settingsBuyerTabs } from '../SettingsTemplate/tabs';
import { SettingsPersonalInfo } from './SettingsPersonalInfo';
import { SettingsEmailRecipients } from './SettingsEmailRecipients';

export const SettingsProfileView: React.FC = () => {
  return (
    <SettingsTemplate
      data-cy="account-settings"
      pageTitle="RxRise | Profile"
      links={settingsBuyerTabs()}
    >
      <SettingsPersonalInfo />

      <Divider style={{ margin: '1rem 0' }} />

      <SettingsEmailRecipients />
    </SettingsTemplate>
  );
};
