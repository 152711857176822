import { URL_V1 } from 'rx-api/api/constants';
import { useGetRequest, makeArrayReactQueryKey } from 'rx-api/utils';

const GET_BANNED_PRODUCT_URL = (id: number | string) =>
  `${URL_V1}/admin/banned-products/${id}`;

const GET_BANNED_PRODUCT_KEY = 'get-banned-product';

export const useGetAdminBannedProduct = (productId: number | string) => {
  return useGetRequest<number | string, any>({
    url: GET_BANNED_PRODUCT_URL(productId),
    key: makeArrayReactQueryKey(GET_BANNED_PRODUCT_KEY, {
      productId: `${productId}`,
    }),
  });
};
