export const BillingAddressFactory = (
  billingName: string,
  street: string,
  city: string,
  state: string,
  zipCode: string
): RxRise.Entities.IBillingAddress => {
  const getBillingName = () => {
    return billingName;
  };

  const getBillingAddress = () => {
    return `${street}, ${city}, ${state}, ${zipCode} United States`;
  };

  return { getBillingAddress, getBillingName };
};
