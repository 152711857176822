import { useGetRequest } from 'rx-api/utils';
import {
  GET_ADMIN_PHARMACY_LEGAL_DOCUMENTS_KEY,
  GET_ADMIN_PHARMACY_LEGAL_DOCUMENTS_URL,
} from 'rx-api/api/admin/pharmacies/constants';

type GetAdminPharmacyLegalDocumentsResponsePayloadDto = {
  dea: string | null;
  stateLicense: string | null;
};

const useGetAdminPharmacyLegalDocuments = (id: string) => {
  return useGetRequest<
    Record<string, unknown>,
    GetAdminPharmacyLegalDocumentsResponsePayloadDto
  >({
    url: GET_ADMIN_PHARMACY_LEGAL_DOCUMENTS_URL.replace('{id}', id),
    key: [GET_ADMIN_PHARMACY_LEGAL_DOCUMENTS_KEY],
  });
};

export { useGetAdminPharmacyLegalDocuments };
