import React, { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

import EyeIcon from '../../../assets/icons/eye-icon.svg';

export type RxInputPasswordIconProps = ImgHTMLAttributes<HTMLImageElement>;

const Icon = styled.img`
  height: 16px;
  width: 22px;
  position: absolute;
  bottom: 16px;
  right: 8px;
  cursor: pointer;
`;

const RxInputPasswordIcon: React.FC<RxInputPasswordIconProps> = (
  props
): JSX.Element => {
  return <Icon src={EyeIcon} {...props} />;
};

export { RxInputPasswordIcon };
