import styled, { css } from 'styled-components';
import { LayoutProps, layout } from 'styled-system';

export const Container = styled.div`
  border-radius: 4px 4px 0px 0px;
  background: #f8f8f8;
  display: flex;
  padding: 1rem 1rem 0.5rem 1rem;
`;

export const TagContainer = styled.div`
  background: #f8f8f8;
  display: flex;
  padding: 0.5rem 1rem 1rem 1rem;
`;

export const Column = styled.div<LayoutProps>`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    ${layout};

    & > p:first-child {
      margin-bottom: 0.5rem;
    }

    & > .order-download-links {
      color: ${theme.colors.blue[500]};
    }

    & > .order-download-links > span {
      cursor: pointer;
    }
  `}
`;
