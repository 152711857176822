import React from 'react';
import { useQueryClient } from 'react-query';
import { Button, Divider, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
  useDeleteAccount,
  useSuspendAccount,
  useReactivateAccount,
} from 'rx-api';
import { useToast } from 'rx-hooks';
import { ReactivateAccountModal } from 'pages/general/ReactivateAccountModal';
import { SuspendAccountModal } from 'pages/general/SuspendAccountModal';
import { AdminSellerDangerZoneDeleteModal } from './AdminSellerDangerZoneDeleteModal';

interface IAdminSellerDangerZoneProps {
  isDisabled: boolean;
}

export const AdminSellerDangerZone: React.FC<IAdminSellerDangerZoneProps> = (
  props
) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [isSuspendModalOpen, setIsSuspendModalOpen] = React.useState(false);
  const [isReactivateModalOpen, setIsReactivateModalOpen] =
    React.useState(false);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const deleteAccount = useDeleteAccount();
  const suspendAccount = useSuspendAccount();
  const reactivateAccount = useReactivateAccount();
  const toast = useToast();
  const queryClient = useQueryClient();

  const handleClickDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleClickCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleClickConfirmDeleteModal = async () => {
    try {
      await deleteAccount.mutateAsync(id);
      toast.onSuccessToast('Manufacturer deleted');
      history.push('/manufacturers');
    } catch (err) {
      toast.onErrorToast('Error: could not delete manufacturer');
    }
  };

  const handleClickSuspend = () => {
    setIsSuspendModalOpen(true);
  };

  const handleClickCloseSuspendModal = () => {
    setIsSuspendModalOpen(false);
  };

  const handleClickConfirmSuspendModal = async () => {
    try {
      await suspendAccount.mutateAsync(id);
      await queryClient.refetchQueries('get-admin-manufacturer-id', {
        active: true,
      });
      toast.onSuccessToast('Manufacturer suspended');
      setIsSuspendModalOpen(false);
    } catch (err) {
      toast.onErrorToast('Error: could not suspend manufacturer');
    }
  };

  const handleClickReactivate = () => {
    setIsReactivateModalOpen(true);
  };

  const handleClickCloseReactivateModal = () => {
    setIsReactivateModalOpen(false);
  };

  const handleClickConfirmReactivateModal = async () => {
    try {
      await reactivateAccount.mutateAsync(id);
      await queryClient.refetchQueries('get-admin-manufacturer-id', {
        active: true,
      });
      toast.onSuccessToast('Manufacturer reactivated');
      setIsReactivateModalOpen(false);
    } catch (err) {
      toast.onErrorToast('Error: could not suspend manufacturer');
    }
  };

  return (
    <>
      <Typography variant="h5">Danger Zone</Typography>

      <div
        style={{
          marginTop: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {!props.isDisabled && (
          <>
            <div>
              <Typography variant="h6">Suspend Account</Typography>
              <Typography>
                Temporarily disable the account to prevent logging in.
              </Typography>
            </div>
            <Button variant="outlined" onClick={handleClickSuspend}>
              Suspend Account
            </Button>
          </>
        )}

        {props.isDisabled && (
          <>
            <div>
              <Typography variant="h6">Suspend Account</Typography>
              <Typography>Reactivate account and allow to log-in.</Typography>
            </div>
            <Button variant="outlined" onClick={handleClickReactivate}>
              Reactivate Account
            </Button>
          </>
        )}
      </div>

      <Divider style={{ margin: '1rem 0' }} />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Typography variant="h6">Delete Manufacturer</Typography>
          <Typography>
            The account's products and selling history will be erased from
            RxRise.
          </Typography>
        </div>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClickDelete}
        >
          Delete Manufacturer
        </Button>
      </div>

      <AdminSellerDangerZoneDeleteModal
        isOpen={isDeleteModalOpen}
        onClickClose={handleClickCloseDeleteModal}
        onClickConfirm={handleClickConfirmDeleteModal}
      />

      <SuspendAccountModal
        isOpen={isSuspendModalOpen}
        onClickClose={handleClickCloseSuspendModal}
        onClickConfirm={handleClickConfirmSuspendModal}
      />

      <ReactivateAccountModal
        isOpen={isReactivateModalOpen}
        onClickClose={handleClickCloseReactivateModal}
        onClickConfirm={handleClickConfirmReactivateModal}
      />
    </>
  );
};
