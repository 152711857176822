import { toast } from 'react-hot-toast';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useToast = () => {
  const onSuccessToast = (msg: string, duration = 5000) => {
    toast(msg, {
      duration: duration,
      style: {
        backgroundColor: '#3EB77D',
        borderRadius: '5px',
        color: '#FFFFFF',
        padding: '8px 12px',
        textAlign: 'center',
      },
    });
  };

  const onErrorToast = (msg: string, duration = 5000) => {
    toast(msg, {
      id: 'error',
      duration: duration,
      style: {
        backgroundColor: '#FF6969',
        borderRadius: '5px',
        color: '#FFFFFF',
        padding: '8px 12px',
        textAlign: 'center',
      },
    });
  };

  return {
    onSuccessToast,
    onErrorToast,
  };
};

export { useToast };
