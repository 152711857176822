import React from 'react';
import styled, { css } from 'styled-components';
import { CircularProgress, Grid } from '@material-ui/core';
import JCBCardIcon from 'assets/icons/empty-icon.svg';

import { GetProducts, InventoryType } from 'rx-domain';

import { ProductItem } from '../molecules/ProductItem';

type IProps = {
  loading?: boolean;
  type: InventoryType;
  products: GetProducts.ProductItem[];
};

const Container = styled(Grid)``;

const LoadingContainer = styled.div`
  display: flex;
  height: 81.25rem;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem 0;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const EmptyTitle = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-weight: 400;
  `}
`;

const ResponsiveContainer = styled(Grid)`
  ${({ theme }) => css`
    ${theme.lessThan('ipadPro')} {
      &.MuiGrid-item {
        margin-bottom: 0rem;
      }
    }
  `}
`;

const EmptyImage = styled.img`
  margin-bottom: 2rem;
`;

const ProductContainer = styled(Grid)`
  ${({ theme }) => css`
    ${theme.lessThan('ipadPro')} {
      &.MuiGrid-spacing-xs-2 {
        width: initial;
        margin: initial;
      }
    }
  `}
`;

export const ProductsList: React.FC<IProps> = (props) => {
  return (
    <Container item xs={12} md={12}>
      {props.loading && (
        <LoadingContainer data-testid="products-loading">
          <CircularProgress size={60} />
        </LoadingContainer>
      )}
      {!props.loading && props.products.length === 0 && (
        <EmptyContainer data-testid="products-empty">
          <EmptyImage src={JCBCardIcon} />
          <EmptyTitle>
            There are no products added yet. <br />
            Please come back soon or choose another tab
          </EmptyTitle>
        </EmptyContainer>
      )}
      <ProductContainer
        container
        item
        xs={12}
        spacing={2}
        data-testid="products-list"
      >
        {props.products.map((product) => (
          <ResponsiveContainer item md={4} xs={12} sm={6} key={product.id}>
            <ProductItem {...product} />
          </ResponsiveContainer>
        ))}
      </ProductContainer>
    </Container>
  );
};
