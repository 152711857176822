import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useForgorPassword = () => {
  const onForgotPasswordRequest = (email: string) => {
    return http.post<{
      email: string;
    }>(`${process.env.REACT_APP_WEBSITE_URL}/v1/auth/reset-password`, {
      email,
    });
  };

  const mutation = useMutation(onForgotPasswordRequest, {});

  return mutation;
};

export { useForgorPassword };
