import * as Yup from 'yup';
import { address, city, state, zipCode } from './common.validator';

const ShippingAddressSchema = Yup.object().shape({
  address,
  city,
  state,
  zipCode,
});

export { ShippingAddressSchema };
