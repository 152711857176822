import { useState } from 'react';
import { usePagination } from 'rx-hooks/utils/usePaginationSimple';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePaginationFilter = <T extends string>() => {
  const [length, setLength] = useState<number>(0);
  const [filterType, setFilter] = useState<string>('');
  const [filterTitle, setFilterTitle] = useState<string>('');
  const [filterSort, setSorting] = useState<string>('');
  const [filterVisible, setFilterVisible] = useState(false);

  const pagination = usePagination({
    totalItems: length,
    initialPage: 0,
    initialPageSize: 5,
  });

  const onFilter = (value: T, title: string) => {
    const [type, sort] = value.split('-');

    setFilter(type);
    setSorting(sort);
    setFilterTitle(title);
    setFilterVisible(false);
  };

  const onSetTotalItems = (total: number) => {
    setLength(total);
  };

  const onSetFilterVisible = (visibility: boolean) => {
    setFilterVisible(visibility);
  };

  return {
    filterType,
    filterTitle,
    filterSort,
    filterVisible,
    pagination,
    onSetTotalItems,
    onFilter,
    onSetFilterVisible,
    totalItems: length,
  };
};

export { usePaginationFilter };
