import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge, IconButton } from '@material-ui/core';
import {
  FavoriteBorder as FavoriteBorderIcon,
  LocalMallOutlined,
} from '@material-ui/icons';

import { getTotalCartItems } from 'rx-store';

import styled from 'styled-components';
import { HeaderSettings } from '../HeaderSettings';
import { SearchHeader } from '../../search/molecules/SearchHeader';

import { Container, Spacer } from './index.styles';

const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    color: 'white',
    backgroundColor: '#5077F2',
  },
});

const HeaderBuyer = ({ isHomePage = false }): JSX.Element => {
  const totalCartItems = useSelector(getTotalCartItems);

  return (
    <Container>
      <SearchHeader isHomePage={isHomePage} />
      <Spacer />
      <Link to="/favorites">
        <IconButton aria-label="favorites">
          <FavoriteBorderIcon fontSize="medium" color="inherit" />
        </IconButton>
      </Link>
      <Link to="/shopping-cart" data-cy="shopping-cart-icon">
        <IconButton aria-label="cart">
          <StyledBadge badgeContent={totalCartItems}>
            <LocalMallOutlined />
          </StyledBadge>
        </IconButton>
      </Link>
      <HeaderSettings />
    </Container>
  );
};

export { HeaderBuyer };
