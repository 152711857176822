import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink, LinkProps } from 'react-router-dom';

import { theme } from 'rx-styles';

type HeaderOptionProps = LinkProps & {
  text: string;
};

const Link = styled(NavLink)`
  ${({ theme: { sizes, fonts, colors } }) => css`
    line-height: 25px;
    text-decoration: none;
    font-size: ${sizes.medium};
    font-family: ${fonts.robotoRegular};
    font-weight: 400;
    color: ${colors.black[100]};
  `}
`;

const HeaderOption = ({ text, ...props }: HeaderOptionProps): JSX.Element => {
  return (
    <Link {...props} activeStyle={{ color: theme.colors.blue[200] }}>
      {text}
    </Link>
  );
};

export { HeaderOption };
