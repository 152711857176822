import { PostPaidSaleOrder } from 'rx-domain';
import { POST_PAID_SALE_ORDER_URL } from 'rx-api/api/admin/sale-order-payments/constants';
import { useRxMutation } from 'rx-api/utils';
import { http } from 'rx-api/http/repository';
import { useMutation } from 'react-query';

export const usePostPaidSaleOrder = ({
  saleOrderId,
}: PostPaidSaleOrder.DTO) => {
  return useRxMutation<PostPaidSaleOrder.DTO, PostPaidSaleOrder.Response>({
    method: 'post',
    url: `${POST_PAID_SALE_ORDER_URL}/${saleOrderId}/deposit`,
  });
};

export const useSetPaymentStatus = () => {
  const onRequest = (request: any) => {
    return http.post(
      `${POST_PAID_SALE_ORDER_URL}/${request.saleOrderId}/deposit`,
      request
    );
  };

  return useMutation(onRequest);
};
