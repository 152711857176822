import Carousel from 'react-multi-carousel';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import 'react-multi-carousel/lib/styles.css';
import '../custom.css';
import { Grid, Typography } from '@material-ui/core';
import { FeaturedProduct } from '../../home/FeaturedProduct/FeaturedProduct';
import { GetFeaturedProducts } from '../../../../rx-domain';
import { useGetFeaturedProducts } from '../../../../rx-api';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

const ContainerSlider = styled.div`
  max-width: 330px;
  margin-left: 2px;
`;

const SwiperContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperStyled = styled(Swiper)`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
`;

const SwiperSlideStyled = styled(SwiperSlide)`
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  width: auto !important;
`;

const BlueOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
`;

const FeaturedSection = (): JSX.Element => {
  const { data: products } = useGetFeaturedProducts({ limit: 3 });
  const swiperRef = useRef<SwiperRef>(null);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);

  const recalculateState = () => {
    setIsNextDisabled(!!swiperRef?.current?.swiper?.isEnd);
  };

  useEffect(() => {
    recalculateState();
  }, [swiperRef?.current]);

  const productsLoaded = products?.data?.data
    ? products?.data?.data
        .sort(
          (productA, productB) =>
            new Date(productB.updatedAt).getTime() -
            new Date(productA.updatedAt).getTime()
        )
        .map((product) => (
          <SwiperSlideStyled key={product.id}>
            <ContainerSlider>
              <FeaturedProduct
                {...(product as GetFeaturedProducts.ProductItem)}
              />
            </ContainerSlider>
          </SwiperSlideStyled>
        ))
    : [];

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          <div>
            <Typography
              variant="h5"
              style={{ marginTop: '30px', marginBottom: '15px' }}
            >
              Featured Products
            </Typography>
            <SwiperContainer>
              <SwiperStyled
                ref={swiperRef}
                onReachBeginnin={recalculateState}
                onReachEnd={recalculateState}
                onLock={() => setIsLocked(true)}
                onUnlock={() => setIsLocked(false)}
                onRealIndexChange={recalculateState}
                modules={[Navigation]}
                navigation
                className="swiper"
                slidesPerView="auto"
                spaceBetween={40}
                updateOnWindowResize
                watchOverflow
              >
                {productsLoaded}
                {!isNextDisabled && !isLocked && (
                  <BlueOverlay slot="container-end" />
                )}
              </SwiperStyled>
            </SwiperContainer>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FeaturedSection;
