import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const Text = styled.span``;

const LoginLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.blue[100]};
  `}
`;

const InputErrorEmailMessage = (): JSX.Element => {
  return (
    <Text>
      Email already registered. Do you want to{' '}
      <LoginLink to="/login">Login?</LoginLink>
    </Text>
  );
};

export { InputErrorEmailMessage };
