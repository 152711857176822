import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Fade, Popper } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

import { SearchQueryParams } from 'rx-domain';
import { formatDate, onMakeURL } from 'rx-utils';

import {
  Container,
  PickerContainer,
  ButtonContainer,
  DateText,
  InputPicker,
  CardContainer,
  CalendarIcon,
} from './index.styles';

type IProps = {
  queries: SearchQueryParams;
};

export const ReportFilterDatePicker: React.FC<IProps> = (props) => {
  const [startDate, changeStartDate] = useState(new Date());
  const [endDate, changeEndDate] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [fromClicked, setFromClicked] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  const history = useHistory();

  const inputDate = useMemo(() => {
    return `${formatDate(startDate.toISOString())} - ${formatDate(
      endDate.toISOString()
    )}`;
  }, [endDate, startDate]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setTimeout(() => {
      setFromClicked(false);
    }, 250);
  };

  const applySearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    history.push(
      onMakeURL({
        ...props.queries,
        to: endDate.toISOString(),
        from: startDate.toISOString(),
      })
    );

    handleClick(event);
  };

  return (
    <Container>
      <InputPicker aria-describedby={id} onClick={handleClick}>
        <span>{inputDate}</span>
        <CalendarIcon />
      </InputPicker>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom-end"
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          offset: {
            enabled: true,
            offset: '405px',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <PickerContainer>
              <CardContainer>
                <div>
                  <DateText $active={!fromClicked}>
                    From: {formatDate(startDate.toISOString(), 'MMM DD, YYYY')}
                  </DateText>
                  <DatePicker
                    autoOk
                    variant="static"
                    openTo="date"
                    disableToolbar
                    disableFuture
                    minDate={new Date(2021, 3, 1)}
                    value={startDate}
                    onChange={(value) => {
                      if (value) changeStartDate(value.toDate());
                      setFromClicked(true);
                    }}
                  />
                </div>
                <div>
                  <DateText $active={fromClicked}>
                    To: {formatDate(endDate.toISOString(), 'MMM DD, YYYY')}
                  </DateText>
                  <DatePicker
                    autoOk
                    variant="static"
                    disableToolbar
                    disableFuture
                    value={endDate}
                    minDate={startDate}
                    onChange={(value) => value && changeEndDate(value.toDate())}
                  />
                </div>
              </CardContainer>
              <ButtonContainer>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={applySearch}
                >
                  Apply
                </Button>
              </ButtonContainer>
            </PickerContainer>
          </Fade>
        )}
      </Popper>
    </Container>
  );
};
