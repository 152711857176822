import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useField } from 'formik';
import MaskedInput from 'react-text-mask';

import { GetProducts } from 'rx-domain';

import { buildNumberMask } from 'rx-utils';

type IProps = GetProducts.ProductItem;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input<{ $error: boolean }>`
  ${({ theme, $error }) => css`
    height: 40px;
    border: 1px solid #9fa6b9;
    width: 60%;
    background-color: ${theme.colors.gray[100]};
    outline: none;
    padding-left: 0;
    text-align: center;

    ${$error &&
    css`
      border: 1px red solid;
    `}
  `}
`;

export const PlusButton = styled.button`
  ${({ theme }) => css`
    border-radius: 0 8px 8px 0;
    background: white;
    border: 1px solid #9fa6b9;
    width: 1.5rem;
    height: 40px;

    font-size: ${theme.sizes.medium};
    cursor: pointer;
  `}
`;

export const MinusButton = styled(PlusButton)`
  border-radius: 8px 0 0 8px;
`;

const ProductItemAmount = (props: IProps): JSX.Element => {
  const [field, meta, helpers] = useField<number>('units');

  useEffect(() => {
    helpers.setValue(props.minimumQuantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formikValidation = meta.touched && !!meta.error;
  const checkoutValidation =
    field.value >= props.minimumQuantity && field.value <= props.units;

  const decrementValue = () => {
    const newValue = Math.max(field.value - 1, props.minimumQuantity);
    helpers.setValue(newValue);
  };

  const incrementValue = () => {
    const newValue = Math.min(field.value + 1, props.units);
    helpers.setValue(newValue);
  };

  return (
    <Container>
      <MinusButton type="button" onClick={decrementValue}>
        -
      </MinusButton>
      <MaskedInput
        mask={buildNumberMask(9999999)}
        guide={false}
        {...field}
        render={(ref: (inputElement: HTMLInputElement) => void, maskProps) => {
          return (
            <>
              <Input
                data-cy="products-quantity-input"
                ref={ref}
                {...maskProps}
                $error={
                  formikValidation || (meta.touched && !checkoutValidation)
                }
                type="text"
              />
            </>
          );
        }}
      />
      <PlusButton type="button" onClick={incrementValue}>
        +
      </PlusButton>
    </Container>
  );
};

export { ProductItemAmount };
