import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

import { useResponsive } from 'rx-hooks';
import { IMediaQueryTransient } from 'rx-domain';

import { FooterAddressTitle, FooterAddressContent, FooterLink } from '../atoms';

type FooterAddressProps = LayoutProps;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Roboto Light, serif;
  max-width: 1240px;
  margin: 0 auto;
  ${layout}
`;

const Text = styled.span`
  margin-top: 1rem;
  color: #9fa6b9;
`;

const TermsAndConditionsText = styled(Link)<IMediaQueryTransient>`
  cursor: pointer;
  text-decoration: none;
  color: #9fa6b9;

  ${({ $isIpad }) =>
    $isIpad &&
    css`
      margin-bottom: 48px;
    `}
`;

const buildTime = process.env.REACT_APP_LAST_BUILD_TIME;

export const FooterAddress: React.FC<FooterAddressProps> = ({ ...props }) => {
  const { getMediaQueryPropsTransient } = useResponsive();

  return (
    <Column width={['100%', '100%', '100%', '100%', '40%', '40%']} {...props}>
      <FooterAddressTitle>
        RxRise <br />
      </FooterAddressTitle>
      <FooterAddressContent>
        Contact us:
        <br />
        <FooterLink href="mailto:hello@rxrise.com">hello@rxrise.com</FooterLink>
        <br />
      </FooterAddressContent>

      <Text>© {new Date().getFullYear()} RxRise. All rights reserved.</Text>
      <TermsAndConditionsText
        to="/legal/end-user-agreement"
        {...getMediaQueryPropsTransient()}
      >
        Terms & Conditions
      </TermsAndConditionsText>
      <Text>Last built at: {buildTime}</Text>
    </Column>
  );
};
