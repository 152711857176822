import React from 'react';

import { Container, Subtitle, Title } from './index.styles';

export const ShopCartTitle: React.FC = () => {
  return (
    <Container>
      <Title>Shopping Cart</Title>
      <Subtitle>Summary of orders arranged by Sellers</Subtitle>
    </Container>
  );
};
