import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { SearchQueryParams } from 'rx-domain';

import { onMakeURL } from 'rx-utils';

import { Container, SelectType, SelectTypeText } from './index.styles';

type IFilterType = {
  type: 'all' | 'edi' | 'non-edi';
  tag: string;
};

const filters: Array<IFilterType> = [
  { type: 'all', tag: 'All' },
  { type: 'edi', tag: 'EDI Orders' },
  { type: 'non-edi', tag: 'Regular Orders' },
];

type IProps = {
  queries: SearchQueryParams;
};

export const ReportFilterEdi: React.FC<IProps> = (props: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const history = useHistory();

  const filterType =
    filters.find((f) => f.type === props.queries.edi)?.tag ?? 'all';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value: IFilterType) => {
    setAnchorEl(null);

    const fullURL = onMakeURL({ ...props.queries, edi: value.type });

    history.push(fullURL);
  };

  return (
    <Container>
      <SelectType
        aria-controls="search-reports-edi-filter-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <SelectTypeText>EDI: {filterType}</SelectTypeText>
        <KeyboardArrowDownIcon />
      </SelectType>
      <Menu
        id="search-reports-edi-filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {filters.map((filter) => (
          <MenuItem onClick={() => handleClose(filter)} key={filter.tag}>
            {filter.tag}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};
