import React from 'react';
import { useFormik } from 'formik';
import { Button, Grid, Paper, TextField } from '@material-ui/core';
import axios from 'axios';

import { useGetContactInfo, useUpdateContactInfo } from 'rx-api';
import { useToast } from 'rx-hooks';
import { clearPhonenumber } from 'rx-utils';

import { ManufacturerProfileInfoSchema } from 'utils/validators/account-settings/manufacturer/profile-info.validator';
import { Title } from './index.styles';

type IForm = {
  firstName: string;
  lastName: string;
  titleOrPosition: string;
  phoneNumber: string;
  email: string;
  secondaryEmail: string;
};

export const SettingsPersonalInfoForm: React.FC = () => {
  const { data: contactInfoRes } = useGetContactInfo();
  const updateContactInfo = useUpdateContactInfo();
  const { onErrorToast, onSuccessToast } = useToast();

  const formik = useFormik({
    initialValues: {
      firstName: contactInfoRes?.data?.firstName || '',
      lastName: contactInfoRes?.data?.lastName || '',
      phoneNumber: contactInfoRes?.data?.phoneNumber || '',
      titleOrPosition: contactInfoRes?.data?.titleOrPosition || '',
      email: contactInfoRes?.data?.email || '',
      secondaryEmail: contactInfoRes?.data?.secondaryEmail || '',
    },
    enableReinitialize: true,
    validationSchema: ManufacturerProfileInfoSchema,
    onSubmit: async ({ email, phoneNumber, ...values }: IForm) => {
      try {
        await updateContactInfo.mutateAsync({
          ...values,
          phoneNumber: clearPhonenumber(phoneNumber),
        });

        onSuccessToast('Changes saved succesfully 🚀');
      } catch (err) {
        if (axios.isAxiosError(err)) {
          onErrorToast(err.response?.data.message);
        }
      }
    },
  });

  return (
    <Paper variant="outlined" style={{ padding: '1rem' }}>
      <Title>Personal Information</Title>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs>
            <TextField
              name="firstName"
              label="First Name *"
              placeholder="First Name"
              variant="outlined"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs>
            <TextField
              name="lastName"
              label="Last Name *"
              placeholder="Last Name"
              variant="outlined"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs>
            <TextField
              name="titleOrPosition"
              label="Title/company position *"
              placeholder="Title/Position at Company"
              variant="outlined"
              value={formik.values.titleOrPosition}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs>
            <TextField
              name="phoneNumber"
              label="contact Phone number *"
              placeholder="Phone number *"
              variant="outlined"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TextField
              name="email"
              label="Primary email"
              placeholder=""
              variant="outlined"
              value={formik.values.email}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="secondaryEmail"
              label="Secondary email"
              variant="outlined"
              placeholder=""
              value={formik.values.secondaryEmail}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              // popperInfoText="Your address will be used as an additional destination for security-relevant account notifications and can also be used for password resets."
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
