import React from 'react';
import { Button, Grid, TextField } from '@material-ui/core';

interface IProps {
  data: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    secondaryEmail: string;
    password: string;
    confirmPassword: string;
    type: string;
  };
  errors: Record<string, any>;

  isLoading: boolean;
  onSubmit(values: any): void;
  onChange(value: any): void;
}

export const AdminNewAccountForm: React.FC<IProps> = (props) => {
  return (
    <form onSubmit={props.onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="First Name"
            name="firstName"
            value={props.data.firstName}
            onChange={props.onChange}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            error={!!props.errors.firstName}
            helperText={props.errors.firstName}
            disabled={props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Last Name"
            name="lastName"
            value={props.data.lastName}
            onChange={props.onChange}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            error={!!props.errors.lastName}
            helperText={props.errors.lastName}
            disabled={props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Email"
            name="email"
            value={props.data.email}
            onChange={props.onChange}
            variant="outlined"
            error={!!props.errors.email}
            helperText={props.errors.email}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Secondary Email"
            name="secondaryEmail"
            value={props.data.secondaryEmail}
            onChange={props.onChange}
            variant="outlined"
            error={!!props.errors.secondaryEmail}
            helperText={props.errors.secondaryEmail}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Phone Number"
            name="phoneNumber"
            value={props.data.phoneNumber}
            onChange={props.onChange}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Account Type"
            name="type"
            value={props.data.type}
            onChange={props.onChange}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={true}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Password"
            name="password"
            value={props.data.password}
            onChange={props.onChange}
            variant="outlined"
            type="password"
            error={!!props.errors.password}
            helperText={props.errors.password}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            value={props.data.confirmPassword}
            onChange={props.onChange}
            variant="outlined"
            type="password"
            error={!!props.errors.confirmPassword}
            helperText={props.errors.confirmPassword}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={props.isLoading}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
