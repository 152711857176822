import React from 'react';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

import { FooterNewsletterTitle } from './FooterNewsletterTitle';

type FooterColumnProps = LayoutProps & {
  children: React.ReactNode;
};

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${layout}
`;

const FooterColumn = ({
  children,
  ...props
}: FooterColumnProps): JSX.Element => {
  return (
    <Column {...props}>
      <FooterNewsletterTitle>{children}</FooterNewsletterTitle>
    </Column>
  );
};

export { FooterColumn };
