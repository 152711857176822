import { SEO, TemplateView, TemplateContent } from 'rx-core';
import React from 'react';
import {
  Grid,
  Typography,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@material-ui/core';

import { DragIndicator } from '@material-ui/icons';
import styled, { css } from 'styled-components';

import { AdminCategoryCard } from './components/AdminCategoryCard';
import { CategoryAddModal } from './components/CategoryAddModal';
import { CategoryEditModal } from './components/CategoryEditModal';
import RxRiseLogo from 'assets/logos/rxrise-gray-logo.svg';
import { useGetFeaturedProducts, useGetCategoriesList } from 'rx-api';

const CategoriesContainer = styled(Grid)`
  ${({ theme }) => css`
    column-gap: 20px;
    background-color: white;
    padding: 18px;
    border-radius: 6px;
    border: 1px #b9b9b9 solid;
    flex-wrap: wrap;
    justify-content: space-around;
    ${theme.lessThan('mobile')} {
      flex-wrap: wrap;
    }
  `}
`;

const HeaderGrid = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ImgFeatured = styled.img`
  width: 50px;
`;

const ImgFeaturedContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CategoriesView: React.FC = () => {
  const { data, refetch } = useGetCategoriesList();
  const { data: featuredProducts } = useGetFeaturedProducts({ limit: 999 });
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);
  const [currentCategoryEdit, setCurrentCategoryEdit] = React.useState(0);

  const productsLoaded = featuredProducts?.data?.data
    ? featuredProducts?.data?.data.sort(
        (productA, productB) =>
          new Date(productB.updatedAt).getTime() -
          new Date(productA.updatedAt).getTime()
      )
    : [];

  const handleClickAddOpenModal = () => {
    setIsAddModalOpen(true);
  };

  const handleClickCancelAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleClickConfirmAddModal = () => {
    refetch();
    setIsAddModalOpen(false);
  };

  const handleClickEditOpenModal = (categoryId: number) => {
    setIsEditModalOpen(true);
    setCurrentCategoryEdit(categoryId);
  };

  const handleClickEditCancelModal = () => {
    setIsEditModalOpen(false);
  };

  const handleClickDeleteEditModal = () => {
    refetch();
    setIsEditModalOpen(false);
  };

  return (
    <TemplateView>
      <CategoryAddModal
        isOpen={isAddModalOpen}
        onClickCancel={handleClickCancelAddModal}
        onClickConfirm={handleClickConfirmAddModal}
      />
      <CategoryEditModal
        onClickCancel={handleClickEditCancelModal}
        isOpen={isEditModalOpen}
        data={data}
        categoryId={currentCategoryEdit}
        onClickDelete={handleClickDeleteEditModal}
      />
      <SEO title="RxRise | Categories" />
      <TemplateContent>
        <HeaderGrid>
          <div>
            <Typography variant="h5">Category Management</Typography>
            <Typography variant="subtitle2">
              Customize RxRise’s categories. They will be displayed on the
              homepage of the marketplace.
            </Typography>
          </div>
          <Button
            onClick={handleClickAddOpenModal}
            variant="contained"
            color="primary"
            size="large"
          >
            + ADD NEW CATEGORY
          </Button>
        </HeaderGrid>
        <CategoriesContainer container>
          {data?.map((item) => (
            <AdminCategoryCard
              handleOnClick={() => handleClickEditOpenModal(item.id)}
              key={item.id}
              background={item.pictureUrl}
              text={item.slug}
            />
          ))}
        </CategoriesContainer>
        <br />
        <hr />
        <br />
        <HeaderGrid>
          <div>
            <Typography variant="h5">Featured Products</Typography>
            <Typography variant="subtitle2">
              Customize the order of the products being featured on the
              marketplace.
            </Typography>
          </div>
        </HeaderGrid>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <strong>Order</strong>
                </TableCell>
                <TableCell>
                  <strong>Product Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Price</strong>
                </TableCell>
                <TableCell>
                  <strong>Quantity Available</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productsLoaded.map((item, index) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>
                      <ImgFeaturedContainer>
                        <DragIndicator />
                        <ImgFeatured src={item.pictureUrl || RxRiseLogo} />
                      </ImgFeaturedContainer>
                    </TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>${item.price}</TableCell>
                    <TableCell>{item.units}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25]}
            count={productsLoaded.length}
            page={0}
            onPageChange={() => {}}
            rowsPerPage={5}
          />
        </Paper>
      </TemplateContent>
    </TemplateView>
  );
};
