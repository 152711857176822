import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const EdiTableLoader: React.FC = () => (
  <TableRow>
    <TableCell colSpan={5}>
      <Skeleton height={50} />
      <Skeleton height={50} />
      <Skeleton height={50} />
      <Skeleton height={50} />
      <Skeleton height={50} />
    </TableCell>
  </TableRow>
);
