import { GetProductById } from 'rx-domain';
import { GET_PRODUCT_BY_ID_KEY, GET_PRODUCT_BY_ID_URL } from './constants';
import { useGetRequest } from 'rx-api/utils';

const useGetProductById = ({ id }: GetProductById.Params) => {
  return useGetRequest<unknown, GetProductById.Response>({
    url: `${GET_PRODUCT_BY_ID_URL}/${id}`,
    key: [GET_PRODUCT_BY_ID_KEY, id],
  });
};

export { useGetProductById };
