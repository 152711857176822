import React from 'react';
import { useHistory } from 'react-router-dom';

import { GetPharmacyList } from 'rx-domain';
import {
  TableCell,
  TableRow,
  Typography,
  Link,
  Button,
} from '@material-ui/core';

type IBuyerListItemProps = {
  data: GetPharmacyList.PharmacyDto;
};

export const BuyerListItem: React.FC<IBuyerListItemProps> = (props) => {
  const history = useHistory();

  const handleClickView = () => {
    history.push(`/pharmacies/${props.data.id}`);
  };

  const DEA_FILE = props.data.legalDocuments.find((af) => af.name === 'dea');
  const STATE_LICENSE_FILE = props.data.legalDocuments.find(
    (af) => af.name === 'state_license'
  );

  const handleClickDea = () => {
    window.open(DEA_FILE?.url, '_blank');
  };

  const handleClickStateLicense = () => {
    window.open(STATE_LICENSE_FILE?.url, '_blank');
  };

  return (
    <TableRow>
      <TableCell>
        <Typography>{props.data.pharmacyName}</Typography>
      </TableCell>

      <TableCell>
        <Typography>
          {DEA_FILE?.url ? (
            <Link onClick={handleClickDea}>Download</Link>
          ) : (
            props.data.dea || 'N/A'
          )}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography>
          {STATE_LICENSE_FILE?.url ? (
            <Link onClick={handleClickStateLicense}>Download</Link>
          ) : (
            props.data.stateLicense || 'N/A'
          )}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography>{props.data.billingAddress?.address || 'N/A'}</Typography>
      </TableCell>

      <TableCell>
        <Typography>{props.data.billingAddress?.city || 'N/A'}</Typography>
      </TableCell>

      <TableCell>
        <Typography>{props.data.billingAddress?.state || 'N/A'}</Typography>
      </TableCell>

      <TableCell>
        <Typography>{props.data.billingAddress?.zipCode || 'N/A'}</Typography>
      </TableCell>

      <TableCell>
        <Button variant="outlined" color="primary" onClick={handleClickView}>
          VIEW
        </Button>
      </TableCell>
    </TableRow>
  );
};
