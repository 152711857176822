import React from 'react';
import styled, { css } from 'styled-components';

type VerificationFormTitleProps = {
  className?: string;
};

const Text = styled.h2`
  font-family: ${({ theme }) => theme.fonts.roobertLight};
  font-size: 40px;
  line-height: 45px;
  color: ${({ theme }) => theme.colors.black[100]};

  ${({ theme: { sizes, fonts, lessThan } }) => css`
    ${lessThan('ipadPro')} {
      font-size: ${sizes.h2};
      font-family: ${fonts.roobertLight};
    }
  `}
`;

const VerificationFormTitle = ({
  ...props
}: VerificationFormTitleProps): JSX.Element => {
  return <Text {...props}>Confirm Validation</Text>;
};

VerificationFormTitle.defaultProps = {
  className: '',
};

export { VerificationFormTitle };
