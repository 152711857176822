import React from 'react';

import { GetFavorites } from 'rx-domain';

import { FavoriteItem } from '../FavoriteItem';

import { Container } from './index.styles';

type IProps = {
  items: GetFavorites.FavoriteItem[];
};

const FavoritesItems = (props: IProps): JSX.Element => {
  return (
    <Container>
      {props.items.map((item) => (
        <FavoriteItem item={item} key={item.id} />
      ))}
    </Container>
  );
};

export { FavoritesItems };
