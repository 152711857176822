import { useMutation } from 'react-query';
import { IPostAuthLogin2FADTO, IPostAuthLogin2FA } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePostAuthLogin2FA = () => {
  const onLoginRequest = async (request: IPostAuthLogin2FADTO) => {
    return http.post<IPostAuthLogin2FA>(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/auth/login/2fa`,
      request,
      {
        withCredentials: true,
      }
    );
  };

  const mutation = useMutation(onLoginRequest);

  return mutation;
};

export { usePostAuthLogin2FA };
