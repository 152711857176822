import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  flex: 1;
`;

export const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: white;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e9ecfe;
`;

export const PageItem = styled.li`
  list-style: none;
`;

export const ItemButton = styled.button`
  ${({ theme, ...props }) => css`
    padding: 0.75rem 0.5rem;
    border: 0;
    background-color: white;
    cursor: pointer;
    color: #838ca5;

    font-size: ${theme.sizes.medium};

    ${props['aria-current'] &&
    css`
      color: black;
    `};
  `}
`;
