import React from 'react';

import { ITransactionPurchaseOrder } from 'rx-domain';
import { capitalize, formatMoney } from 'rx-utils';

import { TransactionPODetailsInfoRow } from '../TransactionPODetailsInfoRow';
import { FeeContainer, InfoColumn, InfoContainer } from './index.styles';

type IProps = ITransactionPurchaseOrder;

export const TransactionPODetailsInfo: React.FC<IProps> = (props) => {
  return (
    <InfoColumn>
      <InfoContainer>
        <TransactionPODetailsInfoRow
          content={
            <>
              {props.buyer.pharmacy.pharmacyName} <br />
              {props.buyer.email}
            </>
          }
          title="BUYER INFO"
        />

        <TransactionPODetailsInfoRow
          content={
            <>
              {props.seller.firstName} {props.seller.lastName} <br />
              {props.seller.email}
            </>
          }
          title="SELLER INFO"
        />
      </InfoContainer>

      <InfoContainer>
        <FeeContainer>
          <TransactionPODetailsInfoRow
            content={`${props.seller.fee.percentage}%`}
            title="FEE"
          />
        </FeeContainer>

        <FeeContainer>
          <TransactionPODetailsInfoRow
            content={capitalize(props.seller.fee.rate)}
            title="FEE RATE"
          />
        </FeeContainer>

        <FeeContainer>
          <TransactionPODetailsInfoRow
            content={formatMoney(parseFloat(props.subtotal))}
            title="GROSS AMOUNT"
          />
        </FeeContainer>

        <FeeContainer>
          <TransactionPODetailsInfoRow
            content={formatMoney(props.sellersSplit)}
            title="SELLER'S SPLIT"
          />
        </FeeContainer>
      </InfoContainer>
    </InfoColumn>
  );
};
