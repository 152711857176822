import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { useToast } from 'rx-hooks';
import { Paper, Typography } from '@material-ui/core';
import { SEO, TemplateContent, TemplateView } from 'rx-core';
import { AdminNewAccountForm } from './AdminNewAccountForm';
import { password } from 'utils/validators/common.validator';
import { useCreateAccount } from 'rx-api';

export const AdminNewAccountView: React.FC = () => {
  const toast = useToast();
  const history = useHistory();
  const createAccount = useCreateAccount();

  const accountFormik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      secondaryEmail: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      type: 2, // admin
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string().email().required('Email is required'),
      password: password.required('Passord is required'),
      phoneNumber: Yup.string().required('Phone number is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords dont't match")
        .required('Password confirmation is required'),
    }),
    onSubmit: async (values) => {
      try {
        await createAccount.mutateAsync({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          secondaryEmail: values.secondaryEmail,
          phoneNumber: values.phoneNumber,
          type: values.type,
          password: values.password,
        });
        toast.onSuccessToast('Account created!');
        history.push('/accounts');
      } catch (err: any) {
        if (err?.response?.data.message === 'Email Already In Use') {
          accountFormik.setErrors({
            email: 'Email already in use',
          });
        }

        toast.onErrorToast('Error: could not create account');
      }
    },
  });

  return (
    <TemplateView>
      <SEO title="RxRise | Ad List" />
      <TemplateContent>
        <header>
          <Typography variant="h5">Account</Typography>
        </header>

        <Paper
          variant="outlined"
          style={{ padding: '1rem', marginTop: '1rem' }}
        >
          <AdminNewAccountForm
            data={{ ...accountFormik.values, type: 'ADMIN' }}
            errors={accountFormik.errors}
            onChange={accountFormik.handleChange}
            onSubmit={accountFormik.handleSubmit}
            isLoading={false}
          />
        </Paper>
      </TemplateContent>
    </TemplateView>
  );
};
