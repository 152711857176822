import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useAddPayment } from 'rx-contexts';

type IProps = {
  type: 'debit-credit-card' | 'ach' | 'payment-terms';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AddPaymentMethodAutoSubmit = ({ type }: IProps) => {
  const { savePayment, view, onSavePayment } = useAddPayment();
  const { submitForm } = useFormikContext();

  useEffect(() => {
    if (savePayment && type === view) {
      submitForm();
      onSavePayment(false);
    }
  }, [onSavePayment, savePayment, submitForm, type, view]);

  return null;
};

export { AddPaymentMethodAutoSubmit };
