import React from 'react';
import { Divider, List, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { PharmacyProfileMemberItem } from './PharmacyProfileMemberItem';

const Header = styled.header({
  marginBottom: '1rem',
});

interface IPharmacyProfileMemberListProps {
  data: Array<{
    id: number;
    name: string;
    email: string;
    role: string;
    twoFactorEnabled: boolean;
  }>;
  onToggle2FASettings(): void;
}

export const PharmacyProfileMemberList: React.FC<
  IPharmacyProfileMemberListProps
> = (props) => {
  return (
    <>
      <Header>
        <Typography variant="h5">Members</Typography>
      </Header>

      <List dense={true}>
        {props.data.map((member, index) => {
          return (
            <>
              <PharmacyProfileMemberItem
                id={member.id}
                name={member.name}
                role={member.role}
                email={member.email}
                twoFactorEnabled={member.twoFactorEnabled}
                onToggle2FASettings={props.onToggle2FASettings}
              />

              {props.data.length - 1 !== index && (
                <Divider style={{ margin: '1rem 0' }} />
              )}
            </>
          );
        })}
      </List>
    </>
  );
};
