import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    height: 100%;

    ${theme.lessThan('ipadPro')} {
      width: 20%;
    }
  `}
`;

export const SelectType = styled.button`
  ${({ theme }) => css`
    background-color: white;
    margin-top: 1rem;
    height: 2.5rem;
    border-radius: 4px;
    border: 1px solid #9fa6b9;
    font-size: ${theme.sizes.medium};
    padding: 0.5rem;
    outline: none;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `}
`;

export const SelectTypeText = styled.span`
  margin-right: 0.5rem;
`;
