import React from 'react';
import styled from 'styled-components';

import { SettingsNavItem } from '../SettingsNavItem';

type ISettingsNavProps = {
  links: Array<{ text: string; to: string; dataCy: string }>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SettingsNav: React.FC<ISettingsNavProps> = (props: ISettingsNavProps) => {
  return (
    <Container>
      {props.links.map((link) => (
        <SettingsNavItem {...link} key={link.to} data-cy={link.dataCy} />
      ))}
    </Container>
  );
};

export { SettingsNav };
