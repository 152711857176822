import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { green, red } from '@material-ui/core/colors';

export const EdiLogStatus: React.FC<{ status: string }> = (props) => {
  return props.status === 'success' ? (
    <CheckCircleOutlineIcon style={{ color: green[500] }} />
  ) : (
    <HighlightOffIcon style={{ color: red[500] }} />
  );
};
