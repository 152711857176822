import { useMutation } from 'react-query';

import { UpdatePharmacyById } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUpdatePharmacyById = (id: number) => {
  const onRequest = async (request: UpdatePharmacyById.RequestPayloadDto) => {
    return http.put(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/pharmacies/${id}`,
      request
    );
  };

  return useMutation(onRequest, {});
};
