import React from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import {
  useAdminGetManufacturerEdiSettings,
  useAdminUpdateManufacturerEdiSettings,
} from 'rx-api';
import { useToast } from 'rx-hooks';

interface IProps {
  manufacturerId: string | number;
}

export const AdminManufacturerEdiSettings: React.FC<IProps> = (props) => {
  const toast = useToast();
  const response = useAdminGetManufacturerEdiSettings(props.manufacturerId);
  const updateEdiSettings = useAdminUpdateManufacturerEdiSettings(
    props.manufacturerId
  );

  const formik = useFormik({
    initialValues: {
      ediPartnerId: response?.data?.data?.ediPartnerId ?? '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await updateEdiSettings.mutateAsync(values);
        toast.onSuccessToast('EDI Settings save!');
      } catch (err) {
        toast.onErrorToast('Error: could not save EDI Settings');
      }
    },
  });

  return (
    <>
      <Typography variant="h5" style={{ marginBottom: '1rem' }}>
        EDI Settings
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={6}>
            <TextField
              name="ediPartnerId"
              label="EDI Partner ID"
              variant="outlined"
              value={formik.values.ediPartnerId}
              onChange={formik.handleChange}
              error={!!formik.errors.ediPartnerId}
              disabled={formik.isSubmitting}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
