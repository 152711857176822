import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { Formik, Form, FormikHelpers, FormikErrors } from 'formik';
import { Button, Grid } from '@material-ui/core';

import { useToast } from 'rx-hooks';
import { clearPhonenumber } from 'rx-utils';
import { useV3PostSigninPharmacy } from 'rx-api';
import { SignupBuyerSchema } from 'utils/validators/signup/buyer-signup.validator';
import { Alert } from '@material-ui/lab';
import FormikErrorsContext from '../context/FormikErrorsContext';

import { SignupHelpSelect } from '../../signup/molecules/SignupHelpSelect';
import { SignupBuyerPharmacyInfoForm } from './SignupBuyerPharmacyInfoForm';
import { SignupBuyerAdditionalInfoForm } from './SignupBuyerAdditionalInfoForm';
import { SignupBuyerPharmacyShippingInfoForm } from './SignupBuyerPharmacyShippingInfoForm';
import { SignupBuyerPharmacyBillingInfoForm } from './SignupBuyerPharmacyBillingInfoForm';
import { SignupBuyerTradeReferenceForm } from './SignupBuyerTradeReferenceForm';
import { SignupBuyerPasswordSetupForm } from './SignupBuyerPasswordSetupForm';
import { SignupBuyerAboutUsForm } from './SignupBuyerAboutUsForm';
import { SignupFinishUp } from '../../signup/molecules/SignupFinishUp';

const Container = styled.div`
  ${({ theme }) => css`
    max-width: 57.5rem;
    margin: auto;

    ${theme.lessThan('ipad')} {
      width: 100%;
    }
  `}
`;

const ButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    ${theme.lessThan('ipad')} {
      padding: 0 1.5rem;
    }
  `}
`;

interface ISignupBuyerFormState {
  pharmacy: {
    name: string;
    dbaName: string;
    stateLicense: File | undefined;
    stateLicenseNumber: string;
    dea: File | undefined;
    deaNumber: string;
    type: string;
    phoneNumber: string;
    faxNumber: string;
    email: string;
    npi: string;
    yearsBusiness: number;
    taxId: string;
  };
  shippingInfo: {
    address: string;
    state: string;
    city: string;
    zipCode: string;
  };
  billingInfo: {
    address: string;
    state: string;
    city: string;
    zipCode: string;
  };
  additionalInfo: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  tradeReference: {
    primaryWholesaler: string;
    accountOne: string;
    secondaryWholesaler: string;
    accountTwo: string;
  };
  passwordSetup: {
    password: string;
    confirmPassword: string;
  };
  others: {
    howDidYouHearAboutUs: string;
    saleRep: string;
    subscribeToMailingList: boolean;
    acceptTOS: boolean;
    useBillingAddress: boolean;
  };
}

const pdfString =
  'Please upload your DEA File using one of these formats: PDF, PNG, JPEG';
const pdfStateString =
  'Please upload your State License File using one of these formats: PDF, PNG, JPEG';

const isDisabled = (
  activeStep: number,
  errors: FormikErrors<ISignupBuyerFormState>
) => {
  if (activeStep === 0) {
    return (
      errors.passwordSetup !== undefined ||
      errors.additionalInfo?.email !== undefined ||
      errors.additionalInfo?.firstName !== undefined ||
      errors.additionalInfo?.lastName !== undefined ||
      errors.additionalInfo?.phoneNumber !== undefined
    );
  }

  if (activeStep === 1) {
    return errors.pharmacy !== undefined;
  }

  if (activeStep === 2) {
    return (
      errors.billingInfo !== undefined || errors.shippingInfo !== undefined
    );
  }

  if (activeStep === 3) {
    return errors.tradeReference !== undefined || errors.others !== undefined;
  }

  return false;
};

interface ISignupBuyerFormProps {
  activeStep: number;
  onClickBack(): void;
  onClickNext(): void;
  setStepError(step: number | null): void;
}

const SignupBuyerForm: React.FC<ISignupBuyerFormProps> = (props) => {
  const [formikErrors, setFormikErrors] = useState<any | null>(null);

  const initialValues: ISignupBuyerFormState = {
    pharmacy: {
      dea: undefined,
      deaNumber: '',
      stateLicense: undefined,
      stateLicenseNumber: '',
      dbaName: '',
      email: '',
      faxNumber: '',
      name: '',
      npi: '',
      phoneNumber: '',
      type: '1',
      yearsBusiness: 0,
      taxId: '',
    },
    shippingInfo: {
      address: '',
      state: 'AL',
      city: '',
      zipCode: '',
    },
    billingInfo: {
      address: '',
      state: 'AL',
      city: '',
      zipCode: '',
    },
    additionalInfo: {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
    },
    tradeReference: {
      accountOne: '',
      accountTwo: '',
      primaryWholesaler: '',
      secondaryWholesaler: '',
    },
    passwordSetup: {
      confirmPassword: '',
      password: '',
    },
    others: {
      subscribeToMailingList: false,
      acceptTOS: false,
      howDidYouHearAboutUs: '',
      saleRep: '',
      useBillingAddress: false,
    },
  };

  const signupPharma = useV3PostSigninPharmacy();
  const toast = useToast();

  const onSubmit = async (
    values: ISignupBuyerFormState,
    { setErrors }: FormikHelpers<ISignupBuyerFormState>
  ) => {
    if (values.pharmacy.dea === undefined) return;
    if (values.pharmacy.stateLicense === undefined) return;

    try {
      const formData: FormData = new FormData();

      // pharmacyType
      formData.append('email', values.additionalInfo.email);
      formData.append('secondaryEmail', '');
      formData.append('password', values.passwordSetup.password);
      formData.append('firstName', values.additionalInfo.firstName);
      formData.append('lastName', values.additionalInfo.lastName);
      formData.append(
        'pharmacyPhoneNumber',
        clearPhonenumber(values.pharmacy.phoneNumber)
      );
      formData.append(
        'phoneNumber',
        clearPhonenumber(values.additionalInfo.phoneNumber)
      );
      formData.append(
        'howDidYouHearAboutUs',
        values.others.howDidYouHearAboutUs
      );
      formData.append('titleOrPosition', 'CEO');
      formData.append('websiteUrl', '');
      formData.append('saleRep', values.others.saleRep);
      formData.append('address', values.shippingInfo.address);
      formData.append('city', values.shippingInfo.city);
      formData.append('state', values.shippingInfo.state);
      formData.append('zipCode', values.shippingInfo.zipCode);
      formData.append('pharmacyType', values.pharmacy.type);
      formData.append('pharmacyName', values.pharmacy.name);
      formData.append(
        'subscribeToMailingList',
        String(values.others.subscribeToMailingList)
      );
      formData.append('acceptTOS', String(values.others.acceptTOS));

      formData.append('faxNumber', clearPhonenumber(values.pharmacy.faxNumber));
      formData.append('npi', values.pharmacy.npi);
      formData.append('taxId', values.pharmacy.taxId);
      formData.append(
        'yearsInBusiness',
        values.pharmacy.yearsBusiness.toString()
      );
      formData.append(
        'primaryWholesaler',
        values.tradeReference.primaryWholesaler
      );
      formData.append('account1', values.tradeReference.accountOne);
      formData.append(
        'secondaryWholesaler',
        values.tradeReference.secondaryWholesaler
      );
      formData.append('account2', values.tradeReference.accountTwo);
      formData.append('dbaName', values.pharmacy.dbaName);

      formData.append('billingInformationName', values.pharmacy.name);
      formData.append('billingAddress', values.billingInfo.address);
      formData.append('billingAddressState', values.billingInfo.state);
      formData.append('billingAddressCity', values.billingInfo.city);
      formData.append('billingAddressZipCode', values.billingInfo.zipCode);

      formData.append('dea', values.pharmacy.dea, 'dea');
      formData.append('deaNumber', values.pharmacy.deaNumber);
      formData.append('deaTypeFile', values.pharmacy.dea.type);
      formData.append(
        'stateLicense',
        values.pharmacy.stateLicense,
        'stateLicense'
      );
      formData.append('stateTypeFile', values.pharmacy.stateLicense.type);
      formData.append('stateLicenseNumber', values.pharmacy.stateLicenseNumber);

      await signupPharma.mutateAsync(formData);
      props.onClickNext();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      props.setStepError(null);
    } catch (err) {
      toast.onErrorToast('There was a problem with your information');
      if (axios.isAxiosError(err)) {
        if (
          err.response &&
          err.response.data.message === 'Email already registered'
        ) {
          setErrors({
            additionalInfo: {
              email: 'EMAIL_ALREADY_USE',
            },
          });
          props.setStepError(0);

          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      }
    }
  };

  let errorsFormik: any = null;

  useEffect(() => {
    setFormikErrors(errorsFormik);
  }, [errorsFormik]);

  return (
    <Container>
      <FormikErrorsContext.Provider value={formikErrors}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnBlur={true}
          validationSchema={SignupBuyerSchema}
        >
          {({ isSubmitting, errors, values }) => {
            errorsFormik = errors;
            return (
              <Form>
                <>
                  {errors?.pharmacy?.dea === pdfString && (
                    <Alert color="error">{pdfString}</Alert>
                  )}
                  {errors?.pharmacy?.stateLicense === pdfStateString && (
                    <Alert color="error">{pdfStateString}</Alert>
                  )}
                  {props.activeStep === 0 && (
                    <>
                      <SignupBuyerAdditionalInfoForm />
                      <SignupBuyerPasswordSetupForm />
                    </>
                  )}

                  {props.activeStep === 1 && <SignupBuyerPharmacyInfoForm />}

                  {props.activeStep === 2 && (
                    <>
                      <SignupBuyerPharmacyShippingInfoForm />
                      <SignupBuyerPharmacyBillingInfoForm />
                    </>
                  )}

                  {props.activeStep === 3 && (
                    <>
                      <SignupBuyerTradeReferenceForm />
                      <SignupBuyerAboutUsForm />
                    </>
                  )}

                  {props.activeStep === 4 && <SignupFinishUp />}

                  <ButtonContainer>
                    <Grid
                      container
                      spacing={2}
                      style={{
                        justifyContent: 'center',
                        margin: '1rem 0 2rem 0',
                      }}
                    >
                      {props.activeStep > 0 && props.activeStep < 4 && (
                        <Grid item xs={3}>
                          <Button
                            fullWidth
                            color="primary"
                            variant="outlined"
                            onClick={props.onClickBack}
                          >
                            Back
                          </Button>
                        </Grid>
                      )}

                      {props.activeStep < 3 && (
                        <Grid item xs={3}>
                          <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            disabled={isDisabled(props.activeStep, errors)}
                            onClick={props.onClickNext}
                          >
                            Next
                          </Button>
                        </Grid>
                      )}

                      {props.activeStep === 3 && (
                        <Grid item xs={3}>
                          <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={
                              isSubmitting ||
                              !values.others.acceptTOS ||
                              !values.tradeReference.accountOne ||
                              !values.tradeReference.accountTwo ||
                              !values.tradeReference.primaryWholesaler ||
                              !values.tradeReference.secondaryWholesaler ||
                              (values.others.howDidYouHearAboutUs ===
                                'SaleRep' &&
                                values.others.saleRep === '')
                            }
                          >
                            Submit
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </ButtonContainer>
                </>
              </Form>
            );
          }}
        </Formik>
        {props.activeStep !== 4 && <SignupHelpSelect />}
      </FormikErrorsContext.Provider>
    </Container>
  );
};

export { SignupBuyerForm };
