import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
import RxRiseLogo from '../../../../assets/logos/rxrise-gray-logo.svg';

type IProps = {
  text: string;
  background: string;
  handleOnClick?: () => void;
};

const CategoryContent = styled.div`
  width: 100%;
  height: 100%;
`;

const ImageStyled = styled.img`
  width: 100%;
  height: 150px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin: 0;
  padding: 0;
  display: block;
`;

const TextStyled = styled.div`
  color: black;
  text-align: center;
  padding: 7px;
`;

const CategoryContainer = styled(Grid)`
  ${({ theme }) => css`
    width: 230px;
    margin-bottom: 10px;
    border: 1px #b9b9b9 solid;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: scale(1.02);
    }
    ${theme.lessThan('ipadPro')} {
      margin-bottom: 10px;
    }
  `}
`;

const AdminCategoryCard = (props: IProps): JSX.Element => {
  return (
    <CategoryContainer
      onClick={() => props.handleOnClick && props.handleOnClick()}
    >
      <CategoryContent>
        <ImageStyled src={props.background || RxRiseLogo} />
        <TextStyled>
          <p>{props.text}</p>
        </TextStyled>
      </CategoryContent>
    </CategoryContainer>
  );
};

export { AdminCategoryCard };
