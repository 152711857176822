import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usePaginationQueries } from 'rx-hooks/utils';

const items = [
  {
    value: 'all',
    title: 'All Status',
  },
  {
    value: 'shipped',
    title: 'Shipped',
  },
  {
    value: 'pending',
    title: 'Pending',
  },
  {
    value: 'cancelled',
    title: 'Cancelled',
  },
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useMOFilterStatus = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const history = useHistory();
  const { onMakeURL, querySearch, queryMOStatus } = usePaginationQueries();

  const filterType =
    items.find((f) => f.value === queryMOStatus)?.title ?? 'All Status';

  const onOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const menuOptions = items.map((item) => ({
    type: item.value,
    text: item.title,
    onClick: () => {
      onClose();

      const fullUrl = onMakeURL({
        search: querySearch,
        mo: item.value,
      });

      history.push(fullUrl);
    },
  }));

  return {
    onOpenMenu,
    onClose,
    anchorEl,
    menuOptions,
    filterType,
  };
};

export { useMOFilterStatus };
