import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { HeaderLogo } from 'rx-core';

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 32px;
`;

const Title = styled.h1`
  ${({ theme }) => css`
    cursor: pointer;

    position: absolute;
    left: 3.25rem;

    color: ${theme.colors.gray[200]};
    font-family: ${theme.fonts.robotoLight};
    font-size: ${theme.sizes.medium};
    font-weight: 400;

    ${theme.lessThan('mobile')} {
      display: none;
    }
  `}
`;

const HomeLink = styled(Link)`
  ${({ theme }) => css`
    text-decoration: none;
    color: ${theme.colors.gray[500]};
  `}
`;

const SignupBuyerHeader = (): JSX.Element => {
  return (
    <Container>
      <Title color="#cfd3de">
        <HomeLink to="/">Back to Website</HomeLink>
      </Title>
      <HeaderLogo />
    </Container>
  );
};

export { SignupBuyerHeader };
