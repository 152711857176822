import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const Summary = styled(Paper)`
  margin-bottom: 1rem;
  padding: 0 1rem;
`;

export const Footer = styled(Paper)`
  padding: 1rem;
`;
