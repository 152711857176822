import React from 'react';

import { ITransactionPurchaseOrder } from 'rx-domain';

import { TransactionPurchaseOrder } from '../TransactionPurchaseOrder';

type IProps = {
  items: Array<ITransactionPurchaseOrder>;
};

export const TransactionPurchaseOrders: React.FC<IProps> = (props) => {
  return (
    <>
      {props.items.map((item) => (
        <TransactionPurchaseOrder key={item.id} {...item} />
      ))}
    </>
  );
};
