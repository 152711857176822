import React from 'react';
import { Button } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { Formik, Form } from 'formik';

import { percentageMask } from 'rx-utils';
import { RRSelect, RxInputText, RxRadioButton } from 'rx-core';
import { usePutAdminManufacturerIdFees } from 'rx-api';
import { useToast } from 'rx-hooks';
import { CustomFeesSchema } from 'utils/validators/admin/custom-fees.validator';
import { ManufacturerFeesTable } from './ManufacturerFeesTable';

type IProps = {
  accountId: number;
  fee: { percentage: string; ownerId: number };
  feeSettings: {
    id: number;
    ownerId: number;
    invoiceDisplay: 'itemized' | 'breakdown';
    rate: 'flat' | 'compound';
    createdAt: string;
    updatedAt: string;
  };
};

type IForm = {
  percentage: number;
  rate: 'flat' | 'compound';
  invoiceDisplay: 'itemized' | 'breakdown';
};

const Container = styled.div``;

const Title = styled.h2`
  padding-bottom: 1.5rem;
`;

const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    padding-bottom: 1.5rem;
  `}
`;

const Input = styled(RxInputText)`
  width: 11.875rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const FeesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FeeTypeTitle = styled.p`
  ${({ theme }) => css`
    margin-bottom: 1rem;
    font-family: ${theme.fonts.robotoLight};
    font-style: normal;
    font-weight: normal;
    font-size: ${theme.sizes.medium};
    line-height: 25px;
  `}
`;

const RadioButton = styled(RxRadioButton)`
  margin-bottom: 1rem;
`;

const Select = styled(RRSelect)`
  width: 11.875rem;
`;

export const ManufacturerFees: React.FC<IProps> = (props) => {
  const initialData: IForm = {
    percentage: parseFloat(props.fee.percentage),
    rate: props.feeSettings.rate,
    invoiceDisplay: props.feeSettings.invoiceDisplay,
  };

  const { onSuccessToast, onErrorToast } = useToast();

  const updateFee = usePutAdminManufacturerIdFees();

  const onSubmit = async (values: IForm) => {
    try {
      const percentage = parseFloat(
        values.percentage.toString().replace('%', '').trim()
      );

      await updateFee.mutateAsync({
        id: props.accountId,
        percentage,
        invoiceDisplay: values.invoiceDisplay,
        rate: values.rate,
      });

      onSuccessToast('Changes saved succesfully 🚀');
    } catch (err) {
      onErrorToast('Error: could not save fees');
    }
  };

  return (
    <Container>
      <Title>Custom Transaction Fee</Title>
      <Subtitle>
        Use this option if you want to apply a different RxRise fee to this
        manufacturer. Leaving blank will keep the default 6% fee.
      </Subtitle>
      <Formik
        initialValues={initialData}
        onSubmit={onSubmit}
        validationSchema={CustomFeesSchema}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <FeesContainer>
              <Input
                name="percentage"
                label="RxRise Fee"
                withMask
                mask={percentageMask}
              />
              <ManufacturerFeesTable
                type={values.rate}
                percentage={parseFloat(values.percentage.toString())}
              />
            </FeesContainer>
            <div>
              <FeeTypeTitle>Fee Rate Type</FeeTypeTitle>
              <Select
                name="rate"
                label=""
                items={[
                  { name: 'Flat', value: 'flat' },
                  { name: 'Compound', value: 'compound' },
                ]}
              />
            </div>
            <div>
              <FeeTypeTitle>Select how RxRise displays fees</FeeTypeTitle>
              <RadioButton
                name="invoiceDisplay"
                text="Single RxRise fee (Stripe fee and RxRise will be shown together)."
                value="itemized"
                checked={values.invoiceDisplay === 'itemized'}
              />
              <RadioButton
                name="invoiceDisplay"
                text="Broken down fees (Stripe fee and RxRise will be shown separate)."
                value="breakdown"
                checked={values.invoiceDisplay === 'breakdown'}
              />
            </div>

            <ButtonContainer>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                Save Changes
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
