import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';
import { motion } from 'framer-motion';

import { Colors } from 'rx-styles';

import { ButtonText } from '../ButtonText';
import { ButtonBase } from '../ButtonBase';

export const Container = styled.div<LayoutProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  ${layout};
`;

export const Text = styled(ButtonText)<{
  textColor?: Colors;
  fontSize?: string;
}>`
  ${({ textColor, fontSize }) => css`
    left: initial;
    position: initial;
    color: ${textColor ?? 'black'};
    font-size: ${fontSize};
  `}
`;

export const Button = styled(ButtonBase)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingContainer = styled(motion.figure)`
  height: fit-content;
  width: fit-content;
`;
