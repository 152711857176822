import styled, { css } from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const Container = styled.div`
  padding: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingIcon = styled(CircularProgress)`
  ${({ theme }) => css`
    color: ${theme.colors.blue[100]} !important;
  `}
`;
