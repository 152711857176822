import { ADMIN_URL_V1, ADMIN_URL_V3 } from '../constants';

export const ADMIN_UPLOAD_DRUGS_URL = `${ADMIN_URL_V3}/manufacturer/{id}/inventory`;

export const GET_ADMIN_SELLER_EMAIL_RECIPIENTS = `${ADMIN_URL_V1}/manufacturers/{id}/email-recipients`;
export const GET_ADMIN_SELLER_EMAIL_RECIPIENTS_KEY = `GET_ADMIN_SELLER_EMAIL_RECIPIENTS_KEY`;

export const POST_ADMIN_SELLER_EMAIL_RECIPIENTS = `${ADMIN_URL_V1}/manufacturers/{id}/email-recipients`;

export const DELETE_ADMIN_SELLER_EMAIL_RECIPIENTS = `${ADMIN_URL_V1}/manufacturers/{manufacturerId}/email-recipients/{recipientId}`;

export const GET_SELLER_KEY = 'GET_SELLER_KEY';
export const GET_SELLER_URL = `${ADMIN_URL_V1}/manufacturers`;
