import React from 'react';
import { Grid, Tabs } from '@material-ui/core';

import { SEO, TemplateView } from 'rx-core';

import { DashboardTitle } from './DashboardTitle';
import { DashboardLoginLogs } from './DashboardLoginLogs';
import { DashboardMetrics } from './DashboardMetrics';
import { DashboardPendingTasks } from './DashboardPendingTasks';
import { DashboardTopProducts } from './DashboardTopProducts';
import { Container, Tab } from './index.styles';

const AdminDashboard = (): JSX.Element => {
  const [tab, setTab] = React.useState('logs');

  return (
    <TemplateView>
      <SEO title="RxRise | Admin" />
      <Container>
        <DashboardTitle />
        <DashboardMetrics />
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <DashboardPendingTasks />
          </Grid>
          <Grid item xs={9} style={{ marginTop: '1rem' }}>
            <Tabs>
              <Tab
                onClick={() => setTab('logs')}
                aria-active={tab === 'logs' ? 'true' : 'false'}
              >
                Login Logs
              </Tab>
              <Tab
                onClick={() => setTab('products')}
                aria-active={tab === 'products' ? 'true' : 'false'}
              >
                Top Products Sold
              </Tab>
            </Tabs>

            {tab === 'logs' && <DashboardLoginLogs />}
            {tab === 'products' && <DashboardTopProducts />}
          </Grid>
        </Grid>
      </Container>
    </TemplateView>
  );
};

export { AdminDashboard };
