import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    error: {
      main: '#FF3360',
    },
    primary: {
      main: '#5A88FF',
    },
  },
});
