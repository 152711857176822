import {
  convertUrlWithQuery,
  createUrlWithParams,
  useGetRequest,
} from 'rx-api/utils';
import { GetProducts, PostSaleOrderInvoice } from 'rx-domain';
import { URL_V1 } from '../../../constants';
import { http } from '../../../../http/repository';
import { useMutation } from 'react-query';

const makeParams = (req: Partial<GetProducts.QueryByActiveIngredient>) => {
  let query = `?limit=10`;

  if (req.limit) {
    query = `?limit=${req.limit}`;
  }

  if (req.offset) {
    query += `&offset=${req.offset}`;
  }

  if (req.activeIngredient) {
    query += `&activeIngredient=${req.activeIngredient}`;
  }

  if (req.form) {
    query += `&form=${req.form}`;
  }

  if (req.strength) {
    query += `&strength=${req.strength}`;
  }

  return query;
};

// const useGetProductsByActiveIngredient1 = (
//   query: Partial<GetProducts.QueryByActiveIngredient>
// ) => {
//   return useGetRequest<
//     Partial<GetProducts.QueryByActiveIngredient>,
//     GetProducts.Response
//   >({
//     url: `${URL_V2}/products-by-active-ingredient`,
//     key: [
//       `${URL_V2}/products-by-active-ingredient`,
//       query.activeIngredient,
//       query.limit,
//       query.offset,
//       query.form,
//       query.strength,
//     ],
//     params: query,
//     makeParams,
//   });
// };

export const useGetProductsByActiveIngredient = () => {
  const onRequest = (params: Partial<GetProducts.QueryByActiveIngredient>) => {
    return http.get(
      convertUrlWithQuery(
        {
          displayName: params.activeIngredient || '',
          limit: params.limit ?? 10,
          skip: params.offset || 0,
          form: params.form || '',
          strength: params.strength || '',
        },
        `${URL_V1}/products`
      ),
      {
        // responseType: 'arraybuffer',
      }
    );
  };

  return useMutation(onRequest, {});
};
