import React from 'react';
import styled from 'styled-components';

import { SEO } from 'rx-core';

import { useAuth } from 'rx-hooks';

import { AdminDashboard } from 'pages/admin/dashboard';
import { InventoryView } from 'pages/seller';
import { HomeView } from 'pages/buyer/home';
import HomeViewV2 from '../../../buyer/homeV2';

const Container = styled.div``;

const DashboardView: React.FC = () => {
  const { type } = useAuth();

  return (
    <Container data-cy="dashboard-container">
      <SEO title="RxRise | Dashboard" />
      {type === 'admin' && <AdminDashboard />}
      {type === 'manufacturer' && <InventoryView />}
      {type === 'pharmacist' &&
        process.env.REACT_APP_IS_NEW_HOMEPAGE_ENABLED !== 'true' && (
          <HomeView />
        )}
      {type === 'pharmacist' &&
        process.env.REACT_APP_IS_NEW_HOMEPAGE_ENABLED === 'true' && (
          <HomeViewV2 />
        )}
    </Container>
  );
};

export { DashboardView };
