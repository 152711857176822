import React from 'react';
import styled from 'styled-components';

import { HeaderLogo } from 'rx-core';

const Container = styled.div`
  position: absolute;
  top: 24px;
  width: 100%;
  padding: 0 34px;
`;

const LoginHeader: React.FC = () => {
  return (
    <Container>
      <HeaderLogo />
    </Container>
  );
};

export { LoginHeader };
