import React, { useState } from 'react';
import { Form, Formik } from 'formik';

import { RRModalProps } from 'rx-core';
import { GetSaleOrderPayments } from 'rx-domain';
import { useDownloadSaleOrderPayments, useGetSellers } from 'rx-api';
import { useDownloadFile, useReportQueryString } from 'rx-hooks';
import { getDateValue } from 'rx-utils';
import { CircularProgress, Box } from '@material-ui/core';
import { useToast } from 'rx-hooks';

import { ReportDownloadSchema } from 'utils/validators/admin/report-download.validator';

import {
  Modal,
  Container,
  RadioInput,
  ActionsContainer,
  AcceptBtn,
  CancelBtn,
  Subtitle,
  Title,
} from './index.styles';

type IProps = RRModalProps & {
  totalItems: number;
  skip: number;
};

type IForm = {
  criteria: string;
};

export const ReportDownloadPicker: React.FC<IProps> = (props) => {
  const initialValues: IForm = { criteria: 'current_page' };

  const { onDownload } = useDownloadFile();
  const { onSuccessToast, onErrorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { seller, sortBy, sortOrder, status, skip } = useReportQueryString();

  const downloadOrdersMutation = useDownloadSaleOrderPayments();

  const { data: adminManufacturers } = useGetSellers({
    approvalStatus: 'approved',
    shipper: seller,
    skip: 0,
    limit: 100,
  });

  const rest = adminManufacturers?.data?.results.find(
    (record) => record.manufacturer.companyName === seller
  );

  const onSubmit = async (values: IForm) => {
    let query: Partial<GetSaleOrderPayments.Query> = {};

    if (!props.totalItems) return;

    try {
      if (values.criteria === 'current_page') {
        query = {
          limit: 10,
          skip: props.skip,
          sellerId: rest ? rest.id : '',
          sortBy,
          sortOrder,
          status,
        };
      }

      if (values.criteria === 'all_payments') {
        query = {
          limit: 10000000,
        };
      }

      if (values.criteria === 'all_filtered_criteria') {
        query = {
          limit: 10000000,
          sellerId: rest ? rest.id : '',
          sortBy,
          sortOrder,
          status,
        };
      }

      const { data } = await downloadOrdersMutation.mutateAsync(query);

      onDownload(
        data,
        `payment-history-reports-${values.criteria}-${getDateValue(
          new Date().toUTCString(),
          true
        )}`
      );
      onSuccessToast('Download completed 🚀');
    } catch (err) {
      onErrorToast('There was an error trying to download your report');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal {...props}>
      {isLoading ? (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress size="6rem" />
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ReportDownloadSchema}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <Container>
                <Title>Download Options</Title>
                <Subtitle>
                  Please choose what would you like to download.
                </Subtitle>
                <RadioInput
                  name="criteria"
                  value="current_page"
                  text="Current Page"
                />
                <RadioInput
                  name="criteria"
                  value="all_payments"
                  text="All payments"
                />
                <RadioInput
                  name="criteria"
                  value="all_filtered_criteria"
                  text="All filtered criteria"
                />
                <ActionsContainer>
                  <CancelBtn
                    type="button"
                    onClick={() => props.onClose()}
                    variant="outline"
                  >
                    Cancel
                  </CancelBtn>
                  <AcceptBtn
                    type="submit"
                    disabled={!isValid}
                    loading={downloadOrdersMutation.isLoading || isSubmitting}
                  >
                    Download CSV
                  </AcceptBtn>
                </ActionsContainer>
              </Container>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
