import React from 'react';

import { MOFilterStatus } from '../MOFilterStatus';
import { MOSearch } from '../MOSearch';

import { Container } from './index.styles';

const MOFilter = (): JSX.Element => {
  return (
    <Container>
      <MOSearch />
      <MOFilterStatus />
    </Container>
  );
};

export { MOFilter };
