import React from 'react';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { Button, Divider, Grid, TextField } from '@material-ui/core';

import { IGetSellerEmailRecipients } from 'rx-domain';

import {
  useAdminDeleteManufacturerEmailRecipient,
  usePostSellerEmailRecipients,
} from 'rx-api';

import { EmailReceiptSchema } from 'validations';

import { useToast } from 'rx-hooks';
import { useQueryClient } from 'react-query';
import { GET_ADMIN_SELLER_EMAIL_RECIPIENTS_KEY } from 'rx-api/api/admin/manufacturers/constants';
import { AdminSellerCardTitle } from '../AdminSellerCardTitle';
import { AdminSellerCardSubTitle } from '../AdminSellerCardSubtitle';

type IProps = {
  emails: IGetSellerEmailRecipients.Response;
};

type IForm = {
  email: string;
};

export const SellerEmailReceipt: React.FC<IProps> = (props) => {
  const initialValues: IForm = {
    email: '',
  };

  const toast = useToast();
  const query = useParams<{ id: string }>();

  const addEmailRecepients = usePostSellerEmailRecipients({
    id: query.id,
  });
  const deleteEmailRecipient = useAdminDeleteManufacturerEmailRecipient(
    query.id
  );
  const queryClient = useQueryClient();

  const onSubmit = async (values: IForm) => {
    try {
      await addEmailRecepients.mutateAsync({ email: values.email });
      await queryClient.refetchQueries(GET_ADMIN_SELLER_EMAIL_RECIPIENTS_KEY, {
        active: true,
      });

      toast.onSuccessToast('Email recipient saved successfully ✅');
    } catch (error) {
      toast.onErrorToast((error as AxiosError)?.response?.data.message);
    }
  };

  const handleClickDeleteEmail = (id: number) => async () => {
    try {
      await deleteEmailRecipient.mutateAsync(id);
      await queryClient.refetchQueries(GET_ADMIN_SELLER_EMAIL_RECIPIENTS_KEY, {
        active: true,
      });
      toast.onSuccessToast('Email recipient removed successfully ✅');
    } catch (error) {
      toast.onErrorToast((error as AxiosError)?.response?.data.message);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: EmailReceiptSchema,
    validate: (values) => {
      if (props.emails.some((email) => email.email === values.email)) {
        return { email: 'Duplicated email recipient!' };
      }

      return {};
    },
  });

  return (
    <div>
      <AdminSellerCardTitle>Email Recepients</AdminSellerCardTitle>
      <AdminSellerCardSubTitle>
        Email recepients are allowed to receive orders emails. This allows more
        people in your organization be aware of the transactions done.
      </AdminSellerCardSubTitle>

      {props.emails.map((email) => {
        return (
          <Grid key={email.id} container spacing={2}>
            <Grid item xs={4}>
              <TextField
                value={email.email}
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={2}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleClickDeleteEmail(email.id)}
                disabled={deleteEmailRecipient.isLoading}
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        );
      })}

      {props.emails.length < 3 && (
        <>
          <Divider style={{ margin: '1rem 0' }} />

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  id="email"
                  name="email"
                  type="email"
                  variant="outlined"
                  placeholder="email@example.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={!!formik.errors.email}
                  helperText={formik.errors.email}
                  fullWidth
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: '1.5rem' }}>
              <Button
                variant="contained"
                color="primary"
                disabled={addEmailRecepients.isLoading}
                type="submit"
              >
                Save
              </Button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};
