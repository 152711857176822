import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getDateValue } from 'rx-utils';

import { setCartItem, setCleanShoppingCart } from 'rx-store';
import { useGetShoppingCart } from 'rx-api';

import { useAuth } from 'rx-hooks/utils/useAuth';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSyncShoppingCart = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type } = useAuth();
  const { data: shoppingCartAPI, ...shoppingCartOpts } =
    useGetShoppingCart(type);
  const dispatch = useDispatch();

  useEffect(() => {
    if (shoppingCartAPI?.data && !shoppingCartOpts.isPreviousData) {
      if (shoppingCartAPI.data.items.length === 0) {
        dispatch(setCleanShoppingCart());
        return;
      }
      for (let i = 0; i < shoppingCartAPI.data.items.length; i += 1) {
        const { product, quantity, shoppingCartId, id } =
          shoppingCartAPI.data.items[i];

        dispatch(
          setCartItem({
            product: {
              ...product,
              expirationDate: getDateValue(product.expirationDate),
            },
            id,
            shoppingCartId,
            count: quantity,
          })
        );
      }
    }
  }, [dispatch, shoppingCartAPI, shoppingCartOpts.isPreviousData]);

  return {
    ...shoppingCartOpts,
  };
};

export { useSyncShoppingCart };
