import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

import { FilterItemMaterial } from 'rx-domain';

import { RxFilterMaterialItem } from '../RxFilterMaterialItem';

export type RxFilterMateriaListProps<T> = LayoutProps & {
  onClick: (value: T, title: string) => void;
  items: FilterItemMaterial<T>[];
};

const Container = styled.ul<LayoutProps>`
  ${({ theme }) => css`
    max-width: 300px;
    width: 300px;
    height: auto;

    background: ${theme.colors.black[100]};
    color: ${theme.colors.white[100]};
    box-shadow: 5px 5px 25px rgb(51 60 68 / 8%);
    padding: 8px 16px 0 16px;
    z-index: 3;
    border-radius: 0 0 24px 24px;

    position: absolute;
    top: 50px;
    ${layout};
  `}
`;

const RxFilterMateriaList = <T extends string>({
  onClick,
  items,
  ...props
}: RxFilterMateriaListProps<T>): JSX.Element => {
  const [currentSelected, setCurrentSelected] = useState('');

  const onClickHandle = (item: FilterItemMaterial<T>) => {
    onClick(item.value, item.title);
    setCurrentSelected(item.value);
  };

  return (
    <Container {...props}>
      {items.map((item) => (
        <RxFilterMaterialItem
          {...item}
          key={item.value}
          onClick={() => onClickHandle(item)}
          selected={currentSelected === item.value}
        />
      ))}
    </Container>
  );
};

export { RxFilterMateriaList };
