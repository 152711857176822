import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useQuery, useToast } from 'rx-hooks';
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { theme } from 'rx-styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { GetProducts } from 'rx-domain';
import {
  formatDate,
  formatMoney,
  formatQuantity,
  getDateValue,
} from 'rx-utils';
import RxRiseRecommendedIcon from 'assets/icons/rxrise-icon.svg';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FeaturedIcon, FeaturedLabel } from './ProductItem';

const FeaturedContainer = styled.div({
  backgroundColor: '#ffeb94',
  alignItems: 'center',
  display: 'flex',
  textAlign: 'center',
  position: 'absolute',
  left: 0,
  top: 0,
  padding: '0 0.25rem 0 0',
});

const useStyles = makeStyles(() =>
  createStyles({
    selectedTop: {
      borderTop: '2px solid #ffe74f',
      borderRight: '2px solid #ffe74f',
      borderLeft: '2px solid #ffe74f',
    },
    selectedBottom: {
      borderBottom: '2px solid #ffe74f',
      borderRight: '2px solid #ffe74f',
      borderLeft: '2px solid #ffe74f',
    },
  })
);

interface IProductListItemProps {
  data: GetProducts.ProductItem;
  onClickAdd: (productId: GetProducts.ProductItem, units: number) => () => void;
}

export const ProductListItem: React.FC<IProductListItemProps> = (props) => {
  const { onErrorToast } = useToast();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [units, setUnits] = React.useState(0);
  const notEnoughQuantity =
    units < props.data.minimumQuantity || Number.isNaN(units);
  const stockIsExceeded = units > props.data.units;
  const maxOrderIsExceeded = Boolean(
    props.data.maximumQuantity && units > props.data.maximumQuantity
  );
  const query = useQuery();
  const category = query.get('category');
  const isDrug = category === 'drug' || !category;

  useEffect(() => {
    setUnits(props.data.minimumQuantity);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (units < props.data.minimumQuantity) {
        onErrorToast(
          `You need to add ${props.data.minimumQuantity} as minimum quantity`
        );
      } else if (units > props.data.units && props.data.units > 0) {
        onErrorToast(
          `You have exceeded the stock of ${props.data.units} products`
        );
      } else if (
        props.data.maximumQuantity &&
        units > props.data.maximumQuantity
      ) {
        onErrorToast(
          `You have exceeded the maximum quantity of ${props.data.maximumQuantity} products`,
          8000
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units, isLoading]);

  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnits(parseInt(event.target.value, 10));
  };

  return (
    <>
      <TableRow
        className={`${open && classes.selectedTop}`}
        style={{
          backgroundColor: props.data.isFeatured === true ? '#FFFDF2' : '',
          fontSize: '0.8rem',
        }}
      >
        <TableCell
          style={{
            position: 'relative',
            fontSize: '0.8rem',
            padding: '8px',
            paddingTop: props.data.isFeatured ? '23px' : '',
          }}
        >
          {props.data.isFeatured && (
            <FeaturedContainer>
              <FeaturedIcon src={RxRiseRecommendedIcon} alt="" />
              <FeaturedLabel>Recommends</FeaturedLabel>
            </FeaturedContainer>
          )}

          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell
          style={{
            fontSize: '0.8rem',
            padding: '8px',
            paddingTop: props.data.isFeatured ? '23px' : '',
          }}
        >
          {props.data.ndc}
        </TableCell>
        <TableCell>
          <Link
            style={{ color: theme.colors.blue[100] }}
            to={`/product/${props.data.id}`}
          >
            {props.data.name}
          </Link>
        </TableCell>
        <TableCell style={{ fontSize: '0.8rem', padding: '16px' }}>
          {props.data.manufacturer}
        </TableCell>
        <TableCell style={{ fontSize: '0.8rem', padding: '16px' }}>
          {props.data.strengthValue}
        </TableCell>
        <TableCell style={{ fontSize: '0.8rem', padding: '16px' }}>
          {formatMoney(props.data.price)}
        </TableCell>
        <TableCell style={{ fontSize: '0.8rem', padding: '16px' }}>
          {getDateValue(props.data.expirationDate)}
        </TableCell>
        <TableCell style={{ fontSize: '0.8rem', padding: '16px' }}>
          {formatQuantity(props.data.units)}
        </TableCell>
        <TableCell style={{ fontSize: '0.8rem', padding: '16px' }}>
          {props.data.minimumQuantity}
        </TableCell>
        {isDrug && (
          <TableCell style={{ fontSize: '0.8rem', padding: '16px' }}>
            {props.data.isVawd ? 'Yes' : 'No'}
          </TableCell>
        )}

        <TableCell style={{ fontSize: '0.8rem', padding: '16px' }} colSpan={3}>
          {props.data.units > 0 ? (
            <Grid container alignItems="center">
              <Grid item xs={8}>
                <TextField
                  error={
                    notEnoughQuantity || stockIsExceeded || maxOrderIsExceeded
                  }
                  type="number"
                  variant="outlined"
                  margin="dense"
                  value={units}
                  onChange={handleChangeQuantity}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  style={{ minWidth: 'unset', padding: '6px 6px' }}
                  variant="contained"
                  color="primary"
                  disabled={
                    notEnoughQuantity || stockIsExceeded || maxOrderIsExceeded
                  }
                  onClick={props.onClickAdd(props.data, units)}
                >
                  <AddShoppingCartIcon />
                </Button>
              </Grid>
            </Grid>
          ) : (
            <p>Out of Stock</p>
          )}
        </TableCell>
      </TableRow>

      <TableRow className={`${open && classes.selectedBottom}`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {props.data.description !== 'N/A' && (
                      <TableCell>Description</TableCell>
                    )}
                    <TableCell>Lot Number</TableCell>
                    <TableCell>Manufacturer</TableCell>
                    <TableCell>Ground Shipping</TableCell>
                    <TableCell>Overnight Shipping</TableCell>
                    <TableCell>Shipping Constraints</TableCell>
                    <TableCell>Shipping Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {props.data.description !== 'N/A' && (
                      <TableCell>{props.data.description}</TableCell>
                    )}
                    <TableCell>{props.data.lotNumber}</TableCell>
                    <TableCell>{props.data.manufacturer}</TableCell>
                    <TableCell>
                      {formatMoney(props.data.groundShipping)}
                    </TableCell>
                    <TableCell>
                      {formatMoney(props.data.overnightShipping)}
                    </TableCell>
                    <TableCell>{props.data.shippingConstraints}</TableCell>
                    <TableCell>{props.data.shippingName}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
