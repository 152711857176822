import React from 'react';
import styled from 'styled-components';
import {
  typography,
  space,
  layout,
  LayoutProps,
  SpaceProps,
  TypographyProps,
} from 'styled-system';

type FooterNewsletterSubtitleProps = LayoutProps &
  SpaceProps &
  TypographyProps & {
    children: React.ReactNode;
  };

const Text = styled.h2`
  color: white;
  font-family: Roobert Light;
  font-weight: 500;
  ${typography}
  ${space}
  ${layout}
`;

const FooterNewsletterSubtitle = ({
  children,
  ...props
}: FooterNewsletterSubtitleProps): JSX.Element => {
  return <Text {...props}>{children}</Text>;
};

export { FooterNewsletterSubtitle };
