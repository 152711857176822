import React from 'react';
import styled, { css } from 'styled-components';

type VerificationFormSubtitleProps = {
  className?: string;
  email?: string;
};

const Text = styled.h2`
  font-family: ${({ theme }) => theme.fonts.robotoLight};
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.black[100]};
  padding: 40px 0 32px 0;

  ${({ theme: { lessThan, sizes } }) => css`
    ${lessThan('ipadPro')} {
      font-size: ${sizes.small};
      padding: 24px 0 32px 0;
    }
  `}
`;

const StrongText = styled.span`
  font-family: ${({ theme }) => theme.fonts.robotoRegular};
`;

const VerificationFormSubtitle = ({
  email,
  ...props
}: VerificationFormSubtitleProps): JSX.Element => {
  return (
    <Text {...props}>
      RxRise just sent an email with a verification code to{' '}
      <StrongText>{email}</StrongText>
    </Text>
  );
};

VerificationFormSubtitle.defaultProps = {
  className: '',
  email: '',
};

export { VerificationFormSubtitle };
