import styled, { css } from 'styled-components';
import { RRModal, RxButtonSimple, RxInputText } from 'rx-core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
`;

export const Modal = styled(RRModal)`
  height: fit-content;
  max-width: 32rem;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    font-family: ${theme.fonts.robotoRegular};
    font-size: ${theme.sizes.h2};
    border-bottom: 1px solid rgb(43, 54, 71, 0.35);
  `}
`;

export const SmallInput = styled(RxInputText)`
  width: 48%;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const CancelButton = styled(RxButtonSimple)`
  margin-right: 1rem;
`;

export const AcceptButton = styled(RxButtonSimple)`
  width: 10rem;
`;
