import styled, { css } from 'styled-components';

export const Title = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoRegular};
    font-size: ${theme.sizes.h2};
    font-weight: 400;
    margin-bottom: 1.5rem;
  `}
`;
