import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAdminUpdateAccountPassword = (id: string | number) => {
  return useMutation(
    ['admin-update-account-password', id],
    async (request: { newPassword: string; confirmPassword: string }) => {
      return http.put(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/accounts/${id}/password`,
        request
      );
    },
    {}
  );
};
