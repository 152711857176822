import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
`;

const Title = styled.h2`
  font-size: ${({ theme: { sizes } }) => `${sizes.h2}`};
  font-family: ${({ theme: { fonts } }) => `${fonts.robotoLight}`};
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(43, 54, 71, 0.35);
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.sizes.medium};
  font-family: ${({ theme }) => theme.fonts.robotoLight};
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const CsvUploadSuccess: React.FC = () => {
  return (
    <Container>
      <Title>Upload is successful! </Title>
      <Text>The products are now available on Rxrise.com!</Text>
    </Container>
  );
};
