import styled, { css } from 'styled-components';

export const Container = styled.div`
  box-shadow: 5px 5px 25px rgba(51, 60, 68, 0.08);
`;

export const Content = styled.div`
  ${({ theme }) => css`
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1240px;
    margin: 0 auto;

    ${theme.lessThan('ipadPro')} {
      padding: 0 0 0 1.5rem;
    }
  `}
`;

export const UserContainer = styled.nav`
  flex: 1;
  height: 100%;
  padding-left: 1rem;
`;
