import { useEffect, useState } from 'react';

import { useGetPharmacyList } from 'rx-api';

import { usePaginationQueries } from 'rx-hooks/utils';

type BuyerPick = { title: string; value: number };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useReportFilterBuyer = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<BuyerPick | null>(null);
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<BuyerPick[]>([]);

  const { queryBuyer } = usePaginationQueries();

  const { data: adminBuyers, isLoading } = useGetPharmacyList({
    search,
    skip: 0,
    limit: 100,
  });

  useEffect(() => {
    if (adminBuyers?.data) {
      setOptions(
        adminBuyers.data.results.map((result) => ({
          title: result.pharmacyName,
          value: result.id,
        }))
      );
    }
  }, [adminBuyers]);

  useEffect(() => {
    if (queryBuyer) setValue(null);
  }, [queryBuyer]);

  return {
    open,
    setOpen,
    value,
    setValue,
    search,
    setSearch,
    options,
    setOptions,
    isLoading,
  };
};

export { useReportFilterBuyer };
