import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = styled(Grid)`
  padding: 1rem;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-weight: 400;
    padding-bottom: 1rem;
  `}
`;

export const Subtitle = styled.p<{ bold?: boolean }>`
  ${({ theme, bold }) => css`
    font-size: ${theme.sizes.medium};
    font-weight: ${bold ? 'bold' : 400};
    padding-bottom: ${bold ? '0.5rem' : '1rem'};
  `}
`;
