import React, { useMemo, useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useAdminGetPharmacists } from 'rx-api';
import { RxPagination } from 'rx-core';
import { useSkipPage } from 'rx-hooks';

import { ApproveAccountCard } from '../../molecules/ApproveAccountCard';

export const ApprovePharmacist = (): JSX.Element => {
  const [length, setLength] = useState(0);

  const { skip, totalPages } = useSkipPage(length);

  const { data: buyersData, isLoading } = useAdminGetPharmacists({
    skip,
  });

  const totalPharmacies = useMemo(() => {
    if (buyersData?.data) return buyersData?.data.pagination.total;

    return 0;
  }, [buyersData]);

  useEffect(() => {
    if (buyersData?.data && !isLoading) {
      setLength(buyersData.data.pagination.total);
    }
  }, [buyersData, isLoading]);

  return (
    <div style={{ marginBottom: '2rem' }}>
      <header style={{ marginBottom: '0.2rem' }}>
        <Typography variant="h5">New Buyer Sign Up</Typography>
        <Typography variant="h6">
          You have {totalPharmacies} requests to approve:
        </Typography>
      </header>

      <Grid spacing={4} container>
        {buyersData?.data &&
          buyersData.data.results.map((buyer) => (
            <Grid xs={4} key={buyer.id} item>
              <ApproveAccountCard
                title="Pharmacy"
                account={{
                  id: buyer.id,
                  firstName: buyer.firstName,
                  lastName: buyer.lastName,
                  phoneNumber: buyer.phoneNumber,
                  titleOrPosition: buyer.titleOrPosition,
                  companyName: buyer.pharmacy.pharmacyName,
                  accountFiles: buyer.accountFiles,
                  address: buyer.billingInformation?.billingAddress,
                }}
              />
            </Grid>
          ))}
      </Grid>

      <div>
        {totalPages > 1 && (
          <RxPagination
            path="approve"
            initialParams="type=pharmacist&"
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
};
