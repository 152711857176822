import { AdminUploadDrugsBulk } from 'rx-domain';
import { ADMIN_UPLOAD_DRUGS_URL } from 'rx-api/api/admin/manufacturers/constants';
import { useRxMutation } from 'rx-api/utils';

const useAdminUploadDrugsBulk = (manufacturerId: number) => {
  return useRxMutation<AdminUploadDrugsBulk.DTO, unknown>({
    url: ADMIN_UPLOAD_DRUGS_URL.replace('{id}', manufacturerId.toString()),
    method: 'post',
  });
};

export { useAdminUploadDrugsBulk };
