import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid rgba(131, 140, 165, 0.35);
  display: flex;
  flex-direction: row;
  padding: 0.25rem 0.75rem 0.75rem 0.75rem;
  width: 100%;
`;
