import React from 'react';
import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

import FormBackgroundImg from 'assets/images/backgrounds/form-login-singup-background.png';

import { LoginCard } from '../atoms';

type LoginFormTemplateProps = LayoutProps & {
  children: React.ReactNode;
};

const Container = styled.div<LayoutProps>`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipad')} {
      width: 90%;
      margin: auto;
    }

    ${lessThan('ipadPro')} {
      width: 95%;
      margin: auto;
    }
  `}
  ${layout};
`;

const FormBackground = styled.div`
  width: 452px;
  height: auto;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipad')} {
      width: 50%;
    }

    ${lessThan('ipadPro')} {
      display: none;
    }
  `}

  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${FormBackgroundImg});
`;

const FormColumn = styled.div`
  width: 468px;
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipad')} {
      width: 50%;
    }

    ${lessThan('ipadPro')} {
      width: 100%;
    }
  `}
`;

const LoginFormTemplate = ({
  children,
  height,
  ...props
}: LoginFormTemplateProps): JSX.Element => {
  return (
    <Container {...props} data-cy="login-form">
      <LoginCard height={height}>
        <FormBackground />
        <FormColumn>{children}</FormColumn>
      </LoginCard>
    </Container>
  );
};

export { LoginFormTemplate };
