import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
  `}
`;

export const Subtitle = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    color: #707070;
    margin-top: 0.5rem;
    font-weight: 400;
  `}
`;

export const FilterContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
`;
