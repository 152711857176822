import React from 'react';
import styled from 'styled-components';
import { flexbox, FlexboxProps } from 'styled-system';

import { FooterBottomText } from '../atoms';

const Container = styled.div<FlexboxProps>`
  display: flex;
  max-width: 1240px;
  margin: 0 auto;
  ${flexbox}
`;

const TextContainer = styled.div<FlexboxProps>`
  display: flex;
  flex-direction: row;
  ${flexbox}
`;

export const FooterBottom: React.FC = () => {
  return (
    <Container
      flexDirection={['column', 'column', 'row', 'row', 'row', 'row', 'row']}
    >
      <FooterBottomText
        pt={['16px', '16px', 0, 0]}
        position={['inherit', 'inherit', 'absolute']}
        bottom={[0, 0, 26, 26]}
        left={[null, null, null, null, 44]}
      >
        <TextContainer
          flexDirection={[
            'column-reverse',
            'column-reverse',
            'column-reverse',
            'column-reverse',
            'row',
          ]}
        ></TextContainer>
      </FooterBottomText>
    </Container>
  );
};

FooterBottom.defaultProps = {
  onClickPrivacy: () => {},
};
