import { Dialog, IconButton } from '@material-ui/core';
import styled from 'styled-components';

import { HighlightOff } from '@material-ui/icons';

export interface IProps {
  open: boolean;
  onClose: () => void;
  image: string;
  url: string;
}

const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
`;

const LinkContainer = styled.a`
  line-height: 0;
`;

export const AdModal = (props: IProps) => {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <IconButtonStyled aria-label="close" onClick={onClose}>
        <HighlightOff style={{ color: 'white' }} />
      </IconButtonStyled>
      <LinkContainer href={props.url} target="_blank">
        <img src={props.image} />
      </LinkContainer>
    </Dialog>
  );
};
