import React from 'react';
import styled, { css } from 'styled-components';

export type RxInputMessageProps = {
  error: boolean;
};

const Text = styled.span<RxInputMessageProps>`
  ${({ theme: { sizes, colors, fonts }, error }) => css`
    font-family: ${fonts.robotoLight};
    font-size: ${sizes.small};
    color: ${colors.gray[500]};
    margin-bottom: 20px;
    margin-top: 4px;

    ${error && `margin-top: 0px`};
  `}
`;

const RxInputMessage: React.FC<RxInputMessageProps> = ({
  children,
  ...props
}): JSX.Element => {
  return <Text {...props}>{children}</Text>;
};

export { RxInputMessage };
