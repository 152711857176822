import { useGetRequest } from 'rx-api/utils';

type CategoryItemDto = {
  id: number;
  text: string;
  slug: string;
  pictureUrl: string;
  productCount: number;
  createdAt: string;
  updatedAt: string;
};

export const useGetCategoriesList = () => {
  const { isLoading, data, ...rest } = useGetRequest<
    unknown,
    {
      data: CategoryItemDto[];
    }
  >({
    key: 'get-categories-list',
    url: `${process.env.REACT_APP_WEBSITE_URL}/v1/products/categories`,
  });

  if (isLoading) {
    return { ...rest, data: null, isLoading };
  }

  return {
    ...rest,
    isLoading,
    data: data?.data?.data ?? null,
  };
};
