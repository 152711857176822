import styled from 'styled-components';
import React from 'react';

export interface IProps {
  image: string | undefined;
  url: string | undefined;
}

const StyledImg = styled.img`
  width: 100%;
`;

export const AdBanner = (props: IProps) => {
  return (
    <div>
      <a href={props.url} target="_blank">
        <StyledImg src={props.image} />
      </a>
    </div>
  );
};
