import styled, { css } from 'styled-components';

import { RxRadioButtonSimple } from 'rx-core';

export const Header = styled.header`
  display: flex;
  margin-bottom: 0.1rem;
  align-items: center;
  align-content: center;
`;

export const PaymentTermsText = styled.p`
  font-weight: 200;
  font-size: 14px;
  margin-bottom: 10px;
  font-family: sans-serif;
`;

export const RadioInput = styled(RxRadioButtonSimple)`
  ${({ theme }) => css`
    margin-bottom: 0.25rem;

    input {
      cursor: pointer;
    }

    span {
      font-weight: 400;
      font-size: ${theme.sizes.small};
      font-family: ${theme.fonts.robotoLight};
    }
  `}
`;
