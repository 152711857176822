import { useMutation } from 'react-query';

import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePostAdminManufacturerIdApiKey = () => {
  const onRequest = async (req: { id: number }) => {
    return http.post(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/manufacturers/${req.id}/api-key`
    );
  };

  return useMutation(onRequest, {});
};

export { usePostAdminManufacturerIdApiKey };
