import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { GetPharmacyList } from 'rx-domain';

import { BuyerListItem } from '../BuyerListItem';

interface IProps {
  items: GetPharmacyList.PharmacyDto[];
}

export const BuyerList: React.FC<IProps> = (props) => {
  if (props.items.length === 0) {
    return (
      <Paper variant="outlined" style={{ padding: '1rem' }}>
        <Typography>There are no pharmacies</Typography>
      </Paper>
    );
  }

  return (
    <Paper variant="outlined">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Pharmacy Name</TableCell>
            <TableCell>DEA Number</TableCell>
            <TableCell>State License Number</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>City</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Zip Code</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((item) => (
            <BuyerListItem key={item.id} data={item} />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};
