import React, { memo, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { useFormikContext } from 'formik';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import { IBlackListItem } from 'rx-domain';

import { useAdminProducts } from 'rx-contexts';

import { usePutAdminBannedProductsId } from 'rx-api';

import { useToast } from 'rx-hooks';

import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { TransactionsOrderMenu } from '../../transactions-legacy/molecules/TransactionsOrderMenu';
import { AdminProductDateItemCell } from '../atoms/AdminProductDateItemCell';

type IProps = IBlackListItem & {
  index: number;
};

const TableRow = styled.tr<{ isSelected: boolean }>`
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;

  background: rgb(255, 255, 255);
  box-shadow: 5px 5px 25px rgba(51, 60, 68, 0.08);
  border-radius: 10px;
  height: 76px;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: #eff3ff;
      border: 1px solid #5a88ff;
    `}
`;

const TableCell = styled.td`
  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  color: black;
`;

const OptionsCell = styled(TableCell)`
  text-align: right;
`;

const AdminProductsBlackListDataItem = memo((props: IProps): JSX.Element => {
  const checkedId = `blackItems.${props.id}.selected`;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mode, setMode] = useState<'EDIT' | 'VIEW'>('VIEW');

  const { onSuccessToast } = useToast();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { isSelected, onModalDelete, onSetDeleteBlackItem } =
    useAdminProducts();
  const { values } = useFormikContext<{ blackItems: Array<IBlackListItem> }>();

  const updateBannedProducts = usePutAdminBannedProductsId();

  const onOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onEditMode = useCallback(() => {
    history.push(`/products/banned/${props.id}`);
  }, [history, props]);

  const onEditView = () => {
    setMode('VIEW');
    // deselected all
  };

  const onDeleteProduct = () => {
    onSetDeleteBlackItem(props);
    onModalDelete();
  };

  const onUpdateProduct = async () => {
    try {
      const product = values.blackItems.find((p) => p.id === props.id);

      if (!product) return;

      const { ...data } = product;

      const payload = {
        description: data.description,
        id: props.id,
        name: data.name,
        ndc: data.ndc,
      };

      await updateBannedProducts.mutateAsync(payload);

      await queryClient.refetchQueries('get-admin-black-list', {
        active: true,
      });

      onEditView();
      onSuccessToast('Changes saved succesfully 🚀');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const viewModeMenuOptions = [
    {
      onClick: onEditMode,
      text: 'Edit product',
      type: 'EDIT',
    },
    {
      onClick: onDeleteProduct,
      text: 'Delete product',
      type: 'DELETE',
    },
  ];

  const editModeMenuOptions = [
    {
      onClick: onUpdateProduct,
      text: 'Save changes',
      type: 'SAVE',
    },
    {
      onClick: onEditView,
      text: 'Cancel edit',
      type: 'CANCEL_EDIT',
    },
  ];

  return (
    <TableRow isSelected={isSelected(checkedId)}>
      <AdminProductDateItemCell
        mode={mode}
        name={`blackItems.${props.index}.ndc`}
        text={props.ndc}
      />
      <AdminProductDateItemCell
        mode={mode}
        name={`blackItems.${props.index}.name`}
        text={props.name}
      />
      <AdminProductDateItemCell
        mode={mode}
        name={`blackItems.${props.index}.description`}
        text={props.description}
      />
      <OptionsCell>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={onOpenMenu}
        >
          <MoreVertIcon />
        </IconButton>
        <TransactionsOrderMenu
          anchorEl={anchorEl}
          onClose={onClose}
          options={mode === 'VIEW' ? viewModeMenuOptions : editModeMenuOptions}
        />
      </OptionsCell>
    </TableRow>
  );
});

export { AdminProductsBlackListDataItem };
