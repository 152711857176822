import React, { SyntheticEvent } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';

import { TemplateView, SEO } from 'rx-core';
import { useToast } from 'rx-hooks';
import {
  Button,
  Divider,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  useGetAdminProducts,
  useGetPharmacyList,
  useGetAdminPharmacyPaymentMethodList,
  http,
} from 'rx-api';

import { AddPharmacist, GetAdminProducts, GetPharmacyList } from 'rx-domain';
import axios from 'axios';
import { NewPurchaseOrderLoadingPharmacy } from './NewPurchaseOrderLoadingPharmacy';
import { NewPurchaseOrderErrorList } from './NewPurchaseOrderErrorList';
import { NewPurchaseOrderCheckout } from './NewPurchaseOrderCheckout';
import { NewPurchaseOrderPaymentMethods } from './NewPurchaseOrderPaymentMethods';
import { NewPurchaseOrderSelectedPharmacy } from './NewPurchaseOrderSelectedPharmacy';
import { NewPurchaseOrderSeletedProducts } from './NewPurchaseOrderSelectedProducts';
import { NewPharmacistForm } from './NewPharmacistForm';

export const Container = styled.div`
  padding: 0;
  margin: 1.5rem;
`;

const usePlaceOrderOnBehalfOfPharmacyWithPaymentMethod = () => {
  return useMutation(
    async (request: {
      paymentMethodId: number;
      pharmacyId: number;
      products: { quantity: number; id: number }[];
    }) => {
      return http.post<unknown>(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/orders/payment-method`,
        request
      );
    },
    {}
  );
};

const usePlaceOrderOnBehalfOfPharmacyWithPaymentTerms = () => {
  return useMutation(
    async (request: {
      gracePeriod: number;
      pharmacyId: number;
      products: { quantity: number; id: number }[];
    }) => {
      return http.post<unknown>(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/orders/payment-term`,
        request
      );
    },
    {}
  );
};

export const NewPurchaseOrder: React.FC = () => {
  const history = useHistory();
  const [searchProductName, setSearchProductName] = React.useState('');
  const [searchPharmacyName, setSearchPharmacyName] = React.useState('');
  const [selectedPharmacy, setSelectedPharmacy] = React.useState<
    | null
    | (GetPharmacyList.PharmacyDto & {
        shippingAddress: any;
        billingAddress: any;
      })
  >(null);
  const { data: products, isLoading: isLoadingProducts } = useGetAdminProducts({
    name: searchProductName,
    category: 'all',
    limit: 10,
  });
  const [selectedProducts, setSelectedProducts] = React.useState<
    Array<GetAdminProducts.ProductItem & { quantity: number }>
  >([]);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = React.useState<
    string | null
  >(null);
  const [responseError, setResponseError] = React.useState<null | string>(null);
  const [productsWithError, setProductWithError] = React.useState<
    GetAdminProducts.ProductItem[]
  >([]);
  const [isNewPharmacyFormOpen, setIsNewPharmacyFormOpen] =
    React.useState(false);
  const toast = useToast();
  const { data: pharmacies, isLoading: isLoadingPharmacies } =
    useGetPharmacyList({
      skip: 0,
      search: searchPharmacyName,
    });
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } =
    useGetAdminPharmacyPaymentMethodList(selectedPharmacy?.id || 0);

  const handleChangeProductSearch = (_event: unknown, value: string) => {
    setSearchProductName(value);
  };

  const handleChangePharmacySearch = (_event: unknown, value: string) => {
    setSearchPharmacyName(value);
  };

  const handleClickProduct = (option: GetAdminProducts.ProductItem) => () => {
    const already = selectedProducts.find(
      (product) => product.id === option.id
    );

    if (already) {
      return;
    }

    setSelectedProducts([...selectedProducts, { ...option, quantity: 1 }]);
  };

  const handleClickPharmacy = (pharmacy: GetPharmacyList.PharmacyDto) => () => {
    if (selectedPharmacy !== null) {
      return;
    }

    setSelectedPharmacy(pharmacy);
  };

  const handleChangePaymentMethod = (_e: SyntheticEvent, value: string) => {
    setSelectedPaymentMethodId(value);
  };

  const handleClickDeleteProduct = (id: number) => () => {
    setSelectedProducts(
      selectedProducts.filter((product) => product.id !== id)
    );
  };

  const handleChangeProductQuantity =
    (id: number) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSelectedProducts(
        selectedProducts.map(
          (product: GetAdminProducts.ProductItem & { quantity: number }) => {
            if (product.id !== id) {
              return product;
            }

            return { ...product, quantity: parseInt(event.target.value, 10) };
          }
        )
      );
    };

  const handleChangeProductPrice =
    (id: number) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSelectedProducts(
        selectedProducts.map(
          (product: GetAdminProducts.ProductItem & { quantity: number }) => {
            if (product.id !== id) {
              return product;
            }

            return { ...product, price: event.target.value };
          }
        )
      );
    };

  const placeOrderWithPaymentMethod =
    usePlaceOrderOnBehalfOfPharmacyWithPaymentMethod();
  const placeOrderWithPaymentTerms =
    usePlaceOrderOnBehalfOfPharmacyWithPaymentTerms();

  const handleClickPlaceOrderWithPaymentMethod = async () => {
    try {
      await placeOrderWithPaymentMethod.mutateAsync({
        paymentMethodId: parseInt(
          selectedPaymentMethodId === null ? '0' : selectedPaymentMethodId,
          10
        ),
        pharmacyId: selectedPharmacy?.id || 0,
        products: selectedProducts.map((product) => ({
          quantity: product.quantity,
          price: product.price,
          id: product.id,
        })),
      });

      toast.onSuccessToast(`Order placed!`);
      history.push('/transactions');
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setResponseError(err.response?.data.message);
        setProductWithError(err.response?.data?.meta?.items || []);
      }
      toast.onErrorToast('Error: could not place order');
    }
  };

  const handleClickPlaceOrderWithPaymentTerms = async () => {
    try {
      await placeOrderWithPaymentTerms.mutateAsync({
        gracePeriod: parseInt(
          selectedPaymentMethodId === null
            ? '0'
            : selectedPaymentMethodId.split('_')[2],
          10
        ),
        pharmacyId: selectedPharmacy?.id || 0,
        products: selectedProducts.map((product) => ({
          quantity: product.quantity,
          price: product.price,
          id: product.id,
        })),
      });

      toast.onSuccessToast(`Order placed!`);
      history.push('/transactions');
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setResponseError(err.response?.data.message);
        setProductWithError(err.response?.data?.meta?.items || []);
      }
      toast.onErrorToast('Error: could not place order');
    }
  };

  const handleClickAddPharmacy = () => {
    setIsNewPharmacyFormOpen(true);
  };

  const handleClickCancel = () => {
    setIsNewPharmacyFormOpen(false);
    setSelectedPharmacy(null);
  };

  const handleAddPharmacy = (pharmacist: AddPharmacist.ResponsePayloadDto) => {
    setSelectedPharmacy({
      ...pharmacist.pharmacy,
      billingAddress: pharmacist.billingAddress,
      shippingAddress: pharmacist.shippingAddress,
      legalDocuments: pharmacist.legalDocuments,
    });
    setIsNewPharmacyFormOpen(false);
  };

  const subtotal = selectedProducts.reduce((total: number, product) => {
    return total + product.quantity * parseFloat(product.price);
  }, 0);

  return (
    <TemplateView>
      <SEO title="RxRise | Purchase Orders" />

      <Container>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography style={{ color: '#5a82ff', fontSize: '30px' }}>
            Create Purchase Order
          </Typography>
        </div>

        <Paper style={{ padding: '1rem' }} variant="outlined">
          <Typography variant="h6">Select Products</Typography>

          <Autocomplete
            style={{ marginTop: '1rem' }}
            placeholder="Search products..."
            onInputChange={handleChangeProductSearch}
            renderInput={(params: AutocompleteRenderInputParams) => {
              return (
                <TextField
                  {...params}
                  label="Search Products..."
                  variant="outlined"
                />
              );
            }}
            options={products?.data?.results || []}
            loading={isLoadingProducts}
            filterOptions={(x) => x}
            renderOption={(option) => {
              const text = option.ndc
                ? `(${option.ndc}) ${option.name} ${option.description} - $${option.price} (${option.manufacturer})`
                : `${option.name} ${option.description} - $${option.price} (${option.manufacturer})`;

              return (
                <button
                  style={{
                    border: 'none',
                    backgroundColor: 'transparent',
                    textAlign: 'left',
                    width: '100%',
                  }}
                  onClick={handleClickProduct(option)}
                >
                  <Typography>{text}</Typography>
                </button>
              );
            }}
            getOptionLabel={(option) =>
              option.ndc
                ? `(${option.ndc}) ${option.name} ${option.description} - $${option.price} (${option.manufacturer})`
                : `${option.name} ${option.description} - $${option.price} (${option.manufacturer})`
            }
            fullWidth
          />

          {selectedProducts.length > 0 && (
            <NewPurchaseOrderSeletedProducts
              products={selectedProducts}
              onChangeProductQuantity={handleChangeProductQuantity}
              onChangeProductPrice={handleChangeProductPrice}
              onClickDeleteProduct={handleClickDeleteProduct}
            />
          )}
        </Paper>

        <Paper
          style={{ padding: '1rem', marginTop: '1rem' }}
          variant="outlined"
        >
          {!isNewPharmacyFormOpen && selectedPharmacy === null && (
            <>
              <Typography variant="h6">Select Pharmacy</Typography>
              <Autocomplete
                style={{ marginTop: '1rem' }}
                placeholder="Search pharmacy..."
                onInputChange={handleChangePharmacySearch}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params: AutocompleteRenderInputParams) => {
                  return (
                    <TextField
                      {...params}
                      label="Search Pharmacy..."
                      variant="outlined"
                    />
                  );
                }}
                options={pharmacies?.data?.results || []}
                loading={isLoadingPharmacies}
                filterOptions={(x) => x}
                renderOption={(option) => {
                  const text = option.pharmacyName;

                  return (
                    <button
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        textAlign: 'left',
                        width: '100%',
                      }}
                      onClick={handleClickPharmacy(option)}
                    >
                      <Typography>{text}</Typography>
                    </button>
                  );
                }}
                getOptionLabel={(option) => option.pharmacyName}
                fullWidth
              />
            </>
          )}

          {selectedPharmacy === null && !isNewPharmacyFormOpen && (
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '1rem' }}
              onClick={handleClickAddPharmacy}
            >
              Add new pharmacy
            </Button>
          )}

          {isNewPharmacyFormOpen && (
            <NewPharmacistForm
              onClickCancel={handleClickCancel}
              onAddPharmacy={handleAddPharmacy}
            />
          )}

          {selectedPharmacy !== null && (
            <NewPurchaseOrderSelectedPharmacy
              pharmacyName={selectedPharmacy.pharmacyName}
              dea={selectedPharmacy.dea || 'N/A'}
              stateLicense={selectedPharmacy.stateLicense || 'N/A'}
              shippingAddress={`${selectedPharmacy.shippingAddress?.address}, ${selectedPharmacy.shippingAddress?.city}, ${selectedPharmacy.shippingAddress?.zipCode}, ${selectedPharmacy.shippingAddress?.state}`}
              billingAddress={`${selectedPharmacy.billingAddress?.address}, ${selectedPharmacy.billingAddress?.city}, ${selectedPharmacy.billingAddress?.zipCode}, ${selectedPharmacy.billingAddress?.state}`}
              billingAddressName={selectedPharmacy.billingAddress.name}
            />
          )}

          {selectedPharmacy !== null && !isLoadingPaymentMethods && (
            <NewPurchaseOrderPaymentMethods
              paymentMethodId={selectedPaymentMethodId}
              onChangePaymentMethod={handleChangePaymentMethod}
              paymentMethods={paymentMethods?.data?.cards || []}
            />
          )}

          {selectedPharmacy !== null && (
            <div>
              <Divider style={{ margin: '1rem 0' }} />

              <Button onClick={handleClickCancel} variant="outlined">
                Cancel
              </Button>
            </div>
          )}

          {selectedPharmacy !== null && isLoadingPaymentMethods && (
            <NewPurchaseOrderLoadingPharmacy />
          )}
        </Paper>

        {responseError !== null && (
          <NewPurchaseOrderErrorList
            error={responseError}
            products={productsWithError}
          />
        )}

        {subtotal > 0 && (
          <NewPurchaseOrderCheckout
            subtotal={subtotal}
            onClickCheckout={
              selectedPaymentMethodId?.includes('payment_terms')
                ? handleClickPlaceOrderWithPaymentTerms
                : handleClickPlaceOrderWithPaymentMethod
            }
            isDisabled={
              placeOrderWithPaymentMethod.isLoading ||
              placeOrderWithPaymentTerms.isLoading ||
              selectedPaymentMethodId === null ||
              selectedPharmacy === null
            }
          />
        )}
      </Container>
    </TemplateView>
  );
};
