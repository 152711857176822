import React from 'react';

import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import MasterCardIcon from 'assets/icons/Mastercard.svg';
import AmexCardIcon from 'assets/icons/Amex.svg';
import VisaCardIcon from 'assets/icons/Visa.svg';
import DinersClubCardIcon from 'assets/icons/DinersClub.svg';
import DiscoverCardIcon from 'assets/icons/Discover.svg';
import JCBCardIcon from 'assets/icons/JCB.svg';
import PoweredByStripeIcon from 'assets/icons/PoweredByStripe.svg';

import {
  Container,
  TitleContainer,
  Subtitle,
  Title,
  PaymentMethodPicture,
} from './index.styles';
import { useAddPayment } from 'rx-contexts';

export interface ICheckoutNewPaymentCardACHProps {
  onClick: () => void;
}

export const CheckoutNewPaymentCardACH: React.FC<
  ICheckoutNewPaymentCardACHProps
> = (props) => {
  const { view, onSetView, onSetError } = useAddPayment();
  return (
    <Container>
      <div>
        <TitleContainer>
          <Title>Add more payment options.</Title>
        </TitleContainer>

        <div>
          <Subtitle>
            RxRise accepts credit/debit cards and ACH payments. Payment 100%
            secure.
          </Subtitle>
          <div
            style={{
              marginTop: '15px',
              display: 'flex',
              alignItems: 'flex-start',
              alignContent: 'flex-start',
            }}
          >
            <div style={{ marginLeft: '1rem' }}>
              <div style={{ display: 'flex' }}>
                <div>
                  <PaymentMethodPicture src={VisaCardIcon} alt="" />
                  <PaymentMethodPicture src={AmexCardIcon} alt="" />
                </div>
                <div>
                  <PaymentMethodPicture src={MasterCardIcon} alt="" />
                  <PaymentMethodPicture src={JCBCardIcon} alt="" />
                </div>
                <div>
                  <PaymentMethodPicture src={DiscoverCardIcon} alt="" />
                  <PaymentMethodPicture src={DinersClubCardIcon} alt="" />
                </div>
                <div>
                  <PaymentMethodPicture src={PoweredByStripeIcon} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Button
        data-cy="checkout-add-payment-method"
        variant="outlined"
        color="primary"
        style={{ marginTop: '1rem' }}
        onClick={() => {
          props.onClick();
          onSetView('debit-credit-card');
        }}
      >
        <AddIcon />
        Add New Debit/Credit Card
      </Button>
      <Button
        data-cy="checkout-add-payment-method"
        variant="outlined"
        color="primary"
        style={{ marginTop: '1rem', marginLeft: '1rem' }}
        onClick={() => {
          props.onClick();
          onSetView('ach');
        }}
      >
        <AddIcon />
        Add New ACH
      </Button>
    </Container>
  );
};
