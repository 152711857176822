import styled, { css } from 'styled-components';

export const Container = styled.tr``;

export const VerifyLink = styled.u`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.colors.blue[300]};
  `}
`;
