import React from 'react';
import styled from 'styled-components';

import { RxTableTabs, SEO, TemplateView } from 'rx-core';

import {
  FilterProvider,
  OrderProvider,
  TransactionsProvider,
} from 'rx-contexts';

import { TransactionTab } from './TransactionTab';
import { TransactionFilter } from './TransactionFilter';
import { TransactionActions } from './TransactionActions';
import { TransactionCancel } from './TransactionCancel';
import { TransactionEDI } from './TransactionEDI';

const Container = styled.div`
  padding: 1.5rem;
`;

export const TransactionViewV2: React.FC = () => {
  return (
    <TemplateView>
      <SEO title="RxRise | Transactions" />
      <TransactionsProvider>
        <FilterProvider>
          <OrderProvider>
            <Container>
              <TransactionActions />
              <RxTableTabs
                items={[
                  {
                    active: true,
                    title: 'All transactions',
                    onSetView: () => {},
                    value: 'ALL_TRANSACTIONS',
                  },
                ]}
                filterComp={<TransactionFilter />}
              >
                <TransactionTab />
              </RxTableTabs>
            </Container>

            <TransactionCancel />
            <TransactionEDI />
          </OrderProvider>
        </FilterProvider>
      </TransactionsProvider>
    </TemplateView>
  );
};
