import { Button, Typography } from '@material-ui/core';
import React from 'react';

type IProps = {
  subtitle: string;
  action: {
    text: string;
    action: () => void;
  };
};

export const CheckoutCardEmpty: React.FC<IProps> = ({
  action,
  subtitle,
  ...props
}: IProps) => {
  return (
    <div {...props}>
      <Typography style={{ marginBottom: '1rem' }}>{subtitle}</Typography>
      <Button variant="contained" color="primary" onClick={action.action}>
        {action.text}
      </Button>
    </div>
  );
};
