import React from 'react';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

import { FilterItem, UserApprovalStatus } from 'rx-domain';

import { RxFilterItem } from '../RxFilterItem';

export type RxFilterListProps = LayoutProps & {
  onClick: (value: UserApprovalStatus, title: string) => void;
  items: FilterItem[];
};

const Container = styled.ul<LayoutProps>`
  max-width: 214px;
  width: 214px;
  height: auto;

  background: #ffffff;
  box-shadow: 5px 5px 25px rgb(51 60 68 / 8%);
  position: absolute;
  top: 56px;
  left: 0;
  padding-top: 16px;
  padding-left: 38px;
  z-index: 3;

  ${layout};
`;

const RxFilterList = ({
  onClick,
  items,
  ...props
}: RxFilterListProps): JSX.Element => {
  return (
    <Container {...props}>
      {items.map((item) => (
        <RxFilterItem
          {...item}
          key={item.value}
          onClick={() => onClick(item.value, item.title)}
        />
      ))}
    </Container>
  );
};

export { RxFilterList };
