import React, { useCallback } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { PaymentMethodACH, PaymentMethodCard } from 'rx-domain';

import {
  useDeleteAdminACH,
  useDeleteAdminCard,
  useDeletePaymentMethodsACH,
  useDeletePaymentMethodsCards,
} from 'rx-api';

import { useAuth, useToast } from 'rx-hooks';

import { PaymentListDialog } from '../PaymentListDialog';

import {
  Container,
  TitleColumn,
  TitleColumnContent,
  Loading,
} from './index.styles';

type Card = PaymentMethodCard & {
  type: 'card';
};

type ACH = PaymentMethodACH & {
  type: 'ach';
};

type IProps = Card | ACH;

const PaymentListItem = (props: IProps): JSX.Element => {
  const { onSuccessToast, onErrorToast } = useToast();
  const { type } = useAuth();
  const [open, setOpen] = React.useState(false);

  const queryClient = useQueryClient();
  const params = useParams<{ id: string }>();

  const deletePaymentCard = useDeletePaymentMethodsCards();
  const deletePaymentACH = useDeletePaymentMethodsACH();

  const deleteAdminPaymentCard = useDeleteAdminCard({
    id: params.id,
    paymentId: props?.id?.toString(),
  });
  const deleteAdminPaymentACH = useDeleteAdminACH({
    id: params.id,
    paymentId: props?.id?.toString(),
  });

  const isLoading =
    deletePaymentACH.isLoading ||
    deletePaymentCard.isLoading ||
    deleteAdminPaymentACH.isLoading ||
    deleteAdminPaymentCard.isLoading;

  const onDeleteCard = useCallback(async () => {
    try {
      if (type !== 'admin') {
        if (props.type === 'ach') {
          await deletePaymentACH.mutateAsync({ id: props.id });
          await queryClient.refetchQueries('get-payment-methods-ach', {
            active: true,
          });
        }

        if (props.type === 'card') {
          await deletePaymentCard.mutateAsync({ id: props.id.toString() });
          await queryClient.refetchQueries('get-payment-methods-cards', {
            active: true,
          });
        }

        setOpen(false);
        onSuccessToast('Removed card successfully 🚀');
        return;
      }

      if (props.type === 'ach') {
        await deleteAdminPaymentACH.mutateAsync({});
        await queryClient.refetchQueries(
          'get-admin-pharmacists-payment-methods',
          {
            active: true,
          }
        );
      }

      if (props.type === 'card') {
        await deleteAdminPaymentCard.mutateAsync({});
        await queryClient.refetchQueries(
          'get-admin-pharmacists-payment-methods',
          {
            active: true,
          }
        );
      }

      setOpen(false);
      onSuccessToast('Removed card successfully 🚀');
    } catch (error) {
      onErrorToast('Could not remove the card');
    }
  }, [
    deleteAdminPaymentACH,
    deleteAdminPaymentCard,
    deletePaymentACH,
    deletePaymentCard,
    onErrorToast,
    onSuccessToast,
    props.id,
    props.type,
    queryClient,
    type,
  ]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container
      container
      item
      alignItems="center"
      data-cy="payment-method-list-item"
    >
      <PaymentListDialog
        open={open}
        handleClose={handleClose}
        action={onDeleteCard}
        disableButtons={isLoading}
      />

      {props.type === 'card' && (
        <>
          <Grid item xs={3}>
            <TitleColumn>{props.brand.toUpperCase()}</TitleColumn>
            <TitleColumnContent>Ending in {props.last4}</TitleColumnContent>
          </Grid>
          <Grid item xs={3}>
            <TitleColumn>EXPIRES</TitleColumn>
            <TitleColumnContent>
              {props.expMonth} / {props.expYear}
            </TitleColumnContent>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <IconButton
              data-cy="payment-method-list-item-delete"
              onClick={() => setOpen(true)}
              disabled={deletePaymentCard.isLoading}
            >
              {deletePaymentCard.isLoading ? (
                <Loading size={24} thickness={6} />
              ) : (
                <CancelIcon />
              )}
            </IconButton>
          </Grid>
        </>
      )}
      {props.type === 'ach' && (
        <>
          <Grid item xs={3}>
            <TitleColumn>ACCOUNT NUMBER</TitleColumn>
            <TitleColumnContent>{props.last4}</TitleColumnContent>
          </Grid>
          <Grid item xs={3}>
            <TitleColumn>ROUTING NUMBER</TitleColumn>
            <TitleColumnContent>{props.routingNumber}</TitleColumnContent>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <IconButton
              onClick={() => setOpen(true)}
              disabled={deletePaymentACH.isLoading}
              data-cy="payment-method-list-item-delete"
            >
              {deletePaymentACH.isLoading ? (
                <Loading size={24} thickness={6} />
              ) : (
                <CancelIcon />
              )}
            </IconButton>
          </Grid>
        </>
      )}
    </Container>
  );
};

export { PaymentListItem };
