import React from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import InputMask from 'react-input-mask';
import { useFormik } from 'formik';

import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import { IGetPharmacistPharmacyResponse } from 'rx-domain';
import { pharmacyTypeList } from 'rx-utils';
import { usePutPharmacistPharmacy } from 'rx-api';
import { useToast } from 'rx-hooks';

import { PharmacyInfoSchema } from 'utils/validators/account-settings/pharmacy/info.validator';

import { Title } from './index.styles';

type IProps = {
  data: IGetPharmacistPharmacyResponse | undefined;
  disabled: boolean;
};

type IForm = {
  pharmacyName: string;
  type: number;
  faxNumber: string;
  npi: string;
  yearsInBusiness: number;
  primaryWholesaler: string;
  account1: string;
  secondaryWholesaler: string;
  account2: string;
  dbaName: string;
  phoneNumber: string;
  taxId: string;
  dea: string;
  stateLicense: string;
};

export const SettingsPharmacy: React.FC<IProps> = (props) => {
  const initialValues: IForm = {
    pharmacyName: props.data?.pharmacyName ?? '',
    dbaName: props.data?.dbaName ?? '',
    phoneNumber: props.data?.phoneNumber ?? '',
    faxNumber: props.data?.faxNumber ?? '',
    npi: props.data?.npi ?? '',
    type: props.data?.type ?? 0,
    yearsInBusiness: props.data?.yearsInBusiness ?? 0,
    primaryWholesaler: props.data?.primaryWholesaler ?? '',
    account1: props.data?.account1 ?? '',
    secondaryWholesaler: props.data?.secondaryWholesaler ?? '',
    account2: props.data?.account2 ?? '',
    taxId: props.data?.taxId ?? '',
    dea: props.data?.dea ?? '',
    stateLicense: props.data?.stateLicense ?? '',
  };

  const queryClient = useQueryClient();
  const params = useParams<{ id: string }>();
  const updatePharmacyData = usePutPharmacistPharmacy(params.id);
  const toast = useToast();
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: PharmacyInfoSchema,
    onSubmit: async (values: IForm) => {
      try {
        await updatePharmacyData.mutateAsync({
          ...values,
          faxNumber: values.faxNumber
            .replace(/[{()}]/g, '')
            .replaceAll(/\s/g, ''),
          phoneNumber: values.phoneNumber
            .replace(/[{()}]/g, '')
            .replaceAll(/\s/g, ''),
        });
        await queryClient.refetchQueries('get-pharmacist-data', {
          active: true,
        });
        toast.onSuccessToast('Changes saved succesfully 🚀');
      } catch (err) {
        toast.onErrorToast('Error: could not save changes');
      }
    },
  });

  return (
    <Paper variant="outlined" style={{ padding: '1rem' }}>
      <Title>Pharmacy</Title>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              name="pharmacyName"
              label=" Pharmacy Name *"
              placeholder="Pharmacy Name"
              variant="outlined"
              value={formik.values.pharmacyName}
              onChange={formik.handleChange}
              error={!!formik.errors.pharmacyName}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="dbaName"
              label="Doing Business As (DBA)"
              placeholder="DBA"
              variant="outlined"
              value={formik.values.dbaName}
              onChange={formik.handleChange}
              error={!!formik.errors.dbaName}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Select
              name="type"
              label="Type of pharmacy *"
              placeholder="Choose State"
              variant="outlined"
              value={formik.values.type}
              onChange={formik.handleChange}
              error={!!formik.errors.type}
              disabled={props.disabled}
              fullWidth
            >
              {pharmacyTypeList.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputMask
              name="phoneNumber"
              mask="(999) 999 9999"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              disabled={props.disabled}
              maskChar=""
            >
              {() => (
                <TextField
                  label="Contact Phone Number*"
                  placeholder="(xxx) xxx-xxxx"
                  variant="outlined"
                  name="phoneNumber"
                  error={!!formik.errors.phoneNumber}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputMask
              name="faxNumber"
              mask="(999) 999 9999"
              value={formik.values.faxNumber}
              onChange={formik.handleChange}
              disabled={props.disabled}
              maskChar=""
            >
              {() => (
                <TextField
                  label="Fax Number*"
                  placeholder="(xxx) xxx-xxxx"
                  variant="outlined"
                  name="faxNumber"
                  error={!!formik.errors.faxNumber}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="yearsInBusiness"
              label="Years in business*"
              type="number"
              variant="outlined"
              value={formik.values.yearsInBusiness}
              onChange={formik.handleChange}
              error={!!formik.errors.yearsInBusiness}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              name="npi"
              label="National Provider Number (NPI#)"
              placeholder="NPI #"
              variant="outlined"
              value={formik.values.npi}
              onChange={formik.handleChange}
              error={!!formik.errors.npi}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="taxId"
              label="Tax ID #"
              placeholder="Tax ID #"
              variant="outlined"
              value={formik.values.taxId}
              onChange={formik.handleChange}
              error={!!formik.errors.taxId}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={props.disabled}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              name="dea"
              label=" DEA Number"
              placeholder="1234567890"
              variant="outlined"
              value={formik.values.dea}
              onChange={formik.handleChange}
              error={!!formik.errors.dea}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={props.disabled}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              name="stateLicense"
              label="State License"
              placeholder="0987654321"
              variant="outlined"
              value={formik.values.stateLicense}
              onChange={formik.handleChange}
              error={!!formik.errors.stateLicense}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={props.disabled}
              required
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={6}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ marginTop: '1rem' }}
              disabled={props.disabled}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
