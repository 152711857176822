import React from 'react';
import { NavLinkProps } from 'react-router-dom';

import { LinkItem, ListItem } from './index.styles';

type IProps = NavLinkProps & {
  text: string;
};

export const HeaderLink: React.FC<IProps> = ({ text, ...props }) => {
  return (
    <ListItem>
      <LinkItem {...props} activeClassName="header-link">
        {text}
      </LinkItem>
    </ListItem>
  );
};
