import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';

export function useAdminUpdateManufacturerEdiSettings(id: number | string) {
  return useMutation(
    ['admin-update-manufacturer-edi-settings', id],
    (data: { ediPartnerId: string }) => {
      return http.put(
        `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/accounts/${id}/manufacturer/edi-settings`,
        data
      );
    },
    {}
  );
}
