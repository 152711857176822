import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';
import { useField } from 'formik';

import { InputTable } from '../InputTable/InputTable';

export type RxInputTableProps = InputHTMLAttributes<HTMLInputElement> &
  LayoutProps & {
    name: string;
  };

const Input = styled(InputTable)`
  width: 100%;
  ${layout};
`;
const RxInputTable = ({ ...props }: RxInputTableProps): JSX.Element => {
  const [field, meta] = useField({ ...props });

  const formError = meta.touched && meta.error;

  return <Input {...field} {...props} error={!!formError} />;
};

export { RxInputTable };
