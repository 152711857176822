import React from 'react';
import { useFormik } from 'formik';
import { useQueryClient } from 'react-query';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import { RRModalProps } from 'rx-core';
import { usePutAddressShippingId } from 'rx-api';
import { STATE_CODES_US } from 'rx-utils';
import { useToast } from 'rx-hooks';

import { ShippingAddressSchema } from 'utils/validators/shipping-address.validator';

import {
  Container,
  Modal,
  SubmitContainer,
  Title,
} from './CheckoutNewShipAddress.styles';

type IProps = RRModalProps;

interface IForm {
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

export const CheckoutNewShipAddress: React.FC<IProps> = (
  props
): JSX.Element => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const postShippingAddress = usePutAddressShippingId();

  const formik = useFormik({
    initialValues: {
      address: '',
      city: '',
      state: 'AL',
      zipCode: '',
    },
    validationSchema: ShippingAddressSchema,
    onSubmit: async (values: IForm) => {
      try {
        await postShippingAddress.mutateAsync({
          address: values,
        });

        await queryClient.refetchQueries('get-pharmacist-shipping-address', {
          active: true,
        });

        props.onClose();
        toast.onSuccessToast('Shipping address saved!');
      } catch (err) {
        toast.onErrorToast('Error: could not add shipping address');
      }
    },
  });

  return (
    <Modal {...props}>
      <Container>
        <Title color="#2b3647" size="h2">
          Shipping Address
        </Title>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
            <Grid item xs={6}>
              <TextField
                name="address"
                label="Address"
                placeholder="Address"
                variant="outlined"
                value={formik.values.address}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="city"
                label="City"
                placeholder="City"
                variant="outlined"
                value={formik.values.city}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  shrink
                  style={{
                    backgroundColor: 'white',
                    boxSizing: 'border-box',
                    margin: '-0.5rem 0 0 0.5rem',
                    padding: '0 0.5rem',
                    zIndex: 100,
                  }}
                >
                  State *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="state"
                  placeholder="State"
                  variant="outlined"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  fullWidth
                  required
                >
                  {STATE_CODES_US.map((state) => (
                    <MenuItem value={state} key={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="zipCode"
                label="Zip Code"
                placeholder="Zip Code"
                variant="outlined"
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <SubmitContainer>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Save
            </Button>
          </SubmitContainer>
        </form>
      </Container>
    </Modal>
  );
};
