import React from 'react';
import styled, { css } from 'styled-components';

import { useAddPayment } from 'rx-contexts';

import { RRModal } from 'rx-core';
import { AddPaymentMethodACHVerifiedForm } from '../../payment-method/molecules/AddPaymentMethodACHVerifiedForm';

const Modal = styled(RRModal)`
  ${({ theme }) => css`
    height: fit-content;
    width: 36rem;

    ${theme.lessThan('ipadPro')} {
      width: 95%;
    }
  `}
`;

export const VerifyPaymentACH: React.FC<{ pharmacyId: number | string }> = (
  props
) => {
  const { onOpenVerifyModal, openVerifyModal } = useAddPayment();

  return (
    <Modal visible={openVerifyModal} onClose={() => onOpenVerifyModal(false)}>
      <AddPaymentMethodACHVerifiedForm pharmacyId={props.pharmacyId} />
    </Modal>
  );
};
