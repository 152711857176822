import React from 'react';
import styled, { css } from 'styled-components';

import { Grid } from '@material-ui/core';
import { RxInputText } from 'rx-core';

const Container = styled.div``;

const PharmacyTitleSection = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoRegular};
    font-weight: 400;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    ${theme.lessThan('ipad')} {
      padding: 0 1.5rem;
    }
  `}
`;

const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoLight};
    font-weight: 400;
    margin-bottom: 1rem;

    ${theme.lessThan('ipad')} {
      padding: 0 1.5rem;
    }
  `}
`;

const GridResponsive = styled(Grid)`
  ${({ theme }) => css`
    ${theme.lessThan('ipad')} {
      &.MuiGrid-spacing-xs-2 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }

      &.MuiGrid-spacing-xs-4 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }
    }
  `}
`;

const SignupBuyerPasswordSetupForm: React.FC = () => {
  return (
    <Container>
      <PharmacyTitleSection>Password setup</PharmacyTitleSection>
      <Subtitle>
        It's important to use a secure password. You can create this with any
        combination of 8 or more mixed case letters, numbers or special
        characters (*? etc).
      </Subtitle>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="passwordSetup.password"
            label="Password *"
            placeholder="Password"
            type="password"
            showPassword={true}
            showPasswordHelper
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="passwordSetup.confirmPassword"
            label="Verify Password *"
            placeholder="Verify Password"
            type="password"
            showPassword={true}
            showPasswordHelper
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
    </Container>
  );
};

export { SignupBuyerPasswordSetupForm };
