import { useMemo } from 'react';
import { ITransaction } from 'rx-domain';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTransactionOrder = ({ order, orderId }: ITransaction) => {
  const transactionOrderId = `${
    order.prefix ? order.prefix : `ORD-${order.pharmacistId}`
  }-${orderId}`;

  const grossTotalAmount = useMemo(() => {
    return order.purchaseOrders.reduce(
      (prev, current) => prev + parseFloat(current.subtotal),
      0
    );
  }, [order.purchaseOrders]);

  const feeRiseFullAmount = useMemo(() => {
    return order.purchaseOrders.reduce((total, purchaseOrder) => {
      return (
        total -
        (parseFloat(purchaseOrder.subtotal) - purchaseOrder.sellersSplit)
      );
    }, grossTotalAmount);
  }, [grossTotalAmount, order.purchaseOrders]);

  const netTotalAmount = useMemo(() => {
    return grossTotalAmount - feeRiseFullAmount;
  }, [grossTotalAmount, feeRiseFullAmount]);

  return {
    transactionOrderId,
    grossTotalAmount,
    feeRiseFullAmount,
    netTotalAmount,
  };
};
