import { Paper, TableCell, TableRow } from '@material-ui/core';
import React from 'react';

export const AdminProductsEmpty: React.FC<React.PropsWithChildren<any>> = ({
  children,
}) => (
  <TableRow>
    <TableCell colSpan={20}>
      <Paper variant="outlined" style={{ padding: '2rem', width: '100%' }}>
        {children}
      </Paper>
    </TableCell>
  </TableRow>
);
