import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const ListItem = styled.li`
  list-style: none;
  height: 100%;

  margin-right: 1.5rem;
`;

export const LinkItem = styled(NavLink)`
  ${({ theme, ...props }) => css`
    text-decoration: none;
    color: black;
    height: 100%;
    display: flex;
    align-items: center;

    &.${props.activeClassName} {
      color: ${theme.colors.blue[500]};
      border-bottom: 2px ${theme.colors.blue[500]} solid;
    }
  `}
`;
