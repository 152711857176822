import React, { HTMLProps } from 'react';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';
import { FieldProps } from 'formik';
import MaskedInput, { Mask } from 'react-text-mask';

import { DataState } from 'rx-domain';

import { MASK_PHONE_NUMBER } from 'rx-utils';

import { StatusCheckIcon } from '../../icons';

import { InputTextContainer } from '../InputText';
import { RxInputErrorMessage } from '../RxInputErrorMessage/RxInputErrorMessage';
import { InputTitle } from '../InputTitle/InputTitle';

export type InputMaskProps = FieldProps &
  HTMLProps<HTMLInputElement> & {
    label: string;
    state?: DataState;
    className?: string;
    inputError: boolean;
    mask: Mask;
  };

const Container = styled.div<LayoutProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${layout}
`;

const CheckIcon = styled(StatusCheckIcon)`
  height: 28px;
  width: 28px;
  position: absolute;
  bottom: 11px;
  right: -42px;
`;

const InputContainer = styled.div<LayoutProps>`
  position: relative;
  ${layout};
`;

const CustomInputTitle = styled(InputTitle)`
  margin-bottom: 10px;
`;

const InputMask: React.FC<InputMaskProps> = ({
  label,
  className,
  state,
  width,
  inputError,
  placeholder,
  field: { name, ...fieldProps },
  form: { errors, touched },
  mask,
}: InputMaskProps & LayoutProps) => {
  return (
    <Container className={className}>
      {label && (
        <CustomInputTitle
          title={label}
          htmlFor={name}
          error={state === 'error' && inputError}
        />
      )}
      <InputContainer width={width}>
        <MaskedInput
          mask={mask}
          id={name}
          guide={false}
          name={name}
          placeholder={placeholder}
          {...fieldProps}
          render={(ref: (inputElement: HTMLInputElement) => void, props) => {
            return (
              <InputTextContainer
                ref={ref}
                {...props}
                error={state === 'error' && inputError}
              />
            );
          }}
        />
        {state !== 'none' && !inputError && <CheckIcon type={state} />}
      </InputContainer>
      <RxInputErrorMessage>
        {touched[name] && errors[name] && errors[name]}
      </RxInputErrorMessage>
    </Container>
  );
};

InputMask.defaultProps = {
  className: '',
  state: 'none',
  mask: MASK_PHONE_NUMBER,
};

export { InputMask };
