import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.fonts.robotoRegular};
    font-size: ${theme.sizes.h2};
    border-bottom: 1px solid rgb(43, 54, 71, 0.35);
    padding-bottom: 1rem;
  `}
`;
