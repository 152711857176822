import { useMutation } from 'react-query';
import { IPostPharmaciesRejectDTO } from 'rx-domain';
import { http } from 'rx-api/http/repository';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePostPharmaciesReject = () => {
  const onRequest = async (request: IPostPharmaciesRejectDTO) => {
    return http.post(
      `${process.env.REACT_APP_WEBSITE_URL}/v1/admin/pharmacists/${request.id}/reject`,
      request
    );
  };

  return useMutation(onRequest, {});
};

export { usePostPharmaciesReject };
