import React from 'react';
import styled, { css } from 'styled-components';
import { LayoutProps, layout } from 'styled-system';

type LoginCardProps = LayoutProps & {
  children: React.ReactNode;
  className?: string;
};

const Container = styled.div<LayoutProps>`
  width: 920px;
  height: 600px;
  background-color: white;

  border-radius: 10px;

  display: flex;
  flex-direction: row;

  box-shadow: 5px 5px 25px rgba(51, 60, 68, 0.08);
  ${layout};

  ${({ theme: { lessThan } }) => css`
    ${lessThan('ipadPro')} {
      height: auto;
    }
  `}
`;

const LoginCard = ({ children, ...props }: LoginCardProps): JSX.Element => {
  return <Container {...props}>{children}</Container>;
};

LoginCard.defaultProps = {
  className: '',
};

export { LoginCard };
