import React from 'react';
import styled, { css } from 'styled-components';

import { FilterItem, UserApprovalStatus } from 'rx-domain';

import { RxFilterList } from '../RxFilterList';

export type RxFilterProps = {
  items: FilterItem[];
  label: string;
  onClickItem: (value: UserApprovalStatus, title: string) => void;
  onClickFilter: () => void;
  visibleItems: boolean;
};

const Container = styled.div`
  ${({ theme }) => css`
    max-width: 214px;
    width: 214px;
    background-color: ${theme.colors.black[100]};
    height: 56px;
    padding: 16px 22px;
    border-radius: 50px;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;
  `}
`;

const SmallTriangle = styled.div`
  ${({ theme }) => css`
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${theme.colors.white[100]};
  `}
`;

const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.white[100]};
  `}
`;

const RxFilter = ({
  items,
  label,
  visibleItems,
  onClickItem,
  onClickFilter,
}: RxFilterProps): JSX.Element => {
  return (
    <Container onClick={onClickFilter}>
      <Text>{label}</Text>
      <SmallTriangle />
      <RxFilterList
        items={items}
        display={visibleItems ? 'initial' : 'none'}
        onClick={onClickItem}
      />
    </Container>
  );
};

export { RxFilter };
