import React from 'react';
import { Skeleton } from '@material-ui/lab';

export const AdListLoader: React.FC = () => {
  return (
    <>
      <Skeleton height={50} />
      <Skeleton height={50} />
    </>
  );
};
