import React, { TextareaHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';
import { useField } from 'formik';

import { DataState } from 'rx-domain';

import { InputTextarea } from '../InputTextarea';
import { InputTitle } from '../InputTitle';
import { RxInputIcon } from '../RxInputIcon';
import { RxInputMessage } from '../RxInputMessage';
import { RxInputErrorMessage } from '../RxInputErrorMessage';

export type RxTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> &
  LayoutProps & {
    label?: string;
    state?: DataState;
    message?: string;
    inputError: boolean;
    name: string;
  };

const Container = styled.div<LayoutProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${layout}
`;

const InputContainer = styled.div<LayoutProps & { error: boolean }>`
  position: relative;
  ${layout};

  ${({ error }) =>
    !error &&
    css`
      margin-bottom: 30px;
    `}
`;

const RxTextarea = ({
  label,
  state,
  width,
  message,
  className,
  inputError,
  ...props
}: RxTextareaProps): JSX.Element => {
  const [field, meta] = useField({ ...props });

  const formError = meta.touched && meta.error;
  const iconState = state !== 'none';
  const inputErrorState = state === 'error' && inputError;

  return (
    <Container className={className}>
      {label && (
        <InputTitle
          title={label}
          htmlFor={props.name}
          error={state === 'error' && inputError}
        />
      )}
      <InputContainer width={width} error={!!formError}>
        <InputTextarea {...field} {...props} error={inputErrorState} />
        {iconState && <RxInputIcon state={state} />}
      </InputContainer>
      {formError && <RxInputErrorMessage>{meta.error}</RxInputErrorMessage>}
      {message && (
        <RxInputMessage error={!!formError}>{message}</RxInputMessage>
      )}
    </Container>
  );
};

RxTextarea.defaultProps = {
  label: '',
  message: '',
  state: 'none',
  inputError: false,
};

export { RxTextarea };
