import React from 'react';
import styled from 'styled-components';

type TermsAndConditionsDescriptionProps = {
  texts: Array<string>;
};

const Text = styled.p`
  font-family: Roboto Light;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #838ca5;
`;

const TermsAndConditionsDescription = ({
  texts,
}: TermsAndConditionsDescriptionProps): JSX.Element => {
  return (
    <div>
      {texts.map((t) => (
        <Text key={t}>{t}</Text>
      ))}
    </div>
  );
};

export { TermsAndConditionsDescription };
