import { GetAdminProduct } from 'rx-domain';
import { useGetRequest } from 'rx-api/utils';
import {
  GET_ADMIN_PRODUCT_URL,
  GET_ADMIN_PRODUCT_KEY,
} from 'rx-api/api/admin/products/constants';

export const useGetAdminProduct = (query: GetAdminProduct.Params) => {
  return useGetRequest<GetAdminProduct.Params, GetAdminProduct.Response>({
    url: GET_ADMIN_PRODUCT_URL.replace('{id}', query.id.toString()),
    key: [GET_ADMIN_PRODUCT_KEY, query.id],
  });
};
