import React from 'react';
import styled from 'styled-components';

import { RRModalProps } from 'rx-core';

import { TwoFactorAuthOffMessage } from './TwoFactorAuthOffMessage';

type IProps = RRModalProps;

const Container = styled.div`
  height: 100%;
`;

const TwoFactorAuthOff = (props: IProps): JSX.Element => {
  return (
    <Container>
      <TwoFactorAuthOffMessage {...props} />
    </Container>
  );
};

export { TwoFactorAuthOff };
