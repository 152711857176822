import * as Yup from 'yup';
import { DATE_REGEX, parseMoneyNumber } from 'rx-utils';

const validateNDC = Yup.string().min(9).max(20).notRequired();

const validateExpDate = Yup.string().required('Date is required').nullable();

const validatePrice = Yup.number()
  .transform(parseMoneyNumber)
  .positive()
  .required('Price is Required');

const validateName = Yup.string()
  .min(1)
  .max(255)
  .transform((_, originalValue: string) =>
    originalValue.replaceAll(DATE_REGEX, '').trim()
  )
  .required('Name is Required');

export { validateNDC, validateExpDate, validatePrice, validateName };
