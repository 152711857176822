import React from 'react';
import { useParams } from 'react-router-dom';

import {
  useGetAdminPharmacyLegalDocuments,
  usePostAdminPharmacyLegalDocuments,
} from 'rx-api';
import { Typography } from '@material-ui/core';
import { useToast, useViewEditMode } from 'rx-hooks';

import {
  PharmacyLegalDocumentsEditDEAForm,
  PharmacyLegalDocumentsEditStateForm,
  PharmacyLegalDocumentsViewForm,
} from 'pages/shared/PharmacyLegalDocuments';
import { PharmacyProfileLegalDocumentsHeader } from './index.styles';

export const PharmacyProfileLegalDocuments: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    isLoading,
    data: response,
    refetch,
  } = useGetAdminPharmacyLegalDocuments(id);
  const {
    modeDEA,
    modeState,
    setViewModeDEA,
    setEditModeDEA,
    setViewModeState,
    setEditModeState,
  } = useViewEditMode();
  const [deaFile, setDeaFile] = React.useState<File | null>(null);
  const [stateLicenseFile, setStateLicenseFile] = React.useState<File | null>(
    null
  );
  const [saving, setSaving] = React.useState(false);
  const toast = useToast();
  const useUploadDea = usePostAdminPharmacyLegalDocuments(id, 'dea');
  const useUploadStateLicense = usePostAdminPharmacyLegalDocuments(
    id,
    'state_license'
  );

  const handleSubmitFiles = async (event: any) => {
    try {
      event.preventDefault();
      setSaving(true);

      const deaForm = new FormData();
      deaForm.append('file', deaFile || '');
      deaForm.append('type', deaFile?.type || '');

      const stateLicenseForm = new FormData();
      stateLicenseForm.append('file', stateLicenseFile || '');
      stateLicenseForm.append('type', stateLicenseFile?.type || '');

      if (modeDEA === 'edit') {
        await useUploadDea.mutateAsync(deaForm);
      } else {
        await useUploadStateLicense.mutateAsync(stateLicenseForm);
      }

      refetch();
      toast.onSuccessToast('File updated!');
      setSaving(false);
      setViewModeDEA();
      setViewModeState();
    } catch (err) {
      toast.onErrorToast('Could not update file!');
      setSaving(false);
    }
  };

  const handleChangeDea = (event: any) => {
    return setDeaFile(event.target.files[0]);
  };

  const handleChangeStateLicense = (event: any) => {
    return setStateLicenseFile(event.target.files[0]);
  };

  return (
    <div>
      <PharmacyProfileLegalDocumentsHeader>
        <Typography variant="h5">Legal Documents</Typography>
      </PharmacyProfileLegalDocumentsHeader>

      {modeDEA === 'view' && modeState === 'view' && (
        <PharmacyLegalDocumentsViewForm
          deaUrl={response?.data.dea || ''}
          stateLicenseUrl={response?.data.stateLicense || ''}
          loading={isLoading}
          disabled={false}
          onClickEditDEA={setEditModeDEA}
          onClickEditState={setEditModeState}
        />
      )}

      {modeDEA === 'edit' && (
        <PharmacyLegalDocumentsEditDEAForm
          onSubmit={handleSubmitFiles}
          onChangeDea={handleChangeDea}
          onChangeStateLicense={handleChangeStateLicense}
          loading={saving}
          onClickCancel={setViewModeDEA}
        />
      )}

      {modeState === 'edit' && (
        <PharmacyLegalDocumentsEditStateForm
          onSubmit={handleSubmitFiles}
          onChangeDea={handleChangeDea}
          onChangeStateLicense={handleChangeStateLicense}
          loading={saving}
          onClickCancel={setViewModeState}
        />
      )}
    </div>
  );
};
