import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { RxText } from 'rx-core/components/texts/RxText';

type HeaderActionUserPopperItemProps = HTMLAttributes<HTMLDivElement> & {
  text: string;
  src: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  margin-top: 8px;
  cursor: pointer;

  padding: 10px 8px;
  border-radius: 10px;

  &:hover {
    background-color: #f7f9fc;
  }
`;

const IconContainer = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 14px;
`;

const HeaderActionUserPopperItem = ({
  text,
  src,
  ...props
}: HeaderActionUserPopperItemProps): JSX.Element => {
  return (
    <Container {...props}>
      <IconContainer src={src} />
      <RxText font="Roboto Regular" size="small">
        {text}
      </RxText>
    </Container>
  );
};

export { HeaderActionUserPopperItem };
