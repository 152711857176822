import React from 'react';
import { Formik, Form } from 'formik';

import { SEO, TemplateView } from 'rx-core';

import { useAddProduct } from 'rx-hooks';

import { EditPictureProductValidator } from 'validations/EditPictureProductValidator';

import { AddProductForm } from './AddProductForm';
import { AddProductActionButtons } from './AddProductActionButtons';

import { Container } from './index.styles';
import { AddProductActions } from '../add-product/AddProductActions';

export const PictureProductView: React.FC = () => {
  const { initialValues, onSubmit, isEdit, product } = useAddProduct(true);

  return (
    <TemplateView>
      <SEO title="RxRise | Update product image" />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={EditPictureProductValidator}
        enableReinitialize
      >
        <Form>
          <Container>
            <AddProductActions
              isEdit={isEdit}
              productName={initialValues.name}
            />
            {product && <AddProductForm product={product} isEdit={isEdit} />}
            <AddProductActionButtons />
          </Container>
        </Form>
      </Formik>
    </TemplateView>
  );
};
