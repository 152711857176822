import React, { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

type FooterSocialIconProps = ImgHTMLAttributes<HTMLImageElement> & {
  href: string;
};

const Link = styled.a`
  height: 40px;
  width: 40px;
  margin-right: 20px;
  cursor: pointer;
`;

const Icon = styled.img`
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

const FooterSocialIcon = ({
  href,
  ...props
}: FooterSocialIconProps): JSX.Element => {
  return (
    <Link href={href} target="_blank">
      <Icon {...props} />
    </Link>
  );
};

export { FooterSocialIcon };
