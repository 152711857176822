import React from 'react';
import styled, { css } from 'styled-components';

import { Grid } from '@material-ui/core';
import { RxInputText, RRSelect as RxSelect } from 'rx-core';
import { MASK_ZIP_CODE, STATE_CODES_US } from 'rx-utils';

const Container = styled.div``;

const PharmacyTitleSection = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.sizes.h2};
    font-family: ${theme.fonts.robotoRegular};
    font-weight: 400;
    margin-bottom: 1.5rem;

    ${theme.lessThan('ipad')} {
      padding: 0 1.5rem;
    }
  `}
`;

const GridResponsive = styled(Grid)`
  ${({ theme }) => css`
    ${theme.lessThan('ipad')} {
      &.MuiGrid-spacing-xs-2 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }

      &.MuiGrid-spacing-xs-4 {
        width: 100%;
        margin: 0;

        & > div {
          padding: 0.5rem 1.5rem;
        }
      }
    }
  `}
`;

const SignupBuyerPharmacyShippingInfoForm = (): JSX.Element => {
  return (
    <Container>
      <PharmacyTitleSection>Pharmacy shipping information</PharmacyTitleSection>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="shippingInfo.address"
            label="Street Address *"
            placeholder="Street Address"
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxSelect
            name="shippingInfo.state"
            label="State *"
            placeholder="Choose State"
            items={STATE_CODES_US.map((state) => ({
              value: state,
              name: state,
            }))}
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
      <GridResponsive container spacing={4}>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="shippingInfo.city"
            label="City *"
            placeholder="City Name"
            upperLabel={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RxInputText
            name="shippingInfo.zipCode"
            label="Zip Code *"
            placeholder="Enter Zip Code"
            mask={MASK_ZIP_CODE}
            withMask
            upperLabel={false}
          />
        </Grid>
      </GridResponsive>
    </Container>
  );
};

export { SignupBuyerPharmacyShippingInfoForm };
