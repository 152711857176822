import React from 'react';

import { ITransactionPurchaseOrder } from 'rx-domain';
import { formatMoney } from 'rx-utils';

import { TransactionPODetailsInfo } from '../TransactionPODetailsInfo';
import { TotalContainer } from './index.styles';
import { TransactionPODetailsInfoRow } from '../TransactionPODetailsInfoRow';
import { TransactionPODetailsProducts } from '../TransactionPODetailsProducts';

type IProps = ITransactionPurchaseOrder;

export const TransactionPurchaseOrderDetails: React.FC<IProps> = (props) => {
  return (
    <div>
      <div>
        <TransactionPODetailsInfo {...props} />
      </div>
      <div>
        <TransactionPODetailsProducts {...props} />
      </div>
      <TotalContainer>
        <TransactionPODetailsInfoRow
          title="GRAND TOTAL"
          content={formatMoney(parseFloat(props.subtotal))}
        />
      </TotalContainer>
    </div>
  );
};
