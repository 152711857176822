import { useMutation } from 'react-query';
import { http } from 'rx-api/http/repository';
import { GET_TRANSACTIONS_URL } from './constants';

type IRequest = {
  compoundOrderId: string;
  limit: number;
};

const makeParams = (req: IRequest) => {
  let params = `?limit=${req.limit}`;

  if (req.compoundOrderId) {
    params = `${params}&compoundOrderId=${req.compoundOrderId}`;
  }

  return params;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDownloadTransactions = () => {
  const onRequest = (request: IRequest) => {
    return http.get(`${GET_TRANSACTIONS_URL}${makeParams(request)}`, {
      withCredentials: true,
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
      },
    });
  };

  return useMutation(onRequest, {});
};

export { useDownloadTransactions };
