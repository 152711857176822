import { useMutation } from 'react-query';

import { http } from 'rx-api/http/repository';

type IPutRequest = {
  url: string;
  method: 'put' | 'post' | 'get' | 'delete';
  headers?: { [key: string]: string };
};

export const useRxMutation = <T, R>({ url, method, headers }: IPutRequest) => {
  const onRequest = (request?: T) =>
    http[method]<R>(url, request, {
      headers: headers ?? {},
    });

  return useMutation(onRequest);
};
