import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  ${({ theme }) => css`
    align-items: center;
    flex-direction: column;
    display: flex;

    ${theme.lessThan('ipadPro')} {
      margin-left: 0;
    }

    ${theme.lessThan('mobile')} {
      padding: 1.5rem;
    }
  `}
`;

const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.blue[500]};
    font-size: ${theme.sizes.h1};
    font-family: ${theme.fonts.roobertLight};
    font-weight: normal;
    text-align: center;
    margin-top: 5rem;
  `}
`;

const Subtitle = styled.p`
  margin-top: 2.25rem;
  text-align: center;
`;

const EmailText = styled.a`
  ${({ theme }) => css`
    margin-top: 2.25rem;
    text-align: center;
    font-size: ${theme.sizes.medium};
    font-family: ${theme.fonts.robotoMedium};
    color: ${theme.colors.black[100]};
    cursor: pointer;
  `}
`;

const SignupFinishUp = (): JSX.Element => {
  return (
    <Container>
      <Title>Thank you!</Title>
      <Subtitle>
        Thank you for joining the RxRise community. We are validating <br />{' '}
        your information and licenses. We will get back to you between 24 <br />{' '}
        hrs.
      </Subtitle>
      <EmailText href="/">Return to main site.</EmailText>
    </Container>
  );
};

export { SignupFinishUp };
