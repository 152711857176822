import React from 'react';
import moment from 'moment';
import {
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useGetAdminMetricsTopProducts } from 'rx-api';
import { useQuery } from 'rx-hooks';

import { DashboardTopProductsHeader } from './DashboardTopProductsHeader';
import { DashboardTopProductsLoader } from './DashboardTopProductsLoader';
import { DashboardTopProductsEmpty } from './DashboardTopProductsEmpty';
import { DashboardTopProductsRow } from './DashboardTopProductsRow';
import { DashboardTopProductsActions } from './DashboardTopProductsActions';
import { ReportFilterDatePicker } from '../../reports/PaymentsReports/PaymentsReportFilterDatePicker';

type DateRangeFilter = 'all' | 'quarter' | 'month' | 'week' | 'day' | 'custom';

const getDateRange = (time: DateRangeFilter, from: string, to: string) => {
  if (time === 'all') {
    return {
      to: '',
      from: '',
    };
  }

  if (time === 'custom') {
    return { to, from };
  }

  return {
    to: moment().endOf(time).toISOString(),
    from: moment().startOf(time).toISOString(),
  };
};

const dollarUSLocale = Intl.NumberFormat('en-US');

export const DashboardTopProducts: React.FC = () => {
  const [filter, setFilter] = React.useState<DateRangeFilter>('all');
  const [pageSize, setPageSize] = React.useState(25);
  const query = useQuery();
  const to = query.get('to') as string;
  const from = query.get('from') as string;
  const { data: response, isLoading } = useGetAdminMetricsTopProducts({
    ...getDateRange(filter, from, to),
    limit: pageSize,
  });

  const handleChangeFilter = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilter(event.target.value as DateRangeFilter);
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setPageSize(event.target.value as number);
  };

  const handleClickDownloadCsv = () => {
    const rows = response?.data
      ?.map((product) => {
        return `${product.name},${product.totalUnitsSold},${product.totalAmountSold},${product.averageSellingPrice}`;
      })
      .join('\n');

    const headers =
      'name,total units sold, total amount sold, average selling price';

    const csv = `${headers}\n${rows}`;
    const csvFile = new Blob([csv], { type: 'text/csv' });
    const downloadLink = document.createElement('a');

    downloadLink.download = `top-products.csv`;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();
  };

  const handleClickScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Paper>
        <DashboardTopProductsHeader>
          <div style={{ display: 'flex' }}>
            <FormControl>
              <Select
                variant="outlined"
                value={filter}
                onChange={handleChangeFilter}
              >
                <MenuItem value="all">Filter: All Time</MenuItem>
                <MenuItem value="day">Filter Today</MenuItem>
                <MenuItem value="week">Filter: This Week</MenuItem>
                <MenuItem value="month">Filter: Last Month</MenuItem>
                <MenuItem value="quarter">Filter: Last Quarter</MenuItem>
                <MenuItem value="custom">Filter: Custom</MenuItem>
              </Select>
            </FormControl>

            {filter === 'custom' && (
              <ReportFilterDatePicker queries={{ to, from }} />
            )}
          </div>

          <DashboardTopProductsActions>
            <FormControl>
              <Typography>
                Displaying{' '}
                <Select
                  variant="outlined"
                  value={pageSize}
                  onChange={handleChangePageSize}
                >
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>{' '}
                items
              </Typography>
            </FormControl>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickDownloadCsv}
                style={{ marginLeft: '1rem' }}
              >
                Download CSV
              </Button>
            </div>
          </DashboardTopProductsActions>
        </DashboardTopProductsHeader>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell align="right">Total Units Sold</TableCell>
                <TableCell align="right">Total Amount Sold</TableCell>
                <TableCell align="right">Average Selling Price</TableCell>
              </TableRow>
            </TableHead>

            {!isLoading && response?.data.length === 0 && (
              <DashboardTopProductsEmpty />
            )}

            {!isLoading && !!response?.data.length && (
              <TableBody>
                {response?.data.map((row, index) => {
                  return (
                    <DashboardTopProductsRow
                      key={index}
                      name={row.name}
                      totalUnitsSold={row.totalUnitsSold.toLocaleString(
                        'en-US'
                      )}
                      totalAmountSold={`$${dollarUSLocale.format(
                        row.totalAmountSold
                      )}`}
                      averageSellingPrice={`$${row.averageSellingPrice}`}
                    />
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {isLoading && <DashboardTopProductsLoader />}
      </Paper>

      {!isLoading && response?.data.length !== 0 && (
        <div style={{ margin: '1rem 0 0 0', textAlign: 'center' }}>
          <Button onClick={handleClickScrollToTop} color="primary">
            Scroll to the top
          </Button>
        </div>
      )}
    </>
  );
};
