import React from 'react';
import { Switch, Typography } from '@material-ui/core';
import styled from 'styled-components';

type IProps = {
  twoFactorEnabled: boolean;
  onClickToggle(): void;
  disabled: boolean;
};

const SwitchContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
});

export const Manufacturer2FASettings: React.FC<IProps> = (props) => {
  return (
    <>
      <header style={{ marginBottom: '1rem' }}>
        <Typography variant="h5">2FA Settings</Typography>
      </header>
      <SwitchContainer>
        <Typography>Is 2FA Enabled?</Typography>
        <Switch
          color="primary"
          checked={props.twoFactorEnabled}
          onClick={props.onClickToggle}
          disabled={props.disabled}
        />
      </SwitchContainer>
    </>
  );
};
