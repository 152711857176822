import React from 'react';

import {
  Container,
  InputContainer,
  LoadingIcon,
  SearchInputIcon,
  Input,
} from './index.styles';

type IProps = {
  loading: boolean;
  onSearchInput: (event: React.SyntheticEvent) => void;
  placeholder?: string;
};

export const RxTableSearch: React.FC<IProps> = (props) => {
  return (
    <Container>
      <InputContainer>
        {props.loading ? (
          <LoadingIcon size={26} thickness={6} />
        ) : (
          <SearchInputIcon />
        )}
        <Input placeholder={props.placeholder} onKeyUp={props.onSearchInput} />
      </InputContainer>
    </Container>
  );
};
