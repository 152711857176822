import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;

  display: flex;
  justify-content: flex-end;

  & > div > button {
    margin-left: 0;
  }
`;
